import React, { Component } from "react";
import * as Yup from "yup";
import Modal from "reactstrap/es/Modal";
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import { getDataByArrivalID } from "../../NetworkHelpers";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {
  getErrorsFromValidationError
} from "../utilities";
import Config from "../../Config";
import "react-image-lightbox/style.css";


class CancelArrival extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cancelObject: {
        cancelcomments: "",
        arrivalID: props.arrivalID,
        cancelusername: this.props.user.firstname + " " + this.props.user.lastname
      },
      updatePopupState: false,
      errors: {},
      loaded: false,
      touched: {},


    };
  }


  componentWillUpdate(previousProps, prevState, snapshot) {

    if (previousProps.open && this.state.updatePopupState === false) {

      this.setState({
        cancelObject: {
          cancelcomments: "",
          arrivalID: previousProps.arrivalID,
          cancelusername: previousProps.user.firstname + " " + previousProps.user.lastname
        },
        updatePopupState: true
      });


    }



  }


  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1>Cancel Arrival</h1>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {



              this.onSubmit();
              e.preventDefault();
            }}
          >
            <Card className="simple-card">
              <CardBody>
                <div>
                  {/* <FFFormTextField
                                    label= "cancelusername"
                                    value={
                                     this.state.cancelObject.cancelusername
                                    }
                                    disabled= {true}
                                    
                              /> */}
                  <FFFormTextAreaField
                    label="Comments"
                    placeholder="Enter Cancel Comments"
                    icon="fa-comment"
                    fieldname="comments"

                    value={this.state.cancelObject.cancelcomments}
                    onChange={(event) => {
                      let s = { ...this.state.cancelObject };
                      s.cancelcomments = event.target.value;
                      this.setState({ cancelObject: s });
                    }}
                    rows={"5"}
                  />


                </div>
                <div>
                  {this.props.TaskAPI && (
                    <h6>
                      <strong>Notice:</strong> Check the Task page and cancel any "Check-In/Arrival" tasks that were created.
                    </h6>
                  )}

                </div>
              </CardBody>
            </Card>
            <div className="mb-3 text-right">

              <Button
                color="danger"
                type="button"
                onClick={() => this.closePopup()}
              >
                Close
              </Button>
              &nbsp;&nbsp;
              <Button
                color="primary"
                type="submit"
                onClick={() => this.onSubmit()}
              >
                Save
              </Button>
            </div>
          </Form>
        </ModalBody>
        {/* <ModalFooter>
   
        </ModalFooter> */}
      </Modal>
    )
  }
  closePopup() {
    this.setState({ updatePopupState: false })
    return this.props.onClose && this.props.onClose();

  }
  onSubmit() {
    // let obj = {...this.state.cancelObject};
    this.setState({ updatePopupState: false })
    return this.props.onSave && this.props.onSave(this.state.cancelObject);
  }

}
export default CancelArrival;