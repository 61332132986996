import React from 'react';
import { Alert, Button, Col, Form, Row } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import FormField from "./FormField";
import { updateCompany } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { TypeOfEntitySelect } from "../../common/TypeOfEntitySelect";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import { FFCountrySelect } from "../../common/formik-free/FFFormCountrySelect";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import { FFStateSelect } from "../../common/formik-free/FFStateSelect";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

const initialValues = {
  entityName: "",
  typeOfUser: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  country: "",
  postalCode: "",
  dbaName: "",
  defaultGeoFence: ".5"
};

const validationSchema = function (values) {
  return Yup.object().shape({
    name: Yup.string()
      .min(2, `Legal Entity Name has to be at least 2 characters`)
      .required('Legal Entity Name is required').nullable(),
    address1: Yup.string()
      .required('Address 1 is required').nullable(),
    country: Yup.string()
      .required('Country is required').nullable(),
    city: Yup.string()
      .required('City is required').nullable(),
    state: Yup.string()
      .required('State is required').nullable(),
    postalCode: Yup.string()
      .required('Postal code is required').nullable(),
    typeOfUser: Yup.string()
      .required('Type of Entity is required').nullable(),
  })
};

const validate = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {}
    } catch (error) {
      return getErrorsFromValidationError(error)
    }
  }
};

export default class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: this.props.company,
      canDownloadListOfStatesForCountries: ["United States", "Canada"]
    }

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company.uuid !== this.props.company.uuid) {
      this.setCompany(this.props.company);
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  setCompany(company) {
    this.setState({ company: initialValues }, //resetForm equivalent
      () => {
        this.setState({ company: company })
      }
    );
  }

  onCancel() {
    this.setCompany(this.props.company);
  }

  onSubmit(values, { setSubmitting, setErrors }) {
    this.updateSpinner("Saving...");
    this.setState({ loadedLocation: true });
    setSubmitting(false);
    // copy over email to username.
    let company = Object.assign({}, values);
    updateCompany(company, (company) => {
      showInfo("Company successfully updated");
      this.props.onChange(company);
      this.setState({ loadedLocation: false });

    }, (error) => {
      this.setState({ loadedLocation: false });

      showError("Company failed to save" + (error.message ? " " + error.message : ""));
    });
    return false;
  }

  render() {
    return <>
      <Loader show={this.state.loadedLocation} message={this.spinner}>

        <Formik
          enableReinitialize
          initialValues={this.state.company}
          validate={validate(validationSchema)}
          onSubmit={(v, o) => {
            this.onSubmit(v, o)
          }}
          render={
            ({
              values,
              errors,
              touched,
              status,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              handleReset,
              setTouched,
              setFieldValue,
              setFieldTouched
            }) => (


              <Form onSubmit={(v) => {
                return handleSubmit(v)
              }}>
                <h5 className="card-title">Entity</h5>
                <FFFormTextField
                  label="Legal Entity Name"
                  placeholder=""
                  icon="fa-truck"
                  fieldname="name"
                  error={errors.name}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("name", newValue);
                    setFieldTouched("name");
                  }}
                  touched={touched.name}
                  value={values.name}
                  required={true}
                />
                <FFFormTextField
                  icon="fa-id-card"
                  label="US DOT Number"
                  placeholder=""
                  fieldname="usDotNumber"
                  error={errors.usDotNumber}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("usDotNumber", newValue);
                    setFieldTouched("usDotNumber");
                  }}
                  touched={touched.usDotNumber}
                  value={values.usDotNumber}
                />
                <FFFormTextField
                  label="FMCSA MC Number"
                  placeholder=""
                  fieldname="fmcsaMcNumber"
                  error={errors.fmcsaMcNumber}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("fmcsaMcNumber", newValue);
                    setFieldTouched("fmcsaMcNumber");
                  }}
                  touched={touched.fmcsaMcNumber}
                  value={values.fmcsaMcNumber}
                  prepend={"MC-"}
                />
                <FFFormTextField
                  label="FMCSA FF Number"
                  placeholder=""
                  fieldname="fmcsaFfNumber"
                  error={errors.fmcsaFfNumber}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("fmcsaFfNumber", newValue);
                    setFieldTouched("fmcsaFfNumber");
                  }}
                  touched={touched.fmcsaFfNumber}
                  value={values.fmcsaFfNumber}
                  prepend={"FF-"}
                />
                <FFFormTextField
                  label="FMCSA MX Number"
                  placeholder=""
                  fieldname="fmcsaMxNumber"
                  error={errors.fmcsaMxNumber}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("fmcsaMxNumber", newValue);
                    setFieldTouched("fmcsaMxNumber");
                  }}
                  touched={touched.fmcsaMxNumber}
                  value={values.fmcsaMxNumber}
                  prepend={"MC-"}
                />
                <FFFormTextField
                  icon="fa-id-card"
                  label="National Registration Number"
                  placeholder=""
                  fieldname="nationalRegistrationNumber"
                  error={errors.nationalRegistrationNumber}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("nationalRegistrationNumber", newValue);
                    setFieldTouched("nationalRegistrationNumber");
                  }}
                  touched={touched.nationalRegistrationNumber}
                  value={values.nationalRegistrationNumber}
                />
                <FormField
                  icon={"fa-city"}
                  fieldname={"typeOfUser"}
                  errors={errors}
                  label={"Type Of Entity"}
                >
                  <TypeOfEntitySelect
                    disabled
                    errors={errors}
                    touched={touched}
                    onChange={(v) => {
                      setFieldValue("typeOfUser", v ? v.value : "");
                      setFieldTouched("typeOfUser", true);
                    }}
                    onBlur={() => setFieldTouched("typeOfUser", true)}
                    value={values.typeOfUser}
                  />
                </FormField>
                <FFFormTextField
                  label="DBA Name"
                  placeholder=""
                  icon="fa-gavel"
                  fieldname="dbaName"
                  error={errors.dbaName}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("dbaName", newValue);
                    setFieldTouched("dbaName");
                  }}
                  touched={touched.dbaName}
                  value={values.dbaName}
                />
                <h5 className="card-title">Corporate Address</h5>
                <FFFormTextField
                  label="Address 1"
                  placeholder=""
                  icon="fa-building"
                  fieldname="address1"
                  error={errors.address1}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("address1", newValue);
                    setFieldTouched("address1");
                  }}
                  touched={touched.address1}
                  value={values.address1}
                  required={true}
                />
                <FFFormTextField
                  label="Address 2"
                  placeholder=""
                  icon="fa-building"
                  fieldname="address2"
                  error={errors.address2}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("address2", newValue);
                    setFieldTouched("address2");
                  }}
                  touched={touched.address2}
                  value={values.address2}
                />
                <FFFormTextField
                  label="City"
                  placeholder=""
                  icon="fa-building"
                  fieldname="city"
                  error={errors.city}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("city", newValue);
                    setFieldTouched("city");
                  }}
                  touched={touched.city}
                  value={values.city}
                  required={true}
                />
                <FormField
                  label={"Country"}
                  errors={errors}
                  icon={"fa-building"}
                  fieldname={"country"}
                  required={true}
                >
                  <FFCountrySelect
                    name="country"
                    placeholder={"Country"}
                    error={errors.country}
                    touched={touched.country}
                    onChange={(object) => {
                      setFieldValue("country", object ? object.value : "");
                      setFieldTouched("country", true);
                      setFieldValue("state", "");
                    }}
                    onBlur={() => setFieldTouched("country", true)}
                    value={values.country}
                  />
                </FormField>
                {this.state.canDownloadListOfStatesForCountries.indexOf(values.country) !== -1 ?
                  <FormField
                    fieldname={"state"}
                    errors={errors}
                    icon={"fa-building"}
                    label={"State"}
                    required={true}
                  >
                    <FFStateSelect
                      name="state"
                      selectedCountry={values.country}
                      fieldname={"state"}
                      value={values.state}
                      touched={touched.state}
                      error={errors.state}
                      onBlur={() => {
                        setFieldTouched("state", true);
                      }}
                      onChange={(object) => {
                        setFieldValue("state", object ? object.value : "");
                        setFieldTouched("state", true);
                      }}
                    />
                  </FormField> :
                  <FFFormTextField
                    label="State/County/Province"
                    placeholder=""
                    icon="fa-building"
                    fieldname="state"
                    error={errors.state}
                    handleBlur={handleBlur}
                    onChange={(value) => {
                      setFieldValue("state", value);
                      setFieldTouched("state", true);
                    }}
                    touched={touched.state}
                    value={values.state}
                    required={true}
                  />}
                <FFFormTextField
                  icon="fa-building"
                  label={values['country'] ? (values['country'] === "United States" ? "Zip Code *" : "Postal Code *") : "Postal Code *"}
                  fieldname="postalCode"
                  error={errors.postalCode}
                  onTouch={handleBlur}
                  onChange={(value) => {
                    setFieldValue("postalCode", value);
                    setFieldTouched("postalCode", true);
                  }}
                  touched={touched.postalCode}
                  value={values.postalCode}
                />
                <FFFormSelectField
                  label="Distance Units Of Measure"
                  required={false}
                  placeholder="Enter Value"
                  icon="fa-ruler"
                  fieldname="distanceUnitsOfMeasure"
                  name="distanceUnitsOfMeasure"
                  error={errors.distanceUnitsOfMeasure}
                  onBlur={() => {
                    setFieldTouched("distanceUnitsOfMeasure", true);
                  }}
                  onChange={(value) => {
                    setFieldValue("distanceUnitsOfMeasure", value ? value.value : "Miles");

                    setFieldTouched("distanceUnitsOfMeasure");
                    setFieldValue("unitsOfMeasure", "distanceUnitsOfMeasure");
                  }}
                  touched={touched.distanceUnitsOfMeasure}
                  value={values.distanceUnitsOfMeasure}
                  maxMenuHeight={150}
                  options={["Miles", "Kilometers"]}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  noSearch={true}
                />
                <FFFormSelectField
                  label="Temperature Units Of Measure"
                  required={false}
                  placeholder="Enter Value"
                  icon="fa-ruler"
                  fieldname="temperatureUnitsOfMeasure"
                  name="temperatureUnitsOfMeasure"
                  error={errors.temperatureUnitsOfMeasure}
                  onBlur={() => {
                    setFieldTouched("temperatureUnitsOfMeasure", true);
                  }}
                  onChange={(value) => {
                    setFieldValue("temperatureUnitsOfMeasure", value ? value.value : "Fahrenheit");
                    setFieldTouched("temperatureUnitsOfMeasure");
                  }}
                  touched={touched.temperatureUnitsOfMeasure}
                  value={values.temperatureUnitsOfMeasure}
                  maxMenuHeight={150}
                  options={["Fahrenheit", "Celsius"]}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  noSearch={true}
                />
                <Alert>
                  <span>
                    Please give comma (,) separated emails in case of multiple emails.
                  </span>
                </Alert>
                <FFFormTextField
                  label="Send New User Request To: "
                  placeholder=""
                  icon="fa-envelope"
                  fieldname="emailAddresses"
                  error={errors.emailAddresses}
                  onTouch={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("emailAddresses", newValue);
                    setFieldTouched("emailAddresses");
                  }}
                  touched={touched.emailAddresses}
                  value={values.emailAddresses}
                  required={false}
                />
                <FFFormCheckboxField
                  label="Visible"
                  icon="fa-power-off"
                  fieldname="visible"
                  error={errors["visible"]}
                  handleBlur={handleBlur}
                  onChange={(newValue) => {
                    setFieldValue("visible", newValue);
                  }}
                  touched={touched["visible"]}
                  value={values["visible"]}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />

                <Row>
                  <Col xs="12" className={"text-right"}>
                    <Button color="danger" onClick={() => { this.onCancel(); return false; }}>Cancel</Button> &nbsp;
                    <Button color="primary" type="submit" disabled={this.state.loadedLocation}>Save</Button>
                  </Col>
                </Row>
              </Form>)}
        />
      </Loader>
    </>
  }
}