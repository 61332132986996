import React, {Component} from "react";
import {Button, Card, CardBody, Col, Container, Row} from "reactstrap"
import {downloadAssetsForTable, saveNote, sendDriverSms} from "../../NetworkHelpers";
import Moment from "react-moment";
import ReactTable from "react-table";
import 'react-table/react-table.css';
import '../../ReactTable.scss';
import ReactPaginate from 'react-paginate';
import MomentDisplay from "./MomentDisplay"
import DurationDisplay from "./DurationDisplay"
import {filterCaseInsensitiveContains, isRegularLeftClick} from "../../common/utilities";
import {ROLE_ADMIN, ROLE_ATTENDANT, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import StaticDurationDisplay from "./StaticDurationDisplay";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {RefreshReload} from "../../common/RefreshReload";
import NoteModal from "./NoteModal";
import ValidationService from "../../common/ValidationService";
import SendSMSModal from "../../Dispatch/actions/SendSMSModal";
import {showError, showInfo} from "../../MainApp";
import Alerts from "../../common/Alerts";


const ShipmentLink = ({shipmentId, displayShipment}) => {
    return <a href={"/shipment-profile/" + shipmentId} onClick={(e)=>{if (isRegularLeftClick(e)) {e.preventDefault();displayShipment(shipmentId)}}}>
        {shipmentId}
    </a>
};


class AssetView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assets: null,
            pages: -1,
            loading: true,
            page: 0,
            sorted: [],
            filtered: [],
            autoRefreshing: true
        };
        this.searchRequest = {
            page: 0,
            pageSize: props.itemsPerPage,
            sorted: [],
            filtered: [],
            assetTypes: props.assetTypes
        };
        this.tableColumns = this.tableColumns.map((col)=>{
            if ( typeof col.sortable === "undefined" ) {
                col.sortable = true;
            }
            if ( col.sortable ) {
                let x = col.Header;
                col.Header = (obj)=>{
                    return (
                        <><i className="fa fa-sort"></i> {x}</>
                    );
                }
            }
            return col;
        });
        this.reactTableRef = React.createRef();
    }

    tableColumns = [
        {
            Header: 'Asset ID',
            accessor: 'assetId',
            filterable: true,
            sortable: true,
            Cell: row => {let v = row.original; return v.assetId;}

        },
        {
            Header: 'Type',
            filterable: true,
            accessor: 'assetType',
        },
        {
            Header: 'Trailer Comment',
            filterable: true,
            filterMethod: (filter, row)=>{
                return row.assetType === "Trailer" && filterCaseInsensitiveContains(filter,row);
            },
            accessor: 'lastArrival.trailerComment',
            Cell: row => {
                let v = row.original;
                if(v.assetType === "Trailer" && v.lastArrival ){
                    return <div>{v.lastArrival.trailerComment}</div>
                }else {
                    return <div></div>
                }
            }
        },
        {
            Header: 'Carrier/Broker',
            accessor: 'lastArrival.carrier.name',
            filterable: true,
            sortable: true,
            Cell: row => {
                let v = row.original;
                let lastGateEvent = v.lastArrival || v.lastDeparture;
                let carrier = lastGateEvent && lastGateEvent.carrier?lastGateEvent.carrier.name:"N/A";
                let broker = lastGateEvent && lastGateEvent.broker?lastGateEvent.broker.name:"N/A";
                return <span>{carrier}<br />{broker}</span>;
            }
        },
        {
            Header: 'Appointment',
            filterable: false,
            accessor: 'appointment',
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {let v = row.original; let d = v.appointment?v.appointment:new Date(); return <MomentDisplay date={d} timezone={this.props.currentLocation && this.props.currentLocation.timezone}/>}
        },
        {
            Header: 'Arrival',
            filterable: false,
            accessor: 'latestGateEventArrival',
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {let v = row.original; return ( v.events && v.events.length > 0 )?(v.latestGateEventArrival?
                <MomentDisplay date={v.latestGateEventArrival} timezone={this.props.currentLocation && this.props.currentLocation.timezone}/>:"N/A"):"N/A"
            }
        },
        {
            Header: 'Alerts',
            filterable: false,
            sortable: false,
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {
                return row.original.asset && row.original.asset.assetType !== "Driver" ? <Alerts alert={row.original.asset.alertModel}/> : "";
            }
        },
        {
            Header: 'Events',
            accessor: 'events',
            filterable: false,
            sortable: false,
            Cell: row => {let v = row.original; return (this.formatEvents(v))}
        },
        {
            Header: 'Receiving Ref #',
            accessor: 'incomingShipmentNumber',
            filterable: true,
            Cell: row => {let v = row.original; return v.incomingShipmentNumber?
                <ShipmentLink shipmentId={v.incomingShipmentNumber}
                              displayShipment={(shipment)=>this.props.displayShipmentProfile(shipment)} />:"N/A"}
        },
        {
            Header: 'Shipping Ref #',
            accessor: 'outgoingShipmentNumber',
            filterable: true,
            Cell: row => {let v = row.original; return v.outgoingShipmentNumber?
                <ShipmentLink shipmentId={v.outgoingShipmentNumber}
                              displayShipment={(shipment)=>this.props.displayShipmentProfile(shipment)} />:"N/A"}
        },
        {
            Header: 'Dwell Time',
            accessor: 'dwellTime',
            filterable: false,
            Cell: row => {let v = row.original; return ( v.events && v.events.length > 0 )?(v.atLocation?
                <DurationDisplay
                    date={v.latestGateEventArrival}
                />:<div><StaticDurationDisplay durationMillis={v.dwellTimeMillliseconds} /></div>):"N/A";}
        },
        {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: row => {return <>
                <Button title="Add Note"
                        aria-label="Add Note"
                        onClick={() => this.openNoteModal(row.original.uuid, row.original.assetId, row.original.notes)}>
                    <i className="fa fa-edit"/>
                </Button> &nbsp;
                {row.original.assetType === "Driver" ?
                    <Button
                        title="Text Driver"
                        aria-label="Text Driver"
                        onClick={() => this.openSmsModal(row.original.driver.uuid,
                            row.original.driver.firstname,
                            row.original.driver.lastname,
                            row.original.company.shipmentIdDisplay,
                            row.original.outgoingShipmentNumber,
                            ValidationService.formatPhone(row.original.driver.countryCode,
                                row.original.driver.cell),
                            row.original.messages)}>
                        <i className="fa fa-comment"/>
                    </Button>
                    : null }
                </>
            }
        }
    ];

    openSmsModal(uuid, driverFirstName, driverLastName, defaultReferenceLabel, shipmentNumber, driverPhoneNumber, reminders) {
        this.setState({showingSmsModal: true,
            smsModalUuid: uuid,
            driverFirstName: driverFirstName,
            driverLastName: driverLastName,
            defaultReferenceLabel: defaultReferenceLabel,
            shipmentNumber: shipmentNumber,
            userFirstName: this.props.user.firstname,
            driverPhoneNumber: driverPhoneNumber,
            remindersForModal: reminders
        });
    }

    closeSmsModal() {
        this.setState({showingSmsModal: false, smsModalUuid: false, messageModalError: "", remindersForModal: []});
    }
    openNoteModal(uuid, assetId, notes){
        this.setState({showingNoteModal: true, noteModalUuid: uuid, noteModalAssetId: assetId, notesForModal: notes});
    }

    closeNoteModal(){
        this.setState({showingNoteModal: false, noteModalUuid: false, notesForModal: {}});
    }


    applyAddedNote(assetUuid, note) {
        let newData = [];
        for ( let i=0;i<this.state.tableData.length;i++ ) {
            if ( this.state.tableData[i].uuid === assetUuid ) {
                let entry = Object.assign({}, this.state.tableData[i]);
                entry = Object.assign({}, entry);
                entry.notes = Object.assign({}, entry.notes);
                entry.notes[note.uuid] = note;
                newData.push(entry);
            } else {
                newData.push(this.state.tableData[i]);
            }

        }
        this.setState({tableData:newData});
    }

    addDepartureOnRole(tableColumns) {
        if(userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT])){
            tableColumns[tableColumns.length]={
                Header: (
                    <><i className="fa fa-sort"/> Departure</>
                ),
                id: "departure",
                accessor: row => {return row.atLocation?row.lastGateEvent:null},
                filterable: false,
                style:{ 'whiteSpace': 'unset'},
                Cell: row => {
                    let v = row.original;
                    return ( v.events && v.events.length > 0 )?(v.atLocation?
                        <Button color="link" onClick={()=>this.props.checkoutAsset(v)}>Process Departure</Button>:
                        <MomentDisplay
                            date={v.latestGateEvent}
                            timezone={this.props.currentLocation && this.props.currentLocation.timezone}
                        />):"";
                }
            };
        }
        return tableColumns;
    }


    componentWillMount() {
        this.addDepartureOnRole(this.tableColumns)
    }


    formatEvents( asset ) {
        if ( asset.events && asset.events.length > 0 ) {
            try {
                return asset.events.filter(e => !e.assetTypes || e.assetTypes.indexOf(asset.assetType)>=0).map(e=><div >{e.messageDescription}: &nbsp;
                    <Moment format="MM/DD/YYYY h:mm a z" tz={this.props.currentLocation && this.props.currentLocation.timezone} date={e.updated} />{this.props.latLongLink(e)}</div>);
            } catch ( e ) {
                console.log("ERROR", e);
            }
            return "ERROR";
        } else {
            return "No Events";
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.downloadAssets(this.state);
        if ( this.state.autoRefreshing ) {
            this.startTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    setRefreshing( v ) {
        if ( !v ) {
            this.stopTimer();
        } else {
            this.startTimer();
        }
        this.setState({autoRefreshing: v})
    }

    tick() {
        if ( this.state.autoRefreshing ) {
            this.downloadAssets(this.state);
        }
    }

    startTimer(){
        this.stopTimer();
        this.timer = setInterval(()=>this.tick(), 10000);
    }

    stopTimer() {
        if ( this.timer ) clearInterval(this.timer);
        this.timer = null;
    }




    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.assetTypes !== prevProps.assetTypes ||
            this.props.currentLocation !== prevProps.currentLocation ||
            this.props.itemsPerPage !== prevProps.itemsPerPage) this.downloadAssets(this.state);
    }


    downloadAssets(state) {
        if ( !this.props.currentLocation ) return;
        this.searchRequest = {
            locationUuid: this.props.currentLocation?this.props.currentLocation.uuid:"",
            page: 0,//state.page,
            pageSize: 10000,//this.props.itemsPerPage,
            sorted: state.sorted,
            filtered: state.filtered,
            test:'test',
            assetTypes: this.props.assetTypes.map((t)=>t.value)
        };
        downloadAssetsForTable( this.searchRequest, (r)=>{
            let dataLen = this.reactTableRef.current?this.reactTableRef.current.getResolvedState().sortedData.length:r.content.length;
            this.setState({tableData: r.content, loading: false, pages: dataLen / this.props.itemsPerPage, downloadTime: new Date()});
        }, (e)=>{
            this.setState({loading: false});
            //showError("Unable to load data: " + e);
        });
    }

    showGateEvents(asset) {
        this.props.showEvents(asset.gateEvents, asset.assetType + ": "  + asset.assetId);
    }

    render() {
        if ( !this.props.currentLocation && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN])) {
            this.props.history.push("/admin");
            return <div></div>;
        }else if (!this.props.currentLocation ){
            return <div className="no-location-error">Please choose a location.</div>
        }
        if ( !this.state.tableData ) {
            return (<div className="app align-items-center animated fadeIn">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <Card className="text-white bg-primary py-5 d-md-down-none" >
                                <CardBody className="text-center">
                                    <div>
                                        <h2>Loading Assets...</h2>
                                        <div className="sk-wave">
                                            <div className="sk-rect sk-rect1"></div>&nbsp;
                                            <div className="sk-rect sk-rect2"></div>&nbsp;
                                            <div className="sk-rect sk-rect3"></div>&nbsp;
                                            <div className="sk-rect sk-rect4"></div>&nbsp;
                                            <div className="sk-rect sk-rect5"></div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>)
        }
        return (<div className="column-wrapper assets-local">
            <NoteModal
                open={this.state.showingNoteModal}
                uuid={this.state.noteModalUuid}
                assetId={this.state.noteModalAssetId}
                notes={this.state.notesForModal}
                error={this.state.noteModalError}
                onSave={(note, setErrors)=>{
                    saveNote(this.state.noteModalUuid, {note: note}, (data)=>{
                        this.applyAddedNote(this.state.noteModalUuid, data);
                        this.closeNoteModal();
                    }, (error) =>{
                        this.setState({noteModalError: error.message})
                    });
                }}
                onCancel={()=>{
                    this.closeNoteModal();
                }}
            />
            <SendSMSModal
                open={this.state.showingSmsModal}
                uuid={this.state.smsModalUuid}
                defaultReferenceLabel={this.state.defaultReferenceLabel}
                driverFirstName={this.state.driverFirstName}
                driverLastName={this.state.driverLastName}
                shipmentNumber={this.state.shipmentNumber}
                userFirstName={this.state.userFirstName}
                sendSmsToPhone={this.state.driverPhoneNumber}
                messages={this.state.remindersForModal}
                error={this.state.messageModalError}
                onSave={(message, setErrors)=>{
                    sendDriverSms(this.state.smsModalUuid, message, (data)=>{
                        showInfo("Message Sent");
                        this.closeSmsModal();
                    }, (error) =>{
                        showError("Failed to send message "+ error.message);
                        this.setState({messageModalError: error.message})
                    });
                }}
                onCancel={()=>{
                    this.closeSmsModal();
                }}
            />
            <div className="main-table-wrapper">
                {this.renderReactTable()}
            </div>
            <div className="table-action-bar page-footer">
                <Row>
                    <Col md="4">
                        {userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT]) ?
                            <Button color="link" onClick={() => this.props.showArrivalScreen()}> <i
                                className="fa fa-arrow-circle-left"></i> Process Arriving </Button> : ""}
                        {userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT]) ?
                            <Button color="link" onClick={() => this.props.showDepartureScreen()}> <i
                                className="fa fa-arrow-circle-right"></i> Process Departing </Button> : ""}
                    </Col>


                    <Col md="4" className="text-center">
                        <RefreshReload onClick={() => this.downloadAssets(this.state)}
                                       onChange={(checked) => this.setRefreshing(checked)}
                                       checked={this.state.autoRefreshing}/>
                    </Col>

                    <Col md="4">
                        <div className="pagination-wrapper">
                            <ReactPaginate
                                previousLabel={'<<'}
                                nextLabel={'>>'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={(x) => {
                                    this.setState({page: x.selected, tableData: this.state.tableData.slice()});
                                }}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                            <select value={this.props.itemsPerPage} onChange={(e) => {
                                this.props.setItemsPerPage(e.target.value)
                            }}>
                                <option>5</option>
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                            </select>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>);
    }

    renderReactTable() {
        return (
            <ReactTable
                showPagination={false}
                columns={this.tableColumns}
                data={this.state.tableData?this.state.tableData:[]}
                loading={this.state.loading}
                page={this.state.page}
                pageSize={this.state.page===0?Math.max(1,Math.min(this.state.tableData.length, this.props.itemsPerPage)):this.props.itemsPerPage}
                onPageSizeChange={(pageSize, page) => this.props.setItemsPerPage( pageSize )}
                className="-striped -highlight"
                defaultFilterMethod={filterCaseInsensitiveContains}
                ref={this.reactTableRef}
                onFilteredChange={() => {
                    this.setState({pages:this.reactTableRef.current.getResolvedState().sortedData.length/this.props.itemsPerPage})
                }}
                getTheadFilterProps={(state, rowInfo, column, instance) => {
                    return {
                        style:
                            this.props.hideFilters
                                ? { display: "none" }
                                : null
                    };
                }}

                style={{
                    maxHeight: "100%",// This will force the table body to overflow and scroll, since there is not enough room
                }}
            />
        )

    }

}

function mapStateToProps(state) {
    return {
        user: state.login.user
    }
}

export default withRouter(connect(mapStateToProps) (AssetView));
