import React, {Component} from "react";
import {downloadCountries} from "../../NetworkHelpers";
import {showError} from "../../MainApp";
import Select from "react-select";
import * as PropTypes from "prop-types";

export class FFCountrySelect extends Component {
    constructor(props){
        super(props);
        this.state = {
            countryOptions: [],
            countries:[]
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        downloadCountries((json) => {
            this.setCountries(json)
        }, (error) => {
            showError(error)
        })
    }

    setCountries(json) {
        let countryOptions = json.countries.map((c) => {
            return {value: c.name, label: c.name}
        });
        this.setState({
            countries: json.countries,
            countryOptions: countryOptions
        });
    }

    render() {
        return <>
            <Select
                name={this.props.name}
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                isDisabled={this.props.disabled}
                value={this.props.value ? {
                    value: this.props.value,
                    label: this.props.value
                } : ""}
                className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
                // classNamePrefix="select"
                options={this.state.countryOptions}
                isClearable={true}/>
        </>;
    }
}

FFCountrySelect.propTypes = {
    isDisabled: PropTypes.bool,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    options: PropTypes.any,
    placeholder: PropTypes.any,
    name: PropTypes.any
};