import React, {Component} from "react";
import {CardHeader, Col, Form, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import {downloadEZTrackTrackingFrequencies, getNotification} from "../../NetworkHelpers";
import {getLocalStorageName, restoreTableState, saveModalState, saveTableState} from "../../common/utilities";
import {ITEMS_PER_PAGE_DEFAULT} from "../../common/constants";

export default class NotificationModal extends Component {

    constructor(props) {
        super(props);
        let localStorageState = null;
        if (props.stateStorage) {
            localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName('notificationModal'+ '-pft', props.user)));
        }

        if (localStorageState != null) {

            if (localStorageState.enabledNotification !== undefined) {
                    this.state.enabledNotification = localStorageState.enabledNotification;
            }
        } else {
            this.state = {
                notificationData: [],
                enabledNotification: []
            };
        }
        console.log(localStorageState, 'local storage')

    }

    checkDisable() {
        this.props.columns.map(it => it.hideFirst = false);
        let v = this.props.columns.findIndex(it => it.disableControls === true);
        if (v > -1) {
            this.props.columns[v + 1].hideFirst = true;
        } else {
            if (this.props.columns.length > 0) {
                this.props.columns[0].hideFirst = true;
            }
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        getNotification((success) => {
            this.setState({ notificationData: success.data })
        })

    }
    render() {
        // this.checkDisable();
        console.log(this.state.enabledNotification, 'enabled')
        return <>
            <div>
                <Modal isOpen={this.props.open} size={'lg'}>
                    <ModalHeader>
                        Notifications
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={(e) => {
                            this.onSubmit();
                            e.preventDefault();
                        }}>
                            <Card className="simple-card">
                                <CardHeader></CardHeader>
                                <CardBody>
                                    <Container>
                                        {this.state.notificationData.map((col, index) => {

                                            return <>
                                                <Row className={col.hidden ? col.hidden === true ? "d-none" : "" : ""}>

                                                    <Col className={"text-left"} sm={4}>
                                                            <FFFormCheckboxField
                                                                value={this.state.enabledNotification.filter(e => e === col.eventCode).length > 0}
                                                                onChange={(show) => {
                                                                    if(this.state.enabledNotification.filter(e => e === col.eventCode).length > 0){
                                                                        this.setState({enabledNotification:
                                                                                this.state.enabledNotification.filter(function(item) {
                                                                                return item !== col.eventCode
                                                                            })
                                                                        });
                                                                    }
                                                                    else{
                                                                        this.setState({
                                                                            enabledNotification: [
                                                                                ...this.state.enabledNotification,
                                                                                col.eventCode]
                                                                        });
                                                                    }

                                                                }}
                                                            />
                                                        {/*}*/}
                                                    </Col>
                                                    <Col sm={6}>
                                                        {col.name}
                                                    </Col>
                                                </Row>

                                            </>;
                                        })}
                                    </Container>
                                </CardBody>
                            </Card>
                            <div className="mb-3 text-right" style={{ marginTop: "5px" }}>
                                <Button color="danger" style={{ marginRight: "5px" }} onClick={this.props.onCancel} type="submit">Cancel</Button>
                                <Button color="secondary" style={{ marginRight: "5px" }} onClick={this.props.onRestoreDefault} type="submit">Restore Defaults</Button>
                                <Button color="primary" onClick={ () => {
                                    // saveModalState('notificationModal' + "-pft", this.state, this.props.user)
                                }}>Save</Button> &nbsp;
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </>;
    }
}