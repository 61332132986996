import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {promiseTrailerByCarrierAndIdPrefix, promiseTrailerByIdPrefix} from "../../NetworkHelpers";
import * as PropTypes from "prop-types";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectStateField from "../../common/formik-free/FFFormSelectStateField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";

export class FFBobTailArrival extends Component {
    render() {
        return <Card>
            <CardHeader>
                <div className="form-section-title">
                    <Button onClick={this.props.onClick}>
                        <i className={this.props.className}/>
                    </Button> Trailer
                </div>
            </CardHeader>
            <CardBody>
                <FFFormSelectCreatableField
                    key={this.props.values && (this.props.values["broker"]||this.props.values["carrier"])}
                    defaultOptions
                    label="Trailer Number"
                    subLabel="(Start typing to lookup trailer)"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-hashtag"
                    fieldname="trailerNumber"
                    error={this.props.errors && this.props.errors["trailerNumber"]}
                    touched={this.props.touched && this.props.touched["trailerNumber"]}
                    value={this.props.values && this.props.values["trailerNumber"]}
                    getOptionLabel={this.props.optionLabel}
                    getOptionValue={this.props.optionLabel}
                    loadOptions={this.props.values && (this.props.values["broker"]||this.props.values["carrier"])?
                        promiseTrailerByCarrierAndIdPrefix(this.props.values["broker"]||this.props.values["carrier"]):
                        promiseTrailerByIdPrefix}
                    onChange={(v)=>{
                        let values;
                        if(!v) v ={};
                        values = Object.assign({}, this.props.values);
                        values.trailerNumber = v.assetId;
                        values.trailerUuid = v.uuid;
                        values.trailerLicense = v.license;
                        values.trailerLicenseState = v.licenseState;
                        values.trailerComment = v.comments;
                        values.trailerTemperature = v.temperature;
                        this.props.onChange && this.props.onChange(values);
                    }}
                    isDisabled={this.props.isDisabled}
                />
                <FFFormTextAreaField
                    label="Trailer Comment"
                    placeholder="Enter Value"
                    icon="fa-comment"
                    fieldname="trailerComment"
                    errors={this.props.errors}
                    onBlur={this.props.onBlur}
                    handleChange={(k)=>{
                        this.props.values.trailerComment = k.target.value;
                        this.props.onChange && this.props.onChange(this.props.values);
                    }}
                    touched={this.props.touched}
                    value={this.props.values.trailerComment}
                    disabled={this.props.isDisabled}
                />
                {this.props.driverSectionOpen ?
                    <FFFormTextField
                        label="License Plate"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="trailerLicense"
                        errors={this.props.errors.trailerLicense}
                        onBlur={this.props.onBlur}
                        onChange={(v)=>{
                            this.props.values.trailerLicense = v;
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched}
                        value={this.props.values.trailerLicense}
                    /> : ""}
                {this.props.driverSectionOpen ?
                    <FFFormSelectStateField
                        label="License State"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="trailerLicenseState"
                        errors={this.props.errors}
                        onBlur={this.props.onBlur}
                        onChange={v=>{
                            this.props.values.trailerLicenseState = v.label;
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched.trailerLicenseState}
                        value={this.props.values.trailerLicenseState}
                        selectedCountry={"United States"}
                    /> : ""}
                {this.props.driverSectionOpen ?
                    <FFFormTextField
                        label="Temperature"
                        placeholder="Enter Value"
                        icon="fa-thermometer-three-quarters"
                        fieldname="trailerTemperature"
                        errors={this.props.errors}
                        onBlur={this.props.onBlur}
                        handleChange={(v)=>{
                            this.props.values.trailerTemperature = v;
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched}
                        value={this.props.values.trailerTemperature}
                    /> : ""}
            </CardBody>
        </Card>;
    }
}

FFBobTailArrival.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.func,
    onChange: PropTypes.func,
    driverSectionOpen: PropTypes.any
};