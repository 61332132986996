import React, {Component} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import Dropzone from "react-dropzone";
import * as PropTypes from "prop-types";

export class FileDropModal extends Component {
    render(open = true) {
        return <Modal isOpen={this.props.open}>
            <ModalHeader>
                {this.props.header}
            </ModalHeader>
            <ModalBody style={{overflow: "auto", whiteSpace: "pre-wrap"}}>
                <div style={{backgroundColor: "light-gray"}}>
                    {this.props.error ?
                        <p style={{color: 'red'}}>
                            There was an issue importing this file due to the following reason.
                            No records from the file have been imported.
                            Note that additional issues may exist in your file and the following is just the first issue we've identified.
                            Please resolve the issue and reupload the file.<br/><br/>
                            <b>{this.props.error}</b>
                        </p>: <div/>
                    }
                    <Dropzone onDrop={this.props.onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div {...getRootProps({className: 'dropzone' + (this.props.error ? ' dropzone-error' : '')})}>
                                    <input {...getInputProps()} />
                                    { <p>Drag 'n' drop some files here, or click to select files</p> }
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <div style={{marginTop: '10px'}} className={'text-right'}>
                        <Button color="primary" onClick={() => this.props.onClose()}>Close</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>;
    }
}

FileDropModal.propTypes = {
    onDrop: PropTypes.func,
    onClose: PropTypes.func,
    header: PropTypes.any,
    error: PropTypes.any,
    open: PropTypes.bool
};