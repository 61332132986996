export const SPECIFY_RADIUS = 'Specify radius';
export const DRAW_ON_MAP = 'Draw on map';
export const VOICE_CALL = "Voice Call";
export const CHECK_CALL = "Check Call Update";
export const TEXT_MESSAGE = "Text Message";
export const ON_SITE_STATUSES = [4, 5, 6, 8, 9, 10];
export const MOVE_THRESHOLD_IN_MILES = 0.5;
export const KILOMETERS_PER_MILE = 1.609344;
export const METERS_PER_KILOMETER = 1000.0;
export const MILLISECONDS_IN_24_HOURS = 1000 * 60 * 60 * 24;
export const NUMBER_OF_MILLISECONDS_IN_30_DAYS = 1000 * 60 * 60 * 24 * 30;
export const ITEMS_PER_PAGE_DEFAULT = 10;
export const usCenter = {lat:39.8283, lng: -98.5795};
export const GOOGLE_MAP_ICONS = {
    'blue': "/assets/img/icons/blue.png",
    'blue-L': "/assets/img/icons/blue-L.png",
    'orange': "/assets/img/icons/orange.png",
    'pink': "/assets/img/icons/pink.png",
    'purple': "/assets/img/icons/purple.png",
    'purple-D': "/assets/img/icons/purple-D.png",
    'green': "/assets/img/icons/green.png",
    'green-dot': "/assets/img/icons/green-dot.png",
    'red-dot': "/assets/img/icons/red-dot.png",
    'yellow-dot': "/assets/img/icons/yellow-dot.png",
};
export const assetTypeOptions = [{value:"trailer",label:"Trailers"},{value:"tractor",label:"Tractors"},{value:"driver",
    label:"Drivers"}];
export const assetTypeDefaults = [{value:"trailer",label:"Trailers"},{value:"tractor",label:"Tractors"}];
