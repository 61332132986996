import React, { Component } from "react";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { convertCtoF, formatDistanceFromDomicile, formatMoveFrequency } from "../../common/utilities";
import { MILLISECONDS_IN_24_HOURS } from "../../common/constants";
import Link from "react-router-dom/Link";
import { BrowserRouter as Router } from "react-router-dom";



class EZTrackAssetMapPinPopup extends Component {
  formatCurrentAddress(asset) {
    if (asset == undefined) {
      // for some reason, render is being called when the popup is closed and the asset is false
      return ["No Address Found"];
    }
    if ((asset.latestEventAddress && asset.latestEventAddress !== "Unknown")
      || (asset.latestEventCity && asset.latestEventCity !== "Unknown")
      || (asset.latestEventState && asset.latestEventState !== "Unknown")
      || (asset.latestEventPostal && asset.latestEventPostal !== "Unknown")) {
      let address = asset.latestEventAddress ? asset.latestEventAddress : "Unknown address";
      let city = asset.latestEventCity ? asset.latestEventCity : "Unknown city";
      let state = asset.latestEventState ? asset.latestEventState : "Unknown state";
      let postal = asset.latestEventPostal ? asset.latestEventPostal : "";
      return [address, city + ", " + state + " " + postal];
    } else {
      return ["No Address Found"];
    }
  }
  formatIdleTime(asset) {
    if (asset.dateOfLastMove) {
      let movingDay = new Date(asset.dateOfLastMove);
      return new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format((new Date().getTime() - movingDay.getTime()) / MILLISECONDS_IN_24_HOURS);
    } else {
      return null;
    }
  }
  formatDwellTime(asset) {
    if (asset.dwellTime) {
      let movingDay = new Date(asset.dwellTime);
      return new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format((new Date().getTime() - movingDay.getTime()) / MILLISECONDS_IN_24_HOURS);
    } else {
      return "Not Calculated";
    }
  }
  formatExcursionTime(asset) {
    if (asset.excursionTime) {
      let movingDay = new Date(asset.excursionTime);
      return new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format((new Date().getTime() - movingDay.getTime()) / MILLISECONDS_IN_24_HOURS);
    } else {
      return "Not Calculated";
    }
  }
  render() {
    return (
      <>
        {this.props.asset.mostRecentEventShipmentName &&
          <p><b>Shipment:</b>&nbsp;{this.props.asset.mostRecentEventShipmentName}</p>
        }
        <p><b className={"mr-2"}>Asset ID:</b>
          <Router>
            <Link className={"text-underline"} to={"asset-profile/" + this.props.asset.uuid}>{this.props.asset.assetId}</Link>

          </Router>
        </p>
        {this.props.asset.devices && this.props.asset.devices.map((d, idx) => {
          return d.temperatureInC ?
            <p><b>Zone {idx + 1} Temperature:</b> {new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
              .format(convertCtoF(d.temperatureInC))} ℉</p> : null
        })}
        {this.props.asset.locationName && <p><b>Location:</b> {this.props.asset.locationName}</p>}
        <p><b>Last Reported Address:</b><br />
          {this.formatCurrentAddress(this.props.asset)[0]}
          <br />{this.formatCurrentAddress(this.props.asset)[1]}</p>
        <p><b>Dwell Time:</b> {this.formatDwellTime(this.props.asset)}</p>
        <p><b>Distance From Domicile:</b> {formatDistanceFromDomicile(
          this.props.asset
            ? this.props.asset.distanceFromDomicileInMeters : 0,
          this.props.asset.owner ?
            this.props.asset.owner.distanceUnitsOfMeasure : "Miles")}</p>
        <p><b>Excursion Time:</b> {this.formatExcursionTime(this.props.asset)}</p>
        <p><b>Report Time:</b>&nbsp;
          <MomentDisplay date={this.props.asset.latestEventDate}
            timezone={Intl.DateTimeFormat().resolvedOptions().timeZone} /></p>
        {this.props.asset.dateOfLastMove ?
          <p><b>Last Move:</b>&nbsp;
            <MomentDisplay date={this.props.asset.dateOfLastMove}
              timezone={Intl.DateTimeFormat().resolvedOptions().timeZone} /></p>
          : <p><b>Last Move:</b>&nbsp;</p>
        }
        <p><b>Idle Time:</b> {this.formatIdleTime(this.props.asset)}</p>
        <p><b>Move Frequency:</b> {formatMoveFrequency(this.props.asset.movesInLast30Days)}</p>
      </>
    )
  }
}
export default EZTrackAssetMapPinPopup;