import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {promiseTractorByCarrierAndIdPrefix} from "../../NetworkHelpers";
import FormTextField from "../../common/FormTextField";
import FormSelectStateField from "../../common/FormSelectStateField";
import * as PropTypes from "prop-types";
import * as uuid from "uuid";
import {promiseReturningEmptyList} from "../../common/utilities";
import FormSelectCreatableField from "../../common/FormSelectCreatableField";


export class TractorCard extends Component {
    render() {
        return <Card>
            <CardHeader>
                <div className="form-section-title">
                    <Button onClick={this.props.onClick}>
                        <i className={this.props.className}/>
                    </Button> Tractor
                </div>
            </CardHeader>
            <CardBody>
                <FormSelectCreatableField
                    key={"ID_"+uuid.v4()}
                    defaultOptions
                    label="Tractor Number"
                    subLabel="(Start typing to lookup tractor)"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-hashtag"
                    fieldname={"tractorNumber"}
                    errors={this.props.errors}
                    touched={this.props.touched}
                    values={this.props.values}
                    setFieldTouched={this.props.fieldTouched}
                    setFieldValue={this.props.fieldValue}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    getOptionLabel={this.props.optionLabel}
                    getOptionValue={this.props.optionLabel}
                    loadOptions={ this.props.values && (this.props.values["carrier"]) ?
                        promiseTractorByCarrierAndIdPrefix(this.props.values["carrier"]) :
                        promiseReturningEmptyList()}
                    onChange={(v)=>{
                        let values;
                        if(!v) {
                            v ={}
                        }
                        values = Object.assign({}, this.props.values);
                        values.tractorNumber = v.assetId;
                        values.tractorAssetId = v.assetId;
                        values.tractorUuid = v.uuid;
                        if(!this.props.disableFormik){
                            this.props.fieldValue("tractorNumber", v.assetId);
                            this.props.fieldValue("tractorUuid", v.uuid);
                        }
                        this.props.onChange && this.props.onChange(this.props.values);
                    }}
                    isDisabled={this.props.isDisabled}
                />
                {this.props.sectionOpen ?
                    <FormTextField
                        label="License Plate"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="tractorLicense"
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={(e, v)=>{
                            this.props.values['tractorLicense'] = e.target.value;
                            // this.props.handleChange(e,v);
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched}
                        values={this.props.values}
                        setFieldTouched={this.props.fieldTouched}
                        setFieldValue={this.props.fieldValue}
                    /> : ""}
                {this.props.sectionOpen ?
                    <FormSelectStateField
                        label="License State"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="tractorLicenseState"
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        touched={this.props.touched}
                        values={this.props.values}
                        setFieldTouched={this.props.fieldTouched}
                        setFieldValue={this.props.fieldValue}
                        // formikOverride={this.props.formikOveride+".tractorLicenseState"}
                        onChange={(v)=>{
                            console.log("State Change", v);
                            this.props.values['tractorLicenseState'] = v.label;
                            this.props.onChange(this.props.values);
                        }}
                    /> : ""}
            </CardBody>
        </Card>;
    }
}

TractorCard.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.func,
    onChange: PropTypes.func,
    sectionOpen: PropTypes.any,
    tractorNumberFieldName: PropTypes.any
};