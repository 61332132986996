import React, {Component} from "react";
import {Button, Col, Row} from "reactstrap"
import {
    downloadDispatchsForTable,
    getRemindAll, requestVisitsToShipmentsExcel,
    saveNote,
    sendDispatchAll,
    sendDispatchSms
} from "../../NetworkHelpers";
import 'react-table/react-table.css'
import '../../ReactTable.scss';
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import {withRouter} from 'react-router';
import {storeItemsPerPage} from "../../assets/actions/Assets";
import connect from "react-redux/es/connect/connect";
import {ROLE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import {
    endOfDay,
    getLocalStorageName,
    getTableSize,
    startOfDay
} from "../../common/utilities";
import Switch from "react-switch";
import DatePicker from "react-datepicker/es";
import moment from "moment-timezone";
import RemindAllModal from "./RemindAllModal";
import SendSMSModal from "../actions/SendSMSModal";
import Config from "../../Config";
import {showError, showInfo} from "../../MainApp";
import NoteModal from "../../assets/components/NoteModal";

class Dispatches extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.statusFilterRef = React.createRef();
        this.dateFilterField=React.createRef();
        this.state={
            loadCheck : true
        };
        let localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(props.stateStorage, props.user)), this.mapFromJson);
        if (localStorageState != null) {
            this.state = localStorageState;
            
        } else {
            this.state = {
                dateFilterEnabled : true,
               
                previousSearchRequest: {},
                createStart: startOfDay(new Date()) - 1,
                createEnd: endOfDay(new Date()),
                baseFilter: [{id: 'archived', value: false}]
            };
        }
       
        if (!this.state.createEnd) {
            this.state.createEnd = endOfDay(new Date());
        }
        if (!this.state.createStart) {
            this.state.createStart = startOfDay(new Date()) -1;
        }

        if(this.state.dateFilterField === undefined || !this.state.dateFilterField){
            this.state.dateFilterField = "created";
        }
        
       
        this.state.tableSize = getTableSize("dispatches-page", "dispatches-page-header");

        console.log('STATE', this.state);
        this.setBaseFilter();
        setTimeout(this.changeState(),3000);
    }
    changeState(){
        this.state.loadCheck= false;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateTableSize.bind(this));
        localStorage.setItem(getLocalStorageName(this.props.stateStorage, this.props.user), JSON.stringify(this.state));
    }

    componentDidMount() {
        window.scrollTo(0,0);
        window.addEventListener('resize', this.calculateTableSize.bind(this));
        // this.setBaseFilter();
        this.calculateTableSize();
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        if(isTablet === true){
            const datePickers = document.getElementsByClassName("react-datepicker__input-container");
            Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
        }
    }

    formatAdditionalReferences(refs, startIndex) {
        if (!refs || refs.length === startIndex) {
            return null;
        }
        let contents = [];
        for (let i=startIndex; i < refs.length; i++) {
            contents = contents.concat([refs[i].value + " (" + refs[i].type] + ")");
        }
        let uniqueContents = contents.filter((item, index, origArray) => origArray.indexOf(item) === index)
        return uniqueContents.map((r) => <>{r}<br/></>);
    }

    calculateTableSize = () => {
        let size = getTableSize("dispatches-page", "dispatches-page-header");
        this.setState({tableSize: {height: size.height, width: size.width}});
    }

    openSmsModal(uuid, driverFirstName, driverLastName, defaultReferenceLabel, shipmentNumber, driverPhoneNumber, reminders) {
        this.setState({showingSmsModal: true,
            smsModalUuid: uuid,
            driverFirstName: driverFirstName,
            driverLastName: driverLastName,
            defaultReferenceLabel: defaultReferenceLabel,
            shipmentNumber: shipmentNumber,
            userFirstName: this.props.user.firstname,
            driverPhoneNumber: driverPhoneNumber,
            remindersForModal: reminders
        });
    }

    closeSmsModal() {
        this.setState({showingSmsModal: false, smsModalUuid: false, messageModalError: "", remindersForModal: []});
    }

    setBaseFilter() {
        let baseFilter = [{id: 'archived', value: this.state.showArchive}];
        if (this.state.dateFilterEnabled) {
           if(this.state.createStart) {
               baseFilter = baseFilter.concat({id: 'createStart', value: this.state.createStart});
           }
            if(this.state.createEnd) {
                baseFilter = baseFilter.concat({id: 'createEnd', value: this.state.createEnd});
            }
            if(this.state.dateFilterField) {
                baseFilter = baseFilter.concat({id: 'dateFilterField', value: this.state.dateFilterField});
            }
        }
        this.setState({baseFilter: baseFilter});
    }

    openNoteModal(uuid, assetId, notes){
        this.setState({showingNoteModal: true, noteModalUuid: uuid, noteModalAssetId: assetId, notesForModal: notes});
    }

    closeNoteModal(){
        this.setState({showingNoteModal: false, noteModalUuid: false, notesForModal: {}});
    }

    archivedFilter() {
        return <>
            <div>
                <div className="filter-switch" title="Show Only Cancelled Dispatches">
                    <label>Show Cancelled</label>
                    <Switch
                        onChange={(checked) => {
                            this.setState({showArchive: !this.state.showArchive}, () => {
                                this.setBaseFilter();
                            });
                        }}
                        disabled ={this.state.loadCheck}
                        checked={this.state.showArchive}
                        uncheckedIcon={<div className="text-center pt-1"><i className="fas fa-archive"/></div>}
                        checkedIcon={<div className="text-center pt-1"><i className="fas fa-archive"/></div>}
                        title="Show Filter"
                        className="react-switch"/>
                </div>
            </div>
        </>
    }

    render() {
        if (!this.props.currentLocation && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN])) {
            this.props.history.push("/admin");
            return <div/>;
        }

        let threshold = 1;
        let thresholdUnit = "Day";
        if(this.props.user.company
            && this.props.user.company.eventNotificationSettings
            && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates
            && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.value){
            threshold = this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.value;
            thresholdUnit = this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.unit;
        }

        return (
            <div className="column-wrapper text-left" id="dispatches-page">
                <NoteModal
                    open={this.props.showingNoteModal}
                    uuid={this.props.noteModalUuid}
                    assetId={this.props.noteModalAssetId}
                    notes={this.props.notesForModal}
                    error={this.props.noteModalError}
                    onSave={(note, setErrors)=>{
                        saveNote(this.props.noteModalUuid, {note: note}, (data)=>{
                            this.tableRef.current.fetchData();
                            this.props.closeNoteModal();
                        }, (error) =>{
                            this.setState({noteModalError: error.message})
                        });
                    }}
                    onCancel={()=>{
                        this.props.closeNoteModal();
                    }}
                />
                <RemindAllModal
                    open={this.state.remindAllModalOpen}
                    onSave={(message)=>{
                        let assign = Object.assign({}, message);
                        if(!assign.message){
                            const defaultValue = this.props.user.company.eventNotificationSettings && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdatesMessageDefaultValue;
                            if(!defaultValue){
                                assign.message = "";
                            }else {
                                assign.message = defaultValue;
                            }
                        }
                        sendDispatchAll(assign, (response)=>{
                            this.setState({remindAllModalOpen: false});
                            showInfo(response.message);
                        }, (error)=>{
                            showError("Failed to send reminder")
                        });
                    }}
                    onCancel={()=>{
                        this.setState({remindAllModalOpen: false});
                    }}
                    characterLimit={Config.textMessageLengthLimit || 300}
                    threshold={threshold}
                    thresholdUnit={thresholdUnit}
                    user={this.props.user}
                    dispatches={this.state.remindAllDispatches}
                    defaultValue={this.props.user.company.eventNotificationSettings && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdatesMessageDefaultValue}
                >
                </RemindAllModal>
                <SendSMSModal
                    open={this.props.showingSmsModal}
                    uuid={this.props.smsModalUuid}
                    defaultReferenceLabel={this.props.defaultReferenceLabel}
                    driverFirstName={this.props.driverFirstName}
                    driverLastName={this.props.driverLastName}
                    shipmentNumber={this.props.shipmentNumber}
                    userFirstName={this.props.userFirstName}
                    sendSmsToPhone={this.props.driverPhoneNumber}
                    messages={this.props.remindersForModal}
                    error={this.props.messageModalError}
                    onSave={(message, setErrors)=>{
                        sendDispatchSms(this.props.smsModalUuid, message, (data)=>{
                            this.tableRef.current.fetchData();
                            this.props.closeSmsModal();
                        }, (error) =>{
                            this.setState({messageModalError: error.message})
                        });
                    }}
                    onCancel={()=>{
                        this.props.closeSmsModal();
                    }}
                />
                <div className="page-header" id="dispatches-page-header">
                    <h1>{this.props.title}</h1> <br/>
                    <Row>
                        <Col md={10}>
                            {this.props.showNewDispatchButton && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN]) ?
                                <Button onClick={() => this.props.history.push("new-dispatch")}>New Dispatch</Button> : ""}
                        </Col>
                        <Col md={2}>
                            {this.props.user.company
                            && this.props.user.company.eventNotificationSettings
                            && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates
                            && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN]) &&
                                <div className={"float-right"}
                                     title={"This will send a reminder text message with link to all active shipments where the shipment does not have a location update for the last " + this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.value + " " + this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.unit}>
                                    <Button className={"float-right"}
                                            onClick={() => {
                                                getRemindAll((response) => {
                                                    this.setState({
                                                        remindAllDispatches: response,
                                                        remindAllModalOpen: true
                                                    });
                                                });
                                            }}>Remind Drivers</Button>
                                </div>
                            }
                        </Col>
                    </Row>
                </div>
                <PaginationFilterTable
                    showEditColumns
                    style={{
                        maxHeight: this.state.tableSize.height,
                        maxWidth: this.state.tableSize.width,
                        minHeight: "120px",
                    }}
                    footerButton={
                        this.props.showExportButton && <Button color="secondary" onClick={() => this.props.onExport && this.props.onExport(this.state.previousSearchRequest)}><i className="fa fa-file-excel"/> Export to Excel</Button>
                    }
                    user={this.props.user}
                    openFiltersCollaps = {true}
                    ref={this.tableRef}
                    get={downloadDispatchsForTable}
                    baseFilter={this.state.baseFilter}
                    stateStorage={this.props.stateStorage}
                    columns={this.props.tableColumns}
                    itemsPerPage={this.state.itemsPerPage}
                    page={this.state.page}
                    filtered={this.state.filtered}
                    onFilteredChange={(filter) => {
                        this.setState({filtered: filter}, () => {this.tableRef.current.requestPageSearch()});
                    }}
                    hideFilters={this.state.hideFilters}
                    additionalFilter={this.archivedFilter()}
                    onChange={(state) => {
                        let statusFilter = state.filtered.filter(f => f.id === 'status');
                        this.setState({hideFilters: state.hideFilters, itemsPerPage: state.itemsPerPage,
                            statusFilter: statusFilter[0] ? statusFilter[0].value : undefined})
                    }}
                    clearFilter={(resetAll) => {
                        if(this.statusFilterRef.current){
                            this.statusFilterRef.current.value = undefined;
                        }
                        if (resetAll) {
                            this.setState({dateFilterEnabled: false, filtered: []}, () => {
                                this.setBaseFilter();
                                // this.tableRef.current.restoreColumns(true);
                                this.tableRef.current.requestPageSearch();
                            });
                        } else {
                            let filters = [];
                            if (this.state.filtered) {
                                filters = this.state.filtered.filter((f) => f.id === 'createStart' || f.id === 'createEnd');
                            }
                            this.setState({filtered: filters, statusFilter: undefined}, () => this.tableRef.current.requestPageSearch());
                        }
                    }}
                >
                    <div className="filter-switch" title="Show between dates">
                        <Switch
                            onChange={(checked) => {
                                this.setState({dateFilterEnabled: !this.state.dateFilterEnabled}, () => {
                                    this.setBaseFilter();
                                });
                            }}
                            checked={this.state.dateFilterEnabled}
                            uncheckedIcon={<div className="text-center pt-1"><i className="fas fa-calendar"/></div>}
                            checkedIcon={<div className="text-center pt-1"><i className="fas fa-calendar"/></div>}
                            title="Show Filter"
                            className="react-switch"/>
                        <label style={{width: "75px", marginLeft: "10px"}}>Between</label>&nbsp;
                        <DatePicker
                            name={this.props.fieldname}
                            className={"dispatch-list-date-picker " + (this.props.touched && !!this.props.error ? "is-invalid" : "")}
                            valid={!this.props.error}
                            invalid={this.props.touched && !!this.props.error}
                            onBlur={this.props.onBlur}
                            onChange={(v) => {
                                this.setState({createStart: v, dateFilterEnabled: true}, () => this.setBaseFilter());
                            }}
                            selected={moment(this.state.createStart).toDate()}
                            disabled={this.props.isDisabled}
                            isDisabled={this.props.isDisabled}
                            onFocus={(e) => e.target.select()}
                        />&nbsp;
                        <label style={{width: "75px", marginLeft: "10px"}}>and</label>&nbsp;
                        <DatePicker
                            name={this.props.fieldname}
                            className={"dispatch-list-date-picker " + (this.props.touched && !!this.props.error ? "is-invalid" : "")}
                            valid={!this.props.error}
                            invalid={this.props.touched && !!this.props.error}
                            onBlur={this.props.onBlur}
                            onChange={(v) => {
                                v.setHours(23);
                                v.setMinutes(59);
                                v.setSeconds(59);
                                v.setMilliseconds(999);
                                this.setState({createEnd: v, dateFilterEnabled: true}, () => this.setBaseFilter());
                            }}
                            selected={moment(this.state.createEnd).toDate()}
                            disabled={this.props.isDisabled}
                            isDisabled={this.props.isDisabled}
                            onFocus={(e) => e.target.select()}
                        />
                        <select
                            disabled={!this.state.dateFilterEnabled}
                            ref={this.dateFilterField}
                            onChange={event => {
                                this.setState({dateFilterField: event.target.value}, ()=>{
                                    this.setBaseFilter();
                                    localStorage.setItem(getLocalStorageName(this.props.stateStorage, this.props.user), JSON.stringify(this.state));
                                    this.tableRef.current.requestPageSearch();
                                })
                            }}
                            value={this.state.dateFilterField}
                        >
                            <option value="undefined" hidden={true}>Select</option>
                            <option value="created">Dispatch Creation Time</option>
                            <option value="latestShipmentStatusEvent.date">Report Time</option>
                            <option value="currentStopPlan.firstStop.appointment">First Stop Appt</option>
                            <option value="currentStopPlan.lastStop.appointment">Final Stop Appt</option>
                        </select>
                    </div>
                </PaginationFilterTable>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        itemsPerPage: state.assets.itemsPerPage,
        currentLocation: state.login.location,
        user: state.login.user
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setItemsPerPage: function(itemsPerPage) {
            dispatch(storeItemsPerPage(itemsPerPage));
        }
    });
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dispatches));