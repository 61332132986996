import React, { Component } from "react";
import { CardHeader, Col, Form, Modal, ModalBody, ModalHeader, Row, Collapse } from "reactstrap";
import Switch from "react-switch";
import ReactTable from "react-table";
import { filterCaseInsensitiveContains, getLocalStorageName, restoreTableState, saveTableState } from "./utilities";
import { Pagination } from "./Pagination";
import * as PropTypes from "prop-types";
import { ITEMS_PER_PAGE_DEFAULT } from "./constants";
import Container from "reactstrap/lib/Container";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import { RefreshReload } from "./RefreshReload";
import { showError } from "../MainApp";
import Button from "reactstrap/lib/Button";
import FFFormCheckboxField from "./formik-free/FFFormCheckboxField";

class EditColumns extends Component {

  moveCol(index, destinationIndex) {
    let cols = this.props.columns;

    // let check = cols.findIndex(it=> it.hideFirst === true);
    // if(check && check === index){

    // }


    let swap = cols.splice(index, 1)[0];
    cols.splice(destinationIndex, 0, swap);
    this.checkDisable();
    this.props.onChange && this.props.onChange(cols);

  }
  checkDisable() {
    this.props.columns.map(it => it.hideFirst = false);
    let v = this.props.columns.findIndex(it => it.disableControls === true);
    if (v > -1) {
      this.props.columns[v + 1].hideFirst = true;
    } else {
      if (this.props.columns.length > 0) {
        this.props.columns[0].hideFirst = true;
      }
    }
  }
  render() {
    this.checkDisable();
    return <>
      <div>
        <Modal isOpen={this.props.open} size={'lg'}>
          <ModalHeader>
            Edit Columns
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => {
              this.onSubmit();
              e.preventDefault();
            }}>
              <Card className="simple-card">
                <CardHeader></CardHeader>
                <CardBody>
                  <Container>
                    {this.props.columns.map((col, index) => {

                      return <>
                        <Row className={col.hidden ? col.hidden === true ? "d-none" : "" : ""}>
                          <Col sm={1}>
                            {col.disableControls === undefined && col.hideFirst !== true &&
                              <Button onClick={(e) => {
                                e.preventDefault();
                                this.moveCol(index, --index);
                              }}><i className={"fa fa-arrow-up"} /></Button>}
                          </Col>
                          <Col sm={1}>
                            {index !== this.props.columns.length - 1 && col.disableControls === undefined &&
                              <Button onClick={(e) => {
                                e.preventDefault();
                                this.moveCol(index, ++index);
                              }}><i className={"fa fa-arrow-down"} /></Button>}
                          </Col>
                          <Col className={"text-left"} sm={4}>
                            {col.disableControls !== undefined &&
                              <FFFormCheckboxField
                                value={col.show}
                                disabled
                                onChange={(show) => {
                                  col.show = show;
                                  this.props.onChange && this.props.onChange(this.props.columns);
                                }}
                              />
                            }
                            {col.disableControls == undefined &&
                              <FFFormCheckboxField
                                value={col.show}
                                onChange={(show) => {
                                  col.show = show;
                                  this.props.onChange && this.props.onChange(this.props.columns);
                                }}
                              />
                            }
                          </Col>
                          <Col sm={6}>
                            {col.assetId ? this.props.assetId : col.cleanHeader} {col.default ? "*" : ""}
                          </Col>
                        </Row>

                      </>;
                    })}
                  </Container>
                </CardBody>
              </Card>
              <div className="mb-3 text-right" style={{ marginTop: "5px" }}>
                <Button color="danger" style={{ marginRight: "5px" }} onClick={this.props.onCancel} type="submit">Cancel</Button>
                <Button color="secondary" style={{ marginRight: "5px" }} onClick={this.props.onRestoreDefault} type="submit">Restore Defaults</Button>
                <Button color="primary" onClick={this.props.onClose}>Save</Button> &nbsp;
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </>;
  }
}

export class PaginationFilterTable extends Component {
  constructor(props) {

    super(props);
    let localStorageState = null;
    if (props.stateStorage) {
      localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(props.stateStorage + '-pft', props.user)));
    }
    if (props.pageType !== "Admin" && props.pageType !== "poweryard-settings" && props.pageType !== "assets-history") {
      if (localStorageState === null) {
        this.restoreColumns();
        localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(props.stateStorage + '-pft', props.user)));
      }
    }
    if (localStorageState != null) {

      this.state = restoreTableState(localStorageState);
      if (localStorageState.columns !== undefined) {
        let fullColumns = [];
        if (props.columns && (localStorageState.columns.length !== props.columns.length)) {
          this.state.columns = props.columns;
        } else {
          for (const storedColumn of localStorageState.columns) {
            if (props.columns) {
              for (const propColumn of props.columns) {
                if (storedColumn.accessor === propColumn.accessor) {
                  propColumn.show = storedColumn.show;
                  fullColumns.push(propColumn);
                }
              }
            }
          }
          this.state.columns = fullColumns;
        }
      }
    } else {

      this.state = {
        page: props.page ? props.page : 0,
        itemsPerPage: props.itemsPerPage ? props.itemsPerPage : ITEMS_PER_PAGE_DEFAULT,
        pageSize: props.itemsPerPage ? props.itemsPerPage : ITEMS_PER_PAGE_DEFAULT,
        hideFilters: props.hideFilters != undefined ? props.hideFilters : true,
        sorted: props.sorted ? props.sorted : props.defaultSort ? props.defaultSort : [],
        filtered: props.filtered ? props.filtered : props.baseFilter ? props.baseFilter : [],
        pageCount: 0,
        autoRefreshEnabled: props.autoRefreshEnabled ? props.autoRefreshEnabled : false,
        filterOpen: props.openFilters,
        columns: props.columns,
        disableFilters: false,
      };
    }
    // autoRefreshInterval: props.autoRefreshInterval ? props.autoRefreshInterval : 20000,
    // if (props.autoRefreshEnabled === undefined || props.autoRefreshEnabled === null ) {
    //     this.state.autoRefreshEnabled = false;
    // }
    if (props.openFilters) {
      this.state.filterOpen = false;
    }
    this.searchInterval = setInterval(() => {

      if (this.state.autoRefreshEnabled) {
        this.fetchData();
      }
    },
      this.props.autoRefreshInterval ? this.props.autoRefreshInterval : 20000
    );
    if (this.state.dateFilters == null) {
      this.state.dateFilters = new Map();
    }


    if (!this.state.columns) {
      this.state.columns = props.columns;
    }
    if (!this.state.disableFilters) {
      this.state.disableFilters = false;
    }
    this.setColumnsToModel();
  }
  setColumnsToModel() {
    this.state.columns = this.state.columns.map((col) => {
      let x = col.Header;
      if (col.show === undefined) {
        col.show = true;
      }
      if (!col.cleanHeader) {
        col.cleanHeader = x;
      }

      if (col.sortable) {
        col.Header = (obj) => {
          if (typeof x === 'function') {
            return (
              <div title={col.title}><i className="fa fa-sort" title={col.title} /> {x()}</div>
            );
          } else {
            return (
              <div title={col.title}><i className="fa fa-sort" title={col.title} /> {x}</div>
            );
          }
        }
      }

      if (col.title && !col.sortable) {
        col.Header = (obj) => {
          if (typeof x === 'function') {
            return (
              <div title={col.title}>{x()}</div>
            );
          } else {
            return (
              <div title={col.title}>{x}</div>
            );
          }
        }
      }

      return col;
    })
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.onTaskHistory == true) {

      this.setState({ pageCount: 0, pages: 0 });
    }
    if (this.props.onTaskHistory != true) {
      this.fetchData();

    }
  }

  // prevent search on every key stroke.
  requestPageSearch() {

    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
    this.searchTimer = setTimeout(() => {
      this.searchTimer = false;
      this.fetchData();
    }, 500);
  }
  refreshColumns() {
    let localStorageState = null;
    let cols = [];
    localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(this.props.stateStorage + '-pft', this.props.user)));
    if (localStorageState != null) {
      // this.state = restoreTableState(localStorageState);
      if (localStorageState.columns !== undefined) {
        let fullColumns = [];
        localStorageState.columns[0].show = this.props.columns[0].show;
        if (this.props.columns && (localStorageState.columns.length !== this.props.columns.length)) {
          this.setState({

            columnsD: this.props.columns
          });
        } else {
          for (const storedColumn of localStorageState.columns) {
            if (this.props.columns) {
              for (const propColumn of this.props.columns) {
                if (storedColumn.accessor === propColumn.accessor) {
                  propColumn.show = storedColumn.show;
                  fullColumns.push(propColumn);
                }
              }
            }
          }

          this.setState({

            columnsD: fullColumns
          });
        }
      }
    } else {
      this.setState({

        columnsD: this.props.columns
      });
    }

    this.setState({ columns: this.state.columnsD }, () => {
      saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    })


    // this.setState({columns: this.state.columnsD }, () => {
    //     // this.fetchData();
    //     saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    // })


  }

  fetchData() {
    let filters = this.props.filtered ? this.props.filtered : this.state.filtered;
    this.props.baseFilter && this.props.baseFilter.forEach((f) => {
      if (filters.filter((e) => { return e.id === f.id }).length === 0) {
        filters = filters.concat(f); // add if not already found
      } else { // update in case value changed by removing then concatenating
        filters = filters.filter((e) => { return e.id !== f.id }).concat(f);
      }
    });
    let searchRequest = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      sorted: this.props.sorted ? this.props.sorted : this.state.sorted,
      filtered: filters,

    };
    if (this.props.taskId) {
      searchRequest = {
        page: this.state.page,
        pageSize: this.state.pageSize,
        sorted: this.props.sorted ? this.props.sorted : this.state.sorted,
        filtered: filters,
        uuid: this.props.taskId.value
      };
    }
    this.props.additionalSearchCriteria && Object.assign(searchRequest, this.props.additionalSearchCriteria);
    let integerColumns = this.props.columns.filter(it => it.integerInput === true);
    let searchAllow = false;

    if (integerColumns.length > 0) {
      for (let index = 0; index < filters.length; index++) {
        if (filters.length > 0) {
          let obj = integerColumns.find(it => it.accessor === filters[index].id);
          if (obj) {
            let val = filters[index].value;
            if (isNaN(val)) {
              showError("This field must be an integer");
              this.setState({ errorBeforeSearch: true })
              this.props.setloaded && this.props.setloaded(false);
              break;
            } else if (val.includes(".") === true) {
              showError("This field must be an integer");
              this.setState({ errorBeforeSearch: true })
              this.props.setloaded && this.props.setloaded(false);
              break;
            }
          }
        }
        if (index === filters.length - 1) {
          searchAllow = true;
        }
      }

      if (this.state.fetched == true && this.state.errorBeforeSearch !== true) {

        this.props.setloaded && this.props.setloaded(true);
      }

      if (searchAllow === true) {
        this.props.get(searchRequest,
          (success) => {
            this.props.setloaded && this.props.setloaded(false);
            this.setState({ fetched: true });
            if (success.code === "200") {

              this.setState({
                disableFilters: false,
                pages: success.content.totalPages,
                tableData: success.content.results,
                loading: false,
                pageCount: success.content.totalPages
              });
              success.content.request = searchRequest;
              this.props.successCallback && this.props.successCallback(success.content)
              if (this.state.page > success.content.totalPages) {
                this.setState({ page: 0, forcePage: 0 });
              }
            }
            else if (success.code === "1000") {
              showError(success.message);
              this.setState({
                loading: false,
              })
            }
            else {
              showError(success.message)
              this.setState({
                loading: false,
              })
            }
          },
          (failure) => {
            this.props.setloaded && this.props.setloaded(false);
            if (!failure.hideToast) {
              showError("Could not load data");
            }
            this.props.failureCallback && this.props.failureCallback(failure)
          });
      }
    } else {
      if (this.state.fetched == true && this.state.errorBeforeSearch !== true) {

        this.props.setloaded && this.props.setloaded(true);
      }
      this.props.onTaskHistory && this.props.setloaded && this.props.setloaded(true);

      this.props.get(searchRequest,
        (success) => {
          this.props.setloaded && this.props.setloaded(false);
          this.setState({ fetched: true });

          if (success.code === "200") {
            this.setState({
              disableFilters: false,
              pages: success.content.totalPages,
              tableData: success.content.results,
              loading: false,
              pageCount: success.content.totalPages
            });
            success.content.request = searchRequest;
            this.props.successCallback && this.props.successCallback(success.content)
            if (this.state.page > success.content.totalPages) {
              this.setState({ page: 0, forcePage: 0 });
            }
          }
          else if (success.code === "1000") {
            showError(success.message);
            this.setState({
              loading: false,
            })
          }
          else {
            showError(success.message)
            this.setState({
              loading: false,
            })

          }
        },
        (failure) => {
          this.props.setloaded && this.props.setloaded(false);
          if (!failure.hideToast) {
            showError("Could not load data");
          }
          this.props.failureCallback && this.props.failureCallback(failure)
        });

    }
  }

  componentWillUnmount() {
    saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    clearInterval(this.searchInterval);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(this.state.disableFilters, "Disable filters");
    if (!this.arraysAreEqual(this.props.baseFilter, prevProps.baseFilter) ||
      this.props.additionalSearchCriteria !== prevProps.additionalSearchCriteria) {
      this.fetchData();
    }
  }
  arraysAreEqual(l, r) {
    if (l === r) {
      return true;
    }
    if (!r || !l) {
      return false;
    }
    if (l.length !== r.length) {
      return false;
    }
    for (let i = 0; i < l.length; i++) {
      if (!this.objectAreEqual(l[i], r[i])) {
        return false;
      }
    }
    return true;
  }
  objectAreEqual(l, r) {
    let lProps = Object.getOwnPropertyNames(l);
    let rProps = Object.getOwnPropertyNames(r);
    if (lProps.length !== rProps.length) {
      return false;
    }
    for (let i = 0; i < lProps.length; i++) {
      let lPropName = lProps[i];
      if (l[lPropName] !== r[lPropName]) {
        return false;
      }
    }
    return true;
  }
  getModifiedColumns() {
    return this.state.columns.map(column => ({
      ...column,
      filterable: this.state.disableFilters ? false : column.filterable,
    }));
  }
  render() {

    if (!this.state.tableData && this.props.onTaskHistory != true) {
      return <div className="column-wrapper">
        <Modal isOpen={this.state.areYouSureOpen}>
          <ModalBody>
            <Row>
              <Col>
                <h3>Reset to default table view?</h3>
              </Col>
            </Row>
            <Row className={"text-right"}>
              <Col>
                <Button color="secondary" onClick={() =>
                  this.setState({
                    areYouSureOpen: false,
                  })
                }>No</Button>
                <Button color="primary" type="submit"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.setState({
                      areYouSureOpen: false,
                      hideFilters: true,
                      sorted: this.props.defaultSorted ? this.props.defaultSorted : [],
                      filtered: this.props.baseFilter ? this.props.baseFilter : [],
                      page: 0,
                      forcePage: 0,
                    }, () => {
                      this.requestPageSearch();
                      this.props.onChange && this.props.onChange(this.state);
                      this.props.clearFilter && this.props.clearFilter(true);
                    })
                  }}>Yes</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <EditColumns
          assetId={(this.props.user && this.props.user.company && this.props.user.company.shipmentIdDisplay) || "Asset ID"}
          open={this.state.editColumnsOpen}
          columns={this.state.editColumns || []}
          onChange={(cols) => {
            this.setState({ editColumns: cols });
          }}
          onRestoreDefault={(e) => {
            e.preventDefault();
            this.restoreColumns();
          }}
          onCancel={(e) => {
            e.preventDefault();
            this.setState({ editColumnsOpen: false, columns: this.state.columns }, () => {
              this.fetchData();
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }}
          onClose={() => {
            this.setState({ editColumnsOpen: false, columns: this.state.editColumns }, () => {

              this.fetchData();
              saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
            })
          }} />
        <Collapse isOpen={this.props.openFiltersCollaps}>
          <div className="table-action-bar page-header d-none" id="pft-action-bar-header" style={
            this.props.hideFilter && { borderTop: 0 }
          }>
            <Row>
              <Col sm="7">
                {this.props.children}
              </Col>
              <Col sm="5" className="filter-box">
                <div className="flex-grow-1" />
                {this.props.additionalFilter}
                {this.props.showEditColumns &&
                  <Button style={{ marginLeft: "5px" }} onClick={() => {
                    let cols = [];
                    let s = [...this.state.columns];
                    s.forEach((column) => {
                      let clone = {};
                      for (let prop in column) {
                        if (column.hasOwnProperty(prop)) {
                          clone[prop] = column[prop];
                        }
                      }
                      cols.push(clone);
                    })
                    this.setState({ editColumnsOpen: true, editColumns: cols })
                  }}>Columns</Button>
                }
                <div className="filter-switch ml-3" title="Show Filter">
                  {!this.props.hideFilter && <div>
                    <label>Show Filter</label>
                    <Switch
                      onChange={(checked) => {
                        this.setState({ hideFilters: !checked }, () => {

                          if (this.props.filtered) {
                            this.props.onChange && this.props.onChange(this.state);
                            this.props.clearFilter && this.props.clearFilter();
                          } else {
                            if (this.state.hideFilters) {
                              this.setState({
                                filtered: this.props.baseFilter
                                  ? this.props.baseFilter : []
                              }, () => {
                                this.props.onChange && this.props.onChange(this.state);
                                this.props.clearFilter && this.props.clearFilter();
                              })
                            } else {
                              let filters = this.state.filtered;
                              this.state.dateFilters.forEach((f) => {
                                filters.push({ id: f.id, value: f.value, operator: f.operator });
                              });
                              this.setState({ filtered: filters }, () => this.requestPageSearch());
                              this.props.onChange && this.props.onChange(this.state);
                            }
                          }
                        });
                      }}
                      checked={!this.state.hideFilters}
                      uncheckedIcon={<div className="text-center pt-1"><i className="fa fa-filter" /></div>}
                      checkedIcon={<div className="text-center pt-1"><i className="fa fa-filter" /></div>}
                      title="Show Filter"
                      className="react-switch" />
                  </div>}
                </div>
                {!this.props.hideClear &&
                  <Button onClick={() => {
                    this.setState({
                      areYouSureOpen: true,
                    })
                  }} color='warning'> <i className="fa fa-undo" /></Button>}
              </Col>
            </Row>
          </div>
        </Collapse>
        <div className={'app align-items-center animated fadeIn ' + this.props.hideTableForAccessDenied && this.props.hideTableForAccessDenied === false ? "d-none" : "block"} >
          <Container>
            <Row className="justify-content-center">
              <Col md="12">
                <Card className="text-white bg-primary py-5 d-md-down-none" >
                  <CardBody className="text-center">
                    <div>
                      <h2>Loading Data...</h2>
                      {/* <h2>Please wait !! Loading Data, it will take time due to higher number of records...</h2> */}
                      <div className="sk-wave">
                        <div className="sk-rect sk-rect1" />&nbsp;
                        <div className="sk-rect sk-rect2" />&nbsp;
                        <div className="sk-rect sk-rect3" />&nbsp;
                        <div className="sk-rect sk-rect4" />&nbsp;
                        <div className="sk-rect sk-rect5" />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>



      </div>;
    } else {
      return (
        <div className="column-wrapper">
          <Modal isOpen={this.state.areYouSureOpen}>
            <ModalBody>
              <Row>
                <Col>
                  <h3>Reset to default table view?</h3>
                </Col>
              </Row>
              <Row className={"text-right"}>
                <Col>
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        areYouSureOpen: false,
                      })
                    }
                  >
                    No
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      this.setState(
                        {
                          areYouSureOpen: false,
                          hideFilters: true,
                          sorted: this.props.defaultSorted
                            ? this.props.defaultSorted
                            : [],
                          filtered: this.props.baseFilter
                            ? this.props.baseFilter
                            : [],
                          page: 0,
                          forcePage: 0,
                        },
                        () => {
                          this.requestPageSearch();
                          this.props.onChange &&
                            this.props.onChange(this.state);
                          this.props.clearFilter &&
                            this.props.clearFilter(true);
                        }
                      );
                    }}
                  >
                    Yes
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <EditColumns
            assetId={
              (this.props.user &&
                this.props.user.company &&
                this.props.user.company.shipmentIdDisplay) ||
              "Asset ID"
            }
            open={this.state.editColumnsOpen}
            columns={this.state.editColumns || []}
            onChange={(cols) => {
              this.setState({ editColumns: cols });
            }}
            onRestoreDefault={(e) => {
              e.preventDefault();
              this.restoreColumns();
            }}
            onCancel={(e) => {
              e.preventDefault();
              this.setState(
                { editColumnsOpen: false, columns: this.state.columns },
                () => {
                  this.fetchData();
                  saveTableState(
                    this.props.stateStorage + "-pft",
                    this.state,
                    this.props.user
                  );
                }
              );
            }}
            onClose={() => {
              let s = { ...this.state.editColumns };
              this.setState(
                { editColumnsOpen: false, columns: this.state.editColumns },
                () => {
                  this.fetchData();
                  saveTableState(
                    this.props.stateStorage + "-pft",
                    this.state,
                    this.props.user
                  );
                }
              );
            }}
          />
          <Collapse isOpen={this.props.openFiltersCollaps}>
            <div
              className="table-action-bar page-header"
              id="pft-action-bar-header"
              style={this.props.hideFilter && { borderTop: 0 }}
            >
              <Row>
                <Col sm="7">{this.props.children}</Col>
                <Col sm="5" className="filter-box">
                  <div className="flex-grow-1" />
                  {this.props.additionalFilter}
                  {this.props.showEditColumns && (
                    <Button
                      style={{ marginLeft: "5px" }}
                      onClick={() => {
                        // this.setColumnsToModel();
                        let cols = [];
                        let s = [...this.state.columns];
                        s.forEach((column) => {
                          let clone = {};
                          for (let prop in column) {
                            if (column.hasOwnProperty(prop)) {
                              clone[prop] = column[prop];
                            }
                          }
                          cols.push(clone);
                        });
                        this.setState({
                          editColumnsOpen: true,
                          editColumns: cols,
                        });
                      }}
                    >
                      Columns
                    </Button>
                  )}
                  <div className="filter-switch ml-3" title="Show Filter">
                    {!this.props.hideFilter && (
                      <div>
                        <label>Show Filter</label>
                        <Switch
                          onChange={(checked) => {
                            this.setState({ hideFilters: !checked }, () => {
                              this.props.setFilterToogle&&this.props.setFilterToogle(checked);
                              if (this.state.hideFilters === false) {
                                this.props.onFilterHide()
                              }
                              if (this.props.filtered) {
                                this.props.onChange &&
                                  this.props.onChange(this.state);
                                this.props.clearFilter &&
                                  this.props.clearFilter();
                              } else {
                                if (this.state.hideFilters) {
                                  this.setState(
                                    {
                                      filtered: this.props.baseFilter
                                        ? this.props.baseFilter
                                        : [],
                                    },
                                    () => {
                                      this.props.onChange &&
                                        this.props.onChange(this.state);
                                      this.props.clearFilter &&
                                        this.props.clearFilter();
                                    }
                                  );
                                } else {
                                  let filters = this.state.filtered;
                                  this.state.dateFilters.forEach((f) => {
                                    filters.push({
                                      id: f.id,
                                      value: f.value,
                                      operator: f.operator,
                                    });
                                  });
                                  this.setState({ filtered: filters }, () =>
                                    this.requestPageSearch()
                                  );
                                  this.props.onChange &&
                                    this.props.onChange(this.state);
                                }
                              }
                            });
                          }}
                          checked={!this.state.hideFilters}
                          uncheckedIcon={
                            <div className="text-center pt-1">
                              <i className="fa fa-filter" />
                            </div>
                          }
                          checkedIcon={
                            <div className="text-center pt-1">
                              <i className="fa fa-filter" />
                            </div>
                          }
                          title="Show Filter"
                          className="react-switch"
                        />
                      </div>
                    )}
                  </div>
                  {!this.props.hideClear && (
                    <Button
                      onClick={() => {
                        this.setState({
                          areYouSureOpen: true,
                        });
                      }}
                      color="warning"
                    >
                      {" "}
                      <i className="fa fa-undo" />
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Collapse>
          {this.props.renderModal}
          <div className="main-table-wrapper">
            <ReactTable
              manual
              showPagination={false}
              columns={this.state.columns}
              data={this.state.tableData ? this.state.tableData : []}
              resolveData={this.props.resolveData}
              loading={this.state.loading}
              page={this.state.page}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  const rowData = rowInfo.row._original;
                  return {
                    style: {
                      background: rowData.abnormalTask && rowData.abnormalTask === true ? '#ffe599' : 'inherit'
                    }
                  };
                }
                return {};
              }}
              pageSize={
                this.state.tableData && this.state.tableData.length
                  ? this.state.tableData.length
                  : 0
              }
              filtered={
                this.props.filtered ? this.props.filtered : this.state.filtered
              }
              onFilteredChange={(filter) => {
                if (this.props.filtered) {
                  this.setState({ page: 0, forcePage: 0 }, () => {
                    this.props.onFilteredChange &&
                      this.props.onFilteredChange(filter);
                  });
                } else {

                  this.setState(
                    { filtered: filter, page: 0, forcePage: 0 },
                    () => {
                      //   this.requestPageSearch();
                      console.log(filter, "hasBoxedInFilter1",this.state.filtered);

                      this.requestPageSearch();
                      // 4000 milliseconds = 4 seconds
                    }
                  );
                }
              }}
              defaultFiltered={
                this.state.filtered
                  ? this.state.filtered
                  : this.props.baseFilter
                    ? this.props.baseFilter
                    : []
              }
              sorted={this.getSorted()}
              onSortedChange={(sort) => {
                if (this.props.sorted) {
                  this.setState({ sortedChanged: true });
                  this.props.onSortedChange && this.props.onSortedChange(sort);
                } else {
                  this.setState({ sorted: sort }, () => {
                    this.requestPageSearch();
                  });
                }
              }}
              defaultSorted={
                this.props.defaultSorted ? this.props.defaultSorted : null
              }
              className="-striped -highlight"
              defaultFilterMethod={filterCaseInsensitiveContains}
              getTheadFilterProps={(state, rowInfo, column, instance) => {
                return {
                  style: this.state.hideFilters ? { display: "none" } : null,
                };
              }}
              getTheadFilterThProps={(state, rowInfo, column, instance) => {
                if (column.dataType === "date") {
                  return {
                    style: { overflow: "inherit" },
                  };
                } else {
                  return {};
                }
              }}
              style={
                this.props.style
                  ? this.props.style
                  : {
                    maxHeight: "100%", // This will force the table body to overflow and scroll, since there is not enough room
                    minHeight: "120px",
                  }
              }
              getTdProps={this.props.getTdProps}
              onResizedChange={this.props.onResizedChange}
            />
          </div>
          {this.props.showFooterSaveCloseButton &&
            this.props.showFooterSaveCloseButton && (
              <div
                className={"table-action-bar page-footer "}
                id="pft-action-bar-footer"
              >
                <Row>
                  <Col md="4"></Col>
                  <Col md="12">
                    <Row>
                      <Col md={6}>{this.props.footerCancelButton}</Col>
                      <Col md={6}>{this.props.footerSaveButton}</Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          <div
            className="table-action-bar page-footer"
            id="pft-action-bar-footer"
          >
            <Row>
              <Col md="8">
                <Row>
                  <Col md="4"></Col>
                  <Col md="12">
                    <Row>
                      <Col md={6}>{this.props.footerButton}</Col>
                      <Col md={6} className={"text-center"}>
                        <RefreshReload
                          checked={this.state.autoRefreshEnabled}
                          disableAutoRefresh={
                            this.props.disableAutoRefresh &&
                            this.props.disableAutoRefresh
                          }
                          disableAutoRefreshMsg={
                            this.props.disableAutoRefreshMsg &&
                            this.props.disableAutoRefreshMsg
                          }
                          onChange={(res) => {
                            let a = { ...this.state };
                            this.setState({
                              autoRefreshEnabled:
                                !this.state.autoRefreshEnabled,
                            });
                            this.props.RefreshReload &&
                              this.props.RefreshReload(res);
                          }}
                          onClick={() => {
                            this.fetchData();
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <div className="pagination-wrapper">
                  <Pagination
                    key={
                      this.state.itemsPerPage
                    } /* using key as itemsPerPage so component is
                                replaced when itemsPerPage changes which sets it to page 1 */
                    pageCount={this.state.pageCount}
                    initialPage={this.state.page}
                    forcePage={this.state.forcePage}
                    onPageChange={(page) => {
                      this.setState(
                        { page: page.selected, forcePage: undefined },
                        () => {
                          this.fetchData();
                        }
                      );
                    }}
                    specificPageSizes={this.props.specificPageSizes}
                    itemsPerPage={this.state.itemsPerPage}
                    onItemsPerPageChange={(e) => {
                      if (this.props.onTaskHistory == true) {
                        if (this.props.dateFilterEnabled == true) {

                          this.setState(
                            {
                              itemsPerPage: e.target.value,
                              page: 0,
                              pageSize: e.target.value,
                              tableData: undefined,
                            },
                            () => {
                              this.fetchData();
                            }
                          );
                        }
                        else {
                          this.setState({
                            itemsPerPage: e.target.value,
                            page: 0,
                            pageCount: 0,
                            pages: 0,
                            pageSize: e.target.value,
                            tableData: undefined,
                          });
                        }

                      } else {
                        this.setState(
                          {
                            itemsPerPage: e.target.value,
                            page: 0,
                            pageSize: e.target.value,
                            tableData: undefined,
                          },
                          () => {
                            this.fetchData();
                          }
                        );
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

  }
  changeAutoRefresh() {
    this.setState({
      autoRefreshEnabled: this.props.autoRefreshEnabled
    })
  }
  restoreColumns(directToState) {
    let cols = this.props.columns.map(col => ({ ...col }));

    // Log the initial state of columns
    console.log('Initial columns:', cols);

    for (const col of cols) {
        col.show = !!col.default;
        if (col.keepShowing && col.keepShowing === true) {
            col.show = true;
        }
    }

    console.log('Modified columns:', cols);

    const newState = directToState ? { columns: cols } : { editColumns: cols };

    console.log('State before setState:', this.state);
    console.log('New state to set:', newState);

    this.setState(newState, () => {
        console.log('State after setState:', this.state);
        saveTableState(this.props.stateStorage + "-pft", this.state, this.props.user);
    });
}


  getSorted() {
    let propOrState = this.props.sorted ? this.props.sorted : this.state.sorted;
    if (!propOrState) return [];
    return propOrState;
  }
}

PaginationFilterTable.propTypes = {
  onChange: PropTypes.func,
  renderModal: PropTypes.any,
  columns: PropTypes.arrayOf(PropTypes.any),
  baseFilter: PropTypes.array,
  additionalSearchCriteria: PropTypes.object,
  stateStorage: PropTypes.string,
  page: PropTypes.number,
  itemsPerPage: PropTypes.number,
  pageSize: PropTypes.number,
  hideFilter: PropTypes.bool,
  hideTableForAccessDenied: PropTypes.bool,
  hideFilters: PropTypes.bool,
  sorted: PropTypes.arrayOf(PropTypes.object),
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  onSortedChange: PropTypes.func,
  filtered: PropTypes.arrayOf(PropTypes.object),
  defaultFiltered: PropTypes.arrayOf(PropTypes.object),
  onFilteredChange: PropTypes.func,
  autoRefreshEnabled: PropTypes.bool,
  get: PropTypes.func,
  successCallback: PropTypes.func,
  RefreshReload: PropTypes.func,
  failureCallback: PropTypes.func,
  children: PropTypes.any,
  additionalFilter: PropTypes.any,
  clearFilter: PropTypes.func,
  resolveData: PropTypes.any,
  getTdProps: PropTypes.func,
  footerButton: PropTypes.any,
  showEditColumns: PropTypes.bool,
};