import React, { Component } from "react";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";

import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";



import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class CommentTaskModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // loadedComment: false,
        taskUnderEdit: this.props.taskUnderEdit,
        errors: {}, touched: {}
      };
    }
    updateSpinner(val) {
      this.spinner = (
        <span>
          <p className="loadingMessage">{val}</p>
          <LoaderIco
            loaded={false}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#51b32e"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </span>
      );
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //   this.state.taskUnderEdit =this.props.taskUnderEdit;
  
  
    // }
  
    getNumber(textCharacterLimit) {
      let value = this.state.taskUnderEdit.comments;
  
      return textCharacterLimit - (value ? value.length : 0);
    }
    render() {
      return (
  
        <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.props.loadedComment} message={this.spinner}>
            <ModalHeader>Add Comments</ModalHeader>
            <ModalBody className={"bg-white"}>
              <Container>
  
                <FFFormTextAreaField
                  label="Comments"
                  placeholder="Add Comments Here"
                  icon="fa-comment"
                  fieldname="comments"
                  defaultValue={this.props.taskUnderEdit.comments}
                  required={this.props.required}
                  onChange={(event) => {
                    let s = { ...this.state.taskUnderEdit };
                    s.comments = event.target.value;
                    this.setState({ taskUnderEdit: s });
                  }}
                  rows={"5"}
                />
                <Row>
                  <Col>
                    <p
                      className={"float-right"}
                      style={{
                        color: this.getNumber(300) < 0 ? "red" : null,
                      }}
                    >
                      Text remaining: {this.getNumber(300)}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right" style={{ marginTop: "10px" }}>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.state.taskUnderEdit.comments = this.props.taskUnderEdit.comments;
                        this.props.onCancel && this.props.onCancel()
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    &nbsp;
                    {this.props.onSiteComment === true &&
                    <Button
                      color="primary"
                      disabled={!(this.getNumber(300) >= 0)}
                      onClick={() => {
                        this.updateSpinner("Saving ...");
  
                        // this.setState({
                        //   loadedComment : true
                        // });
                        this.onSubmit();
                      }}
                    >
                      Save
                    </Button>
                    }
                    {this.props.onSiteComment !== true &&
                      <Button
                      color="primary"
                      disabled={!(this.getNumber(300) >= 0) }
                      onClick={() => {
                        this.updateSpinner("Saving ...");
  
                        // this.setState({
                        //   loadedComment : true
                        // });
                        this.onSubmit();
                        this.props.onCancel && this.props.onCancel();
                      }}
                    >
                      Save
                    </Button>
                    }
                    
  
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Loader>
        </Modal>
  
      );
    }
  
    onSubmit() {
  
      //   if (this.state.taskUnderEdit.comments === undefined) {
      //     let s = this.props.taskUnderEdit;
      //     this.state.taskUnderEdit.comments = s.comments;
      //   }
      let s = this.props.taskUnderEdit;
      if (this.state.taskUnderEdit.comments === undefined || this.state.taskUnderEdit.comments === null) {
        this.state.taskUnderEdit.comments = s.comments;
      }
      s.comments = this.state.taskUnderEdit.comments;
  
      this.setState({
        loaded: false,
        taskUnderEdit: s
      });
      return (
        this.props.onSave &&
        this.props.onSave(this.state && this.state.taskUnderEdit)
      );
    }
  }
  
  export default CommentTaskModal;