import React, {Component} from 'react';
import {Button, Card, CardBody, CardGroup, Col, Container, Form, Row} from 'reactstrap';
import {checkPasswordReset, performPasswordReset} from "../../NetworkHelpers";
import {withRouter} from 'react-router';
import {Formik} from "formik";
import RegisterTextField from "../../Register/components/RegisterTextField";
import * as Yup from "yup";
import {getErrorsFromValidationError} from "../../common/utilities";
import {showInfo} from "../../MainApp";
import Config from "../../Config";


const validationSchema = function (values) {
    return Yup.object().shape({
        password: Yup.string()
            .min(6, `Password has to be at least ${6} characters!`)
            .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/, 'Password must contain: numbers, uppercase and lowercase letters\n')
            .required('Password is required'),
        passwordConfirm: Yup.string()
            .oneOf([values.password], 'Passwords must match')
            .required('Password confirmation is required'),
    })
};

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values);
        try {
            validationSchema.validateSync(values, {abortEarly: false});
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
};

const initialValues = {
    password: "",
    passwordConfirm: ""
};


class PasswordReset extends Component {

    constructor(props) {
        super(props);
        let uuid = this.extractUuidFromUrl();
        this.state = {
            error: false,
            loading: true,
            uuid : uuid
        }
    }


    componentWillUnmount() {
        document.body.style.background = null;
    }

    extractUuidFromUrl(){
        let url = window.location.pathname;
        url = url.split("/");
        url = url.filter(it=> it != "");
        return url[3];
    }
    componentDidMount() {
        window.scrollTo(0,0);
        document.body.style.background = null;
        document.body.style.backgroundColor = "#000";
        this.attemptLoginWithCredentials();
    }

    attemptLoginWithCredentials() {
        checkPasswordReset(this.props.match.params, (user)=>{
            this.setState({loading: false})
            },
            (error)=>{
                console.log("ERROR!!");
                this.setState({error:true})
        });

    }

    updatePassword(values, success, error) {
        let request = {
            userUuid : this.state.uuid,
            password:values.password,
            passwordConfirm: values.passwordConfirm

        };
        performPasswordReset(request, success, error);
    }


    onSubmit(values, {setSubmitting, setErrors}) {
        this.updatePassword(values, ()=>{
                setSubmitting(false);
                showInfo("Your password has been reset and you have been automatically logged in.");
                this.props.history.push("/shipments-trackassured");
            }, (error) => {
                setSubmitting(false);
                setErrors(error.formError);
            });
        return false;
    }


    renderError() {
        return (<div>
                <h4>The link you used is no longer valid.</h4>
                <h6>Please try requesting another email link.</h6><br /><br />
            <Button color="primary" onClick={()=>this.props.history.push("/login-trackassured")}>OK</Button></div>);
    }

    renderForm() {
        return  <Formik
            initialValues={initialValues}
            validate={validate(validationSchema)}
            onSubmit={(v, o) => {
                this.onSubmit(v, o)
            }}
            render={
                ({
                     values,
                     errors,
                     touched,
                     status,
                     dirty,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     isSubmitting,
                     isValid,
                     handleReset,
                     setTouched,
                     setFieldValue,
                     setFieldTouched
                 }) => (
                    <Form  onSubmit={(v) => {
                        return handleSubmit(v)
                    }}>
                        <h1>Update Password</h1>

                       
                            <RegisterTextField
                                password={true}
                                placeholder="Password"
                                icontype="fa fa-lock"
                                fieldname="password"
                                error={errors["password"]}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                touched={touched["password"]}
                                value={values["password"]}
                            />
                       
                       
                            <RegisterTextField
                                password={true}
                                placeholder="Password confirmation"
                                icontype="fa fa-lock"
                                fieldname="passwordConfirm"
                                error={errors["passwordConfirm"]}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                touched={touched["passwordConfirm"]}
                                value={values["passwordConfirm"]}
                            />
                       


                        <Button color="primary" className="mt-3"
                                type="submit">Set Password</Button>
                    </Form>)} />;
    }


    render() {
        return (
            <div className="app flex-row align-items-center animated fadeIn login-background">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <CardGroup>
                                <Card className="p-4 text-left login-card" >
                                    <CardBody className={"text-center"}>
                                        <img alt="Logo" className="login-logo" src={Config.preLoginBrand ? Config.preLoginBrand : "/assets/img/brand/vantix.png"} />
                                        {this.state.error?this.renderError():this.renderForm()}
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default withRouter(PasswordReset);