import React, { Component } from "react";
import { showInfo } from "../MainApp";
import { Col, Row, Tooltip } from "reactstrap";

export class ClipboardClickable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false
        }
    }

    toggle() {
        this.setState({ tooltipOpen: this.state && !this.state.tooltipOpen })
    }
    render() {
        return <>
            <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                    let content = this.props.children;

                    if (this.props.value) {
                        content = this.props.value;
                    }

                    if (typeof content === "string") {
                        if ("clipboard" in navigator) {
                            navigator.clipboard.writeText(content);
                            showInfo(content + " copied to clipboard!", {}, { autoClose: 2000 });
                        }
                    }
                }}>
                <Row>
                    <Col md={7}>
                        {this.props.children}
                    </Col>
                    {this.props.hideIcon !== true &&
                    <Col md={5} className={this.props.clickClass ? this.props.clickClass : ''}>
                        <i id={this.props.id} className="fas fa-copy"></i>
                        <Tooltip isOpen={this.state.tooltipOpen} target={this.props.id} toggle={() => this.toggle()} >
                            {!this.props.hover ? "Click to copy to clipboard!" : this.props.hover}
                        </Tooltip>
                    </Col>

                    }
                </Row>
            </div>
        </>;
    }
}