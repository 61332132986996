import React, {Component} from "react";
import Dispatches from "./Dispatches";
import {Button} from "reactstrap";
import moment from "moment-timezone";
import ValidationService from "../../common/ValidationService";
import {formatLatLong, getShipmentsLink} from "../../common/utilities";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {ClipboardClickable} from "../../common/ClipboardClickable";
import * as uuid from "uuid";
import Moment from "react-moment";
import {formatAdditionalReferences, getStartColumn} from "../utilities";
import DurationDisplay from "../../assets/components/DurationDisplay";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import {requestVisitsToShipmentsExcel} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import { latLongDistance } from "../../common/utilities";
export default class ShipmentDispatches extends Component{
    constructor() {
        super();
        this.state = {};
    }

    exportShipments(previousSearchRequest) {
        if ( previousSearchRequest ) {
            requestVisitsToShipmentsExcel(previousSearchRequest, (res)=>{
                let a = res.message;
                if(a && a.includes("https://e4score-ezcheckin")){
                    window.location.href=a;
                } else {
                    showError("We are not able to generate the excel file at this time.  Please try again later.");
                }
                // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
            }, (e)=>{
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    openNoteModal(uuid, assetId, notes){
        this.setState({showingNoteModal: true, noteModalUuid: uuid, noteModalAssetId: assetId, notesForModal: notes});
    }

    closeNoteModal(){
        this.setState({showingNoteModal: undefined, noteModalUuid: undefined, notesForModal: {}});
    }

    openSmsModal(uuid, driverFirstName, driverLastName, defaultReferenceLabel, shipmentNumber, driverPhoneNumber, reminders) {
        this.setState({showingSmsModal: true,
            smsModalUuid: uuid,
            driverFirstName: driverFirstName,
            driverLastName: driverLastName,
            defaultReferenceLabel: defaultReferenceLabel,
            shipmentNumber: shipmentNumber,
            userFirstName: this.props.user.firstname,
            driverPhoneNumber: driverPhoneNumber,
            remindersForModal: reminders
        });
    }

    closeSmsModal() {
        this.setState({showingSmsModal: false, smsModalUuid: false, messageModalError: "", remindersForModal: []});
    }

    render(){
        return <Dispatches
            {...this.props}
            {...this.state}
            stateStorage={"ezcheckin-shipments"}
            closeNoteModal={this.closeNoteModal.bind(this)}
            closeSmsModal={this.closeSmsModal.bind(this)}
            tableColumns={[
                {
                    Header: 'Actions',
                    filterable: false,
                    sortable: false,
                    default: true,
                    Cell: row => {
                        return <div>
                            {row.original.shipment && this.props.showAddNoteActionButton && <Button title="Add Note"
                                                                                                    aria-label="Add Note"
                                                                                                    onClick={() => this.openNoteModal(row.original.shipment.uuid, row.original.shipment.assetId, row.original.shipment.notes)}>
                                <i className="far fa-sticky-note"/>
                            </Button>}&nbsp;
                            <Button
                                title="Edit"
                                aria-label="Edit"
                                onClick={() => this.props.history.push("dispatch/" + row.original.dispatchUuid)}>
                                <i className="fa fa-edit"/>
                            </Button> &nbsp;
                            {row.original.shipment && row.original.shipment.status !== 12.0 &&
                            row.original.shipment.deliveryAppointment > (moment.now() - 1000 * 60 * 60 * 2)
                            && row.original.shipment.driver && row.original.shipment.driver.firstname && row.original.shipment.driver.cell &&
                            <Button
                                title="Text Driver"
                                aria-label="Text Driver"
                                onClick={() =>
                                    this.openSmsModal(
                                        row.original.dispatchUuid,
                                        row.original.shipment.driver.firstname,
                                        row.original.shipment.driver.lastname,
                                        row.original.shipment.company.shipmentIdDisplay,
                                        row.original.shipment.assetId,
                                        ValidationService.formatPhone(row.original.shipment.driver.countryCode, row.original.shipment.driver.cell),
                                        row.original.messages
                                    )
                                }>
                                <i className="fa fa-sms"/>
                            </Button>
                            }&nbsp;
                        </div>
                    }
                },
                {
                    accessor: 'shipment.assetId',
                    Header: this.props.user.company.shipmentIdDisplay,
                    assetId: true,
                    filterable: true,
                    sortable: true,
                    default: true,
                    Cell: row => { return row.original.shipment ? getShipmentsLink(row.original.dispatchUuid,this.props.user, row.original.shipment.assetId, row.original.shipment.company.uuid, row.original.shipment.company.shipmentIdDisplay) : "" }
                },
                {
                    Header: 'Secondary ID',
                    accessor: 'currentAssetRoster.references',
                    filterable: true,
                    sortable: false,
                    Cell: row => {return formatAdditionalReferences(row.original.dispatchReferences, 1)}
                },
                {
                    Header: 'Stop ID',
                    accessor: 'stopPlan.references',
                    filterable: true,
                    sortable: false,
                    default: true,
                    Cell: row => {return formatAdditionalReferences(row.original.references, 0)}
                },
                {
                    accessor: 'status',
                    Header: 'Dispatch Status',
                    filterable: true,
                    sortable: true,
                    Cell: row => {return row.original.status},
                    maxWidth: 200,
                    Filter: ({filter, onChange}) =>
                        <>
                            <select
                                onChange={event => {
                                    this.setState({statusFilter: event.target.value});
                                    onChange(event.target.value)}}
                                style={{width: "100%"}}
                                ref={this.statusFilterRef}
                                value={this.state.statusFilter ? this.state.statusFilter : undefined}
                            >
                                <option value="undefined" hidden={true}>Select</option>
                                <option value="">All</option>
                                <option value="Completed">Completed</option>
                                <option value="InProgress">In Progress</option>
                                <option value="PendingManual">Pending Manual Dispatch</option>
                                <option value="PendingScheduled">Pending Scheduled</option>
                                <option value="Pending">Pending</option>
                                <option value="FailedToStartMissingInfo">Failed to Start</option>
                            </select>
                        </>
                },
                {
                    accessor: 'created',
                    Header: 'Dispatch Creation Time',
                    filterable: false,
                    sortable: true,
                    Cell: row => {
                        let v = row.original;
                        if (v.created) {
                            return <MomentDisplay date={v.created} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/>;
                        } else {
                            return null;
                        }
                    },
                },
                {
                    accessor: 'startTime',
                    Header: 'Dispatch Start Time',
                    filterable: false,
                    sortable: true,
                    Cell: row => {return getStartColumn(row, this.props);}
                },
                {
                    accessor: 'latestShipmentStatusEvent.shipmentStatus.statusName',
                    Header: 'Shipment Status',
                    title:'Most recent event/status update for this shipment, see Report Time column for when this was updated.',
                    filterable: true,
                    sortable: true,
                    Cell: row => {
                        return row.original.latestEvent ? <ClipboardClickable
                            id={"ID_" + uuid.v4()}
                            value={formatLatLong(row.original.latestEvent.latitude) + ", " + formatLatLong(row.original.latestEvent.longitude)}
                            hover={"Click to Copy Coordinates"}>
                              {row.original.latestEvent.status === 14 &&

row.original.latestEvent.statusMessage}
{row.original.latestEvent.status !== 14 &&

row.original.latestEvent.shipmentStatus &&
row.original.latestEvent.shipmentStatus.statusName}
                        </ClipboardClickable> : null
                    },
                    maxWidth: 150
                },
                {
                    accessor: 'latestShipmentStatusEvent.statusMessage',
                    Header: 'Latest Event',
                    title:'Most recent event/status update for this shipment, see Report Time column for when this was updated.',
                    filterable: true,
                    sortable: true,
                    Cell: row => {
                        return row.original.latestEvent ? <ClipboardClickable
                            id={"ID_" + uuid.v4()}
                            value={formatLatLong(row.original.latestEvent.latitude) + ", " + formatLatLong(row.original.latestEvent.longitude)}
                            hover={"Click to Copy Coordinates"}>
                            {row.original.latestEvent.statusMessage}
                        </ClipboardClickable> : null
                    },
                    maxWidth: 150
                },
                {
                    accessor: 'latestShipmentStatusEvent.date',
                    Header: 'Report Time',
                    filterable: false,
                    sortable: true,
                    default: true,
                    title:"Shipment status report datetime for the indicated \"shipment status\"",
                    Cell: row => {
                        return row.original.latestEvent ?
                            <Moment
                                title={"Shipment status report datetime for the indicated \"shipment status\""}
                                format="MM/DD/YYYY h:mm a z"
                                tz={row.original.lastLocationTimezone}
                                date={row.original.latestEvent.date}/> : null
                    },
                    maxWidth: 150
                },
                {
                    accessor: 'latestShipmentStatusEvent.date.elapsed', // E4SCORE3-1562: add this column, even though it's really the same as Report Time, displayed differently.  If this accessor is changed, change DispatchWebController.translateId()
                    Header: 'Elapsed Time',
                    filterable: false,
                    sortable: true,
                    Cell: row => {
                        if (row.original.latestEvent == null) {
                            return null;
                        }
                        let driver = row.original.driver;
                        let status = row.original.status;
                        let date1 = row.original.latestEvent.date;
                        let date2 = new Date();
                        // To calculate the time difference of two dates 
                        var Difference_In_Time = date2.getTime() - date1;

                        // To calculate the no. of days between two dates 
                        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                        let reportGraceInMinutes = driver && driver.company && driver.company.dispatchSettings && driver.company.dispatchSettings.reportGraceWindowInMinutes;
                        let shipmentInProgress = row.original.shipment && row.original.shipment.status > 0 && row.original.shipment.status < 12;
                        if(status === "Completed"){
                            return "";
                        }else if(Difference_In_Days > 5 && status === "In Progress"){
                            return "Time Exceeded";
                        } else if (reportGraceInMinutes == null || !shipmentInProgress) {
                            return <DurationDisplay
                                criticalDwellInMinues={0}
                                warningDwellInMinutes={0}
                                warningDwellUnit={'Minutes'}
                                criticalDwellUnit={'Minutes'}
                                date={row.original.latestEvent.date}/>
                        } else {
                            let durationSinceEventInMins = (new Date().getTime() - row.original.latestEvent.date) / 1000.0 / 60.0;
                            let color = durationSinceEventInMins > reportGraceInMinutes ? "red" : "black";
                            return <div style={{display: 'flex'}}>
                                {color === 'red' ?
                                    <i className='fa fa-dot-circle' aria-hidden="true" style={{color: color}}/>
                                    : null }
                                { <DurationDisplay
                                    criticalDwellInMinues={reportGraceInMinutes}
                                    warningDwellInMinutes={reportGraceInMinutes}
                                    warningDwellUnit={'Minutes'}
                                    criticalDwellUnit={'Minutes'}
                                    date={row.original.latestEvent.date}/>
                                    }

                            </div>
                        }
                    },
                    maxWidth: 150
                },
                {
                    accessor: 'totalStops',
                    Header: '# of Stops',
                    filterable: true,
                    sortable: true,
                    Cell: row => {return row.original.numberOfStops},
                    maxWidth: 150
                },
                {
                    accessor: 'currentStop',
                    Header: 'Stops Complete',
                    filterable: true,
                    sortable: true,
                    default: true,
                    Cell: row => {return row.original.currentStop},
                    maxWidth: 150
                },
                {
                    accessor: 'shipments',
                    Header: "Remaining Miles",
                    filterable: true,
                    sortable: true,
                    default: true,
                    Cell: (row) => {
                      if (row.original.latestEvent) {
                          if(row.original.shipment.deliveryLocation){
                              return latLongDistance(
                                row.original.latestEvent.latitude,
                                row.original.latestEvent.longitude,
                                row.original.shipment.deliveryLocation.latitude,
                                row.original.shipment.deliveryLocation.longitude,
                                row.original.shipment.company.distanceUnitsOfMeasure
                              );
                          }
                          return "Delivery Location Not Available";
                      }
                      return "N/A";
                    },
                  },
                {
                    accessor: 'carrier',
                    Header: 'Carrier',
                    filterable: false,
                    sortable: false,
                    title:"First carrier assigned, open to view all. ",
                    Cell: row => {return row.original.carrier},
                    maxWidth: 150,
                    default: true,
                },
                {
                    Header: 'Driver',
                    accessor: 'driver.firstname',
                    sortable: false,
                    filterable: false,
                },
                {
                    accessor: 'currentStopPlan.firstStop.location.name',
                    Header: 'First Stop',
                    filterable: true,
                    sortable: true,
                    default: true,
                    Cell: row => {
                        return row.original.firstLocationName
                    }
                },
                {
                    accessor: 'currentStopPlan.firstStop.appointment',
                    Header: 'First Stop Appt',
                    filterable: false,
                    sortable: true,
                    default: true,
                    Cell: row => {return row.original.firstAppointment ? <MomentDisplay
                        date={row.original.firstAppointment}
                        timezone={row.original.firstLocationTimezone}
                    />: ""}
                },
                {
                    accessor: 'currentStopPlan.firstStop.arrival',
                    Header: 'First Stop Arrival',
                    filterable: false,
                    sortable: true,
                    default: true,
                    Cell: row => {return row.original.firstStop && row.original.firstStop.arrival ? <MomentDisplay
                        date={row.original.firstStop.arrival}
                        timezone={row.original.firstLocationTimezone}
                    />: ""}
                },
                {
                    accessor: 'currentStopPlan.lastStop.location.name',
                    Header: 'Final Stop',
                    filterable: true,
                    sortable: true,
                    default: true,
                    Cell: row => {
                        return row.original.lastLocationName
                    }
                },
                {
                    accessor: 'currentStopPlan.lastStop.appointment',
                    Header: 'Final Stop Appt',
                    filterable: false,
                    default: true,
                    sortable: true,
                    Cell: row => {return row.original.lastAppointment ? <MomentDisplay
                        date={row.original.lastAppointment}
                        timezone={row.original.lastLocationTimezone}
                    />:""}
                },
                {
                    accessor: 'currentStopPlan.lastStop.arrival',
                    Header: 'Final Stop Arrival',
                    width:220,
                    filterable: false,
                    sortable: true,
                    default: true,
                    Cell: row => {return row.original.lastStop && row.original.lastStop.arrival ? <MomentDisplay
                        date={row.original.lastStop.arrival}
                        timezone={row.original.lastLocationTimezone}
                    />: ""}
                },
            ]}
            title={"Shipments"}
            showAddNoteActionButton
            onExport={(previousSearchRequest) => this.exportShipments(previousSearchRequest)}
            showExportButton
        />
    }
}