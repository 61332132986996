import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { exportUsersToExcel, importUserFile, postUser, searchUsers, welcomeEmail } from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import UserForm from "./UserForm";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { showError, showInfo } from "../../MainApp";
import { FileDropModal } from "../../common/FileDropModal";
import Moment from "react-moment";
var moment = require('moment-timezone');


class Users extends Component {
    tableColumns = [
        {
            Header: "Created",
            accessor: 'created',
            filterable: false,
            sortable: true,

            width: 250,
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                let v = row.original; return v.created ?
                    <MomentDisplay date={v.created} timezone={moment.tz.guess()} />
                    : "New";
            }
        },
        {
            Header: "Last Updated",
            accessor: 'updated',
            filterable: false,
            sortable: true,

            width: 250,
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                let v = row.original; return v.updated ?
                    <MomentDisplay date={v.updated} timezone={moment.tz.guess()} />
                    : "";
            }
        },
        {
            Header: "Email/Username",
            filterable: true,
            sortable: true,
            accessor: 'email'
        },
        {
            Header: "First Name",
            filterable: true,
            sortable: true,
            accessor: 'firstname'
        },
        {
            Header: "Last Name",
            filterable: true,
            sortable: true,
            accessor: 'lastname'
        },
        {
            Header: 'Roles',
            filterable: true,
            sortable: true,
            accessor: 'roles',
            Cell: row => {
                let v = row.original.roles;
                let roles = [];
                if (v) {
                    v.forEach(r => roles.push(r.displayName));
                }
                return roles.join(", ");
            }
        },
        {
            Header: 'Location' + "'s" + '',
            filterable: true,
            sortable: false,
            accessor: 'locCriteria',
            Cell: row => {
                let v = row.original.locations;
                let locations = [];
                if (v) {
                    v.forEach(loc => locations.push(`${loc.nickname} (${loc.code})`));
                }
                if (locations.length > 0) {
                    return locations.join(", ");
                } else {
                    return 'All'
                }
            }
        },
        {
            Header: "Shift",
            filterable: true,
            sortable: true,
            accessor: 'shift'
        },
        {
            Header: 'Enabled',
            accessor: 'enabled',
            filterable: true,
            sortable: true,
            Cell: row => {
                return row.original.enabled ? "Yes" : "No";
            },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={event => {
                            this.setState({ enabledFilter: event.target.value, filterObject: { id: "enabled", value: event.target.value } });
                            let a = { filtered: [] };

                            a.filtered.push(this.state.filterObject);
                            localStorage.setItem("ezcheckin-admin-users-pft", JSON.stringify(a));
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.filterObject ? this.state.filterObject.value : ""}
                    >
                        {/* <option value="undefined" hidden={true}>Select</option> */}
                        <option value="-1">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: 'Action',
            filterable: false,
            accessor: 'action',
            width: 300,
            Cell: row => {
                let v = row.original;
                return <span>
                    <Button disabled={(this.props.loggedInUser.roles.filter(it => it.role.includes("ROLE_EZTRACK_MANAGER")).length > 0) && (v.roles.filter(it => it.role.includes("ADMIN")).length > 0)} onClick={() => this.editUser(v)}><i className="fa fa-edit" /> Edit </Button>
                    <Button style={{ marginLeft: "5px" }} onClick={() => this.sendWelcomeEmail(v)}><i style={{ paddingRight: "2px" }} className="fa fa-paper-plane" /> Send Welcome Email</Button>
                </span>;
            },
            sortable: false
        }
    ];


    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.enabledFilterRef = React.createRef();
        var parse = JSON.parse(localStorage.getItem("ezcheckin-admin-users-pft"));
        var objToPut;

        if (parse && parse !== null) {
            if (parse.filtered.length > 0) {
                let a = parse.filtered.find(it => it.id === "enabled");
                if (!a) {
                    objToPut = { id: "enabled", value: "true" };
                } else {
                    objToPut = { id: "enabled", value: a.value };
                }
            } else {
                objToPut = { id: "enabled", value: "true" };
            }
        } else {
            objToPut = { id: "enabled", value: "true" };
            let a = { filtered: [] };

            a.filtered.push(objToPut);
            a.hideFilters = true;
            localStorage.setItem("ezcheckin-admin-users-pft", JSON.stringify(a));
        }

        this.state = {
            pages: -1,
            editingUser: null,
            importModalShow: false,
            importModalError: false,
            filterObject: objToPut,
            loggedInUser: props.loggedInUser,
        }

        // let adminUserCheck = this.state.loggedInUser.roles.filter(it => it.role.includes("ROLE_EZTRACK_MANAGER"));
        // if(adminUserCheck.length > 0){
        //     this.setState({admin: true});
        // } else {
        //     this.setState({admin: false});
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.company.uuid !== this.props.company.uuid) {
            this.tableRef.current.fetchData();
        }
    }

    renderModal() {
        if (this.state.editingUser) {
            return (
                <UserForm
                    loadedUser={this.state.loadedUser}

                    loggedInUser={this.props.loggedInUser}
                    editingUser={this.state.editingUser}
                    cancel={() =>
                        this.setState({ editingUser: null })
                    }
                    save={(c, setErrors) => {
                        this.setState({ loadedUser: true })

                        let userObj = { ...this.state.loggedInUser };
                        let newUserRoleCheck = c.roles.filter(it => it.role.includes("ADMIN"));
                        let adminCheck = userObj.roles.filter(it => it.role.includes("ADMIN"));
                        if (adminCheck.length > 0) {
                            this.saveUser(c, () => {
                                this.setState({
                                    editingUser: null,

                                })
                            }, (error) => {
                                this.setState({
                                    loadedUser: false
                                })
                                setErrors(error.formError);
                            });
                        } else {
                            let managerCheck = userObj.roles.filter(it => it.role.includes("ROLE_EZTRACK_MANAGER"));
                            if (managerCheck.length > 0) {
                                if (newUserRoleCheck.length === 0) {
                                    this.saveUser(c, () => {
                                        this.setState({
                                            editingUser: null
                                        })
                                    }, (error) => {
                                        this.setState({
                                            loadedUser: false
                                        })
                                        setErrors(error.formError);
                                    });
                                } else {
                                    showError("Access Denied");
                                }
                            } else {
                                this.saveUser(c, () => {
                                    this.setState({
                                        editingUser: null
                                    })
                                }, (error) => {
                                    this.setState({
                                        loadedUser: false
                                    })
                                    setErrors(error.formError);
                                });
                            }
                        }



                    }}
                />
            );
        }
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    saveUser(user, onSuccess, onError) {
        if (!user.onYardCheck) {
            user.onYardCheck = false;
        }
        if (user.password === "PASSWORD SAVED, BUT NOT DISPLAYED FOR SECURITY") {
            user.password = "NOPASSWORD";
            user.passwordConfirm = "NOPASSWORD";

        }
        postUser(user, (response) => {
            this.setState({
                loadedUser: false
            })
            if (response.code === '1000') {
                showError(response.message);
            } else {
                this.setState({ editingUser: null });
                this.tableRef.current.fetchData();
                onSuccess();
            }
        }, (e) => {
            this.setState({
                loadedUser: false
            })
            onError(e)
        })
    }


    startNewUser() {
        this.setState({
            editingUser: {
                roles: [],
                email: "",
                firstname: "",
                lastname: "",
                countryCode: "US",
                password: "",
                passwordConfirm: "",
                isNew: true
            }
        });
    }

    editUser(user) {
        user.password = "PASSWORD SAVED, BUT NOT DISPLAYED FOR SECURITY";
        user.passwordConfirm = "PASSWORD SAVED, BUT NOT DISPLAYED FOR SECURITY";
        this.setState({ editingUser: user });
    }
    clearFilters() {
        this.enabledFilterRef.current.value = undefined;
        this.setState({ enabledFilter: undefined }, () => this.tableRef.current.requestPageSearch());
    }
    exportUsers() {
        if (this.state.previousSearchRequest) {
            exportUsersToExcel(this.state.previousSearchRequest, 'users-export.xlsx', (response) => {
                if (response.emailSent) {
                    showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
                }
            }, (e) => {
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.showImportInstructions}>
                    <ModalHeader>
                        Import Instructions
                    </ModalHeader>
                    <ModalBody>
                        EZCheck-In allows for importing certain data via a file in CSV format.
                        To get started with importing users click the Download Import Template to obtain a sample CSV
                        file.
                        Add one row per user that you wish to import.<br />
                        <br />
                        The following are the list of columns that should be included.
                        Any fields with an asterisk are required and must be non blank.
                        Any fields that contain a comma must be enclosed in quotes.
                        <br />
                        Email*<br />
                        First Name*<br />
                        Last Name*<br />
                        Roles*<br />
                        Shift<br />
                        Phone Country Code<br />
                        Phone<br />
                        Cell Country Code<br />
                        Cell<br />
                        Enabled* (True or False)<br />
                        Locations (case sensitive names of authorized locations, comma separated, quoted)<br />
                        <br />
                        You may include a quoted, comma separated list of roles. The following are acceptable values for roles:<br />
                        {/* PowerYard Admin, PowerYard Manager, PowerYard Yard Jockey, PowerYard Requester{this.props.loggedInUser.company.enableGate ? ', Gate User.' : '.'} <br /> */}
                        <br />
                        {<span className='text-uppercase'>Available Roles</span>}.<br />
                        ROLE_POWERYARD_ADMIN<br>
                        </br>
                        ROLE_POWERYARD_MANAGER<br>
                        </br>
                        ROLE_POWERYARD_REQUESTER<br>
                        </br>
                        ROLE_POWERYARD_JOCKEY<br>
                        </br>
                        <br />
                        {<span className='text-uppercase'>Available Shifts</span>}: Day, Mid, Night, or left "blank" for no shift selected (not case sensitive).<br />
                        <br />
                        <div style={{ marginTop: '10px' }} className={'text-right'}>
                            <Button color="primary" onClick={() => this.setState({
                                showImportInstructions: false
                            })}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>

                <FileDropModal
                    open={this.state.importModalShow}
                    error={this.state.importModalError}
                    header={"Import Users"}
                    onDrop={acceptedFiles => {
                        for (const file of acceptedFiles) {
                            if (file['type'] && !file['type'].includes('text')) {
                                //Or is it excel?
                                if (file['type'] && !(file['type'].includes('excel') || file['type'].includes('openxml'))) {
                                    this.setState({ importModalError: "File must be text" });
                                    return;
                                }
                            }
                            importUserFile(file, (res) => {
                                let response = res.content;
                                this.setState({ importModalShow: false });
                                showInfo(response.message + " in " + response.seconds + " seconds.");
                                this.tableRef.current.fetchData();
                            }, (response) => {
                                this.setState({ importModalError: response.message });
                            }, () => {
                                this.props.history.push("/");
                            })
                        }
                    }}
                    onClose={() => {
                        this.setState({
                            importModalError: false,
                            importModalShow: false
                        });

                    }}
                />
                <Loader show={this.state.loaded} message={this.spinner}>
                    <PaginationFilterTable
                        setloaded={(check) => {
                            this.setState({ loaded: check });
                            this.updateSpinner("");
                        }} ref={this.tableRef}
                        get={searchUsers}
                        openFiltersCollaps={true}
                        pageType="Admin"

                        stateStorage="ezcheckin-admin-users"
                        renderModal={this.renderModal()}
                        columns={this.tableColumns}
                        pageCount={this.state.pages}
                        onFilterHide={() => {
                            this.setState({
                                enabledFilter: "undefined",
                            });
                        }}

                        clearFilter={() => {
                            this.clearFilters.bind(this);
                            var parse = JSON.parse(localStorage.getItem("ezcheckin-admin-users-pft"));
                            parse.filtered = [{ id: "enabled", value: "true" }];
                            let a = { ...this.state.filterObject };
                            a = { id: "enabled", value: "true" };
                            this.setState({ filterObject: a, enabledFilter: "true" });
                            localStorage.setItem("ezcheckin-admin-users-pft", JSON.stringify(parse));
                        }}
                        baseFilter={[this.state.filterObject]}
                        successCallback={(response) => {
                            let filters = this.tableRef.current.state.filtered;
                            let sort = this.tableRef.current.state.sorted;



                            if (filters.length > 0) {
                                var a = filters.find(it => it.id === "enabled");
                                if (a) {
                                    this.setState({ enabledFilter: a.value });
                                }
                            }

                            this.setState({
                                hasResults: response.totalElements > 0,
                                previousSearchRequest: { filtered: filters, sorted: sort }
                            });
                        }}
                        footerButton={
                            <span title={"Select to extract your company user data."}>
                                <Button disabled={!this.state.hasResults}
                                    color="secondary" onClick={() => this.exportUsers()}><i className="fa fa-file-excel" /> Export to Excel</Button>
                            </span>
                        }
                    >
                        <Button color="primary" onClick={() => this.startNewUser()}>
                            <i className="fa fa-plus" /> Add New User
                        </Button>
                        <Button style={{ marginLeft: '10px' }} color="secondary" className={"btn-sm"} onClick={() => {
                            this.setState({ importModalShow: true });
                        }}>
                            <i className="fa fa-file-import" /> Import
                        </Button>
                        <a style={{ marginLeft: '10px' }} href={"/assets/files/user-import-format.csv"}>Download Import Template</a>
                        <a style={{ marginLeft: '10px' }} href={"#"} onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showImportInstructions: true })
                        }}>Import Instructions</a>
                    </PaginationFilterTable>
                </Loader>
            </div>
        );
    }

    sendWelcomeEmail(user) {
        welcomeEmail(user.uuid, () => {
            showInfo("Welcome email sent to " + user.email);
        }, () => {
            showError("Failed to send welcome email to " + user.email);
        });
    }
}


export default Users;