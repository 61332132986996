import React, {Component} from "react";
import Select from "react-select";
import * as PropTypes from "prop-types";

export class TypeOfEntitySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeOfUserOptions: [
                {value: 'Shipper/Receiver', label: 'Shipper/Receiver'},
                {value: 'Carrier', label: 'Carrier (asset-based)'},
                {value: 'Broker', label: 'Broker (non asset-based)'},
                {value: 'Owner-Operator', label: 'Owner-Operator'},
                {value: 'Logistics Provider', label: 'Logistics Provider'}
            ]
        }
    }


    render() {
        return <Select
            isDisabled={this.props.disabled}
            name="typeOfUser"
            placeholder="Type of Entity"
            valid={!this.props.error}
            invalid={this.props.touched && !!this.props.error}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            value={this.props.value ? {
                label: this.props.value,
                name: this.props.value
            } : null}
            className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
            classNamePrefix="select"
            options={this.state.typeOfUserOptions}
            isClearable={true}/>;
    }
}

TypeOfEntitySelect.propTypes = {
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any
};