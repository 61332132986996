import React, { Component } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { withRouter } from "react-router";
import {
  downloadShipmentTAProfile,
  downloadShipmentStatuses,
  searchNextShipments,
  searchPreviousShipments,
  trackShipmentsUsingTrackingID,
} from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import ShipmentEvents from "./ShipmentEvents";
import AssetList from "./AssetList";
import ShipmentTAProfileProgressBar from "./ShipmentTAProfileProgressBar";
import ShipmentTAProfileProgressBarBasic from "./ShipmentTAProfileProgressBarBasic";
import Select from "react-select";
import Badge from "reactstrap/es/Badge";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import MapView from "../../PingAssets/components/MapView";
import FFFormDateTimeField from "../../common/formik-free/FFFormDateTimeField";
import FFFormRadioField from "../../common/formik-free/FFFormRadioField";
import { CHECK_CALL, ON_SITE_STATUSES } from "../../common/constants";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import Switch from "react-switch";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import Row from "reactstrap/lib/Row";
import MomentDisplay from "../../assets/components/MomentDisplay";
class ReferenceDisplay extends Component {
  render() {
    return (
      <div>
        <Badge>{this.props.reference.type.name}</Badge>{" "}
        {this.props.reference.value}
      </div>
    );
  }
}

ReferenceDisplay.propTypes = { reference: PropTypes.any };

class ShipmentTAProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: true,
      shipmentProfile: null,
      shipmentProfileStepsTemp: null,
      detailEnabled: true,
      shipmentStatus: "",
      stopData: [],
      track: false,
      searchByTracking: "",
      trackedShipments: [],
      openSections: {
        map: true,
        location: true,
        events: true,
        eztrackEvents: true,
        assets: true,
      },
      statusMin: -1,
      statusMax: 100,
      nextArrivalEventStatus: {},
      nextEventStatus: {},
      loaded: false,
      mapFilter: "Origin & Destination",
      mapSize: { height: "500px", width: "500px" },
      currentDispatch: this.props.match.params.duuid,
    };
    this.updateSpinner("Loading ...");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let tempArr = JSON.parse(localStorage.getItem("trackedShipments"));
    if (tempArr) {
      tempArr = tempArr.map((it) => (it = it.trackID));
      let s = "";
      for (var i = 0; i < tempArr.length; i++) {
        if (i === 0) {
          s = s + tempArr[0];
        } else {
          s = s + " " + tempArr[i];
        }
      }
      // let s = tempArr.map(it => it =+" ");
      // s = s.replace(",", " ");
      this.setState({ searchByTracking: s });
    }
    this.downloadShipmentProfile();
    this.downloadShipmentStatuses();
    window.addEventListener("resize", this.getMapSize.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getMapSize.bind(this));
  }
  refreshView() {
    this.setState({ detailEnabled: true, loading: true });
    this.downloadShipmentProfile();
    this.downloadShipmentStatuses();
    window.addEventListener("resize", this.getMapSize.bind(this));
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  downloadShipmentProfile() {
    let companyUuid = this.getCompanyRequestUuid();
    let trackObj = this.getTrackingObject();
    trackObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (trackObj.trackType !== "Invalid ID") {
      downloadShipmentTAProfile(
        this.props.match.params.uuid,
        companyUuid,
        trackObj,
        (response) => {
          let nextEventStatus = {};
          let nextArrivalEventStatus = this.state.nextArrivalEventStatus;
          if (this.state.shipmentStatuses) {
            nextEventStatus = this.state.shipmentStatuses.filter((s) => {
              return s.status > response.ShipmentProfile.status;
            })[0];
            nextArrivalEventStatus = this.state.shipmentStatuses.filter((s) => {
              return (
                s.status > response.status &&
                ON_SITE_STATUSES.includes(s.status)
              );
            })[0];
          }
          let activeStatus =
            response.ShipmentProfile.shipmentProfileProgress.steps.find(
              (it) => it.active === true
            );
          if (activeStatus.event !== null) {
            this.setState({
              shipmentStatus: activeStatus
                ? activeStatus.event.shipmentStatus.statusName
                : null,
            });
          }
          this.setState({
            loading: false,
            trackType: response.TrackedShipments[0].trackType,
            trackID: response.TrackedShipments[0].trackID,
            shipmentProfile: response.ShipmentProfile,
            nextEventStatus: nextEventStatus,
            nextArrivalEventStatus: nextArrivalEventStatus,
            stopData: response.Stops,
            shipmentStatus: response.ShipmentStatus
          });
          const tempProfile = JSON.stringify(
            response.ShipmentProfile.shipmentProfileProgress
          );
          let check = localStorage.getItem(
            this.props.user.username + "-viewswitch"
          );
          if (check) {
            check = JSON.parse(check);
            this.setState({ detailEnabled: check });
            if (check === false) {
              let s = this.state.shipmentProfile;
              let sd = this.state.stopData;
              let tempArr = this.getBasicView(s, sd);
              s.shipmentProfileProgress.steps = tempArr;
              this.setState({
                shipmentProfile: s,
                shipmentProfileStepsTemp: JSON.parse(tempProfile),
              });
            } else {
              this.getAppointmentDate(1);
              this.getAppointmentDate(4);
            }
          } else {
            this.setState({ detailEnabled: true });
          }
          
          this.getMapSize();
          this.generateMarkers();
          this.generateMarkersForEvents();
        },
        (error) => {
          showError("Unable to load shipment.");
        }
      );
    } else {
      this.setState({
        loading: false,
        trackType: trackObj.trackType,
        trackID: trackObj.trackID,
        shipmentProfile: null,
        nextEventStatus: [],
        nextArrivalEventStatus: [],
        stopData: [],
      });
    }
  }

  downloadShipmentStatuses() {
    downloadShipmentStatuses(
      (statuses) => {
        statuses.sort();
        let nextArrivalEventStatus = statuses.filter((s) => {
          return ON_SITE_STATUSES.includes(s.status);
        })[0];
        let nextEventStatus = {};
        if (this.state.shipmentProfile) {
          nextEventStatus = statuses.filter((s) => {
            return s.status > this.state.shipmentProfile.status;
          })[0];
          nextArrivalEventStatus = statuses.filter((s) => {
            return (
              s.status > this.state.shipmentProfile.status &&
              ON_SITE_STATUSES.includes(s.status)
            );
          })[0];
        }
        this.setState({
          shipmentStatuses: statuses,
          nextArrivalEventStatus: nextArrivalEventStatus,
          nextEventStatus: nextEventStatus,
        });
      },
      (error) => {
        showError("Unable to load shipment statuses.");
      }
    );
  }
  getCompanyRequestUuid() {
    let company = this.props.user.company;
    let companyUuid = company.uuid;
    const values = queryString.parse(this.props.location.search);

    if (values.company) {
      companyUuid = values.company;
    }
    return companyUuid;
  }
  getTrackingObject() {
    var values = this.props.location.pathname;
    values = values.split("/");
    values = values.filter((it) => it !== "");
    let obj = {
      trackType: values[3],
      trackID: values[4],
    };
    return obj;
  }
  classForSectionButton(section) {
    if (this.state.openSections[section]) {
      return "fa fa-minus";
    } else {
      return "fa fa-plus";
    }
  }
  classForSectionBackground(section) {
    if (this.state.openSections[section]) {
      return "btn-primary text-white";
    } else {
      return "";
    }
  }
  sectionOpen(section) {
    return this.state.openSections[section];
  }

  toggleSection(section) {
    let sections = Object.assign({}, this.state.openSections); // copy sections
    if (sections[section]) {
      sections[section] = false;
    } else {
      sections[section] = true;
    }
    this.setState({ openSections: sections });
  }

  statusInRangeForMap(status) {
    return status >= this.state.statusMin && status <= this.state.statusMax;
  }
  generateMarkers() {
    let markers = [];
    if (
      this.state.shipmentProfile.source &&
      this.state.shipmentProfile.source.latitude &&
      this.state.shipmentProfile.source.longitude &&
      this.statusInRangeForMap(4)
    ) {
      markers.push({
        title: "Origin: " + this.state.shipmentProfile.source.name,
        position: {
          lat: this.state.shipmentProfile.source.latitude,
          lng: this.state.shipmentProfile.source.longitude,
        },
        name: "origin",
        zIndex: 0,
      });
    }
    if (
      this.state.shipmentProfile.destination &&
      this.state.shipmentProfile.destination.latitude &&
      this.state.shipmentProfile.destination.longitude &&
      this.statusInRangeForMap(12)
    ) {
      markers.push({
        title: "Destination: " + this.state.shipmentProfile.destination.name,
        position: {
          lat: this.state.shipmentProfile.destination.latitude,
          lng: this.state.shipmentProfile.destination.longitude,
        },
        name: "destination",
        zIndex: 0,
      });
    }
    return markers;
  }
  generateMarkersForEvents() {
    let events = [];
    let textMessageEvents = this.state.shipmentProfile.textMessageEvents;
    for (let i = 0; i < textMessageEvents.length; i++) {
      let tme = textMessageEvents[i];
      if (
        tme.latitude &&
        tme.longitude &&
        this.statusInRangeForMap(tme.status)
      ) {
        tme.shipmentNumber = this.state.shipmentProfile.shipment.assetId;
        let icon =
          tme.eventDescription === "EZTrack"
            ? "/assets/img/icons/truck-orange.png"
            : "/assets/img/icons/truck.png";
        events.push({
          title: tme.eventDescription,
          position: { lat: tme.latitude, lng: tme.longitude },
          name: "tme:" + i,
          event: tme,
          icon: icon,
          zIndex: i + 1000000,
          supportMouseOver: true,
        });
      }
    }
    return events;
  }

  mapFilterChange(filter) {
    let min = -1;
    let max = 99;
    if (filter === "Origin & Destination") {
    } else if (filter === "Destination") {
      min = 8;
    } else if (filter === "Origin") {
      max = 7.1;
    }
    this.setState({ mapFilter: filter, statusMin: min, statusMax: max });
  }

  getMapSize = () => {
    let w = window.innerWidth;
    let s = document.getElementById("app-sidebar");
    if (s === null) {
      this.setState({ mapSize: { height: "500px", width: "500px" } });
      return { height: "500px", width: "500px" };
    }
    w = w - s.offsetWidth - 145; // 40 for card padding, 2 for card border, 34 for page padding, 13 for scroll bar
    let d = document.getElementById("shipment-map-container");
    if (d === null) {
      this.setState({ mapSize: { height: "500px", width: "500px" } });
      return { height: "500px", width: "500px" };
    }
    let h = d.offsetHeight - 25;
    for (let i = 0; i < d.children.length - 1; i++) {
      h = h - d.children[i].offsetHeight;
    }
    if (h < 500) {
      h = 500;
    }
    this.setState({ mapSize: { height: h, width: w } });
    return { height: h, width: w };
  };
  addNewEvent() {
    this.setState({
      addEventModalOpen: true,
      errors: {},
      touched: {},
      newEvent: { date: new Date(), eventType: CHECK_CALL },
    });
  }
  cancelEditEvent() {
    this.setState({
      editEventModalOpen: false,
      errors: {},
      touched: {},
    });
  }
  cancelAddNewEvent() {
    this.setState({
      addEventModalOpen: false,
      errors: {},
      touched: {},
    });
  }

  getEventTypeOptions() {
    let options = [{ name: CHECK_CALL, value: CHECK_CALL }];
    if (this.state.nextArrivalEventStatus) {
      options.push({
        name: this.state.nextArrivalEventStatus.statusName,
        value: this.state.nextArrivalEventStatus.statusName,
      });
    }
    return options;
  }

  getTime(stepStr){
    let seperatedArr = stepStr.split("\n");
      seperatedArr = seperatedArr.filter(it => it !== "" && it !== "&nbsp;");
      return seperatedArr[1];
    }
  getAppointmentDate(index){
    let s = this.state.stopData;
    if(index === 1){
      let stopappointment = s[0].appointment;
      let stopTimezone = s[0].location ? s[0].location.timezone: null;
      let stopgraceInMins = this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert.graceInMins: null;
      let stopStatus = this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert.arrivalAlert: null;
      this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert = {
        appointment : stopappointment,
        timezone : stopTimezone,
        graceInMins : stopgraceInMins,
        arrivalAlert : stopStatus
      };
    }
    if(index === 4){
      let stopappointment = s[s.length -1].appointment;
      let stopTimezone = s[s.length -1].location ? s[s.length -1].location.timezone: null;
     
      let stopgraceInMins = this.state.shipmentProfile.shipmentProfileProgress.steps[index] ? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert.graceInMins : null : null;
      let stopStatus = this.state.shipmentProfile.shipmentProfileProgress.steps[index] ? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert.arrivalAlert: null : null;
      this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert = {
        appointment : stopappointment,
        timezone : stopTimezone,
        graceInMins : stopTimezone,
        graceInMins : stopgraceInMins,
        arrivalAlert : stopStatus
      };
    }
    }
  getBasicView(profile, totalStops) {
    console.log(profile, totalStops);
    let activeStepsArr = profile.shipmentProfileProgress.steps[3];
    let stopArr = [];
    for (var obj in totalStops) {
      stopArr.push(totalStops[obj]);
    }

    stopArr.map((it) => (it.active = false));
    const length = stopArr.length;
    let currentStop = profile.shipment.currentStop;

    let firstStop = stopArr[0];
    if (firstStop.location !== null) {
      firstStop.stepText =
        firstStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[1].stepText)
        +
        "\n\n**" +
        firstStop.location.name +
        "**\n\n" +
        firstStop.location.code +
        "\n\n" +
        firstStop.location.address1 +
        "\n\n \n\n" +
        firstStop.location.city +
        ", " +
        firstStop.location.state +
        " " +
        firstStop.location.postal +
        "\n\n" +
        firstStop.location.country;
    } else {
      firstStop.stepText =
        firstStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[1].stepText)+
        "\n\n**" +
        "N/A" +
        "**\n\n" +
        "N/A" +
        "\n\n" +
        "N/A" +
        "\n\n \n\n" +
        "N/A" +
        ", " +
        "N/A" +
        " " +
        "N/A" +
        "\n\n" +
        "N/A";
    }
    firstStop.active = false;
    firstStop.showLocation = true;
    firstStop.alert = profile.shipmentProfileProgress.steps[1].alert;
    let lastStop = stopArr[length - 1];
    if (lastStop.location !== null) {
      lastStop.stepText =
        lastStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[4].stepText) +
        "\n\n**" +
        lastStop.location.name +
        "**\n\n" +
        lastStop.location.code +
        "\n\n" +
        lastStop.location.address1 +
        "\n\n \n\n" +
        lastStop.location.city +
        ", " +
        lastStop.location.state +
        " " +
        lastStop.location.postal +
        "\n\n" +
        lastStop.location.country;
    } else {
      lastStop.stepText =
      lastStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[4].stepText) +
        "\n\n**" +
        "N/A" +
        "**\n\n" +
        "N/A" +
        "\n\n" +
        "N/A" +
        "\n\n \n\n" +
        "N/A" +
        ", " +
        "N/A" +
        " " +
        "N/A" +
        "\n\n" +
        "N/A";
    }

    lastStop.active = false;
    lastStop.showLocation = true;
    lastStop.alert = profile.shipmentProfileProgress.steps[4].alert;
    if (currentStop === 0) {
      if (profile.status > 3 && profile.status < 7) {
        firstStop.active = true;
        firstStop.showGrey = false;
        lastStop.showGrey = false;
      } else {
        firstStop.active = false;
        firstStop.showGrey = true;
        lastStop.showGrey = true;
      }
    }
    if (currentStop > 0 && currentStop === length) {
      lastStop.active = true;
    }
    let arr = [];
    arr.push(firstStop);
    if (currentStop > 0 && currentStop <= length) {
      let doneStop = stopArr[currentStop - 1];
      if (activeStepsArr.locationUpdates === null) {
        if(doneStop.location !== null){
          activeStepsArr.stopLocation =
          "**" +
          doneStop.location.name +
          "**\n" +
          doneStop.location.code +
          "\n\n" +
          doneStop.location.address1 +
          "\n\n \n\n" +
          doneStop.location.city +
          ", " +
          doneStop.location.state +
          " " +
          doneStop.location.postal +
          "\n\n" +
          doneStop.location.country;
        } else {
          activeStepsArr.stopLocation =
          "**" +
          "N/A"+
          "**\n" +
          "N/A"+
          "\n\n" +
          "N/A"+
          "\n\n \n\n" +
          "N/A"+
          ", " +
          "N/A"+
          " " +
          "N/A"+
          "\n\n" +
          "N/A";
        }
      } else {
        if (activeStepsArr.locationUpdates.length === 0) {
          if(doneStop.location !== null){
            activeStepsArr.stopLocation =
            "**" +
            doneStop.location.name +
            "**\n" +
            doneStop.location.code +
            "\n\n" +
            doneStop.location.address1 +
            "\n\n \n\n" +
            doneStop.location.city +
            ", " +
            doneStop.location.state +
            " " +
            doneStop.location.postal +
            "\n\n" +
            doneStop.location.country;
          } else {
            activeStepsArr.stopLocation =
            "**" +
            "N/A"+
            "**\n" +
            "N/A"+
            "\n\n" +
            "N/A"+
            "\n\n \n\n" +
            "N/A"+
            ", " +
            "N/A"+
            " " +
            "N/A"+
            "\n\n" +
            "N/A";
          }
        } else {
          activeStepsArr.stopLocation =
            activeStepsArr.locationUpdates[0].geocodeLocation.fullLocation;
        }
      }
      activeStepsArr.completedInTwoSteps = true;

      arr.push(activeStepsArr);
    }

    arr.push(lastStop);
    return arr;
    // if()
  }
  getUniqueIds(names) {
    var uniqueArr = [];
    for (var value of names) {
      if (uniqueArr.indexOf(value) === -1) {
        uniqueArr.push(value);
      }
    }
    return uniqueArr;
  }
  hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }
  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <Loader show={this.state.loaded} message={this.spinner}>
        {this.state.shipmentProfile !== null && (
          <div className="text-left p-3">
            <div className="mb-2">
              <Card>
                <CardBody>
                  <div className="float-right d-flex">
                    <Input
                      className=""
                      type="text"
                      placeholder="Insert ID(s)"
                      value={
                        this.state.searchByTracking
                          ? this.state.searchByTracking
                          : ""
                      }
                      onBlur={
                        (e) => {
                          e.target.value = e.target.value.trim();
                          let s = { ...this.state.searchByTracking };
                          s = e.target.value;
                          this.setState({ searchByTracking: s });
                        }
                      }
                      onChange={(e) => {
                        let s = { ...this.state.searchByTracking };
                        s = e.target.value;
                        this.setState({ searchByTracking: s });
                      }}
                    />
                    &nbsp;
                    <Button
                      color="primary"
                      className="px-4"
                      disabled={this.state.searchByTracking === ""}
                      onClick={() => {
                        this.setState({ loaded: true });
                        let s = this.state.searchByTracking.trim();
                        s = s.split(" ");
                        s = s.filter(it => it !== "");
                        s = s.map(it => it = it.toLowerCase());
                        let chec = this.hasDuplicates(s);
                        if (chec === true) {
                          showInfo("Duplicate IDs are removed.");
                        }
                        s = this.getUniqueIds(s);
                        s = s.toString();
                        let companyUuid = this.getCompanyRequestUuid();
                        var data = {
                          page: 0,
                          pageSize: 20,
                          sorted: [],
                          filtered: [],
                        };
                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        trackShipmentsUsingTrackingID(
                          s,
                          timeZone,
                          companyUuid,
                          data,
                          (response) => {
                            this.setState({ loaded: false });
                            if (response.TrackedShipments.length > 0) {
                              // response.TrackedShipments.splice((response.TrackedShipments.length-1),1);
                              localStorage.setItem(
                                "trackedShipments",
                                JSON.stringify(response.TrackedShipments)
                              );

                              this.props.history.push(
                                "/trackassured-shipment-profile/" +
                                response.TrackedShipments[0].assetID +
                                "/" +
                                response.TrackedShipments[0].dispatchUUID +
                                "/" +
                                response.TrackedShipments[0].trackType +
                                "/" +
                                response.TrackedShipments[0].trackID
                              );
                            }
                            showInfo("Data Loaded.");
                          },
                          (error) => {
                            this.setState({ loaded: false });
                            showError("Unable to load shipment.");
                          }
                        );
                      }}
                    >
                      Track
                    </Button>{" "}
                    &nbsp;
                    <Button
                      style={{ background: "#ff9e19" }}
                      className="text-white"
                      onClick={() => this.refreshView()}
                    >
                      <i className="far fa-repeat-alt"></i>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
            {/* <h1>Shipment {this.state.shipmentProfile.shipment.assetId}</h1> */}
            {this.state.shipmentProfile.shipment.references &&
              this.state.shipmentProfile.shipment.references.map((r) => {
                return <ReferenceDisplay reference={r} />;
              })}
            <br />

            <Card>
              <CardBody>
                <div className={"btn-navigation"}>
                  <Button
                    onClick={() => {
                      let trackedShipments =
                        localStorage.getItem("trackedShipments");
                      if (trackedShipments) {
                        let tempArr = JSON.parse(trackedShipments);
                        let index = tempArr.findIndex(
                          (it) =>
                            it.trackID ===
                            this.state.trackID
                        );

                        if (index > -1 && index !== 0) {
                          this.props.history.push(
                            "/trackassured-shipment-profile/" +
                            tempArr[index - 1].assetID +
                            "/" +
                            tempArr[index - 1].dispatchUUID +
                            "/" +
                            tempArr[index - 1].trackType +
                            "/" +
                            tempArr[index - 1].trackID
                          );
                        }
                        if (index == 0) {
                          showInfo("No More data to show.");
                        }

                      } else {
                        this.setState({
                          loaded: true,
                        });
                        let s = { ...this.state.currentDispatch };
                        let p = { ...this.props };
                        let companyUid = p.user.company.uuid;
                        let str = "";
                        const keys = Object.keys(s);
                        for (const key of keys) {
                          str += s[key];
                        }
                        var data = {
                          page: 0,
                          pageSize: 20,
                          sorted: [],
                          filtered: [],
                        };
                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        searchPreviousShipments(
                          companyUid,
                          timeZone,
                          str,
                          data,
                          (response) => {
                            // console.log(response);
                            this.setState({
                              loaded: false,
                            });
                            this.props.history.push(
                              "/trackassured-shipment-profile/" +
                              response.assetID +
                              "/" +
                              response.dispatchUUID +
                              "/" +
                              response.trackType +
                              "/" +
                              response.trackID
                            );
                          }
                        );
                      }
                    }}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </Button>
                  <Button
                    className={"float-right"}
                    onClick={() => {
                      let trackedShipments =
                        localStorage.getItem("trackedShipments");
                      if (trackedShipments) {
                        let tempArr = JSON.parse(trackedShipments);
                        let index = tempArr.findIndex(
                          (it) =>
                            it.trackID ===
                            this.state.trackID
                        );
                        if (index > -1 && index < tempArr.length - 1) {
                          this.props.history.push(
                            "/trackassured-shipment-profile/" +
                            tempArr[index + 1].assetID +
                            "/" +
                            tempArr[index + 1].dispatchUUID +
                            "/" +
                            tempArr[index + 1].trackType +
                            "/" +
                            tempArr[index + 1].trackID
                          );
                        }
                        if (tempArr.length == index + 1) {
                          showInfo("No More data to show.");
                        }
                      } else {
                        this.setState({
                          loaded: true,
                        });
                        let s = { ...this.state.currentDispatch };
                        let p = { ...this.props };
                        let companyUid = p.user.company.uuid;
                        let str = "";
                        const keys = Object.keys(s);
                        for (const key of keys) {
                          str += s[key];
                        }
                        var data = {
                          page: 0,
                          pageSize: 20,
                          sorted: [],
                          filtered: [],
                        };
                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        searchNextShipments(
                          companyUid,
                          timeZone,
                          str,
                          data,
                          (response) => {
                            this.setState({
                              loaded: false,
                            });
                            this.props.history.push(
                              "/trackassured-shipment-profile/" +
                              response.assetID +
                              "/" +
                              response.dispatchUUID +
                              "/" +
                              response.trackType +
                              "/" +
                              response.trackID
                            );
                          }
                        );
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </Button>
                </div>
                {this.state.stopData.length > 1 && (
                  <div className="mb-4">
                    <div className={"float-right"}>
                      <label>Basic View</label>&nbsp;
                      <Switch
                        offHandleColor="#51B32E"
                        onHandleColor="#51B32E"
                        offColor="#d5d3d3"
                        onColor="#d5d3d3"
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onChange={(checked) => {
                          let s = { ...this.state.shipmentProfile };
                          let d = { ...this.state.detailEnabled };
                          let p = { ...this.state.shipmentProfileStepsTemp };
                          let totalStops = { ...this.state.stopData };
                          localStorage.setItem(
                            this.props.user.username + "-viewswitch",
                            checked
                          );
                          if (checked == false) {
                            d = false;
                            let allsteps = JSON.stringify(
                              s.shipmentProfileProgress
                            );
                            let tempArr = this.getBasicView(s, totalStops);
                            s.shipmentProfileProgress.steps = tempArr;
                            this.setState({
                              shipmentProfile: s,
                              detailEnabled: d,
                              shipmentProfileStepsTemp: JSON.parse(allsteps),
                            });
                          } else {
                            d = true;
                            s.shipmentProfileProgress = p;
                            this.setState({
                              shipmentProfile: s,
                              detailEnabled: d,
                              shipmentProfileStepsTemp: null,
                            });
                          }
                        }}
                        checked={this.state.detailEnabled}
                        title="Show Filter"
                        className="react-switch"
                      />{" "}
                      &nbsp;
                      <label>Detailed View</label>
                    </div>
                    <strong>
                      {this.state.trackType.toLowerCase()} : {this.state.trackID.toLowerCase()}
                    </strong>
                  </div>
                )}

                <h3 className={"font-weight-bold text-center mt-4"}>
                  Current Status : {this.state.shipmentStatus}
                </h3>
                {this.state.shipmentProfile.shipmentProfileProgress &&
                  this.state.detailEnabled === true && (
                    <ShipmentTAProfileProgressBar
                      progress={
                        this.state.shipmentProfile.shipmentProfileProgress
                      }
                      laststop={this.state.shipmentProfile.destination} currentLatLong={this.state.shipmentProfile.textMessageEvents}
                      cancelled={this.state.shipmentStatus}
                      user={this.props.user}
                    />
                  )}
                {this.state.shipmentProfile.shipmentProfileProgress &&
                  this.state.detailEnabled === false && (
                    <ShipmentTAProfileProgressBarBasic
                      progress={
                        this.state.shipmentProfile.shipmentProfileProgress
                      }
                      laststop={this.state.shipmentProfile.destination} currentLatLong={this.state.shipmentProfile.textMessageEvents}
                      cancelled={this.state.shipmentStatus}
                      user={this.props.user}
                    />
                  )}


              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Button
                  className={this.classForSectionBackground("map")}
                  onClick={() => this.toggleSection("map")}
                >
                  <i className={this.classForSectionButton("map")} />
                </Button>{" "}
                &nbsp; Map
              </CardHeader>

              {this.sectionOpen("map") && (
                <CardBody>
                  <div className="map-header">
                    <Select
                      onChange={(e) => this.mapFilterChange(e.value)}
                      value={{
                        value: this.state.mapFilter,
                        label: this.state.mapFilter,
                      }}
                      classNamePrefix="simple_select"
                      options={[
                        "Origin & Destintation",
                        "Origin",
                        "Destination",
                      ].map((v) => {
                        return { value: v, label: v };
                      })}
                    ></Select>
                  </div>
                  <div
                    id="shipment-map-container"
                    style={{
                      height: "500px",
                    }}
                  >
                    <MapView
                      onMarkerClick={(marker) => {
                        // figure out if it's origin or destination or TME and highlight
                        console.log(marker);
                      }}
                      lat={this.state.lat}
                      lng={this.state.lng}
                      zoom={this.state.zoom}
                      getMapSize={this.getMapSize.bind(this)}
                      mapHeight={this.state.mapSize.height}
                      mapWidth={this.state.mapSize.width}
                      boundsMarkers={this.generateMarkers().concat(
                        this.generateMarkersForEvents()
                      )}
                      markers={this.generateMarkers().concat(
                        this.generateMarkersForEvents()
                      )}
                      showAssetPopup={false}
                      showShipmentEventPopup={true}
                    />
                  </div>
                </CardBody>
              )}
            </Card>
            <Card>
              <CardHeader>
                <Button
                  className={this.classForSectionBackground("events")}
                  onClick={() => this.toggleSection("events")}
                >
                  <i className={this.classForSectionButton("events")} />
                </Button>{" "}
                &nbsp; Events
              </CardHeader>
              {this.state.shipmentProfile.textMessageEvents &&
                this.state.shipmentProfile.textMessageEvents.length > 0 ? (
                <CardBody>
                  {this.sectionOpen("events") && (
                    <ShipmentEvents
                      events={this.state.shipmentProfile.textMessageEvents}
                      onEdit={(event) => {
                        this.setState({
                          editEventModalOpen: true,
                          eventToEdit: event,
                          errors: {},
                          touched: {},
                        });
                      }}
                    />
                  )}

                  {/* {this.hasForcedDispatch(this.state.shipmentProfile.textMessageEvents) &&
                        <Alert color="warning">
                            *  Indicates that this was not a GPS verified timestamp as a user manually set the time for the asset at this location.
                        </Alert>} */}
                </CardBody>
              ) : (
                ""
              )}
            </Card>
          </div>
        )}
        {this.state.shipmentProfile === null &&
          <div className="text-left p-3">
            <div className="mb-2">
              <Card>
                <CardBody>
                  <div className="float-right d-flex">
                    <Input
                      className=""
                      type="text"
                      placeholder="Insert ID(s)"
                      value={
                        this.state.searchByTracking
                          ? this.state.searchByTracking
                          : ""
                      }
                      onBlur={
                        (e) => {
                          e.target.value = e.target.value.trim();
                          let s = { ...this.state.searchByTracking };
                          s = e.target.value;
                          this.setState({ searchByTracking: s });
                        }
                      }
                      onChange={(e) => {
                        let s = { ...this.state.searchByTracking };
                        s = e.target.value;
                        this.setState({ searchByTracking: s });
                      }}
                    />
                    &nbsp;
                    <Button
                      color="primary"
                      className="px-4"
                      disabled={this.state.searchByTracking === ""}
                      onClick={() => {
                        this.setState({ loaded: true });
                        let s = this.state.searchByTracking;
                        s = s.split(" ");
                        s = s.filter(it => it !== "");
                        s = s.map(it => it = it.toLowerCase());
                        s = s.toString();
                        let companyUuid = this.getCompanyRequestUuid();
                        var data = {
                          page: 0,
                          pageSize: 20,
                          sorted: [],
                          filtered: [],
                        };
                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        trackShipmentsUsingTrackingID(
                          s,
                          timeZone,
                          companyUuid,
                          data,
                          (response) => {
                            this.setState({ loaded: false });
                            if (response.TrackedShipments.length > 0) {
                              localStorage.setItem(
                                "trackedShipments",
                                JSON.stringify(response.TrackedShipments)
                              );

                              this.props.history.push(
                                "/trackassured-shipment-profile/" +
                                response.TrackedShipments[0].assetID +
                                "/" +
                                response.TrackedShipments[0].dispatchUUID +
                                "/" +
                                response.TrackedShipments[0].trackType +
                                "/" +
                                response.TrackedShipments[0].trackID
                              );
                            }
                            showInfo("Data Loaded.");
                          },
                          (error) => {
                            this.setState({ loaded: false });
                            showError("Unable to load shipment.");
                          }
                        );
                      }}
                    >
                      Track
                    </Button>{" "}
                    &nbsp;
                    <Button
                      style={{ background: "#ff9e19" }}
                      className="text-white"
                      onClick={() => this.refreshView()}
                    >
                      <i className="far fa-repeat-alt"></i>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>

            <br />

            <Card>
              <CardBody>
                <div className={"btn-navigation"}>
                  <Button
                    onClick={() => {
                      let trackedShipments =
                        localStorage.getItem("trackedShipments");
                      if (trackedShipments) {
                        let tempArr = JSON.parse(trackedShipments);
                        let index = tempArr.findIndex(
                          (it) =>
                            it.trackID ===
                            this.state.trackID
                        );

                        if (index > -1 && index !== 0) {
                          this.props.history.push(
                            "/trackassured-shipment-profile/" +
                            tempArr[index - 1].assetID +
                            "/" +
                            tempArr[index - 1].dispatchUUID +
                            "/" +
                            tempArr[index - 1].trackType +
                            "/" +
                            tempArr[index - 1].trackID
                          );
                        }
                        if (index == 0) {
                          showInfo("No More data to show.");
                        }

                      } else {
                        this.setState({
                          loaded: true,
                        });
                        let s = { ...this.state.currentDispatch };
                        let p = { ...this.props };
                        let companyUid = p.user.company.uuid;
                        let str = "";
                        const keys = Object.keys(s);
                        for (const key of keys) {
                          str += s[key];
                        }
                        var data = {
                          page: 0,
                          pageSize: 20,
                          sorted: [],
                          filtered: [],
                        };
                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
                        searchPreviousShipments(
                          companyUid,
                          timeZone,
                          str,
                          data,
                          (response) => {
                            // console.log(response);
                            this.setState({
                              loaded: false,
                            });
                            this.props.history.push(
                              "/trackassured-shipment-profile/" +
                              response.assetID +
                              "/" +
                              response.dispatchUUID
                            );
                          }
                        );
                      }
                    }}
                  >
                    <i className="fa fa-chevron-left"></i>
                  </Button>
                  <Button
                    className={"float-right"}
                    onClick={() => {
                      let trackedShipments =
                        localStorage.getItem("trackedShipments");
                      if (trackedShipments) {
                        let tempArr = JSON.parse(trackedShipments);
                        let index = tempArr.findIndex(
                          (it) =>
                            it.trackID ===
                            this.state.trackID
                        );
                        if (index > -1 && index < tempArr.length - 1) {
                          this.props.history.push(
                            "/trackassured-shipment-profile/" +
                            tempArr[index + 1].assetID +
                            "/" +
                            tempArr[index + 1].dispatchUUID +
                            "/" +
                            tempArr[index + 1].trackType +
                            "/" +
                            tempArr[index + 1].trackID
                          );
                        }
                        if (tempArr.length == index + 1) {
                          showInfo("No More data to show.");
                        }
                      } else {
                        this.setState({
                          loaded: true,
                        });
                        let s = { ...this.state.currentDispatch };
                        let p = { ...this.props };
                        let companyUid = p.user.company.uuid;
                        let str = "";
                        const keys = Object.keys(s);
                        for (const key of keys) {
                          str += s[key];
                        }
                        var data = {
                          page: 0,
                          pageSize: 20,
                          sorted: [],
                          filtered: [],
                        };
                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                        searchNextShipments(
                          companyUid,
                          timeZone,
                          str,
                          data,
                          (response) => {
                            this.setState({
                              loaded: false,
                            });
                            this.props.history.push(
                              "/trackassured-shipment-profile/" +
                              response.assetID +
                              "/" +
                              response.dispatchUUID
                            );
                          }
                        );
                      }
                    }}
                  >
                    <i className="fa fa-chevron-right"></i>
                  </Button>
                </div>

                <div className="mb-4">

                  <strong>
                    {this.state.trackType} : {this.state.trackID}
                  </strong>
                </div>




                <h3 className={"font-weight-bold text-center mt-4"}>
                  Current Status : <span className={"text-danger"}>Shipment Not Found</span>
                </h3>


              </CardBody>
            </Card>

          </div>
        }
      </Loader>
    );
  }

  getBroker() {
    if (this.state.shipmentProfile.shipment) {
      if (this.state.shipmentProfile.shipment.broker) {
        return ", " + this.state.shipmentProfile.shipment.broker;
      } else {
        if (
          this.state.shipmentProfile.gateEvents &&
          this.state.shipmentProfile.gateEvents.length > 0 &&
          this.state.shipmentProfile.gateEvents[0].broker
        ) {
          return this.state.shipmentProfile.gateEvents[0].broker.name;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }

  getCarrier() {
    if (this.state.shipmentProfile.shipment) {
      if (this.state.shipmentProfile.shipment.carrier) {
        return this.state.shipmentProfile.shipment.carrier.name;
      } else {
        if (
          this.state.shipmentProfile.gateEvents &&
          this.state.shipmentProfile.gateEvents.length > 0 &&
          this.state.shipmentProfile.gateEvents[0].carrier
        ) {
          return this.state.shipmentProfile.gateEvents[0].carrier.name;
        } else {
          return "";
        }
      }
    } else {
      return "";
    }
  }

  getTsps() {
    let providers = "";
    if (
      this.state.shipmentProfile.transportationServiceProviders !== null &&
      typeof this.state.shipmentProfile.transportationServiceProviders[
      Symbol.iterator
      ] === "function"
    ) {
      for (const provider of this.state.shipmentProfile
        .transportationServiceProviders) {
        providers += provider + " ";
      }
    }
    return providers;
  }

  displayShipmentProfile(shipmentId) {
    this.props.history.push("/shipment-profile/" + shipmentId);
  }

  hasForcedDispatch(textMessageEvents) {
    if (textMessageEvents) {
      for (const textMessageEvent of textMessageEvents) {
        if (textMessageEvent.forcedDeparture) return true;
      }
    }
    return false;
  }
}

export default withRouter(ShipmentTAProfile);
