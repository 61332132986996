import {
  clientResponseByTaskUuid,
  downloadTaskHistory,
  getSecondaryReferenceByRefID,
  getTaskReferenceByRefID,
  requestTaskHistoryToExcel,
} from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { getFormattedDwellDateString } from "../../assets/components/StaticDurationDisplay";
import React, { Component } from "react";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { getTemp, isF, getTempSetpoint } from "./Tasks";
import * as PropTypes from "prop-types";
import TableDateRangePickerGO from "../../common/TableDateRangePickerGo";
import LoaderIco from "react-loader";
import Loader from "react-loader-advanced";

import {
  endOfDay,
  getLocalStorageName,
  getTableSize,
  startOfDay,
  startOfDayEST,
  endOfDayEST
} from "../../common/utilities";
import { Button, Tooltip } from "reactstrap";
import { showError, showInfo } from "../../MainApp";
import ReferenceModal from "./ReferenceModal";
import FlagModal from "../../common/FlagModal";
import IconWithTooltip from "../../common/IconWithTooltip";

class TaskHistory extends Component {
  handleClickComments = (rowindex) => {
    if (this.state.istab === true) {
      this.setState({ Showhover: true });
      if (this.state.clickedRowIndex === rowindex) {
        this.setState((prevState) => ({
          showTooltip: !prevState.showTooltip,
          clickedRowIndex: rowindex,
        }));
      } else {
        this.setState({
          showTooltip: true,
          clickedRowIndex: rowindex,
        });
      }
    }

    console.log("tooltip", this.state.showTooltip);
  };
  tableColumns = [
    {
      accessor: "taskLocationName",
      Header: "Location Name",
      filterable: true,
      sortable: true,
      default: false,
      show: false,

    },
    // {
    //   accessor: "statusSource",
    //   Header: "Alert Status Source",
    //    default: false,
    //   filterable: false,
    //   sortable: false,
    //   Cell: (row) => {
    //     let v = row.original;
    //     return v.statusSource !== "" ? v.statusSource : 'UI'
    //   }
    // },
    {
      accessor: "locationNickName",
      Header: "Location Nickname",
      filterable: true,
      sortable: true,
      default: false,
      show: false,

    },
    {
      accessor: "locationCode",
      Header: "Location Code",
      filterable: true,
      sortable: true,
      default: false,
      show: false,

    },
    {
      accessor: "taskType",
      Header: "Task Type",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "taskStatus",
      Header: "Task Status",
      default: true,
      filterable: true,
      sortable: true,
      maxWidth: 200,
      Cell: (row) => {
        let cancelCode = null;

        if (row.original.taskStatus === "Canceled") {
          if (row.original.cancelReasonName) {
            cancelCode = " (" + row.original.cancelReasonName + ")";
          }
        }
        return <>{row.original.taskStatus}</>;
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.taskStatusFilterRef}
            onChange={(event) => {
              this.setState({ taskStatusFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.taskStatusFilter
                ? this.state.taskStatusFilter
                : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="n/a">All</option>
            <option value="Complete">Complete</option>
            <option value="Canceled">Canceled</option>
          </select>
        </>
      ),
    },
    {
      accessor: "createdBy",
      Header: "Task Created By User",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "lastModifiedBy",
      Header: "Last Updated By User",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "assigneeName",
      Header: "Assignee",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "taskShift",
      Header: "Assignee Shift",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "createdDate",
      Header: "Task Created Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.createdDate) {
          return (
            <MomentDisplay
              date={task.createdDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "assignDate",
      Header: "Assign Datetime",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.assignDate) {
          return (
            <MomentDisplay
              date={task.assignDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "acceptDate",
      Header: "Task Accept Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.acceptDate) {
          return (
            <MomentDisplay
              date={task.acceptDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "taskStartDate",
      Header: "Task Start Datetime",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.taskStartDate) {
          return (
            <MomentDisplay
              date={task.taskStartDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return "Not Started";
        }
      },
    },
    {
      accessor: "taskEndDate",
      Header: "Task End Datetime",
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.taskEndDate) {
          return (
            <MomentDisplay
              date={task.taskEndDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      accessor: "completedDate",
      Header: "Task Complete Datetime",
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.completedDate) {
          return (
            <MomentDisplay
              date={task.completedDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      accessor: "lastModified",
      Header: "Last Updated Datetime",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.lastModified) {
          return (
            <MomentDisplay
              date={task.lastModified}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "canceledDate",
      Header: "Cancel Datetime",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.canceledDate) {
          return (
            <MomentDisplay
              date={task.canceledDate}
              timezone={task.locationTimezone}
            />
          );
        } else {
          return <div />;
        }
      },
    },
    {
      accessor: "huntTime",
      Header: "Hunt Time",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getFormattedDwellDateString(row.original.huntTime);
      },
    },
    {
      accessor: "duration",
      Header: "Duration",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getFormattedDwellDateString(row.original.duration);
      },
    },
    {
      accessor: "totalTaskTime",
      Header: "Total Task Time",
      default: false,
      show: false,

      filterable: false,
      sortable: true,
      Cell: (row) => {
        return getFormattedDwellDateString(row.original.totalTaskTime);
      },
    },
    {
      accessor: "trailerName",
      Header: "Trailer Name",
      filterable: true,
      default: true,
      sortable: true,
    },
    {
      accessor: "trailerNumber",
      Header: "Trailer Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerNumber ? row.original.trailerNumber.replace(/ /g, '').toUpperCase() : "";
      },
    },

    {
      accessor: "moveFromSpot",
      Header: "Move From Zone-Spot",
      filterable: true,
      default: false,
      show: false,

      sortable: true,
    },
    {
      accessor: "moveFromSpotType",
      Header: "Move From Spot Type",
      filterable: true,
      default: true,
      sortable: true,
    },
    {
      accessor: "moveFromZone",
      Header: "Move From Zone Name",
      filterable: true,
      default: true,
      sortable: true,
    },
    {
      accessor: "moveFromName",
      Header: "Move From Spot#",
      filterable: true,
      default: true,
      sortable: true,
    },
    {
      accessor: "boxedIn",
      Header: "Boxed In",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "movedToSpot",
      Header: "Move To Zone-Spot",
      filterable: true,
      sortable: true,
      default: false,
      show: false,

    },
    {
      accessor: "spotType",
      Header: "Move To Spot Type",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "moveToZone",
      Header: "Move To Zone Name",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "spotName",
      Header: "Move To Spot#",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "assetType",
      Header: "Trailer Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assetStatus",
      Header: "Trailer Status",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "shipmentNumber",
      Header: "Shipment Number",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "sealed",
      Header: "Sealed",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.sealed ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.sealedFilterRef}
            onChange={(event) => {
              this.setState({ sealedFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.sealedFilter ? this.state.sealedFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
      ),
    },
    {
      accessor: "sealNumber",
      Header: "Seal Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return (
          <span title={row.original.sealNumber}>{row.original.sealNumber}</span>
        );
      },
    },
    {
      accessor: "comments",
      Header: "Comments",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        const targetId = `commentsCell_${row.index}`;

        return (
          <span
            id={targetId}
            title={this.state.Showhover ? " " : row.original.comments}
            onClick={() => {
              this.handleClickComments(row.index);
            }}
          >
            {this.state.showTooltip &&
              this.state.clickedRowIndex == row.index && (
                <Tooltip
                  style={{ color: "#fff" }}
                  target={targetId}
                  isOpen={this.state.showTooltip}
                  placement="right"
                >
                  {row.original.comments}
                </Tooltip>
              )}
            {console.log(this.state.Showhover, "titlestate")}
            {row.original.comments}
          </span>
        );
      },
    },
    {
      accessor: "fuelLevel",
      Header: "Fuel Level",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.fuelLevel ? row.original.fuelLevel : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.fuelFilterRef}
            onChange={(event) => {
              this.setState({ fuelFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.fuelFilter ? this.state.fuelFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Empty">Empty</option>
            <option value="1/8">1/8</option>
            <option value="1/4">1/4</option>
            <option value="3/8">3/8</option>
            <option value="1/2">1/2</option>
            <option value="5/8">5/8</option>
            <option value="3/4">3/4</option>
            <option value="7/8">7/8</option>
            <option value="Full">Full</option>
          </select>
        </>
      ),
    },
    {
      accessor: "reefer",
      Header: "Reefer (On/Off )",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.reefer == true ? "Yes" : row.original.reefer == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.reeferFilterRef}
            onChange={(event) => {
              this.setState({ reeferFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.reeferFilter ? this.state.reeferFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "continous",
      Header: "Continuous (Yes/No)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.continous == true ? "Yes" : row.original.continous == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.continousFilterRef}
            onChange={(event) => {
              this.setState({ continousFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.continousFilter ? this.state.continousFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "temperatureSetPointInc",
      Header: "Temp - Setpoint" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTempSetpoint(row, this.props.user);
      },
    },

    {
      accessor: "temperatureInc",
      Header: "Temp - Actual" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTemp(row, null, this.props.user);
      },
    },
    {
      accessor: "temperatureDeviationInc",
      Header: "Temp - Deviation" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        return getTemp(
          row,
          row.original.temperatureSetPointInc,
          this.props.user
        );
      },
    },
    {
      accessor: "arrivalTime",
      Header: "Asset Arrival",
      cleanHeader: "Asset Arrival",

      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.arrivalTime) {
          return (
            <MomentDisplay
              date={task.arrivalTime}
              timezone={
                task.locationTimezone

              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {

      accessor: "dwellTime",
      Header: "Dwell Time",
      cleanHeader: "Dwell Time",
      default: true,
      filterable: false,
      sortable: true,
    },
    {
      accessor: "departTime",
      Header: "Asset Departure",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.departTime) {
          return (
            <MomentDisplay
              date={task.departTime}
              timezone={
                task.locationTimezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "cancelReasonName",
      Header: "Cancel Reason",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assignmentLat",
      Header: "Task Accept Latitude",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "assignmentLon",
      Header: "Task Accept Longitude",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "startLat",
      Header: "Task Start Latitude",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "startLong",
      Header: "Task Start Longitude",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "endLat",
      Header: "Task End Latitude",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      accessor: "endLon",
      Header: "Task End Longitude",
      default: false,
      show: false,

      filterable: true,
      sortable: true,
    },
    {
      Header: "Location Billing Code",
      accessor: "locationBillingCode",
      filterable: true,
      sortable: true,
      default: false,
      show: false,

    },

    // {
    //   accessor: "secondaryRef",
    //   Header: "Reference Number",
    //   default: true,
    //   filterable: false,
    //   sortable: false,

    //   // Cell: (row) => {
    //   //   let v = row.original;

    //   //   return row.value.split(",").map((elem, index) => (
    //   //     <a href={"javascript:;"} onClick={() => this.openRefModal(v, elem)}>
    //   //       {" "}
    //   //       {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //   //         }`}
    //   //       {row.value.split(",").length - 1 === index ? "" : ","} <br />
    //   //     </a>
    //   //   ));
    //   // },
    //   Cell: (row) => {
    //     if (row.value !== null && row.value !== '' && row.value !== undefined) {
    //       let v = row.original;
    //       return row.value.split(",").map((elem, index) => (
    //         <a href={"javascript:;"} onClick={() => this.openRefModal(v, elem)}>
    //           {" "}
    //           {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //             }`} */}
    //           {`${elem}`}
    //           {row.value.split(",").length - 1 === index ? "" : ","} <br />
    //         </a>
    //       ));
    //     }
    //     else {
    //       return 'N/A'
    //     }
    //   },
    //   // Cell: (row) => {
    //   //   let v = row.original;
    //   //   // console.log("v", v);
    //   //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null? "": row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //   //     }`}</a>
    //   // }
    // },

    // {
    //     accessor: 'createdDate',
    //     Header: 'Task Created Date Time',
    //     default: true,
    //     filterable: false,
    //     sortable: true,
    //     dataType: 'date',
    //     operator: 'GT',
    //     sortable: true,
    //     Cell: row => {
    //         let task = row.original;
    //         if (task.createdDate) {
    //             return <MomentDisplay date={task.createdDate}
    //             timezone={task.locationTimezone} />;
    //         } else {
    //             return null;
    //         }
    //     }
    // },

    // {
    //   accessor: "primaryReferenceNumber",
    //   Header: "Reference Number",
    //   default: true,
    //   filterable: false,
    //   sortable: false,

    //   Cell: (row) => (
    //     <a
    //       href={"javascript:;"}
    //       onClick={() => alert(row.original.primaryReferenceID)}
    //     >
    //       {" "}
    //       {row.value}{" "}
    //     </a>
    //   ),
    // },

    // {
    //   accessor: "sealed",
    //   Header: "Sealed",
    //   default: true,
    //   filterable: true,
    //   sortable: true,
    //   Cell: (row) => {
    //     return row.original.sealed ? "Yes" : "No";
    //   },
    //   Filter: ({ filter, onChange }) => (
    //     <>
    //       <select
    //         ref={this.sealedFilterRef}
    //         onChange={(event) => {
    //           this.setState({ sealedFilter: event.target.value });
    //           onChange(event.target.value);
    //         }}
    //         style={{ width: "100%" }}
    //         value={
    //           this.state.sealedFilter ? this.state.sealedFilter : undefined
    //         }
    //       >
    //         <option value="undefined" hidden={true}>
    //           Select
    //         </option>
    //         <option value="">All</option>
    //         <option value="true">Yes</option>
    //         <option value="false">No</option>
    //       </select>
    //     </>
    //   ),
    // },

    {
      accessor: "canceledBy",
      Header: "Canceled by User",
      default: true,
      filterable: false,
      sortable: false,
    },

  ];
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.sealedFilterRef = React.createRef();
    this.reeferFilterRef = React.createRef();
    this.fuelFilter = React.createRef();
    this.continousFilterRef = React.createRef();
    this.taskStatusFilterRef = React.createRef();
    const parse = JSON.parse(
      localStorage.getItem(
        getLocalStorageName("poweryard-task-history", props.user)
      ),
      this.mapFromJson
    );

    this.state = {
      hideFilters: true,
      showTooltip: false,
      isdisabled: false,
      clickedRowIndex: 0,
      Showhover: true,
      istab: false,
      goclick: false,
    };
    // const parse = JSON.parse(localStorage.getItem("poweryard-task-history"));
    if (parse) {
      this.state = {
        name: null,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            { id: "start", value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "createdDate", operator: "EQ" },
          ],
        },
        sealedFilter: parse.sealedFilter,
        reeferFilter: parse.reeferFilter,
        fuelFilter: parse.fuelFilter,
        continousFilter: parse.continousFilter,
        taskStatusFilter: "undefined",
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "createdDate",
        dateFilterEnabled: true,
        tableSize: parse.tableSize,
        loaded: false,
        autoRefreshEnabled: parse.autoRefreshEnabled,
        selectedTask: {
          refData: {
            prNumber: null,
            prName: null,
            prAppointmentDate: null,
            prAppointmentWindow: null,
            prAppointmentType: null,
            prAppointmentPlan: null,
            prScac: null,
            prRefIdFlagDate: null,
            cancelled: null,
            prRefIdFlag: null,
            uuid: null,
            carrier: {},
            location: null,
            secondaryRef: "",
          },
        },
        flagData: {
          respondingSystem: null,
          reasonCode: null,
          Description: null,
          Datetime: null,
        },
      };
    } else {
      this.state = {
        previousSearchRequest: {
          sorted: [{ id: "start", desc: "true" }],
          filtered: [
            // { id: "taskStatus", value: "Complete,Canceled" },
            {
              id: "start",
              value: startOfDay(
                new Date(new Date().setDate(new Date().getDate() - 7))
              ),
              operator: "GT",
            },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "createdDate", operator: "EQ" },
          ],
        },
        filterStartDate: startOfDayEST(
          new Date()
        ),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "createdDate",
        tableSize: { height: "500px", width: "800px" },
        dateFilterEnabled: true,
        autoRefreshEnabled: false,
        selectedTask: {
          refData: {
            prNumber: null,
            prName: null,
            prAppointmentDate: null,
            prAppointmentWindow: null,
            prAppointmentType: null,
            prAppointmentPlan: null,
            prScac: null,
            prRefIdFlagDate: null,
            cancelled: null,
            prRefIdFlag: null,
            uuid: null,
            carrier: {},
            location: null,
            secondaryRef: "",
          },
        },
        flagData: {
          respondingSystem: null,
          reasonCode: null,
          Description: null,
          Datetime: null,
        },
      };
    }
    if (!this.state.filterEndDate) {
      this.state.filterEndDate = endOfDay(new Date());
    }
    if (!this.state.filterStartDate) {
      this.state.filterStartDate = startOfDay(
        new Date(new Date().setDate(new Date().getDate() - 7))
      );
    }
    if (!this.state.filterDateType) {
      this.state.filterDateType = "createdDate";
    }
    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "800px" };
    }
    if (
      this.props.currentLocation.apiSetting !== null
        ? this.props.currentLocation.apiSetting.refernceApi
        : false
    ) {
      let length = this.tableColumns.length;
      if (length != 0) {
        this.tableColumns = this.tableColumns;
        let item = {
          accessor: "secondaryRef",
          Header: "Reference Number",
          default: true,
          filterable: true,
          sortable: false,
          show: true,
          cleanHeader: "Reference Number",
          //  Cell: row => <ReferencePopup isPopoverOpen={true} primaryReferenceNumber={row.original.primaryReferenceNumber} primaryRefId={row.original.primaryReferenceID}/>
          // Cell: (row) => {
          //   let v = row.original;
          //   // console.log("v", v);
          //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null ? "" : row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
          //     }`}</a>
          // }
          Cell: (row) => {
            if (
              row.value !== null &&
              row.value !== "" &&
              row.value !== undefined
            ) {
              let v = row.original;
              return row.value.split(",").map((elem, index) => (
                <a
                  href={"javascript:;"}
                  onClick={() => this.openRefModal(v, elem.trim())}
                >
                  {" "}
                  {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
                    }`} */}
                  {`${elem}`}
                  {row.value.split(",").length - 1 === index ? "" : ","} <br />
                </a>
              ));
            } else {
              return "N/A";
            }
          },
        };
        let item1 = {
          show: false,
          cleanHeader: "Alert",
          accessor: "statusFlag",
          Header: "Alert",
          default: false,
          filterable: false,
          sortable: false,
          Cell: (row) => {
            let taskObj = row.original;
            return (
              taskObj.statusFlag && (
                <div className="text-center">
                  {/* <i class="fas fa-flag text-danger c-pointer" ></i> */}
                  {/* <Tooltip placement="right" target={`ID_${v.taskUuid}`} isOpen={this.state.tooltipOpen} toggle={this.toggle}>
                  {'System Response to this Task, click for details'}
                </Tooltip> */}
                  {taskObj.statusFlag && (
                    <IconWithTooltip
                      isToolTip={true}
                      isIcon={true}
                      isClickAble={true}
                      toolTipTxt={
                        "System Response to this Task, click for details"
                      }
                      classList={"fas fa-flag text-danger c-pointer"}
                      placement="top"
                      onClick={() => {
                        this.onClickIcon(taskObj);
                      }}
                    />
                  )}
                </div>
              )
            );
          },
        };
        this.tableColumns.splice(length >= 3 ? 3 : 0, 0, item, item1);
      }
      //For Assersorials
    }
    // if (
    //   this.props.currentLocation.enableArrivalLog  !== null
    //     ? this.props.currentLocation.enableArrivalLog 
    //     : false
    // ) {
    //   let length = this.tableColumns.length;
    //   if (length != 0) {
    //     this.tableColumns = this.tableColumns;
    //     let item = {
    //       accessor: "arrivalTime",
    //       Header: "Asset Arrival",
    //       cleanHeader: "Asset Arrival",

    //       default: true,
    //       filterable: false,
    //       sortable: true,
    //       dataType: "date",
    //       operator: "GT",
    //       sortable: true,
    //       Cell: (row) => {
    //         let task = row.original;
    //         if (task.arrivalTime) {
    //           return (
    //             <MomentDisplay
    //               date={task.arrivalTime}
    //               timezone={
    //                 task.locationTimezone 

    //               }
    //             />
    //           );
    //         } else {
    //           return null;
    //         }
    //       },
    //     }
    //     let item1 = {

    //       accessor: "dwellTime",
    //       Header: "Dwell Time",
    //       cleanHeader: "Dwell Time",
    //       default: true,
    //       filterable: false,
    //       sortable: true,
    //     }
    //     let item2 =  {
    //       accessor: "departTime",
    //       Header: "Asset Departure",
    //       default: true,
    //       filterable: false,
    //       sortable: true,
    //       dataType: "date",
    //       operator: "GT",
    //       sortable: true,
    //       Cell: (row) => {
    //         let task = row.original;
    //         if (task.departTime) {
    //           return (
    //             <MomentDisplay
    //               date={task.departTime}
    //               timezone={
    //                 task.locationTimezone 
    //               }
    //             />
    //           );
    //         } else {
    //           return null;
    //         }
    //       },
    //     }
    //     this.tableColumns.splice(length >= 13 ? 13 : 0, 0, item, item1,item2);
    //   }
    //   //For Assersorials
    //  }
    this.addOrRemovePriorityTableColumn();

    // this.addOrRemoveTableColumn();
  }
  addOrRemoveTableColumn() {
    let s = this.tableColumns;
    let secRefIndex = 0;
    let statusFlagIndex = 0;
    let dwellIndex = 0;
    let arrivalIndex = 0;


    let length = s.length;

    let isRefApiOn =
      this.props.currentLocation.apiSetting !== null
        ? this.props.currentLocation.apiSetting.refernceApi
        : false;
    let isDwellPiOn = this.props.currentLocation.enableArrivalLog !== null
      ? this.props.currentLocation.enableArrivalLog
      : false;
    secRefIndex = s.findIndex((it) => it.accessor === "secondaryRef");
    statusFlagIndex = s.findIndex((it) => it.accessor === "statusFlag");
    arrivalIndex = s.findIndex((it) => it.accessor === "arrivalTime");
    dwellIndex = s.findIndex((it) => it.accessor === "dwellTime");
    let arrivalobj = {
      accessor: "arrivalTime",
      Header: "Asset Arrival",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.arrivalTime) {
          return (
            <MomentDisplay
              date={task.arrivalTime}
              timezone={
                this.props.currentLocation &&
                this.props.currentLocation.timezone
              }
            />
          );
        } else {
          return null;
        }
      },
    }
    let dwellobj = {
      accessor: "dwellTime",
      Header: "Dwell Time",
      default: true,
      filterable: false,
      sortable: true,
    }
    let secRefObj = {
      cleanHeader: "Reference Number",
      accessor: "secondaryRef",
      Header: "Reference Number",
      default: true,
      filterable: true,
      sortable: false,
      show: true,
      //  Cell: row => <ReferencePopup isPopoverOpen={true} primaryReferenceNumber={row.original.primaryReferenceNumber} primaryRefId={row.original.primaryReferenceID}/>
      // Cell: (row) => {
      //   let v = row.original;
      //   // console.log("v", v);
      //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null ? "" : row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
      //     }`}</a>
      // }
      Cell: (row) => {
        if (row.value !== null && row.value !== "" && row.value !== undefined) {
          let v = row.original;
          return row.value.split(",").map((elem, index) => (
            <a
              href={"javascript:;"}
              onClick={() => this.openRefModal(v, elem.trim())}
            >
              {" "}
              {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
                }`} */}
              {`${elem}`}
              {row.value.split(",").length - 1 === index ? "" : ","} <br />
            </a>
          ));
        } else {
          return "N/A";
        }
      },
    };
    let statusFlagObj = {
      show: true,
      cleanHeader: "Alert",
      accessor: "statusFlag",
      Header: "Alert",
      default: true,
      filterable: false,
      sortable: false,
      Cell: (row) => {
        let taskObj = row.original;
        return (
          taskObj.statusFlag && (
            <div className="text-center">
              {/* <i class="fas fa-flag text-danger c-pointer" ></i> */}
              {/* <Tooltip placement="right" target={`ID_${v.taskUuid}`} isOpen={this.state.tooltipOpen} toggle={this.toggle}>
            {'System Response to this Task, click for details'}
          </Tooltip> */}
              {taskObj.statusFlag && (
                <IconWithTooltip
                  isToolTip={true}
                  isIcon={true}
                  isClickAble={true}
                  toolTipTxt={"System Response to this Task, click for details"}
                  classList={"fas fa-flag text-danger c-pointer"}
                  placement="top"
                  onClick={() => {
                    this.onClickIcon(taskObj);
                  }}
                />
              )}
            </div>
          )
        );
      },
    };

    if (isRefApiOn) {
      if (secRefIndex === -1) {
        s.splice(length >= 4 ? 4 : 0, 0, secRefObj);
      } else {
        s.splice(secRefIndex, 1);
      }
      if (statusFlagIndex === -1) {
        s.splice(length >= 5 ? 5 : 0, 0, statusFlagObj);
      } else {
        s.splice(statusFlagIndex, 1);
      }
    } else {
      if (secRefIndex > -1 && statusFlagIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = secRefIndex;
        removeIndexArr[1] = statusFlagIndex;

        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }
    if (isDwellPiOn) {
      if (arrivalIndex === -1) {
        s.splice(length >= 13 ? 13 : 0, 0, arrivalobj);
      } else {
        s.splice(arrivalIndex, 1);
      }
      if (dwellIndex === -1) {
        s.splice(length >= 14 ? 14 : 0, 0, dwellobj);
      } else {
        s.splice(dwellIndex, 1);
      }
    } else {
      if (arrivalIndex > -1 && dwellIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = arrivalIndex;
        removeIndexArr[1] = dwellIndex;

        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }
    this.setState({ tableColumns: s });
    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }
  addOrRemovePriorityTableColumn() {
    let s = this.tableColumns;

    let PriorityIndex = 0;


    let length = s.length;
    PriorityIndex = s.findIndex((it) => it.accessor === "priority");





    let priorityObj = {
      cleanHeader: "Task Priority",
      Header: "Task Priority",
      accessor: "priority",

      filterable: true,
      default: true,
      sortable: false,
      show: true,
      Cell: (row) => {
        if (row.original.priority == 1) {
          return "Low";
        } else if (row.original.priority == 3) {
          return "High";
        } else {
          return "Normal";
        }
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={(event) => {
              this.setState({ enabledFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={this.state.enabledFilter}
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="3">High</option>
            <option value="2">Normal</option>
            <option value="1">Low</option>
          </select>
        </>
      ),
    };


    if (this.props.currentLocation.enablePriority == true) {
      if (PriorityIndex === -1) {
        s.splice(length >= 3 ? 3 : 0, 0, priorityObj);
      }

    } else {
      if (PriorityIndex > -1) {
        let removeIndexArr = [];
        removeIndexArr[0] = PriorityIndex;


        console.log("removeINdexArr", removeIndexArr);
        for (var i = removeIndexArr.length - 1; i >= 0; i--)
          s.splice(removeIndexArr[i], 1);
      }
    }

    this.setState({ tableColumns: s });

    if (this.tableRef.current !== null && this.tableRef.current !== undefined) {
      this.tableRef.current.refreshColumns();
    }
  }
  onClickIcon(taskObj) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    this.getFlagDataByTakUuid(taskObj);
  }

  getFlagDataByTakUuid(taskObj) {
    let ref = {
      system: undefined,
      reasonCode: undefined,
      description: undefined,
      responseDate: new Date(),
      taskName: undefined,
    };
    clientResponseByTaskUuid(
      taskObj.taskUuid,
      (success) => {
        if (success.code === "200") {
          ref = success.content;
          this.setState({
            isFalRefModalOpen: true,
            flagData: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          this.setState({ loaded: false });
          showError(success.message);
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }

  openRefModal(ref, elem) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = `${elem.split(" ")[0]}  (${elem.split(" ")[1]})`;
    this.onGetRefDataByRefID(ref, elem.split(" ")[0]);
  }
  onGetRefDataByRefID(ref, elem) {
    getSecondaryReferenceByRefID(
      elem.trim(),
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }
  componentDidMount() {
    console.log(this.props.currentLocation.enableArrivalLog, "enabless");

    window.addEventListener("resize", this.calculateTableSize.bind(this));
    window.scrollTo(0, 0);
    this.calculateTableSize();
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    if (isTablet === true) {
      const datePickers = document.getElementsByClassName(
        "react-datepicker__input-container"
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute("readOnly", true)
      );
      this.setState({ istab: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTableSize.bind(this));
    localStorage.setItem(
      getLocalStorageName("poweryard-task-history", this.props.user),
      JSON.stringify({
        sealedFilter: this.state.sealedFilter,
        reeferFilter: this.state.reeferFilter,
        fuelFilter: this.state.fuelFilter,
        continousFilter: this.state.continousFilter,
        //taskStatusFilter: this.state.taskStatusFilter,
        previousSearchRequest: this.state.previousSearchRequest,
        filterStartDate: this.state.filterStartDate,
        filterEndDate: this.state.filterEndDate,
        filterDateType: this.state.filterDateType,
        dateFilterEnabled: this.state.dateFilterEnabled,
      })
    );
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  savePageState() {
    localStorage.setItem(
      getLocalStorageName("poweryard-task-history", this.props.user),
      JSON.stringify({
        previousSearchRequest: this.state.previousSearchRequest,
        //taskStatusFilter: this.state.taskStatusFilter,
        filterStartDate: this.state.filterStartDate,
        filterEndDate: this.state.filterEndDate,
        filterDateType: this.state.filterDateType,
        dateFilterEnabled: this.state.dateFilterEnabled,
        autoRefreshEnabled: this.state.autoRefreshEnabled,
      })
    );
  }
  exportTaskHistory() {
    this.setState({ isdisabled: true, loaded: true });
    this.updateSpinner("Exporting...");
    if (this.state.previousSearchRequest) {
      requestTaskHistoryToExcel(
        this.state.previousSearchRequest,
        (res) => {
          if (
            res.content &&
            res.content.message &&
            res.content.message.includes("s3.amazonaws.com")
          ) {
            window.location.href = res.content.message;
          } else {
            if (res.code === "1001" || res.code === "1000") {
              showError(res.message);
            } else {
              showError(
                "We are not able to generate the excel file at this time.  Please try again later."
              );
            }
          }
          setTimeout(() => {
            this.setState({ isdisabled: false, loaded: false });
          }, 3000);
        },
        (e) => {
          console.log(e);
          showError(
            "We are not able to generate the excel file at this time.  Please try again later."
          );
          this.setState({ isdisabled: false, loaded: false });
        }
      );
    }
  }
  calculateTableSize = () => {
    let size = getTableSize(
      "power-yard-task-history-page",
      "power-yard-task-history-header"
    );
    this.setState({ tableSize: { height: size.height, width: size.width } });
  };
  render() {
    return (
      <div
        className="column-wrapper text-left"
        id="power-yard-task-history-page"
      >
        <div className="page-header" id={"power-yard-task-history-header"}>
          <h1>Task History</h1>
        </div>
        <Loader show={this.state.loaded} message={this.spinner}>

          {this.state.selectedTask !== undefined && (
            <ReferenceModal
              selectedTask={this.state.selectedTask}
              currentLocation={this.props.currentLocation}
              cancel={() => this.setState({ isRefModalOpen: false })}
              isRefModalOpen={this.state.isRefModalOpen}
            />
          )}
          {this.state.flagData !== undefined && (
            <FlagModal
              flagData={this.state.flagData}
              currentLocation={this.props.currentLocation}
              cancel={() => this.setState({ isFalRefModalOpen: false })}
              isRefModalOpen={this.state.isFalRefModalOpen}
            />
          )}
          <PaginationFilterTable
            dateFilterEnabled={this.state.dateFilterEnabled}
            onTaskHistory={true}

            setloaded={(check) => {
              this.updateSpinner("");
              this.setState({ loaded: check });
              this.updateSpinner("");
            }}
            setFilterToogle={(value) => {
              this.setState({
                hideFilters: value,

              });
            }}
            openFiltersCollaps={true}
            showEditColumns
            user={this.props.user}
            style={{
              maxHeight: this.state.tableSize.height,
              maxWidth: this.state.tableSize.width,
              minHeight: "120px",
            }}
            ref={this.tableRef}
            autoRefreshEnabled={this.state.autoRefreshEnabled}
            autoRefreshInterval={120000}
            pageType="Admin"
            get={downloadTaskHistory}
            stateStorage={"poweryard-task-history"}
            defaultSorted={[]}
            filtered={this.state.previousSearchRequest.filtered}
            onFilterHide={() => {
              this.setState({
                taskStatusFilter: "undefined",
                enabledFilter: "undefined",
                sealedFilter: "undefined",
                reeferFilter: "undefined",
                fuelFilter: "undefined",
                continousFilter: "undefined",
              });
            }}
            onFilteredChange={(filter) => {
              let index = filter.findIndex((it) => it.id === "taskStatus");
              if (index > -1) {
                let check = filter[index].value;
                if (check === "n/a") {
                  filter.splice(index, 1);
                }
              }
              const delayFilters = ['taskStatus', 'fuelLevel', 'priority', 'boxedIn', 'sealed', 'reefer', 'continous'];
              let psr = { ...this.state.previousSearchRequest };
              psr.filtered = filter;
              const hasDelayFilter = psr.filtered.some(filter => delayFilters.includes(filter.id));
              this.setState({ previousSearchRequest: psr }, () => {
                if (hasDelayFilter) {
                  this.updateSpinner("");

                  this.tableRef.current.requestPageSearch();
                } else {
                  setTimeout(() => {
                    this.updateSpinner("");

                    this.tableRef.current.requestPageSearch();
                  }, 3000); // 3000 milliseconds = 4 seconds
                }

                // this.tableRef.current.requestPageSearch();
              });
            }}
            successCallback={(response) => {
              this.setState({ hasResults: response.totalElements > 0 });
            }}
            clearFilter={(resetAll) => {
              if (resetAll) {
                let psr = { ...this.state.previousSearchRequest };
                psr.filtered = [];
                // this.taskStatusFilterRef.current.value = undefined;

                this.setState(
                  {
                    filterStartDate: startOfDayEST(new Date()),
                    filterEndDate: endOfDayEST(new Date()),
                    dateFilterEnabled: true,
                    previousSearchRequest: psr,
                  },
                  () => {
                    // this.tableRef.current.restoreColumns(true);
                    this.updateSpinner("");
                    this.tableRef.current.setState({ page: 0, forcePage: 0 });
                    this.updateProperty("start", this.state.filterStartDate);
                    this.updateProperty("end", this.state.filterEndDate);
                    this.updateProperty(
                      "dateFilterField",
                      this.state.filterDateType
                    );
                  }
                );
              } else {
                let psr = { ...this.state.previousSearchRequest };
                psr.filtered = psr.filtered.filter(
                  (f) =>
                    f.id === "start" ||
                    f.id === "end" ||
                    f.id === "dateFilterField"
                );
                // this.taskStatusFilterRef.current.value = undefined;
                this.setState(
                  {
                    previousSearchRequest: psr,
                  },
                  () => {
                    if (this.state.hideFilters == false) {
                      this.updateSpinner("");
                      this.tableRef.current.requestPageSearch()
                    }

                    // this.updateSpinner("");
                    // this.tableRef.current.requestPageSearch()
                  }
                );
              }
            }}
            columns={this.tableColumns}
            onChange={(state) => {
              this.setState({
                previousSearchRequest: {
                  dateFilter: state.dateFilter,
                  itemsPerPage: state.itemsPerPage,
                  page: state.page,
                  pageSize: state.pageSize,
                  sorted: state.sorted,
                  filtered: state.filtered,
                },
              });
            }}
            footerButton={
              <Button
                disabled={!this.state.hasResults || this.state.isdisabled}
                color="secondary"
                onClick={() => {
                  this.exportTaskHistory();
                }}
              >
                <i className="fa fa-file-excel" /> Export to Excel
              </Button>
            }
          >
            <TableDateRangePickerGO
              showTaskSelector={true}
              start={this.state.filterStartDate}
              end={this.state.filterEndDate}
              filterEnabled={this.state.dateFilterEnabled}
              dateType={this.state.filterDateType}
              onDateFilterEnable={(enabled) => {
                this.setState({ dateFilterEnabled: enabled });
                if (enabled) {
                  this.updateProperty("start", this.state.filterStartDate);
                  this.updateProperty("end", this.state.filterEndDate);
                  this.updateProperty(
                    "dateFilterField",
                    this.state.filterDateType
                  );
                } else {
                  this.removeFilter("start");
                  this.removeFilter("end");
                  this.removeFilter("dateFilterField");
                }
                //this.tableRef.current.requestPageSearch();
              }}
              DateChangeApiCall={(date) => {
                this.updateSpinner("Loading...");
                this.tableRef.current.requestPageSearch();

              }}
              onStartDateChange={(date) => {
                this.updateProperty("start", date);
                this.setState({ filterStartDate: date });
              }}
              onEndDateChange={(date) => {
                this.updateProperty("end", date);
                this.setState({ filterEndDate: date });
              }}
              onDateTypeChange={(val) => {
                this.updateProperty("dateFilterField", val);
                this.setState({ filterDateType: val });
              }}
            />
          </PaginationFilterTable>
        </Loader>
      </div>
    );
  }
  removeFilter(id) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => {
      this.savePageState();
    });
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }
    previousSearchRequest.filtered = filtered;
    if (!found) {
      if (id === "start") {
        filtered.push({ id: id, value: value, operator: "GT" });
      } else if (id === "end") {
        filtered.push({ id: id, value: value, operator: "LT" });
      } else if (id === "filterDateType") {
        filtered.push({ id: id, value: value, operator: "EQ" });
      } else {
        filtered.push({ id: id, value: value, operator: "EQ" });
      }
    }

    this.setState(
      {
        previousSearchRequest: previousSearchRequest,
      },
      () => {
        this.savePageState();
      }
    );
  }
  getProperty(field) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }
  }
}
TaskHistory.propTypes = {
  user: PropTypes.object,
};
export default TaskHistory;