import React, { Component } from "react";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import * as PropTypes from "prop-types";
import { Button } from "reactstrap";

export default class TableDateRangePickerGO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      startChanged: false,
      endChanged: false,
	  typeChanged:false,
      localDate: moment.tz(moment.tz.guess()).toDate(),
	  event:0
    };
  }

 

  handleGoButtonClick = () => {

  
		this.props.DateChangeApiCall();
    

    
	
  };
	render() {
		return (
			<>
				<div className="filter-switch" title="Show between dates">
					<Switch
						onChange={(checked) => {
							this.props.onDateFilterEnable(checked);
							// this.setState({dateFilterEnabled: !this.state.dateFilterEnabled}, () => {
							//     this.setBaseFilter();
							// });
						}}
						checked={this.props.filterEnabled}
						uncheckedIcon={
							<div className="text-center pt-1">
								<i className="fas fa-calendar" />
							</div>
						}
						checkedIcon={
							<div className="text-center pt-1">
								<i className="fas fa-calendar" />
							</div>
						}
						title="Show Filter"
						className="react-switch"
					/>

					<label style={{ width: "60px", marginLeft: "5px" }}>Between</label>
					&nbsp;
					<DatePicker
						name={"start"}
						className={
							"dispatch-list-date-picker " +
							(this.props.touched && !!this.props.error ? "is-invalid" : "")
						}
						valid={!this.props.error}
						invalid={this.props.touched && !!this.props.error}
						onBlur={this.props.onBlur}
						onChange={(v) => {
							
							const date = v.getDate();
							const month = v.getMonth();
							const year = v.getFullYear();
							
						
							const localTimeZone = moment.tz.guess();
							let localDate = moment.tz('2023-06-13T00:00:00',localTimeZone);
							localDate.set({
							  year: year,
							  month: month,
							  date: date
							  
							});
							localDate=localDate.format('YYYY-MM-DD')
							this.setState({ startChanged:true,endChanged:false,typeChanged:false });

							this.setState({ localDate });
							this.props.onStartDateChange(localDate);
							// this.setState({createStart: v, dateFilterEnabled: true}, () => this.setBaseFilter());
						}}
						dateFormat="MM/dd/yyyy"
						selected={moment(this.props.start).tz(moment.tz.guess()).toDate()}
						disabled={!this.props.filterEnabled}
						onFocus={(e) => e.target.select()}
					/>
					<label style={{ width: "30px", marginLeft: "5px" }}>and</label>&nbsp;
					<DatePicker
						name={"end"}
						className={
							"dispatch-list-date-picker " +
							(this.props.touched && !!this.props.error ? "is-invalid" : "")
						}
						valid={!this.props.error}
						invalid={this.props.touched && !!this.props.error}
						onBlur={this.props.onBlur}
						dateFormat="MM/dd/yyyy"
						onChange={(v) => {
							
							const date = v.getDate();
							const month = v.getMonth();
							const year = v.getFullYear();
							
							const localTimeZone = moment.tz.guess();
							let localDate = moment.tz('2023-06-13T00:00:00',localTimeZone);
							localDate.set({
							  year: year,
							  month: month,
							  date: date
							  
							});
							localDate=localDate.format('YYYY-MM-DD')
							this.setState({ startChanged:false,typeChanged:false,endChanged:true });

							this.setState({ localDate });
							this.props.onEndDateChange(localDate);
						}}
						selected={this.props.end}
						disabled={!this.props.filterEnabled}
						onFocus={(e) => e.target.select()}
					/>
					{this.props.showTaskSelector && (
						<select
							disabled={!this.props.filterEnabled}
							onChange={(event) => {
								let v = event.target.value;
								this.setState({ startChanged:false,endChanged:false,typeChanged:true,event:v });
                                 
								this.props.onDateTypeChange(v);
							}
							}
							value={this.props.dateType}
						>
							{this.props.hisotryPageType && this.props.hisotryPageType === "yardcheckPage" &&
								<option value="yardCheckDate">Yard Check Complete Date</option>
							}
							{this.props.hisotryPageType && this.props.hisotryPageType === "DwellPage" &&
								<>
								<option value="arrivalTime">Asset Arrival Time</option>
								<option value="departTime">Asset Departure Time</option>
								
								</>
							}
							{this.props.hisotryPageType && this.props.hisotryPageType === "eztrack-events" &&
								<option value="sourceTimestamp">Report Date</option>
							}
							{this.props.hisotryPageType && this.props.hisotryPageType === "gate-history" &&
								<>
									<option value="createdDate">Create Date</option>
									<option value="eta">ETA Date</option>
									<option value="arrivalendtime">Arrival Date</option>
									<option value="departureendtime">Departure Date</option>
								</>
							}
							{!this.props.hisotryPageType &&
								<>
									<option value="createdDate">Create Date</option>
									<option value="acceptDate">
										Accepted Date
									</option>
									<option value="taskStartDate">
										Start Date
									</option>
									<option value="taskEndDate">
										End Date
									</option>
								</>
							}

						</select>
					)}
					&nbsp;&nbsp;&nbsp;


					<Button color="primary" disabled={!this.props.filterEnabled}
						onClick={this.handleGoButtonClick}>GO</Button>
				</div>
			</>
		);
	}
}

TableDateRangePickerGO.propTypes = {
	filterEnabled: PropTypes.bool,
	start: PropTypes.any,
	end: PropTypes.any,
	onDateFilterEnable: PropTypes.func,
	onStartDateChange: PropTypes.func,
	onEndDateChange: PropTypes.func,
};