import React, {Component} from 'react';
import {Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Form, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Formik} from 'formik';
import * as Yup from 'yup'
import {getErrorsFromValidationError} from "../common/utilities";
import FormPhoneTextField from "../common/FormPhoneTextField";
import ValidationService from "../common/ValidationService";
import {logout} from "../NetworkHelpers";
import Config from "../Config";





class Reset extends Component {
    constructor(props) {
        super(props);
        this.hardReset();
        
    }

    componentWillUnmount() {
        document.body.style.background = null;
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }
    hardReset(){
        logout((response) => {
            
            localStorage.clear();
            let url = window.location.origin;

            window.location.replace(url);
        }, () => console.log("Unable to logout"));
    }
   

    render() {
        return (
            <div className="app flex-row mt-5 animated fadeIn">
                
            </div>);
    }

}

export default Reset;
