import * as React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  downloadSpotsForTable,
  importSpotFile,
  requestSpotsToExcel,
  saveSpot,
  promiseAllSpotTypes,
} from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import SpotEdit from "./SpotEdit";
import { FileDropModal } from "../../common/FileDropModal";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class Spots extends React.Component {
  tableColumns = [
    {
      Header: "Location Nickname",
      accessor: "location.nickname",
      sortable: true,
      filterable: true,
      Cell: (row) => {
        return row.original.location.nickname;
      },
    },
    {
      Header: "Spot ID",
      accessor: "id",
      sortable: false,
      filterable: false,
    },
    {
      Header: "Yard Zone / Building",
      accessor: "zone",
      sortable: true,
      filterable: true,
    },
    {
      Header: "Type",
      accessor: "type.name",
      sortable: true,
      filterable: true,
      Cell: (row) => {
        console.log(row, `row in type header`);
        return row.original.type.name;
      },
    },
    {
      Header: "Spot Name",
      accessor: "name",
      sortable: true,
      filterable: true,
    },
    {
      Header: "Enabled",
      accessor: "enabled",
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.enabled ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={(event) => {
              this.setState({ enabledFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={this.state.enabledFilter}
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "action",
      sortable: false,
      filterable: false,
      Cell: (row) => {
        return (
          <Button
            title="Edit"
            aria-label="Edit Spot"
            onClick={() => this.editSpot(row.original)}
          >
            <i className="fa fa-edit" />
          </Button>
        );
      },
    },
  ];
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.enabledFilterRef = React.createRef();
    this.typeFilterRef = React.createRef();
    let localStorageState = JSON.parse(
      localStorage.getItem("poweryard-spot-settings")
    );
    if (localStorageState != null) {
      this.state = {
        enabledFilter: localStorageState.enabledFilter,
        spotUnderEdit: localStorageState.spotUnderEdit,
      };
    } else {
      this.state = {
        spotUnderEdit: {},
      };
    }
  }
  componentDidMount() {
    promiseAllSpotTypes("")
      .then((data) => {
        // Handle the API response data here
        console.log(data, `response from datar`);
        data = data.filter((d) => {
          console.log(d, `response from datar valur`);
          if (d.defaultValue) {
            this.setState({ spotTypes: d });
          }
        });
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company.uuid !== this.props.company.uuid) {
      this.tableRef.current.fetchData();
    }
  }
  componentWillUnmount() {
    localStorage.setItem(
      "poweryard-spot-settings",
      JSON.stringify({
        enabledFilter: this.state.enabledFilter,
        spotUnderEdit: this.state.spotUnderEdit,
      })
    );
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  editNewSpot() {
    this.setState({
      showModal: true,
      spotUnderEdit: {
        name: "",
        enabled: true,
        type: this.state.spotTypes,
        isNew: true,
      },
    });
  }
  renderModal() {
    return (
      <SpotEdit
        spotUnderEdit={this.state.spotUnderEdit}
        defaultLocation={this.props.user.location}
        loaded={this.state.loaded}
        cancel={() => this.cancelDialog()}
        save={(a, onSuccess, onError) => {
          this.saveSpot(a, onSuccess, onError);
        }}
        onChange={(state) => {
          this.setState({ spotUnderEdit: state });
        }}
        showModal={this.state.showModal}
      />
    );
  }
  cancelDialog() {
    this.setState({ showModal: false, spotUnderEdit: {} });
  }
  editSpot(taskType) {
    this.setState({ showModal: true, spotUnderEdit: taskType });
  }
  saveSpot(asset, onSuccess, onError) {
    this.setState({ loaded: true });
    console.log(`this runs on save spot`, asset, onSuccess, onError);
    saveSpot(
      asset,
      (response) => {
        this.setState({ loaded: false });
        if (response.code === "1000") {
          console.log(`this runs on response failure`, response.message);
          showError(response.message);
        } else {
          showInfo("Spot Saved");
          console.log(`spot saved`);
          this.tableRef.current.fetchData();
          this.props.onChange(this.state);
          onSuccess();
          this.setState({ showModal: false, spotUnderEdit: {} });
        }
      },
      (failure) => {
        console.log(`this runs on failue`, failure);
        showError(failure.message);
        onError();
      }
    );
  }
  clearFilters() {
    this.enabledFilterRef.current.value = undefined;
    this.typeFilterRef.current = undefined;
    this.setState({ enabledFilter: undefined, typeFilterRef: undefined });
  }
  exportSpots() {
    if (this.state.previousSearchRequest) {
      requestSpotsToExcel(
        this.state.previousSearchRequest,
        "spot-export.xlsx",
        (response) => {
          if (response.emailSent) {
            showInfo(
              "We are currently generating the Excel file and you will receive it via email shortly."
            );
          }
        },
        (e) => {
          console.log(e);
          showError(
            "We are not able to generate the excel file at this time.  Please try again later."
          );
        }
      );
    }
  }
  render() {
    return (
      <div>
        <div className="column-wrapper text-left">
          <Modal isOpen={this.state.showImportInstructions}>
            <ModalHeader>Import Instructions</ModalHeader>
            <ModalBody>
              EZCheck-In allows for importing certain data via a file in CSV
              format or XLSX format. To get started with importing spots, click
              the Download Import Template to obtain a sample CSV file. Add one
              row per spot that you wish to import.
              <br />
              <br />
              The following columns must be included. Any fields with an
              asterisk are required and must be non blank. Any fields that
              contain a comma must be enclosed in quotes.
              <br />
              <br />
              Location* (case insensitive name, must already exist)
              <br />
              Yard Zone / Building*
              <br />
              Spot Type* (Name of your Spot Type)
              <br />
              Name
              <br />
              Enabled* (True or False)
              <br />
              <br />
              <div style={{ marginTop: "10px" }} className={"text-right"}>
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showImportInstructions: false,
                    })
                  }
                >
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <FileDropModal
            open={this.state.importModalShow}
            error={this.state.importModalError}
            header={"Import Spots"}
            onDrop={(acceptedFiles) => {
              for (const file of acceptedFiles) {
                if (file["type"] && !file["type"].includes("text")) {
                  //Or is it excel?
                  if (
                    file["type"] &&
                    !(
                      file["type"].includes("excel") ||
                      file["type"].includes("openxml")
                    )
                  ) {
                    this.setState({ importModalError: "File must be text" });
                    return;
                  }
                }
                importSpotFile(
                  file,
                  (res) => {
                    let response = res.content;
                    this.setState({ importModalShow: false });
                    showInfo(
                      response.message + " in " + response.seconds + " seconds."
                    );
                    this.tableRef.current.fetchData();
                  },
                  (response) => {
                    this.setState({ importModalError: response.message });
                  },
                  () => {
                    this.props.history.push("/");
                  }
                );
              }
            }}
            onClose={() => {
              this.setState({
                importModalError: false,
                importModalShow: false,
              });
            }}
          />
           <Loader show={this.state.loaded} message={this.spinner}>
          <PaginationFilterTable
           setloaded={(check) => {
                  this.setState({ loaded: check });
                  this.updateSpinner("");
                }}
            onFilterHide={() => {
              this.setState({
                enabledFilter: "undefined",
              });
            }}
            openFiltersCollaps={true}
            ref={this.tableRef}
            get={downloadSpotsForTable}
            pageType="poweryard-settings"
            stateStorage="poweryard-spot-settings"
            clearFilter={this.clearFilters.bind(this)}
            renderModal={this.renderModal()}
            columns={this.tableColumns}
            successCallback={(response) => {
              let filters = this.tableRef.current.state.filtered;
              let sort = this.tableRef.current.state.sorted;
              this.setState({
                hasResults: response.totalElements > 0,
                previousSearchRequest: { filtered: filters, sorted: sort },
              });
            }}
            footerButton={
              <span title={"Select to extract your company spot data."}>
                <Button
                  disabled={!this.state.hasResults}
                  color="secondary"
                  onClick={() => this.exportSpots()}
                >
                  <i className="fa fa-file-excel" /> Export to Excel
                </Button>
              </span>
            }
          >
            <Button color="primary" onClick={() => this.editNewSpot()}>
              {" "}
              <i className="fa fa-plus" /> Add New Spot
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              color="secondary"
              className={"btn-sm"}
              onClick={() => {
                this.setState({ importModalShow: true });
              }}
            >
              <i className="fa fa-file-import" /> Import
            </Button>
            <a
              style={{ marginLeft: "10px" }}
              href={"/assets/files/spot-import-format.csv"}
            >
              Download Import Template
            </a>
            <a
              style={{ marginLeft: "10px" }}
              href={"#"}
              onClick={(e) => {
                e.preventDefault();
                this.setState({ showImportInstructions: true });
              }}
            >
              Import Instructions
            </a>
          </PaginationFilterTable>
          </Loader>
        </div>
      </div>
    );
  }
}
export default Spots;
