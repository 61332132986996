import React from "react";
import * as uuid from "uuid";
import {Tooltip} from "reactstrap";
import MomentDisplay from "../../assets/components/MomentDisplay";

export class ReportTime extends React.Component{
    constructor(props) {
        super();
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        }
    }

    getColor() {
        
            return "black";
        
    }

    toggle(){
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    render() {
        return <span className={this.props.showOn === "progress" ? "clock": ""}>
            <i id={this.state.id} className="fas fa-clock" aria-hidden="true"
               style={{marginLeft: "10px", color: this.getColor()}}/>
            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={() => this.toggle()}>
                {!this.props.reportedTime && "Not Calculated"}
                {this.getTooltip()}
              
               
            </Tooltip>
        </span>
            ;
    }

   

 

    getTooltip() {
        if(!this.props.reportedTime){
            return <></>;
        }
        return <>
            Last Report Time: {
            this.props.reportedTime &&
            <MomentDisplay
                timezone={this.props.timezone}
                date={this.props.reportedTime}
           
            />
        }
        
        </>;
    }
}