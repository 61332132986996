import {downloadDispatchAssessorialsForTable} from "../../NetworkHelpers";
import AssetsHistory from "./DispatchAssets";
import React, {Component} from "react";
import MomentDisplay from "../../assets/components/MomentDisplay";
import DurationDisplay from "../../assets/components/DurationDisplay";
import StaticDurationDisplay from "../../assets/components/StaticDurationDisplay";
import {getDwellForAssetType, getShipmentLink, latLongLink} from "../../common/utilities";
import Alerts from "../../common/Alerts";
import {AlertModelDurationDisplay} from "./AlertModelDurationDisplay";
import {AlertModelStaticDurationDisplay} from "./AlertModelStaticDurationDisplay";

export default class AssetsHistoryComponent extends React.Component {
    tableColumns = [
        {
            Header: 'Location',
            accessor: 'location.name',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Asset ID',
            accessor: 'asset.assetId',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Type',
            accessor: 'asset.assetType',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Carrier/Broker',
            accessor: 'carrier.name',
            sortable: true,
        },
        {
            Header: 'Appointment',
            accessor: 'appointment',
            style:{ 'whiteSpace': 'unset'},
            filterable: false,
            sortable: true,
            Cell: row => {return row.original.appointment ?
                <MomentDisplay date={row.original.appointment}
                               timezone={row.original.location && row.original.location.timezone}/>:"NA"}
        },
        {
            Header: 'Arrival',
            filterable: false,
            sortable: true,
            accessor: 'arrival',
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {return row.original.arrival ?
                <MomentDisplay date={row.original.arrival}
                               timezone={row.original.location && row.original.location.timezone}/>:"NA"}
        },
        {
            Header: 'Alerts',
            filterable: false,
            sortable: false,
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {
                return row.original.asset && row.original.asset.assetType !== "Driver" ? <Alerts alert={row.original.alertModel}/> : "";
            }
        },
        {
            Header: 'Departure',
            filterable: false,
            sortable: true,
            accessor: 'departure',
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {return row.original.departure ?
                <MomentDisplay date={row.original.departure}
                               timezone={row.original.location && row.original.location.timezone}/>:"NA"}
        },
        {
            Header: 'Shipment #',
            accessor: 'shipment.assetId',
            sortable: true,
            filterable: true,
            Cell: row => {return getShipmentLink(this.props.user, row.original.shipment.assetId, row.original.shipment.company.uuid)}
        },
        {
            Header: 'Stop References',
            accessor: 'stopReferences',
            sortable: false,
            filterable: false
        },
        {
            Header: 'Events',
            accessor: 'assetEvents',
            filterable: false,
            sortable: false,
            Cell: row => {
                if(row.original.assetEvents){
                    return <div>
                        {Object.keys(row.original.assetEvents).map(a =><div>
                            <MomentDisplay
                                date={a} timezone={row.original.location && row.original.location.timezone}/>
                            &nbsp; {row.original.assetEvents[a].message} {latLongLink(row.original.assetEvents[a])}
                        </div>)}
                    </div>
                }else {
                    return <div/>
                }
            }
        },
        {
            Header: 'Dwell Time',
            accessor: 'dwellTime',
            filterable: false,
            sortable: false,
            Cell: row=> {
                if (!row.original.dwellInSeconds) {
                    return !row.original.arrival ? "" : this.getDurationDisplay(row)
                } else {
                    return this.renderDwellTime(row.original.dwellInSeconds, row.original)
                }
            }
        }
    ];

    getDurationDisplay(row) {
        return <AlertModelDurationDisplay
            arrival={row.original.arrival}
            assetType={row.original.assetType}
            alertModel={row.original.alertModel}
        />;
    }

    renderDwellTime(dwellInSeconds, assetHistory) {
        return <div>
            <AlertModelStaticDurationDisplay
                alertModel={assetHistory.alertModel}
                assetType={assetHistory.asset.assetType}
                visitLength={dwellInSeconds * 1000}
            />
        </div>;
    }

    render(){
        return <AssetsHistory {...this.props} title={"Asset History"} get={downloadDispatchAssessorialsForTable} tableColumns={this.tableColumns}/>
    }
}

export class DwellAlert extends Component {
    style = {
        maxWidth:"26px",
        ...this.props.style
    };

    getColor(){
        switch (this.props.alert) {
            case "Acceptable":
                return "green";
            case "Warning":
                return "yellow";
            case "Critical":
                return "red";
            default:
                return "black";
        }
    }

    render(){
        if(!this.props.showBlack && !this.props.type){
            return <div/>
        }

        switch (this.props.type) {
            case "Tractor":
                return <span className="icon-tractor-trans-black" style={{...this.style, color: this.getColor()}}/>;
            case "Trailer":
                return <span className="icon-trailer-trans-black" style={{...this.style, color: this.getColor()}}/>;
            default:
                return <div/>;
        }
    }
}
