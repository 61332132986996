import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import * as React from "react";
import {Button} from "reactstrap";
import {DispatchDepartureModal} from "./DispatchDepartureModal";
import * as moment from "moment";
import {AlertModelStaticDurationDisplay} from "./AlertModelStaticDurationDisplay";
import MomentDisplay from "../../assets/components/MomentDisplay";
import Alerts from "../../common/Alerts";
import {getLocalStorageName, getShipmentsLink} from "../../common/utilities";
import ValidationService from "../../common/ValidationService";
import {showError, showInfo} from "../../MainApp";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import SendSMSModal from "../actions/SendSMSModal";
import NoteModal from "../../assets/components/NoteModal";
import {storeItemsPerPage} from "../../assets/actions/Assets";
import {departAsset, requestAssetsHistoryToExcel, saveNote, sendDriverSms} from "../../NetworkHelpers";
import {AlertModelDurationDisplay} from "./AlertModelDurationDisplay";

class DispatchAssets extends React.Component{
    tableColumns = [
        {
            Header: 'Location',
            accessor: 'location.name',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Asset ID',
            accessor: 'assetId',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Type',
            accessor: 'assetType',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Trailer Comment',
            accessor: 'comments',
            filterable: false,
            sortable: false,
            Cell: row => {
                if(row.original.comments){
                    return <div>
                        {Object.keys(row.original.comments).map(a =><div>
                            <MomentDisplay date={row.original.comments[a].created} timezone={row.original.location && row.original.location.timezone}/>
                            &nbsp; {row.original.comments[a].note}
                        </div>)}
                    </div>
                }else {
                    return <div></div>
                }
            }
        },
        {
            Header: 'Carrier/Broker',
            accessor: 'carrier.name',
            sortable: true,
        },
        {
            Header: 'Appointment',
            accessor: 'appointment',
            style:{ 'whiteSpace': 'unset'},
            filterable: false,
            sortable: false,
            Cell: row => {return row.original.appointment ?
                <MomentDisplay date={row.original.appointment}
                               timezone={row.original.location && row.original.location.timezone}/>:"NA"}
        },
        {
            Header: 'Arrival',
            filterable: false,
            sortable: false,
            accessor: 'arrival',
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {return row.original.arrival ?
                <MomentDisplay date={row.original.arrival}
                               timezone={row.original.location && row.original.location.timezone}/>:"NA"}
        },
        {
            Header: 'Alerts',
            filterable: false,
            sortable: false,
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {
                return row.original.assetType !== "Driver" ? <Alerts alert={row.original.alertModel}/> : "";
            }
        },
        {
            Header: 'Events',
            accessor: 'assetEvents',
            filterable: false,
            sortable: false,
            Cell: row => {
                if(row.original.assetEvents){
                    return <div>
                        {Object.keys(row.original.assetEvents).map(a =><div>
                            <MomentDisplay date={a} timezone={row.original.location && row.original.location.timezone}/>
                            &nbsp; {row.original.assetEvents[a]}
                        </div>)}
                    </div>
                }else {
                    return <div/>
                }
            }
        },
        {
            Header: 'Shipment #',
            accessor: 'latestShipmentId',
            sortable: true,
            filterable: true,
            Cell: row => { return row.original.shipment ? getShipmentsLink(row.original.dispatchUuid,this.props.user, row.original.shipment.assetId, row.original.shipment.company.uuid) : "" }
        },
        {
            Header: 'Dwell Time',
            accessor: 'dwellTime',
            filterable: false,
            sortable: false,
            Cell: row=> {return !row.original.visitLength ?
                (!row.original.arrival ? "" :
                    <AlertModelDurationDisplay
                    arrival={row.original.arrival}
                    assetType={row.original.assetType}
                    alertModel={row.original.alertModel}
                    />)
                :
                <AlertModelStaticDurationDisplay
                    alertModel={row.original.alertModel}
                    assetType={row.original.assetType}
                    visitLength={row.original.visitLength}
                />
            }
        },
        {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: row => {return <>
                <Button title="Add Note" aria-label="Add Note"
                        onClick={() => this.openNoteModal(row.original.uuid, row.original.assetId, row.original.notes)}>
                    <i className="far fa-sticky-note"/>
                </Button>
                &nbsp;
                {!row.original.visitLength &&
                <Button title="Set Departure Time" aria-label="Set Departure Time"
                        onClick={() => this.openDepartureModal(row.original.uuid, row.original.assetId, row.original.latestShipmentId)}>
                    <i className="far fa-clock"/>
                </Button>}
            </>
            }
        }

    ];
    openSmsModal(uuid, driverFirstName, driverLastName, defaultReferenceLabel, shipmentNumber, driverPhoneNumber, reminders) {
        this.setState({showingSmsModal: true,
            smsModalUuid: uuid,
            driverFirstName: driverFirstName,
            driverLastName: driverLastName,
            defaultReferenceLabel: defaultReferenceLabel,
            shipmentNumber: shipmentNumber,
            userFirstName: this.props.user.firstname,
            driverPhoneNumber: driverPhoneNumber,
            remindersForModal: reminders
        });
    }
    closeSmsModal() {
        this.setState({showingSmsModal: false, smsModalUuid: false, messageModalError: "", remindersForModal: []});
    }

    constructor(props){
        super(props);
        this.tableRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName("ezcheckin-dispatch-assets", this.props.user)));
        if (localStorageState != null) {
            this.state = localStorageState;
        } else {
            this.state = {
                previousSearchRequest: {},
                showingDepartureModal: false
            };
        }

        if(this.props.tableColumns){
            //For Assersorials
            this.tableColumns = this.props.tableColumns;
            this.tableColumns.push({
                Header: 'Action',
                accessor: 'action',
                sortable: false,
                filterable: false,
                Cell: row => {return <>
                    <Button title="Add Note" aria-label="Add Note"
                            onClick={() => this.openNoteModal(row.original.asset.uuid, row.original.asset.assetId, row.original.asset.notes)}><i
    className="far fa-sticky-note"/></Button>&nbsp;
                    {row.original.asset.assetType === "Driver" && row.original.shipment.status !== 12.0 &&
                    row.original.shipment.deliveryAppointment > (moment.now() - 1000 * 60 * 60 * 2) ?
                        <Button
                            title="Text Driver"
                            aria-label="Text Driver"
                            onClick={() => this.openSmsModal(row.original.asset.uuid,
                                row.original.asset.firstname,
                                row.original.asset.lastname,
                                row.original.asset.company.shipmentIdDisplay,
                                row.original.shipment.assetId,
                                ValidationService.formatPhone(row.original.asset.countryCode,
                                    row.original.asset.cell),
                                row.original.messages)}>
                            <i className="fa fa-comment"/>
                        </Button>
                        : null }
                </>
                }
            })
        }
    }

    componentWillUnmount()  {
        localStorage.setItem(getLocalStorageName("ezcheckin-dispatch-assets", this.props.user), JSON.stringify({
            previousSearchRequest: this.state.previousSearchRequest,
            showingDepartureModal: this.state.showingDepartureModal,
        }));
    }

    openNoteModal(uuid, assetId, notes){
        this.setState({showingNoteModal: true, noteModalUuid: uuid, noteModalAssetId: assetId, notesForModal: notes});
    }

    closeNoteModal(){
        this.setState({showingNoteModal: false, noteModalUuid: false, notesForModal: {}});
        this.tableRef.current.fetchData();
    }

    openDepartureModal(uuid, id, shipment) {
        this.setState({
            showingDepartureModal: true,
            departureModalUuid: uuid,
            departureModalAssetId: id,
            departureModalShipment: shipment
        })
    }

    closeDepartureModal(){
        this.setState({showingDepartureModal: false, departureModalUuid: null})
    }

    applyAddedNote(assetUuid, note) {
        let newData = [];
        for ( let i=0;i<this.state.tableData.length;i++ ) {
            if ( this.state.tableData[i].uuid === assetUuid ) {
                let entry = Object.assign({}, this.state.tableData[i]);
                entry = Object.assign({}, entry);
                entry.notes = Object.assign({}, entry.notes);
                entry.notes[note.uuid] = note;
                newData.push(entry);
            } else {
                newData.push(this.state.tableData[i]);
            }

        }
        this.setState({tableData:newData});
    }


    pageSearch(searchRequest, successFn, failureFn) {
        this.setState({previousSearchRequest: searchRequest});
        this.props.get(
            searchRequest,
            (response) => {
                this.setState({tableData: response.content});
                successFn && successFn();
            }, (response) => {
                failureFn && failureFn(response);
            })
    }

    render(){
        return <>
            <div className="column-wrapper text-left">
                <DispatchDepartureModal
                    open={this.state.showingDepartureModal}
                    onCancel={() =>  this.closeDepartureModal()}
                    assetId={this.state.departureModalAssetId}
                    shipment={this.state.departureModalShipment}
                    onSave={(departure)=>{
                        this.departAsset(departure);
                        this.tableRef.current.fetchData();
                    }}
                />
                <NoteModal
                    open={this.state.showingNoteModal}
                    uuid={this.state.noteModalUuid}
                    assetId={this.state.noteModalAssetId}
                    notes={this.state.notesForModal}
                    error={this.state.noteModalError}
                    onSave={(note, setErrors)=>{
                        saveNote(this.state.noteModalUuid, {note: note}, (data)=>{
                            this.applyAddedNote(this.state.noteModalUuid, data);
                            this.closeNoteModal();
                        }, (error) =>{
                            this.setState({noteModalError: error.message})
                        });
                    }}
                    onCancel={()=>{
                        this.closeNoteModal();
                    }}
                />
                <SendSMSModal
                    open={this.state.showingSmsModal}
                    uuid={this.state.smsModalUuid}
                    defaultReferenceLabel={this.state.defaultReferenceLabel}
                    driverFirstName={this.state.driverFirstName}
                    driverLastName={this.state.driverLastName}
                    shipmentNumber={this.state.shipmentNumber}
                    userFirstName={this.state.userFirstName}
                    sendSmsToPhone={this.state.driverPhoneNumber}
                    messages={this.state.remindersForModal}
                    error={this.state.messageModalError}
                    onSave={(message, setErrors)=>{
                        sendDriverSms(this.state.smsModalUuid, message, (data)=>{
                            showInfo("Message Sent");
                            this.closeSmsModal();
                        }, (error) =>{
                            showError("Failed to send message "+ error.message);
                            this.setState({messageModalError: error.message})
                        });
                    }}
                    onCancel={()=>{
                        this.closeSmsModal();
                    }}
                />
                <div className="page-header">
                    <h1>{this.props.title}</h1>
                </div>
                <div className="">
                <PaginationFilterTable
                openFiltersCollaps = {true}
                    user={this.props.user}
                    ref={this.tableRef}
                    get={this.props.get}
                    pageType = "assets-history"
                    stateStorage="ezcheckin-dispatch-assets"
                    columns={this.tableColumns}
                    tableData={this.state.tableData}
                    onChange={(state) => {
                        this.setState({itemsPerPage: state.itemsPerPage,
                            previousSearchRequest: {page: state.page,
                                pageSize: state.pageSize,
                                sorted: state.sorted,
                                filtered: state.filtered
                            }
                        });
                    }}
                    footerButton={
                        <Button color="secondary" onClick={() => this.exportAssetsHistory()}><i className="fa fa-file-excel"/> Export to Excel</Button>
                    }
                >
                </PaginationFilterTable>
                </div>
            </div>
        </>
    }

    exportAssetsHistory() {
        if ( this.state.previousSearchRequest ) {
            requestAssetsHistoryToExcel(moment.tz.guess(), this.state.previousSearchRequest, (res)=>{
                let a = res.message;
                if(a && a.includes("https://e4score-ezcheckin")){
                    window.location.href=a;
                } else {
                    showError("We are not able to generate the excel file at this time.  Please try again later.");
                }
                // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
            }, (e)=>{
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    departAsset(date) {
        departAsset(this.state.departureModalUuid, date, ()=>{
            this.closeDepartureModal();
            showInfo("Asset Departed");
        }, ()=>{
            showError("Failed to depart asset");
        });
    }
}

function mapStateToProps(state) {
    return {
        itemsPerPage: state.dispatchAssets.itemsPerPage,
        currentLocation: state.login.location,
        user: state.login.user
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setItemsPerPage: function(itemsPerPage) {
            dispatch(storeItemsPerPage(itemsPerPage));
        }
    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DispatchAssets));