import React from 'react';
import { Button } from "reactstrap";
import { downloadPyApi, savePyApi } from "../../NetworkHelpers";

import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { showError, showInfo } from "../../MainApp";
import PyApiForm from "./PyApiForm";
import { appendAsteriskToStr } from '../../common/utilities';
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

export default class PyApi extends React.Component { //1

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.refernceApiFilterRef = React.createRef();
    this.taskEventApiFilterRef = React.createRef();
    this.taskApiFilterRef = React.createRef();
    this.enabledFilterRef = React.createRef();

    this.enabledFilterRef = React.createRef();
    var parse = JSON.parse(localStorage.getItem("ezcheckin-admin-py-api"));
    var objToPut;
       
    if (parse && parse !== null) {
      this.state = parse;
    }
    // comment by ali
    // else {
    //   objToPut = { id: "enabled", value: "true" };
    //   let a = { filtered: [] };

    //   a.filtered.push(objToPut);
    //   a.hideFilters = true;
    //   localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify(a));
    // } 
    else {
      this.state = {
        pages: -1,
        editingApiSetting: null,
        sorted: [],
        filtered: []
        // loggedInUser: props.loggedInUser,

      }
    }
  };

  // componentWillUnmount() {
  //   localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify({
  //     refernceApiFilter: this.state.refernceApiFilter,
  //     taskEventApiFilterRef: this.taskEventApiFilterRef,
  //     taskApiFilterRef: this.taskApiFilterRef,
  //     enabledFilterRef: this.enabledFilterRef,
  //   }))
  // }
  tableColumns = [
    {
      Header: "Location Name",
      accessor: 'locationName',
      filterable: true,
      width: 250,
      sortable: true,
      style: { 'whiteSpace': 'unset' },
      Cell: row => {
        let v = row.original;
        return v.locationName ? v.locationName : "";
      }


    },

    {
      Header: "Location Code",
      sortable: true,
      filterable: true,
      accessor: 'locationCode',
      Cell: row => { let v = row.original; return v.locationCode ? v.locationCode : ""; }
    },

    {
      Header: "Client ID",
      sortable: true,
      filterable: true,
      accessor: 'clientId',
      Cell: row => { let v = row.original; return v.clientId ? v.clientId : ""; }

    },
    {
      Header: "Client Secret",
      sortable: true,
      filterable: false,
      accessor: 'clientSecret',
      Cell: row => {
        let v = row.original;
        let tempClientSecret = row.value
        return tempClientSecret ? appendAsteriskToStr(tempClientSecret) : '';
      }

    },
    {
      Header: "REF#API",
      sortable: true,
      filterable: true,
      accessor: 'refernceApi',
      Cell: row => {
        return row.original.refernceApi ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.refernceApiFilterRef}
            onChange={event => {
              this.setState({ refernceApiFilter: event.target.value });
              // let a = { filtered: [] };
              // a.filtered.push(this.state.refernceApiFilter);
              // localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify(a));
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.refernceApiFilter ? this.state.refernceApiFilter.value : ""}
          >
            <option value="" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },

    {
      Header: "Task Event API",
      filterable: true,
      sortable: true,
      accessor: 'taskEventApi',
      Cell: row => {
        return row.original.taskEventApi ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.taskEventApiFilterRef}
            onChange={event => {
              this.setState({ taskEventApiFilter: event.target.value });

              // let a = { filtered: [] };
              // a.filtered.push(this.state.filterObject);
              // localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify(a));
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.taskEventApiFilter ? this.state.taskEventApiFilter.value : ""}
          >
            <option value="" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },
    {
      Header: "Task API",
      filterable: true,
      sortable: true,
      accessor: 'taskApi',
      Cell: row => {
        return row.original.taskApi ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.taskApiFilterRef}
            onChange={event => {
              this.setState({ taskApiFilter: event.target.value });

              // let a = { filtered: [] };
              // a.filtered.push(this.state.filterObject);
              // localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify(a));
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.taskApiFilter ? this.state.taskApiFilter.value : ""}
          >
            <option value="" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      filterable: true,
      sortable: true,
      Cell: row => {
        return row.original.enabled ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={event => {
              this.setState({ enabledFilter: event.target.value });

              // let a = { filtered: [] };
              // a.filtered.push(this.state.filterObject);
              // localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify(a));

              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.enabledFilter ? this.state.enabledFilter.value : ""}
          >
            <option value="" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },
    {
      Header: 'Action',
      filterable: false,
      accessor: 'action',
      width: 200,
      Cell: row => {
        let v = row.original;
        return <span>
          <Button disabled=
            {(this.props.loggedInUser.roles.filter(it => it.role.includes("ROLE_EZTRACK_MANAGER")).length > 0)
              && (v.roles.filter(it => it.role.includes("ADMIN")).length > 0)}
            onClick={() => this.editPyApi(v)}><i className="fa fa-edit" /> Edit </Button>
        </span>;
      },
      sortable: false
    }
  ];
  editPyApi(obj) {

    let arr = [
      { label: "Pending", value: "Pending" },
      { label: "Assigned", value: "IsAssinged" },
      { label: "In Progress", value: "InProgress" },
      { label: "Complete", value: "Complete" },
      { label: "Cancelled", value: "Canceled" }
    ];
    arr = arr.filter(it => {
      return obj.taskStatusList.some(u => it.value === u)
    })
    obj.isEdit = true;
    var tempObj = { ...obj }
    tempObj.taskStatusList = arr;
    this.setState({ editingApiSetting: tempObj });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company.uuid !== this.props.company.uuid) {
      this.tableRef.current.fetchData();
    }
  }
  clearFilters() {
    this.enabledFilterRef.current.value = undefined;
    this.setState({ enabledFilter: undefined }, () => this.tableRef.current.requestPageSearch());
  }
  saveApiSetting(apiSettingObj, onSuccess, onError) {
    // if (!user.onYardCheck) {
    //   user.onYardCheck = false;
    // }

    var apiSettingObjSend = { ...apiSettingObj }
    apiSettingObjSend.taskStatusList = apiSettingObjSend.taskStatusList.map(it => it.value);
    // apiSettingObjSend.settingHeaders = apiSettingObjSend.settingHeaders
    savePyApi(apiSettingObjSend, (response) => {
      if (response.code === '1000') {
        showError(response.message);
      } else if (response.code === '200') {
        let loc = this.props.currentLocation;
        loc.apiSetting = response.content;
        this.props.setCurrentLocation(loc)
        showInfo("Record saved successfully");
        this.setState({ editingApiSetting: null });
        this.tableRef.current.fetchData();
        onSuccess();
      } else {
        this.setState({ editingApiSetting: null });
      }
    }, (e) => {
      console.log(e)
      onError(e)
    })
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  startNewApiSetting() {
    console.log('client ID=', this.props.currentLocation.uuid)
    this.setState({
      editingApiSetting: {
        uuid: null,
        locationId: null, //Locations are multiple
        locationUuid: null,
        locationName: '',
        refernceApi: true,
        taskApi: true,
        taskEventApi: true,
        clientSecret: null,
        clientId: null,
        taskStatusList: [],
        updateSecret: false,
        enabled: true,
        webhookUrl: null,
        settingHeaders: [],
        isEdit: false
      }
    });
  }
  renderModal() {
    //change by ali
    if (this.state.editingApiSetting !== null) {
      return (
        <PyApiForm
          loggedInUser={this.props.loggedInUser}
          editingApiSetting={this.state.editingApiSetting}
          cancel={() =>
            this.setState({ editingApiSetting: null })
          }
          save={(c, setErrors) => {
            let userObj = { ...this.state.loggedInUser };
            this.saveApiSetting(c, () => {
              this.setState({
                editingApiSetting: null
              })
            }, (error) => {
              setErrors(error.formError);
            });
          }}
        />
      );
    }
  }


  render() {
    return (
      <div>
        <Loader show={this.state.loaded} message={this.spinner}>
          <PaginationFilterTable
            setloaded={(check) => {
              this.setState({ loaded: check });
              this.updateSpinner("");
            }} onFilterHide={() => {
              this.setState({
                enabledFilter: "undefined",
                isDefaultFilter: "undefined"
              });
            }}
            ref={this.tableRef}
            get={downloadPyApi}
            openFiltersCollaps={true}
            pageType="Admin"

            stateStorage="ezcheckin-admin-py-api"
            renderModal={this.renderModal()}
            columns={this.tableColumns}
            pageCount={this.state.pages}

            // clearFilter={() => {
            //   this.clearFilters.bind(this);
            //   var parse = JSON.parse(localStorage.getItem("ezcheckin-admin-py-api"));
            //   parse.filtered = [{ id: "enabled", value: "true" }];
            //   let a = { ...this.state.filterObject };
            //   a = { id: "enabled", value: "true" };
            //   this.setState({ filterObject: a, enabledFilter: "true" });
            //   localStorage.setItem("ezcheckin-admin-py-api", JSON.stringify(parse));
            // }}
            // baseFilter={[this.state.filterObject]}
            successCallback={(response) => {
              let filters = this.tableRef.current.state.filtered;
              let sort = this.tableRef.current.state.sorted;

              if (filters.length > 0) {
                var a = filters.find(it => it.id === "enabled");
                if (a) {
                  this.setState({ enabledFilter: a.value });
                }
              }
              this.setState({
                hasResults: response.totalElements > 0,
                previousSearchRequest: { filtered: filters, sorted: sort }
              });
            }}
          >
            <Button color="primary" onClick={() => this.startNewApiSetting()}>
              <i className="fa fa-plus" /> Add New Location
            </Button>
          </PaginationFilterTable>
        </Loader>
      </div>
    );
  }
}