import * as Actions from "../actions/Departure";

let initialState = {
    departureData: null
}

export default function departure(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_DEPARTURE_DATA:
            return Object.assign({},state, {departureData: action.departureData})
        default: return state;
    }
}
