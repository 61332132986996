import React from 'react';
import {EventLocation} from "./EventLocation";
import {DwellAlert} from "../../Dispatch/components/AssetsHistoryComponent";
import {ArrivalAlertClock} from "./ArrivalAlertClock";
import {RangeAlertCircle} from "./RangeAlertCircle";
import {DwellAlertTooltip} from "../../Dispatch/components/DwellAlertTooltip";
const ReactMarkdown = require('react-markdown');

const renderUpdate = (event) => {
    return <div>
        <EventLocation
            showDate={true}
            geocodeLocation={event.geocodeLocation}
            latitude={event.latitude}
            longitude={event.longitude}
            timezone={event.timezone}
            date={event.date}
        />
    </div>;
};

class Node extends React.Component{
    render() {
        const arrivalAlert = this.props.alert && this.props.alert.arrivalAlert;
        const rangeAlert = this.props.alert && this.props.alert.rangeAlert;
        const tractorDwell = this.props.alert && this.props.alert.tractorDwell;
        const trailerDwell = this.props.alert && this.props.alert.trailerDwell;

        return <li className={this.props.active ? "is-active" : ""}>
            <ReactMarkdown source={this.props.step.stepText}/>
            {this.props.step.locationUpdates && this.props.step.locationUpdates.map(this.props.renderUpdate)}
            {(this.props.ix === 1 || this.props.ix === 4) &&
            <ArrivalAlertClock
                arrivalAlert={arrivalAlert && arrivalAlert.arrivalAlert}
                stopAppointment={arrivalAlert && arrivalAlert.appointment}
                graceInMins={arrivalAlert && arrivalAlert.graceInMins}
                timezone={arrivalAlert && arrivalAlert.timezone}
                allDay={arrivalAlert && arrivalAlert.allDay}
            />}
            {(this.props.ix === 1 || this.props.ix === 2 || this.props.ix === 4 || this.props.ix === 5 ) &&
            <RangeAlertCircle
                distanceUnitsOfMeasure={rangeAlert && rangeAlert.distanceUnitsOfMeasure}
                geoFenceRange={rangeAlert && rangeAlert.geoFenceRadiusInMeters}
                rangeAlert={rangeAlert && rangeAlert.rangeAlert}
                latitude={rangeAlert && rangeAlert.latitude}
                longitude={rangeAlert && rangeAlert.longitude}
                locationLatitude={rangeAlert && rangeAlert.locationLatitude}
                locationLongitude={rangeAlert && rangeAlert.locationLongitude}
                showBlack
            />}
            {(this.props.ix === 2 || this.props.ix === 5) && tractorDwell &&
            <DwellAlertTooltip
                style={{marginLeft: "10px"}}
                type={"Tractor"}
                alert={tractorDwell.dwellAlert}
                dwellInSeconds={tractorDwell.dwellInSeconds}
                criticalDwellInSeconds={tractorDwell.criticalDwellAlertInSeconds}
                criticalDwellUnit={tractorDwell.criticalDwellAlertUnit}
                warningDwellInSeconds={tractorDwell.warningDwellAlertInSeconds}
                warningDwellUnit={tractorDwell.warningDwellAlertUnit}
            />
            }
            {(this.props.ix === 2 || this.props.ix === 5) && trailerDwell &&
            <DwellAlertTooltip
                type={"Trailer"}
                alert={trailerDwell.dwellAlert}
                dwellInSeconds={trailerDwell.dwellInSeconds}
                criticalDwellInSeconds={trailerDwell.criticalDwellAlertInSeconds}
                criticalDwellUnit={trailerDwell.criticalDwellAlertUnit}
                warningDwellInSeconds={trailerDwell.warningDwellAlertInSeconds}
                warningDwellUnit={trailerDwell.warningDwellAlertUnit}
            />
            }
        </li>
    }
}

const ShipmentProfileProgressBar = ({progress, user}) => {
    return <div>
        <br/><br/>
        <ul className="list-unstyled multi-steps">
            {progress.steps.map(((step,ix)=>{
                return <Node
                    key={ix}
                    step={step}
                    renderUpdate={(e) => renderUpdate(e)}
                    ix={ix}
                    active={step.active}
                    user={user}
                    alert={step.alert}
                />
            }))}
        </ul>
    </div>;
};


export default ShipmentProfileProgressBar