import React, {Component} from "react";
import {FormFeedback, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {StateSelect} from "./StateSelect";
import * as PropTypes from "prop-types";

export class FormStateSelectInputGroup extends Component {
    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}/>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
        return <InputGroup>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>{this.icon()}</InputGroupText>
            </InputGroupAddon>
            <StateSelect name={this.props.name}
                         fieldname={this.props.name}
                         placeholder={this.props.placeholder}
                         errors={this.props.errors}
                         touched={this.props.touched}
                         onChange={this.props.onChange}
                         onBlur={this.props.onBlur}
                         values={this.props.values}
                         disabled={this.props.disabled}
            />
            <FormFeedback>{this.props.errors && this.props.errors[this.props.name]}</FormFeedback>
        </InputGroup>;
    }
}

FormStateSelectInputGroup.propTypes = {
    icon: PropTypes.any,
    name: PropTypes.any,
    placeholder: PropTypes.string,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    disabled: PropTypes.any
};