import React, {Component} from "react";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import {downloadTaskSpotHistory} from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";

class TaskSpotHistory extends Component {
    tableColumns = [
        {
            Header: 'Date',
            accessor: 'date',
            sortable: false,
            filterable: false,
            Cell: row => {return row.original.date ?
                <MomentDisplay
                        timezone={this.props.task.location.timezone}
                        date={row.original.date}/>
                : null},
        },
        {
            Header: 'Last Updated By',
            accessor: 'user.username',
            sortable: false,
            filterable: false,
            Cell: row => {return row.original.user ? (row.original.user.firstname + " " + row.original.user.lastname) : ''}
        },
        {
            Header: 'Assignee',
            accessor: 'assignee.username',
            sortable: false,
            filterable: false,
            Cell: row => {return row.original.assignee ? (row.original.assignee.firstname + " " + row.original.assignee.lastname) : ''}
        },
        {
            Header: 'Move From Spot',
            accessor: 'moveFrom.name',
            sortable: false,
            filterable: false,
            Cell: row => {return row.original.moveFrom ? (row.original.moveFrom.zone + " - " + row.original.moveFrom.name) : ''}
        },
        {
            Header: 'Move To Spot',
            accessor: 'moveTo.name',
            sortable: false,
            filterable: false,
            Cell: row => {return row.original.moveTo ? (row.original.moveTo.zone + " - " + row.original.moveTo.name) : ''}
        },
    ];
    render() {
        return (
            <>
            <h2>Spot History</h2>
                <PaginationFilterTable
                openFiltersCollaps = {true}
                    hideClear={true}
                    get={downloadTaskSpotHistory}
                    hideFilter={true}
                    pageType="Admin"
                    
                    taskId = {{id: "uuid", value: this.props.task.uuid}}
                    stateStorage="poweryard-task-spot-history"
                    columns={this.tableColumns}
                    successCallback={this.props.successCallback}
                    failureCallback={this.props.failureCallback}
                />
            </>
        )
    }
}
TaskSpotHistory.propTypes = {

}
export default TaskSpotHistory;