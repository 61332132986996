import { Circle, GoogleApiWrapper, InfoWindow, Map, Polygon, Polyline } from 'google-maps-react';
import React, { Component } from 'react';
import Config from "../../Config";
import EZTrackAssetMapPinPopup from "../../PingAssets/components/EZTrackAssetMapPinPopup";
import EZCheckInEventMapPinPopup from "../../Shipment/components/EZCheckInEventMapPinPopup";
import EZTrackEventMapPinPopup from "./EZTrackEventMapPinPopup";
import MarkerCluster from "./MarkerClusterer";

const usCenter = { lat: 39.8283, lng: -98.5795 };

export class MapView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMarkerPopup: false,
      activeMarker: {
        asset: {
          name: "",
        },
        event: {
          name: "",
        }
      },
      timer: false,
    };
    this.mapRef = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.legends) {
      this.props.legends.forEach((l) => {
        this.mapRef.current.map.controls[l.position].push(document.getElementById(l.elementId));
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.adjustMap && this.props.boundsMarkers !== prevProps.boundsMarkers) ||
      (this.props.boundsMarkers !== prevProps.boundsMarkers && prevProps.boundsMarkers.length === 0)) {
      this.adjustMap(this.props, this.mapRef.current.map);
    }
  }
  onMapClick() {
    if (this.state.mapZoom && this.state.mapCenter) {
      this.mapRef.current.map.setZoom(this.state.mapZoom);
      this.mapRef.current.map.panTo(this.state.mapCenter);
    }
    this.setState({
      activeMarker: { asset: { name: "" }, event: { name: "" } },
      mapZoom: null, mapCenter: null
    });
  }
  onMarkerClick(args) {
    // if (this.props.supportClick && this.props.onMarkerClick) {
    //     this.props.onMarkerClick(args.props.map, args.marker);
    // }
    if (args.marker.supportClick) {
      if (this.state.timer) {
        clearTimeout(this.state.timer);
      }
      this.setState({
        showMarkerPopup: true, activeMarker: args.marker, timer: false,
        mapCenter: this.mapRef.current.map.getCenter(), mapZoom: this.mapRef.current.map.getZoom()
      });
    }
  }
  onMouseClustureOver(args) {
    if (args.marker.supportMouseOver) {
      if (this.state.timer) {
        clearTimeout(this.state.timer);
      }
      this.setState({
        showMarkerPopup: true, activeMarker: args.marker, timer: false,
        mapCenter: this.mapRef.current.map.getCenter(), mapZoom: this.mapRef.current.map.getZoom()
      });
    }
  }
  onMarkerClose() {
    let timerId = setTimeout(() => {
      this.setState({ showMarkerPopup: false });
    }, 300);
    if (this.state.mapZoom && this.state.mapCenter) {
      this.mapRef.current.map.setZoom(this.state.mapZoom);
      this.mapRef.current.map.panTo(this.state.mapCenter);
    }
    this.setState({ timer: timerId, mapZoom: null, mapCenter: null });
  }
  onMouseOut(args) {
    if (args.marker.supportMouseOut) {
      let timerId = setTimeout(() => {
        this.setState({ showMarkerPopup: false });
      }, 300);
      this.setState({ timer: timerId });
    }
  }
  adjustMap(mapProps, map) {
    if (this.props.zoom && this.props.lat && this.props.lng) {
      map.setZoom(this.props.zoom);
      map.setCenter({ lat: this.props.lat, lng: this.props.lng });
    } else {
      map.fitBounds(this.bounds());
    }
    this.props.google.maps.event.trigger(map, 'resize');
  }

  boundsAround(lat, long, r) {
    lat = parseFloat(lat);
    long = parseFloat(long);
    let dl = r / 69;       // North-south distance in degrees
    let df = dl / (Math.cos(lat * (Math.PI / 180.0))); // East-west distance in degrees
    const bounds = new this.props.google.maps.LatLngBounds();
    bounds.extend(new this.props.google.maps.LatLng(lat - df, long - dl));
    bounds.extend(new this.props.google.maps.LatLng(lat + df, long - dl));
    bounds.extend(new this.props.google.maps.LatLng(lat + df, long + dl));
    bounds.extend(new this.props.google.maps.LatLng(lat - df, long + dl));
    return bounds;
  }


  bounds() {
    if (this.props.boundsMarkers) {
      if (this.props.boundsMarkers.length > 1) {
        const bounds = new this.props.google.maps.LatLngBounds();
        this.props.boundsMarkers.forEach(marker => {
          const { lat, lng } = marker.position;
          bounds.extend(new this.props.google.maps.LatLng(lat, lng));
        });
        // if bounds are less than about 2 miles, we'll do bounds around the center with 1 mile radius to cause zoom out.
        if (Math.abs(bounds.getNorthEast().lng() - bounds.getSouthWest().lng()) < 2.0 / 69.0 &&
          Math.abs(bounds.getNorthEast().lat() - bounds.getSouthWest().lat()) < 2.0 / 69.0) {
          return this.boundsAround(bounds.getCenter().lat(), bounds.getCenter().lng(), 1);
        }
        return bounds;
      } else if (this.props.boundsMarkers.length === 1) {
        const { lat, lng } = this.props.boundsMarkers[0].position;
        return this.boundsAround(lat, lng, 100);
      }
    }
    return this.boundsAround(usCenter.lat, usCenter.lng, 1000);
  }

  render() {
    return (
      <Map google={this.props.google}
        onReady={this.adjustMap.bind(this)}
        containerStyle={{
          width: this.props.mapWidth,
          height: this.props.mapHeight,
        }}
        ref={this.mapRef}
        onClick={this.onMapClick.bind(this)}
      >
        {this.props.locationMarkers && this.props.locationMarkers.length > 0 &&
          <MarkerCluster
            map={this.mapRef}
            google={this.props.google}
            markers={this.props.locationMarkers}
            click={this.onMarkerClick.bind(this)}
            mouseover={this.onMouseClustureOver.bind(this)}
            mouseout={this.onMouseOut.bind(this)}
            markerstyle={'circle'}
          />
        }
        {this.props.markers && this.props.markers.length > 0 &&
          <MarkerCluster
            map={this.mapRef}
            google={this.props.google}
            markers={this.props.markers}
            click={this.onMarkerClick.bind(this)}
            mouseover={this.onMouseClustureOver.bind(this)}
            mouseout={this.onMouseOut.bind(this)}
          />
        }
        {this.props.polygons && this.props.polygons.length > 0 && this.props.polygons.map((c, idx) => {
          return <Polygon
            key={idx}
            map={this.mapRef}
            paths={c.paths}
            strokeColor={c.strokeColor}
            strokeOpacity={c.strokeOpacity}
            strokeWeight={c.strokeWeight}
            fillColor={c.fillColor}
            fillOpacity={c.fillOpacity}
            onClick={this.onMapClick.bind(this)}
          />
        })}
        {this.props.circles && this.props.circles.length > 0 && this.props.circles.map((c, idx) => {
          return <Circle
            key={idx}
            map={this.mapRef}
            center={c.center}
            radius={c.radius}
            strokeColor={c.strokeColor}
            strokeOpacity={c.strokeOpacity}
            strokeWeight={c.strokeWeight}
            fillColor={c.fillColor}
            fillOpacity={c.fillOpacity}
            onClick={this.onMapClick.bind(this)}
          />
        })}
        {this.props.polylines && this.props.polylines.length > 0 && this.props.polylines.map((p, idx) => {
          return <Polyline
            path={p.path}
            map={this.mapRef}
            options={{
              geodesic: p.geodesic,
              strokeColor: p.strokeColor ? p.strokeColor : '#000000',
              strokeOpacity: p.strokeOpacity ? p.strokeOpacity : 1.0,
              strokeWeight: p.strokeWeight ? p.strokeWeight : 2,
              icons: p.icons
            }}
          />
        })}
        <InfoWindow
          // marker={this.state.activeMarker} // don't set marker or CORS blows up for some reason
          position={this.state.activeMarker.position}
          pixelOffset={new this.props.google.maps.Size(0, -30)} // Place the window above the marker pin
          visible={this.state.showMarkerPopup}
          onClick={this.onMarkerClick.bind(this)}
          onMouseout={this.onMouseOut.bind(this)}
          onClose={this.onMarkerClose.bind(this)}
        >
          {this.props.showAssetPopup &&
            <EZTrackAssetMapPinPopup
              asset={this.state.activeMarker.asset}
            />
          }
          {this.props.showShipmentEventPopup &&
            <EZCheckInEventMapPinPopup
              event={this.state.activeMarker.event}
            />
          }
          {this.props.showEZTrackEventPopup &&
            <EZTrackEventMapPinPopup
              event={this.state.activeMarker.event}
            />
          }
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (Config.googleMapsApiKey),
  libraries: ['drawing'],
})(MapView)