import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert,
  Label
} from "reactstrap";
import { checkLogin, postLoginUser, postLoginUserV2, postRegisterUser, trackShipmentsUsingTrackingIDAnonymously } from "../../NetworkHelpers";
import { withRouter } from "react-router";
import { showError, showInfo } from "../../MainApp";
import AccessModal from "./AccessModal";
import * as PropTypes from "prop-types";
import Config from "../../Config";
import { propTypes } from "react-json-pretty";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class LoginCard extends Component {
  render() {
    return (
      <Card className={"pb-0" + this.props.className}>
        <CardBody className={"pb-0"}>
          <Row>
            <div className={"text-center w-100"}>
              <img className={"w-15"} src={"/assets/img/brand/vantix.png"}></img>
            </div>
          </Row>
          <Row className={"d-block d-lg-none d-xl-none"}>
            <div className={"w-100 text-center mt-3"}>
              <Button color="primary"
                className="px-4 d-640px-none"
                onClick={this.props.onTrackViewClick}>Track</Button> &nbsp;
              <Button color="primary"
                className="px-4 d-640px-none"
                onClick={this.props.onLoginViewClick}>Login</Button>
            </div>
          </Row>
          <Row>
            <Col lg={6} className={"br-2px d-none d-lg-block d-xl-block"}>
              <div className="v-space" />
              <div className={"pl-5 pr-5"}>
                <h1 className="text-center ta-text-color">Track Your Shipment</h1>
                <h6 className="text-center ta-text-color">(No login Required)</h6>

                <div className="v-space" />
                <InputGroup className="mb-3 mt-3">

                  <Input
                    type="text"

                    // style={{ 'min-height': '155px' }}
                    placeholder="[Enter your Reference Number(s) here.]"
                    value={this.props.valueShipmentId}
                    onBlur={this.props.onShipmentIdBlur}
                    rows={6}
                    onChange={this.props.onShipmentIdChange}
                  // onKeyPress={this.props.onKeyPress}
                  />
                </InputGroup>
                <Alert>
                  <span className={"font-10"}>
                    Enter your Reference Number(s) above. Can enter multiple space separated Reference numbers provided by your provider. Example Numbers: LOAD ID, BOL, PO, Shipment ID, PRO
                  </span>
                </Alert>
                <Row className={"mt-4"}>
                  <Col xs="12">
                    <Button
                      color="primary"
                      className="px-4 w-100"

                      disabled={this.props.valueShipmentId === ""}
                      onClick={this.props.onTrackClick}
                    >
                      Track
                    </Button>

                  </Col>

                </Row>
              </div>
              <div className="v-space" />
              <div className="v-space" />
            </Col>
            <Col lg={6} className={"bl-2px d-none d-lg-block d-xl-block"}>
              <div className="v-space" />
              <Form className={"pl-5 pr-5"}>
                <h1 className="text-center ta-text-color">Customer Portal</h1>
                <h1 className="text-center ta-text-color">Login</h1>

                <div className="v-space" />
                <InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Enter email here"
                    value={this.props.value}
                    autoComplete="username"
                    onChange={this.props.onUsernameChange}
                    onKeyPress={this.props.onKeyPress}
                  />
                </InputGroup>
                <InputGroup className="mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="password"
                    placeholder="Enter password here"
                    value={this.props.password}
                    autoComplete="current-password"
                    onChange={this.props.onPasswordChange}
                    onKeyPress={this.props.onKeyPress}
                  />
                </InputGroup>
                {/* <div className="v-space" /> */}
                <Row>
                  <Col xs="6">
                    {/* <Label check className={"ml-4"}>
                      <Input type="checkbox" />{' '}
                      Remember Me
                    </Label> */}


                  </Col>
                  <Col xs="6">

                    <Button
                      color="link"
                      className="px-0 muted-link float-right"
                      onClick={this.props.onPasswordResetClick}
                    >
                      Forgot password?
                    </Button>
                  </Col>
                </Row>
                <Row className={"mt-4"}>
                  <Col xs="12">
                    <Button
                      color="primary"
                      className="px-4 w-100"
                      onClick={this.props.onLoginClick}
                    >
                      Login
                    </Button>

                  </Col>

                </Row>
                <Row>
                  <Col xs={12} className={"text-center"}>
                    <span>Don't have an account yet? </span>
                    <Button
                      color="link"
                      className="px-0 muted-link"
                      onClick={this.props.onRequestAccessClick}
                    >
                      Request Access
                    </Button> &nbsp;
                    | &nbsp; <span>Version : {Config.APP_VERSION} </span>
                  </Col>
                </Row>
              </Form>

              <div className="v-space" />
              <div className="v-space" />
            </Col>
          </Row>
          <Row>
            {this.props.showLogin && !this.props.showTrack &&
              <Col lg={6} className={"d-block d-lg-none d-xl-none d-640px-none"}>
                <div className="v-space" />
                <Form className={"pl-5 pr-5"}>
                  <h1 className="text-center ta-text-color">Customer Portal</h1>
                  <h1 className="text-center ta-text-color">Login</h1>

                  <div className="v-space" />
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Enter email here"
                      value={this.props.value}
                      autoComplete="username"
                      onChange={this.props.onUsernameChange}
                      onKeyPress={this.props.onKeyPress}
                    />
                  </InputGroup>
                  <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      placeholder="Enter password here"
                      value={this.props.password}
                      autoComplete="current-password"
                      onChange={this.props.onPasswordChange}
                      onKeyPress={this.props.onKeyPress}
                    />
                  </InputGroup>
                  {/* <div className="v-space" /> */}
                  <Row>
                    <Col xs="6">
                      {/* <Label check className={"ml-4"}>
                        <Input type="checkbox" />{' '}
                  Remember Me
                </Label> */}


                    </Col>
                    <Col xs="6">

                      <Button
                        color="link"
                        className="px-0 muted-link float-right"
                        onClick={this.props.onPasswordResetClick}
                      >
                        Forgot password?
                      </Button>
                    </Col>
                  </Row>
                  <Row className={"mt-4"}>
                    <Col xs="12">
                      <Button
                        color="primary"
                        className="px-4 w-100"
                        onClick={this.props.onLoginClick}
                      >
                        Login
                      </Button>

                    </Col>

                  </Row>
                  <Row>
                    <Col xs={12} className={"text-center"}>
                      <span>Don't have an account yet? </span>
                      <Button
                        color="link"
                        className="px-0 muted-link"
                        onClick={this.props.onRequestAccessClick}
                      >
                        Request Access
                      </Button>
                    </Col>
                  </Row>
                </Form>

                <div className="v-space" />
                <div className="v-space" />
              </Col>

            }
            {!this.props.showLogin && this.props.showTrack &&
              <Col lg={6} className={"d-block d-lg-none d-xl-none"}>
                <div className="v-space" />
                <div className={"pl-5 pr-5"}>
                  <h1 className="text-center ta-text-color">Track Your Shipment</h1>
                  <h6 className="text-center ta-text-color">(No login Required)</h6>

                  <div className="v-space" />
                  <InputGroup className="mb-3">

                    <Input
                      type="text"

                      // style={{ 'min-height': '155px' }}
                      placeholder="[Enter your Reference Number(s) here.]"
                      value={this.props.valueShipmentId}
                      onBlur={this.props.onShipmentIdBlur}
                      rows={6}
                      onChange={this.props.onShipmentIdChange}
                    // onKeyPress={this.props.onKeyPress}
                    />
                  </InputGroup>
                  <Alert>
                    <span className={"font-10"}>
                      Enter your Reference Number(s) above. Can enter multiple space separated Reference numbers provided by your provider. Example Numbers: LOAD ID, BOL, PO, Shipment ID, PRO
                    </span>
                  </Alert>
                  <Row className={"mt-4"}>
                    <Col xs="12">
                      <Button
                        color="primary"
                        className="px-4 w-100"
                        onClick={this.props.onTrackClick}
                        disabled={this.props.valueShipmentId === ""}
                      >
                        Track
                      </Button>

                    </Col>

                  </Row>
                </div>
                <div className="v-space" />
                <div className="v-space" />
              </Col>
            }
          </Row>

        </CardBody>
        <img className={"branding-logo"} src="/assets/img/brand/e4logo.png"></img>
      </Card >

    );
  }
}

LoginCard.propTypes = {
  value: PropTypes.any,
  valueShipmentId: PropTypes.any,
  onUsernameChange: PropTypes.func,
  onShipmentIdChange: PropTypes.func,
  onShipmentIdBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  password: PropTypes.any,
  showLogin: PropTypes.any,
  showTrack: PropTypes.any,
  onPasswordChange: PropTypes.func,
  onLoginClick: PropTypes.func,
  onTrackClick: PropTypes.func,
  onRequestAccessClick: PropTypes.func,
  onLoginViewClick: PropTypes.func,
  onTrackViewClick: PropTypes.func,
  onPasswordResetClick: PropTypes.func,
  className: PropTypes.any,
};

class LoginTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: true,
      shipmentId: "",
      userIsLoggedIn: false,
      showLogin: false,
      showTrack: true,
      showingAccessModal: false,
      loaded: false,
      loader: false,
      requestForm: {
        enableTrackAssured: true
      }
    };
    this.updateSpinner("Searching ...");
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentWillUnmount() {

    document.body.style.background = null;

  }


  componentDidMount() {
    window.scrollTo(0, 0);
    localStorage.removeItem("trackedShipments");
    document.body.style.background = null;

    document.body.style.backgroundColor = "#000";
    this.checkLogin();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  registerClicked(e) {
    e.preventDefault();
    this.nextPath("/register");
  }

  checkLogin() {
    checkLogin(
      (user) => {
        this.props.login(user.username, user);
        this.setState({ loading: false, userIsLoggedIn: true });
      },
      () => {
        this.setState({ loading: false, userIsLoggedIn: false });
      }
    );
  }

  login() {
    postLoginUserV2(
      { username: this.state.username, password: this.state.password },
      (user) => {
        user.enableTrackAssured = true;
        this.props.login(this.state.username, user.content);
        localStorage.setItem('taskPopUpCheck', false);
      },
      () => {
        showError("You could not be logged in. Please check your credentials.");
      }
    );
  }
  getUniqueIds(names) {
    var uniqueArr = [];
    for (var value of names) {
      if (uniqueArr.indexOf(value) === -1) {
        uniqueArr.push(value);
      }
    }
    return uniqueArr;
  }
  hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }
  track() {

    let s = this.state.shipmentId.trim();
    s = s.split(" ");
    s = s.filter(it => it !== "");
    s = s.map(it => it = it.toLowerCase());
    let chec = this.hasDuplicates(s);
    if (chec === true) {
      showInfo("Duplicate IDs are removed.");
    }
    s = this.getUniqueIds(s);
    s = s.toString();
    var data = { "page": 0, "pageSize": 20, "sorted": [], "filtered": [] };
    this.setState({ loader: true });
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    trackShipmentsUsingTrackingIDAnonymously(s, timeZone, data, (response) => {
      this.setState({ loader: false });
      if (response.TrackedShipments.length > 0) {
        localStorage.setItem("trackedShipments", JSON.stringify(response.TrackedShipments));
        this.props.history.push("/track-anonymously/" + response.TrackedShipments[0].assetID + "/" + response.TrackedShipments[0].trackType + "/" + response.TrackedShipments[0].trackID);
      }

      showInfo("Data Loaded.");
    }, (error) => {
      this.setState({ loader: false });
      s = s.split(",");
      let arr = [];
      for (var i = 0; i < s.length; i++) {
        arr.push({ trackID: s[i] });
      }
      arr = JSON.stringify(arr);
      localStorage.setItem("trackedShipments", arr);
      this.props.history.push("/track-anonymously/" + s[0]);
      // showError("Unable to load shipment.");
    })

  }
  onLoginViewClick() {
    this.setState({ showLogin: true, showTrack: false });
  }
  onTrackViewClick() {
    this.setState({ showLogin: false, showTrack: true });
  }
  onRequestAccessClick() {
    this.setState({ showingAccessModal: true });
  }
  onUsernameChange(username) {
    this.setState({ username: username });
  }
  onShipmentIdChange(shipmentId) {
    this.setState({ shipmentId: shipmentId });
  }
  onShipmentIdBlur(shipmentId) {
    shipmentId = shipmentId.trim();
    this.setState({ shipmentId: shipmentId });
  }
  onPasswordChange(password) {
    this.setState({ password: password });
  }

  onEnterLogin(e) {
    if (e.charCode === 13) this.login();
  }

  showRequestPasswordReset() {
    this.props.history.push("password-reset-request-trackassured");
  }

  getCardWidth() {
    return Config.hideInfoOnLogin ? 6 : 12;
  }
  closeAccessModal() {
    this.setState({ showingAccessModal: false });
  }
  render() {
    if (!this.state.loading && !this.state.userIsLoggedIn) {
      return (
        <div className="app flex-row align-items-center animated fadeIn login-TA-background">
          <AccessModal
            open={this.state.showingAccessModal}

            error={this.state.accessModalError}
            onCancel={() => this.closeAccessModal()}

            onSave={(note) => {
              this.closeAccessModal();

            }, (error) => {
              this.closeAccessModal();
            }
            }

          />
          <Container>
            <Loader show={this.state.loader} message={this.spinner}>
              <Row className="justify-content-center">
                <Col md={this.getCardWidth()}>
                  <CardGroup>
                    <LoginCard
                      className={
                        "p-4 text-left" +
                        (this.props.hideLoginOnSmall ? "d-md-down-none" : "")
                      }
                      value={this.state.username}
                      valueShipmentId={this.state.shipmentId}
                      onShipmentIdChange={(e) =>
                        this.onShipmentIdChange(e.target.value)
                      }
                      onShipmentIdBlur={(e) => {
                        this.onShipmentIdBlur(e.target.value)
                      }

                      }
                      onUsernameChange={(e) =>
                        this.onUsernameChange(e.target.value)
                      }
                      onKeyPress={(e) => this.onEnterLogin(e)}
                      password={this.state.password}
                      showLogin={this.state.showLogin}
                      showTrack={this.state.showTrack}
                      onPasswordChange={(e) =>
                        this.onPasswordChange(e.target.value)
                      }
                      onLoginClick={() => this.login()}
                      onTrackClick={(e) => {
                        e.preventDefault();
                        this.track()
                      }}
                      onLoginViewClick={() => this.onLoginViewClick()}
                      onTrackViewClick={() => this.onTrackViewClick()}
                      onRequestAccessClick={() => this.onRequestAccessClick()}
                      onPasswordResetClick={(e) => {
                        e.preventDefault();
                        this.showRequestPasswordReset();
                      }}
                    />

                  </CardGroup>
                </Col>
              </Row>
            </Loader>
          </Container>
        </div>
      );
    }
    return <div></div>;
  }
}

export default withRouter(LoginTA);
