import {combineReducers} from "redux";
import login from "../Login/reducers/Login"
import departure from "../Departure/reducers/Departure"
import assets from "../assets/reducers/Assets"
import dispatchAssets from "../Dispatch/reducers/DispatchAssets";

export default combineReducers({
    login,
    departure,
    assets,
    dispatchAssets
});