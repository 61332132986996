import React, { Component } from "react";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import * as PropTypes from "prop-types";
import { showInfo } from "../MainApp";
export default class TableDateRangePicker extends Component {
  handleDateChange = (type, dateValue) => {
    const date = dateValue.getDate();
    const month = dateValue.getMonth();
    const year = dateValue.getFullYear();
    const localTimeZone = moment.tz.guess();
    let localDate = moment.tz("2023-06-13T00:00:00", localTimeZone);

    localDate.set({
      year: year,
      month: month,
      date: date,
    });

    localDate = localDate.format("YYYY-MM-DD");

    const startDate = moment(this.props.start);
    const endDate = moment(this.props.end);
    const newDate = moment(localDate);

    // If changing start date
    if (this.props.hisotryPageType === "gate-history") {
		// If changing start date
		if (type === "start") {
		  const diffInDays = endDate.diff(newDate, "days");
  
		  if (diffInDays <= 93 && diffInDays >= 0) {
			this.props.onStartDateChange(localDate);
		  } else {
			showInfo("The selected date range cannot exceed 93 days.");
		  }
		}
		// If changing end date
		else if (type === "end") {
		  const diffInDays = newDate.diff(startDate, "days");
  
		  if (diffInDays <= 93 && diffInDays >= 0) {
			this.props.onEndDateChange(localDate);
		  } else {
			showInfo("The selected date range cannot exceed 93 days.");
		  }
      
		}
	  } else {
		// If filterEnabled is false, allow any date selection
		if (type === "start") {
		  this.props.onStartDateChange(localDate);
		} else if (type === "end") {
		  this.props.onEndDateChange(localDate);
		}
	  }
  };

  render() {
    return (
      <>
        <div className="filter-switch">
          <Switch
            onChange={(checked) => {
              this.props.onDateFilterEnable(checked);
            }}
            disabled={this.props.hisotryPageType === "gate-history" ? true : false}
            checked={
              this.props.hisotryPageType === "gate-history"
                ? true
                : this.props.filterEnabled
            }
            uncheckedIcon={
              <div className="text-center pt-1">
                <i className="fas fa-calendar" />
              </div>
            }
            checkedIcon={
              <div className="text-center pt-1">
                <i className="fas fa-calendar" />
              </div>
            }
            title="Show Filter"
            className="react-switch"
          />

          <label style={{ width: "60px", marginLeft: "5px" }}>Between</label>
          &nbsp;
          <DatePicker
            name={"start"}
            className={
              "dispatch-list-date-picker " +
              (this.props.touched && !!this.props.error ? "is-invalid" : "")
            }
            valid={!this.props.error}
            invalid={this.props.touched && !!this.props.error}
            onBlur={this.props.onBlur}
            onChange={(v) => this.handleDateChange("start", v)}
            dateFormat="MM/dd/yyyy"
            selected={moment(this.props.start).tz(moment.tz.guess()).toDate()}
            disabled={!this.props.filterEnabled}
            onFocus={(e) => e.target.select()}
          />
          <label style={{ width: "30px", marginLeft: "5px" }}>and</label>&nbsp;
          <DatePicker
            name={"end"}
            className={
              "dispatch-list-date-picker " +
              (this.props.touched && !!this.props.error ? "is-invalid" : "")
            }
            valid={!this.props.error}
            invalid={this.props.touched && !!this.props.error}
            onBlur={this.props.onBlur}
            dateFormat="MM/dd/yyyy"
            onChange={(v) => this.handleDateChange("end", v)}
            selected={moment(this.props.end).tz(moment.tz.guess()).toDate()}
            disabled={!this.props.filterEnabled}
            onFocus={(e) => e.target.select()}
          />
        </div>
      </>
    );
  }
}

TableDateRangePicker.propTypes = {
  filterEnabled: PropTypes.bool,
  start: PropTypes.any,
  end: PropTypes.any,
  onDateFilterEnable: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
};
