import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    Row
} from "reactstrap";
import {InputAdapter, TextMask} from 'react-text-mask-hoc';

import {Link} from "react-router-dom";


class RegisterCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card: "",
            email: "",
            password: "",
            passwordConfirm: ""
        }
    }

    render() {
        return (
            <div className="app flex-row align-items-center animated fadeIn">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup className="text-left">
                                <Card className="py-5 d-md-down-none">

                                    <CardBody>

                                        <div>
                                            <Button className={"round btn btn-secondary float-right"} onClick={()=>this.setState({showInfographic: true})}>?</Button>
                                            <Modal isOpen={this.state.showInfographic} size={"lg"} onClick={()=>this.setState({showInfographic: false})}>
                                                <ModalBody style={{overflow:'auto'}}>
                                                    <img src="assets/img/infographic/demo.jpg" alt="Shipment"></img>
                                                </ModalBody>
                                            </Modal>
                                            <h1>Register - Payment</h1>
                                            <p className="text-muted">Enter your payment information</p>
                                        <Row>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label htmlFor="name">Name</Label>
                                                    <Input type="text" id="name" placeholder="Enter your name"
                                                           required/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12">
                                                <FormGroup>
                                                    <Label htmlFor="ccnumber">Credit Card Number</Label>

                                                    <TextMask id="ccnumber"
                                                              mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                                        Component={InputAdapter}
                                                          placeholder="0000 0000 0000 0000"
                                                          required
                                                        className="form-control"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <FormGroup>
                                                    <Label htmlFor="ccmonth">Month</Label>
                                                    <Input type="select" name="ccmonth" id="ccmonth">
                                                        {[1,2,3,4,5,6,7,8,9,10,11,12].map(v=><option value={v}>{v}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="4">
                                                <FormGroup>
                                                    <Label htmlFor="ccyear">Year</Label>
                                                    <Input type="select" name="ccyear" id="ccyear">
                                                        >
                                                        {[2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033].map(v=><option value={v}>{v}</option>)}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="4">
                                                <FormGroup>
                                                    <Label htmlFor="cvv">CVV/CVC</Label>
                                                    <Input type="text" id="cvv" placeholder="123" required/>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col xs="12">
                                                <Link to="/"><Button color="success" className="form-control" onClick={()=>{}}>Continue</Button></Link>
                                            </Col>
                                        </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default RegisterCard;


