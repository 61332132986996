import React from 'react';
import PropTypes from 'prop-types';
import Modal from "reactstrap/es/Modal";
import {
    Button,
    Card,
    CardText,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    ModalBody,
    ModalHeader
} from "reactstrap";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {getErrorsFromValidationError} from "../../common/utilities";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {TEXT_MESSAGE} from "../../common/constants"

class TextMessage extends React.Component<{ messages: Object, uuid: T, currentLocation: any }> {
    render() {
        return <Row>
            <Col md={12}>
                <Card body>
                    <CardTitle>
                        <Row>
                            <Col sm={12}>
                                {this.props.messages[this.props.uuid].user}
                            </Col>
                            <Col sm={12}>
                                <MomentDisplay date={this.props.messages[this.props.uuid].created}
                                               timezone={this.props.currentLocation && this.props.currentLocation.timezone}/>
                            </Col>
                        </Row>
                    </CardTitle>
                    <CardText>{this.props.messages[this.props.uuid].message}</CardText>
                </Card>
            </Col>
        </Row>;
    }
}

export default class SendSMSModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            toPhone: props.sendSmsToPhone,
            contactMethod: TEXT_MESSAGE
        }
    }

    validationSchema(values) {
        return Yup.object().shape({
            message: Yup.string().required('Message is required.').nullable()
        })
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                console.log("error", error);
                return getErrorsFromValidationError(error)
            }
        }
    }

    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        let prologue = "EZCheck-In Message to " + this.props.driverFirstName + " from " + this.props.userFirstName + ": ";
        if (this.props.shipmentNumber) {
            prologue = "EZCheck-In Message to " + this.props.driverFirstName + " for " +
                this.props.defaultReferenceLabel + ' - ' + this.props.shipmentNumber
                + " from " + this.props.userFirstName + ": ";
        }
        let message = {message: prologue + values.message,
            // phoneDate: new Date(),
            contactMethod: TEXT_MESSAGE};
        this.props.onSave(message, setErrors);
        return false;
    }

    render() {
        return (
            <Modal isOpen={this.props.open} size={"lg"}>
                <ModalHeader>
                    Send SMS to {this.props.sendSmsToPhone} ({this.props.driverFirstName ? this.props.driverFirstName : ""}&nbsp;
                    {this.props.driverLastName ? this.props.driverLastName : ""})
                </ModalHeader>
                <ModalBody style={{overflow: 'auto'}}>
                    <Formik
                        onSubmit={(v, o) => {
                            this.onSubmit(v, o)
                        }}
                        validate={this.validate(this.validationSchema.bind(this))}
                        initialValues={this.state}
                        >{({
                                     values,
                                     errors,
                                     touched,
                                     status,
                                     dirty,
                                     handleChange,
                                     handleBlur,
                                     handleSubmit,
                                     isSubmitting,
                                     isValid,
                                     handleReset,
                                     setTouched,
                                     setFieldValue,
                                     setFieldTouched
                                 }) => (
                            <Form onSubmit={(v) => {
                                return handleSubmit(v)
                            }}>
                                {this.props.messages && Object.keys(this.props.messages).length > 0 &&
                                    <Row>
                                        <Col md={12}>
                                            <h3>Messages</h3>
                                        </Col>
                                    </Row>
                                }
                                <div className="note-scollbox">
                                {this.props.messages && Object.keys(this.props.messages).length > 0 && Object.keys(this.props.messages).map((uuid, ix) =>
                                    <TextMessage key={ix} messages={this.props.messages} uuid={uuid} currentLocation={this.props.currentLocation}/>
                                )}
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <h3>New message</h3>
                                        <h5>Message will be prepended with: </h5>
                                        <p>{this.props.shipmentNumber ? "EZCheck-In Message to " +
                                            this.props.driverFirstName + " for " + this.props.defaultReferenceLabel +
                                            ' - ' + this.props.shipmentNumber + " from " + this.props.userFirstName + ":" :
                                            "EZCheck-In Message to " + this.props.driverFirstName + " from " +
                                            this.props.userFirstName + ":"} </p>
                                    </Col>
                                </Row>
                                {this.props.error &&
                                <Row>
                                    <Col md={12}>
                                        <p style={{color: "red"}}>{this.props.error}</p>
                                    </Col>
                                </Row>
                                }
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            type={'textarea'}
                                            name={'message'}
                                            valid={touched['message'] && !errors['message']}
                                            invalid={touched['message'] && !!errors['message']}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['messages']}
                                        />
                                        <FormFeedback>{errors['message']}</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-right">
                                    <Button color="danger" onClick={() => this.props.onCancel()}>Cancel</Button> &nbsp;
                                    <Button color="primary" type="submit">Send Message</Button>
                                </div>
                            </Form>)}
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }
}
SendSMSModal.propTypes = {
    messages: PropTypes.object,
    assetId: PropTypes.any,
    open: PropTypes.any,
    uuid: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};
    