import {Component, default as React} from "react";
import {ArrivalAlertClock} from "../Shipment/components/ArrivalAlertClock";
import {RangeAlertCircle} from "../Shipment/components/RangeAlertCircle";

export default class Alerts extends Component {
    render(){
        const arrivalAlert = this.props.alert && this.props.alert.arrivalAlert;
        const rangeAlert = this.props.alert && this.props.alert.rangeAlert;
        return <>
            <span>
                <ArrivalAlertClock
                    arrivalAlert={arrivalAlert && arrivalAlert.arrivalAlert}
                    stopAppointment={arrivalAlert && arrivalAlert.appointment}
                    graceInMins={arrivalAlert && arrivalAlert.graceInMins}
                    timezone={arrivalAlert && arrivalAlert.timezone}
                    allDay={arrivalAlert && arrivalAlert.allDay}
                />
                <RangeAlertCircle
                    distanceUnitsOfMeasure={rangeAlert && rangeAlert.distanceUnitsOfMeasure}
                    geoFenceRange={rangeAlert && rangeAlert.geoFenceRadiusInMeters}
                    rangeAlert={rangeAlert && rangeAlert.rangeAlert}
                    latitude={rangeAlert && rangeAlert.latitude}
                    longitude={rangeAlert && rangeAlert.longitude}
                    locationLatitude={rangeAlert && rangeAlert.locationLatitude}
                    locationLongitude={rangeAlert && rangeAlert.locationLongitude}
                    showBlack
                />
            </span>
        </>;
    }
}