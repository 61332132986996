import React from 'react';
import {Stop} from "../model/Stop";
import {DispatchStopCard} from "./DispatchStopCard";
import {Button} from 'reactstrap';


export default class DispatchStops extends React.Component {
    constructor(props: P, context: any) {
        super(props, context);
        this.state = {
            editingStop: false,
            advancedView: false,
        };
    }

    moveStop(index, destinationIndex) {
        let stops = this.props.values;

        let swap = stops.splice(index, 1)[0];
        stops.splice(destinationIndex, 0, swap);

        this.props.onChange("stops", stops);
    }

    saveStop(stop, index) {
        let modifiedStopPlan = Object.assign({}, this.state.stopplan);

        modifiedStopPlan.stops[index] = stop;

        this.setState({stopplan: modifiedStopPlan});
    }


    addStopToEnd() {
        let stops = this.props.values;
        let newStop = new Stop();
        newStop.isEditing = true;
        stops.push(newStop);
        this.props.onChange("stops", stops);
        // this.setState({editingStop: this.props.values.length})
    }

    addStopReferenceNumbersToDispatchReferenceNumbers() {
        let dispatchRefNos = this.props.dispatchReferenceNumbers ? this.props.dispatchReferenceNumbers.slice() : [];
        this.props.stops.forEach((stop) => {
            if (!!stop.pickupReferenceNumbers) {
                stop.pickupReferenceNumbers.forEach((v) => {
                    let matchingReferences = dispatchRefNos.filter((d) => {
                        return v.type === d.type && v.value === d.value;
                    });
                    if (matchingReferences.length === 0) {
                        dispatchRefNos.push(v);
                    }
                });
            }
            if (!!stop.deliverReferenceNumbers) {
                stop.deliverReferenceNumbers.forEach((v) => {
                    let matchingReferences = dispatchRefNos.filter((d) => {
                        return v.type === d.type && v.value === d.value;
                    });
                    if (matchingReferences.length === 0) {
                        dispatchRefNos.push(v);
                    }
                });
            }
        });
        return dispatchRefNos;
    }

    render(){
        return <>
            <div className={'column'}>
            {this.props.values && this.props.values.map ? this.props.values.map((stop, index) => {
                if(!stop) return <div/>;
                return <>
                    <DispatchStopCard
                        index={index}
                        company={this.props.company}
                        errors={this.props.errors}
                        value={stop}
                        location={stop.location}
                        appointment={stop.appointment}
                        disabled={!stop.canEdit}
                        saveStop={(stop) => {this.onSaveStop(index, stop);}}
                        cancelEdit={() => {this.onCancelEdit(index);}}
                        onDelete={() => {this.onDelete(index);}}
                        onEdit={()=>{this.onEdit(index);}}
                        onUp={()=>{this.moveStop(index, index-1);}}
                        showEditButtons={!this.state.editingStop && stop.actionStep === 0}
                        showCancelButton={this.props.values.length > 1}
                        onBlur={this.props.onBlur}
                        onChange={(value, callback) => {
                            let stop = {...this.props.values[index]};
                            stop = Object.assign(stop, value);
                            this.updateStop(stop, index);
                            if(callback){
                                callback();
                            }
                        }}
                        defaultPickupReferenceType={this.props.defaultPickupReferenceType}
                        defaultDeliverReferenceType={this.props.defaultPickupReferenceType}
                        dispatchReferenceNumbers={this.addStopReferenceNumbersToDispatchReferenceNumbers()}
                        referenceNumberTypeOptions={this.props.referenceNumberTypeOptions}
                        dispatchUuid={this.props.dispatch && this.props.dispatch.uuid}
                    />
                </>
            }) : <div/>}
                <Button onClick={() => this.addStopToEnd()}><i className="fas fa-upload"> </i> Add Stop</Button>
            </div>
        </>
            ;
    }

    updateStop(stop, index) {
        let stops = this.props.stops;
        stops[index] = stop;
        this.setState(stops);
        this.props.onChange("stops", stops);
    }
    onEdit(index) {
        this.setState({
            editingStop: true,
            saveStop: Object.assign({}, this.props.values[index])
        });
    }

    onDelete(index) {
        delete this.props.values[index];
        let newList = [];
        for (const value of this.props.values) {
            if (value) newList.push(value);
        }
        this.props.onChange("stops", newList);
    }

    onCancelEdit(index) {
        if (!this.state.saveStop) {
            delete this.props.values[index];
        }

        let newList = [];
        for (const value of this.props.values) {
            if (value) newList.push(value);
        }
        if (this.state.saveStop) {
            newList[index] = Object.assign({}, this.state.saveStop);
        }

        this.setState({
            editingStop: false,
            saveStop: false
        });
        this.props.onChange("stops", newList);
    }

    onSaveStop(index, stop) {
        if (this.props.values[index]) {
            this.props.values[index] = stop;
            this.props.values[index].isEditing = false;
        }
        this.setState({
            editingStop: false,
            saveStop: false
        })
    }
}