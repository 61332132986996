import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import React, {Component} from "react";

class EZTrackDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trackFrequencyOptions: [],
        }
    }
    filterTrackFrequencyOptions() {
        return this.props.trackFrequencyOptions.filter((d) => {
            return d.trackerType === this.props.value.trackerType
        })
    }
    render() {
        return <>
            <FFFormTextField
                fieldname="zone"
                label="Zone"
                placeholder="Enter the device's zone"
                icon="fa-abacus"
                value={this.props.value.zone}
                required={false}
                numbersOnly={true}
                onChange={(v) => {
                    let s = {...this.props.value};
                    s.zone = v;
                    this.props.onChange(s);
                }}
                onBlur={() => {}}
                error={this.props.errors.zone}
                touched={this.props.touched.zone}
            />
            <FFFormSelectField
                fieldname="trackerType"
                icon="fa-broadcast-tower"
                    label="Device"
                    placeholder="Select the device type"
                    options={["MT9090", "PG2400"]}
                    isClearable={true}
                    value={this.props.value.trackerType}
                    onChange={(v) => {
                        let d = {...this.props.value};
                        if (v) {
                            d.ezTrackTrackingFrequency = this.props.trackFrequencyOptions.filter((d) => {
                                return d.trackerType === v.value })[0];
                        }
                        d.trackerType = v ? v.value : "";
                        this.props.onChange && this.props.onChange(d);
                    }}
                    onBlur={() => {
                        // let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
                        let touched = {...this.state.touched};
                        touched.trackerType = true;
                        this.setState({touched: touched})
                    }}
                    error={this.props.errors.trackerType}
                    touched={this.props.touched.trackerType}
                >
                </FFFormSelectField>
                {this.props.value.trackerType === 'PG2400' &&
                <FFFormSelectField
                    fieldname="ezTrackTrackingFrequency"
                    icon="fa-router"
                    label="Tracking Frequency"
                    placeholder="Select the tracking frequency"
                    options={this.filterTrackFrequencyOptions()}
                    value={this.props.value.ezTrackTrackingFrequency ?
                        this.props.value.ezTrackTrackingFrequency.displayName : null}
                    onChange={(v) => {
                        let s = {...this.props.value};
                        s.ezTrackTrackingFrequency = v;
                        this.props.onChange && this.props.onChange(s);
                    }}
                    onBlur={() => {
                        // let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
                        // let touched = {...this.state.touched};
                        // touched.devices[idx].ezTrackTrackingFrequency = true;
                        // this.setState({errors: errors, touched: touched})
                    }}
                    error={this.props.errors.ezTrackTrackingFrequency}
                    touched={this.props.touched.ezTrackTrackingFrequency}
                >
                </FFFormSelectField>
                }
                {this.props.value.trackerType === 'MT9090' &&
                <FFFormTextField
                    fieldname="ezTrackTrackingFrequency"
                    label="Tracking Frequency"
                    placeholder="Select the tracking frequency"
                    value={this.filterTrackFrequencyOptions()[0].label}
                    icon='fa-router'
                    disabled={true}
                />
                }
                <FFFormTextField
                    fieldname="imei"
                    label="Device ID"
                    placeholder="Enter the device's IMEI"
                    icon="fa-barcode"
                    value={this.props.value.imei}
                    required={false}
                    numbersOnly={false}
                    onChange={(v) => {
                        let s = {...this.props.value};
                        s.imei = v;
                        this.props.onChange(s);
                    }}
                    onBlur={() => {
                        // let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
                        // let touched = {...this.state.touched};
                        // touched.devices[idx].imei = true;
                        // this.setState({errors: errors, touched: touched})
                    }}
                    error={this.props.errors.imei}
                    touched={this.props.touched.imei}
                >
                </FFFormTextField>
            </>
    }
}
export default EZTrackDevice;