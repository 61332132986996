import React, { Component } from "react";

import Modal from "reactstrap/es/Modal";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import {deleteAttachment, getAttachments,downloadGateAttachmentsByArrivalID,deleteGateAttachment,downloadDriverImage} from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import Lightbox from "react-image-lightbox";
import Config from "../../Config";
import "react-image-lightbox/style.css";
import DeleteAttachmentModal from "./DeleteAttachmentModal";
class AttachmentsImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false
    }
    };
   openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }

    render()
    {return(
        this.state.open ?

            (<Modal isOpen={this.state.open} size={"lg"}>
              <ModalHeader>
                <h1>PDF Viewer </h1>
              </ModalHeader>
              <ModalBody>
                <Container className="note-scrollbox">
                  <embed src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${this.props.src}`}
                         type="application/pdf"
                         height={720} />
                         
                </Container>
              </ModalBody>
              <ModalFooter>
                <Row>
                  <Col className="text-right" >
                    &nbsp;<Button color="danger" onClick={() => this.setState({open:!this.state.open})} type="submit">Close</Button>
                  </Col>
                </Row>
              </ModalFooter>
            </Modal>)
            : <Button title="Preview" className="ml-1 mr-1" onClick={() => this.setState({open:!this.state.open})}><i  className="fa fa-eye"/></Button>);
    }
  }

class AttachmentsModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedArrival: {
        firstname: "",
        mobile: ""
      },
      deleteButtonCheck: false,
      lstDeletedAttachments: [],
      allAttachments: [],
      gateAttachments: [],
      updatePopupState: false,
      hideActionColumn: false,
      currentCompany: "",
      currentLocation: "",
      lstAttachments: [],
      gatedelete:false,


    };
  }
  openInNewTab(href) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    }).click();
  }

downloadImageFile(imageId) {
  // Call the API function
  downloadDriverImage(
    imageId,
    (response) => {
      if (response.statusCode === "200" && response.data) {
        const { path, fileName } = response.data;

        const fileUrl = `${Config.blobUrl}${path}`;

        fetch(fileUrl)
          .then((res) => {
            if (!res.ok) {
              throw new Error("Failed to fetch the file");
            }
            return res.blob(); 
          })
          .then((blob) => {
          
            const blobUrl = window.URL.createObjectURL(blob);

            // Trigger download
            const anchor = document.createElement("a");
            anchor.href = blobUrl;
            anchor.download = fileName; // Use the filename from API response
            document.body.appendChild(anchor);
            anchor.click();

            // Clean up
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(blobUrl);
            console.log(`File "${fileName}" downloaded successfully!`);
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
          });
      } else {
        console.error("Invalid API response", response);
      }
    },
    (error) => {
      console.error("API call failed:", error);
    }
  );
}



  onDelete(index) {
    let lastAttachments = this.state.lstAttachments
    delete lastAttachments[index];
    let newList = [];
    for (const value of lastAttachments) {
      if (value) newList.push(value);
    }
    this.setState({lstAttachments: newList});
  }
  componentWillUpdate(previousProps, prevState, snapshot) {

    if (previousProps.open && this.state.updatePopupState === false) {

      this.setState({
        selectedArrival: previousProps.selectedArrival,
        updatePopupState: true,
        hideActionColumn: false,
        deleteButtonCheck: false,
        currentCompany: previousProps.currentCompany,
        currentLocation: previousProps.currentLocation,
        hideChat: previousProps.hideChat,
        lstAttachments: []
      });
      this.fetchAttachments(previousProps.selectedArrival.arrivalID);

    }



  }
  deleteGateAttachments(controlId,arrivalID){
    const payload = {
     
      controllId: controlId,
      arrivalId: arrivalID,
      comments: "",
      url:"",
  };
     deleteGateAttachment(payload, (res) => {
      if (res.statusCode === "200") {
        showInfo('Attachment deleted successfully');
        this.setState({
         gatedelete:false,
        });
            } 
            else {
        showError('Failed to delete attachment');
      }
    }, (err) => {
      showError(err);
    });
   this.closePopup();

  }
  fetchAttachments(arrivalID){
    getAttachments(arrivalID, (res) => {
      if (res.statusCode === "MS-200") {
        const driverAttachments = res.data.driverAttachments || []; // Safely access driverAttachments
        const lstDeletedAttachments = driverAttachments.filter(it => it.isDelete === true);
        const gateAttachments= res.data.gateAttachments || [];
        this.setState({
          allAttachments: res.data,
          lstAttachments: driverAttachments.filter(it => it.isDelete !== true), // Filter out deleted attachments
          lstDeletedAttachments: lstDeletedAttachments,
          gateAttachments:gateAttachments,
        });
      } else {
        showError('Unexpected response status');
      }
    }, (err) => {
      showError(err);
    });
    
  }
   handleDownload = (base64,name) => {
    const base64String = base64
    const byteCharacters = atob(base64String);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: 'application/pdf' });

    const link = document.createElement('a');
    
    const url = URL.createObjectURL(blob);
    link.href = url;

    link.download = name;

    link.click();

    URL.revokeObjectURL(url);
  };

  fetchGateAttachments(arrivalID,controllId){
    downloadGateAttachmentsByArrivalID(arrivalID, controllId, (res) => {
      if (res.statusCode=="200") {
        if(res.data){
          const file=res.data.fileContents;
          this.handleDownload(file,res.data.fileDownloadName);
        }
       
      } else {
        showError('Unexpected response status');
      }
    }, (err) => {
      showError(err);
    });
    
    
  }
  showDeleted(){
    if(this.state.deleteButtonCheck == true){
      this.state.deleteButtonCheck = false ;
      this.setState({deleteButtonCheck : this.state.deleteButtonCheck,hideActionColumn: false,lstAttachments : this.state.allAttachments.filter(it=> it.isDelete !== true)} );
    } else {
      this.setState({deleteButtonCheck : true,lstAttachments : this.state.allAttachments.filter(it=> it.isDelete == true), hideActionColumn : true} );
    }
  }
  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1> Attachments</h1>
        </ModalHeader>
        <ModalBody>
          <Container className="note-scollbox">
          <h1>Driver Attachments {this.state.deleteButtonCheck && this.state.deleteButtonCheck == true ? '(Deleted)': ''}{this.state.lstDeletedAttachments && this.state.lstDeletedAttachments.length > 0 && <Button title="Show Deleted" className={'deleteAttachmentButton'} disabled={this.state.lstDeletedAttachments.length == 0}  onClick={()=>{this.showDeleted()}}><i className="fas fa-trash-alt"></i></Button>}</h1>

          {/* <PaginationFilterTable
                    openFiltersCollaps = {false}
                    hideClear={true}
                    get={getAttachments}
                    hideFilter={true}
                    pageType="Admin"
                    baseFilter={[{id: "ArrivalID", value: this.state.selectedArrival && this.state.selectedArrival.arrivalID}]}
                    stateStorage="poweryard-gate-attachments"
                    columns={this.tableColumns}
                    successCallback={this.props.successCallback}
                    failureCallback={this.props.failureCallback}
                /> */}
                <DeleteAttachmentModal
                    open={this.state.deleteModalOpen}
                    selectedAttachment = {this.state.selectedAttachment}
                    gate={this.state.gatedelete}
                    onSave={(obj) => {
                      if(this.state.gatedelete){
                        this.deleteGateAttachments(obj.controlID,obj.arrivalID);
                        this.setState({ deleteModalOpen: false });

                      }
                      else{
                        deleteAttachment(obj, (res) => {
                        if(res.statusCode == "200"){
                          showInfo('Successfully deleted.');
                          this.setState({ deleteModalOpen: false });
                          this.fetchAttachments(this.state.selectedArrival.arrivalID);
                        }
                      }, () => {
                      });
                      }
                     
                    }}
                    onClose={() => this.setState({ deleteModalOpen: false })}
                  />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      {this.state.hideActionColumn !== true &&
                      <th>Actions</th>
                      }
                      <th>Label</th>
                      <th>{this.state.hideActionColumn == true ? 'Deleted ': 'Created '} Date</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                    {this.state.lstAttachments.length > 0 && this.state.lstAttachments.map((it, key) => {
                     return (
                      <tr key={key}>
                      {this.state.hideActionColumn !== true &&
                      <td>
                        <AttachmentsImage  src={Config.blobUrl+it.attachment}/>
                        <Button title="Download" className="ml-1" onClick={() => this.openInNewTab(Config.blobUrl+it.attachment)}><i  className="fa fa-download"/></Button>
                        {this.state.deleteButtonCheck !== true && 
                        <Button title="Delete" className="ml-1" onClick={() => {
                          this.setState({
                            selectedAttachment : it,
                            deleteModalOpen : true
                          });
                        }}><i  className="fa fa-trash"/></Button>
                        }
                        {/*<img src={Config.blobUrl+it.attachment} alt="" className="hw-100" onClick={() => this.setState({ isOpen: true, imageSource :  Config.blobUrl+it.attachment})}/>*/}
                      </td>
                    }
                      <td>{it.control.label}</td>
                      <td> <MomentDisplay date={it.isDelete == true ? it.deletedDate+"Z" : it.createdDate+"Z"}
                  timezone={this.state.currentLocation && this.state.currentLocation.timezone} /></td>
                    </tr>
                          )
                    })}
                    {this.state.lstAttachments.length == 0 && 
                    <tr>
                      <td colSpan={4}><p className="text-center">No Data to show</p></td>
                    </tr>
                      
                    }
                    
                  </tbody>
                </Table>
                <h1>Gate Attachments</h1>


                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Actions</th>
                    
                      <th>Label</th>
                      <th>{'Created '} Date</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                    {this.state.gateAttachments.length > 0 && this.state.gateAttachments.map((it, key) => {
                     return (
                      <tr key={key}>
                      <td>
                        <Button title="Download" className="ml-1" onClick={() => this.fetchGateAttachments(it.arrivalID,it.controlID)}><i  className="fa fa-download"/></Button>
                        <Button title="Delete" className="ml-1" onClick={() => {
                           this.setState({
                            selectedAttachment : it,
                            deleteModalOpen : true,
                            gatedelete:true
                          });
                        }}><i  className="fa fa-trash"/></Button>
                        
                        {/*<img src={Config.blobUrl+it.attachment} alt="" className="hw-100" onClick={() => this.setState({ isOpen: true, imageSource :  Config.blobUrl+it.attachment})}/>*/}
                      </td>
                    
                      <td>{it.label}</td>
                      <td> <MomentDisplay date={ it.createdDate+"Z"}
                  timezone={this.state.currentLocation && this.state.currentLocation.timezone} /></td>
                    </tr>
                          )
                    })}
                    {this.state.gateAttachments.length == 0 && 
                    <tr>
                      <td colSpan={4}><p className="text-center">No Data to show</p></td>
                    </tr>
                      
                    }
                    
                  </tbody>
                </Table>
          </Container>
        </ModalBody>
        <ModalFooter>
        <Row> 
        {/* style={{ marginTop: "10px" }} */}
              <Col className="text-right" >
                {/* {this.state.hideChat == false &&
                  <Button disabled={!this.state.message} color="primary" onClick={() => this.onSubmit()} >Send</Button>
                } */}

                &nbsp;<Button color="danger" onClick={() => this.closePopup()} type="submit">Close</Button>
              </Col>
            </Row>
        </ModalFooter>
      </Modal>
    )
  }
  closePopup() {
    this.setState({ updatePopupState: false,deleteButtonCheck: false,hideActionColumn:false,gateAttachments: [], lstAttachments: [], allAttachments: [], lstDeletedAttachments: [] })
    return this.props.onClose && this.props.onClose();

  }
  onSubmit() {
    let da = moment.utc();
    let gt = da.valueOf();
    let obj =
    {
      arrival: {
        arrivalID: this.state.selectedArrival.arrivalID
      },
      created: gt,

      toPhone: this.state.selectedArrival.mobile,
      message: this.state.message,
      contactMethod: "messenger & sms",
      messageType: false,
      orgName: this.state.currentCompany,
      orgUuid: this.state.selectedArrival.organizationUuid,
      locName: this.state.currentLocation
    }
    this.setState({ updatePopupState: false })
    return this.props.onAdd && this.props.onAdd(obj);
  }
  localDateToUTC(dateISOLocalString) {
    let localLoginTime = moment.utc(dateISOLocalString).utc();
    var returnDate = new Date(localLoginTime.format('MMM-DD-YYYY HH:mm:ss'));
    return returnDate;
  }
}
export default AttachmentsModal;