import React, { Component } from "react";
import withRouter from "react-router/withRouter";
import { showError } from "../../MainApp";
import * as moment from "moment";
import MapView from "./MapView";
import { downloadEZTrackEquipmentForTable, downloadLocationList } from "../../NetworkHelpers";
import { DRAW_ON_MAP, GOOGLE_MAP_ICONS } from "../../common/constants";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Label from "reactstrap/lib/Label";
import { Progress } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import { GoogleApiWrapper } from "google-maps-react";
import Config from "../../Config";
import { milesToMeters } from "../../common/utilities";

const USAGE_COLOR = 'info';
class EZTrackMap extends Component {

  constructor(props) {
    super(props);
    let assetUsage = new Map();
    assetUsage.set(3, null);
    assetUsage.set(7, null);
    assetUsage.set(30, null);
    assetUsage.set(60, null);
    assetUsage.set(90, null);
    assetUsage.set('all', null);
    let localStorageState = JSON.parse(localStorage.getItem('eztrack-map'));
    if (localStorageState != null) {
      this.state = {
        loading: true,
        loadingLocations: true,
        markers: [],
        domicileLocations: [],
        nonDomicileLocations: [],
        circles: [],
        assets: [],
        equipmentTypes: [],
        mapSize: { height: "500px", width: "500px" },
        usageHistory: assetUsage,
        lat: localStorageState.lat,
        lng: localStorageState.lng,
        zoom: localStorageState.zoom,
        hideKpi: localStorageState.hideKpi === undefined ? false : localStorageState.hideKpi,
        hideMarkerLegend: localStorageState.hideMarkerLegend === undefined ? false : localStorageState.hideMarkerLegend,
        showRedMarkers: localStorageState.showRedMarkers === undefined ? true : localStorageState.showRedMarkers,
        showYellowMarkers: localStorageState.showYellowMarkers === undefined ? true : localStorageState.showYellowMarkers,
        showGreenMarkers: localStorageState.showGreenMarkers === undefined ? true : localStorageState.showGreenMarkers,
        showBlueMarkers: localStorageState.showBlueMarkers === undefined ? true : localStorageState.showBlueMarkers,
        showPurpleMarkers: localStorageState.showPurpleMarkers === undefined ? true : localStorageState.showPurpleMarkers,
        showLocationsWithAssets: localStorageState.showLocationsWithAssets === undefined ? true : localStorageState.showLocationsWithAssets,
        showAllCheckbox: localStorageState.showAllCheckbox === undefined ? true : localStorageState.showAllCheckbox,
        assetTypeFilter: localStorageState.assetTypeFilter,
      }
    } else {
      this.state = {
        loading: true,
        loadingLocations: true,
        markers: [],
        domicileLocations: [],
        nonDomicileLocations: [],
        circles: [],
        assets: [],
        equipmentTypes: [],
        mapSize: { height: "500px", width: "500px" },
        usageHistory: assetUsage,
        hideKpi: false,
        hideMarkerLegend: false,
        showRedMarkers: true,
        showYellowMarkers: true,
        showGreenMarkers: true,
        showBlueMarkers: false,
        showPurpleMarkers: false,
        showLocationsWithAssets: true,
        showAllCheckbox: false,
      }
    }

    this.mapView = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location && this.props.location.state
      && !(Object.keys(this.props.location.state).length === 0
        && this.props.location.state.constructor === Object)) {
      let lat = this.props.location.state.lat;
      let lng = this.props.location.state.lng;
      let zoom = this.props.location.state.zoom;
      this.setState({ lat: lat, lng: lng, zoom: zoom });
    }
    this.setState({
      loading: true, legends: [
        {
          position: this.props.google.maps.ControlPosition.LEFT_TOP,
          elementId: 'legend',
        },
        {
          position: this.props.google.maps.ControlPosition.RIGHT_TOP,
          elementId: 'marker-legend',
        }
      ]
    });
    this.downloadEZTrackAssets();
    this.downloadEquipmentTypes();
    this.downloadLocations();
    window.addEventListener('resize', this.getMapSize.bind(this));
    window.addEventListener('click', this.handleClick.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.getMapSize.bind(this));
    window.removeEventListener('click', this.handleClick.bind(this));
    localStorage.setItem('eztrack-map', JSON.stringify(
      {
        // lat:this.mapView.current.mapRef.current.map.center.lat(),
        // lng: this.mapView.current.mapRef.current.map.center.lng(),
        // zoom: this.mapView.current.mapRef.current.map.getZoom(),
        hideKpi: this.state.hideKpi,
        hideMarkerLegend: this.state.hideMarkerLegend,
        showRedMarkers: this.state.showRedMarkers,
        showYellowMarkers: this.state.showYellowMarkers,
        showGreenMarkers: this.state.showGreenMarkers,
        showBlueMarkers: this.state.showBlueMarkers,
        showPurpleMarkers: this.state.showPurpleMarkers,
        showLocationsWithAssets: this.state.showLocationsWithAssets,
        showAllCheckbox: this.state.showAllCheckbox,
        assetTypeFilter: this.state.assetTypeFilter,
      }))
  }
  downloadEquipmentTypes() {
    let searchCriteria = {
      page: 0,
      pageSize: 100,
      sorted: [],
      filtered: [{ id: "enabled", value: "true" }]
    };
    downloadEZTrackEquipmentForTable(searchCriteria, (success) => {
      this.setState({ equipmentTypes: success.content });
    }, (failure) => {
      showError("Unable to list the equipment types.");
      this.setState({ equipmentTypes: [] });
    })
  }
  downloadEZTrackAssets() {
    let pageSize = Config.ezTrackMapAssetLimit != undefined ? Config.ezTrackMapAssetLimit : 999999;
    let searchRequest = {
      page: 0,
      pageSize: pageSize,
      sorted: [],
      filtered: [{ id: 'enabled', value: true }, { id: 'hasDevices', value: true }]
    };
    this.props.get(searchRequest, (response) => {
      this.setState({ loading: false, assets: response.content });
      this.generateMarkers();
      this.calculateUsageStatistics();
      if (this.state.locations) {
        this.classifyDomiciles();
      }
      this.getMapSize();
    }, () => {
      showError("Unable to load EZTrack Assets.");
    })
  }
  downloadLocations() {
    downloadLocationList((success) => {
      this.setState({ loadingLocations: false, locations: success });
      if (this.state.assets) {
        this.classifyDomiciles();
      }
      this.getMapSize();
    }, (failure) => {
      if (!failure.message.includes('this.state.locations')) {
        showError("Unable to load locations.");
      }
    });
  }
  classifyDomiciles() {
    let nonDomiciles = [];
    let locationsWithAssets = [];
    let assetsPerLocation = new Map();
    let domiciles = [];
    let domicileNames = [];
    for (let i = 0; i < this.state.assets.length; i++) {
      let asset = this.state.assets[i];
      if (asset.locationName !== null) {
        if (assetsPerLocation.has(asset.locationName)) {
          assetsPerLocation.set(asset.locationName, assetsPerLocation.get(asset.locationName) + 1);
        } else {
          assetsPerLocation.set(asset.locationName, 1);
        }
      }
      if (asset.domicile != null && domicileNames.indexOf(asset.domicile.name) === -1) {
        domicileNames.push(asset.domicile.name);
      }
    }
    // this.state.locations.forEach((loc) => {
    //     loc = JSON.stringify(loc);
    //     let b = JSON.parse(loc);
    //     if (assetsPerLocation.has(b.name)) {
    //         if (domicileNames.indexOf(b.name) !== -1) {
    //             b.label = undefined;
    //             b.geofenceColor = "#8f68fd";
    //             b.icon = GOOGLE_MAP_ICONS['purple-D'];
    //             domiciles.push(b);

    //         }


    //     }
    // });
    this.state.locations.forEach((loc) => {
      loc = JSON.stringify(loc);
      let a = JSON.parse(loc);
      if (assetsPerLocation.has(a.name)) {
        // if (domicileNames.indexOf(loc.name) === -1) {
        //     // not a domicile, but with an asset within its geofence
        //     loc.geofenceColor = "#0000FF";
        //     loc.icon = undefined;

        // } else {
        //     loc.geofenceColor = "#8f68fd";
        //     // loc.icon = GOOGLE_MAP_ICONS['purple-D'];
        //     // domiciles.push(loc);
        // }
        a.geofenceColor = "#0000FF";
        a.icon = undefined;
        let index = domiciles.findIndex(it => it.name === a.name);
        if (index > -1) {
          var min = .999999;
          var max = 1.000001;
          a.latitude = parseFloat(a.latitude) * (Math.random() * (max - min) + min);
          a.longitude = parseFloat(a.longitude) * (Math.random() * (max - min) + min);

        }
        a.label = assetsPerLocation.get(a.name).toString();
        locationsWithAssets.push(a);

      } else if (domicileNames.indexOf(a.name) === -1) {
        a.geofenceColor = "#0000FF";
        a.label = undefined;
        a.icon = GOOGLE_MAP_ICONS['blue-L'];
        nonDomiciles.push(a);
      }
      // else {
      //     a.geofenceColor = "#8f68fd";
      //     a.label = undefined;
      //     a.icon = GOOGLE_MAP_ICONS['purple-D'];

      //     domiciles.push(a);
      // }
    });
    this.state.locations.forEach((loc) => {
      loc = JSON.stringify(loc);
      let a = JSON.parse(loc);

      a.geofenceColor = "#8f68fd";
      a.label = undefined;
      a.icon = GOOGLE_MAP_ICONS['purple-D'];
      if (a.isDomicile === true) {
        domiciles.push(a);
      }

    });

    this.setState({ domicileLocations: domiciles, nonDomicileLocations: nonDomiciles, locationsWithAssets: locationsWithAssets })
  }
  calculateUsageStatistics(assetFilter) {
    let assetUsage = this.state.usageHistory;
    let assetMoves = new Map();
    assetMoves.set(3, 0);
    assetMoves.set(7, 0);
    assetMoves.set(30, 0);
    assetMoves.set(60, 0);
    assetMoves.set(90, 0);
    assetMoves.set('all', 0);
    let assetCount = new Map();
    assetCount.set(3, 0);
    assetCount.set(7, 0);
    assetCount.set(30, 0);
    assetCount.set(60, 0);
    assetCount.set(90, 0);
    assetCount.set('all', 0);
    this.state.assets.filter(a => {
      return !assetFilter || (a.equipment &&
        a.equipment.name === assetFilter)
    }).forEach((a) => {
      if (a.movesInLast3Days != null && a.daysOfEventHistory >= 3) {
        assetMoves.set(3, (assetMoves.get(3) + (a.movesInLast3Days / 3)));
        assetCount.set(3, assetCount.get(3) + 1);
      }
      if (a.movesInLast7Days != null && a.daysOfEventHistory >= 7) {
        assetMoves.set(7, (assetMoves.get(7) + (a.movesInLast7Days / 7)));
        assetCount.set(7, assetCount.get(7) + 1);
      }
      if (a.movesInLast30Days != null && a.daysOfEventHistory >= 30) {
        assetMoves.set(30, (assetMoves.get(30) + (a.movesInLast30Days / 30)));
        assetCount.set(30, assetCount.get(30) + 1);
      }
      if (a.movesInLast60Days != null && a.daysOfEventHistory >= 60) {
        assetMoves.set(60, (assetMoves.get(60) + (a.movesInLast60Days / 60)));
        assetCount.set(60, assetCount.get(60) + 1);
      }
      if (a.movesInLast90Days != null && a.daysOfEventHistory >= 90) {
        assetMoves.set(90, (assetMoves.get(90) + (a.movesInLast90Days / 90)));
        assetCount.set(90, assetCount.get(90) + 1);
      }
      if (a.totalDaysWithAMove != null) {
        assetMoves.set('all', (assetMoves.get('all') + (a.totalDaysWithAMove / a.daysOfEventHistory)));
        assetCount.set('all', assetCount.get('all') + 1);
      }
    });
    if (assetCount.get(3) !== 0) {
      assetUsage.set(3, new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format(assetMoves.get(3) / assetCount.get(3) * 100));
    }
    if (assetCount.get(7) !== 0) {
      assetUsage.set(7, new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format(assetMoves.get(7) / assetCount.get(7) * 100));
    }
    if (assetCount.get(30) !== 0) {
      assetUsage.set(30, new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format(assetMoves.get(30) / assetCount.get(30) * 100));
    }
    if (assetCount.get(60) !== 0) {
      assetUsage.set(60, new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format(assetMoves.get(60) / assetCount.get(60) * 100));
    }
    if (assetCount.get(90) !== 0) {
      assetUsage.set(90, new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format(assetMoves.get(90) / assetCount.get(90) * 100));
    }
    if (assetCount.get('all') !== 0) {
      assetUsage.set('all', new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
        .format(assetMoves.get('all') / assetCount.get('all') * 100));
    }
    this.setState({ usageHistory: assetUsage, usageCount: assetCount });
  }
  generateMarkers() {
    let markers = [];
    markers = markers.concat(this.generateAssetMarkers());
    markers = markers.concat(this.generateLocationMarkers());
    return markers;
  }
  generateLocationMarkers() {
    let markers = [];
    let locationMarkers = this.state.showBlueMarkers ?
      this.createMarkersForLocations(this.state.nonDomicileLocations, 1000) : [];
    let domicileMarkers = this.state.showPurpleMarkers ?
      this.createMarkersForLocations(this.state.domicileLocations, 10000) : [];
    let locationsWithAssetMarkers = this.state.showLocationsWithAssets ?
      this.createMarkersForLocations(this.state.locationsWithAssets, 100) : [];
    markers = markers.concat(locationMarkers);
    markers = markers.concat(domicileMarkers);
    markers = markers.concat(locationsWithAssetMarkers);
    return markers;
  }
  generateAssetMarkers() {
    let markers = [];
    let shape = {
      // coords: [5, 1, 5, 22, 27, 22, 27, 1],
      // coords: [5, 1, 5, 22, 16, 35, 27, 22, 27, 1],
      coords: [7, 3, 7, 20, 16, 33, 25, 20, 25, 1],
      type: "poly"
    };
    this.state.assets.filter(a => {
      return !this.state.assetTypeFilter || (a.equipment &&
        a.equipment.name === this.state.assetTypeFilter)
    })
      .forEach(asset => {
        if (asset.lastEventLatitude != null && asset.lastEventLongitude != null) {
          let icon = { url: GOOGLE_MAP_ICONS["red-dot"] };
          let zIndexOffset = 100000000;
          let days = 0;
          if (asset.dateOfLastMove != null) {
            let movingDay = new Date(asset.dateOfLastMove);
            let durationInMillis = new Date().getTime() - movingDay.getTime();
            let d = moment.duration(durationInMillis);
            days = Math.floor(d.asDays());
            if (days <= 4) {
              icon = { url: GOOGLE_MAP_ICONS["green-dot"] };
              zIndexOffset = 1000000;
            } else if (days <= 10) {
              icon = { url: GOOGLE_MAP_ICONS["yellow-dot"] };
              zIndexOffset = 10000000;
            }
          }
          if ((days <= 4 && this.state.showGreenMarkers) ||
            (days > 4 && days <= 10 && this.state.showYellowMarkers) ||
            (days > 10 && this.state.showRedMarkers)) {
            markers.push({
              position: {
                lat: asset.lastEventLatitude,
                lng: asset.lastEventLongitude
              },
              name: asset.assetId,
              icon: icon,
              shape: shape,
              asset: asset,
              zIndex: zIndexOffset - asset.lastEventLatitude * 100,
              supportClick: true,
              supportMouseOver: false,
            });
          }
        }
      });
    return markers;
  }
  createMarkersForLocations(locations, zIndexOffset) {
    let markers = [];
    locations = locations == null ? [] : locations;
    locations.forEach(location => {
      if (location !== undefined && location.latitude != null && location.longitude != null) {
        let pos = {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude)
        };
        let x = markers.filter(it => it.position.lat === pos.lat && it.position.lng === pos.lng);
        if (x.length > 0) {
          var min = .999999;
          var max = 1.000001;
          let a = 300.0 / x.length;
          x = x[x.length - 1].position;
          console.log(x);
          pos.lat = x.lat * (Math.random() * (max - min) + min);
          pos.lng = x.lng * (Math.random() * (max - min) + min);
        }
        if (location.geofenceEnabled === true) {
          markers.push({
            title: location.name,
            position: pos,
            label: location.label != null ? { text: location.label, color: '#FFFFFF', fontSize: '14px' } : null,
            name: location.name,
            location: location,
            strokeColor: location.geofenceColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: location.geofenceColor,
            fillOpacity: 0.25,
            optimized: true,
            icon: location.icon ? location.icon : {
              path: "m 817.11249,282.97118 c -1.25816,1.34277 -2.04623,3.29881 -2.01563,5.13867 0.0639,3.84476 1.79693,5.3002 4.56836,10.59179 0.99832,2.32851 2.04027,4.79237 3.03125,8.87305 0.13772,0.60193 0.27203,1.16104 0.33416,1.20948 0.0621,0.0485 0.19644,-0.51262 0.33416,-1.11455 0.99098,-4.08068 2.03293,-6.54258 3.03125,-8.87109 2.77143,-5.29159 4.50444,-6.74704 4.56836,-10.5918 0.0306,-1.83986 -0.75942,-3.79785 -2.01758,-5.14062 -1.43724,-1.53389 -3.60504,-2.66908 -5.91619,-2.71655 -2.31115,-0.0475 -4.4809,1.08773 -5.91814,2.62162 z",
              fillColor: location.geofenceColor,
              anchor: new this.props.google.maps.Point(823, 308),
              labelOrigin: new this.props.google.maps.Point(823, 290),
              scale: 1.3,
              fillOpacity: 1.0,
              strokeColor: '#000000',
            },
            zIndex: zIndexOffset - location.latitude * 10
          });
        }
      }

    });

    return markers;
  }
  generatePolygonsFor(locations) {
    let polygons = [];
    if (locations) {
      locations.forEach(location => {
        if (location.geofenceEnabled && location.geofenceType === DRAW_ON_MAP && location.shape.geometry.type === "Polygon") {
          let coords = [];
          location.shape.geometry.coordinates[0].map((coord) => coords.push({ lat: coord[1], lng: coord[0] }));
          polygons.push({
            paths: coords,
            strokeColor: location.geofenceColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: location.geofenceColor,
            fillOpacity: 0.25
          })
        }
      });
    }
    return polygons;
  }
  generateCirclesFor(locations) {
    let circles = [];
    if (locations) {
      locations.forEach(location => {
        if (location.geofenceEnabled && (location.geofenceRadiusInMeters != null || location.company.dispatchSettings.defaultGeoFence != null)
          && location.geofenceType !== DRAW_ON_MAP) {
          let companyDefaultRadius = location.company.distanceUnitsOfMeasure === "Miles" ?
            milesToMeters(location.company.dispatchSettings.defaultGeoFence) : location.company.dispatchSettings.defaultGeoFence * 1000;
          circles.push({
            radius: location.geofenceRadiusInMeters ? location.geofenceRadiusInMeters : companyDefaultRadius,
            center: {
              lat: parseFloat(location.latitude),
              lng: parseFloat(location.longitude)
            },
            strokeColor: location.geofenceColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: location.geofenceColor,
            fillOpacity: 0.25
          })
        }
        if (location.geofenceEnabled && location.geofenceType === DRAW_ON_MAP && location.shape.geometry.type === "Point") {
          circles.push({
            radius: location.shape.properties.radius,
            center: {
              lat: parseFloat(location.shape.geometry.coordinates[1]),
              lng: parseFloat(location.shape.geometry.coordinates[0])
            },
            strokeColor: location.geofenceColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: location.geofenceColor,
            fillOpacity: 0.25
          })
        }
      });
    }
    return circles;
  }
  generatePolygons() {
    if (this.state.locations == null || this.state.locations.size === 0) {
      return [];
    }
    let polygons = [];
    if (this.state.showLocationsWithAssets) {
      polygons = polygons.concat(this.generatePolygonsFor(this.state.locationsWithAssets));
    }
    if (this.state.showPurpleMarkers) {
      polygons = polygons.concat(this.generatePolygonsFor(this.state.domicileLocations));
    }
    if (this.state.showBlueMarkers) {
      polygons = polygons.concat(this.generatePolygonsFor(this.state.nonDomicileLocations));
    }
    return polygons;
  }
  generateCircles() {
    if (this.state.locations == null || this.state.locations.size === 0) {
      return [];
    }
    let circles = [];
    if (this.state.showLocationsWithAssets) {
      circles = circles.concat(this.generateCirclesFor(this.state.locationsWithAssets));
    }
    if (this.state.showPurpleMarkers) {
      circles = circles.concat(this.generateCirclesFor(this.state.domicileLocations));
    }
    if (this.state.showBlueMarkers) {
      circles = circles.concat(this.generateCirclesFor(this.state.nonDomicileLocations));
    }
    return circles;
  }
  getMapSize = () => {
    let w = window.innerWidth;
    let s = document.getElementById("app-sidebar");
    if (s === null) {
      this.setState({ mapSize: { height: "500px", width: "500px" } });
      return { height: "500px", width: "500px" }
    }
    w = w - s.offsetWidth - 60;
    let d = document.getElementById("eztrack-map-page");
    if (d === null) {
      this.setState({ mapSize: { height: "500px", width: "500px" } });
      return { height: "500px", width: "500px" }
    }
    let h = d.offsetHeight - 45;
    for (let i = 0; i < d.children.length - 1; i++) {
      h = h - d.children[i].offsetHeight;
    }
    this.setState({ mapSize: { height: h, width: w } });
    return { height: h, width: w };
  }
  handleClick = (event) => {
    if (event.target.id === 'legend-header' || event.target.parentElement.id === 'legend-header') {
      this.setState({ hideKpi: !this.state.hideKpi });
    }
    if (event.target.id === 'marker-legend-toggle' || event.target.parentElement.id === 'marker-legend') {
      this.setState({ hideMarkerLegend: !this.state.hideMarkerLegend });
    }
    if (event.target.id === 'red-marker-checkbox') {
      this.setState({
        showRedMarkers: !this.state.showRedMarkers,
        showAllCheckbox: !this.state.showRedMarkers && this.state.showYellowMarkers &&
          this.state.showGreenMarkers && this.state.showBlueMarkers && this.state.showPurpleMarkers &&
          this.state.showLocationsWithAssets,
      });
    }
    if (event.target.id === 'yellow-marker-checkbox') {
      this.setState({
        showYellowMarkers: !this.state.showYellowMarkers,
        showAllCheckbox: this.state.showRedMarkers && !this.state.showYellowMarkers &&
          this.state.showGreenMarkers && this.state.showBlueMarkers && this.state.showPurpleMarkers &&
          this.state.showLocationsWithAssets
      });
    }
    if (event.target.id === 'green-marker-checkbox') {
      this.setState({
        showGreenMarkers: !this.state.showGreenMarkers,
        showAllCheckbox: this.state.showRedMarkers && this.state.showYellowMarkers &&
          !this.state.showGreenMarkers && this.state.showBlueMarkers && this.state.showPurpleMarkers &&
          this.state.showLocationsWithAssets
      });
    }
    if (event.target.id === 'blue-marker-checkbox') {
      this.setState({
        showBlueMarkers: !this.state.showBlueMarkers,
        showAllCheckbox: this.state.showRedMarkers && this.state.showYellowMarkers &&
          this.state.showGreenMarkers && !this.state.showBlueMarkers && this.state.showPurpleMarkers &&
          this.state.showLocationsWithAssets
      });
    }
    if (event.target.id === 'purple-marker-checkbox') {
      this.setState({
        showPurpleMarkers: !this.state.showPurpleMarkers,
        showAllCheckbox: this.state.showRedMarkers && this.state.showYellowMarkers &&
          this.state.showGreenMarkers && this.state.showBlueMarkers && !this.state.showPurpleMarkers &&
          this.state.showLocationsWithAssets
      });
    }
    if (event.target.id === 'locations-with-assets-checkbox') {
      this.setState({
        showLocationsWithAssets: !this.state.showLocationsWithAssets,
        showAllCheckbox: this.state.showRedMarkers && this.state.showYellowMarkers &&
          this.state.showGreenMarkers && this.state.showBlueMarkers && !this.state.showPurpleMarkers &&
          this.state.showLocationsWithAssets
      });
    }
    if (event.target.id === 'show-all-checkbox') {
      if (!this.state.showAllCheckbox) {
        this.setState({
          showPurpleMarkers: true,
          showBlueMarkers: true,
          showRedMarkers: true,
          showYellowMarkers: true,
          showGreenMarkers: true,
          showLocationsWithAssets: true,
          showAllCheckbox: true,
        })
      } else {
        this.setState({
          showPurpleMarkers: false,
          showBlueMarkers: false,
          showRedMarkers: false,
          showYellowMarkers: false,
          showGreenMarkers: false,
          showLocationsWithAssets: false,
          showAllCheckbox: false,
        })
      }
    }
  }
  legendExpandContractIcon() {
    if (this.state.hideMarkerLegend) {
      return <i className={"fas fa-chevron-double-down"} />
    } else {
      return <i className={"fas fa-chevron-double-up"} />
    }
  }
  assetUtilizationIcon() {
    if (this.state.hideKpi) {
      return <i className={"fas fa-chevron-double-down"} />
    } else {
      return <i className={"fas fa-chevron-double-up"} />
    }
  }
  render() {
    let profilePage = document.getElementById("eztrack-asset-profile");
    let positioning = profilePage === null ? "absolute" : "relative";
    let topOfMap = profilePage === null ? "55px" : "0px";
    let bottomOfMap = profilePage === null ? "50px" : "0px";
    if (this.state.loading) {
      return <div>Loading...</div>
    }
    return (
      <div className="text-left p-3" id="eztrack-map-page"
        style={{
          top: topOfMap,
          bottom: bottomOfMap,
          position: positioning,
        }}
      >
        <h1>EZTrack Map</h1>
        <Row>
          {(this.state.usageHistory.get(3) || this.state.usageHistory.get(7) || this.state.usageHistory.get(30)
            || this.state.usageHistory.get(60) || this.state.usageHistory.get(90)
            || this.state.usageHistory.get('all')) &&
            <Card className='text-center' id='legend' style={{
              'marginLeft': '10px',
              'width': '180px'
            }} hidden={this.state.loading}>
              <CardHeader id='legend-header' style={{ cursor: 'pointer' }}>
                {this.assetUtilizationIcon()}
                <Label title='Asset Utilization is the average move frequency for the number of
                                 assets that were tracked in that period. Move frequency is defined by at least
                                 one move for more then 0.5 mile in a day, a day is 12:00a to 11:59p. To be
                                 included in the calculation the asset must be tracked for the full time
                                 period.' style={{ cursor: 'pointer' }}>Asset Utilization KPI</Label>
              </CardHeader>
              <CardBody className='text-center' hidden={this.state.hideKpi} style={{
                'paddingBottom': '0'
              }}>
                <div style={{ 'marginBottom': '1.0rem' }}>
                  <select value={this.state.assetTypeFilter ? this.state.assetTypeFilter : undefined}
                    onChange={event => {
                      this.setState({ assetTypeFilter: event.target.value });
                      this.calculateUsageStatistics(event.target.value);
                    }}
                  >
                    <option value="">All Equipment Types</option>
                    {this.state.equipmentTypes.map(opt => {
                      return <option key={opt.uuid} value={opt.name}>{opt.name}</option>
                    })}
                  </select>
                </div>
                {this.state.usageCount.get(3) > 0 &&
                  <Card style={{ 'marginBottom': '0.1rem' }}>
                    <CardBody>
                      <Label>3 day usage: <b>{this.state.usageHistory.get(3)}%</b></Label><br />
                      <Progress color={USAGE_COLOR} className="progress-xs"
                        value={this.state.usageHistory.get(3)}
                        aria-valuenow={this.state.usageHistory.get(3)} aria-valuemin="0"
                        aria-valuemax="100" />
                      {this.state.usageCount.get(3)} assets<br />
                    </CardBody>
                  </Card>
                }
                {this.state.usageCount.get(7) > 0 &&
                  <Card style={{ 'marginBottom': '0.1rem' }}>
                    <CardBody>
                      <Label>7 day usage: <b>{this.state.usageHistory.get(7)}%</b></Label><br />
                      <Progress color={USAGE_COLOR} className="progress-xs"
                        value={this.state.usageHistory.get(7)}
                        aria-valuenow={this.state.usageHistory.get(7)} aria-valuemin="0"
                        aria-valuemax="100" />
                      {this.state.usageCount.get(7)} assets<br />
                    </CardBody>
                  </Card>
                }
                {this.state.usageCount.get(30) > 0 &&
                  <Card style={{ 'marginBottom': '0.1rem' }}>
                    <CardBody>
                      <Label>30 day usage: <b>{this.state.usageHistory.get(30)}%</b></Label>
                      <Progress color={USAGE_COLOR} className="progress-xs"
                        value={this.state.usageHistory.get(30)}
                        aria-valuenow={this.state.usageHistory.get(30)} aria-valuemin="0"
                        aria-valuemax="100" />
                      {this.state.usageCount.get(30)} assets<br />
                    </CardBody>
                  </Card>
                }
                {this.state.usageCount.get(60) > 0 &&
                  <Card style={{ 'marginBottom': '0.1rem' }}>
                    <CardBody>
                      <Label>60 day usage: <b>{this.state.usageHistory.get(60)}%</b></Label>
                      <Progress color={USAGE_COLOR} className="progress-xs"
                        value={this.state.usageHistory.get(60)}
                        aria-valuenow={this.state.usageHistory.get(60)} aria-valuemin="0"
                        aria-valuemax="100" />
                      {this.state.usageCount.get(60)} assets
                    </CardBody>
                  </Card>
                }
                {this.state.usageCount.get(90) > 0 &&
                  <Card style={{ 'marginBottom': '0.1rem' }}>
                    <CardBody>
                      <Label>90 day usage: <b>{this.state.usageHistory.get(90)}%</b></Label>
                      <Progress color={USAGE_COLOR} className="progress-xs"
                        value={this.state.usageHistory.get(90)}
                        aria-valuenow={this.state.usageHistory.get(90)} aria-valuemin="0"
                        aria-valuemax="100" />
                      {this.state.usageCount.get(90)} assets
                    </CardBody>
                  </Card>
                }
                {this.state.usageCount.get('all') > 0 &&
                  <Card>
                    <CardBody>
                      <Label>Full History: <b>{this.state.usageHistory.get('all')}%</b></Label>
                      <Progress color={USAGE_COLOR} className="progress-xs"
                        value={this.state.usageHistory.get('all')}
                        aria-valuenow={this.state.usageHistory.get('all')} aria-valuemin="0"
                        aria-valuemax="100" />
                      {this.state.usageCount.get('all')} assets
                    </CardBody>
                  </Card>
                }
              </CardBody>
            </Card>
          }
          <Col xs='auto' id='marker-legend' className='text-right' hidden={this.state.loading}>
            {this.legendExpandContractIcon()}
            <i className={"far fa-info-circle fa-3x"} style={{ cursor: 'pointer', 'paddingBottom': '5px' }} id='marker-legend-toggle' />
            <Card hidden={this.state.hideMarkerLegend} style={{ 'marginBottom': '0', 'width': '270px' }}
              className='text-center'>
              <CardHeader style={{ 'fontSize': 'large' }}>
                Legend
              </CardHeader>
              <CardBody className='text-left' style={{ 'padding': '3px' }}>
                Last reported moves correspond to idle time which is the number of days between the current time and when the asset last moved.
                <br /><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showAllCheckbox} id='show-all-checkbox' />&nbsp;&nbsp;Show All</label><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showRedMarkers} id='red-marker-checkbox' />
                  <img src={GOOGLE_MAP_ICONS["red-dot"]} alt={"Red"} /> Last reported move more than 10 days ago.</label><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showYellowMarkers} id='yellow-marker-checkbox' />
                  <img src={GOOGLE_MAP_ICONS["yellow-dot"]} alt={"Yellow"} /> Last reported move 5 to 10 days ago.</label><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showGreenMarkers} id='green-marker-checkbox' />
                  <img src={GOOGLE_MAP_ICONS["green-dot"]} alt={"Green"} /> Last reported move less than 5 days ago.</label><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showLocationsWithAssets} id='locations-with-assets-checkbox' />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Locations with assets.</label><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showPurpleMarkers} id='purple-marker-checkbox' />
                  &nbsp;&nbsp;<img src={GOOGLE_MAP_ICONS["purple-D"]} alt={"Purple"} />&nbsp;&nbsp;&nbsp;Domicile locations.</label><br />
                <label style={{ cursor: 'pointer' }}><input readOnly={true} type='checkbox' checked={this.state.showBlueMarkers} id='blue-marker-checkbox' />
                  &nbsp;&nbsp;<img src={GOOGLE_MAP_ICONS["blue-L"]} alt={"Blue"} />&nbsp;&nbsp;&nbsp;EZTrack locations.</label><br />
              </CardBody>
            </Card>
          </Col>
          <Col xs='auto'>
            <MapView
              ref={this.mapView}
              legends={this.state.legends}
              onMarkerClick={(map, marker) => {
                this.props.history.replace({
                  pathname: "/eztrack-map",
                  state: {
                    lat: map.center.lat(),
                    lng: map.center.lng(),
                    zoom: map.getZoom(),
                  }
                });
                this.props.history.push({
                  pathname: "/asset-profile/" + marker.asset.uuid,
                  state: {
                    lat: map.center.lat(),
                    lng: map.center.lng(),
                    zoom: map.getZoom(),
                  }
                });
              }}
              lat={this.state.lat}
              lng={this.state.lng}
              zoom={this.state.zoom}
              getMapSize={this.getMapSize.bind(this)}
              mapHeight={this.state.mapSize.height}
              mapWidth={this.state.mapSize.width}
              markers={this.generateAssetMarkers()}
              locationMarkers={this.generateLocationMarkers()}
              boundsMarkers={this.generateAssetMarkers()}
              circles={this.generateCircles()}
              polygons={this.generatePolygons()}
              showAssetPopup={true}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(GoogleApiWrapper({
  apiKey: (Config.googleMapsApiKey),
  libraries: ['drawing'],
})(EZTrackMap));
