import React, { Component } from "react";
import { Tooltip } from "reactstrap";
import * as uuid from "uuid";
import { getDwell, getDwellForLimit } from "../../Dispatch/components/DwellAlertTooltip";

var moment = require('moment-timezone');

export function getFormattedDwellDateString(durationMillis) {
  if (!durationMillis) {
    return "00:00:00";
  }
  let d = moment.duration(durationMillis);
  let days = Math.floor(d.asDays());
  d = d.get("hours") + ":" + pad(d.get("minutes")) + ":" + pad(d.get("seconds"));
  if (days) {
    if (days > 1) d = days + " days, " + d;
    else d = "1 day, " + d;
  }
  return d;
}



function pad(s) {
  if (!s) return "00";
  if (s < 10) return "0" + s;
  return s;
}

class StaticDurationDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      id: "ID_" + uuid.v4()
    }
  }



  calcDuration() {
    return getFormattedDwellDateString(this.props.durationMillis);
  }



  warn(d, h) {
    if (this.props.warning) {
      return this.props.warning(d, h);
    } else {
      return (h >= 2);
    }
  }

  getColor(dwellAlert) {
    switch (dwellAlert) {
      case "Acceptable":
        return "green";
      case "Warning":
        return "yellow";
      case "Critical":
        return "red";
    }
  }

  render() {
    return <div>
      <span id={this.state.id}>
        {this.calcDuration()}
        {!(!this.props.dwellAlert) &&
          <i id={this.state.id}
            style={{
              marginLeft: "10px",
              color: this.getColor(this.props.dwellAlert)
            }}
            className={"fa fa-circle "}
            aria-hidden="true"
          />}
      </span>
      <Tooltip
        placement="right"
        isOpen={this.state.tooltipOpen}
        target={this.state.id}
        toggle={() => {
          if (!(!this.props.warningDwellInMinutes || !this.props.criticalDwellInMinutes)) {
            this.setState({ tooltipOpen: !this.state.tooltipOpen })
          }
        }}>
        Dwell: {getDwell(this.props.durationMillis / 1000)}<br />
        Warning Dwell Limit: {getDwellForLimit(this.props.warningDwellInMinutes, this.props.warningDwellUnit)}
        <br />
        Critical Dwell
        Limit: {getDwellForLimit(this.props.criticalDwellInMinutes, this.props.criticalDwellUnit)}
        <br />
      </Tooltip>
    </div>;
  }
}

export default StaticDurationDisplay;