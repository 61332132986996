import React, {Component} from "react";
import {Button} from "reactstrap"
import {downloadVisitsForTable, requestVisitsToExcel, saveNote} from "../../NetworkHelpers";
import 'react-table/react-table.css'
import '../../ReactTable.scss';
import MomentDisplay from "./MomentDisplay"
import StaticDurationDisplay from "./StaticDurationDisplay";
import Moment from "react-moment";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import {ROLE_ADMIN, ROLE_ATTENDANT, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import Select from 'react-select'
import {showError, showInfo} from "../../MainApp";
import NoteModal from "./NoteModal";
import {withRouter} from 'react-router';
import {getLocalStorageName, isRegularLeftClick} from "../../common/utilities";
import {assetTypeDefaults, assetTypeOptions} from "../../common/constants";
import Alerts from "../../common/Alerts";

class AssessorialView extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem('ezcheckin-assessorials'));
        if (localStorageState != null) {
            this.state = {
                assetTypes: localStorageState.assetTypes,
                previousSearchRequest: localStorageState.previousSearchRequest,
            }
        } else {
            this.state = {
                assetTypes: assetTypeDefaults,
                previousSearchRequest: {},
                events: null,
            }
        }
    }

    tableColumns = [
        {
            accessor: 'arrival.location.name',
            Header: 'Location',
            filterable: true,
            sortable: true,
            Cell: row => {return row.original.arrival.location?row.original.arrival.location.name:"Unknown"}
        },
        {
            accessor: 'assetId',
            Header: 'Asset',
            filterable: true,
            sortable: true,
            Cell: row => {return row.original.asset.assetId}
        },
        {
            accessor: 'asset.assetType',
            Header: 'Type',
            filterable: false,
            sortable: true,
            Cell: row => {return row.original.asset.assetType}
        },
        {
            accessor: 'arrival.carrier.name',
            Header: 'Carrier/Broker',
            filterable: true,
            sortable: true,
            Cell: row => {
                let carrier = row.original.arrival &&
                    row.original.arrival.carrier?row.original.arrival.carrier.name:"N/A";
                let broker = row.original.arrival && row.original.arrival.broker?row.original.arrival.broker.name:"N/A"
                return <span>{carrier}<br />{broker}</span>;
            }
        },
        {
            Header: 'Appointment',
            filterable: false,
            sortable: true,
            accessor: "appointment",
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {return row.original.arrival.appointment ?
                <MomentDisplay date={row.original.arrival.appointment}
                               timezone={row.original.arrival.location && row.original.arrival.location.timezone || (this.props.currentLocation && this.props.currentLocation.timezone)}/>:"NA"}
        },
        {
            Header: 'Alerts',
            filterable: false,
            sortable: false,
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {
                return row.original.asset && row.original.asset.assetType !== "Driver" ? <Alerts alert={row.original.asset.alertModel}/> : "";
            }
        },
        {
            Header: 'Arrival',
            filterable: false,
            sortable: true,
            accessor: 'date',
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {
                let v = row.original;
                return (v.created ?
                <MomentDisplay
                    date={v.arrival.date}
                    timezone={row.original.arrival.location && row.original.arrival.location.timezone || this.props.currentLocation && this.props.currentLocation.timezone}/>
                :"NA")
            }
        },
        {
            Header: 'Departure',
            accessor: 'departure',
            sortable: true,
            filterable: false,
            style:{ 'whiteSpace': 'unset'},
            Cell: row => {
                let v = row.original;
                return (v.created ?
                    <MomentDisplay
                        date={v.departure.date}
                        timezone={row.original.arrival.location && row.original.arrival.location.timezone || this.props.currentLocation && this.props.currentLocation.timezone}/>
                    :"NA")
            }
        },
        {
            Header: 'Receiving Ref #',
            accessor: 'arrival.shipmentNumber',
            sortable: true,
            filterable: true,
            Cell: row => {return row.original.arrival.shipmentNumber?
                <a href={"/shipment-profile/" + row.original.arrival.shipmentNumber} onClick={(e)=>{
                    if ( isRegularLeftClick(e) ) {
                        e.preventDefault();
                        this.props.displayShipmentProfile(row.original.arrival.shipmentNumber)
                    }
                }} >
                    {row.original.arrival.shipmentNumber}</a>:"NA"}
        },
        {
            Header: 'Shipping Ref #',
            accessor: 'departure.shipmentNumber',
            sortable: true,
            filterable: true,
            Cell: row => {
                return row.original.departure.shipmentNumber?
                <a href={"/shipment-profile/" + row.original.departure.shipmentNumber} onClick={(e)=>{
                    if ( isRegularLeftClick(e) ) {
                        e.preventDefault();
                        this.props.displayShipmentProfile(row.original.departure.shipmentNumber)
                    }
                }} >{row.original.departure.shipmentNumber}</a> : "NA"
            }
        },
        {
            Header: 'Events',
            accessor: 'events',
            filterable: false,
            sortable: false,
            Cell: row => {let v = row.original; return (<div>{this.formatEvents(v.arrival, v.asset.assetType)}</div>)}
        },
        {
            Header: 'Dwell Time',
            accessor: 'dwelltime',
            sortable: true,
            filterable: false,
            Cell: row => {return this.renderDwellTime(row.original)}
        },
        {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: row => {return <>
                <Button title="Add Note"
                        aria-label="Add Note"
                        onClick={() => this.openNoteModal(row.original.asset.uuid, row.original.asset.assetId, row.original.asset.notes)}>
                    <i className="fa fa-edit"/>
                </Button>
            </>
            }
        }
    ];

    formatEvents( arrivalGateEvent, assetType ) {
        let messages = arrivalGateEvent.textMessageEvents;
        if ( messages.length > 0 ) {
            try {
                return messages.filter(e => !e.assetTypes || e.assetTypes.indexOf(assetType)>=0).map((e, ix) =>
                    <div key={ix}>{e.messageDescription}: &nbsp;
                    <Moment format="MM/DD/YYYY h:mm a z"
                            tz={arrivalGateEvent.location && arrivalGateEvent.location.timezone || this.props.currentLocation && this.props.currentLocation.timezone}
                            date={e.updated}/>{this.props.latLongLink(e)}</div>);
            } catch ( e ) {
                console.log("ERROR", e);
            }
            return "ERROR";
        } else {
            return "No Events";
        }
    }

    renderDwellTime(event ) {

        return <div>
            <StaticDurationDisplay 
                durationMillis={event.visitLength}
            />
        </div>;
    }

    componentWillUnmount() {
        localStorage.setItem(getLocalStorageName("ezcheckin-assessorials", this.props.user), JSON.stringify({
            previousSearchRequest: this.state.previousSearchRequest,
            assetTypes: this.state.assetTypes,
        }))
    }

    exportTable() {
        if ( this.state.previousSearchRequest ) {
            requestVisitsToExcel( this.state.previousSearchRequest, ()=>{
                showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
            }, (e)=>{
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    openNoteModal(uuid, assetId, notes){
        this.setState({showingNoteModal: true, noteModalUuid: uuid, noteModalAssetId: assetId, notesForModal: notes});
    }

    closeNoteModal(){
        this.setState({showingNoteModal: false, noteModalUuid: false, notesForModal: {}});
    }

    renderFooter() {
        return <>
            {this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT])
                ?<><Button color="link" onClick={() => this.props.showArrivalScreen()}>
            <i className="fa fa-arrow-circle-left"/> Process Arriving
        </Button>
            <Button color="link" onClick={() => this.props.showDepartureScreen()}>
                <i className="fa fa-arrow-circle-right"/> Process Departing
            </Button></>:""}
            <Button color="secondary" onClick={() => this.exportTable()}><i
                className="fa fa-file-excel"/> Export Assessorial View to Excel</Button>
        </>
    }

    renderFilter() {
        return <div className="type-select">
            <Select
                placeholder="Choose Asset Types"
                onChange={(v) => {if (v.length===0) v = assetTypeDefaults;
                    this.setState({assetTypes:v})
                }}
                value={this.state.assetTypes}
                isMulti="true"
                classNamePrefix="select"
                options={assetTypeOptions}
                isClearable={true}/>
        </div>
    }

    render() {
        if ( !this.props.currentLocation && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN])) {
            this.props.history.push("/admin");
            return <div/>;
        }else if (!this.props.currentLocation ){
            return <div className="no-location-error">Please choose a location.</div>
        }

        return (<div className="">
            <NoteModal
                open={this.state.showingNoteModal}
                uuid={this.state.noteModalUuid}
                assetId={this.state.noteModalAssetId}
                notes={this.state.notesForModal}
                error={this.state.noteModalError}
                onSave={(note, setErrors)=>{
                    saveNote(this.state.noteModalUuid, {note: note}, (data)=>{
                        this.tableRef.current.fetchData();
                        this.closeNoteModal();
                    }, (error) =>{
                       this.setState({noteModalError: error.message})
                    });
                }}
                onCancel={()=>{
                    this.closeNoteModal();
                }}
            />
            <div className="">
                <PaginationFilterTable
                    user={this.props.user}
                    openFiltersCollaps = {true}
                    get={downloadVisitsForTable}
                    stateStorage="ezcheckin-assessorials"
                    ref={this.tableRef}
                    columns={this.tableColumns}
                    footerButton={this.renderFooter()}
                    additionalFilter={this.renderFilter()}
                    additionalSearchCriteria={{assetTypes: this.state.assetTypes.map((t)=>t.value)}}
                    onChange={(state) => {
                        this.setState({itemsPerPage: state.itemsPerPage,
                            previousSearchRequest: {page: state.page,
                                pageSize: state.pageSize,
                                sorted: state.sorted,
                                filtered: state.filtered
                            }
                        });
                    }}>
                    {this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT])
                        ?<Button color="link" onClick={()=>this.props.showArrivalScreen()}>
                        <i className="fa fa-arrow-circle-left"/> Process Arriving </Button>:""}
                    {this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT])
                        ?<Button color="link" onClick={()=>this.props.showDepartureScreen()}>
                        <i className="fa fa-arrow-circle-right"/> Process Departing </Button>:""}
                </PaginationFilterTable>
            </div>
        </div>);
    }



}


export default withRouter(AssessorialView);