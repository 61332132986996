import React, {Component} from 'react';
import {getErrorsFromValidationError} from "../../common/utilities";
import * as Yup from "yup";
import {Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class SpotTypeEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            SpotTypeUnderEdit: {
                name: "",
                def: false,
                enabled: true,
            },
            errors: {},
            touched: {}
        }
    }
    updateSpinner(val) {
        this.spinner = (
          <span>
            <p className="loadingMessage">{val}</p>
            <LoaderIco
              loaded={false}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#51b32e"
              speed={1}
              trail={60} b      
              shadow={false}
              hwaccel={false}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
              loadedClassName="loadedContent"
            />
          </span>
        );
      }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.SpotTypeUnderEdit !== prevProps.SpotTypeUnderEdit ) {
            this.setState(
                {   SpotTypeUnderEdit: this.props.SpotTypeUnderEdit,
                    modalIsOpen: this.props.showModal
                });
        }
    }
    cancelEdit() {
        this.setState( {
            modalIsOpen: false,
            errors: {},
            touched: {}
        });
        this.props.cancel();
    }
    onSubmit() {
        this.updateSpinner("Saving...");
        this.props.save && this.props.save(this.state.SpotTypeUnderEdit, () => {
            this.setState({modalIsOpen: false, touched: {}});
        }, () => {
            this.setState({modalIsOpen: true});
        });
    }
    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        };
    }
    validationSchema(values) {
        let validationRules = {
            name: Yup.string()
                .required('Name is required.')
        }
        return Yup.object().shape(validationRules)
    }
    render() {
        return(
            <Modal isOpen={this.state.modalIsOpen} size={'lg'}>
                <Loader show={this.props.loaded} message={this.spinner}>
                <ModalHeader>
                    {this.state.SpotTypeUnderEdit.isNew ? "New Spot Type" : "Edit Spot Type"}
                </ModalHeader>             
                <ModalBody>
                    <Form onSubmit={(e) => {
                        let errors = this.validate(this.validationSchema.bind(this))(this.state.SpotTypeUnderEdit);
                        let errorsIsEmpty = Object.keys(errors).length === 0 && errors.constructor === Object;
                        if (!errorsIsEmpty || this.state.errors.name) {
                            let t = {...this.state.touched};
                            t.name = true;
                            this.setState({touched: t, errors: errors});
                            e.preventDefault();
                            return;
                        }
                        this.state.SpotTypeUnderEdit.name.trimEnd();

                        this.onSubmit();
                        e.preventDefault();
                    }}
                    >
                        <Card className="simple-card">
                            <CardHeader>Spot Type</CardHeader>
                            <CardBody>
                                <div>
                                    <FFFormTextField
                                        fieldname="Name"
                                        label="Name"
                                        placeholder="Enter a name for the Spot Type"
                                        icon="fa-tag"
                                        value={this.state.SpotTypeUnderEdit.name}
                                        maxLength={40}
                                        required={true}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.SpotTypeUnderEdit);
                                            let touched = {...this.state.touched};
                                            touched.name = true;
                                            this.setState({errors: errors, touched: touched})
                                         }}
                                        error={this.state.errors.name}
                                        touched={this.state.touched.name}
                                        onChange={(v) => {
                                            

                                            let trimmedValue = v;
                                            if (v.startsWith(' ') ) 
                                            {
                                                trimmedValue=v.trimStart();


                                            }
                                          
        
    
                                            let s = {
                                              ...this.state.SpotTypeUnderEdit,
                                            };
                                            s.name = trimmedValue;
                                            this.setState({
                                              SpotTypeUnderEdit: s,
                                            });
                                        }}
                                    >
                                    </FFFormTextField>
                                    <FFFormCheckboxField
                                        fieldname="def"
                                        icon="fa-power-off"
                                        label="Default"
                                        value={this.state.SpotTypeUnderEdit.def}
                                        onChange={(v) => {
                                            let s = {...this.state.SpotTypeUnderEdit};
                                            s.def = v;
                                            this.setState({SpotTypeUnderEdit: s});
                                        }}
                                    />
                                    <FFFormCheckboxField
                                        fieldname="enabled"
                                        icon="fa-power-off"
                                        label="Enabled"
                                        value={this.state.SpotTypeUnderEdit.enabled}
                                        onChange={(v) => {
                                            let s = {...this.state.SpotTypeUnderEdit};
                                            s.enabled = v;
                                            this.setState({SpotTypeUnderEdit: s});
                                        }}
                                    >
                                    </FFFormCheckboxField>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mb-3 text-right">
                            <Button color="danger" onClick={() => this.cancelEdit()}>Cancel</Button> &nbsp;
                            <Button color="primary" type="submit">Save</Button>
                        </div>
                    </Form>
                </ModalBody>
                </Loader>
            </Modal>
        )
    }
}
export default SpotTypeEdit;