import React, { Component } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import MomentDisplay from "../assets/components/MomentDisplay";
class FlagModal extends Component {
	constructor(props) {
		super(props);
	}

	renderForm() {
		return (
			<div className="row">
				<div className="col-md-12">
					<div className="card mb-0">
						<div className="card-body">
							<ul class="list-group list-group-flush">
								<li class="list-group-item">
									Responding System:{" "}
									{
										this.props.flagData.system === null ?
											"N/A" : this.props.flagData.system
									}
								</li>
								<li class="list-group-item">
									Reason Code:{" "}
									{
										this.props.flagData.reasonCode === null ?
											"N/A" : this.props.flagData.reasonCode
									}
								</li>
								<li class="list-group-item">
									Description:{" "}
									{
										this.props.flagData.description === null ?
											"N/A" : this.props.flagData.description
									}
								</li>
								<li class="list-group-item">
									Date time:{" "}
									{this.props.flagData.responseDate === null
										? "N/A" :
										<MomentDisplay
											date={this.props.flagData.responseDate}
											timezone={
												this.props.currentLocation &&
												this.props.currentLocation.timezone
											}
										/>
										// getFormattedDwellDateString(this.props.flagData.refData.prAppointmentWindow)
									}
									{/* : this.props.flagData.refData.prAppointmentWindow} */}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
	render() {
		return (
			<Modal isOpen={this.props.isRefModalOpen} size={"md"}>
				<ModalHeader>
					Response details of task
					 {/* ({this.props.flagData.taskName}) */}
				</ModalHeader>
				<ModalBody style={{ overflow: "auto" }}>{this.renderForm()}</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => this.props.cancel()}>
						Close
					</Button>{" "}
					&nbsp;
				</ModalFooter>
			</Modal>
		);
	}
}
export default FlagModal;
