import React, {Component} from 'react';
import {getErrorsFromValidationError} from "../../common/utilities";
import * as Yup from "yup";
import {Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import FFFormAsyncSelectField from '../../common/formik-free/FFFormAsyncSelectField';
import FFFormSelectField from '../../common/formik-free/FFFormSelectField';
import { promiseUserLocationSearch } from '../../NetworkHelpers';
import { flattenDiagnosticMessageText } from 'typescript';
import { ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_MANAGER, ROLE_POWERYARD_REQUESTER } from '../../Roles';

export const roleOptions = [
  { value: ROLE_POWERYARD_ADMIN    ,label: 'PowerYard Admin' },
  { value: ROLE_POWERYARD_MANAGER,   label: 'PowerYard Manager' },
  { value: ROLE_POWERYARD_REQUESTER, label: 'PowerYard Requester' },
  { value: ROLE_POWERYARD_JOCKEY,    label: 'PowerYard Jockey' }
];
class RoleEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            RoleUnderEdit: {
                roles: [],
                location: this.props.currentLocation,
                allowed: false,
            },
            errors: {},
            touched: {}
        }
    }
    updateSpinner(val) {
        this.spinner = (
          <span>
            <p className="loadingMessage">{val}</p>
            <LoaderIco
              loaded={false}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#51b32e"
              speed={1}
              trail={60} b      
              shadow={false}
              hwaccel={false}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
              loadedClassName="loadedContent"
            />
          </span>
        );
      }
    componentDidUpdate(prevProps, prevState, snapshot) {
      console.log("dataaaaaa", this.state.RoleUnderEdit);
      if ( this.props.RoleUnderEdit !== prevProps.RoleUnderEdit ) {
        this.setState({                        
          modalIsOpen: this.props.showModal,
          RoleUnderEdit: {...this.props.RoleUnderEdit, roles: this.props.RoleUnderEdit.roles?
            this.props.RoleUnderEdit.roles.map((role) => roleOptions.find((option) => option.value === role)) : []},
        });
      }
    }
    cancelEdit() {
        this.setState( {
            modalIsOpen: false,
            errors: {},
            touched: {}
        });
        this.props.cancel();
    }
    onSubmit() {
        this.updateSpinner("Saving...");
        this.props.save && this.props.save({
          locationUuid: this.state.RoleUnderEdit.location.uuid,
          roles: this.state.RoleUnderEdit.roles.map(r => r.value),
          allowed: this.state.RoleUnderEdit.allowed,
          ...(this.state.RoleUnderEdit.uuid && {uuid: this.state.RoleUnderEdit.uuid})
        }, () => {
            this.setState({modalIsOpen: false, touched: {}});
        }, () => {
            this.setState({modalIsOpen: true});
        });
    }
    validate(getValidationSchema) {
      return (values) => {
          const validationSchema = getValidationSchema(values);
          try {
              validationSchema.validateSync(values, {abortEarly: false});
              return {};
          } catch (error) {
              console.error("Validation error:", error); 
              return getErrorsFromValidationError(error);
          }
      };
  }
    validationSchema(values) {
      let validationRules = {
        location: Yup.object().required("Location is required."),
        roles: Yup.array().required('Role is required.')
      }
      return Yup.object().shape(validationRules)
    }

    render() {
        return (
          <Modal isOpen={this.state.modalIsOpen} size={"lg"}>
            <Loader show={this.props.loaded} message={this.spinner}>
              <ModalHeader>
                {this.state.RoleUnderEdit.isNew
                  ? "New Task Priority Configuration"
                  : "Edit Task Priority Configuration"}
              </ModalHeader>
              <ModalBody>
                <Form
                  onSubmit={(e) => {
                    let errors = this.validate(
                      this.validationSchema.bind(this)
                    )(this.state.RoleUnderEdit);

                    let errorsIsEmpty =
                      Object.keys(errors).length === 0 &&
                      errors.constructor === Object;
                    if (!errorsIsEmpty || this.state.errors.location || this.state.errors.roles) {
                      let t = { ...this.state.touched };
                      t.location = true;
                      t.roles = true;
                      this.setState({ touched: t, errors: errors });

                      e.preventDefault();
                      return;
                    }

                    this.onSubmit();
                    e.preventDefault();
                  }}
                >
                  <Card className="simple-card">
                    <CardHeader>Task Priority Configuration</CardHeader>
                    <CardBody>
                      <div>
                        <FFFormAsyncSelectField
                          defaultOptions
                          fieldname="location"
                          label="Location"
                          placeholder="Select a Location"
                          icon="fa-building"
                          value={
                            this.state.RoleUnderEdit.location
                              ? this.state.RoleUnderEdit.location.name
                                ? this.state.RoleUnderEdit.location.name
                                : this.state.RoleUnderEdit.location.name
                              : ""
                          }
                          loadOptions={promiseUserLocationSearch}
                          checkered={true}
                          hideEmpty={true}
                          required={true}
                          onBlur={() => {
                            let errors = this.validate(this.validationSchema.bind(this))(this.state.RoleUnderEdit);
                            let touched = {...this.state.touched};
                            touched.name = true;
                            this.setState({errors: errors, touched: touched})
                        }}
                          error={this.state.errors.location}
                          touched={this.state.touched.location}
                          onChange={(v) => {
                            let s = { ...this.state.RoleUnderEdit };
                            s.location = v;
                            s.zone = "";
                            this.setState({ RoleUnderEdit: s });
                          }}
                        />
                        <FFFormSelectField
                          fieldname="role"
                          icon="fa-user"
                          label="Role"
                          isMulti={true}
                          placeholder="Select Role"
                          value={this.state.RoleUnderEdit.roles} 
                          options={roleOptions}
                          required={true}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.RoleUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.roles = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.roles}
                          touched={this.state.touched.roles}
                          onChange={(v) => {
                            let s = { ...this.state.RoleUnderEdit };
                            s.roles = v; 
                            this.setState({ RoleUnderEdit: s });
                          }}
                        />

                      
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mb-3 text-right">
                    <Button color="danger" onClick={() => this.cancelEdit()}>
                      Cancel
                    </Button>{" "}
                    &nbsp;
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Loader>
          </Modal>
        );
    }
}
export default RoleEdit;