import React, {Component} from "react";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {ArrivalAlertClock} from "./ArrivalAlertClock";
import {RangeAlertCircle} from "./RangeAlertCircle";
import {formatLatLong} from "../../common/utilities";

export class EventLocation extends Component {
    getLocation() {
        let location = this.props.geocodeLocation.locality + ", " + this.props.geocodeLocation.state;
        if (this.props.geocodeLocation.country !== "US") {
            location += (", " + this.props.geocodeLocation.country);
        }
        return location;
    }

    render(){
        const date = this.props.showDate ? <span> at <MomentDisplay timezone={this.props.timezone} date={this.props.date}/></span> : "";

        if (this.props.geocodeLocation ) {
            if (this.props.geocodeLocation.locality && this.props.geocodeLocation.state && this.props.geocodeLocation.country ) {
                let location = this.getLocation();
                return <div>
                    <p>
                    <span title={this.props.geocodeLocation.fullLocation || "N/A"} className="pointer-cursor">
                        {location} {date}
                    </span>
                    </p>
                    <p>
                        <LatitudeLongitude latitude={this.props.latitude} longitude={this.props.longitude} />
                    </p>
                </div>
            }
        }

        return <>
        <span>
            <LatitudeLongitude latitude={this.props.latitude} longitude={this.props.longitude}/>
        </span>
        </>;
    }
}

class LatitudeLongitude extends Component {
    render(){
        return <div>{formatLatLong(this.props.latitude) + ", " + formatLatLong(this.props.longitude)} {this.props.children}</div>;
    }
}
