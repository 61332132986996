import {Component, default as React} from "react";
import {Input, InputGroup} from "reactstrap";
import moment from "moment-timezone";

export class FFDateSegmentPicker extends Component {
    render(){
        return <>
            <Input type="text" placeholder={this.props.placeholder}
                   className="text-short"
                   name={this.props.fieldname + "_" + this.props.placeholder}
                   onChange={(v) => {
                       this.props.onChange && this.props.onChange(v.target.value);
                   }}
                   onBlur={this.props.onBlur}
                   value={this.props.value}
                   bsSize="2char"
                   maxLength="2"
                   disabled={this.props.disabled}
                   onFocus={(e) => e.target.select()}
            />
        </>
    }
}