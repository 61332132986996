import React, {Component} from "react";
import AsyncSelect from 'react-select/lib/Async';
import * as PropTypes from "prop-types";
import {promiseAssetStatusesByPref} from "../../NetworkHelpers";

export class FFAssetStatusSelect extends Component {
    constructor(props) {
        
        super(props);
        this.state = {
        }
    }
  
    render() {
        let obj = {};
        return <AsyncSelect
            name={this.props.name}
            placeholder={this.props.placeholder}
            valid={!this.props.error}
            isMulti={!this.props.single}
            invalid={
                this.props.touched
                && !!this.props.error
            }
            defaultOptions
            minimumInput={0}
            onChange={(v)=>this.props.onAssetStatusChange(v)}
        
            onBlur={this.props.onBlur}
            value={this.props.value ? this.props.value.map((v)=> {return {value:v.uuid, label:v.name}}) : null}
            // value={this.props.value ? this.props.value.filter((v)=>v.enabled=="true") : null}
            className={"basic-single short-select select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
            // classNamePrefix="select"
            loadOptions={promiseAssetStatusesByPref}
            isClearable={true}
        />;
    }
}

FFAssetStatusSelect.propTypes = {
    name: PropTypes.any,
    placeholder: PropTypes.any,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    fieldname: PropTypes.any
};