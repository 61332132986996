import React, { Component } from "react";
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Tooltip,
} from "reactstrap";
import * as uuid from "uuid";

class FFFormTextField extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      id: "ID_" + uuid.v4(),
      passwordType: null
    };
  }

  icon() {
    if (this.props.icon) {
      return <i className={"fa " + this.props.icon}></i>;
    } else if (this.props.iconText) {
      return this.props.iconText;
    }
    return " ";
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  clearField() {

    const input = document.getElementsByName(this.props.fieldname);

    input[0].value = "";
    this.props.onChange(null);

  }

  render() {
    console.log(this.props.isonsite, `this onsite`, this.props.isClearable, this.props.icon)
    return (
      <FormGroup>
        <Row>
          <Col lg="2" md="3" sm="4">
            <Label className={this.props.lowerLabel ? "mb-0" : ""}>
              {this.props.label}&nbsp;
              <span className={'text-danger'}>{this.props.label && this.props.required ? " *" : ""}</span>
            </Label>
            <span className={"font-12"}> {this.props.lowerLabel ? this.props.lowerLabel : ""}</span>
            {this.props.helpMessage && (
              <div>
                <i
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "5px",
                    color: "grey",
                  }}
                  className={"fa fa-question-circle"}
                  id={this.state.id}
                />
                <Tooltip
                  placement="right"
                  isOpen={this.state.tooltipOpen}
                  target={this.state.id}
                  toggle={this.toggle}
                >
                  {this.props.helpMessage}
                </Tooltip>
              </div>
            )}
          </Col>
          <Col lg="10" md="9" sm="8">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  {this.icon()}
                  {this.props.prepend}
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type={
                  this.props.password
                    ? this.state.passwordType === null ? 'password' : this.state.passwordType
                    : this.props.numbersOnly
                      ? "number"
                      : this.props.colorInput ? "color"
                      : "text"
                }
                min={this.props.numbersOnly ? this.props.min : ""}
                max={this.props.numbersOnly ? this.props.max : ""}
                maxLength={this.props.maxLength}
                placeholder={this.props.placeholder}
                step="any"
                defaultValue={this.props.defaultValue}
                name={this.props.fieldname}
                valid={this.props.touched && !this.props.error}
                invalid={this.props.touched && !!this.props.error}
                onKeyDown={this.props.onKeyDown}
                onChange={(v) => {
                  this.props.onChange && this.props.onChange(v.target.value);
                }}
                onBlur={this.props.onBlur}
                value={this.props.value}
                disabled={this.props.disabled}
                readOnly = {this.props.readOnly}
              />
              {this.props.isClearable && (
                <div
                  className={"clearable-input"}
                  onClick={() => this.clearField()}
                >
                  <i className={"far fa-times"}></i>
                </div>
              )}
              <FormFeedback>{this.props.error}</FormFeedback>
              {
                this.props.isPasswordFiled &&
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={(this.state.passwordType === null || this.state.passwordType === 'password') ? 'fa fa-eye c-pointer' : 'fa fa-eye-slash c-pointer'} onClick={() => {
                      let type = (this.state.passwordType === null || this.state.passwordType === 'password') ? 'text' : 'password'
                      this.setState({
                        passwordType: type,
                      })
                    }} />
                  </InputGroupText>
                </InputGroupAddon>
              }
              { this.props.isonsite &&
                <InputGroupAddon addonType="prepend">
                <InputGroupText style={{backgroundColor: '#e4e7ea', opacity: 1, paddingLeft: '10px' }}>
                  {/* <i className="fa-solid fa-circle-exclamation"></i> */}
                  <i id={this.state.id} style={{position: "absolute", right: "10px"}} className={"fa fa-exclamation-circle"}></i>
                  <Tooltip
                  className=""
                  placement="top"
                  isOpen={this.state.tooltipOpen}
                  target={this.state.id}
                  toggle={this.toggle}
                >
           
                <span>{"“Move Me” acts on the Trailer Name and Number.  If you need to correct either value, please close this form (Cancel Changes below) and edit the record.  Then, re-click the Move Me button."}</span>
        
                </Tooltip>
              </InputGroupText>
                </InputGroupAddon>
              }
            </InputGroup>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

export default FFFormTextField;
