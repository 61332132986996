import React, {Component} from "react";
import ReferenceNumberList from "./ReferenceNumberList";
import {FFTractorCard} from "../../Arrival/components/FFTractorCard";
import {Card, CardBody, CardHeader} from "reactstrap";
import {DispatchStopInformation} from "./DispatchStopInformation";
import {DispatchDriverCard} from "./DispatchDriverCard";
import {FFBobTailArrival} from "../../Arrival/components/FFBobTailArrival";
import {DispatchStopButtons} from "./DispatchStopButtons";


export class DispatchStopCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSections: {},
            errors: {
                departingDriver: {},
                tractor: {},
                trailer: {}
            },
            touched: {
                driver: {},
                tractor: {},
                trailer: {}
            },
            isEditing: props.isEditing ? props.isEditing : false,
        }
    }


    toggleSection(section ) {
        let sections = Object.assign({},this.state.openSections); // copy sections
        sections[section] = !sections[section];
        this.setState({openSections: sections});
    }

    // componentDidMount() {
    //     if (this.props.values && this.props.values[this.props.index] && this.props.values[this.props.index].location) {
    //         downloadLocationByUuid(this.props.values[this.props.index].location.value, (data) => {
    //             console.log("by uuid", data);
    //             this.setState({location: data})
    //         })
    //     }
    // }

    validate(values, success) {
        if(values.differentDepartingDriver && values.departingDriver){
            this.setDriverErrors(values.departingDriver);
        }

        switch (values.typeOfStop) {
            case "Pickup":
                success();
                break;
            case "Delivery":
                break;
            case "Delivery & Pickup":
                break;
            case "Transfer":
                if(!values.typeOfTransfer|| values.typeOfTransfer.length === 0){
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            typeOfTransfer: "Needs a type of transfer."
                        },
                        touched: {
                            typeOfTransfer: true
                        }
                    });
                    return;
                }
        }
        if(this.state.differentDepartingTractor && !values.departingTractor.number){
            this.setTractorErrors();
            return;
        }
        if(values.differentDepartingTrailer && !values.departingTrailer.trailerNumber){
            this.setTrailerErrors();
            return;
        }
        success();
    }

    setTractorErrors() {
        this.setState({
            errors: {
                tractor: {
                    number: "Needs a tractor number"
                }
            },
            touched: {
                tractor: {
                    number: true
                }
            }
        });
    }

    setTrailerErrors() {
        this.setState({
            errors: {

                trailer: {
                    trailerNumber: "Needs a trailer number"
                }
            },
            touched: {
                trailer: {
                    trailerNumber: true
                }
            }
        });
    }

    setDriverErrors(values) {
        let newErrors = {
            errors: {
                departingDriver: {
                    cell: values.assetId ? null : "Needs a number",
                    firstname: values.firstname ? null : "Needs first name",
                    lastname: values.lastname ? null : "Needs last name"
                }
            },
        };
        this.setState(newErrors);
    }

    sectionOpen(section) {
        return this.state.openSections[section];
    }

    classForSectionButton(section) {
        if (this.state.openSections[section]) {
            return "fa fa-minus";
        } else {
            return "fa fa-plus"
        }
    }

    cardHeaderContentText(props) {
        let stop = this.props.value;
        if(!stop) stop = {};

        if (stop && stop.typeOfStop) {
            return <div className="demo-card-header-title">Stop {this.props.index + 1} - {stop.typeOfStop}</div>
        } else {
            return <div className="demo-card-header-title">New Stop</div>
        }
    }

    cardHeaderContentText(props) {
            if (props.typeOfStop) {
            return <div className="demo-card-header-title">Stop {this.props.index + 1} - { props.typeOfStop}</div>
        } else {
            return <div className="demo-card-header-title">New Stop</div>
        }
    }

    getFormikProperty(arrayName, fieldname, index){
            return  arrayName + "[" + index + "]."+fieldname;
    }

    render() {
        let stop = this.props.value;
        //Needs default TZ
        if (!stop) stop = {appointment: {}};

        let pickupReferenceNumberList = <ReferenceNumberList
            title="Stop Pickup Reference Numbers"
            fieldName="pickupReferenceNumbers"
            referenceNumbers={this.props.value.pickupReferenceNumbers || []}
            defaultReferenceType={this.props.defaultPickupReferenceType}
            referenceNumberTypeOptions={this.props.referenceNumberTypeOptions}
            references={this.props.dispatchReferenceNumbers ? this.props.dispatchReferenceNumbers : []}
            onChange={(shipmentReference) =>{
                if(shipmentReference && shipmentReference.value){
                    this.pushAndUpdatePickupReferences(shipmentReference, this.props.company.shipmentIdDisplay);
                }
            }}
            onDelete={(referenceNumber)=>{
                for (let i = 0; i < this.props.value.pickupReferenceNumbers.length; i++) {
                    if(this.props.value.pickupReferenceNumbers[i].type === referenceNumber.type && this.props.value.pickupReferenceNumbers[i].value === referenceNumber.value){
                        this.props.value.pickupReferenceNumbers.splice(i,1);
                    }
                }
                this.setState({
                    errors: {
                        shipmentIdPickup: null
                    }
                });
            }}
            disabled={this.props.disabled}
            useLookup={true}
            touched={this.props.touched}
            onBlur={this.props.onBlur}
            errors={this.props.errors}
            error={this.state.errors.shipmentIdPickup}
            values={this.props.value}
            hideDefault
        />;
        let deliverReferenceNumberList = <ReferenceNumberList
            title="Stop Deliver Reference Numbers"
            fieldName="deliverReferenceNumbers"
            referenceNumbers={this.props.value.deliverReferenceNumbers || []}
            defaultReferenceType={this.props.defaultDeliverReferenceType}
            referenceNumberTypeOptions={this.props.referenceNumberTypeOptions}
            references={this.props.dispatchReferenceNumbers}
            onChange={(shipmentReference) =>{
                if(shipmentReference && shipmentReference.value){
                    this.pushAndUpdateDeliverReferences(shipmentReference, this.props.company.shipmentIdDisplay);
                }
            }}
            onDelete={(referenceNumber)=>{
                for (let i = 0; i < this.props.value.deliverReferenceNumbers.length; i++) {
                    if(this.props.value.deliverReferenceNumbers[i].type === referenceNumber.type && this.props.value.deliverReferenceNumbers[i].value === referenceNumber.value){
                        this.props.value.deliverReferenceNumbers.splice(i,1);
                    }
                }

                // this.props.setFieldValue(this.getFormikProperty("stops", "deliverReferenceNumbers", this.props.index), this.props.value.deliverReferenceNumbers);
                // this.props.setFieldTouched(this.getFormikProperty("stops", "deliverReferenceNumbers", this.props.index), true);

                this.setState({
                    errors: {
                        shipmentIdDeliver: null
                    }
                });
            }}
            disabled={this.props.disabled}
            useLookup={true}
            touched={this.props.touched}
            onBlur={this.props.onBlur}
            errors={this.props.errors}
            error={this.state.errors.shipmentIdDeliver}
            values={this.props.value}
            hideDefault
        />;
        return <>
            <Card className="demo-card">
                <CardHeader>
                    <h6 className="demo-card-header">
                        {this.cardHeaderContentText(this.props.value)}
                        {/*{this.pickupReferenceNumberList(this.props.values[this.props.index])}*/}
                        {/*{this.deliveryReferenceNumberList(this.props.values[this.props.index])}*/}
                    </h6>
                </CardHeader>
                <div className={"dispatch-stop-editor"}>
                    <div className="stopToolbar"/>
                    <Card style={{margin: "20px"}}>
                        <CardBody>
                            <DispatchStopInformation errors={this.state.errors}
                                                     touched={this.state.touched}
                                                     value={this.props.value}
                                                     index={this.props.index}
                                                     onBlur={this.props.onBlur}
                                                     onChange={(field, value, callback) => {
                                                         let touched = {...this.state.touched};
                                                         touched[field] = true;
                                                         this.setState({touched});
                                                         let stop = {...this.props.value};
                                                         stop[field] = value;
                                                         this.props.onChange(stop, callback);
                                                     }}
                                                     stop={stop}
                                                     location={this.props.location}
                                                     appointment={stop.appointment}
                                                     disabled={this.props.disabled}
                                                     allowLocationsByName={this.props.company.allowLocationsByName}
                            />

                            {this.isPickup() && this.getReferenceNumberTypeOptions().length > 0 && pickupReferenceNumberList}
                            {this.isDeliver() && this.getReferenceNumberTypeOptions().length > 0 && deliverReferenceNumberList}

                            {this.props.value.typeOfStop === "Transfer" &&
                            <div>
                                <FFTractorCard
                                    onClick={(e) => this.toggleSection("tractor")}
                                    className={this.classForSectionButton("tractor")}
                                    sectionOpen={this.sectionOpen("tractor")}
                                    errors={this.state.errors.tractor}
                                    onBlur={this.props.onBlur}
                                    handleChange={this.props.handleChange}
                                    touched={this.state.touched.tractor || {}}
                                    values={this.props.value.departingTractor ? this.props.value.departingTractor : {}}
                                    fieldTouched={this.props.setFieldTouched}
                                    fieldValue={this.props.setFieldValue}
                                    optionLabel={this.props.tractorOptionLabel}
                                    onChange={(tractor) => {
                                        let stop = {...this.props.value};
                                        stop.departingTractor = tractor;
                                        this.props.onChange(stop);
                                    }}
                                    disabled={this.props.disabled}
                                    isDisabled={this.props.disabled}
                                />
                                <FFBobTailArrival
                                    onClick={(e) => this.toggleSection("trailer")}
                                    className={this.classForSectionButton("trailer")}
                                    driverSectionOpen={this.sectionOpen("trailer")}
                                    errors={this.state.errors.trailer}
                                    handleBlur={this.props.handleBlur}
                                    onBlur={this.props.onBlur}
                                    handleChange={this.props.handleChange}
                                    touched={this.state.touched.trailer}
                                    values={this.props.value.departingTrailer ? this.props.value.departingTrailer : {}}
                                    fieldTouched={this.props.setFieldTouched}
                                    fieldValue={this.props.setFieldValue}
                                    optionLabel={this.props.tractorOptionLabel}
                                    onChange={(trailer) => {
                                        let stop = {...this.props.value};
                                        stop.departingTrailer = trailer;
                                        this.props.onChange(stop);
                                    }}
                                    isDisabled={this.props.disabled}
                                    disabled={this.props.disabled}
                                />
                                <DispatchDriverCard
                                    errors={this.state.errors.departingDriver || {}}
                                    advancedView={this.state.advanced}
                                    driver={this.props.value.departingDriver && this.props.value.departingDriver}
                                    touched={this.props.value.departingDriver && this.props.value.departingDriver.touched}
                                    onBlur={this.props.onBlur}
                                    onChange={(driver) => {
                                        if (driver) {
                                            let stop = {...this.props.value};
                                            stop.departingDriver = driver;
                                            this.props.onChange(stop);
                                            this.validate(this.props.value, () => {
                                            });
                                        }
                                    }}
                                    onTouch={() => {
                                        this.props.value.departingDriver.touched = true;
                                    }}
                                    isDisabled={this.props.disabled}
                                    disabled={this.props.disabled}
                                    dispatchUuid={this.props.dispatchUuid}
                                />
                            </div>}
                            <br/>
                            <DispatchStopButtons
                                showUpArrow={this.props.index > 0}
                                onUp={() => {this.props.onUp && this.props.onUp(this.props.index)}}
                                showDelete={!this.props.disabled && this.props.index > 0}
                                onDelete={() => this.props.onDelete && this.props.onDelete()}
                            />
                        </CardBody>
                    </Card>
                </div>
            </Card>
        </>
            ;
    }

    getReferenceNumberTypeOptions() {
        if (this.props.dispatch) {
            return this.props.dispatch.company.shipmentReferenceTypeList && this.props.dispatch.company.shipmentReferenceTypeList.map((type) => {
                if (this.props.dispatch.company.shipmentIdDisplay !== type.name) {
                    return {label: type.name, value: type.name};
                }
            });
        }
        return this.props.company.shipmentReferenceTypeList && this.props.company.shipmentReferenceTypeList.map((type) => {
            if (this.props.company.shipmentIdDisplay !== type.name) {
                return {label: type.name, value: type.name};
            }
        });
    }

    pushAndUpdatePickupReferences(shipmentReference, ref) {
        let pushed = false;
        if (!this.props.value.pickupReferenceNumbers) {
            this.props.value.pickupReferenceNumbers = [];
        }

        if(this.props.value.pickupReferenceNumbers.length > 0){
            for (let i = 0; i < this.props.value.pickupReferenceNumbers.length; i++) {
                if(this.props.value.pickupReferenceNumbers[i].type === shipmentReference.type && shipmentReference.type === ref){
                    this.props.value.pickupReferenceNumbers[i] = shipmentReference;
                }
            }
        }else {
            this.props.value.pickupReferenceNumbers.push(shipmentReference);
            pushed = true;
        }

        if(shipmentReference.type !== ref && !pushed){
            let matchingReferences = this.props.value.pickupReferenceNumbers.filter((v) => {
                return v.type === shipmentReference.type && v.value === shipmentReference.value;
            });
            if (matchingReferences.length === 0) {
                this.props.value.pickupReferenceNumbers.push(shipmentReference);
            }
        }

        this.setState({
            errors: {
                shipmentId: null
            }
        });
    }

    pushAndUpdateDeliverReferences(shipmentReference, ref) {
        let pushed = false;
        if (!this.props.value.deliverReferenceNumbers) {
            this.props.value.deliverReferenceNumbers = [];
        }

        if(this.props.value.deliverReferenceNumbers.length > 0){
            for (let i = 0; i < this.props.value.deliverReferenceNumbers.length; i++) {
                if(this.props.value.deliverReferenceNumbers[i].type === shipmentReference.type && shipmentReference.type === ref){
                    this.props.value.deliverReferenceNumbers[i] = shipmentReference;
                }
            }
        }else {
            this.props.value.deliverReferenceNumbers.push(shipmentReference);
            pushed = true;
        }

        if(shipmentReference.type !== ref && !pushed){
            let matchingReferences = this.props.value.deliverReferenceNumbers.filter((v) => {
                return v.type === shipmentReference.type && v.value === shipmentReference.value;
            });
            if (matchingReferences.length === 0) {
                this.props.value.deliverReferenceNumbers.push(shipmentReference);
            }
        }

        this.setState({
            errors: {
                shipmentId: null
            }
        });
    }

    isDeliver() {
        return this.props.value.typeOfStop && this.props.value.typeOfStop.indexOf("Deliver") >= 0;
    }

    isPickup() {
        return this.props.value.typeOfStop && this.props.value.typeOfStop.indexOf("Pickup") >= 0;
    }
}