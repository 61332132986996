import * as React from "react";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {ITEMS_PER_PAGE_DEFAULT, KILOMETERS_PER_MILE} from "../../common/constants";
import * as moment from "moment";
import {showInfo} from "../../MainApp";
import {DISTANCE_FROM_DOMICILE, IDLE_TIME,EXCURSION_TIME,DWELL_TIME, MOVE_FREQUENCY, SHIPMENT} from "./EZTrackEventTableColumn";
import {convertCtoF} from "../../common/utilities";

class EZTrackEventTable extends React.Component {
    tableColumns = [
        {
            Header: "Pin #",
            accessor: "index",
            filterable: false,
            sortable: false,
            Cell: row => {
                return this.formatIndex(row);
            }
        },
        {
            Header: "Zone",
            accessor: "zone",
            filterable: true,
            sortable: false,
        },
        SHIPMENT(),
        IDLE_TIME(),
        MOVE_FREQUENCY(),
        DISTANCE_FROM_DOMICILE(this.props.distanceUnitsOfMeasure),
        {
            Header: 'Distance From Last Event',
            accessor: 'distanceFromPreviousEventInMeters',
            filterable: false,
            sortable: true,
            Cell: row => {
                if (this.props.distanceUnitsOfMeasure === 'Miles') {
                    return row.original.distanceFromPreviousEventInMeters !== null ?
                        <span>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                            .format(row.original.distanceFromPreviousEventInMeters / 1000.0 / KILOMETERS_PER_MILE)} {this.props.distanceUnitsOfMeasure}</span> :
                        null
                } else {
                    let distanceInMeters = row.original.distanceFromPreviousEventInMeters;
                    if (distanceInMeters != null) {
                        return <span>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                            .format(distanceInMeters / 1000.0)} {this.props.distanceUnitsOfMeasure}</span>
                    } else {
                        return null;
                    }
                }
            }
        },
        EXCURSION_TIME(),
        {
            Header: 'Moved',
            accessor: 'isMove',
            filterable: true,
            sortable: true,
            Cell: row => {return row.original.move ? 1 : 0},
            Filter: ({filter, onChange}) =>
                <>
                    <select
                        ref={this.isMoveFilterRef}
                        onChange={event => {
                            this.setState({isMoveFilter: event.target.value});
                            onChange(event.target.value)}}
                        style={{width: "100%"}}
                        value={
                            !!this.state.isMoveFilter
                            ? this.state.isMoveFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">1</option>
                        <option value="false">0</option>
                    </select>
                </>
        },
        {
            Header: 'Temperature (℉)',
            accessor: 'sensors.temperature',
            filterable: false,
            sortable: false,
            Cell: row => {return row.original.sensors.temperature !== null ? convertCtoF(row.original.sensors.temperature)+ " ℉" : null}
        },
        {
            Header: 'Location Name',
            accessor: 'locationName',
            filterable: true,
            sortable: true,
        },
        DWELL_TIME(),
        {
            Header: 'Address',
            accessor: 'address',
            filterable: true,
            sortable: true,
            Cell: row => {return row.original.locationName ? row.original.geofenceAddress : row.original.address}
        },
        {
            Header: 'City',
            accessor: 'city',
            filterable: true,
            sortable: true,
            Cell: row => {return this.formatCity(row) }
        },
        {
            Header: 'State',
            accessor: 'state',
            filterable: true,
            sortable: true,
            Cell: row => {return row.original.locationName ? row.original.geofenceState : row.original.state}
        },
        {
            Header: 'Report Time',
            accessor: 'sourceTimestamp',
            filterable: false,
            sortable: true,
            Cell: row => {
                let v = row.original;
                if (v.timestamp) {
                    return <MomentDisplay date={v.timestamp} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/>;
                } else {
                    return null;
                }
            },
        },
    ];
    constructor(props){
        super(props);
        this.isMoveFilterRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem('eztrack-event-table'));
        if (localStorageState != null) {
            this.state = {isMoveFilter: localStorageState.isMoveFilter}
        } else {
            this.state = {
                previousSearchRequest: {},
                pages: -1,
                autoRefreshEnabled: true,
                itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
                filtered: [{value: props.assetUuid, id: 'assetUuid'}]
            };
        }

    }
    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                if ("clipboard" in navigator && column.id === 'city') {
                    let coords = rowInfo.original.primaryLocation.latitude + ", "
                        + rowInfo.original.primaryLocation.longitude;
                    navigator.clipboard.writeText(coords);
                    showInfo(coords + " copied to clipboard!", {}, {
                        autoClose: 2000
                    });
                }
            }
        }
    }
    componentWillUnmount() {
        localStorage.setItem("eztrack-event-table", JSON.stringify({
            isMoveFilter: !!this.state.isMoveFilter,
        }))
    }
    onEventsDownloaded(data ) {
        this.setState({tableData: data});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.itemsPerPage !== prevProps.itemsPerPage ) {
            this.onEventsDownloaded(this.state.tableData.slice());
        }
        if (this.state.tableData && !prevState.tableData) {
            this.tableColumns = this.tableColumns.map((col) => {
                if (col.accessor === "movesInLast30Days") {
                    col.Header = (obj)=>{
                        return (
                            <><span title={this.formatMoveFrequencyHeader()}><i className="fa fa-sort"/> Move Frequency</span></>
                        );
                    }
                }
                return col;
            });
        }
    }
    formatIndex(row) {
        return this.state.pageable ? this.state.pageable.offset + row.index + 1 : row.index + 1;
    }
    formatCity(row) {
        if ("clipboard" in navigator) {
            return <div><span title={this.formatLatitudeLongitudePopup(row.original.primaryLocation)}>
                    {row.original.locationName ? row.original.geofenceCity : row.original.city}</span></div>
        } else {
            return row.original.locationName ? row.original.geofenceCity : row.original.city;
        }
    }
    formatLatitudeLongitudePopup(location) {
        let lat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
            .format(location.latitude );
        let lng = new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
        .format(location.longitude );
        return lat + ", " + lng + " (Click to copy)";
    }
    formatMoveFrequencyHeader() {
        let tz = "";
        if (this.props.assetDomicile) {
            tz = moment.tz(this.props.assetDomicile.timezone).format('z');
        }
        return "Percentage of days with a move in the 30 day period ending yesterday.\n" +
            "Based on the timezone of the asset's domicile (" +
            tz + ")."
    }

    clearFilterCallback() {
        this.isMoveFilterRef.current.value = undefined;
        this.setState({isMoveFilter: undefined});
    }

    render() {
        return(
            <div>
                <div className="column-wrapper text-left">
                    <PaginationFilterTable
                    openFiltersCollaps = {true}
                        get={this.props.get}
                        stateStorage="eztrack-event-table"
                        pageType="Admin"
                        baseFilter={[{id: "assetUuid", value: this.props.assetUuid}]}
                        columns={this.tableColumns}
                        clearFilter={this.clearFilterCallback.bind(this)}
                        getTdProps={this.onRowClick}
                        successCallback={(success) => {
                            this.setState({pageable: success.pageable})
                            this.props.successCallback && this.props.successCallback(success)
                        }}
                    >
                    </PaginationFilterTable>
                </div>

            </div>
        )
    }
}
export default EZTrackEventTable;
