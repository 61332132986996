import React, {Component} from 'react';
import Select from "react-select";
import {Alert, Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Row} from 'reactstrap';
import {showError} from "../../MainApp";
import * as uuid from "uuid";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";

class ReferenceNumberList extends Component {

    constructor(props) {
        super(props);
        let dispatchRefNos = props.dispatchReferenceNumbers ? props.dispatchReferenceNumbers : [];
        this.state = {
            referenceNumberType: this.getReferenceNumberType(props),
            referenceNumberValue: "",
            referenceNumbers: {},
            referenceNumbersList: props.referenceNumbers ? props.referenceNumbers : [],
            dispatchReferenceNos: dispatchRefNos.map(ref => {
                if (ref.type === this.getReferenceNumberType(props)) return {name: ref.value, value: ref.value}
            })
        };
    }

    getReferenceNumberType(props) {
        if(this.props.hideDefault) return this.props.referenceNumberTypeOptions ? this.props.referenceNumberTypeOptions[0].value : "";
        return props.defaultReferenceType ? props.defaultReferenceType : "";
    }

//So you can pass in a list to work with all the existing functionality
    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.referenceNumbers){
            let refNums = {};
            for (const referenceNumber of nextProps.referenceNumbers) {
                if (referenceNumber.value) {
                    refNums[referenceNumber.type] = referenceNumber.value;
                }
            }
            this.setState({...this.state, referenceNumbers: refNums})
        }
        if(nextProps.dispatchReferenceNumbers) {
            let newNums = nextProps.dispatchReferenceNumbers;
            let filteredNums = newNums.map(ref => {
                if(ref.type === this.getReferenceNumberType(nextProps)) return {name: ref.value, value: ref.value}
            });
            this.setState({...this.state, dispatchReferenceNos: filteredNums});
        }
    }

    //Returns a list because thats what exists
    getListFromMap(map){
        let list = [];
        if(map){
            Object.keys(map).forEach(key => {
                list.push({type: key, value: map[key]})
            })
        }
        return list;
    }

    filterReferencesForSelectedLabel() {
        return this.props.references ? this.props.references.filter(dRef => dRef.type === this.state.referenceNumberType).map((dRef) => {
            return dRef.value
        }).sort() : [];
    }

    render() {
        return <div className="referenceNumberList">
            <Card>
                <CardHeader>
                    {this.props.title?this.props.title:"Reference Numbers"}
                </CardHeader>
                <CardBody>
                    <FormGroup>
                        {this.props.error &&
                        <Row>
                            <Col className={"text-center"}>
                                <Alert color={"danger"}>{this.props.error}</Alert>
                            </Col>
                        </Row>
                        }
                            <Row >
                                <Col lg="4" md="4" sm="4">
                                    <Select onChange={e=>this.setState({referenceNumberType:e.value})}
                                            value={{value:this.state.referenceNumberType, label:this.state.referenceNumberType}}
                                            options={this.props.referenceNumberTypeOptions}
                                            isClearable={false}
                                            isSearchable={false}
                                            isDisabled={this.props.disabled}
                                    >
                                    </Select>
                                </Col>
                                {this.props.useLookup ?
                                    <Col lg="8" md="8" sm="8">
                                        {this.props.referenceNumberOptions ?
                                            <FFFormSelectCreatableField
                                                key={"ID_" + uuid.v4()}
                                                defaultOptions
                                                placeholder="Reference Number"
                                                fieldname={this.props.fieldName ? this.props.fieldName : "references"}
                                                icon="fa-list-alt"
                                                error={this.props.error}
                                                onBlur={this.props.onBlur}
                                                touched={this.props.touched}
                                                value={this.state.referenceNumbers}
                                                onChange={(v) =>{
                                                    this.props.onChange(v);
                                                }}
                                                options={this.filterReferencesForSelectedLabel()}
                                                getOptionLabel={"Reference"}
                                                getOptionValue={"Reference"}
                                                noLabel={true}
                                                isDisabled={this.props.isDisabled || !this.state.referenceNumberType}
                                            /> : <FFFormSelectCreatableField
                                                key={"ID_" + uuid.v4()}
                                                placeholder="Start Typing to Look Up Reference Number"
                                                fieldname={this.props.fieldName ? this.props.fieldName : "references"}
                                                icon="fa-list-alt"
                                                error={this.props.error}
                                                onBlur={this.props.onBlur}
                                                touched={this.props.touched}
                                                values={this.state.referenceNumbers}
                                                defaultOptions={this.state.dispatchReferenceNos}
                                                options={this.filterReferencesForSelectedLabel()}
                                                getOptionLabel={"Reference"}
                                                getOptionValue={"Reference"}
                                                onChange={(v) =>{
                                                    if(v){
                                                        v.type = this.state.referenceNumberType;
                                                        this.props.onChange(v);
                                                    }
                                                }}
                                                noLabel={true}
                                                isDisabled={this.props.isDisabled || !this.state.referenceNumberType}
                                                hideEmpty={true}
                                            />
                                        }</Col>
                                    : <Col lg="4" md="4" sm="4">
                                        <Input value={this.state.referenceNumberValue}
                                               onChange={(e) => {
                                                   this.setState({referenceNumberValue: e.target.value})
                                               }}
                                               disabled={this.props.disabled}
                                        /></Col>}
                                {!this.props.useLookup &&
                                <Col lg="4" md="4" sm="4">
                                    <Button onClick={() => {this.onAdd();}}
                                            title="Add Reference Number"
                                            style={{width: "100%"}}
                                            isDisabled={this.props.disabled}
                                            disabled={
                                                !this.state.referenceNumberValue
                                                || !this.state.referenceNumberType
                                            }>
                                        <i className="fa fa-plus"/> Add
                                    </Button>
                                </Col>}
                            </Row>
                            <div className="mt-3">
                                {this.props.referenceNumbers && this.props.referenceNumbers.map((referenceNumber) => {
                                    return this.getReferenceDiv(referenceNumber)
                                })}
                            </div>
                    </FormGroup>
                </CardBody>
            </Card>
            <div className="clear-both"></div>
        </div>
    }

    getReferenceDiv(referenceNumber) {
        return <div className="reference-number-value">
            <Badge>{referenceNumber.type}</Badge>: {referenceNumber.value}
            <Button
                color="danger"
                size="sm"
                style={{marginLeft: "5px"}}
                onClick={() => {
                    this.props.onDelete(referenceNumber);
                }}>
                <i className="fa fa-trash"/>
            </Button>
        </div>;
    }

    onAdd() {
        if (this.state.referenceNumbers[this.state.referenceNumberType]) {
            showError("That Reference Number is already applied");
        } else {
            let copy = this.state.referenceNumbers;
            copy[this.state.referenceNumberType] = this.state.referenceNumberValue;
            this.setState({
                referenceNumberValue: ""
            }, () => {
                this.props.onChange && this.props.onChange(this.getListFromMap(copy));
            });
        }
    }
}

export default ReferenceNumberList;