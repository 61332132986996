import React, { Component } from "react";

import Modal from "reactstrap/es/Modal";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import {deleteAttachment, getAttachments, putTaskCancel} from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import Lightbox from "react-image-lightbox";
import Config from "../../Config";
import "react-image-lightbox/style.css";


class DeleteAttachmentModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedAttachment: {},
      updatePopupState: false,
      comment: ""


    };
  }
 
  onDelete(index) {
    let lastAttachments = this.state.lstAttachments
    delete lastAttachments[index];
    let newList = [];
    for (const value of lastAttachments) {
      if (value) newList.push(value);
    }
    this.setState({lstAttachments: newList});
  }
  componentWillUpdate(previousProps, prevState, snapshot) {

    if (previousProps.open && this.state.updatePopupState === false) {

      this.setState({
        selectedAttachment: previousProps.selectedAttachment,
        updatePopupState: true
      });
   

    }



  }
  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1>Delete Attachments </h1>
        </ModalHeader>
        <ModalBody>
          <Container className="note-scollbox">
           
            <div>
                <Row>
                  <Col md={12}>
                    
                    <h5>Are you sure you want to delete this attachment?</h5>
                    {/* <p>You have a new Message from {this.state.currentCompany} {this.state.currentLocation} in EzCheck-In portal, click HERE to view.</p> */}
                  </Col>
                </Row>
               
            </div>
          
              
          </Container>
        </ModalBody>
        <ModalFooter>
        <Row> 
        {/* style={{ marginTop: "10px" }} */}
              <Col className="text-right" >
                  <Button color="primary" onClick={() => this.onSubmit()} >Confirm</Button>
                

                &nbsp;<Button color="danger" onClick={() => this.closePopup()} type="submit">Close</Button>
              </Col>
            </Row>
        </ModalFooter>
      </Modal>
    )
  }
  closePopup() {
    this.setState({ updatePopupState: false })
    return this.props.onClose && this.props.onClose();

  }
  onSubmit() {
    let obj={};
    if(this.props.gate){

       obj =
    {
      controlID : this.state.selectedAttachment.controlID,
      arrivalID : this.state.selectedAttachment.arrivalID,
    };
  
  }
    else{
      obj =
      {
        id : this.state.selectedAttachment.id,
        attachment : this.state.selectedAttachment.attachment,
        deleteComment :""
      };
    }
   
    this.setState({ updatePopupState: false })
     this.props.onSave && this.props.onSave(obj);
  }
  localDateToUTC(dateISOLocalString) {
    let localLoginTime = moment.utc(dateISOLocalString).utc();
    var returnDate = new Date(localLoginTime.format('MMM-DD-YYYY HH:mm:ss'));
    return returnDate;
  }
}
export default DeleteAttachmentModal;