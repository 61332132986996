import React, {Component} from "react";
import FFFormSelectLocationField from "../../common/formik-free/FFFormSelectLocationField";
import FFFormDateTimeField from "../../common/formik-free/FFFormDateTimeField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import {postLocation, promiseLocationSearch} from "../../NetworkHelpers";
import LocationForm from "../../Admin/components/LocationForm";
import {Col, Row} from "reactstrap";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";

export class DispatchStopInformation extends Component<{ errors: any, handleBlur: any, handleChange: any, touched: any, values: any, index: any, fieldTouched: any, fieldValue: any, onChange: Function, stop: any }> {
    constructor(props) {
        super(props);
        this.state = {
            locationList: []
        };
        this.selectRef = React.createRef();
    }

    loadOptions = (inputValue) => {
        return new Promise(resolve => {
            promiseLocationSearch(inputValue).then(
                (data) => {
                    if (this.props.optionsFilter) {
                        data = this.props.optionsFilter(data);
                    }
                this.setState({locationList: data});
                resolve(data);
                }
            );
        });
    };

    saveLocationSuccessCallback = (newLoc) => {
        newLoc.label = newLoc.name;
        newLoc.value = newLoc.name;
        this.props.onChange("location", newLoc);
        let locs = [newLoc].concat(this.state.locationList);
        this.setState({
            editingLocation:null,
            locationList: locs
        },)
    };

    renderModal() {
        return (
            <LocationForm
                editingLocation={this.state.editingLocation}
                cancel={()=> {
                    this.props.onChange("location", {label: "", value: ""});
                    this.setState({editingLocation:null});
                    this.selectRef.current.focus();
                    }
                }
                save={(c, setErrors)=>{
                    postLocation(c, this.saveLocationSuccessCallback,
                        (error)=>{
                        setErrors(error.formError);
                    });

                }}
                allowQuickAdd={true}
            />
        );
    }
    createNewLocation = () => {
        this.setState({
            editingLocation: {
                name: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                country: "United States",
                postal: "",
                email: "",
                contactFirstName: "",
                contactLastName: "",
                phone: "",
                latitude: "",
                longitude: "",
                code: "",
                nickname: "",
                timezone: "",
                isNew: true
            }
        });

    };

    render() {
        return <>
            {this.state.editingLocation ? this.renderModal() : null}
            <FFFormSelectField
                label="Type of Stop"
                required={true}
                placeholder="Enter Value"
                icon="fa-map-pin"
                fieldname="typeOfStop"
                name="typeOfStop"
                errors={this.props.errors.typeOfStop}
                onBlur={this.props.onBlur}
                touched={this.props.touched.typeOfStop}
                value={this.props.value.typeOfStop}
                options={["Pickup", "Delivery", "Delivery & Pickup", "Transfer"]}
                noSearch={true}
                index={this.props.index}
                isDisabled={this.props.disabled}
                onChange={(v) => {
                    this.props.onChange("typeOfStop", v ? v.value : "");
                }}
            />
            {this.props.allowLocationsByName ?
                <FFFormSelectCreatableField
                    label="Location Name"
                    placeholder="Enter Value"
                    icon="fa-map-marked-alt"
                    fieldname="locationUuid"
                    error={this.props.errors.location}
                    onBlur={this.props.onBlur}
                    touched={this.props.touched.location}
                    value={this.props.location ? this.props.location.label : ""}
                    onChange={(location)=>{
                        this.props.onChange("location", location);
                    }}
                    hideEmpty={false}
                    isDisabled={this.props.disabled}
                    defaultOptions={true}
                    loadOptions={this.loadOptions}
                />
                :
                <FFFormSelectLocationField
                    label="Location Name"
                    placeholder="Enter Value"
                    icon="fa-map-marked-alt"
                    fieldname="locationUuid"
                    errors={this.props.errors.location}
                    onBlur={this.props.onBlur}
                    touched={this.props.touched.location}
                    value={this.props.location}
                    onChange={(location)=>{
                        if (location.label === "Create new location...") {
                            location = this.createNewLocation();
                        }
                        this.props.onChange("location", location);
                    }}
                    disabled={this.props.disabled}
                    customFirstOption={{label: "Create new location...", value: "Create new location..."}}
                    selectRef={this.selectRef}
                />
            }
            <Row>
                <Col>
                    <FFFormDateTimeField
                        label={"Appointment Time"}
                        placeholder="Enter Value"
                        icon="fa-calendar"
                        fieldname="appointment"
                        error={this.props.errors.appointment}
                        touched={this.props.touched.appointment}
                        onBlur={this.props.onBlur}
                        value={this.props.appointment}
                        timezone={this.props.location && this.props.location.timezone}
                        isDisabled={this.props.disabled}
                        className={"stop-datepicker"}
                        onChange={(date) => {
                            this.props.onChange("appointment", date);
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FFFormCheckboxField
                        fieldname="enabled"
                        icon="fa-power-off"
                        label="All Day"
                        labelStyle={{minWidth: "50px"}}
                        value={this.props.stop.allDayAppointment}
                        onChange={(v) => {
                            this.props.onChange("allDayAppointment", v, ()=>{
                                this.props.onChange("appointment", new Date());
                            });
                        }}
                    >
                    </FFFormCheckboxField>
                </Col>
            </Row>
            {this.props.value.typeOfStop === "Transfer" &&
            <FFFormSelectField
                label="Type of Transfer"
                required={true}
                placeholder="Enter Value"
                icon="fa-truck"
                fieldname="typeOfTransfer"
                name="typeOfTransfer"
                error={this.props.errors.typeOfTransfer}
                touched={this.props.touched.typeOfTransfer}
                onBlur={this.props.onBlur}
                value={this.props.value.typeOfTransfer}
                options={["Equipment Transfer", "Intermodal Transfer (Dray to Rail)",
                    "Intermodal Transfer (Rail to Dray)", "Port Transfer (Dray to Port)",
                    "Port Transfer (Port to Dray)"]}
                isDisabled={this.props.disabled}
                onChange={(v) => {
                    this.props.onChange("typeOfTransfer", v ? v.value : "");
                }}
            />}
            {(this.props.value.typeOfStop === "Delivery"
                || this.props.value.typeOfStop === "Delivery & Pickup") &&
            <FFFormSelectField
                label="Delivery Plan"
                required={true}
                placeholder="Enter Value"
                icon="fa-truck-loading"
                fieldname="typeOfDelivery"
                name="typeOfDelivery"
                errors={this.props.errors.typeOfDelivery}
                touched={this.props.touched.typeOfDelivery}
                onBlur={this.props.onBlur}
                value={this.props.value.typeOfDelivery}
                options={["Live", "Drop"]}
                isDisabled={this.props.disabled}
                onChange={(v) => {
                    this.props.onChange("typeOfDelivery", v ? v.value : "");
                }}
            />}
            {(this.props.value.typeOfStop === "Pickup" || this.props.value.typeOfStop === "Delivery & Pickup") &&
            <FFFormSelectField
                label="Pickup Plan"
                required={true}
                placeholder="Enter Value"
                icon="fa-truck-loading"
                fieldname="typeOfPickup"
                name="typeOfPickup"
                errors={this.props.errors.typeOfPickup}
                touched={this.props.touched.typeOfPickup}
                onBlur={this.props.onBlur}
                value={this.props.value.typeOfPickup}
                options={["Live", "Preload"]}
                isDisabled={this.props.disabled}
                onChange={(v) => {
                    this.props.onChange("typeOfPickup", v ? v.value : "");
                }}
            />
            }
        </>;
    }
}