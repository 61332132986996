import React, { Component } from "react";
import {
  Card,
  CardBody,
  Form,
  FormFeedback,
  Button,
  Label,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  Input,
  ModalFooter,
} from "reactstrap";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import {
  convertCtoF,
  convertFtoC,
  getErrorsFromValidationError,
} from "../utilities";

import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import {
  downloadAuthorizedLocations,
  carriersByLocationUUid,
  promiseSpotForLocationByZonePrefixOrSpotPrefix,
  promiseAssetByPrefix,
  promiseTaskTypeByPrefix,
  downloadTaskByUuid,
  saveTask,
  getDefaultEZTrackEquipment,
  downloadEZTrackEquipmentForTable,
  promiseAssetStatusesByLocationUUid,
  promiseEZTrackEquipmentTypeByPrefix,
  setupDepartAsset,
  getTaskReferenceByRefID,
  getSecondaryReferenceByRefID,
  getAllSecondaryReferenceByRefID,
  promiseEZTrackEquipmentFuel
} from "../../NetworkHelpers";
import DatePicker from "react-datepicker";
import { showInfo, showError } from "../../MainApp";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import TemperatureModal from "./TemperatureModal";
import CommentTaskModal from "./CommentTaskModal";
import FFFormLabelButton from "../formik-free/FFFLabelButton";
import ReferenceModal from "../../PowerYard/components/ReferenceModal";
import HelpIcon from "../HelpIcon";
import FFFormTextAreaField from "../formik-free/FFFormTextAreaField";
class DepartTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topggleCheck: false,
      taskUnderEdit: {
        location: this.props.currentLocation,
      },
      taskTypes:{},
      errors: {},
      loaded: false,
      touched: {},
      defaultEquipmentType: {},
      closeCheck: false,
      showSealNumber: false,
      selectedTask: {
        refData: {
          prNumber: null,
          prName: null,
          prAppointmentDate: null,
          prAppointmentWindow: null,
          prAppointmentType: null,
          prAppointmentPlan: null,
          prScac: null,
          prRefIdFlagDate: null,
          cancelled: null,
          prRefIdFlag: null,
          uuid: null,
          carrier: {},
          location: null,
          secondaryRef: "",
        },
      },
      secRefNumber: null,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(this.state.taskUnderEdit, "update task under edit");
    if (!prevProps.open && this.props.open) {
      this.loadTask(this.state.taskUnderEdit.taskUuid);
      this.setState({ open: true });
      getDefaultEZTrackEquipment(this.props.currentLocation.uuid, (def) => {
        this.setState({
          defaultEquipmentType: def.content,
        });
      });
      let obj = {
        locationUuid: this.props.currentLocation.uuid,
      };
      setupDepartAsset(obj, (res) => {
        let results = res.content.taskTypes.map((it) => ({
          label: it.name,
          value: it.name,
          ...it,
        }));
        results = results.filter(
          (it) =>
            it.name.toLocaleLowerCase() === "depart asset" ||
            it.name.toLocaleLowerCase() === "check-out asset"
        );
        let index = results.findIndex(
          (it) => it.name.toLocaleLowerCase() === "check-out asset"
        );
        let currentTask = { ...this.state.taskUnderEdit };
        currentTask.created = new Date().getTime();
        currentTask.type = results[index];
        this.setState({
          taskTypeOptions: results,
          timeStamp: new Date().getTime(),
          taskUnderEdit: currentTask,
          taskTypes:currentTask.type,
        });
      });
    }
   
  }
  componentWillUpdate(previousProps, prevState, snapshot) {
    console.log(previousProps, "update task under edit");

    if (this.props.open !== previousProps.open) {
      previousProps.currentTask.acceptDate = new Date(
        previousProps.currentTask.acceptDate
      );
      previousProps.currentTask.start = new Date(
        previousProps.currentTask.start
      );
      previousProps.currentTask.end = new Date(previousProps.currentTask.end);
      this.state.taskUnderEdit = previousProps.currentTask;
    }
  }
  loadTask(uuid) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading.....");
    downloadTaskByUuid(
      uuid,
      (data) => {
        this.setState({ loaded: false });

        let success = data.content;
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
          if (success.tempSetPoint !== null) {
            success.tempSetPoint = this.getFlooredFixed(
              success.tempSetPoint,
              1
            );
          }
          success.actualTemp = convertCtoF(success.temperatureInC);
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          success.tempSetPoint = success.temperatureSetPointInC;
          if (success.temperatureSetPointInC !== null) {
            success.tempSetPoint = this.getFlooredFixed(
              success.tempSetPoint,
              1
            );
          }
          success.actualTemp = success.temperatureInC;
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        //   if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
        //     success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
        //     success.actualTemp = convertCtoF(success.temperatureInC);
        // }
        // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
        //     success.tempSetPoint = success.temperatureSetPointInC;
        //     success.actualTemp = success.temperatureInC;
        // }
        success.lastModified = success.updated;
        if (success.fuelLevel) {

          success.fuelLevel.label = success.fuelLevel.fuelLevel;

        }

        if (success.acceptDate) {
          success.acceptDate = new Date(success.acceptDate);
        } else {
          success.acceptDate = null;
        }
        if (success.startDate) {
          success.start = new Date(success.startDate);
        } else {
          success.start = null;
        }
        if (success.endDate) {
          success.end = new Date(success.endDate);
        } else {
          success.end = null;
        }
        if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY" && !(success.taskStatus == "Complete")) {
          success.assignee = this.props.user;
        }
        let taskBackup = JSON.stringify(success);
        success.secondaryRef = success.secondaryRef === '' ? null : success.secondaryRef;
        if (success.secondaryRef !== null) {
          var singleRefObj = success.secondaryRef.split(",")[0];
          var seRefObj = {
            value: singleRefObj,
            label: singleRefObj,
            uuid: singleRefObj,
            valueSubmit: success.secondaryRef,
          };
          success.secondaryRef = seRefObj;
        }
        this.setState({
          taskUnderEdit: success,
          taskBackup: taskBackup,
          showSealNumber: data.content.sealed,
          secRefNumber: success.secondaryRef
        });
      },
      (failure) => {
        showError("Unable to access task.");
      }
    );
  }
  getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  openRefModal(ref, elem) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = elem;
    this.onGetRefDataByRefID(ref);
  }
  onGetRefDataByRefID(ref) {
    getSecondaryReferenceByRefID(
      ref.clickedRef,
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }

  onChangeRefNum(obj) {
    let taskUnderEditObj = { ...this.state.taskUnderEdit };
    getTaskReferenceByRefID(
      obj.value,
      (success) => {
        if (success.code === "200") {
          let response = success.content;
          if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
            response.tempSetPoint = convertCtoF(response.temperatureSetPointInC);
            if (response.tempSetPoint !== null) {
              response.tempSetPoint = this.getFlooredFixed(response.tempSetPoint, 1);
            }
            response.actualTemp = convertCtoF(response.temperatureInC);
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(response.actualTemp, 1);
            }
          }
          if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
            response.tempSetPoint = response.temperatureSetPointInC;
            if (response.temperatureSetPointInC !== null) {
              response.tempSetPoint = this.getFlooredFixed(response.tempSetPoint, 1);
            }
            response.actualTemp = response.temperatureInC;
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(response.actualTemp, 1);
            }
          }
          // let task = taskUnderEditObj;
          let task = response;
          task.uuid = taskUnderEditObj.uuid;
          task.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          task.acceptDate = taskUnderEditObj.acceptDate;
          task.start = taskUnderEditObj.start;
          task.lastModified = taskUnderEditObj.lastModified;
          task.end = taskUnderEditObj.end;
          task.type = taskUnderEditObj.type;
          var secRefObj = {
            value: obj.value,
            label: obj.label,
            uuid: obj.uuid,
            valueSubmit: task.secondaryRef
          }
          task.moveTo = task.moveTo === null ? {} : task.moveTo;
          task.secondaryRef = secRefObj;
          this.setState({ taskUnderEdit: task });
          // success.content.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          // success.content.uuid = taskUnderEditObj.uuid;
          // success.content.acceptDate = taskUnderEditObj.acceptDate;
          // success.content.startDate = taskUnderEditObj.startDate;
          // success.content.endDate = taskUnderEditObj.endDate;
          // success.content.type = taskUnderEditObj.type === undefined ? {} : taskUnderEditObj.type;
          // // for temperature converting 
          // if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
          //   success.content.tempSetPoint = convertCtoF(success.content.temperatureSetPointInC);
          //   if (success.content.tempSetPoint !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = convertCtoF(success.content.temperatureInC);
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          //   success.content.tempSetPoint = success.content.temperatureSetPointInC;
          //   if (success.content.temperatureSetPointInC !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = success.content.temperatureInC;
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: success.content.secondaryRef
          // }
          // success.content.moveTo = success.content.moveTo === null ? {} : success.content.moveTo;
          // success.content.secondaryRef = secRefObj;
          // this.setState({ taskUnderEdit: success.content, showSealNumber: success.content.sealed })
        }
        else if (success.code === "1000") {
        }
        else if (success.code === "500") {
          // let errors = {
          //   boxedIn: true
          // }
          // let touched = {
          //   boxedIn: true
          // }
          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: obj.valueSubmit,

          // };
          // this.setState({
          //   taskUnderEdit: {
          //     secondaryRef: secRefObj,
          //     type: taskUnderEditObj.type === undefined ? {} : taskUnderEditObj.type,
          //     location: this.props.currentLocation,
          //     boxedIn: null,
          //     uuid: taskUnderEditObj.uuid
          //   },
          //   showSealNumber: false,
          //   errors: errors,
          //   touched: touched,
          // });
          //  
          console.log('state', this.state)
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit(val, taskObj) {
    if (val === "comments") {
      this.setState({
        loadedComment: true,
      });
    }
    if (val === "temp") {
      this.setState({
        loadedTemp: true,
      });
    }

    this.updateSpinner("Saving...");

    let task = {};
    if (taskObj) {
      if (taskObj.popupType === "temperature") {
        task = { ...this.state.taskUnderEdit };
        task.tempSetPoint = taskObj.tempSetPoint;
        task.actualTemp = taskObj.actualTemp;
      } else {
        task = { ...this.state.taskUnderEdit };
        task.comments = taskObj.comments;
      }
    } else {
      task = { ...this.state.taskUnderEdit };
    }
    let taskToBeSubmit = {};
    // taskToBeSubmit.boxedIn = task.boxedIn === 0 ? null : task.boxedIn;
 
   if(this.state.taskTypes){

    taskToBeSubmit.type = this.state.taskTypes;
   }
    
   
    if (task.assetStatus) {
      taskToBeSubmit.assetStatus = {
        uuid: task.assetStatus.uuid,
        name: task.assetStatus.name,
      };
    }
    if (task.assetType) {
      taskToBeSubmit.assetType = task.assetType.name;
    }
    
    
    // else {
    //   if (task.assetType) {
    //     task.asset = {
    //       // "@type": task.assetType.baseEquipment.equipmentType,
    //       // domicile: this.props.currentLocation,
    //       // carrier: task.carrier,
    //       equipment: task.assetType,
    //     };
    //   }

    // if(!task.yardStatusCheck){
    //   task.yardStatusCheck = false;
    // }

    if (task.asset) {
     
      taskToBeSubmit.assetType = task.asset.equipment.name;
    }
   
    if (task.fuelLevel) {
      if (!taskToBeSubmit.fuelLevel) {
        taskToBeSubmit.fuelLevel = {}; // Initialize fuelLevel if it doesn't exist
      }

      taskToBeSubmit.fuelLevel.uuid = task.fuelLevel.uuid;
      taskToBeSubmit.fuelLevel.name = task.fuelLevel.name;
      taskToBeSubmit.fuelLevel.fuelLevel = task.fuelLevel.fuelLevel;
    }
    else {

      taskToBeSubmit.fuelLevel = null;
    }
    if (task.reefer) {


      taskToBeSubmit.reefer = task.reefer;
    }

    else {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.reefer = false;

      }
    }
    if (task.continous) {
      taskToBeSubmit.continous = task.continous;
    }
    else {

      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.continous = false;

      }
    }
    if (task.actualTemp === "") {
      task.actualTemp = null;
    }
    if (task.tempSetPoint === "") {
      task.tempSetPoint = null;
    }
    if (
      this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
      this.props.company.temperatureUnitsOfMeasure !== "Celsius"
    ) {
      task.temperatureSetPointInC = convertFtoC(task.tempSetPoint);
      task.temperatureInC = convertFtoC(task.actualTemp);
      this.setState({ taskUnderEdit: task });
    }
    if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
      task.temperatureSetPointInC = task.tempSetPoint;
      task.temperatureInC = task.actualTemp;
      this.setState({ taskUnderEdit: task });
    }
    taskToBeSubmit.tempSetPoint = task.tempSetPoint;
    taskToBeSubmit.actualTemp = task.actualTemp;
    
    taskToBeSubmit.temperatureSetPointInC = task.temperatureSetPointInC;
    taskToBeSubmit.temperatureInC = task.temperatureInC;
    taskToBeSubmit.comments = task.comments;
    taskToBeSubmit.shipmentNumber = task.shipmentNumber;
    taskToBeSubmit.timeStamp= this.state.timeStamp;

    taskToBeSubmit.sealed = task.sealed === true ? task.sealed : false;
    if(task.sealed===true){
      taskToBeSubmit.sealNumber = task.sealNumber;


    }
    else{

      taskToBeSubmit.sealNumber = null;

    }

    //taskToBeSubmit.enabled = task.enabled;
    taskToBeSubmit.taskUuid = task.uuid;
  
    var secondaryRef = null;
    if (
      this.state.taskUnderEdit.secondaryRef !== null &&
      this.state.taskUnderEdit.secondaryRef !== "" && this.state.taskUnderEdit.secondaryRef !== undefined
    ) {
      secondaryRef = this.state.taskUnderEdit.secondaryRef.valueSubmit;
      taskToBeSubmit.secondaryRef = secondaryRef;
    } else {
      taskToBeSubmit.secondaryRef = secondaryRef;
    }
    return this.props.onSave && this.props.onSave(taskToBeSubmit);

  }

  validationSchema(values) {
    if (values.acceptDate && values.acceptDate === "Invalid Date") {
      values.acceptDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      type: Yup.string().required("Type is required."),
      
      tempSetPoint: Yup.string()
        .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        // /^-?\d{1,3}(\.\d{1,3})?$/
        .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.9)')
        .nullable()
        .transform((_, val) => val === val ? val : null),
      actualTemp: Yup.string()
        .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.9)')
        .nullable()
        .transform((_, val) => val === val ? val : null),

      
    };
    return Yup.object().shape(validationRules);
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }

 
 
  getAssetType() {
    if (
      this.state.taskUnderEdit.asset &&
      this.state.taskUnderEdit.asset.equipment
    ) {
      return this.state.taskUnderEdit.asset.equipment.name;
    } else if (this.state.taskUnderEdit.assetType) {
      return this.state.taskUnderEdit.assetType.name;
    } else if (this.state.defaultEquipmentType) {
      return this.state.defaultEquipmentType.name;
    } else {
      return "";
    }
  }

 

  setCloseVariable(val) {
    this.setState({ closeCheck: val });
  }

  render() {
    return (
      <>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
     
        <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.state.loaded} message={this.spinner}>
            <ModalHeader>Depart / Check-Out Asset</ModalHeader>
            <Formik
              initialValues={this.state.taskUnderEdit}
              onSubmit={(e) => {
                let s = { ...this.state.taskUnderEdit };
                this.setState({ taskUnderEdit: s })
                let errors = this.validate(this.validationSchema.bind(this))(
                  this.state.taskUnderEdit
                );
                ;
                let closeCheck = this.state.closeCheck;
                let errorsIsEmpty =
                  Object.keys(errors).length === 0 &&
                  errors.constructor === Object;
                if (!errorsIsEmpty || this.state.errors.name) {
                  let t = { ...this.state.touched };
                  t.type = true;
                  t.assetType = true;
                  t.asset = true;
                  this.setState({ touched: t, errors: errors });
                  // e.preventDefault();
                  return;
                }
                //   if (this.props.match.params.uuid === "new") {
                //     // this.setState({loaderMessage: "Saving ..."});
                //     this.updateSpinner("Saving ...");
                //   } else {
                //     // this.setState({loaderMessage: "Updating ..."});
                //     this.updateSpinner("Updating ...");
                //   }
                this.setState({
                  loaded: true,
                });
                this.onSubmit(closeCheck);
              }}
            >
              {({
              
                handleSubmit,
              
              }) => (
                <Form
                  onSubmit={(v) => {
                    return handleSubmit(v);
                  }}
                >
                  <ModalBody>
                    <Card className="simple-card">
                      <CardBody>
                        <FFFormAsyncSelectField
                          defaultOptions={true}
                          isClearable={true}
                          fieldname="carrier"
                          isDisabled={true}
                          label="Trailer Name"
                          hideEmpty={false}
                          placeholder="Select the Trailer Carrier Name (from the list)"
                          icon="fa-truck"
                          value={
                            this.state.taskUnderEdit.asset &&
                              this.state.taskUnderEdit.asset.carrier
                              ? this.state.taskUnderEdit.asset.carrier.name
                              : this.state.taskUnderEdit.carrier
                                ? this.state.taskUnderEdit.carrier.name
                                : ""
                          }
                          loadOptions={
                            this.props.currentLocation
                              ? carriersByLocationUUid(
                                this.props.currentLocation.uuid
                              )
                              : carriersByLocationUUid
                          }

                          // checkered={true}
                          required={false}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.carrier = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.carrier}
                          // touched={this.state.touched.carrier}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            if (
                              v == null ||
                              (s.carrier && s.carrier.uuid !== v.uuid)
                            ) {
                              s.asset = {};
                            }
                            if (v && v.__isNew__) {
                              v.name = v.label;
                            }
                            if (s.asset) {
                              s.asset.carrier = v;
                            }
                            s.carrier = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        <FFFormSelectCreatableField
                          key={
                            "ID_" +
                            (this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.uuid
                              : "")
                          }
                          fieldname="asset"
                          label="Trailer Number"
                          placeholder="Input/Select Trailer Number (Type Ahead, Drop Down)"
                          icon="fa-truck"
                          value={
                            this.state.taskUnderEdit.asset && this.state.taskUnderEdit.asset.assetId
                              ? this.state.taskUnderEdit.asset.assetId
                              : ""
                          }
                          isClearable={true}
                          isDisabled={true}
                          defaultOptions={true}
                          loadOptions={promiseAssetByPrefix(
                            this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.uuid
                              : ""
                          )}
                          hideEmpty={true}
                          checkered={true}
                          required={false}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.asset = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.asset}
                          touched={this.state.touched.asset}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.asset = v;
                            s.assetType =
                              v && v.equipment ? v.equipment.name : "";
                            if (v && v.__isNew__ === true) {
                              s.asset.assetId = s.asset.assetId.replace(/ /g, '').toUpperCase()
                              s.asset.label = s.asset.label.replace(/ /g, '').toUpperCase()
                              s.asset.value = s.asset.value.replace(/ /g, '').toUpperCase()
                            }
                            if (
                              v &&
                              v.latestTaskSpot != null &&
                              v.latestTaskSpot.location &&
                              v.latestTaskSpot.location.uuid ===
                              this.props.currentLocation.uuid
                            ) {
                              s.moveFrom = v.latestTaskSpot;
                            }
                            this.setState({ taskUnderEdit: s });
                          }}
                        ></FFFormSelectCreatableField>
                        <FFFormSelectField
                          label="Task Type"
                          required={true}
                          placeholder="Enter Value"
                          icon="fa-keynote"
                          fieldname="type"
                          name="type"
                          value={this.state.taskTypes ? this.state.taskTypes.name : ""}
                          options={this.state.taskTypeOptions}
                          noSearch={true}
                          onChange={(v) => {
                            let s = {  };
                            s = v;
                            this.setState({ taskTypes: s });
                          }}
                        />
                        <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>{"Timestamp"}</Label>

                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                placeholderText={"MM/DD/YYYY HH:MM AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.start &&
                                  "is-invalid"
                                }
                                selected={this.state.timeStamp}
                                onChange={(date) => this.setState({ timeStamp: date })}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm a"
                                showTimeInput
                                shouldCloseOnSelect={false}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                        <FFFormAsyncSelectField
                          defaultOptions={true}
                          fieldname="assetType"
                          label="Asset Type"
                          icon="fa-spot"
                          value={this.getAssetType()}
                          required={false}
                          isClearable={true}
                          hideEmpty={true}

                          placeholder="Asset Type - Select the Trailer Type"
                          loadOptions={
                            this.props.currentLocation
                              ? promiseEZTrackEquipmentTypeByPrefix(
                                this.props.currentLocation.uuid
                              )
                              : promiseEZTrackEquipmentTypeByPrefix
                          }
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.assetType = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.assetType}
                          touched={this.state.touched.assetType}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            if (s.asset) {
                              s.asset.equipment = v === null ? this.state.defaultEquipmentType : v;
                            }
                            s.assetType = v;
                            if (
                              s.assetType &&
                              s.assetType.temperatureControlled !== true
                            ) {
                              s.tempSetPoint = null;
                              s.actualTemp = null;
                            }
                            if (s.assetType && s.assetType.reefer !== true) {
                              s.fuelLevel = null;
                              s.reefer = null;
                              s.continous = null;

                            }

                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        {
                          (this.props.currentLocation.apiSetting !== null && this.props.currentLocation.apiSetting !== undefined) &&
                          this.props.currentLocation.apiSetting.refernceApi &&
                          <FFFormLabelButton
                            label=""
                            isDisabled={
                              this.state.secRefNumber === null ? true : false
                            }
                            btnColor={"primary"}
                            isLblShow={false}
                            btnTxt="Reference Data"
                            fieldname="clientSecret"
                            onClick={() =>
                              this.openRefModal(
                                this.state.selectedTask.refData,
                                this.state.taskUnderEdit.secondaryRef.value
                              )
                            }
                          />
                        }
                        {/* <HelpIcon className={"float-right"} helpMessage={"Reference number in disabled in edit task."} /> */}
                        {
                          (this.props.currentLocation.apiSetting !== null && this.props.currentLocation.apiSetting !== undefined) &&
                          this.props.currentLocation.apiSetting.refernceApi &&
                          <FFFormAsyncSelectField
                            defaultOptions={false}
                            isClearable={true}
                            isDisabled={false}
                            fieldname="secondaryRef"
                            placeholder="Select Your Company ID for the Shipment on this Trailer"
                            label="Reference Number"
                            hideEmpty={true}
                            icon="fa-regular fa-hashtag"
                            value={
                              this.state.taskUnderEdit.secondaryRef
                                ? this.state.taskUnderEdit.secondaryRef
                                  .valueSubmit
                                : ""
                            }
                            required={false}
                            loadOptions={
                              this.state.taskUnderEdit.location ?
                                getAllSecondaryReferenceByRefID(
                                  this.state.taskUnderEdit.location.uuid
                                )
                                : getAllSecondaryReferenceByRefID
                            }
                            // isDisabled={this.state.secRefNumber}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.secondaryRef = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            error={this.state.errors.secondaryRef}
                            touched={this.state.touched.secondaryRef}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.secondaryRef = v;
                              if (v !== null) {
                                // if (this.state.isNew) {
                                //   this.onChangeRefNum(v);
                                // }
                                this.setState({
                                  taskUnderEdit: s,
                                  secRefNumber: v.valueSubmit,
                                });
                              } else {
                                this.setState({
                                  taskUnderEdit: s,
                                  secRefNumber: null,
                                });
                              }
                            }}
                          />
                        }
                        <FFFormAsyncSelectField
                          defaultOptions={true}
                          fieldname="assetStatus"
                          label="Asset Status"
                          hideEmpty={true}
                          placeholder="Select the status of the trailer (from the list)"
                          icon="fa-balance-scale-left"
                          isClearable={true}
                          value={
                            this.state.taskUnderEdit.assetStatus
                              ? this.state.taskUnderEdit.assetStatus.name
                              : ""
                          }

                          required={false}
                          loadOptions={promiseAssetStatusesByLocationUUid(
                            this.props.currentLocation.uuid
                          )}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            ;
                            let touched = { ...this.state.touched };

                            touched.assetStatus = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.assetStatus}
                          touched={this.state.touched.assetStatus}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.assetStatus = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                        {((this.state.taskUnderEdit.asset &&
                          this.state.taskUnderEdit.asset.equipment &&
                          this.state.taskUnderEdit.asset.equipment
                            .reefer) ||
                          (this.state.taskUnderEdit.assetType &&
                            this.state.taskUnderEdit.assetType
                              .reefer || this.state.newAsset === true)) && (
                            <>
                              <FFFormAsyncSelectField
                                defaultOptions={true}
                                fieldname="fuelLevel"
                                label="Fuel Level"
                                hideEmpty={true}
                                placeholder="Select the fuel Level of the trailer (from the list)"
                                icon="fa-solid fa-gas-pump"
                                isClearable={true}
                                value={
                                  this.state.taskUnderEdit.fuelLevel
                                    ? this.state.taskUnderEdit.fuelLevel.label
                                    : ""
                                }
                                required={false}
                                loadOptions={promiseEZTrackEquipmentFuel}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.taskUnderEdit);
                                  let touched = { ...this.state.touched };

                                  touched.fuelLevel = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.fuelLevel}
                                touched={this.state.touched.fuelLevel}
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.fuelLevel = v;
                                  this.setState({ taskUnderEdit: s });
                                }}
                              />
                              <FFFormCheckboxField
                                fieldname="reefer"
                                icon="fa-power-off"

                                label="Reefer (On/Off)"
                                required={false}
                                value={
                                  this.state.taskUnderEdit.reefer == null
                                    ? false
                                    : this.state.taskUnderEdit.reefer
                                }
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.reefer = v;
                                  if (!v) { // If Reefer is false, set Continous to false as well
                                    s.continous = false;
                                  }
                                  this.setState({
                                    taskUnderEdit: s,
                                  });
                                }}
                              />
                              <FFFormCheckboxField
                                fieldname="continous"
                                icon="fa-power-off"
                                label="Continuous (Yes/No)"

                                disabled={!this.state.taskUnderEdit.reefer}
                                required={false}
                                value={
                                  this.state.taskUnderEdit.continous == null
                                    ? false
                                    : this.state.taskUnderEdit.continous
                                }
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.continous = v;
                                  this.setState({
                                    taskUnderEdit: s,
                                  });
                                }}
                              />



                            </>
                          )}
                        {((this.state.taskUnderEdit.asset &&
                          this.state.taskUnderEdit.asset.equipment &&
                          this.state.taskUnderEdit.asset.equipment
                            .temperatureControlled) ||
                          (this.state.taskUnderEdit.assetType &&
                            this.state.taskUnderEdit.assetType
                              .temperatureControlled) ||
                          this.state.newAsset === true) && (
                            <>
                              <FFFormTextField
                                fieldname="tempSetPoint"
                                isClearable={true}
                                label={
                                  "Temp - Set Point (" +
                                  (this.props.company.temperatureUnitsOfMeasure ===
                                    "Fahrenheit"
                                    ? "℉"
                                    : "℃") +
                                  ")"
                                }
                                placeholder="Enter the temperature set point"
                                icon="fa-temperature-low"
                                value={this.state.taskUnderEdit.tempSetPoint}
                                numbersOnly={false}
                                required={false}
                                onBlur={() => {
                                  let errors = this.validate(
                                    this.validationSchema.bind(this)
                                  )(this.state.taskUnderEdit);
                                  let touched = { ...this.state.touched };
                                  touched.tempSetPoint = true;
                                  this.setState({ errors: errors, touched: touched });
                                }}
                                error={this.state.errors.tempSetPoint}
                                touched={this.state.touched.tempSetPoint}
                                onChange={(v) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.tempSetPoint = v;
                                  this.setState({ taskUnderEdit: s });
                                }}
                              />
                            </>
                          )}
                        {((this.state.taskUnderEdit.asset &&
                          this.state.taskUnderEdit.asset.equipment &&
                          this.state.taskUnderEdit.asset.equipment
                            .temperatureControlled) ||
                          (this.state.taskUnderEdit.assetType &&
                            this.state.taskUnderEdit.assetType
                              .temperatureControlled) ||
                          this.state.newAsset === true) && (
                            <FFFormTextField
                              fieldname="actualTemp"
                              isClearable={true}
                              label={
                                "Temp - Actual (" +
                                (this.props.company.temperatureUnitsOfMeasure ===
                                  "Fahrenheit"
                                  ? "℉"
                                  : "℃") +
                                ")"
                              }
                              placeholder="Enter the actual temperature"
                              icon="fa-temperature-high"
                              value={this.state.taskUnderEdit.actualTemp}
                              numbersOnly={false}
                              required={false}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };
                                touched.actualTemp = true;
                                this.setState({ errors: errors, touched: touched });
                              }}
                              error={this.state.errors.actualTemp}
                              touched={this.state.touched.actualTemp}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.actualTemp = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />
                          )}

                        <FFFormTextField
                          fieldname="shipmentNumber"
                          label="Shipment Number"
                          placeholder="Enter the shipment number"
                          icon="fa-tag"
                          value={
                            this.state.taskUnderEdit.shipmentNumber
                              ? this.state.taskUnderEdit.shipmentNumber
                              : ""
                          }
                          required={false}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.shipmentNumber = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.shipmentNumber}
                          touched={this.state.touched.shipmentNumber}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.shipmentNumber = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />

                        <FFFormCheckboxField
                          // button
                          fieldname="sealed"
                          icon="fa-power-off"
                          // onClick={(v) => {
                          //   this.setState({
                          //     cancelTask: this.state.taskUnderEdit,
                          //     commentTaskModalOpen: true,
                          //   });
                          // }}
                          // btnEmpty={this.state.taskUnderEdit.comments}
                          showCommentButton={true}
                          label="Sealed"
                          value={
                            this.state.taskUnderEdit.sealed == null
                              ? false
                              : this.state.taskUnderEdit.sealed
                          }
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.sealed = v;
                            this.setState({
                              taskUnderEdit: s,
                              showSealNumber: v,
                            });
                          }}
                        />
                         {this.state.taskUnderEdit.sealed && (
                          <FFFormTextField
                            fieldname="sealNumber"
                            label="Seal #"
                            placeholder="Enter the seal number"
                            icon="fa-tag"
                            value={
                              this.state.taskUnderEdit.sealNumber
                                ? this.state.taskUnderEdit.sealNumber
                                : ""
                            }
                            required={false}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.sealNumber = true;
                              this.setState({
                                errors: errors,
                                touched: touched,
                              });
                            }}
                            error={this.state.errors.sealNumber}
                            touched={this.state.touched.sealNumber}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.sealNumber = v;
                              this.setState({ taskUnderEdit: s });
                            }}
                          />
                         )}
                        <FFFormTextAreaField
                          label="Comments"
                          required={false}
                          placeholder="Enter Task Comments"
                          icon="fa-comment"
                          fieldname="comments"
                          isClearable={true}
                          error={this.state.errors.comments}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.comments = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          touched={this.state.touched.comments}
                          value={this.state.taskUnderEdit.comments}
                          onChange={(event) => {

                            let s = { ...this.state.taskUnderEdit };
                            s.comments = event !== null ? event.target.value : null;
                            this.setState({ taskUnderEdit: s });
                          }}
                          rows={"5"}
                        />

                      </CardBody>
                    </Card>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      className={"float-left"}
                      color="danger"
                      onClick={() => {
                        this.setState({ open: false, showSealNumber: false });
                        this.props.onCancel && this.props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>{" "}
                    &nbsp;&nbsp;
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => this.setCloseVariable(true)}
                    >
                      Save &#38; Close
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </Loader>
        </Modal>
      </>
    );
  }
}

export default DepartTaskModal;
