import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, Row} from "reactstrap";
import {updateNotificationSettings} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import * as Yup from "yup";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormRadioField from "../../common/formik-free/FFFormRadioField";
import {getErrorsFromValidationError} from "../../common/utilities";
import {VOICE_CALL, TEXT_MESSAGE} from "../../common/constants"
import {DwellInputWithUnit} from "./LocationFormFields";

export default class EventNotifications extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            errors: {},
            touched: {},
            eventNotificationSettings: this.props.company.eventNotificationSettings ? this.props.company.eventNotificationSettings : {
                driverDeliveryMinutesPrior: null,
                driverDeliveryReminderMethod: VOICE_CALL,
                driverAtDockMessageWithinMinutes: null,
                driverAtDockReminderMethod: VOICE_CALL,
                carrierAtDockMessageWithinMinutes: null,
                carrierAtDockReminderMethod: VOICE_CALL
            }
        };
        if (!this.state.eventNotificationSettings.driverDeliveryReminderMethod) {
            this.state.eventNotificationSettings.driverDeliveryReminderMethod = VOICE_CALL;
        }
        if (!this.state.eventNotificationSettings.driverAtDockReminderMethod) {
            this.state.eventNotificationSettings.driverAtDockReminderMethod = VOICE_CALL;
        }
        if (!this.state.eventNotificationSettings.carrierAtDockReminderMethod) {
            this.state.eventNotificationSettings.carrierAtDockReminderMethod = VOICE_CALL;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.company.uuid !== this.props.company.uuid){
            if (this.props.company.eventNotificationSettings) {
                let newState = {...this.props.company.eventNotificationSettings};
                if (newState.driverDeliveryReminderMethod === null) {
                    newState.driverDeliveryReminderMethod = VOICE_CALL;
                }
                if (newState.driverAtDockReminderMethod === null) {
                    newState.driverAtDockReminderMethod = VOICE_CALL;
                }
                if (newState.carrierAtDockReminderMethod === null) {
                    newState.carrierAtDockReminderMethod = VOICE_CALL;
                }
                this.setState({eventNotificationSettings: newState});
            } else {
                this.setState({eventNotificationSettings:  {
                        driverDeliveryMinutesPrior: "",
                        driverDeliveryReminderMethod: VOICE_CALL,
                        driverAtDockMessageWithinMinutes: "",
                        driverAtDockReminderMethod: VOICE_CALL,
                        carrierAtDockMessageWithinMinutes: "",
                        carrierAtDockReminderMethod: VOICE_CALL
                    }
                });
            }
        }
    }

    onSubmit(values) {
        if (values.driverDeliveryMinutesPrior === "" || values.driverDeliveryMinutesPrior === null) {
            values.driverDeliveryMinutesPrior = null;
            values.driverDeliveryReminderMethod = null;
        }
        if (values.driverAtDockMessageWithinMinutes === "" || values.driverAtDockMessageWithinMinutes === null) {
            values.driverAtDockMessageWithinMinutes = null;
            values.driverAtDockReminderMethod = null;
        }
        if (values.carrierAtDockMessageWithinMinutes === "" || values.carrierAtDockMessageWithinMinutes === null) {
            values.carrierAtDockMessageWithinMinutes = null;
            values.carrierAtDockReminderMethod = null;
        }

        updateNotificationSettings(this.props.company.uuid, values, (settings) => {
            showInfo("Successfully saved event notification settings");
            if (settings.driverDeliveryReminderMethod === null) {
                settings.driverDeliveryReminderMethod = VOICE_CALL;
            }
            if (settings.driverAtDockReminderMethod === null) {
                settings.driverAtDockReminderMethod = VOICE_CALL;
            }
            if (settings.carrierAtDockReminderMethod === null) {
                settings.carrierAtDockReminderMethod = VOICE_CALL;
            }
            this.setState({eventNotificationSettings:settings});
            this.props.onChange(settings);
        }, () => {
            showError("Failed to save settings")
        });
        return false;
    }

    validationSchema() {
        return Yup.object().shape({
            driverDeliveryMinutesPrior: Yup.number()
                .typeError("Minutes prior must be a number")
                .positive("Minutes prior must be a positive integer")
                .integer("Minutes prior must be an integer.").nullable(),
            driverAtDockMessageWithinMinutes: Yup.number()
                .typeError("Notify driver if no 'At Dock' message must be a number")
                .positive("Notify driver if no 'At Dock' message must be a positive integer")
                .integer("notify driver if no 'At Dock' message must be an integer").nullable(),
            carrierAtDockMessageWithinMinutes: Yup.number()
                .typeError("Notify carrier if no 'At Dock' message must be a number")
                .positive("Notify carrier if no 'At Dock' message must be a positive integer")
                .integer("notify carrier if no 'At Dock' message must be an integer").nullable(),
        })
    };

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema();
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        }
    };

    onChange(field, value) {
        let touched = {...this.state.touched};
        touched[field] = true;
        let eventSettings = {...this.state.eventNotificationSettings};
        eventSettings[field] = value === '' ? null : value;
        let errors = this.validate(this.validationSchema)(eventSettings);
        this.setState({eventNotificationSettings: eventSettings, touched: touched, errors: errors});
    }

    render() {
        return (
            <div className="flex-column flex flex-grow-1">
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    return this.onSubmit(this.state.eventNotificationSettings);
                }}>
                    <h5 className="card-title">Event Notification Settings</h5>
                    <Row>
                        <Col md={6}>
                            <FFFormTextField
                                label="Notify driver minutes prior to every stop appointment."
                                placeholder="Leave blank to disable."
                                icon="fa-truck"
                                fieldname="driverDeliveryMinutesPrior"
                                error={this.state.errors.driverDeliveryMinutesPrior}
                                onChange={(v) => {
                                    this.onChange("driverDeliveryMinutesPrior", v);
                                }}
                                touched={this.state.touched.driverDeliveryMinutesPrior}
                                value={this.state.eventNotificationSettings.driverDeliveryMinutesPrior}
                                required={true}
                                numbersOnly
                                min={0}
                            />
                        </Col>
                        <Col md={1}>
                            minutes
                        </Col>
                        <Col md={5}>
                            <FFFormRadioField
                                label="Notify using:"
                                icon="fa-phone"
                                fieldname="driverDeliveryReminderMethod"
                                options={[{name: VOICE_CALL, value:VOICE_CALL},{name: TEXT_MESSAGE, value: TEXT_MESSAGE}]}
                                error={this.state.errors.driverDeliveryReminderMethod}
                                value={this.state.eventNotificationSettings.driverDeliveryReminderMethod}
                                touched={this.state.touched.driverDeliveryReminderMethod}
                                onChange={(v) => {
                                    this.onChange("driverDeliveryReminderMethod", v);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                        <FFFormTextField
                            label='Notify driver if no "At dock" message received within a number of minutes'
                            placeholder="Leave blank to disable."
                            icon="fa-truck"
                            fieldname="driverAtDockMessageWithinMinutes"
                            error={this.state.errors.driverAtDockMessageWithinMinutes}
                            onChange={(v) => {
                                this.onChange("driverAtDockMessageWithinMinutes", v);
                            }}
                            touched={this.state.touched.driverAtDockMessageWithinMinutes}
                            value={this.state.eventNotificationSettings.driverAtDockMessageWithinMinutes}
                            required={true}
                            numbersOnly
                        />
                        </Col>
                        <Col md={1}>
                            minutes
                        </Col>
                        <Col md={5}>
                            <FFFormRadioField
                                label="Notify using:"
                                icon="fa-phone"
                                fieldname="driverAtDockReminderMethod"
                                options={[{name: VOICE_CALL, value:VOICE_CALL},{name: TEXT_MESSAGE, value: TEXT_MESSAGE}]}
                                error={this.state.errors.driverAtDockReminderMethod}
                                value={this.state.eventNotificationSettings.driverAtDockReminderMethod}
                                touched={this.state.touched.driverAtDockReminderMethod}
                                onChange={(v) => {
                                    this.onChange("driverAtDockReminderMethod", v);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FFFormTextField
                                label='Notify first carrier contact if no "At dock" message received within a number of minutes'
                                placeholder="Leave blank to disable."
                                icon="fa-truck"
                                fieldname="carrierAtDockMessageWithinMinutes"
                                error={this.state.errors.carrierAtDockMessageWithinMinutes}
                                onChange={(v) => {
                                    this.onChange("carrierAtDockMessageWithinMinutes", v);
                                }}
                                touched={this.state.touched.carrierAtDockMessageWithinMinutes}
                                value={this.state.eventNotificationSettings.carrierAtDockMessageWithinMinutes}
                                required={true}
                                numbersOnly
                            />
                        </Col>
                        <Col md={1}>
                            minutes
                        </Col>
                        <Col md={5}>
                            <FFFormRadioField
                                label="Notify using:"
                                icon="fa-phone"
                                fieldname="carrierAtDockReminderMethod"
                                options={[{name: VOICE_CALL, value:VOICE_CALL},{name: TEXT_MESSAGE, value: TEXT_MESSAGE}]}
                                error={this.state.errors.carrierAtDockReminderMethod}
                                value={this.state.eventNotificationSettings.carrierAtDockReminderMethod}
                                touched={this.state.touched.carrierAtDockReminderMethod}
                                onChange={(v) => {
                                    this.onChange("carrierAtDockReminderMethod", v);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DwellInputWithUnit
                                label="Remind driver to 'Check-In' if no updates"
                                error={this.state.errors.remindDriverIfNoUpdates}
                                value={this.state.eventNotificationSettings.remindDriverIfNoUpdates && this.state.eventNotificationSettings.remindDriverIfNoUpdates.value}
                                touched={this.state.touched.remindDriverIfNoUpdates}
                                unit={this.state.eventNotificationSettings.remindDriverIfNoUpdates && this.state.eventNotificationSettings.remindDriverIfNoUpdates.unit}
                                onChange={(obj) => {
                                    if(!obj.unit) obj.unit = "Minutes";
                                    this.onChange("remindDriverIfNoUpdates", obj);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FFFormTextField
                                label="Default Message for remind driver to 'Check-In' if no updates"
                                icon="fa-truck"
                                fieldname="remindDriverIfNoUpdatesMessageDefaultValue"
                                error={this.state.errors.remindDriverIfNoUpdatesMessageDefaultValue}
                                onChange={(v) => {
                                    this.onChange("remindDriverIfNoUpdatesMessageDefaultValue", v);
                                }}
                                touched={this.state.touched.remindDriverIfNoUpdatesMessageDefaultValue}
                                value={this.state.eventNotificationSettings.remindDriverIfNoUpdatesMessageDefaultValue}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className={"text-right"}>
                            <Button color="primary" type="submit">Save</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}
EventNotifications.propTypes = {
    company: PropTypes.any,
};
    