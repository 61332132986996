import React, {Component} from 'react';
import FormTextField from "../../common/FormTextField";
import {Button, Col, Form, Row} from "reactstrap";
import {
    downloadCarrierList,
    downloadLocationList,
    postArrival, postUserDefaultLocation,
    promiseTrailerByCarrierAndIdPrefix,
    promiseTrailerByCarrierAndIdPrefixWithFilter,
    promiseTrailerByIdPrefix,
    promiseTrailerByIdPrefixWithFilter
} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import {withRouter} from "react-router";
import {Formik} from "formik";
import * as Yup from "yup";
import {getErrorsFromValidationError, padNumber} from "../../common/utilities";
import connect from "react-redux/es/connect/connect";
import FormSelectLocationField from "../../common/FormSelectLocationField";
import $ from 'jquery';
import {TrailerTypeCard} from "./TrailerTypeCard";
import {DepartureInformationCard} from "./DepartureInformationCard";
import {ArrivalCard} from "./ArrivalCard";
import FormDateTimeField from "../../common/FormDateTimeField";
import FormSelectCreatableField from "../../common/FormSelectCreatableField";
import FormRadioField from "../../common/FormRadioField";
import {ROLE_ADMIN, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import ReferenceNumberList from "../../Dispatch/components/ReferenceNumberList";
import {LoadedTrailerFormFields} from "./LoadedTrailerFormFields";
import {ChangeLocationFormField} from "../../Dashboard/Main/components/CurrentLocation";
import {storeCurrentLocation} from "../../Login/actions/Login";


class Arrival extends Component {
    constructor(props) {
        super(props);
        this.displayInField = false;
        this.state = {
            date: new Date(),
            formValues: {
                arrival: true,
                shipmentNumber:"",
                shipmentReferenceNumbers: [],
                departingShipmentNumber:"",
                departingShipmentReferenceNumbers: [],
                departingTrailerNumber: "",
                date: new Date(),
                appointment: new Date(),
                carrier:"",
                broker:"",
                tractorNumber:"",
                tractorLicense:"",
                tractorLicenseState:"",
                trailerNumber:"",
                trailerLicense:"",
                trailerLicenseState:"",
                trailerTemperature:"",
                driverCdl:"",
                driverFirstname:"",
                driverLastname:"",
                driverState:"",
                driverCell:"",
                driverCountryCode:"US",
                driverHasSmartPhone:false,
                driverUuid: false,
                minutesWaiting: "0",
                isShipping: false,
                departingType: "",
                liveDrop: "",
                departingAppointment: new Date(),
                destinationLiveDrop: "",
                departingDestinationLocationUuid:""
            },
            openSections: {}
        };
        this.setupStateWithData(this.state, this.props.arrivalData);
    }

    componentDidMount(){
        window.scrollTo(0,0);
        downloadCarrierList((data)=>{this.onCarriersDownloaded(data)}, (e)=>{console.dir(e)});
        downloadLocationList((data) => {this.onLocationsDownloaded(data)}, (e) => {console.dir(e)});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.arrivalData !== this.props.arrivalData) {
            let s = {};
            this.setupStateWithData(s, this.props.arrivalData);
            this.setState(s);
        }
    }

    onCarriersDownloaded( data ) {
        let carrierList = data.map((c)=>c.name);
        this.setState({carrierOptions:carrierList})
    }

    onLocationsDownloaded( data ) {
        this.setState({locationList: data})
    }

    setupStateWithData(state, data) {
        if (!data) return state;
        let event = null;
        if (data.shipment) {
            event = data.shipment.lastArrival;
        } else if (data.tractor) {
            event = data.tractor.lastArrival;
        } else if (data.trailer) {
            event = data.trailer.lastArrival;
        } else if (data.driver) {
            event = data.driver.lastArrival;
        }
        if (event) {
            state.formValues = {...event};
            state.formValues.appointment = event.appointment?event.appointment:new Date();
            state.formValues.carrier = event.carrier?event.carrier.name:"";
        }
        return state;
    }



    validationSchema(values) {

        let validationRules = {
            date: Yup.string()
                .required('Date is required.'),
            carrier: Yup.string()
                .required('Carrier is required.'),
            driverFirstname: Yup.string()
                .required('Driver firstname is required.'),
            driverLastname: Yup.string()
                .required('Driver lastname is required.'),
            driverCell: Yup.string()
                .required('Driver mobile is required.'),
            minutesWaiting: Yup.number("Please enter a valid number.").typeError("Please enter a valid number.").nullable(),
            tractorNumber: Yup.string().required('Tractor number is required.')
        };

        if(this.props.user.company.smsEnabled) {
            validationRules.departingType = Yup.string()
                .required('Departing trailer type is required.');

            if (values.departingType === "loaded") {
                validationRules.departingShipmentReferenceNumbers = Yup.array().min(1).required("At least one departing shipment reference number is required");

                if ((!this.props.user.company.workflowSettings ||
                    !this.props.user.company.workflowSettings.disableMultiLocationTracking)) {
                    validationRules.destinationLiveDrop = Yup.string()
                        .required('Destination Delivery Plan is required.');
                }
                if ((!this.props.user.company.workflowSettings ||
                    !this.props.user.company.workflowSettings.disableMultiLocationTracking)) {
                    validationRules.departingDestinationLocationUuid = Yup.string()
                        .required('Destination Location is required.');
                }
            }
        }

        if ( this.state.trailerType === "loaded" ) {
            validationRules.shipmentReferenceNumbers = Yup.array().min(1).required("At least one shipment reference number is required");
            validationRules.liveDrop = Yup.string()
                .required('Arrival Delivery Plan is required.');
            validationRules.trailerNumber = Yup.string()
                .required('Trailer number is required.');
        } else if ( this.state.trailerType === "bobtail" ) {

        } else if ( this.state.trailerType === "empty" ) {
            validationRules.trailerNumber = Yup.string()
                .required('Trailer number is required.');
        }
        return Yup.object().shape(validationRules)
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        }
    }

    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        values.trailerType = this.state.trailerType;
        values.locationUuid =   this.props.currentLocation?this.props.currentLocation.uuid:"";
        this.saveArrival(values, setErrors, setSubmitting);
        return false;
    }

    departingOptions(values) {
        if ( this.state.trailerType === "loaded") {
            if ( values.liveDrop === "live" ) {
                return [{name:"Loaded", value: "loaded"}, {name:"Empty", value:"empty"}];
            } else {
                return [{name:"Loaded", value: "loaded"}, {name:"Empty", value:"empty"}, {name:"Bobtail (No trailer)", value:"bobtail"}];
            }
        } else if (this.state.trailerType === "bobtail") {
            return [{name:"Loaded", value: "loaded"}, {name:"Empty", value:"empty"}];
        } else if (this.state.trailerType === "empty") {
            return [{name:"Loaded", value: "loaded"}, {name:"Empty", value:"empty"}, {name:"Bobtail (No trailer)", value:"bobtail"}];
        }
    }

    classForSectionButton(section) {
        if ( this.state.openSections[section]) {
            return "fa fa-minus";
        } else {
            return "fa fa-plus"
        }
    }

    sectionOpen( section ) {
        return this.state.openSections[section];
    }

    toggleSection(section ) {
        let sections = Object.assign({},this.state.openSections); // copy sections
        if ( sections[section]) {
            sections[section] = false;
        } else {
            sections[section] = true;
        }
        this.setState({openSections: sections});
    }

    scrollToFirstError() {
        // set timeout is needed to yield to formix so it re-renders with the errors visible.
        setTimeout( () => {
            if ($(".invalid-feedback:visible")[0]) {
                let newTop = $(".invalid-feedback:visible").offset().top - 140;  // -140 for AppHeader, etc.
                $('html, body').animate({
                    scrollTop: newTop
                });
            }
        }, 100);
    }


    dateToStringLocalTime( date ) {
        if ( date ) {
            return date.getFullYear() + "-" + padNumber(date.getMonth()+1, 2) + "-" + padNumber(date.getDate(),2) + "T" + padNumber(date.getHours(),2) + ":" + padNumber(date.getMinutes(),2) + ":00";
        }
    }

    saveArrival(values, setErrors,setSubmitting) {
        let request = Object.assign({}, values);
        request.appointment =  this.dateToStringLocalTime(request.appointment);
        request.departingAppointment = this.dateToStringLocalTime(request.departingAppointment);

        postArrival(request,
            () => {
                showInfo("Arrival has been saved.  Please advise the driver that they have been sent a text message to initiate tracking.");
                this.props.history.push("/")
            },
            (e) => {
                if(e.formError){
                    setSubmitting(false);
                    setErrors(e.formError);
                    showError(e.message);
                }else {
                    showError("Unable to process arrival.");
                }
                // console.log(e);
            })
    }


    render() {
        if ( !this.props.currentLocation && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN])) {
            this.props.history.push("/admin");
            return <div></div>;
        }else if (!this.props.currentLocation ){
            return <div className="no-location-error">Please choose a location.</div>
        }

        function getLocationByUuid(value, locationList) {
            for (const locationListElement of locationList) {
                if(locationListElement && locationListElement.uuid === value){
                    return locationListElement;
                }
            }
            return false;
        }

        return (
            <div className="text-left p-3 form-bk">
                <Row>
                    <Col>
                        <h1>Process Arrival: {this.props.currentLocation.name}</h1>
                    </Col>
                    <Col style={{
                        textAlign: "right"
                    }}>
                        <ChangeLocationFormField
                            currentLocation={this.props.currentLocation}
                            onChange={(location)=>{
                                this.props.setCurrentLocation(location);
                                if (location && location.uuid) {
                                    postUserDefaultLocation(location.uuid);
                                }
                            }}
                        />
                    </Col>
                </Row>

                <Formik
                    initialValues={this.state.formValues}
                    validate={this.validate(this.validationSchema.bind(this))}
                    onSubmit={(v, o) => {
                        this.onSubmit(v, o)
                    }}
                    render={
                        ({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             setFieldValue,
                             setFieldTouched}) => (
                            <Form onSubmit={(v) => {
                                this.scrollToFirstError();
                                return handleSubmit(v);
                            }}>
                                <TrailerTypeCard
                                    trailerType={this.state.trailerType}
                                    onLoadedTrailerChange={(e) => this.setState({trailerType: (e.target.checked ? "loaded" : "")})}
                                    onEmptyTrailerChange={(e) => this.setState({trailerType: (e.target.checked ? "empty" : "")})}
                                    onBobTailTrailerChange={(e) => {
                                        this.setState({trailerType: (e.target.checked ? "bobtail" : "")});
                                        //Clear value for departure trailer type
                                        if(values["departingType"] === "bobtail"){
                                            setFieldValue("departingType", "");
                                        }
                                    }}/>
                                {this.state.trailerType &&
                                <div className="mt-2">
                                    <ArrivalCard
                                        company={this.props.company}
                                        trailerType={this.state.trailerType}
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        values={values}
                                        fieldTouched={setFieldTouched}
                                        fieldValue={setFieldValue}
                                        optionLabel={(option) => option.name}
                                        onChange={(v) => this.setState({appointment: v})}
                                        value={this.state.appointment} displayInField={this.displayInField}
                                        onFormDateTimeChange={(v) => this.setState({date: v})}
                                        dateTimeValue={this.state.date}
                                        onClick={() => this.toggleSection("tractor")}
                                        className={this.classForSectionButton("tractor")}
                                        tractorOptionLabel={(option) => option.assetId}
                                        onTractorCardChange={(tractor) => {
                                            if (tractor) {
                                                setFieldValue("tractorLicense", tractor.license || "");
                                                setFieldValue("tractorLicenseState", tractor.licenseState || "");
                                            } else {/*could clear fields here, but maybe we don't want to*/
                                            }
                                        }}
                                        sectionOpen={this.sectionOpen("tractor")}
                                        onBobTailArrivalClick={() => this.toggleSection("trailer")}
                                        bobTailClassName={this.classForSectionButton("trailer")}
                                        onBobTailArrivalChange={(trailer) => {
                                            if (trailer) {
                                                setFieldValue("trailerLicense", trailer.license || "");
                                                setFieldValue("trailerLicenseState", trailer.licenseState || "");
                                                if(this.hasDepartingTrailer(values) && this.state.trailerType === "loaded" ){
                                                    setFieldValue("departingTrailerNumber", trailer.assetId);
                                                }
                                            } else {/*could clear fields here, but maybe we don't want to*/
                                            }
                                        }}
                                        driverSectionOpen={this.sectionOpen("trailer")}
                                        onDriverCardClick={() => this.toggleSection("driver")}
                                        driverCardClassName={this.classForSectionButton("driver")}
                                        onDriverCardChange={(driver) => {
                                            this.setDriverValues(driver, setFieldValue);
                                        }}
                                        onCountryCodeChange={(e)=>{
                                            setFieldValue("driverCountryCode", e.target.value);
                                        }}
                                        driverCardSectionOpen={this.sectionOpen("driver")}
                                        timezone={this.props.currentLocation.timezone}
                                        referenceTypes={this.props.user.company.shipmentReferenceTypeList}
                                        onReferenceNumberListChange={(list)=>{
                                            setFieldValue("shipmentReferenceNumbers", list);
                                            this.setState({shipmentReferenceNumbers: list})
                                        }}
                                        shipmentReferences={this.state.shipmentReferenceNumbers}
                                        referenceNumberFieldName={"shipmentReferenceNumbers"}
                                    />
                                    {this.props.user.company.smsEnabled &&
                                    <DepartureInformationCard
                                        options={this.departingOptions(values)}
                                        errors={errors}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched}
                                        values={values}
                                        fieldTouched={setFieldTouched}
                                        fieldValue={(field, value) => {
                                            setFieldValue(field, value);
                                            if ( this.state.trailerType === "empty" && value === "empty" && values.departingTrailerNumber === values.trailerNumber ) {
                                                setFieldValue("departingTrailerNumber", "");
                                            }
                                        }}>
                                        <div>
                                            {values.departingType && values.departingType === "loaded" &&
                                            <ReferenceNumberList
                                                title="Departing Shipment Reference Numbers"
                                                referenceNumbers={this.state.departingShipmentReferenceNumbers}
                                                referenceNumberOptions={
                                                    LoadedTrailerFormFields.getReferenceNumberOptions(
                                                        this.props.user.company.shipmentReferenceTypeList, this.props.user.company
                                                    )
                                                    || []
                                                }
                                                defaultReferenceType={this.props.user.company.shipmentIdDisplay}
                                                onChange={(refNums)=>{
                                                    this.setState({departingShipmentReferenceNumbers: refNums});
                                                    setFieldValue("departingShipmentReferenceNumbers", refNums);
                                                }}
                                                error={touched['departingShipmentReferenceNumbers'] && errors['departingShipmentReferenceNumbers']}
                                            />
                                            }
                                            {values.departingType && values.departingType === "loaded" &&
                                            (!this.props.user.company.workflowSettings ||
                                                !this.props.user.company.workflowSettings.disableMultiLocationTracking) &&
                                            <FormRadioField
                                                label="Destination Delivery Plan *"
                                                placeholder="Choose Value"
                                                options={[{name: "Live Unload", value: "live"}, {
                                                    name: "Drop Trailer",
                                                    value: "drop"
                                                }]}
                                                fieldname="destinationLiveDrop"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                icon="fa-dolly"
                                            />}
                                            {values.departingType && values.departingType === "loaded" &&
                                            (!this.props.user.company.workflowSettings ||
                                                !this.props.user.company.workflowSettings.disableMultiLocationTracking) &&
                                            <FormSelectLocationField
                                                label="Shipment Destination"
                                                placeholder="Enter Value"
                                                icon="fa-map-marked-alt"
                                                fieldname="departingDestinationLocationUuid"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                optionsFilter={(data)=>
                                                    data.filter((item)=>item.value !== this.props.currentLocation.uuid)
                                                }
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                            />
                                            }
                                            {values.departingType && values.departingType === "loaded" &&
                                            (!this.props.user.company.workflowSettings ||
                                                !this.props.user.company.workflowSettings.disableMultiLocationTracking) &&
                                            <FormDateTimeField
                                                label={"Delivery Appointment Time"}
                                                timezone={this.getLocationTimezone(
                                                    getLocationByUuid(values['departingDestinationLocationUuid'],
                                                        this.state.locationList))}
                                                placeholder="Enter Value"
                                                icon="fa-calendar"
                                                fieldname="departingAppointment"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                            />
                                            }
                                            {values.departingType !== "bobtail" &&
                                            <FormSelectCreatableField
                                                key={(values["broker"]||values["carrier"]) + "_" + values['trailerNumber']}
                                                defaultOptions
                                                label="Departing Trailer Number"
                                                subLabel="(Start typing to lookup trailer)"
                                                placeholder="Enter Value"
                                                icon="fa-hashtag"
                                                fieldname="departingTrailerNumber"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                getOptionLabel={(option)=>option.assetId}
                                                getOptionValue={(option)=>option.assetId}
                                                loadOptions={this.getLoadOptionsForDeparting(values)}
                                                onChange={(trailer)=>{
                                                    if ( trailer) {
                                                        setFieldValue("departingTrailerNumber", trailer.assetId);
                                                    } else {/*could clear fields here, but maybe we don't want to*/}
                                                }}
                                            />
                                            }
                                        </div>
                                    </DepartureInformationCard>}
                                    <Button color="primary" type="submit">Save Arrival</Button>
                                </div>}
                            </Form>)}>
                </Formik>
            </div>);
    }

    getLoadOptionsForDeparting(values) {
        if ((values["broker"] || values["carrier"]) && values['liveDrop'] ==='drop') {
            return promiseTrailerByCarrierAndIdPrefixWithFilter(values["broker"] || values["carrier"], values['trailerNumber']);
        }else if(values['liveDrop'] ==='drop'){
            return promiseTrailerByIdPrefixWithFilter(values['trailerNumber']);
        } else if (values["broker"] || values["carrier"]) {
            return promiseTrailerByCarrierAndIdPrefix(values["broker"]|| values["carrier"])
        } else {
            return promiseTrailerByIdPrefix
        }
    }

    getLocationTimezone(location) {
        return location && location.timezone;
    }

    hasDepartingTrailer(values) {
        return (values.liveDrop === "live" && this.state.trailerType === "loaded")
            || (this.state.trailerType === "empty" && values.departingType !== "empty");
    }

    setDriverValues(driver, setFieldValue) {
        if (driver && !driver.__isNew__) {
            setFieldValue("driverFirstname", driver.firstname);
            setFieldValue("driverLastname", driver.lastname);
            setFieldValue("driverCdl", driver.cdl);
            setFieldValue("driverState", driver.cdlState);
            setFieldValue("driverHasSmartPhone", driver.hasSmartPhone ? "Yes" : "No");
            setFieldValue("driverUuid", driver.uuid);
            setFieldValue("driverCountryCode", driver.countryCode);
        } else {
            /*could clear fields here, but maybe we don't want to*/
        }
    }
}



function mapStateToProps(state) {
    return {
        currentLocation: state.login.location,
        user: state.login.user
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setCurrentLocation: function(location) {
            dispatch(storeCurrentLocation(location));
        }
    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Arrival));
