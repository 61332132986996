import * as React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import PingAssetEdit from "./PingAssetEdit";
import { exportAssetsToExcel } from "../../NetworkHelpers";
import { importEZTrackAssetFile, promiseCarriersByPrefixIncludingSelf, saveNewPingAsset } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { ITEMS_PER_PAGE_DEFAULT } from "../../common/constants";
import { FileDropModal } from "../../common/FileDropModal";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class PingAssets extends React.Component {
  tableColumns = [
    {
      Header: 'Trailer Name',
      accessor: 'trailerName',
      sortable: true,
      filterable: true
    },
    {
      Header: 'Trailer Number',
      accessor: 'trailerNumber',
      sortable: true,
      filterable: true,
      Cell: (row) => {
        return row.original.trailerNumber ? row.original.trailerNumber.replace(/ /g, '').toUpperCase() : "";
      },
    },
    {
      Header: 'Device ID',
      accessor: 'imei',
      sortable: false,
      filterable: true,
      Cell: row => {
        return row.original.devices.map((d, idx) => { return idx !== row.original.devices.length - 1 ? d.imei + ", " : d.imei })
      },

    },
    {
      Header: 'Device',
      accessor: 'trackerType',
      sortable: false,
      filterable: true,
      Cell: row => {
        return row.original.devices.map((d, idx) => { return idx !== row.original.devices.length - 1 ? d.trackerType + ", " : d.trackerType })
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.trackerTypeFilterRef}
            onChange={event => {
              this.setState({ trackerTypeFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.trackerTypeFilter ? this.state.trackerTypeFilter : undefined}
          >
            <option value="undefined" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="MT9090">MT9090</option>
            <option value="PG2400">PG2400</option>
          </select>
        </>
    },
    {
      Header: 'Tracking Frequency',
      accessor: 'ezTrackTrackingFrequency.displayName',
      sortable: false,
      filterable: true,
      Cell: row => {
        return row.original.devices.map((d, idx) => {
          if (d.ezTrackTrackingFrequency === null) {
            return null;
          } else {
            return idx !== row.original.devices.length
              - 1 ? d.ezTrackTrackingFrequency.displayName + ", " : d.ezTrackTrackingFrequency.displayName
          }
        })
      },

    },
    {
      Header: 'Equipment Type',
      accessor: 'equipmentType',
      sortable: true,
      filterable: true
    },
    {
      Header: 'Domicile Name',
      accessor: 'domicileName',
      filterable: true,
      sortable: true,
    },
    {
      Header: 'Battery',
      accessor: 'battery',
      filterable: false,
      sortable: true,
      Cell: row => {
        let bat = row.original.battery;
        if (bat == null) {
          return null;
        }
        else if (bat >= 95.0) {
          return <span>{bat}% <i className="fa fa-battery-full">&nbsp;</i></span>
        }
        else if (bat >= 75.0) {
          return <span>{bat}% <i className="fa fa-battery-three-quarters">&nbsp;</i></span>
        }
        else if (bat >= 50.0) {
          return <span>{bat}% <i className="fa fa-battery-half">&nbsp;</i></span>
        }
        else if (bat >= 25.0) {
          return <span>{bat}% <i className="fa fa-battery-quarter">&nbsp;</i></span>
        }
        else if (bat <= 25.0) {
          return <span>{bat}% <i className="fa fa-battery-empty">&nbsp;</i></span>
        } else {
          return <span>{bat}% <i className="fa fa-battery-empty">&nbsp;</i></span>
        }
      }
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      filterable: true,
      sortable: true,
      Cell: row => {
        return row.original.enabled ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={event => {
              this.setState({ enabledFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.enabledFilter ? this.state.enabledFilter : undefined}
          >
            <option value="undefined" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },
    {
      Header: 'Action',
      accessor: 'action',
      sortable: false,
      filterable: false,
      Cell: row => {
        return <Button title="Edit Asset" aria-label="Edit Asset"
          onClick={() => this.editAsset(row.original)}><i
            className="fa fa-edit" /></Button>
      }
    }
  ];
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.enabledFilterRef = React.createRef();
    this.trackerTypeFilterRef = React.createRef();
    let parse = localStorage.getItem('eztrack-admin-assets');
    if (parse) {
      this.state = {
        previousSearchRequest: parse.previousSearchRequest,
        pages: parse.pages,
        loading: false,
        autoRefreshEnabled: parse.autoRefreshEnabled,
        itemsPerPage: parse.itemsPerPage
      };
    }
    else {
      this.state = {
        previousSearchRequest: {},
        pages: -1,
        loading: false,
        autoRefreshEnabled: true,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT
      };
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    promiseCarriersByPrefixIncludingSelf(this.props.user)().then((success) => {
      let selfCarriers = success.filter((c) => { return c.self === true });
      let selfCarrier = null;
      if (selfCarriers.length > 0) {
        selfCarrier = selfCarriers[0];
      }
      this.setState({ selfCarrier: selfCarrier });
    })
  }
  componentWillUnmount() {
    localStorage.setItem("eztrack-admin-assets", JSON.stringify(this.state))
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company.uuid !== this.props.company.uuid) {
      this.tableRef.current.fetchData();
    }
  }
  startNewAsset() {
    this.setState({
      editingAsset: {
        carrier: this.state.selfCarrier,
        assetId: "",
        imei: "",
        equipmentType: "",
        domicile: "",
        isNew: true,
        enabled: true,
        devices: [],
      }
    });
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  renderModal() {
    return (
      <PingAssetEdit
        loadedAssets={this.state.loadedAssets}

        assetUnderEdit={this.state.editingAsset}
        loggedInUser={this.props.user}
        cancel={() => this.cancelDialog()}
        save={(a, onSuccess, onError) => {
          this.setState({ loadedAssets: true })
          this.saveAsset(a, onSuccess, onError);
        }}
        onChange={(state) => {
          this.setState({ editingAsset: state })
        }}
      />
    );
  }
  cancelDialog() {
    this.setState({ editingAsset: {} });
  }
  editAsset(pingAsset) {
    if (pingAsset.domicile) {
      pingAsset.domicile.label = pingAsset.domicile.name;
      pingAsset.domicile.value = pingAsset.domicile.uuid;
    }
    let asset = {
      uuid: pingAsset.assetUuid,
      carrier: {
        uuid: pingAsset.carrierUuid,
        name: pingAsset.trailerName,
      },
      assetId: pingAsset.trailerNumber,
      domicile: {
        uuid: pingAsset.domicileUuid,
        name: pingAsset.domicileName,
        label: pingAsset.domicileName,
        value: pingAsset.domicileUuid
      },
      equipment: pingAsset.equipment,
      devices: pingAsset.devices,
      enabled: pingAsset.enabled,
      // pwaEnabled : pingAsset.pwaEnabled

    }
    this.setState({ editingAsset: asset })
  }
  saveAsset(asset, onSuccess, onError) {
    saveNewPingAsset(asset, (response) => {
      this.setState({
        loadedAssets: false
      })
      if (response.code === '1000') {
        showError(response.message);
      } else {
        showInfo("Asset Saved");
        this.tableRef.current.fetchData();
        this.setState({ editingAsset: { devices: [] } });
        this.props.onChange(this.state);
        onSuccess();
      }
    },
      (failure) => {
        showError(failure.message);
        this.setState({
          loadedAssets: false
        })
        onError();
      });
  }
  clearFilters() {
    this.trackerTypeFilterRef.current.value = undefined;
    this.enabledFilterRef.current.value = undefined;
    this.setState({
      enabledFilter: undefined,
      trackerTypeFilter: undefined
    })
  }
  render() {
    return (
      <div>
        <div className="column-wrapper text-left">
          <Modal isOpen={this.state.showImportInstructions}>
            <ModalHeader>
              Import Instructions
            </ModalHeader>
            <ModalBody>
              It is possible to import asset data via a file in CSV format or XLS format.
              To get started with importing assets, click the
              Download Import Template to obtain a sample CSV file.
              Add one row per device that you wish to import.  To import
              an asset with multiple devices, use one row per device.  Duplicate
              the asset details on each row where the device should be assigned
              to the same asset.<br />
              <br />
              Note that your company must have the EZTrack module enabled to
              import assets with EZTrack devices.  Leave the device specific
              fields (zone, Device, Tracking Frequency and Device ID) blank
              to import an asset without EZTrack devices.<br />
              <br />
              The following are the list of columns that should be included.
              Any fields with an asterisk are required and must be non blank.
              Any fields that contain a comma must be enclosed in quotes.<br />
              <br />
              Carrier* (Case sensitive name, must already exist)<br />
              Asset ID*<br />
              Zone Number (A number starting at 1 to indicate the position of the zone)<br />
              Device (MT9090 or PG2400)<br />
              Tracking Frequency (Case sensitive name, leave blank for MT devices)<br />
              Device ID<br />
              Equipment Type* (case insensitive name, must already exist)<br />
              Domicile* (case insensitive name, must already exist)<br />
              Enabled (True or False)<br />
              <div style={{ marginTop: '10px' }} className={'text-right'}>
                <Button color="primary" onClick={() => this.setState({
                  showImportInstructions: false
                })}>Close</Button>
              </div>
            </ModalBody>
          </Modal>
          <FileDropModal
            open={this.state.importModalShow}
            error={this.state.importModalError}
            header={"Import Assets"}
            onDrop={acceptedFiles => {
              for (const file of acceptedFiles) {
                if (file['type'] && !file['type'].includes('text')) {
                  //Or is it excel?
                  if (file['type'] && !(file['type'].includes('excel') || file['type'].includes('openxml'))) {
                    this.setState({ importModalError: "File must be text" });
                    return;
                  }
                }
                importEZTrackAssetFile(file, (res) => {
                  let response = res.content;
                  this.setState({ importModalShow: false });
                  showInfo(response.message + " in " + response.seconds + " seconds.");
                  this.tableRef.current.fetchData();
                }, (response) => {
                  this.setState({ importModalError: response.message });
                }, () => {
                  this.props.history.push("/");
                })
              }
            }}
            onClose={() => {
              this.setState({
                importModalError: false,
                importModalShow: false
              });

            }}
          />
          <Loader show={this.state.loaded} message={this.spinner}>
            <PaginationFilterTable
              setloaded={(check) => {
                this.setState({ loaded: check });
                this.updateSpinner("");
              }} openFiltersCollaps={true}
              ref={this.tableRef}
              get={this.props.get}
              stateStorage="eztrack-admin-assets"
              pageType="Admin"
              clearFilter={this.clearFilters.bind(this)}
              loading={this.state.loading}
              renderModal={this.renderModal()}
              columns={this.tableColumns}
              tableData={this.state.tableData}
              pageCount={this.state.pages}
              onFilterHide={() => {
                this.setState({
                  enabledFilter: "undefined",
                });
              }}
              successCallback={
                (res) => {
                  this.setState({
                    previousSearchRequest: res.request
                  });
                }
              }
              footerButton={
                <Button color="secondary" onClick={() => this.exportAssets()}><i className="fa fa-file-excel" /> Export Assets to Excel</Button>
              }
            >
              <Button color="primary" onClick={() => this.startNewAsset()}> <i className="fa fa-plus" /> Add New Asset </Button>
              <Button style={{ marginLeft: '10px' }} color="secondary" className={"btn-sm"} onClick={() => {
                this.setState({ importModalShow: true });
              }}>
                <i className="fa fa-file-import" /> Import
              </Button>
              <a style={{ marginLeft: '10px' }} href={"/assets/files/eztrack-asset-import-format.csv"}>Download Import Template</a>
              <a style={{ marginLeft: '10px' }} href={"#"} onClick={(e) => {
                e.preventDefault();
                this.setState({ showImportInstructions: true })
              }}>Import Instructions</a>
            </PaginationFilterTable>
          </Loader>
        </div>

      </div>
    )
  }
  exportAssets() {
    // const parse = JSON.parse(localStorage.getItem("eztrack-admin-assets-pft"));
    // if(parse){
    //     this.state = {
    //         previousSearchRequest: {
    //             page:parse.page,
    //             pageSize:parse.pageSize,
    //             sorted:parse.sorted,
    //             filtered:parse.filtered
    //         }

    //     };
    // }
    if (this.state.previousSearchRequest) {
      exportAssetsToExcel(this.state.previousSearchRequest, 'assets-export.xlsx', (response) => {
        if (response.emailSent) {
          showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
        }
      }, (e) => {
        console.log(e);
        showError("We are not able to generate the excel file at this time.  Please try again later.");
      });
    }
  }
}
export default PingAssets;
