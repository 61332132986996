import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import {
  convertCtoF,
  convertFtoC,
  getErrorsFromValidationError,
} from "../../common/utilities";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import * as PropTypes from "prop-types";
import {
  downloadTaskByUuid,
  getDefaultEZTrackEquipment,
  promiseAssetByPrefix,
  promiseAssetStatusByPrefix,
  promiseCarriersByPrefix,
  promiseEZTrackEquipmentTypeByPrefix,
  promiseSpotForLocationByZonePrefixOrSpotPrefix,
  promiseTaskTypeByPrefix,
  promiseUserByLocation,
  saveTask,
  searchLocations,
  downloadEZTrackEquipmentForTable,
  putTaskRemoveAssignee,
  putTaskStart,
  putTaskEnd,
  putTaskCancel,
  promiseCancelReasonSearch,
  getDefaultCancelReasonCode,
} from "../../NetworkHelpers";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import { showError, showInfo } from "../../MainApp";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import DatePicker from "react-datepicker";
import moment from "moment";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import HelpIcon from "../../common/HelpIcon";


class TemperatureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setpoint: "",
      actual: "",
      taskUnderEdit: this.props.taskUnderEdit,
      errors: {},
      touched: {},
      loadedTemp: false,
    };
    this.updateSpinner("Saving...");
  }
//   componentDidUpdate(prevProps, prevState, snapshot) {
//     this.state.taskUnderEdit =this.props.taskUnderEdit;
    
  
// }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{"Saving..."}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    if (values.assignDate && values.assignDate === "Invalid Date") {
      values.assignDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      // type: Yup.string().required("Type is required."),
      // moveTo: Yup.object().required("Move To is required."),
      asset: Yup.object().nullable(),
      //   moveFrom: Yup.object().nullable(),
      boxingAssets: Yup.number()
        .typeError("Number must be between 1 to 9")
        .positive("Number must be between 1 to 9")
        .max(9, ({ max }) => "Number must be between 1 to 9")
        .min(1, ({ min }) => "Number must be between 1 to 9")
        .integer("Number must be between 1 to 9")
        .nullable(),
      assignee: Yup.object().nullable(),
      tempSetPoint: Yup.string()
        .max(999.999, ({ max }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .min(-999.999, ({ min }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,3})?)?$/, 'Invalid Entry, maximum of three digits before or after the decimal (999.999)')
        .nullable(true)
        .transform((_, val) => val === val ? val : null),
      actualTemp: Yup.string()
        .max(999.999, ({ max }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .min(-999.999, ({ min }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,3})?)?$/, 'Invalid Entry, maximum of three digits before or after the decimal (999.999)')
        .nullable(true)
        .transform((_, val) => val === val ? val : null),



      //   moveTo: Yup.object()
      //     .nullable()
      //     .test("equal", "Move To must not equal Move From", function (v) {
      //       const ref = Yup.ref("moveFrom");
      //       if (v == null) {
      //         return true;
      //       }
      //       if (this.resolve(ref) != null) {
      //         return v.uuid !== this.resolve(ref).uuid;
      //       } else {
      //         return true;
      //       }
      //     }),
      assignDate: Yup.string()
        .max(
          new Date(),
          ({ max }) =>
            `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
        ),
      // assignDate: Yup.date()
      //   .nullable()
      //   .max(
      //     new Date(),
      //     ({ max }) =>
      //       `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
      //   ),
      // start: Yup.date()
      //   .nullable()
      //   .min(
      //     Yup.ref("assignDate"),
      //     ({ min }) =>
      //       `Task Start Timestamp needs to be after the assignment time.`
      //   ),
      // end: Yup.date()
      //   .nullable()
      //   .min(
      //     Yup.ref("start"),
      //     ({ min }) =>
      //       `Task End Timestamp needs to be after the Task Start Timestamp.`
      //   ),
    };
    return Yup.object().shape(validationRules);
  }
  render() {
    return (
     
        <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.state.loadedTemp} message={this.spinner}>
            <ModalHeader>Temperature Controlled</ModalHeader>
            <ModalBody className={"bg-white"}>

            <Container>
              <FFFormTextField
                fieldname="tempSetPoint"
                onBlur={() => {
                  let errors = this.validate(
                    this.validationSchema.bind(this)
                  )(this.state.taskUnderEdit);
                  let touched = { ...this.state.touched };
                  touched.tempSetPoint = true;
                  this.setState({ errors: errors, touched: touched });
                }}
                error={this.state.errors.tempSetPoint}
                touched={this.state.touched.tempSetPoint}
                label={
                  "Temp - Set Point (" +
                  (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit"
                    ? "℉"
                    : "℃") +
                  ")"
                }
                placeholder="Enter the Set Point temperature"
                icon="fa-temperature-high"
                defaultValue={this.props.taskUnderEdit.tempSetPoint}
                numbersOnly={true}
                required={false}
                onChange={(v) => {
                  let s = { ...this.state.taskUnderEdit };
                  s.tempSetPoint = v;
                  this.setState({ taskUnderEdit: s });
                }}
              />

              <FFFormTextField
                fieldname="actualTemp"
                label={
                  "Temp - Actual (" +
                  (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit"
                    ? "℉"
                    : "℃") +
                  ")"
                }
                onBlur={() => {
                  let errors = this.validate(
                    this.validationSchema.bind(this)
                  )(this.state.taskUnderEdit);
                  let touched = { ...this.state.touched };
                  touched.actualTemp = true;
                  this.setState({ errors: errors, touched: touched });
                }}
                error={this.state.errors.actualTemp}
                touched={this.state.touched.actualTemp}
                placeholder="Enter the actual temperature"
                icon="fa-temperature-high"
                defaultValue={this.props.taskUnderEdit.actualTemp}
                numbersOnly={true}
                required={false}
                valid={true}
                invalid={false}
                onChange={(v) => {
                  let s = { ...this.state.taskUnderEdit };
                  s.actualTemp = v;
                  this.setState({ taskUnderEdit: s });
                }}
              />

              <Row>
                <Col className="text-right" style={{ marginTop: "10px" }}>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.state.taskUnderEdit.tempSetPoint = undefined;
                      this.state.taskUnderEdit.actualTemp = undefined;

                      this.setState({ touched: [], errors: [] });
                      this.props.onCancel && this.props.onCancel()
                    }
                    }
                  >
                    Cancel
                  </Button>{" "}
                  &nbsp;
                  <Button
                    color="primary"
                    disabled={this.props.taskUnderEdit.type === undefined || this.props.taskUnderEdit.type === null || this.props.taskUnderEdit.asset === null || this.props.taskUnderEdit.asset === undefined || this.props.taskUnderEdit.carrier === undefined || this.props.taskUnderEdit.carrier === null  || this.props.taskUnderEdit.moveTo === null || this.props.taskUnderEdit.moveTo === undefined}
                    onClick={() =>{
                      this.updateSpinner("Saving...");
                      this.setState({
                        loadedTemp : true
                      });
                      this.onSubmit();
                    }}
                    
                  >
                    Save
                  </Button>
                  <HelpIcon helpMessage={"Do not leave Task Type, Asset ID or Move To field/fields empty."} />
                  
                </Col>
              </Row>
            </Container>

          </ModalBody>
          </Loader>
        </Modal>
      
    );
  }

  onSubmit() {
    this.setState({loadedTemp : true});
    let errors = this.validate(this.validationSchema.bind(this))(
      this.state.taskUnderEdit
    );

    let errorsIsEmpty =
      Object.keys(errors).length === 0 &&
      errors.constructor === Object;
    if (!errorsIsEmpty || this.state.errors.name) {
      let t = { ...this.state.touched };
      t.tempSetPoint = true;
      t.actualTemp = true;
       
      this.setState({ touched: t, errors: errors,loadedTemp: false });
      return;
    }
    if (this.state.taskUnderEdit.actualTemp === undefined) {
      let s = this.props.taskUnderEdit;
      this.state.taskUnderEdit.actualTemp = s.actualTemp;
    }
    if (this.state.taskUnderEdit.tempSetPoint === undefined) {
      let s = this.props.taskUnderEdit;
      this.state.taskUnderEdit.tempSetPoint = s.tempSetPoint;
    }
    
    return (
      this.props.onSave &&
      this.props.onSave(this.state && this.state.taskUnderEdit)
    );

  }
}

TemperatureModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
class CommentTaskModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      loadedComment: false,
      taskUnderEdit: props.taskUnderEdit, 
      errors: {}, touched: {} 
    };
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   this.state.taskUnderEdit =this.props.taskUnderEdit;
      
    
  // }
  
  getNumber(textCharacterLimit) {
    let value = this.state.taskUnderEdit.comments;

    return textCharacterLimit - (value ? value.length : 0);
  }
  render() {
    return (
      
      <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.state.loadedComment} message={this.spinner}>
          <ModalHeader>Add Comments</ModalHeader>
          <ModalBody className={"bg-white"}>
            <Container>
              <FFFormTextAreaField
                label="Comments"
                placeholder="Add Comments"
                icon="fa-comment"
                fieldname="comments"
                defaultValue={this.props.taskUnderEdit.comments}
                onChange={(event) => {
                  let s = { ...this.state.taskUnderEdit };
                  s.comments = event.target.value;
                  this.setState({ taskUnderEdit: s });
                }}
                rows={"5"}
              />
              <Row>
                <Col>
                  <p
                    className={"float-right"}
                    style={{
                      color: this.getNumber(300) < 0 ? "red" : null,
                    }}
                  >
                    Text remaining: {this.getNumber(300)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="text-right" style={{ marginTop: "10px" }}>
                  <Button
                    color="danger"
                    onClick={() => {
                      this.state.taskUnderEdit.comments = this.props.taskUnderEdit.comments;
                      this.props.onCancel && this.props.onCancel()
                    }}
                  >
                    Cancel
                  </Button>{" "}
                  &nbsp;
                  <Button
                    color="primary"
                    disabled={!(this.getNumber(300) >= 0) || this.props.taskUnderEdit.type === undefined || this.props.taskUnderEdit.type === null || this.props.taskUnderEdit.asset === null || this.props.taskUnderEdit.asset === undefined || this.props.taskUnderEdit.carrier === undefined || this.props.taskUnderEdit.carrier === null  || this.props.taskUnderEdit.moveTo === null || this.props.taskUnderEdit.moveTo === undefined}
                    onClick={() => {
                      this.updateSpinner("Saving ...");
                      
                      this.setState({
                        loadedComment : true
                      });
                      this.onSubmit();
                    }}
                  >
                    Save
                  </Button>
                  <HelpIcon helpMessage={"Do not leave Task Type, Asset ID or Move To field/fields empty."} />
              
                </Col>
              </Row>
            </Container>
          </ModalBody>
          </Loader>
        </Modal>
      
    );
  }

  onSubmit() {
    
    if (this.state.taskUnderEdit.comments === undefined) {
      let s = this.props.taskUnderEdit;
      this.state.taskUnderEdit.comments = s.comments;
    }
    this.setState({
      loaded : false
    });
    return (
      this.props.onSave &&
      this.props.onSave(this.state && this.state.taskUnderEdit)
    );
  }
}

CommentTaskModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export const getTemp = function (row, setPoint, user) {
  let temperatureInC = row.original.temperatureInC;

  if (temperatureInC == null) {
    return "";
  }

  if (setPoint != null) {
    if (isF(user)) {
      let tempInF = convertCtoF(temperatureInC);
      let setPointInF = convertCtoF(setPoint);
      tempInF -= setPointInF;
      return tempInF.toFixed(1) + " ℉";
    } else {
      temperatureInC -= setPoint;
      return temperatureInC.toFixed(1) + " ℃";
    }
  }
  if (isF(user)) {
    return convertCtoF(temperatureInC).toFixed(1) + " ℉";
  } else {
    return temperatureInC.toFixed(1) + " ℃";
  }
};
export const isF = function (user) {
  return (
    user &&
    user.company &&
    user.company.temperatureUnitsOfMeasure &&
    user.company.temperatureUnitsOfMeasure === "Fahrenheit"
  );
};

class AssetForm extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      topggleCheck: false,
      taskUnderEdit: {
        name: "",
        location: this.props.currentLocation,
        enabled: true,
        isNew: true,
      },
      errors: {},
      loaded: false,
      touched: {},
      showSpotHistory: true,
      defaultEquipmentType: {},
      allLocations: [],
      allEquipmentTypes: [],
      closeCheck: false,
      taskProgressTypeChecker: "",
    };
    
    getDefaultEZTrackEquipment(this.props.currentLocation.uuid, (def) => {
      this.setState({
        defaultEquipmentType: def,
      });
    });
    if (this.props.match.params.uuid !== "new") {
      this.loadTask(this.props.match.params.uuid);
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let searchRequest = { page: 0, pageSize: 50, sorted: [], filtered: [] };
    downloadEZTrackEquipmentForTable(searchRequest, (response) => {
      this.setState({ allEquipmentTypes: response.content });
    });
    searchLocations(searchRequest, (response) => {
      this.setState({ allLocations: response.content });
    });

    promiseTaskTypeByPrefix("").then((results) => {

      results = results.filter(it => (it.name).toLocaleLowerCase() === "add asset" || (it.name).toLocaleLowerCase() === "check-in asset");
      let index = results.findIndex(it => (it.name).toLocaleLowerCase() === "add asset");
      this.setState({
        taskTypeOptions: results,
       


      });
      if (this.props.match.params.uuid === "new") {
         this.setState({taskUnderEdit: {
          type: results[index],
          created: new Date().getTime(),
          assignDate: new Date().getTime(),
          start: new Date().getTime()+60000,
        }});
      }
    });
    promiseAssetStatusByPrefix("").then((results) => {
      let task = { ...this.state.taskUnderEdit };
      if (!task.assetStatus) {
        task.assetStatus = results.filter((t) => t.isDefault)[0];
      }
      this.setState({ assetStatusOptions: results, taskUnderEdit: task });
    });
  }
  getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }
  loadTask(uuid) {
    downloadTaskByUuid(
      uuid,
      (success) => {
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
          if (success.tempSetPoint !== null) {
            success.tempSetPoint = this.getFlooredFixed(success.tempSetPoint, 1);
          }
          success.actualTemp = convertCtoF(success.temperatureInC);
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          success.tempSetPoint = success.temperatureSetPointInC;
          if (success.temperatureSetPointInC !== null) {
            success.tempSetPoint = this.getFlooredFixed(success.tempSetPoint, 1);
          }
          success.actualTemp = success.temperatureInC;
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        //   if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
        //     success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
        //     success.actualTemp = convertCtoF(success.temperatureInC);
        // }
        // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
        //     success.tempSetPoint = success.temperatureSetPointInC;
        //     success.actualTemp = success.temperatureInC;
        // }
        if (success.assignDate) {
          success.assignDate = new Date(success.assignDate);
        }
        if (success.start) {
          success.start = new Date(success.start);
        }
        if (success.end) {
          success.end = new Date(success.end);
        }
        this.setState({ taskUnderEdit: success });
      },
      (failure) => {
        showError("Unable to access task.");
      }
    );
  }
  cancelEdit() {
    this.props.history.push("/poweryard-onsite");
  }
  onSubmit(val, taskObj) {
    this.updateSpinner("Saving...");
    
    let task = {};
    if (taskObj) {
      if (taskObj.type === "temperature") {
        task = { ...this.state.taskUnderEdit };
        task.tempSetPoint = taskObj.tempSetPoint;
        task.actualTemp = taskObj.actualTemp;
      } else {
        task = { ...this.state.taskUnderEdit };
        task.comments = taskObj.comments;
      }
    } else {
      task = { ...this.state.taskUnderEdit };
    }
    if (task.asset != null) {
      task.asset.carrier = task.carrier;
      if (task.asset.equipment === undefined || task.asset.equipment === "") {
        task.asset.equipment = null;
        task.asset["@type"] = this.state.defaultEquipmentType.equipmentType;
        task.asset.equipment = this.state.defaultEquipmentType;
      } else if (
        task.asset.equipment !== null &&
        task.asset.equipment.baseEquipment !== null &&
        task.asset.equipment.baseEquipment !== undefined
      ) {
        task.asset["@type"] = task.asset.equipment.baseEquipment.equipmentType;
      }

      if (task.asset.__isNew__ || !task.asset.domicile) {
        task.asset.domicile = this.props.currentLocation;
      }
    } else {
      if (task.assetType) {
        task.asset = {
          "@type": task.assetType.baseEquipment.equipmentType,
          domicile: this.props.currentLocation,
          carrier: task.carrier,
          equipment: task.assetType,
        };
      }
    }
    if (task.carrier && task.asset == null) {
      task.asset = {
        "@type": this.state.defaultEquipmentType.equipmentType,
        equipment: this.state.defaultEquipmentType,
        domicile: this.props.currentLocation,
        carrier: task.carrier,
      };
    }
    if (navigator.geolocation) {
      let positionOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60 * 60 * 1000,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          task.latitude = position.coords.latitude;
          task.longitude = position.coords.longitude;
        },
        (error) => {
          //ignore errors
        },
        positionOptions
      );
    }
    if (task.actualTemp === "") {
      task.actualTemp = null;
    }
    if (task.tempSetPoint === "") {
      task.tempSetPoint = null;
    }
    if (
      this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
      this.props.company.temperatureUnitsOfMeasure !== "Celsius"
    ) {
      task.temperatureSetPointInC = convertFtoC(task.tempSetPoint);
      task.temperatureInC = convertFtoC(task.actualTemp);
      this.setState({ taskUnderEdit: task });
    }
    if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
      task.temperatureSetPointInC = task.tempSetPoint;
      task.temperatureInC = task.actualTemp;
      this.setState({ taskUnderEdit: task });
    }
    task.company = this.props.company;
    task.end = new Date().getTime();
    task.assignee = this.props.user;
    task.location = this.props.currentLocation;
    saveTask(
      task,
      (res) => {
        this.setState({ touched: {} });
        
        if (val === "temp") {
          showInfo("Temperature saved.");
          this.setState({ temperatureModalOpen: false });
          this.props.history.push("/poweryard/assetDetail/"+res.uuid);
        } else if (val === "comment") {
          showInfo("Comment saved.");
          this.setState({ commentTaskModalOpen: false });
          this.props.history.push("/poweryard/assetDetail/"+res.uuid);
        } else if (val === true) {
          showInfo("Task saved.");
          this.props.history.push("/poweryard-onsite");
        } else {
          showInfo("Task saved.");
          this.getLocationFireEvent(
            this.state.taskUnderEdit.uuid,
            this.state.taskProgressTypeChecker,
            this.props.company,
            this.props.currentLocation.uuid
          );
        }
        this.setState({ closeCheck: false, taskProgressTypeChecker: ""});
      },
      () => {
        showError("Unable to save task.");
      }
    );
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    if (values.assignDate && values.assignDate === "Invalid Date") {
      values.assignDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      type: Yup.string().required("Type is required."),
      asset: Yup.object().nullable(),
      //   moveFrom: Yup.object().nullable(),
        moveTo: Yup.object().required("Move To is required."),
      boxingAssets: Yup.number()
        .typeError("Number must be between 1 to 9")
        .positive("Number must be between 1 to 9")
        .max(9, ({ max }) => "Number must be between 1 to 9")
        .min(1, ({ min }) => "Number must be between 1 to 9")
        .integer("Number must be between 1 to 9")
        .nullable(),
      assignee: Yup.object().nullable(),
      tempSetPoint: Yup.string()
        .max(999.999, ({ max }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .min(-999.999, ({ min }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        // /^-?\d{1,3}(\.\d{1,3})?$/
        .matches(/^(?:^-?\d{1,3}(\.\d{1,3})?)?$/, 'Invalid Entry, maximum of three digits before or after the decimal (999.999)')
        .nullable()
        .transform((_, val) => val === val ? val : null),
      actualTemp: Yup.string()
        .max(999.999, ({ max }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .min(-999.999, ({ min }) => "Invalid Entry, maximum of three digits before or after the decimal (999.999)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,3})?)?$/, 'Invalid Entry, maximum of three digits before or after the decimal (999.999)')
        .nullable()
        .transform((_, val) => val === val ? val : null),

      //   moveTo: Yup.object()
      //     .nullable()
      //     .test("equal", "Move To must not equal Move From", function (v) {
      //       const ref = Yup.ref("moveFrom");
      //       if (v == null) {
      //         return true;
      //       }
      //       if (this.resolve(ref) != null) {
      //         return v.uuid !== this.resolve(ref).uuid;
      //       } else {
      //         return true;
      //       }
      //     }),
      assignDate: Yup.string()
        .max(
          new Date(),
          ({ max }) =>
            `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
        ),
      // assignDate: Yup.date()
      //   .nullable()
      //   .max(
      //     new Date(),
      //     ({ max }) =>
      //       `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
      //   ),
      // start: Yup.date()
      //   .nullable()
      //   .min(
      //     Yup.ref("assignDate"),
      //     ({ min }) =>
      //       `Task Start Timestamp needs to be after the assignment time.`
      //   ),
      // end: Yup.date()
      //   .nullable()
      //   .min(
      //     Yup.ref("start"),
      //     ({ min }) =>
      //       `Task End Timestamp needs to be after the Task Start Timestamp.`
      //   ),
    };
    return Yup.object().shape(validationRules);
  }
  getLocationFireEvent(id, val, value, user, currentlocationUuid) {
    if (navigator.geolocation) {
      let latestLocation = { latitude: "", longitude: "" };
      let positionOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60 * 60 * 1000,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          latestLocation.latitude = position.coords.latitude;
          latestLocation.longitude = position.coords.longitude;
          latestLocation.assignee = value;
          let locationobj = this.state.allLocations.find(
            (it) => it.uuid === currentlocationUuid
          );
          let scopedObj = {
            locationobj: locationobj,
            user: user,
            history: this.props.history,
            state: this.state.taskUnderEdit,
          };

          if (val === "start") {
            putTaskStart(id, latestLocation, scopedObj);
            setTimeout(() => {
              this.loadTask(id);
            }, 3000);
          }
          if (val === "end") {
            putTaskEnd(id, latestLocation, scopedObj);
            setTimeout(() => {
              this.loadTask(id);
            }, 3000);
          }
          if (val === "cancel") {
            const data = {
              latitude: latestLocation.latitude,
              longitude: latestLocation.longitude,
              cancelReasonCode: value,
            };

            putTaskCancel(
              this.state.taskUnderEdit.uuid,
              data,
              () => {
                this.setState({ cancelModalOpen: false });
                this.props.history.push("/poweryard-tasks");
              },
              () => { }
            );
          }
        },
        (error) => {
          //ignore errors
        },
        positionOptions
      );
    }
  }

  timezoneShortCode(timezone, inp) {
    if (!inp) inp = new Date();
    if (timezone) {
      return moment(inp).tz(timezone).format("z");
    } else {
      return moment(inp).tz(moment.tz.guess()).format("z");
    }
  }
  taskProgressChecker(val) {
    this.setState({ taskProgressTypeChecker: val });
  }
  render() {
    return (
      <Loader show={this.state.loaded} message={this.spinner}>
      <div className="text-left p-5">
     
        <TemperatureModal
          open={this.state.temperatureModalOpen}
          company={this.props.company}
          taskUnderEdit={this.state.taskUnderEdit}
          onSave={(taskUnderEdit) => {
            taskUnderEdit.type = "temperature";
            let errors = this.validate(this.validationSchema.bind(this))(
              this.state.taskUnderEdit
            );
            let closeCheck = this.state.closeCheck;
            let errorsIsEmpty =
              Object.keys(errors).length === 0 && errors.constructor === Object;
            if (!errorsIsEmpty || this.state.errors.name) {
              let t = { ...this.state.touched };
              t.actualTemp = true;
              t.tempSetPoint = true;
              t.moveTo = true;
              this.setState({ touched: t, errors: errors });
              // e.preventDefault();
              return;
            }
            this.onSubmit("temp", taskUnderEdit);
            // e.preventDefault();
            // this.setState({taskUnderEdit: taskUnderEdit});
          }}
          onCancel={() => this.setState({ temperatureModalOpen: false })}
        />
        <CommentTaskModal
          open={this.state.commentTaskModalOpen}
          company={this.props.company}
          taskUnderEdit={this.state.taskUnderEdit}
          onSave={(taskUnderEdit) => {
            // this.setState({taskUnderEdit: taskUnderEdit});
            taskUnderEdit.type = "comments";
            let errors = this.validate(this.validationSchema.bind(this))(
              this.state.taskUnderEdit
            );

            let errorsIsEmpty =
              Object.keys(errors).length === 0 && errors.constructor === Object;
            if (!errorsIsEmpty || this.state.errors.name) {
              let t = { ...this.state.touched };
              t.type = true;
              t.assetType = true;
              t.moveTo = true;
              this.setState({ touched: t, errors: errors });
              // e.preventDefault();
              return;
            }
            this.onSubmit("comment", taskUnderEdit);
          }}
          onCancel={() => this.setState({ commentTaskModalOpen: false })}
        />
        <h5 className="dispatch-title">
          {/* <Button
            hidden={this.state.taskUnderEdit.end}
            onClick={() =>
              putTaskRemoveAssignee(
                this.state.taskUnderEdit.uuid,
                this.props.position,
                () => {
                  this.props.history.push("/poweryard-tasks");
                  showInfo(
                    "The task has been unassigned and any start time cleared."
                  );
                }
              )
            }
            color={"danger"}
          >
            {this.state.taskUnderEdit.assignee
              ? this.state.taskUnderEdit.assignee.firstname +
                " " +
                this.state.taskUnderEdit.assignee.lastname
              : ""}{" "}
            <i className="fa fa-times-circle" />
          </Button>{" "} */}
          &nbsp; ADD / CHECK-IN ASSET 
          <span className="float-right">
            {this.props.currentLocation.nickname}
          </span>
        </h5>

        <div>
          <Form
            onSubmit={(e) => {
              let errors = this.validate(this.validationSchema.bind(this))(
                this.state.taskUnderEdit
              );
              let closeCheck = this.state.closeCheck;
              let errorsIsEmpty =
                Object.keys(errors).length === 0 &&
                errors.constructor === Object;
              if (!errorsIsEmpty || this.state.errors.name) {
                let t = { ...this.state.touched };
                t.type = true;
                t.assetType = true;
                t.moveTo = true;
                this.setState({ touched: t, errors: errors });
                e.preventDefault();
                return;
              }
              if (this.props.match.params.uuid === "new") {
                // this.setState({loaderMessage: "Saving ..."});
                this.updateSpinner("Saving ...");
              } else {
                // this.setState({loaderMessage: "Updating ..."});
                this.updateSpinner("Updating ...");
              }
              this.setState({
                loaded: true,
              });
              this.onSubmit(closeCheck);
              e.preventDefault();
            }}
          >
            <Card className="simple-card">
              <CardBody>
                <div>
                  <FormGroup>


                    <Row>
                      <Col lg="2" md="3" sm="4">
                        <Label>
                          {"Datetime(" +
                            this.timezoneShortCode(
                              this.props.currentLocation &&
                              this.props.currentLocation.timezone,
                              this.state.taskUnderEdit.start
                            ) +
                            ")"}
                          <span className={"text-danger"}>*</span>
                        </Label>
                      </Col>
                      <Col className={"task-form"} lg="10" md="9" sm="8">
                        <DatePicker
                          placeholderText={"MM/DD/YYYY HH:MM AP"}
                          
                          className={
                            this.state.errors &&
                            this.state.errors.start &&
                            "is-invalid"
                          }
                          selected={this.state.taskUnderEdit.start}
                          onChange={(date) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.accept = date;
                            s.start = date;
                            // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                            if (date === null || !isNaN(date.getTime())) {
                              this.setState({ taskUnderEdit: s });
                            }
                          }}
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm a"
                          showTimeInput
                          shouldCloseOnSelect={false}
                        />
                      </Col>
                    </Row>

                  </FormGroup>

                  <FFFormSelectField
                    key={"ID_taskType"}
                    fieldname="type"
                    label="Task Type"
                    placeholder="Select a Task"
                    icon="fa-keynote"
                    value={
                      this.state.taskUnderEdit.type &&
                      this.state.taskUnderEdit.type.name
                    }
                    options={this.state.taskTypeOptions}
                    checkered={true}
                    required={true}
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.type = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.type}
                    touched={this.state.touched.type}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.type = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                  />
                  <FFFormSelectCreatableField
                    defaultOptions={true}
                    fieldname="carrier"
                    label="Carrier Name"
                    placeholder="Enter the Carrier"
                    icon="fa-truck"
                    value={
                      this.state.taskUnderEdit.asset &&
                        this.state.taskUnderEdit.asset.carrier
                        ? this.state.taskUnderEdit.asset.carrier.name
                        : this.state.taskUnderEdit.carrier
                          ? this.state.taskUnderEdit.carrier.name
                          : ""
                    }
                    loadOptions={promiseCarriersByPrefix}
                    hideEmpty={false}
                    checkered={true}
                    required={false}
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.carrier = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.carrier}
                    touched={this.state.touched.carrier}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      if (
                        v == null ||
                        (s.carrier && s.carrier.uuid !== v.uuid)
                      ) {
                        s.asset = {};
                      }
                      if (v && v.__isNew__) {
                        v.name = v.label;
                      }
                      if (s.asset) {
                        s.asset.carrier = v;
                      }
                      s.carrier = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                  />
                  <FFFormSelectCreatableField
                    key={
                      "ID_" +
                      (this.state.taskUnderEdit.carrier
                        ? this.state.taskUnderEdit.carrier.uuid
                        : "")
                    }
                    fieldname="asset.assetId"
                    label="Asset ID"
                    placeholder="Enter the Asset ID"
                    icon="fa-truck"
                    value={
                      this.state.taskUnderEdit.asset
                        ? this.state.taskUnderEdit.asset.assetId
                        : ""
                    }
                    isClearable={true}
                    isDisabled={!this.state.taskUnderEdit.carrier}
                    defaultOptions={true}
                    loadOptions={promiseAssetByPrefix(
                      this.state.taskUnderEdit.carrier
                        ? this.state.taskUnderEdit.carrier.uuid
                        : ""
                    )}
                    hideEmpty={false}
                    checkered={true}
                    required={true}
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.asset = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.asset}
                    touched={this.state.touched.asset}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.asset = v;
                      s.assetType = v && v.equipment ? v.equipment.name : "";
                      if (
                        v &&
                        v.latestTaskSpot != null &&
                        v.latestTaskSpot.location &&
                        v.latestTaskSpot.location.uuid ===
                        this.props.currentLocation.uuid
                      ) {
                        s.moveFrom = v.latestTaskSpot;
                      }
                      this.setState({ taskUnderEdit: s });
                    }}
                  ></FFFormSelectCreatableField>

                  <FFFormAsyncSelectField
                    defaultOptions={true}
                    fieldname="assetType"
                    label="Asset Type"
                    icon="fa-spot"
                    btnIcon="fa-temperature-low"
                    value={this.getAssetType()}
                    required={false}
                    isClearable={true}
                    button
                    btnHidden={
                      (this.state.taskUnderEdit.asset &&
                        this.state.taskUnderEdit.asset.equipment &&
                        this.state.taskUnderEdit.asset.equipment
                          .temperatureControlled) ||
                      (this.state.taskUnderEdit.assetType &&
                        this.state.taskUnderEdit.assetType
                          .temperatureControlled)
                    }
                    onClick={() => {
                      this.setState({
                        cancelTask: this.state.taskUnderEdit,
                        temperatureModalOpen: true,
                      });
                    }}
                    placeholder={this.state.defaultEquipmentType}
                    loadOptions={
                      this.props.currentLocation
                        ? promiseEZTrackEquipmentTypeByPrefix(
                          this.props.currentLocation.uuid
                        )
                        : promiseEZTrackEquipmentTypeByPrefix
                    }
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.assetType = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.assetType}
                    touched={this.state.touched.assetType}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      if (s.asset) {
                        s.asset.equipment = v;
                      }
                      s.assetType = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                  />

                  <FFFormSelectField
                    fieldname="assetStatus"
                    label="Asset Status"
                    icon="fa-balance-scale-left"
                    value={
                      this.state.taskUnderEdit.assetStatus
                        ? this.state.taskUnderEdit.assetStatus.name
                        : ""
                    }
                    required={false}
                    options={this.state.assetStatusOptions}
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.assetStatus = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.assetStatus}
                    touched={this.state.touched.assetStatus}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.assetStatus = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                  />

                  {/* <FFFormAsyncSelectField
                    defaultOptions={true}
                    isClearable={true}
                    fieldname="moveFrom"
                    label="Move From Spot"
                    icon="fa-play"
                    value={
                      this.state.taskUnderEdit.moveFrom
                        ? this.state.taskUnderEdit.moveFrom.uuid &&
                          this.state.taskUnderEdit.moveFrom.name
                          ? this.state.taskUnderEdit.moveFrom.zone +
                            " - " +
                            this.state.taskUnderEdit.moveFrom.name
                          : this.state.taskUnderEdit.moveFrom.zone
                        : null
                    }
                    required={false}
                    loadOptions={
                      this.props.currentLocation &&
                      promiseSpotForLocationByZonePrefixOrSpotPrefix(
                        this.props.currentLocation.uuid
                      )
                    }
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.moveFrom = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.moveFrom}
                    touched={this.state.touched.moveFrom}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.moveFrom = v;
                      let touched = { ...this.state.touched };
                      touched.moveFrom = true;
                      this.setState({ taskUnderEdit: s, touched: touched });
                    }}
                  /> */}
                  {/* <FFFormTextField
                    fieldname="boxingAssets"
                    label={"Boxed In"}
                    placeholder="Enter the number of boxing assets"
                    icon="fa-box"
                    value={this.state.taskUnderEdit.boxingAssets}
                    numbersOnly={true}
                    isClearable={true}
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.boxingAssets = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.boxingAssets}
                    touched={this.state.touched.boxingAssets}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.boxingAssets = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                    max={9}
                    min={1}
                  /> */}
                  <FFFormAsyncSelectField
                    defaultOptions={true}
                    isClearable={false}
                    fieldname="moveTo"
                    label="Move To Spot"
                    icon="fa-stop"
                    value={
                      this.state.taskUnderEdit.moveTo
                        ? this.state.taskUnderEdit.moveTo.uuid
                          ? this.state.taskUnderEdit.moveTo.zone +
                          " - " +
                          this.state.taskUnderEdit.moveTo.name
                          : this.state.taskUnderEdit.moveTo.zone
                        : null
                    }
                    required={true}
                    loadOptions={
                      this.props.currentLocation &&
                      promiseSpotForLocationByZonePrefixOrSpotPrefix(
                        this.props.currentLocation.uuid
                      )
                    }
                    onBlur={() => {
                      let touched = { ...this.state.touched };
                      touched.moveTo = true;
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.moveTo}
                    touched={this.state.touched.moveTo}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.moveTo = v;
                      let touched = { ...this.state.touched };
                      touched.moveTo = true;
                      this.setState({ taskUnderEdit: s, touched: touched });
                    }}
                  />
                  <FFFormTextField
                    fieldname="shipmentNumber"
                    label="Shipment Number"
                    placeholder="Enter the shipment number"
                    icon="fa-tag"
                    value={
                      this.state.taskUnderEdit.shipmentNumber
                        ? this.state.taskUnderEdit.shipmentNumber
                        : ""
                    }
                    required={false}
                    onBlur={() => {
                      let errors = this.validate(
                        this.validationSchema.bind(this)
                      )(this.state.taskUnderEdit);
                      let touched = { ...this.state.touched };
                      touched.shipmentNumber = true;
                      this.setState({ errors: errors, touched: touched });
                    }}
                    error={this.state.errors.shipmentNumber}
                    touched={this.state.touched.shipmentNumber}
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.shipmentNumber = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                  />

                  <FFFormCheckboxField
                    button
                    fieldname="sealed"
                    icon="fa-power-off"
                    btnIcon="fa-sticky-note"
                    onClick={(v) => {
                      this.setState({
                        cancelTask: this.state.taskUnderEdit,
                        commentTaskModalOpen: true,
                      });
                    }}
                    btnEmpty={this.state.taskUnderEdit.comments}
                    label="Sealed"
                    value={
                      this.state.taskUnderEdit.sealed == null
                        ? false
                        : this.state.taskUnderEdit.sealed
                    }
                    onChange={(v) => {
                      let s = { ...this.state.taskUnderEdit };
                      s.sealed = v;
                      this.setState({ taskUnderEdit: s });
                    }}
                  />

                  {/* {!this.state.taskUnderEdit.end && (
                    <Row>
                      <Col lg="2" md="3" sm="4">
                        <Label>Click to End</Label>
                      </Col>
                      <Col lg="10" md="9" sm="8">
                        <Button
                          className={"progressScreenBtn"}
                          color="primary"
                          disabled={!this.state.taskUnderEdit.start}
                          type="submit"
                          onClick={() => this.taskProgressChecker("end")}
                          color="primary"
                        >
                          {" "}
                          <i className="fa fa-clock" /> End Task
                        </Button>
                      </Col>
                    </Row>
                  )} */}
                  {/* {this.state.taskUnderEdit.start &&
                    this.state.taskUnderEdit.end && (
                      <Row>
                        <Col lg="2" md="3" sm="4">
                          <Label>
                            {"Task End Timestamp (" +
                              this.timezoneShortCode(
                                this.props.currentLocation &&
                                  this.props.currentLocation.timezone,
                                this.state.taskUnderEdit.end
                              ) +
                              ")"}
                          </Label>
                        </Col>
                        <Col className={"task-form"} lg="10" md="9" sm="8">
                          <DatePicker
                            placeholderText={"MM/DD/YYYY HH:MM AP"}
                            disabled
                            className={
                              this.state.errors &&
                              this.state.errors.end &&
                              "is-invalid"
                            }
                            selected={this.state.taskUnderEdit.end}
                            onChange={(date) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.end = date;
                              // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                              if (date === null || !isNaN(date.getTime())) {
                                this.setState({ taskUnderEdit: s });
                              }
                            }}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm a"
                            showTimeInput
                            shouldCloseOnSelect={false}
                          />
                          <FormFeedback
                            className={"d-block"}
                            style={{ marginLeft: 0 }}
                          >
                            {this.state.errors && this.state.errors.end}
                          </FormFeedback>
                        </Col>
                      </Row>
                    )} */}
                </div>
              </CardBody>
            </Card>
            <div className="mb-3 text-right">
              <Button
                
                className={"float-left"}
                color="danger"
                onClick={() => {
                  this.props.history.push("/poweryard-onsite");
                }}
              >
                Cancel
              </Button>{" "}

              &nbsp;&nbsp;
              <Button
                color="primary"
                type="submit"
                onClick={() => this.setCloseVariable(true)}
              >
                Save &#38; Close
              </Button>
            </div>
          </Form>
        </div>
      </div>
      </Loader>
    );
  }
  setCloseVariable(val) {
    this.setState({ closeCheck: val });
  }
  getAssetType() {
    if (
      this.state.taskUnderEdit.asset &&
      this.state.taskUnderEdit.asset.equipment
    ) {
      return this.state.taskUnderEdit.asset.equipment.name;
    } else if (this.state.taskUnderEdit.assetType) {
      return this.state.taskUnderEdit.assetType.name;
    } else if (this.state.defaultEquipmentType) {
      return this.state.defaultEquipmentType.name;
    } else {
      return "";
    }
  }
}
AssetForm.propTypes = {
  currentLocation: PropTypes.object,
  company: PropTypes.object,
};
export default AssetForm;
