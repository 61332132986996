import CreatableSelect from "react-select/lib/Creatable";
import React from "react";

export default (props)=>
    <CreatableSelect
        name={props.fieldname}
        isMulti="true"
        placeholder={props.placeholder}
        valid={!props.errors[props.fieldname]}
        invalid={props.touched[props.fieldname] && !!props.errors[props.fieldname]}
        onChange={(v) => {
            v = v.map((x)=>{return {shortCode:x.value}});
            props.setFieldValue(props.fieldname, v );
            props.setFieldTouched(props.fieldname, true);
        }}
        onBlur={() => props.setFieldTouched(props.fieldname, true)}
        value={props.values[props.fieldname] ? props.values[props.fieldname].map((v)=>{return {value:v.shortCode, label:v.shortCode}}) : null}
        className={"basic-single select-in-form" + ((props.touched[props.fieldname] && !!props.errors[props.fieldname]) ? " is-invalid" : "")}
        classNamePrefix="select"
        options={props.options.map((v) => {
            return {value: v, label: v}
        })}
        isClearable={true}/>