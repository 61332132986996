import React, { Component } from 'react';
import AsyncSelect from "react-select/lib/Async";
import {
    Col,
    FormFeedback,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Tooltip,
    Button
} from "reactstrap";
import * as uuid from "uuid";

class FFFormAsyncSelectField extends Component {


    constructor(props, context) {
        super(props, context);
        console.log('select async', this.props);
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        };
    }

    icon() {
        if (this.props.icon) {
            return <i className={"fa " + this.props.icon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }
    btnIcon() {
        if (this.props.btnIcon) {
            return <i className={"fa " + this.props.btnIcon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }
    renderSelect() {
        return (
            <AsyncSelect
                name={this.props.fieldname}
                defaultOptions={this.props.defaultOptions}
                placeholder={this.props.placeholder}
                valid={!this.props.error}
                isMulti={this.props.isMulti}
                invalid={this.props.touched && !!this.props.error}
                onBlur={this.props.onBlur}
                onChange={(v) => {

                    if (this.props.onChange) {
                        if (v && v.__isNew__) {
                            v.assetId = v.value;
                        }
                        this.props.onChange && this.props.onChange(v);
                    }
                }}
                // value={this.props.value ? this.props.value.map((v) => { return { value: this.props.value, label: this.props.value } }) : null}
                value={this.props.value && this.props.value ? {
                    label: this.props.value,
                    value: this.props.value
                } : this.props.hideEmpty ? null : { label: "", value: "" }}
                className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
                // classNamePrefix="select"
                loadOptions={this.props.loadOptions}
                isClearable={this.props.isClearable}
                isDisabled={this.props.isDisabled} />)
    }


    render() {
        if (this.props.button) {
            return (

                <FormGroup>
                    <Row>
                        {!this.props.noLabel &&
                            <Col lg="2" md="3" sm="4">
                                <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''}</span> {this.props.subLabel ?
                                    <small><br />{this.props.subLabel}</small> : ""}</Label>
                                {this.props.helpMessage &&
                                    <div>
                                        <i style={{
                                            position: "absolute",
                                            right: 0,
                                            top: "5px",
                                            color: "grey"
                                        }}
                                            className={"fa fa-question-circle"}
                                            id={this.state.id}
                                        />
                                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                                            toggle={() => {
                                                this.setState({
                                                    tooltipOpen: !this.state.tooltipOpen
                                                })
                                            }}>
                                            {this.props.helpMessage}
                                        </Tooltip>
                                    </div>
                                }
                            </Col>
                        }
                        <Col lg="8" md="6" sm="5">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                {this.renderSelect()}
                                <FormFeedback>{this.props.error}</FormFeedback>
                            </InputGroup>
                        </Col>
                         <Col lg="2" md="3" sm="3">

                            <Button hidden={!this.props.btnHidden} className={"float-right text-uppercase font-temperature-btn"} color="primary" onClick={this.props.onClick}  >Temperature <i className={"fa " + this.props.btnIcon} /></Button>
                        </Col> 
                    </Row>
                </FormGroup>
            )
        } else {
            return (

                <FormGroup>
                    <Row>
                        {!this.props.noLabel &&
                            <Col lg="2" md="3" sm="4">
                                <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''}</span> {this.props.subLabel ?
                                    <small><br />{this.props.subLabel}</small> : ""}</Label>
                                {this.props.helpMessage &&
                                    <div>
                                        <i style={{
                                            position: "absolute",
                                            right: 0,
                                            top: "5px",
                                            color: "grey"
                                        }}
                                            className={"fa fa-question-circle"}
                                            id={this.state.id}
                                        />
                                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                                            toggle={() => {
                                                this.setState({
                                                    tooltipOpen: !this.state.tooltipOpen
                                                })
                                            }}>
                                            {this.props.helpMessage}
                                        </Tooltip>
                                    </div>
                                }
                            </Col>
                        }
                        <Col lg="10" md="9" sm="8">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                {this.renderSelect()}
                                <FormFeedback>{this.props.error}</FormFeedback>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>
            )
        }

    }
}


export default FFFormAsyncSelectField;