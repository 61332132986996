import React, {Component} from "react";
import * as PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

export class PaginationSelect extends Component {
    render() {
        if(this.props.specificPageSizes && this.props.specificPageSizes.length !== 0){
            return <select value={this.props.value} onChange={this.props.onChange}>
               
               {this.props.specificPageSizes.map(item => 
                (<option>{item}</option>)
               )}

            </select>;

        } else {
            return <select value={this.props.value} onChange={this.props.onChange}>
                <option>5</option>
                <option>10</option>
                <option>20</option>
                <option>50</option>
            </select>;

        }
    }
}

PaginationSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
};

export class PaginationButtons extends Component {
    render() {
        return <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.props.pageCount}
            forcePage={this.props.forcePage}
            initialPage={this.props.initialPage ? this.props.initialPage : 0}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.props.onPageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            disableInitialCallback={true}
        />;
    }
}

PaginationButtons.propTypes = {
    pageCount: PropTypes.any,
    onPageChange: PropTypes.func
};

export class Pagination extends Component {
    render() {
        return <>
            <PaginationButtons
                pageCount={this.props.pageCount}
                onPageChange={this.props.onPageChange}
                initialPage={this.props.initialPage}
                forcePage={this.props.forcePage}
            />
            <PaginationSelect
                specificPageSizes = {this.props.specificPageSizes}
                value={this.props.itemsPerPage}
                onChange={this.props.onItemsPerPageChange}/>
        </>;
    }
}

Pagination.propTypes = {
    pageCount: PropTypes.any,
    onPageChange: PropTypes.func,
    itemsPerPage: PropTypes.any,
    onItemsPerPageChange: PropTypes.func
};