import React, { Component } from "react";
import Select from "react-select";
import * as PropTypes from "prop-types";
import {
  ROLE_ACCOUNTS_PAYABLE,
  ROLE_ADMIN,
  ROLE_ATTENDANT,
  ROLE_CARRIER_ACCOUNTS_PAYABLE,
  ROLE_CARRIER_ADMIN,
  ROLE_CARRIER_DISPATCHER,
  ROLE_OPERATOR,
  ROLE_POWERYARD_ADMIN,
  ROLE_POWERYARD_JOCKEY,
  ROLE_POWERYARD_MANAGER,
  ROLE_POWERYARD_REQUESTER,
  ROLE_TRACKASSURED_ADMIN,
  ROLE_TRACKASSURED_USER,
  ROLE_EZTRACK_ADMIN,
  ROLE_EZTRACK_MANAGER,
  ROLE_EZTRACK_USER,
  ROLE_GATE_USER,
} from "../../Roles";

const eztrackroles = [
  {
    label: "EZ-Track Admin",
    name: ROLE_EZTRACK_ADMIN,
  },
  {
    label: "EZ-Track Manager",
    name: ROLE_EZTRACK_MANAGER,
  },
  {
    label: "EZ-Track User",
    name: ROLE_EZTRACK_USER,
  },
];
const welcomeCenterRoles = [
  {
    label: "Welcome Center Admin",
    name: ROLE_CARRIER_ADMIN,
  },
  {
    label: "Welcome Center Accounts Payable",
    name: ROLE_CARRIER_ACCOUNTS_PAYABLE,
  },
  {
    label: "Welcome Center Dispatcher",
    name: ROLE_CARRIER_DISPATCHER,
  },
];
const dispatchRoles = [
  {
    label: "Dispatch Admin",
    name: ROLE_ADMIN,
  },
  {
    label: "Dispatch Accounts Payable",
    name: ROLE_ACCOUNTS_PAYABLE,
  },
  {
    label: "Dispatch Attendant",
    name: ROLE_ATTENDANT,
  },
  {
    label: "Dispatch Operator",
    name: ROLE_OPERATOR,
  },
];
const powerYardRoles = [
  {
    label: "PowerYard Admin",
    name: ROLE_POWERYARD_ADMIN,
  },
  {
    label: "PowerYard Manager",
    name: ROLE_POWERYARD_MANAGER,
  },
  {
    label: "PowerYard Yard Jockey",
    name: ROLE_POWERYARD_JOCKEY,
  },
  {
    label: "PowerYard Requester",
    name: ROLE_POWERYARD_REQUESTER,
  }
];
const trackassuredRoles = [
  {
    label: "TrackAssured Admin",
    name: ROLE_TRACKASSURED_ADMIN,
  },
  {
    label: "TrackAssured User",
    name: ROLE_TRACKASSURED_USER,
  },
];
export class FFRoleSelect extends Component {
  constructor(props) {
    super(props);
    let options = [];
    // if (this.props.enabledModules.includes('enableEzTrack') || this.props.enabledModules.includes('enableEzCheckInDispatch')) {
    //     options = options.concat(dispatchRoles);
    // }
    if (this.props.enabledModules.includes("enableEzTrack")) {
      options = options.concat(eztrackroles);
    }
    if (this.props.enabledModules.includes("enableEzCheckInDispatch")) {
      options = options.concat(dispatchRoles);
    }
    if (this.props.enabledModules.includes("enableEzCheckInWelcome")) {
      options = options.concat(welcomeCenterRoles);
    }
    if (this.props.enabledModules.includes("enablePowerYard")) {
      if (this.props.loggedInUser.company.enableGate) {
        let gateRoleObj = {
          label: "Gate User",
          name: ROLE_GATE_USER,
        }
        if (powerYardRoles.findIndex(it => it.label === 'Gate User') === - 1) {
          powerYardRoles.push(gateRoleObj)
        }
      }
      options = options.concat(powerYardRoles);
    }
    if (this.props.enabledModules.includes("enableTrackAssured")) {
      options = options.concat(trackassuredRoles);
    }
    if (options === []) {
      options = dispatchRoles;
    }
    let currentUser = this.props.loggedInUser;
    let rolArr = currentUser.roles.filter(it => it.role.toLowerCase().includes("admin"));
    if (rolArr.length == 0) {
      options = options.filter(r => !r.label.toLowerCase().includes("admin"));
    }
    this.state = {
      options: options,
    };
  }

  lookupLabel(value) {
    for (let i = 0; i < this.state.options.length; i++) {
      if (this.state.options[i].name === value) {
        return this.state.options[i].label;
      }
    }
    return value;
  }

  render() {
    return (
      <Select
        name={this.props.name}
        placeholder={this.props.placeholder}
        valid={!this.props.error}
        isMulti="true"
        invalid={this.props.touched && !!this.props.error}
        onChange={(v) => {
          v = v.map((x) => {
            return { role: x.value };
          });
          this.props.onRolesChange(v);
        }}
        onBlur={this.props.onBlur}
        value={
          this.props.value
            ? this.props.value.map((v) => {
              return { value: v.role, label: this.lookupLabel(v.role) };
            })
            : null
        }
        className={
          "basic-single select-in-form" +
          (this.props.touched && !!this.props.error ? " is-invalid" : " TEST")
        }
        // classNamePrefix="select"
        options={this.state.options.map((v) => {
          return { value: v.name, label: v.label };
        })}
        isClearable={true}
        menuPosition={this.props.menuPosition}
      />
    );
  }
}

FFRoleSelect.propTypes = {
  name: PropTypes.any,
  placeholder: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  values: PropTypes.any,
  fieldname: PropTypes.any,
};
