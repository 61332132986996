import React from "react";
import * as uuid from "uuid";
import {Tooltip} from "reactstrap";
import MomentDisplay from "../../assets/components/MomentDisplay";

export class ArrivalTool extends React.Component{
    constructor(props) {
        super();
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        }
    }

    getColor(arrivalAlert) {
        if(!arrivalAlert){
            return "black";
        }else if(arrivalAlert === "NotCalculated"){
            return "black";
        }else if(arrivalAlert === "Late"){
            return "red";
        }else if(arrivalAlert === "OnTime"){
            return "green";
        }else {
            return "black";
        }
    }

    toggle(){
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    render() {
        return <span className={this.props.showOn === "progress" ? "clock": ""}>
            <i id={this.state.id} className="fas fa-clock" aria-hidden="true"
               style={{marginLeft: "10px", color: this.getColor(this.props.arrivalAlert)}}/>
            <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={() => this.toggle()}>
                {!this.props.arrivalAlert && "Not Calculated"}
                {this.getTooltip()}
            
            </Tooltip>
        </span>
            ;
    }

  
    getTooltip() {
        if(!this.props.arrivalAlert){
            return <></>;
        }
        return <>
            Arrival Time: {
            this.props.stopAppointment &&
            <MomentDisplay
                timezone={this.props.timezone}
                date={parseInt(this.props.stopAppointment)}
            />
    }
       
        </>;
    }
}