import React, {Component} from "react";
import {Button, Col, Row} from "reactstrap"
import {
    downloadDispatchsForTable,
    saveNote
} from "../../NetworkHelpers";
import ValidationService from "../../common/ValidationService";
import 'react-table/react-table.css'
import '../../ReactTable.scss';
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import {withRouter} from 'react-router';
import {storeItemsPerPage} from "../../assets/actions/Assets";
import connect from "react-redux/es/connect/connect";
import {formatLatLong, getShipmentTALink} from "../../common/utilities";
import {ROLE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import {formatAdditionalReferences, getStartColumn} from "../utilities";
import { latLongDistance } from "../../common/utilities";
import {
    endOfDay,
    getLocalStorageName,
    getTableSize,
    startOfDay
} from "../../common/utilities";
import Switch from "react-switch";
import * as uuid from "uuid";
import DatePicker from "react-datepicker/es";
import moment from "moment-timezone";
import Moment from "react-moment";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {ClipboardClickable} from "../../common/ClipboardClickable";
import DurationDisplay from "../../assets/components/DurationDisplay";
import NoteModal from "../../assets/components/NoteModal";

class ShipmentsTA extends Component {
    tableColumns=[
        {
            Header: 'Actions',
            filterable: false,
            sortable: false,
            default: true,
            width:65,
            Cell: row => {
                return <div>
                    {row.original.shipment && this.props.showAddNoteActionButton && <Button title="Add/View Note"
                        aria-label="Add/View Note"
                        onClick={() => this.openNoteModal(row.original.shipment.uuid, row.original.shipment.assetId, row.original.shipment.notes)}>
                        <i className="far fa-sticky-note" />
                    </Button>}
                  
                </div>
            }
        },
        {
            accessor: 'shipment.assetId',
            Header: this.props.user.company.shipmentIdDisplay,
            assetId: true,
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => { return row.original.shipment ? getShipmentTALink(row.original.dispatchUuid,this.props.user, row.original.shipment.assetId, row.original.shipment.company.uuid, row.original.shipment.company.shipmentIdDisplay) : "" }
        },
        {
            Header: 'Secondary ID',
            accessor: 'currentAssetRoster.references',
            filterable: true,
            sortable: false,
            default: true,
            Cell: row => { return formatAdditionalReferences(row.original.dispatchReferences, 1) }
        },
        {
            Header: 'Stop ID',
            accessor: 'stopPlan.references',
            filterable: true,
            sortable: false,
            default: false,
            Cell: row => { return formatAdditionalReferences(row.original.references, 0) }
        },
        {
            accessor: 'created',
            Header: 'System Create Time',
            filterable: false,
            sortable: true,
            default: false,
            Cell: row => {
                let v = row.original;
                if (v.created) {
                    return <MomentDisplay date={v.created} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone} />;
                } else {
                    return null;
                }
            },
        },
    
        {
            accessor: 'latestShipmentStatusEvent.shipmentStatus.statusName',
            Header: 'Shipment Status',
            title: 'Most recent event/status update for this shipment, see Report Time column for when this was updated.',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.latestEvent ? <ClipboardClickable
                    id={"ID_" + uuid.v4()}
                    value={formatLatLong(row.original.latestEvent.latitude) + ", " + formatLatLong(row.original.latestEvent.longitude)}
                    hover={"Click to Copy Coordinates"}>
                      {row.original.latestEvent.status === 14 &&

row.original.latestEvent.statusMessage}
{row.original.latestEvent.status !== 14 &&

row.original.latestEvent.shipmentStatus &&
row.original.latestEvent.shipmentStatus.statusName}
                </ClipboardClickable> : null
            },
            maxWidth: 150
        },
        {
            accessor: 'latestShipmentStatusEvent.statusMessage',
            Header: 'Latest Event',
            title: 'Most recent event/status update for this shipment, see Report Time column for when this was updated.',
            filterable: true,
            sortable: true,
            Cell: row => {
                return row.original.latestEvent ? <ClipboardClickable
                    id={"ID_" + uuid.v4()}
                    value={formatLatLong(row.original.latestEvent.latitude) + ", " + formatLatLong(row.original.latestEvent.longitude)}
                    hover={"Click to Copy Coordinates"}>
                    {row.original.latestEvent.statusMessage}
                </ClipboardClickable> : null
            },
            maxWidth: 150
        },
        {
            accessor: 'latestShipmentStatusEvent.date',
            Header: 'Report Time',
            filterable: false,
            sortable: true,
            title: "Shipment status report datetime for the indicated \"shipment status\"",
            Cell: row => {
                return row.original.latestEvent ?
                    <Moment
                        title={"Shipment status report datetime for the indicated \"shipment status\""}
                        format="MM/DD/YYYY h:mm a z"
                        tz={row.original.lastLocationTimezone}
                        date={row.original.latestEvent.date} /> : null
            },
            maxWidth: 150
        },
      
        {
            accessor: 'totalStops',
            Header: '# of Stops',
            filterable: true,
            sortable: true,
            Cell: row => { return row.original.numberOfStops },
            maxWidth: 150
        },
        {
            accessor: 'currentStop',
            Header: 'Stops Complete',
            filterable: true,
            sortable: true,
            Cell: row => { return row.original.currentStop },
            maxWidth: 150
        },
        {
            accessor: 'shipments',
            Header: "Remaining Miles",
            filterable: true,
            sortable: true,
            default: true,
            Cell: (row) => {
              if (row.original.latestEvent && row.original.latestEvent!== null) {
                  if(row.original.shipment && row.original.shipment !== null){
                      if(row.original.shipment.deliveryLocation && row.original.shipment.deliveryLocation !== null){
                          return latLongDistance(
                            row.original.latestEvent.latitude,
                            row.original.latestEvent.longitude,
                            row.original.shipment.deliveryLocation.latitude,
                            row.original.shipment.deliveryLocation.longitude,
                            row.original.shipment.company.distanceUnitsOfMeasure
                          );
                      } else {
                        return "Delivery Location Not Available";
                      }
                  } else {
                    return "Shipment information Not Available";
                  }
                  
              }
              return "N/A";
            },
          },
        {
            accessor: 'carrier',
            Header: 'Carrier',
            filterable: false,
            sortable: false,
            title: "First carrier assigned, open to view all. ",
            Cell: row => { return row.original.carrier },
            maxWidth: 150
        },
       
        {
            accessor: 'currentStopPlan.firstStop.location.name',
            Header: 'First Stop',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.firstLocationName
            }
        },
        {
            accessor: 'currentStopPlan.firstStop.appointment',
            Header: 'First Stop Appt',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.firstAppointment ? <MomentDisplay
                    date={row.original.firstAppointment}
                    timezone={row.original.firstLocationTimezone}
                /> : ""
            }
        },
        {
            accessor: 'currentStopPlan.firstStop.arrival',
            Header: 'First Stop Arrival',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.firstStop && row.original.firstStop.arrival ? <MomentDisplay
                    date={row.original.firstStop.arrival}
                    timezone={row.original.firstLocationTimezone}
                /> : ""
            }
        },
        {
            accessor: 'currentStopPlan.lastStop.location.name',
            Header: 'Final Stop',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.lastLocationName
            }
        },
        {
            accessor: 'currentStopPlan.lastStop.appointment',
            Header: 'Final Stop Appt',
            filterable: false,
            default: true,
            sortable: true,
            Cell: row => {
                return row.original.lastAppointment ? <MomentDisplay
                    date={row.original.lastAppointment}
                    timezone={row.original.lastLocationTimezone}
                /> : ""
            }
        },
        {
            accessor: 'currentStopPlan.lastStop.arrival',
            Header: 'Final Stop Arrival',
            width:220,
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.lastStop && row.original.lastStop.arrival ? <MomentDisplay
                    date={row.original.lastStop.arrival}
                    timezone={row.original.lastLocationTimezone}
                /> : ""
            }
        },
    ];
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.statusFilterRef = React.createRef();
        this.dateFilterField=React.createRef();
        
        let localStorageState = JSON.parse(localStorage.getItem(getLocalStorageName(props.stateStorage, props.user)), this.mapFromJson);
        if (localStorageState != null) {
            this.state = localStorageState;
        } else {
            this.state = {
                previousSearchRequest: {},
                createStart: startOfDay(new Date(),7),
                createEnd: endOfDay(new Date(),7),
                dateFilterEnabled: true,
                dateFilterField: "currentStopPlan.firstStop.appointment",
                baseFilter: [{id: 'archived', value: false}],
                filtered: [
                    { id: "createStart", value: startOfDay(new Date(),7) }, 
                    { id: "createEnd", value: endOfDay(new Date(),7)},
                    { id: "dateFilterField", value: "currentStopPlan.firstStop.appointment"}
                ],
                defaultSort : [{
                    desc : false , id: "currentStopPlan.firstStop.appointment"
                }],
                // createStart: startOfDay(new Date(),7) ,
                // createEnd: endOfDay(new Date(),7),
                // dateFilterEnabled: true,
                // dateFilterField : "currentStopPlan.firstStop.appointment"
            };
        }
        if (!this.state.createEnd) {
            this.state.createEnd = endOfDay(new Date(),7);
        }
        if (!this.state.createStart) {
            this.state.createStart = startOfDay(new Date(),7) ;
        }

        if(this.state.dateFilterField === undefined || !this.state.dateFilterField){
            this.state.dateFilterField = "currentStopPlan.firstStop.appointment";
        }

        this.state.tableSize = getTableSize("dispatches-page", "dispatches-page-header");
    }
    mapFromJson(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.values);
            }
        }
        return value;
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateTableSize.bind(this));
        localStorage.setItem(getLocalStorageName(this.props.stateStorage, this.props.user), JSON.stringify(this.state));
    }

    componentDidMount() {
        window.scrollTo(0,0);
        window.addEventListener('resize', this.calculateTableSize.bind(this));
        this.calculateTableSize();
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        if(isTablet === true){
            const datePickers = document.getElementsByClassName("react-datepicker__input-container");
            Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
        }
    }

    formatAdditionalReferences(refs, startIndex) {
        if (!refs || refs.length === startIndex) {
            return null;
        }
        let contents = [];
        for (let i=startIndex; i < refs.length; i++) {
            contents = contents.concat([refs[i].value + " (" + refs[i].type] + ")");
        }
        let uniqueContents = contents.filter((item, index, origArray) => origArray.indexOf(item) === index)
        return uniqueContents.map((r) => <>{r}<br/></>);
    }

    calculateTableSize = () => {
        let size = getTableSize("dispatches-page", "dispatches-page-header");
        this.setState({tableSize: {height: size.height, width: size.width}});
    }

    openNoteModal(uuid, assetId, notes){
        this.setState({showingNoteModal: true, noteModalUuid: uuid, noteModalAssetId: assetId, notesForModal: notes});
    }
    closeNoteModal() {
        this.setState({ showingNoteModal: false, noteModalUuid: false, notesForModal: {} });
    }
 

    setBaseFilter() {
        let baseFilter = [{id: 'archived', value: this.state.showArchive}];
      
        if (this.state.dateFilterEnabled) {
           if(this.state.createStart) {
               baseFilter = baseFilter.concat({id: 'createStart', value: this.state.createStart});
           }
            if(this.state.createEnd) {
                baseFilter = baseFilter.concat({id: 'createEnd', value: this.state.createEnd});
            }
            if(this.state.dateFilterField) {
                baseFilter = baseFilter.concat({id: 'dateFilterField', value: this.state.dateFilterField});
            }
        } 
        this.setState({baseFilter: baseFilter});
    }

    



    render() {
        if (!this.props.currentLocation && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_CARRIER_ADMIN])) {
            this.props.history.push("/admin");
            return <div/>;
        }

        let threshold = 1;
        let thresholdUnit = "Day";
        if(this.props.user.company
            && this.props.user.company.eventNotificationSettings
            && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates
            && this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.value){
            threshold = this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.value;
            thresholdUnit = this.props.user.company.eventNotificationSettings.remindDriverIfNoUpdates.unit;
        }

        return (
            <div className="column-wrapper text-left" id="dispatches-page">
              <NoteModal
                    open={this.state.showingNoteModal}
                    uuid={this.state.noteModalUuid}
                    assetId={this.state.noteModalAssetId}
                    notes={this.state.notesForModal}
                    error={this.state.noteModalError}
                    onSave={(note, setErrors)=>{
                        saveNote(this.state.noteModalUuid, {note: note}, (data)=>{
                            this.tableRef.current.fetchData();
                            this.closeNoteModal();
                        }, (error) =>{
                            this.setState({noteModalError: error.message})
                        });
                    }}
                    onCancel={()=>{
                        this.closeNoteModal();
                    }}
                />
              
                <div className="page-header" id="dispatches-page-header">
                    <h1>Tracked Shipments</h1> <br/>
                
                </div>
                <PaginationFilterTable
                    showEditColumns
                    style={{
                        maxHeight: this.state.tableSize.height,
                        maxWidth: this.state.tableSize.width,
                        minHeight: "120px",
                    }}
                    footerButton={
                        this.props.showExportButton && <Button color="secondary" onClick={() => this.props.onExport && this.props.onExport(this.state.previousSearchRequest)}><i className="fa fa-file-excel"/> Export to Excel</Button>
                    }
                    user={this.props.user}
                    openFiltersCollaps = {true}
                    ref={this.tableRef}
                    get={downloadDispatchsForTable}
                    baseFilter={this.state.baseFilter}
                    stateStorage={this.props.stateStorage}
                    columns={this.tableColumns}
                    itemsPerPage={this.state.itemsPerPage}
                    page={this.state.page}
                    filtered={this.state.filtered}
                    defaultSort = {this.state.defaultSort}
             
                    onFilteredChange={(filter) => {
                        this.setState({filtered: filter}, () => {this.tableRef.current.requestPageSearch()});
                    }}
                    hideFilters={this.state.hideFilters}
                   
                    onChange={(state) => {
                        let statusFilter = state.filtered.filter(f => f.id === 'status');
                        this.setState({hideFilters: state.hideFilters, itemsPerPage: state.itemsPerPage,
                            statusFilter: statusFilter[0] ? statusFilter[0].value : undefined})
                    }}
                    clearFilter={(resetAll) => {
                        if(this.statusFilterRef.current){
                            this.statusFilterRef.current.value = undefined;
                        }
                        if (resetAll) {
                            this.setState({dateFilterEnabled: false, defaultSort : [],filtered: []}, () => {
                                this.setBaseFilter();
                                // this.tableRef.current.restoreColumns(true);
                                this.tableRef.current.requestPageSearch();
                            });
                        } else {
                            let filters = [];
                            if (this.state.filtered) {
                                filters = this.state.filtered.filter((f) => f.id === 'createStart' || f.id === 'createEnd');
                            }
                            this.setState({filtered: filters,defaultSort : [], statusFilter: undefined}, () => this.tableRef.current.requestPageSearch());
                        }
                    }}
                >
                   
                        <div className="filter-switch" title="Show between dates">
                        <Switch
                            onChange={(checked) => {
                                var appliedFilters = this.state.filtered;
                                if(checked === false){
                                    appliedFilters = appliedFilters.filter(it=> it.id !== "createStart" && it.id !== "createEnd" && it.id !== "dateFilterField");
                                    this.setState({filtered : appliedFilters,defaultSort : []});
                                }
                                this.setState({dateFilterEnabled: !this.state.dateFilterEnabled}, () => {
                                    this.setBaseFilter();
                                });
                            }}
                            checked={this.state.dateFilterEnabled}
                            uncheckedIcon={<div className="text-center pt-1"><i className="fas fa-calendar"/></div>}
                            checkedIcon={<div className="text-center pt-1"><i className="fas fa-calendar"/></div>}
                            title="Show Filter"
                            className="react-switch"/>
                        <label style={{width: "75px", marginLeft: "10px"}}>Between</label>&nbsp;
                        <DatePicker
                            name={this.props.fieldname}
                            className={"dispatch-list-date-picker " + (this.props.touched && !!this.props.error ? "is-invalid" : "")}
                            valid={!this.props.error}
                            invalid={this.props.touched && !!this.props.error}
                            onBlur={this.props.onBlur}
                            onChange={(v) => {
                                this.setState({createStart: v, dateFilterEnabled: true}, () => this.setBaseFilter());
                            }}
                            selected={moment(this.state.createStart).toDate()}
                            disabled={this.props.isDisabled}
                            isDisabled={this.props.isDisabled}
                            onFocus={(e) => e.target.select()}
                        />&nbsp;
                        <label style={{width: "75px", marginLeft: "10px"}}>and</label>&nbsp;
                        <DatePicker
                            name={this.props.fieldname}
                            className={"dispatch-list-date-picker " + (this.props.touched && !!this.props.error ? "is-invalid" : "")}
                            valid={!this.props.error}
                            invalid={this.props.touched && !!this.props.error}
                            onBlur={this.props.onBlur}
                            onChange={(v) => {
                                v.setHours(23);
                                v.setMinutes(59);
                                v.setSeconds(59);
                                v.setMilliseconds(999);
                                this.setState({createEnd: v, dateFilterEnabled: true}, () => this.setBaseFilter());
                            }}
                            selected={moment(this.state.createEnd).toDate()}
                            disabled={this.props.isDisabled}
                            isDisabled={this.props.isDisabled}
                            onFocus={(e) => e.target.select()}
                        />
                        <select
                            disabled={!this.state.dateFilterEnabled}
                            ref={this.dateFilterField}
                            onChange={event => {
                                this.setState({dateFilterField: event.target.value}, ()=>{
                                    this.setBaseFilter();
                                    localStorage.setItem(getLocalStorageName(this.props.stateStorage, this.props.user), JSON.stringify(this.state));
                                    this.tableRef.current.requestPageSearch();
                                })
                            }}
                            value={this.state.dateFilterField}
                        >
                            <option value="undefined" hidden={true}>Select</option>
                            <option value="currentStopPlan.firstStop.appointment">First Stop Appointment</option>
                            <option value="currentStopPlan.lastStop.appointment">Final Stop Appointment</option>
                            <option value="latestShipmentStatusEvent.date">Last Event Report Date</option>
                            <option value="created">Create Date</option>
                        </select>
                    </div>
                    
                </PaginationFilterTable>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        itemsPerPage: state.assets.itemsPerPage,
        currentLocation: state.login.location,
        user: state.login.user
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setItemsPerPage: function(itemsPerPage) {
            dispatch(storeItemsPerPage(itemsPerPage));
        }
    });
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShipmentsTA));