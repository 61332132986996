import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Formik} from "formik";
import {Button, Col, Form, Row, TabPane} from "reactstrap";
import FormTextField from "../../common/FormTextField";
import * as Yup from "yup";
import {getErrorsFromValidationError} from "../../common/utilities";
import { updateWorkflowSettings} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import FormPhoneTextField from "../../common/FormPhoneTextField";
import FormCheckboxField from "../../common/FormCheckboxField";
const validationSchema = function (values) {
    return Yup.object().shape({
    })
};

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values);
        try {
            validationSchema.validateSync(values, {abortEarly: false});
            return {}
        } catch (error) {
            console.log(error);
            return getErrorsFromValidationError(error)
        }
    }
};

export default class WorkflowSettings extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            workflowSettings: this.props.company.workflowSettings ? this.props.company.workflowSettings: {
                disableMultiLocationTracking: false
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.company.uuid !== this.props.company.uuid){
            this.setState({
                workflowSettings: this.props.company.workflowSettings ? this.props.company.workflowSettings: {
                    disableMultiLocationTracking: false
                }
            });
        }
    }

    onSubmit(values, {setSubmitting, setErrors, resetForm}) {
        setSubmitting(false);
        updateWorkflowSettings(this.props.company.uuid, values, (settings) => {
            showInfo("Successfully saved event workflow settings");
            resetForm();
            this.setState({workflowSettings:settings});
            this.props.onChange(settings);
        }, () => {
            showError("Failed to save settings")
        });
        return false;
    }

    render() {
        return (
            <div className="flex-column flex flex-grow-1">
                <Formik
                    enableReinitialize
                    initialValues={this.state.workflowSettings}
                    validate={validate(validationSchema)}
                    onSubmit={(v, o) => {
                        this.onSubmit(v, o)
                    }}
                    render={
                        ({
                             values,
                             errors,
                             touched,
                             status,
                             dirty,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                             isValid,
                             handleReset,
                             setTouched,
                             setFieldValue,
                             setFieldTouched
                         }) => (


                            <Form onSubmit={(v) => {
                                return handleSubmit(v)
                            }}>
                                <h5 className="card-title">Workflow Settings</h5>
                                <Row>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Disable Multi Location Tracking"
                                            icon="fa-road"
                                            fieldname="disableMultiLocationTracking"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" className={"text-right"}>
                                        <Button color="primary" type="submit">Save</Button>
                                    </Col>
                                </Row>
                            </Form>)}
                />
            </div>
        );
    }
}
WorkflowSettings.propTypes = {
    company: PropTypes.any,
}
    