import React, {Component} from "react";
import {Tooltip} from "reactstrap";
import * as uuid from "uuid";
import {getDwellForLimit} from "../../Dispatch/components/DwellAlertTooltip";

var moment = require('moment-timezone');


class DurationDisplay extends Component {

    constructor(props) {
        super(props);
        this.element = React.createRef();
        this.state = {
            duration:this.calcDuration(),
            tooltipOpen: false,
            id: "ID_"+uuid.v4()
        };
    }

    tick() {
        if ( this.state.duration !== this.calcDuration() ) {
            this.setState({duration: this.calcDuration()})
        }
    }


    componentDidUpdate(prevProps) {
        this.tick();
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.tick();
        this.timer = setInterval(()=>this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    pad( s) {
        if ( !s ) return "00";
        if ( s < 10 ) return "0" + s;
        return s;
    }

    calcDuration() {
        let d = moment.duration(moment(this.props.fromDate).diff(this.props.date));
        let days = Math.floor(d.asDays());
        let hours = d.get("hours");
        d = hours + ":" + this.pad(d.get("minutes")) + ":" + this.pad(d.get("seconds"));
        if ( days ) {
            if (days > 1) d = days + " days, " + d;
            else d = "1 day, " + d;
        }
        return d;
    }

    getColor() {
        let diff = moment.duration(moment(this.props.fromDate).diff(this.props.date));
        const minutes = diff/60000;
        if (minutes >= this.props.criticalDwellInMinutes ) return "red";
        else if ( minutes >= this.props.warningDwellInMinutes ) return "yellow";
        return "green";
    }

    render() {
        return (
            <div id={this.state.id}>
                 <span ref={this.element}>
                    {this.state.duration}
                     {!(!this.props.warningDwellInMinutes || !this.props.criticalDwellInMinutes) &&
                     <i id={this.state.id}
                        style={{
                            marginLeft: "10px",
                            color: this.getColor()
                        }}
                        className={"fa fa-circle "}
                        aria-hidden="true"
                     />}
                </span>

                <Tooltip
                    placement="right"
                    isOpen={this.state.tooltipOpen}
                    target={this.state.id}
                    toggle={() => {
                        if (!(!this.props.warningDwellInMinutes || !this.props.criticalDwellInMinutes)) {
                            this.setState({tooltipOpen: !this.state.tooltipOpen})
                        }
                    }}>
                    Warning Dwell Limit: {getDwellForLimit(this.props.warningDwellInMinutes, this.props.warningDwellUnit)}
                    <br/>
                    Critical Dwell
                    Limit: {getDwellForLimit(this.props.criticalDwellInMinutes, this.props.criticalDwellUnit)}
                    <br/>
                </Tooltip>
            </div>


        );


    }
}

export default DurationDisplay;