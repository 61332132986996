import React, { Component } from 'react';
import { Alert, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Carriers from "./Carriers";
import Locations from "./Locations";
import Workflows from "./Workflows";
import { storeItemsPerPage } from "../../assets/actions/Assets";
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import Users from "./Users";
import PyApi from "../../PowerYard/components/PyApi";
import AsyncSelect from 'react-select/lib/Async';
import {
  downloadEZTrackEquipmentForTable,
  downloadPingAssetsForTable,
  promiseCompanyByPrefix,
  saveCompany,
  setCompanyAdministrationUuid
} from "../../NetworkHelpers";
import Companies from "./Companies";
import { showError, showInfo } from "../../MainApp";
import Config from "../../Config";
import Company from "./Company";
import { setUsersCompany, storeUserCompanyReferenceNumberTypes } from "../../Login/actions/Login";
import WorkflowSettings from "./WorkflowSettings";
import TrackAssuredSettings from "./TrackAssuredSettings";
import {
  ROLE_ACCOUNTS_PAYABLE,
  ROLE_ADMIN,
  ROLE_ATTENDANT,
  ROLE_CARRIER_ACCOUNTS_PAYABLE,
  ROLE_CARRIER_ADMIN,
  ROLE_E4SCORE_ADMIN,
  ROLE_OPERATOR,
  ROLE_POWERYARD_ADMIN,
  ROLE_POWERYARD_JOCKEY,
  ROLE_POWERYARD_MANAGER,
  ROLE_POWERYARD_REQUESTER,
  ROLE_TRACKASSURED_ADMIN,
  ROLE_EZTRACK_ADMIN,
  ROLE_EZTRACK_MANAGER,
  userHasRole
} from "../../Roles";
import PingAssets from "../../PingAssets/components/PingAssets";
import EZTrackEquipment from "../../PingAssets/components/EZTrackEquipment";
import { ReferenceNumberTypes } from "./ReferenceNumberTypes";
class Admin extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '2',
      hideFilters: true,
      loader: true,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        loader: false
      });
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    // if (this.props.loggedInUser.roles.length === 1 && this.props.loggedInUser.roles[0].role === ROLE_POWERYARD_MANAGER) {
    if (this.props.loggedInUser.roles.length === 1 && this.props.loggedInUser.roles[0].role === ROLE_POWERYARD_MANAGER) {
      this.setState({ activeTab: '3', loader: false });
    }
    else if (this.props.loggedInUser.roles.length > 0 && this.props.loggedInUser.roles[0].role === ROLE_POWERYARD_MANAGER) {
      this.setState({ activeTab: '3', loader: false });
    }
    else if (this.props.defaultCompany && this.props.defaultCompany.name === "e4score") {
      this.setState({
        activeTab: '4',
        loader: false
      });
    } else {
      this.setState({
        activeTab: '2',
        loader: false
      });
    }
    // window.scrollTo(0, 0);
    // if (this.props.loggedInUser.roles.length === 1 && this.props.loggedInUser.roles[0].role === ROLE_POWERYARD_MANAGER) {
    //   this.setState({ activeTab: '3' });
    // }
    // if (this.props.defaultCompany && this.props.defaultCompany.name === "e4score") {
    //   this.setState({
    //     activeTab: '4'
    //   });
    // }
  }

  render() {
    return (
      <div className="admin text-left p-3 form-bk">
        {!this.props.currentLocation && !this.props.superAdmin && <>
          {/* <Alert color="success">
                        <h4 className="alert-heading">Congratulations, {this.props.loggedInUser.firstname}, and welcome
                            to EZCheck-In. </h4>
                        <p>
                            An account for <b>{this.props.loggedInUser.company.name}</b> as
                            a <b>{this.props.loggedInUser.company.typeOfUser}</b> company type has been created. You
                            have been
                            assigned the role of <b>Admin</b> and
                            can now add Users, Locations and Carriers to your account. And, you can also configure the
                            workflow for the Event by Message functionality that collects event information for any
                            shipment. Once you add at least one Carrier and one Location a set of additional links will
                            appear in the left sidebar which will allow you to
                            process {(this.props.loggedInUser.company.enableEzCheckInDispatch || this.props.loggedInUser.company.enableEzTrack) ? "dispatches" : "shipments"}.
                        </p>
                        <hr />
                        <p>
                            Please contact us at <b>customersuccess@e4score.com</b> or <b>203-314-2893</b> and we will
                            guide you through the set-up process.
                        </p>
                    </Alert> */}
        </>}
        <h1>Administration</h1>
        {this.props.superAdmin &&
          <Row style={{ marginBottom: "10px" }}>
            <Col md={12}>
              <h3>Change Company</h3>
            </Col>
            <Col md={12}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={promiseCompanyByPrefix}
                placeholder={this.props.defaultCompany.name}
                onChange={(option) => {
                  this.setState({ selectedCompany: option });
                  setCompanyAdministrationUuid(option.uuid, (company) => {
                    this.props.setUsersCompany(company.content);
                  }, () => {

                  })
                }}
              />
            </Col>
          </Row>
        }
        <div className="admin-main">
          <Nav tabs>
            {this.modulesEnabled() && this.notE4scoreCompany() && this.isAdminRole() &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '2' ? "active" : ""}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Partners
                </NavLink>
              </NavItem>
            }
            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
              this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured) &&
              this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
              userHasRole(this.props.loggedInUser, [ROLE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_TRACKASSURED_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER]) &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '1' ? "active" : ""}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Locations
                </NavLink>
              </NavItem>
            }
            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enablePowerYard)
              && userHasRole(this.props.loggedInUser, [ROLE_CARRIER_ADMIN, ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_EZTRACK_ADMIN]) &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '14' ? "active" : ""}
                  onClick={() => {
                    this.toggle('14');
                  }}
                >
                  Asset Types
                </NavLink>
              </NavItem>
            }
            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
              this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard)
              && userHasRole(this.props.loggedInUser, [ROLE_CARRIER_ADMIN, ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER]) &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '13' ? "active" : ""}
                  onClick={() => {
                    this.toggle('13');
                  }}
                >
                  Assets
                </NavLink>
              </NavItem>
            }
            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
              this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured) &&
              this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '3' ? "active" : ""}
                  onClick={() => {
                    this.toggle('3');
                  }}
                >
                  Users
                </NavLink>
              </NavItem>
            }
            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
              this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured) &&
              this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
              userHasRole(this.props.loggedInUser, [ROLE_E4SCORE_ADMIN, ROLE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_TRACKASSURED_ADMIN, ROLE_EZTRACK_ADMIN]) &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '6' ? "active" : ""}
                  onClick={() => {
                    this.toggle('6');
                  }}
                >
                  My Company
                </NavLink>
              </NavItem>
            }

            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
              this.props.loggedInUser.company.enableEzCheckInWelcome) && !userHasRole(this.props.loggedInUser, ROLE_POWERYARD_MANAGER, ROLE_EZTRACK_ADMIN) &&
              <NavItem>
                <NavLink
                  className={this.state.activeTab === '11' ? "active" : ""}
                  onClick={() => {
                    this.toggle('11');
                  }}
                >
                  Integration Settings
                </NavLink>
              </NavItem>
            }

          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <div className="flex-column flex flex-grow-1">
                {this.state.activeTab === "1" && !this.state.loader ? (
                  <Locations
                    setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
                    itemsPerPage={this.props.itemsPerPage}
                    hideFilters={this.state.hideFilters}
                    history={this.props.history}
                    user={this.props.loggedInUser}
                    company={this.props.loggedInUser.company}
                  >
                  </Locations>
                ) : false}
              </div>
            </TabPane>
            {this.modulesEnabled() && this.notE4scoreCompany() && this.isAdminRole() &&
              <TabPane tabId="2">
                <div className="flex-column flex flex-grow-1">
                  {this.state.activeTab === "2" && !this.state.loader ? (
                    <Carriers
                      setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
                      itemsPerPage={this.props.itemsPerPage}
                      hideFilters={this.state.hideFilters}
                      company={this.props.loggedInUser.company}
                      history={this.props.history}
                      currentLocation={this.props.currentLocation}
                    >
                    </Carriers>) : false}
                </div>
              </TabPane>
            }
            <TabPane tabId="3">
              <div className="flex-column flex flex-grow-1">
                {this.state.activeTab === "3" && !this.state.loader ? (
                  <Users
                    loggedInUser={this.props.loggedInUser}
                    setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
                    itemsPerPage={this.props.itemsPerPage} hideFilters={this.state.hideFilters}
                    history={this.props.history}
                    company={this.props.loggedInUser.company}
                  >
                  </Users>) : false}
              </div>
            </TabPane>
            <TabPane tabId="6">
              <div className="flex-column flex flex-grow-1">
                {this.state.activeTab === "6" && !this.state.loader ? (
                  <Company company={this.props.loggedInUser.company} onChange={(company) => {
                    this.props.setUsersCompany(company);
                  }} />) : false}
              </div>
            </TabPane>
            {/* <TabPane tabId="4">
              <div className="flex-column flex flex-grow-1">
                {this.state.activeTab === "4" && !this.state.loader ? (
                  <Companies
                    company={this.props.defaultCompany}
                    save={(values, setErrors) => {
                      saveCompany(values, (company) => {
                        showInfo("Successfully updated company")
                        this.props.setUsersCompany(company);
                      }, () => {
                        showError("Failed to updated company");
                      })
                    }}
                  />) : false}
                {this.props.superAdmin && <div className="text-right p-1"
                  title={Config.version}>Tag: {Config.gitTag || "Unknown"}</div>}
              </div>
            </TabPane> */}
            {/* <TabPane tabId="10">
              {this.state.activeTab === "10" && !this.state.loader ? (
                <ReferenceNumberTypes
                  company={this.props.loggedInUser.company}
                  loggedInUser={this.props.loggedInUser}
                  defaultCompany={this.props.defaultCompany}
                  onChange={(referenceNumberTypes) => this.props.setUsersReferenceNumberTypes(Object.values(referenceNumberTypes))}
                  onCompanyChange={(company) => {
                    this.props.setUsersCompany(company);
                  }}
                />) : false}
            </TabPane> */}
            <TabPane tabId="11">
              {this.state.activeTab === "11" && !this.state.loader ? (
                <TrackAssuredSettings company={this.props.loggedInUser.company} onChange={company => {
                  this.props.setUsersCompany(company);
                }} />) : false}
            </TabPane>

            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
              this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured) &&
              userHasRole(this.props.loggedInUser, [ROLE_CARRIER_ADMIN, ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_TRACKASSURED_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER]) &&
              <TabPane tabId="13">
                {this.state.activeTab === "13" && !this.state.loader ? (
                  <PingAssets user={this.props.loggedInUser} title={"Ping Assets"} get={downloadPingAssetsForTable}
                    company={this.props.loggedInUser.company}
                    onChange={state => { this.props.setUsersCompany(this.props.loggedInUser.company); }} />
                ) : false}
              </TabPane>
            }
            {(this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured) &&
              <TabPane tabId="14">
                {this.state.activeTab === "14" && !this.state.loader ? (
                  <EZTrackEquipment user={this.props.loggedInUser} title={"Equipment Types"}
                    get={downloadEZTrackEquipmentForTable}
                    company={this.props.loggedInUser.company}
                    onChange={state => {
                      this.props.setUsersCompany(this.props.loggedInUser.company);
                    }} />) : false}
              </TabPane>
            }

          </TabContent>
        </div>
      </div>
    );
  }

  isAdminRole() {
    return userHasRole(this.props.loggedInUser, [ROLE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_TRACKASSURED_ADMIN, ROLE_EZTRACK_ADMIN, ROLE_EZTRACK_MANAGER]);
  }

  notE4scoreCompany() {
    return this.props.defaultCompany && this.props.defaultCompany.name !== "e4score";
  }

  modulesEnabled() {
    return this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
      this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured;
  }
}


function mapStateToProps(state) {
  return {
    loggedInUser: state.login.user,
    itemsPerPage: state.assets.itemsPerPage
  }
}

function mapDispatchToProps(dispatch) {
  return ({
    setUsersCompany: function (company) {
      dispatch(setUsersCompany(company));
    },
    setUsersReferenceNumberTypes: function (referenceNumberTypes) {
      dispatch(storeUserCompanyReferenceNumberTypes(referenceNumberTypes));
    },
    setItemsPerPage: function (itemsPerPage) {
      dispatch(storeItemsPerPage(itemsPerPage));
    }
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));