import React from "react";
import * as uuid from "uuid";
import {Tooltip} from "reactstrap";
import {latLongDistance} from "../../common/utilities";

export class RangeAlertCircle extends React.Component{
    constructor(props) {
        super();
        this.disabled = "Disabled";
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        }
    }

    getColor(rangeAlert) {
        if(!rangeAlert){
            return "black";
        }else if(rangeAlert === "NoCoordinates" || rangeAlert === "Disabled"){
            return "black";
        }else if(rangeAlert === "OutOfRange"){
            return "red";
        }else if(rangeAlert === "InRange"){
            return "green";
        }else {
            return "black";
        }
    }

    toggle(){
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    render() {
        if(!this.props.rangeAlert && !this.props.showBlack){
            return <div/>;
        }
        return <span className={this.props.showOn === "progress" ? "geofence" : ""}>
            <i id={this.state.id} className="fa fa-dot-circle" aria-hidden="true"
               style={{marginLeft: "10px", color: this.getColor(this.props.rangeAlert)}}/>
            <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={() => this.toggle()}>
                {this.getTooltip()}
            </Tooltip>
        </span>
            ;
    }

    getTooltip() {
        if(!this.props.rangeAlert || !this.props.geoFenceRange || (this.props.rangeAlert === this.disabled || this.props.rangeAlert === "NoCoordinates")){
            if(this.props.rangeAlert === this.disabled) return this.disabled;
            return "Not Calculated";
        }
        return <>
            Geo-fence Radius: {
            this.getGeoFenceRange()
        } {this.props.distanceUnitsOfMeasure === "Kilometers" ? "km" : "mi"}
            <br/>
            Distance from Location: {latLongDistance(
            this.props.latitude,
            this.props.longitude,
            this.props.locationLatitude,
            this.props.locationLongitude,
            this.props.distanceUnitsOfMeasure)
        }
        </>;
    }

    getGeoFenceRange() {
        if (this.props.distanceUnitsOfMeasure === "Kilometers") {
            return (this.props.geoFenceRange / 1000).toFixed(2);
        }
        return (this.props.geoFenceRange / 1609.34).toFixed(2);
    }
}