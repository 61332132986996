import * as React from "react";
import { Button } from "reactstrap";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { saveNewEZTrackEquipment } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import EZTrackEquipmentEdit from "./EZTrackEquipmentEdit";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class EZTrackEquipment extends React.Component {
  tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      sortable: true,
      filterable: true
    },
    {
      Header: 'Asset ID',
      accessor: 'id',
      sortable: false,
      filterable: false
    },
    {
      Header: 'Base Type',
      accessor: 'baseEquipment.equipmentType',
      sortable: true,
      filterable: true
    },
    {
      Header: 'Temp Controlled',
      accessor: 'temperatureControlled',
      filterable: true,
      sortable: true,
      Cell: row => {
        return row.original.temperatureControlled ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={event => {
              this.setState({ tempFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.tempFilter}
          >
            <option value="undefined" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },
    
    {
      Header: 'Advance Temp Controlled',
      accessor: 'reefer',
      filterable: true,
      sortable: true,
      Cell: row => {
        return row.original.reefer ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={event => {
              this.setState({ advTempFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.advTempFilter}
          >
            <option value="undefined" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },

    {
      Header: 'Default',
      accessor: 'def',
      filterable: false,
      sortable: true,
      Cell: row => {
        return row.original.def ? "Yes" : "No";
      },
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      filterable: true,
      sortable: true,
      Cell: row => {
        return row.original.enabled ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) =>
        <>
          <select
            ref={this.enabledFilterRef}
            onChange={event => {
              this.setState({ enabledFilter: event.target.value });
              onChange(event.target.value)
            }}
            style={{ width: "100%" }}
            value={this.state.enabledFilter}
          >
            <option value="undefined" hidden={true}>Select</option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
    },
    {
      Header: 'Action',
      accessor: 'action',
      sortable: false,
      filterable: false,
      Cell: row => {
        return <Button title="Edit Asset" aria-label="Edit Asset"
          onClick={() => this.editAsset(row.original)}><i
            className="fa fa-edit" /></Button>
      }
    }
  ];
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.enabledFilterRef = React.createRef();
    let localStorageState = JSON.parse(localStorage.getItem("eztrack-admin-settings"));
    if (localStorageState != null) {
      this.state = {
        enabledFilter: localStorageState.enabledFilter,
        tempFilter: localStorageState.tempFilter,
        advTempFilter: localStorageState.advTempFilter,
        assetUnderEdit: localStorageState.assetUnderEdit,
      }
    } else {
      this.state = {
        assetUnderEdit: {},
      }
    }

  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company.uuid !== this.props.company.uuid) {
      this.tableRef.current.fetchData();
    }
  }
  componentWillUnmount() {
    localStorage.setItem("eztrack-admin-settings", JSON.stringify({
      enabledFilter: this.state.enabledFilter,
      tempFilter: this.state.tempFilter,
      advTempFilter:this.state.advTempFilter,
      assetUnderEdit: this.state.assetUnderEdit,
    }))
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  startNewAsset() {
    this.setState({
      showModal: true,
      editingAsset: {
        name: "",
        baseEquipment: {},
        enabled: true,
        isNew: true
      }
    });
  }
  renderModal() {
    return (
      <EZTrackEquipmentEdit
        assetUnderEdit={this.state.editingAsset}
        loadedAsset={this.state.loadedAsset}
        cancel={() => this.cancelDialog()}
        save={(a, onSuccess, onError) => {
          this.setState({ loadedAsset: true })
          this.saveAsset(a, onSuccess, onError);
        }}
        onChange={(state) => {
          this.setState({ editingAsset: state })
        }}
        showModal={this.state.showModal}
      />
    );
  }
  cancelDialog() {
    this.setState({ showModal: false, editingAsset: { baseEquipment: {} } });
  }
  editAsset(ezTrackEquipmentType) {
    this.setState({ showModal: true, editingAsset: ezTrackEquipmentType })
  }
  saveAsset(asset, onSuccess, onError) {
    saveNewEZTrackEquipment(asset, (response) => {
      this.setState({
        loadedAsset: false
      })
      if (response.code === '1000') {
        showError(response.message);
      } else {
        showInfo("Equipment Type Saved");
        this.tableRef.current.fetchData();
        this.props.onChange(this.state);
        onSuccess();
        this.setState({ showModal: false, editingAsset: {} });
      }
    }, (failure) => {
      this.setState({
        loadedAsset: false
      })
      showError(failure.message);
      onError();
    });
  }
  clearFilters() {
    this.enabledFilterRef.current.value = undefined;
    this.setState({
      enabledFilter: undefined,
      tempFilter: undefined,
      advTempFilter:undefined,
      trackerTypeFilter: undefined
    })
  }
  render() {
    return (
      <div>
        <div className="column-wrapper text-left">
        <Loader show={this.state.loaded} message={this.spinner}>

        <PaginationFilterTable
           setloaded={(check) => {
                  this.setState({ loaded: check });
                  this.updateSpinner("");
                }}
            onFilterHide={() => {
              this.setState({
                enabledFilter: "undefined",
                tempFilter: "undefined",
                advTempFilter:"undefined",
              });
            }}
            openFiltersCollaps={true}
            ref={this.tableRef}
            get={this.props.get}
            pageType="Admin"
            stateStorage="eztrack-admin-settings"
            clearFilter={this.clearFilters.bind(this)}
            renderModal={this.renderModal()}
            columns={this.tableColumns}
          >
            <Button color="primary" onClick={() => this.startNewAsset()}> <i className="fa fa-plus" /> Add New Asset Type</Button>
          </PaginationFilterTable>
          </Loader>
        </div>

      </div>
    )
  }
}
export default EZTrackEquipment;
