import React from "react";
import * as Yup from "yup";
import {camelCaseToWords, getErrorsFromValidationError, getShipmentLink} from "../../common/utilities";
import Modal from "reactstrap/es/Modal";
import {Button, FormFeedback, FormGroup, Input, InputGroup, ModalBody, ModalHeader, Table} from "reactstrap";
import {Formik} from "formik";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import PropTypes from "prop-types";
import ValidationService from "../../common/ValidationService";

export default class RemindAllModal extends React.Component {
    constructor(props) {
        super(props);
    }

    validationSchema(values) {
        return Yup.object().shape({
        })
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        }
    }

    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        this.props.onSave(values, setErrors);
        return false;
    }


    render() {
        const textCharacterLimit = this.props.characterLimit;
        let limit = this.props.threshold;
        let unit = this.props.thresholdUnit;
        return (
            <Modal isOpen={this.props.open} size={"lg"}>
                <ModalHeader>
                    Remind Drivers
                </ModalHeader>
                <ModalBody style={{overflow: 'auto'}}>
                    <Formik
                        onSubmit={(v, o) => {
                            this.onSubmit(v, o)
                        }}
                        validate={this.validate(this.validationSchema.bind(this))}
                        initialValues={this.props}
                        render={
                            ({
                                 values,
                                 errors,
                                 touched,
                                 handleChange,
                                 handleBlur,
                                submitForm
                             }) => <>
                                {this.props.error &&
                                <Row>
                                    <Col md={12}>
                                        <p style={{color: "red"}}>{this.props.error}</p>
                                    </Col>
                                </Row>
                                }
                                <Row>
                                    <Col>
                                        <p>
                                            The message is then sent to all open dispatches that are not complete where
                                            the "text" the driver is still enabled and no update has been received in {limit} {unit}.
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Table responsive>
                                            <thead>
                                            <tr>
                                                <th>{this.props.user.company.shipmentIdDisplay}</th>
                                                <th>Name</th>
                                                <th>Number</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {!this.props.dispatches || this.props.dispatches.length === 0 &&
                                                <tr>
                                                    <td colSpan={3} style={{textAlign: "center"}}>No Results</td>
                                                </tr>
                                            }
                                            {this.props.dispatches && this.props.dispatches.length > 0 && this.props.dispatches.map((dispatch) =>
                                                <>
                                                    <tr>
                                                        <td>
                                                            {getShipmentLink(this.props.user, dispatch.currentAssetRoster.shipment.assetId, this.props.user.company.uuid)}
                                                        </td>
                                                        <td>
                                                            {dispatch.currentAssetRoster.driver && dispatch.currentAssetRoster.driver.firstname}
                                                        </td>
                                                        <td>
                                                            {dispatch.currentAssetRoster.driver && ValidationService.formatPhone(dispatch.currentAssetRoster.driver.countryCode, dispatch.currentAssetRoster.driver.cell)}
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>


                                <p>{this.props.user.firstname} with {this.props.user.company.name}</p>
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            defaultValue={this.props.defaultValue}
                                            style={{minHeight: "100px"}}
                                            type={'textarea'}
                                            name={'message'}
                                            valid={touched['message'] && !errors['message']}
                                            invalid={touched['message'] && !!errors['message']}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['message']}
                                        />
                                        <FormFeedback>{errors['message']}</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <p>[EBW Link], Thank You</p>
                                <Row>
                                    <Col>
                                        <p className={"float-right"}
                                           style={{color: (this.getNumber(textCharacterLimit, values) < 0 ? "red" : null)}}
                                        >Text remaining: {this.getNumber(textCharacterLimit, values)}</p>
                                    </Col>
                                </Row>
                                <div className="text-right">
                                    <Button color="primary" onClick={() => this.props.onCancel()}>Cancel</Button> &nbsp;
                                    <Button color="primary" type="submit"
                                            disabled={!(this.getNumber(textCharacterLimit, values) >= 0)}
                                            onClick={()=>{
                                                if((this.getNumber(textCharacterLimit, values) >= 0)){
                                                    submitForm();
                                                }
                                            }}
                                    >
                                        Send Reminders
                                    </Button>
                                </div>
                            </>
                        }
                    >
                    </Formik>
                </ModalBody>
            </Modal>
        );
    }

    getNumber(textCharacterLimit, values) {
        let value = values['message'];
        if(!value){
            value = this.props.defaultValue;
        }

        return textCharacterLimit - (value ? value.length : 0);
    }
}

RemindAllModal.propTypes = {
    open: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    characterLimit: PropTypes.number,
    threshold: PropTypes.number,
    thresholdUnit: PropTypes.string,
    defaultValue: PropTypes.string
};