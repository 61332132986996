import React, {Component} from "react";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import {promiseCarriersByPrefix} from "../../NetworkHelpers";
import {Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import * as PropTypes from "prop-types";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormDateTimeField from "../../common/formik-free/FFFormDateTimeField";
import {FFAsyncCreatableSelect} from "../../common/formik-free/FFAsyncCreatableSelect";

export class DispatchBasicInformationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            touched: {}
        }
    }

    onChange = (field, value) => {
        this.props.onChange(field, value);
        let touched = {...this.state.touched};
        touched[field] = true;
        this.setState({touched: touched});
    };

    render() {
        function setLabelsAndValues(transportationServiceProviders) {
            if(transportationServiceProviders){
                for (const provider of transportationServiceProviders) {
                    if(!provider.label){
                        provider.label = provider.name;
                    }
                    if(!provider.value){
                        provider.value = provider.uuid || provider.name;
                    }
                }
            }
            return transportationServiceProviders;
        }

        return <Card className="dispatch-card-sub demo-card">
            <CardHeader><h6 className="demo-card-header-title">Basic Information</h6></CardHeader>
            <CardBody>
                <FFFormSelectField
                    label="Begin Dispatch"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-clock"
                    fieldname="dispatchTrigger"
                    name="dispatchTrigger"
                    errors={this.props.errors.dispatchTrigger}
                    touched={this.state.touched.dispatchTrigger}
                    onBlur={this.props.onBlur}
                    value={this.props.values.dispatchTrigger}
                    options={["Manually", "At Date/Time"]}
                    noSearch={true}
                    isDisabled={this.props.disabled}
                    onChange={(select)=>{
                        this.onChange("dispatchTrigger", select.value);
                    }}
                />
                {this.props.values["dispatchTrigger"] === "At Date/Time" &&
                <FFFormDateTimeField
                    label={"Dispatch Date/Time"}
                    placeholder="Enter Value"
                    icon="fa-calendar"
                    fieldname="startDate"
                    error={this.props.errors.startDate}
                    touched={this.state.touched.startDate}
                    onBlur={this.props.onBlur}
                    value={this.props.values.startDate || new Date()}
                    timezone={Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : "UTC"}
                    isDisabled={this.props.disabled}
                    onChange={(value)=>{
                        this.onChange("startDate", value);
                    }}

                />}

                <FormGroup>
                    <Row>
                        <Col lg="2" md="3" sm="4">
                            <Label>
                                Partners
                            </Label>
                        </Col>
                        <Col lg="10" md="9" sm="8">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className={"align-self-start fa fa-building"}/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <FFAsyncCreatableSelect
                                    fieldname={"transportationServiceProviders"}
                                    promise={promiseCarriersByPrefix}
                                    placeholder={"Select a Partner"}
                                    onBlur={this.props.onBlur}
                                    onChange={(value) => {
                                        this.onChange("transportationServiceProviders", value);
                                    }}
                                    value={setLabelsAndValues(this.props.values.transportationServiceProviders)}
                                />
                                <FormFeedback/>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>
            <br/>
            </CardBody>
        </Card>;
    }
}

DispatchBasicInformationCard.propTypes = {
    errors: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    disabled: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};