import React, { Component } from 'react';
import {
  Col,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Tooltip
} from "reactstrap";
import * as PropTypes from "prop-types";
import * as uuid from 'uuid';

//2 x 10
class FormField extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      id: "ID_" + uuid.v4()
    };
  }

  icon() {
    if (this.props.icon) {
      return <i className={"fa " + this.props.icon} />
    } else if (this.props.iconText) {
      return <strong>{this.props.iconText}</strong>;
    }
    return " ";
  }


  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <FormGroup>
        <Row>
          <Col lg="2" md="3" sm="4">
            <Label>{this.props.label} &nbsp;
              <span className={'text-danger'}>{this.props.label && this.props.required ? " *" : ""}</span></Label>
            {this.props.helpMessage &&
              <div>
                <i style={{
                  position: "absolute",
                  right: 0,
                  top: "5px",
                  color: "grey"
                }}
                  className={"fa fa-question-circle"}
                  id={this.state.id}
                />
                <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
                  {this.props.helpMessage}
                </Tooltip>
              </div>
            }
          </Col>
          <Col lg="10" md="9" sm="8">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{this.icon()}</InputGroupText>
              </InputGroupAddon>
              {this.props.children}
              <FormFeedback>{this.props.errors[this.props.fieldname]}</FormFeedback>
            </InputGroup>
          </Col>
        </Row>
      </FormGroup>
    )
  }
}

FormField.propTypes = {
  label: PropTypes.any,
  required: PropTypes.bool,
  icon: PropTypes.any,
  iconText: PropTypes.any,
  errors: PropTypes.any,
  fieldname: PropTypes.any,
  helpMessage: PropTypes.any
};


export default FormField;