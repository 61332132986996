import React from 'react';
import { EventLocation } from "./EventLocation";
import { DwellAlert } from "../../Dispatch/components/AssetsHistoryComponent";
import { ArrivalAlertClock } from "./ArrivalAlertClock";
import { ArrivalTool } from "./ArrivalTool";
import { ReportTime } from "./ReportTime";
import { DwellTimeTool } from "./DwellTimeTool";
import { latLongDistance } from "../../common/utilities";
import { DwellLocationTool } from "./DwellLocationTool";
import { ArrivalDateTool } from "./ArrivalDateTool";
import { DwellDateTool } from "./DwellDateTool";
import { RangeAlertCircle } from "./RangeAlertCircle";
import { DwellAlertTooltip } from "../../Dispatch/components/DwellAlertTooltip";
import { LocationTooltip } from "../../Dispatch/components/LocationTooltip";
const ReactMarkdown = require('react-markdown');
const renderUpdate = (event) => {
    return <div>
        <EventLocation
            showDate={true}
            geocodeLocation={event.geocodeLocation}
            latitude={event.latitude}
            longitude={event.longitude}
            timezone={event.timezone}
            date={event.date}
        />
    </div>;
};

class Node extends React.Component {
    seperateSteptext() {
        var location = "";
        var address = "";
        var type = "";
        var startType = "";
        var startDate = "";
        var date = "";
        var dwellTime = "";
        let enrouteCityState = "";
        let stepStr = this.props.step.stepText;
        let seperatedArr = stepStr.split("\n");
        seperatedArr = seperatedArr.filter(it => it !== "" && it !== "&nbsp;");
        if (this.props.ix !== 3) {
            type = seperatedArr[0];
        }
        type = seperatedArr[0];
        if (this.props.ix === 3) {
            type = seperatedArr[0];
            enrouteCityState = seperatedArr[1];
        }
        if (this.props.ix !== 3) {
        date = (seperatedArr[1] ? seperatedArr[1] : "");
    }

        if (this.props.ix === 0) {
            startType = seperatedArr[2];
            startDate = seperatedArr[3];
        }
        if (this.props.ix === 1 || this.props.ix === 4) {
            let locName = seperatedArr.find(it => it.includes("**"));
            location = locName;
            let index = seperatedArr.findIndex(it => it === locName);
            for (var i = index + 1; i < seperatedArr.length; i++) {
                address += seperatedArr[i] + " ";
            }

        }
        if (this.props.ix === 2) {
            dwellTime = seperatedArr[2];
        }
        let obj = {
            type: type, date: date, dwellTime: dwellTime, location: location, address: address, startDate: startDate, startType: startType,enrouteCityState: enrouteCityState
        }
        return obj;

    }
    getEnrouteTime(arr){
        let enrouteObj = arr.filter(it=> it.status == 7.1);
        if(enrouteObj.length > 0){
            enrouteObj = enrouteObj[enrouteObj.length -1];
            return enrouteObj.datee;
        } else {
            return "";
        }
    }
    render() {
        const arrivalAlert = this.props.alert && this.props.alert.arrivalAlert;
        const rangeAlert = this.props.alert && this.props.alert.rangeAlert;
        const tractorDwell = this.props.alert && this.props.alert.tractorDwell;
        const trailerDwell = this.props.alert && this.props.alert.trailerDwell;
        // const locationDwell = this.props.alert && this.props.alert.arrivalAlert;
        const returnedObj = this.seperateSteptext();
        const enrouteTime = this.getEnrouteTime(this.props.currentLatLong && this.props.currentLatLong);
        const type = returnedObj.type;
        const date = returnedObj.date;
        const startType = returnedObj.startType;
        const startDate = returnedObj.startDate;
        const location = returnedObj.location;
        const address = returnedObj.address;
        const dwellTime = returnedObj.dwellTime;
        const enrouteCityState = returnedObj.enrouteCityState;
        return <li className={this.props.cancelled !== "Cancelled" ? this.props.active ? "is-active" : "" :   "deactive"}>

            {(this.props.ix === 1 || this.props.ix === 4) &&
                <ArrivalAlertClock
                    showOn={"progress"}
                    arrivalAlert={this.props.cancelled !== "Cancelled" ?arrivalAlert && arrivalAlert.arrivalAlert : ""}
                    stopAppointment={this.props.cancelled !== "Cancelled" ?arrivalAlert && arrivalAlert.appointment : ""}
                    graceInMins={this.props.cancelled !== "Cancelled" ?arrivalAlert && arrivalAlert.graceInMins : ""}
                    timezone={this.props.cancelled !== "Cancelled" ?arrivalAlert && arrivalAlert.timezone : ""}
                    allDay={this.props.cancelled !== "Cancelled" ?arrivalAlert && arrivalAlert.allDay : ""}
                />}
            {(this.props.ix === 1 || this.props.ix === 2 || this.props.ix === 4 || this.props.ix === 5) &&
                <RangeAlertCircle
                    showOn={"progress"}
                    distanceUnitsOfMeasure={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.distanceUnitsOfMeasure:""}
                    geoFenceRange={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.geoFenceRadiusInMeters:""}
                    rangeAlert={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.rangeAlert:""}
                    latitude={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.latitude:""}
                    longitude={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.longitude:""}
                    locationLatitude={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.locationLatitude:""}
                    locationLongitude={this.props.cancelled !== "Cancelled" ? rangeAlert && rangeAlert.locationLongitude:""}
                    showBlack
                />}
            {(this.props.ix === 2 || this.props.ix === 5) && tractorDwell &&
                <DwellAlertTooltip
                    showOn={"progress"}
                    style={{ marginLeft: "10px" }}
                    type={"Tractor"}
                    alert={this.props.cancelled !== "Cancelled" ? tractorDwell.dwellAlert: ""}
                    dwellInSeconds={this.props.cancelled !== "Cancelled" ? tractorDwell.dwellInSeconds: ""}
                    criticalDwellInSeconds={this.props.cancelled !== "Cancelled" ? tractorDwell.criticalDwellAlertInSeconds: ""}
                    criticalDwellUnit={this.props.cancelled !== "Cancelled" ? tractorDwell.criticalDwellAlertUnit: ""}
                    warningDwellInSeconds={this.props.cancelled !== "Cancelled" ? tractorDwell.warningDwellAlertInSeconds: ""}
                    warningDwellUnit={this.props.cancelled !== "Cancelled" ? tractorDwell.warningDwellAlertUnit: ""}
                />
            }
            {(this.props.ix === 2 || this.props.ix === 5) && trailerDwell &&
                <DwellAlertTooltip
                    showOn={"progress"}
                    type={"Trailer"}
                    alert={this.props.cancelled !== "Cancelled" ? trailerDwell.dwellAlert: ""}
                    dwellInSeconds={this.props.cancelled !== "Cancelled" ? trailerDwell.dwellInSeconds: ""}
                    criticalDwellInSeconds={this.props.cancelled !== "Cancelled" ? trailerDwell.criticalDwellAlertInSeconds: ""}
                    criticalDwellUnit={this.props.cancelled !== "Cancelled" ? trailerDwell.criticalDwellAlertUnit: ""}
                    warningDwellInSeconds={this.props.cancelled !== "Cancelled" ? trailerDwell.warningDwellAlertInSeconds: ""}
                    warningDwellUnit={this.props.cancelled !== "Cancelled" ? trailerDwell.warningDwellAlertUnit: ""}
                />
            }
            <ReactMarkdown source={type} />
            {(this.props.ix === 0) &&
                <DwellDateTool
                    arrivalAlert={"Created Date: " + date}
                />
            }
            <ReactMarkdown source={this.props.cancelled !== "Cancelled" ? location  : ""} />
            <ReactMarkdown source={this.props.cancelled !== "Cancelled" ? startType : ""} />
            {(this.props.ix === 0) &&
                <DwellDateTool
                    arrivalAlert={this.props.cancelled !== "Cancelled" ?"Start Date: " + startDate ? startDate :"" : ""}
                />
            }
           {(this.props.ix === 3 && this.props.active === true && this.props.cancelled && this.props.cancelled !== "Cancelled") &&
                                <ReactMarkdown source={this.props.currentLatLong ? this.props.currentLatLong[this.props.currentLatLong.length-1].geocodeLocation !== null ? this.props.currentLatLong[this.props.currentLatLong.length-1].geocodeLocation.locality+","+this.props.currentLatLong[this.props.currentLatLong.length-1].geocodeLocation.state : "N/A": "N/A"} />
               
            }
            {(this.props.ix === 3 && this.props.active === true && this.props.cancelled && this.props.cancelled !== "Cancelled") &&
                  <DwellDateTool
                  arrivalAlert={"Enroute Time: "+enrouteTime}
              />
            }
 
            {/* {this.props.step.locationUpdates && this.props.step.locationUpdates.map(this.props.renderUpdate)} */}
            {/* {(this.props.ix === 2) &&
                <DwellTimeTool

                    arrivalAlert={dwellTime}

                />} */}
            {(this.props.ix !== 0 && this.props.ix !== 3) &&
                <ArrivalDateTool
                    arrivalAlertToolColor = {this.props.step.alert && this.props.step.alert.arrivalAlert && this.props.step.alert.arrivalAlert.arrivalAlert}
                    arrivalAlert={type + " : " + (this.props.cancelled !== "Cancelled" ? date ? date : "Not Calculated": "")}
                />
            }
            {(this.props.ix === 1 || this.props.ix === 4) &&
                <DwellLocationTool
                    arrivalAlert={this.props.cancelled !== "Cancelled" ? address : ""}
                />}
                {(this.props.ix === 3 && this.props.active === true && this.props.cancelled && this.props.cancelled !== "Cancelled") &&
                <ReactMarkdown source={"Remaining Miles: "+   latLongDistance(
                    this.props.laststop.latitude,
                    this.props.laststop.longitude,
                    this.props.currentLatLong[this.props.currentLatLong.length-1].latitude,
                    this.props.currentLatLong[this.props.currentLatLong.length-1].longitude,
                    this.props.user.company.distanceUnitsOfMeasure)
            } />
               
            }

        </li>
    }
}

const ShipmentTAProfileProgressBar = ({ progress,cancelled,laststop,currentLatLong,company, user }) => {
    return <div>
        <br /><br />
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <ul className="list-unstyled multi-steps">
                {progress.steps.map(((step, ix) => {
                    return <Node
                        key={ix}
                        step={step}
                        company={company}
                        laststop={laststop}
                        currentLatLong={currentLatLong}
                        renderUpdate={(e) => renderUpdate(e)}
                        ix={ix}
                        cancelled = {cancelled}
                        active={step.active}
                        user={user}
                        alert={step.alert}
                    />
                }))}
            </ul>
        </div>
        <div className="d-block d-md-none d-lg-none d-xl-none">
            <ul className="list-unstyled multi-steps-vertical">
                {progress.steps.map(((step, ix) => {
                    return <Node
                        key={ix}
                        step={step}
                        laststop={laststop}
                        currentLatLong={currentLatLong}
                        company={company}
                        renderUpdate={(e) => renderUpdate(e)}
                        ix={ix}
                        cancelled = {cancelled}
                        active={step.active}
                        user={user}
                        alert={step.alert}
                    />
                }))}
            </ul>
        </div>
    </div>;
};


export default ShipmentTAProfileProgressBar