import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
} from 'reactstrap';
import {checkLogin, requestPasswordReset} from "../../NetworkHelpers";
import {withRouter} from 'react-router';
import {showError, showInfo} from "../../MainApp"

import FrontPageInformationCard from "../../Login/components/FrontPageInformationCard";
import Config from "../../Config";

class PasswordResetRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: ""
        }
    }

    componentWillUnmount() {
        document.body.style.background = null;
    }

    componentDidMount() {
        window.scrollTo(0,0);
        document.body.style.background = null;
        document.body.style.backgroundColor = "#000";
        this.checkLogin();
    }

    registerClicked(e ) {
        e.preventDefault();
        this.props.history.push("/register");
    }


    checkLogin() {
        checkLogin((user)=>{this.props.login(user.username, user);}, ()=>{});
    }

    onUsernameChange(username) {
        this.setState({username: username});
    }

    requestReset() {

        if ( !this.state.username ) {
            showError("Please enter an email address.");
        } else {
            requestPasswordReset(this.state.username, () => {
                    showInfo("If you have an existing account an email will be sent with a link for resetting your password.");
                    this.props.history.push("/");
                },
                () => {
                    showError("Unable to request password reset, please try again later.");
                });
        }
    }

    showLogin() {
        this.props.history.push("/");
    }

    getCardWidth() {
        return Config.hideInfoOnLogin ? 6 : 12;
    }

    render() {
        return (
            <div className="app flex-row align-items-center animated fadeIn">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={this.getCardWidth()}>
                            <CardGroup>
                                <Card className="p-4 text-left login-card" >
                                    <CardBody>
                                        <img alt="Logo" className="login-logo" src={Config.preLoginBrand ? Config.preLoginBrand : "/assets/img/brand/ezc_by-e4.png"} />
                                        <div className="v-space" />
                                        <Form>
                                            <h1>Password Reset</h1>
                                            <p>Please provide your email address below.</p>
                                            <div className="v-space" />
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="fa fa-user"/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" placeholder="Email Address" value={this.state.username} autoComplete="username" onChange={(e)=>this.onUsernameChange(e.target.value)}  />
                                            </InputGroup>
                                            <div className="v-space" />
                                            <Row>
                                                <Col xs="12">
                                                    <Button color="primary" onClick={(e)=>{e.preventDefault();this.requestReset()}}>Continue</Button> &nbsp;&nbsp;&nbsp;
                                                    <Button color="link" className="muted-link" onClick={(e)=>{e.preventDefault();this.showLogin()}}>Login</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <div className="v-space" />
                                        <div className="v-space" />
                                    </CardBody>
                                </Card>
                                {!Config.hideInfoOnLogin &&
                                <FrontPageInformationCard
                                    className={"text-white bg-primary"} onClick={(e) => this.registerClicked(e)}/>
                                }
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default withRouter(PasswordResetRequest);