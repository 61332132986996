import React, { Component } from 'react';
import './App.scss';
import Config from "./Config.js";


import 'react-datepicker/dist/react-datepicker.css';
// import {HashRouter as Router} from "react-router-dom";

// const store = createStore(rootReducer, composeWithDevTools(
//     applyMiddleware(thunkMiddleware)
// ));
class Maintenance extends Component {
    componentWillMount() {
        if (!Config.debug) {
            console.log = function () {
                //dead
            }
        }
    }

    render() {

        return (
            <div className="bg-maintenance">
                <div class="container text-center">
                    <a className='mt-5' href={Config.footerBrandUrl} target='_blank'><img className='w-50 m-auto mt-3' src='/assets/img/maintenance/ls-downTime.png' /></a>
                    <h3 className='text-white  mt-5'>PowerYard is undergoing planned maintenance and will be unavailable on {Config.downWindow}.

                    </h3>
                    <h4 className='text-white'>Please use your location’s back-up process to manage yard tasks during this downtime.  Drivers will update the system when back up. Text “NotifyMe” to {Config.maintenancePhone} to receive a text notification when PowerYard is back up.
                    </h4>
                    <h4 className='text-white'>Thank you for your understanding.</h4>

                </div>
            </div>
        );
    }
}

export default Maintenance;





