import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";

class FormTextAreaField extends Component {

    icon() {
        if ( this.props.icon ) {
            return <i className={"align-self-start fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return this.props.iconText;
        }
        return " ";
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText className="align-top">{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <Input type="textarea" placeholder={this.props.placeholder}
                                   name={this.props.fieldname}
                                   valid={this.props.touched && this.props.touched[this.props.fieldname] && !this.props.errors[this.props.fieldname]}
                                   invalid={this.props.touched && this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]}
                                   onChange={this.props.handleChange}
                                   onBlur={this.props.handleBlur}
                                   value={this.props.values && this.props.values[this.props.fieldname]}
                                   disabled={this.props.isDisabled}
                            />
                            <FormFeedback>{this.props.errors && this.props.errors[this.props.fieldname]}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FormTextAreaField;