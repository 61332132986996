import React, {Component} from "react";
import AsyncSelect from 'react-select/lib/Async';
import * as PropTypes from "prop-types";
import {promiseLocationSearch} from "../NetworkHelpers";

export class LocationsSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <AsyncSelect
            name={this.props.name}
            placeholder={this.props.placeholder}
            valid={!this.props.errors[this.props.name]}
            isMulti={!this.props.single}
            invalid={
                this.props.touched[this.props.name]
                && !!this.props.errors[this.props.name]
            }
            defaultOptions
            minimumInput={0}
            onChange={(v)=>this.props.onLocationsChange(v)}
            onBlur={this.props.onBlur}
            value={this.props.values[this.props.name] ? this.props.values[this.props.name].map((v)=>{return {value:v.uuid, label:v.name}}) : null}
            className={"basic-single short-select select-in-form" + ((this.props.touched[this.props.name] && !!this.props.errors[this.props.name]) ? " is-invalid" : "")}
            // classNamePrefix="select"
            loadOptions={promiseLocationSearch}
            isClearable={true}
        />;
    }
}

LocationsSelect.propTypes = {
    name: PropTypes.any,
    placeholder: PropTypes.any,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    fieldname: PropTypes.any
};