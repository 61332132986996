import React, {Component} from "react";
import Select from "react-select";
import * as PropTypes from "prop-types";
import {downloadStates} from "../../NetworkHelpers";
import {showError} from "../../MainApp";

export class FFStateSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
        }
    }

    onCountryChange() {
        downloadStates(this.props.selectedCountry, (json) => {
            this.setStates(json)
        }, (error) => {
            showError(error)
        })
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.onCountryChange();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedCountry != prevProps.selectedCountry) {
            this.onCountryChange();
            this.props.onChange("");
        }
    }


    setStates(json) {
        let stateOptions = json.states.map((s) => {
            return {value: s.name, label: s.name}
        });
        this.setState({
            options: json.states
        })
    }

    lookupLabel( value ) {
        for ( let i=0;i<this.state.options.length;i++ ) {
            if ( this.state.options[i].abbreviation === value ) {
                return this.state.options[i].name;
            }
        }
        return value;
    }


    render() {
        return <Select
            name={this.props.name}
            placeholder={this.props.placeholder}
            valid={!this.props.error}
            invalid={
                this.props.touched
                && !!this.props.error
            }
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            value={this.props.value? {
                label: this.lookupLabel(this.props.value),
                name: this.props.value
            } : null}
            className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
            // classNamePrefix="select"
            options={this.state.options.map((v) => { return {value: v.abbreviation, label: v.name}})}
            isClearable={true}
            isDisabled={this.props.disabled}
        />;
    }
}

FFStateSelect.propTypes = {
    isDisabled: PropTypes.bool,
    name: PropTypes.any,
    placeholder: PropTypes.any,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    fieldname: PropTypes.any
};