import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Formik } from "formik";
import FormTextField from "../../common/FormTextField";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import FormField from "./FormField";
import ShortCodeSelect from '../../common/ShortCodeSelect'
import ContactsList from '../../common/ContactsList'
import Config from "../../Config";
import FormCheckboxField from "../../common/FormCheckboxField";
import ValidationService from "../../common/ValidationService";
import FormPhoneTextField from "../../common/FormPhoneTextField";
import Select from "react-select";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormSelectCompanyIdField from "../../common/formik-free/FFFormSelectCompanyIdField";
import FFFormSelectLocationField from "../../common/formik-free/FFFormSelectLocationField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class CarrierForm extends Component {
    options = [
        { label: "Logistics Service Provider (Carrier)", value: "Carrier" },
        { label: "Logistics Service Provider (Broker)", value: "Broker" },
        { label: "Logistics Service Provider (Logistics Provider)", value: "LogisticsProvider" },
        { label: "Shipper", value: "Shipper" },
        { label: "Receiver", value: "Receiver" },
        { label: "Customer", value: "Customer" }
    ];


    constructor(props) {
        super(props);
        this.state = {
            showingCarrierContactModal: false,
            editingCarrier: this.props.editingCarrier,
            editingContact: {
                isNew: true,
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.editingCarrier !== prevProps.editingCarrier) {
            this.setState(
                {
                    editingCarrier: this.props.editingCarrier,
                    formValues: this.props.editingCarrier,
                    editingContact: {
                        isNew: this.props.editingCarrier ? this.props.editingCarrier.name !== "" ? false : true : true
                    }
                });
        }

    }


    validationSchema(values) {
        let validationRules = {
            name: Yup.string()
                .required('Name is required.'),
            carrierShortCodes: Yup.array().nullable().notRequired()//.required("Enter at least one short code.")
        };
        return Yup.object().shape(validationRules)
    }

    validationContactSchema(values) {
        let validationRules = {
            firstName: Yup.string().required('First name is required.'),
            lastName: Yup.string().required('First name is required.'),
            email: Yup.string().required('Email is required.').email("Email not valid format").trim(),
            phone: Yup.string().nullable().phone(),
            cell: Yup.string().nullable().phone()
        };

        return Yup.object().shape(validationRules)
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, { abortEarly: false });
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        }
    }

    onSubmitCarrierContact(values, { setFieldValue: setFieldValue }) {
        this.addContact(values, setFieldValue);
        this.setState({
            showingCarrierContactModal: false,
            editingContact: {
                type: "Carrier"
            }
        });
    }

    onSubmit(values, { setSubmitting, setErrors }) {
        this.updateSpinner("Saving...");

        setSubmitting(false);
        values.contacts = this.state.editingCarrier.contacts;
        this.props.save(values, setErrors);
        return false;
    }

    startNewContact() {
        this.setState(
            {
                showingCarrierContactModal: true,
                editingContact: {
                    isNew: true,
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    cell: "",
                    cellCountryCode: "US",
                    phoneCountryCode: "US",
                    type: "Carrier"
                }, touched: {}, showErrors: false
            });
    }

    cancelEdit() {
        this.setState({
            showingCarrierContactModal: false,
            editingContact: {}
        });
    }

    addContact(values) {
        let v = this.state.editingCarrier.contacts;
        if (!v) {
            v = [];
        }
        if (this.state.editingContact.isNew) {
            v.push({
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
                phoneCountryCode: values.phoneCountryCode,
                cellCountryCode: values.cellCountryCode,
                cell: values.cell
            });
        } else {
            for (let i = 0; i < v.length; i++) {
                if (v[i] === this.state.editingContact.originalContact) {
                    v[i] = values;
                    break;
                }
            }
        }
        this.setState(Object.assign({}, {
            editingCarrier: { ...this.state.editingCarrier, contacts: v }
        }), () => {
            this.cancelEdit();
        })
    }

    getLabel(type) {
        for (const option of this.options) {
            if (option.value === type) {
                return option.label;
            }
        }
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }

    renderForm() {

        return (
            <div>
                <Modal isOpen={this.state.showingCarrierContactModal}>
                    <ModalHeader>
                        {this.state.editingContact.isNew ? "Add Contact" : "Edit Contact"}
                    </ModalHeader>
                    <ModalBody>
                        <Formik
                            initialValues={this.state.editingContact}
                            validate={this.validate(this.validationContactSchema.bind(this))}
                            onSubmit={(v, o) => {
                                this.onSubmitCarrierContact(v, o)
                            }}
                        >{
                                ({
                                    values,
                                    errors,
                                    touched,
                                    status,
                                    dirty,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    isValid,
                                    handleReset,
                                    setTouched,
                                    setFieldValue,
                                    setFieldTouched
                                }) => (


                                    <Form onSubmit={(v) => {
                                        return handleSubmit(v)
                                    }}>
                                        <FormTextField
                                            label="First Name"
                                            placeholder=""
                                            icon="fa-id-card"
                                            fieldname="firstName"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            required={true}
                                        />
                                        <FormTextField
                                            label="Last Name"
                                            placeholder=""
                                            icon="fa-id-card"
                                            fieldname="lastName"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            required={true}
                                        />
                                        <FormTextField
                                            label="Email"
                                            icon="fa-building"
                                            placeholder=""
                                            fieldname="email"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            required={true}
                                        />
                                        <FormPhoneTextField
                                            label="Phone"
                                            icon="fa-phone"
                                            fieldname="phone"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={(e) => {
                                                e.target.value = ValidationService.formatPhone(values['countryCode'], e.target.value, true);
                                                handleChange(e);
                                            }}
                                            countryCodeFieldName={"phoneCountryCode"}
                                            onCountryCodeChange={(e) => {
                                                setFieldValue('phoneCountryCode', e.target.value);
                                            }}
                                            touched={touched}
                                            values={values}
                                            placeholder={values["phoneCountryCode"] === "US" || !values["phoneCountryCode"] ? "###-###-#### ext. XXXX" : "#########"}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                        <FormPhoneTextField
                                            label="Mobile"
                                            icon="fa-phone"
                                            fieldname="cell"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={(e) => {
                                                e.target.value = ValidationService.formatPhone(values['cellCountryCode'], e.target.value, false);
                                                handleChange(e);
                                            }}
                                            countryCodeFieldName={"cellCountryCode"}
                                            onCountryCodeChange={(e) => {
                                                setFieldValue('cellCountryCode', e.target.value);
                                            }}
                                            touched={touched}
                                            values={values}
                                            placeholder={values["cellCountryCode"] === "US" || !values["cellCountryCode"] ? "###-###-####" : "#########"}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />

                                        <div className="mb-3 text-right">
                                            <Button color="danger" onClick={() => this.cancelEdit()}>Cancel</Button> &nbsp;
                                            <Button color="primary" type="submit" disabled = {this.props.loadedCarriers}>Save</Button>
                                        </div>
                                    </Form>
                                )}
                        </Formik>
                    </ModalBody>
                </Modal>
                <Formik
                    initialValues={this.state.editingCarrier}
                    validate={this.validate(this.validationSchema.bind(this))}
                    onSubmit={(v, o) => {
                        this.onSubmit(v, o)
                    }}
                >{
                        ({
                            values,
                            errors,
                            touched,
                            status,
                            dirty,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            handleReset,
                            setTouched,
                            setFieldValue,
                            setFieldTouched
                        }) => (


                            <Form onSubmit={(v) => {
                                return handleSubmit(v)
                            }}>
                                <Card className="simple-card">
                                    <CardHeader>Company Info</CardHeader>
                                    <CardBody>
                                        <div>
                                            <FFFormSelectField
                                                label="Partner Type"
                                                required={true}
                                                placeholder="Enter Value"
                                                icon="fa-map-pin"
                                                fieldname="type"
                                                name="type"
                                                // onBlur={handleBlur}
                                                value={this.getLabel(values.type || "Carrier")}
                                                options={this.options}
                                                noSearch={true}
                                                isDisabled={this.props.disabled}
                                                onChange={(v) => {
                                                    this.setState({
                                                        editingContact: {
                                                            type: v,
                                                            isNew: this.props.editingCarrier ? this.props.editingCarrier.name !== "" ? false : true : true
                                                        }
                                                    }, () => {
                                                        setFieldValue("type", v.value);
                                                    })
                                                }}
                                            />
                                            <FormTextField
                                                label="Name"
                                                placeholder="Enter Value"
                                                icon="fa-truck"
                                                fieldname="name"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                required={true}

                                            />
                                            {this.getLabel(values.type || "Carrier") !== "Shipper" && this.getLabel(values.type || "Carrier") !== "Receiver" && this.getLabel(values.type || "Carrier") !== "Customer" &&
                                                <FFFormSelectCompanyIdField
                                                    create
                                                    icon="fa-id-card"
                                                    label="US Dot Number"
                                                    placeholder="Enter Value"
                                                    fieldname="usDotNumberCompany"
                                                    error={null}
                                                    touched={false}
                                                    onChange={(company) => {
                                                        this.updateState(company, setFieldValue, "usDotNumber");
                                                    }}
                                                    disabled={this.props.disabled}
                                                    type={"usDotNumber"}
                                                    value={this.getLinkedCompanyValue("usDotNumber")}
                                                />
                                            }
                                            {this.getLabel(values.type || "Carrier") !== "Shipper" && this.getLabel(values.type || "Carrier") !== "Receiver" && this.getLabel(values.type || "Carrier") !== "Customer" &&
                                                <FFFormSelectCompanyIdField
                                                    create
                                                    label="FMCSA MC Number"
                                                    placeholder="Enter Value"
                                                    fieldname="fmcsaMcNumber"
                                                    error={null}
                                                    touched={false}
                                                    onChange={(company) => {
                                                        this.updateState(company, setFieldValue, "fmcsaMcNumber");
                                                    }}
                                                    disabled={this.props.disabled}
                                                    type={"fmcsaMcNumber"}
                                                    prepend={"MC-"}
                                                    value={this.getLinkedCompanyValue("fmcsaMcNumber")}


                                                />
                                            }
                                            {this.getLabel(values.type || "Carrier") !== "Shipper" && this.getLabel(values.type || "Carrier") !== "Receiver" && this.getLabel(values.type || "Carrier") !== "Customer" &&
                                                <FFFormSelectCompanyIdField
                                                    create
                                                    label="FMCSA FF Number"
                                                    placeholder="Enter Value"
                                                    fieldname="fmcsaFfNumber"
                                                    error={null}
                                                    touched={false}
                                                    onChange={(company) => {
                                                        this.updateState(company, setFieldValue, "fmcsaFfNumber");
                                                    }}
                                                    disabled={this.props.disabled}
                                                    type={"fmcsaFfNumber"}
                                                    prepend={"FF-"}
                                                    value={this.getLinkedCompanyValue("fmcsaFfNumber")}
                                                />
                                            }
                                            {this.getLabel(values.type || "Carrier") !== "Shipper" && this.getLabel(values.type || "Carrier") !== "Receiver" && this.getLabel(values.type || "Carrier") !== "Customer" &&
                                                <FFFormSelectCompanyIdField
                                                    create
                                                    label="FMCSA MX Number"
                                                    placeholder="Enter Value"
                                                    fieldname="fmcsaMxNumber"
                                                    error={null}
                                                    touched={false}
                                                    onChange={(company) => {
                                                        this.updateState(company, setFieldValue, "fmcsaMxNumber");
                                                    }}
                                                    disabled={this.props.disabled}
                                                    type={"fmcsaMxNumber"}
                                                    prepend={"MX-"}
                                                    value={this.getLinkedCompanyValue("fmcsaMxNumber")}
                                                />
                                            }
                                            <FFFormSelectCompanyIdField
                                                create
                                                icon="fa-id-card"
                                                label="National Registration Number"
                                                placeholder="Enter Value"
                                                fieldname="nationalRegistrationNumberCompany"
                                                error={null}
                                                touched={false}
                                                onChange={(company) => {
                                                    this.updateState(company, setFieldValue, "nationalRegistrationNumber");
                                                }}
                                                disabled={this.props.disabled}
                                                type={"nationalRegistrationNumber"}
                                                value={this.getLinkedCompanyValue("nationalRegistrationNumber")}
                                            />
                                            {/*<FormTextField*/}
                                            {/*label="National Registration Number"*/}
                                            {/*placeholder="Enter Value"*/}
                                            {/*icon="fa-hashtag"*/}
                                            {/*fieldname="nationalRegistrationNumber"*/}
                                            {/*errors={errors}*/}
                                            {/*handleBlur={handleBlur}*/}
                                            {/*handleChange={handleChange}*/}
                                            {/*touched={touched}*/}
                                            {/*values={values}*/}
                                            {/*required={true}*/}
                                            {/*helpMessage={"In U.S., enter USDOT Number (asset-based carriers) or MC Number (non-asset based freight brokers)."}*/}
                                            {/*/>*/}

                                            <FormCheckboxField
                                                label="Enabled"
                                                icon="fa-power-off"
                                                fieldname="enabled"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                            />

                                            <FormField
                                                label={"Short Codes"}
                                                required={false}
                                                errors={errors}
                                                icon={"fa-building"}
                                                fieldname={"carrierShortCodes"}
                                                helpMessage={"In U.S., SCAC code(s) as assigned by NMFTA."}
                                            >
                                                <ShortCodeSelect
                                                    fieldname={"carrierShortCodes"}
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                    options={[]}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    placeholder={"Enter one or more Short Codes"}
                                                />
                                            </FormField>
                                            <ContactsList
                                                fieldname={"contacts"}
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={this.state.editingCarrier}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                                label="Contacts"
                                                required={false}
                                                icon={"fa-building"}
                                                onNewCarrierContactClick={() => {
                                                    this.startNewContact();
                                                }}
                                                onEditContactClick={(contact) => {
                                                    this.setState({
                                                        showingCarrierContactModal: true,
                                                        editingContact: contact
                                                    });
                                                }}
                                                onChange={(contacts) => {
                                                    this.setState({
                                                        editingCarrier: {
                                                            ...this.state.editingCarrier,
                                                            contacts: contacts
                                                        }
                                                    })
                                                }}
                                            />
                                            <FFFormCheckboxField
                                                label="Visible"
                                                icon="fa-power-off"
                                                fieldname="visible"
                                                error={errors["visible"]}
                                                handleBlur={handleBlur}
                                                onChange={(newValue) => {
                                                    setFieldValue("visible", newValue);
                                                }}
                                                touched={touched["visible"]}
                                                value={values["visible"]}
                                                setFieldValue={setFieldValue}
                                                setFieldTouched={setFieldTouched}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mb-3 text-right">
                                    <Button color="danger" onClick={() => this.props.cancel()}>Cancel</Button> &nbsp;
                                    <Button color="primary" type="submit">Save</Button>
                                </div>
                            </Form>
                        )}
                </Formik>
            </div>);
    }


    updateState(company, setFieldValue, feild) {
        let state = { editingCarrier: {} };

        if (company.__isNew__) {
            state[feild] = company.value;
        } else {
            state[feild + "Company"] = company;
        }


        this.setState(state, () => {
            this.updateNumberFieldValue(company, setFieldValue, feild);
        });
    }

    updateNumberFieldValue(company, setFieldValue, field) {
        if (company.__isNew__) {
            setFieldValue(field, company.value);
        } else {
            setFieldValue(field + "Company", company);
        }
    }

    getLinkedCompanyValue(field) {
        if (!this.state.editingCarrier) return;
        if (!this.state.editingCarrier[field + "Company"] && this.state.editingCarrier[field]) {
            return {
                label: this.state.editingCarrier[field]
                    + " - Unlinked",
                value: {}
            }
        }

        return this.state.editingCarrier && this.state.editingCarrier[field + "Company"] && {
            label: this.state.editingCarrier[field + "Company"][field]
                + " - "
                + this.state.editingCarrier[field + "Company"].name,
            value: this.state.editingCarrier[field + "Company"]
        };
    }

    render() {
        return (
            <Modal isOpen={this.state.editingCarrier != null} size={"lg"} >
                <Loader show={this.props.loadedCarriers} message={this.spinner}>

                    <ModalHeader>
                        {this.state.editingContact.isNew ? "Add Partner" : "Edit Partner"}
                    </ModalHeader>
                    <ModalBody style={{ overflow: 'auto' }}>
                        {this.renderForm()}
                    </ModalBody>
                </Loader>
            </Modal>);

    }
}

export default CarrierForm;