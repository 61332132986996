import React, {Component} from 'react';
import './App.scss';
import {applyMiddleware, createStore} from 'redux';
import rootReducer from './reducers/index';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import MainApp from "./MainApp";
import {composeWithDevTools} from 'redux-devtools-extension';
import Raven from 'react-raven';
import Config from "./Config.js";

import Maintenance from "./Maintenance"
import 'react-datepicker/dist/react-datepicker.css';
import {BrowserRouter as Router} from "react-router-dom";
// import {HashRouter as Router} from "react-router-dom";

const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(thunkMiddleware)
));
class App extends Component {
    componentWillMount() {
        if(!Config.debug){
            console.log = function () {
                //dead
            }
        }
    }

    render() {
        return (
            <>
              {Config.maintenance === true ? 
              (
                <Maintenance />
              ):(
                   <div className="App" >
                   <Provider store={store}>
                       <Raven dsn={Config.ravenUrl} environment={Config.environment}/>
                       <Router>
                           <MainApp/>
                       </Router>
                   </Provider>
               </div>
              )

            }
            </>
           
        );
    }
}

export default App;





