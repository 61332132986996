import React, { Component } from "react";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import {
  promiseUserLocationSearch,
  promiseZoneByPrefix,
  promiseAllSpotTypes,
} from "../../NetworkHelpers";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class SpotEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      spotUnderEdit: {
        name: "",
        location: this.props.defaultLocation,
        type: this.props.type,
        enabled: true,
      },
      errors: {},
      touched: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.spotUnderEdit !== prevProps.spotUnderEdit) {
      this.setState({
        spotUnderEdit: this.props.spotUnderEdit,
        modalIsOpen: this.props.showModal,
      });
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  cancelEdit() {
    this.setState({
      modalIsOpen: false,
      errors: {},
      touched: {},
    });
    this.props.cancel();
  }
  onSubmit() {
    if (this.state.spotUnderEdit.type === "Dock Spot") {
      this.state.spotUnderEdit.type = "Dock";
    }
    if (this.state.spotUnderEdit.type === "Yard Spot") {
      this.state.spotUnderEdit.type = "Yard";
    }
   if (this.state.spotUnderEdit.name === ""){
    this.state.spotUnderEdit.name=null;
   }
    this.updateSpinner("Saving...");
    this.props.save &&
      this.props.save(
        this.state.spotUnderEdit,
        () => {
          this.setState({ modalIsOpen: false, touched: {} });
        },
        () => {
          this.setState({ modalIsOpen: true });
        }
      );
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    let validationRules = {
      location: Yup.object().required("Location is required."),
      zone: Yup.string("Yard Zone / Building is required.").required("Yard Zone / Building is required.").nullable(),
      type: Yup.object().required("Type is required."),
    };
    return Yup.object().shape(validationRules);
  }
  render() {
    console.log(this.state.spotUnderEdit, `this is spotunder edit`);
    return (
      <Modal isOpen={this.state.modalIsOpen} size={"lg"}>
        <Loader show={this.props.loaded} message={this.spinner}>
          <ModalHeader>
            {this.state.spotUnderEdit.isNew ? "New Spot" : "Edit Spot"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                let errors = this.validate(this.validationSchema.bind(this))(
                  this.state.spotUnderEdit
                );
                let errorsIsEmpty =
                  Object.keys(errors).length === 0 &&
                  errors.constructor === Object;
                if (!errorsIsEmpty || this.state.errors.name) {
                  let t = { ...this.state.touched };
                  t.name = true;
                  t.location = true;
                  t.zone = true;
                  t.type = true;
                  this.setState({ touched: t, errors: errors });
                  e.preventDefault();
                  return;
                }
                this.onSubmit();
                e.preventDefault();
              }}
            >
              <Card className="simple-card">
                <CardHeader>Spot</CardHeader>
                <CardBody>
                  <div>
                    <FFFormAsyncSelectField
                      defaultOptions
                      fieldname="location"
                      label="Location"
                      placeholder="Select a Location"
                      icon="fa-building"
                      value={
                        this.state.spotUnderEdit.location
                          ? this.state.spotUnderEdit.location.name
                            ? this.state.spotUnderEdit.location.name
                            : this.state.spotUnderEdit.location.name
                          : ""
                      }
                      loadOptions={promiseUserLocationSearch}
                      checkered={true}
                      hideEmpty={true}
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.spotUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.location = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.location}
                      touched={this.state.touched.location}
                      onChange={(v) => {
                        let s = { ...this.state.spotUnderEdit };
                        s.location = v;
                        s.zone = "";
                        this.setState({ spotUnderEdit: s });
                      }}
                    />
                    <FFFormSelectCreatableField
                      key={
                        "ID_" +
                        (this.state.spotUnderEdit.location
                          ? this.state.spotUnderEdit.location.uuid
                          : "")
                      }
                      defaultOptions={true}
                      fieldname="zone"
                      label="Yard Zone / Building"
                      placeholder="Enter the Yard Zone / Building"
                      icon="fa-building"
                      value={this.state.spotUnderEdit.zone}
                      isDisabled={!this.state.spotUnderEdit.location}
                      loadOptions={promiseZoneByPrefix(
                        this.state.spotUnderEdit.location
                          ? this.state.spotUnderEdit.location.uuid
                          : ""
                      )}
                      hideEmpty={true}
                      checkered={true}
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.spotUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.zone = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.zone}
                      touched={this.state.touched.zone}
                      onChange={(v) => {
                        let s = { ...this.state.spotUnderEdit };
                        s.zone = v == null ? null : v.value;
                        this.setState({ spotUnderEdit: s });
                      }}
                    ></FFFormSelectCreatableField>
                    <FFFormAsyncSelectField
                      defaultOptions
                      fieldname="type"
                      label="Spot Type"
                      placeholder="Select Spot Type"
                      icon="fa-building"
                      value={
                        this.state.spotUnderEdit.type
                          ? this.state.spotUnderEdit.type.name
                            ? this.state.spotUnderEdit.type.name
                            : this.state.spotUnderEdit.type.name
                          : ""
                      }
                      loadOptions={promiseAllSpotTypes}
                      checkered={true}
                      required={true}
                      hideEmpty={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.spotUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.type = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.type}
                      touched={this.state.touched.type}
                      onChange={(v) => {
                        console.log(
                          v,
                          `this is in onchange v`,
                          this.state.spotUnderEdit.type
                        );
                        let s = { ...this.state.spotUnderEdit };
                        console.log(s, `this is  in onchange s`);

                        s.type = v;
                        this.setState({ spotUnderEdit: s });
                      }}
                    />
                    {/* <FFFormSelectField
                      fieldname="type"
                      label="Type"
                      icon="fa-parking"
                      value={this.state.spotUnderEdit.type}
                      required={true}
                      options={this.props.spotType}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.spotUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.type = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.type}
                      touched={this.state.touched.type}
                      onChange={(v) => {
                        let s = { ...this.state.spotUnderEdit };
                        s.type = v.value;
                        this.setState({ spotUnderEdit: s });
                      }}
                    /> */}
                    <FFFormTextField
                      fieldname="name"
                      label="Spot Name"
                      placeholder="Enter a name for the Spot"
                      icon="fa-tag"
                      value={this.state.spotUnderEdit.name}
                      maxLength={40}
                      hideEmpty={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.spotUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.name = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.name}
                      touched={this.state.touched.name}
                      onChange={(v) => {
                        let s = { ...this.state.spotUnderEdit };
                        s.name = v;
                        this.setState({ spotUnderEdit: s });
                      }}
                    ></FFFormTextField>
                    <FFFormCheckboxField
                      fieldname="enabled"
                      icon="fa-power-off"
                      label="Enabled"
                      value={this.state.spotUnderEdit.enabled}
                      onChange={(v) => {
                        let s = { ...this.state.spotUnderEdit };
                        s.enabled = v;
                        this.setState({ spotUnderEdit: s });
                      }}
                    ></FFFormCheckboxField>
                  </div>
                </CardBody>
              </Card>
              <div className="mb-3 text-right">
                <Button color="danger" onClick={() => this.cancelEdit()}>
                  Cancel
                </Button>{" "}
                &nbsp;
                <Button color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Loader>
      </Modal>
    );
  }
}
export default SpotEdit;
