export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT = "LOGOUT";
export const SET_USERS_COMPANY = "SET_USERS_COMPANY";
export const SET_USERS_COMPANY_REFERENCE_NUMBER_TYPES = "SET_USERS_COMPANY_REFERENCE_NUMBER_TYPES";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_SHIFT = "SET_CURRENT_SHIFT";
export const SET_DISABLE_SCREEN = "SET_DISABLE_SCREEN";
export const SET_NOTICE= "SET_NOTICE";



export function storeLogin( username, user, location,shift) {
    return {
        type: LOGIN_USER,
        username: username,
        user: user,
        location:location,
        shift: {label:user.shift, value: user.shift}
    }
}

export function storeLogout(  ) {
    return {
        type: LOGOUT
    }
}


export function setUsersCompany(company) {
    return {
        type: SET_USERS_COMPANY,
        company: company
    }
}

export function storeCurrentLocation( location ) {
    return {
        type: SET_CURRENT_LOCATION,
        location: location
    }
}
export function storeCurrentUser( user ) {
    return {
        type: SET_CURRENT_USER,
        user: user
    }
}
export function storeCurrentShift(shift){
    return {
        type: SET_CURRENT_SHIFT,
        shift: shift
    }
}
export function storeDisableScreen(isDisabled){
    return {
        type: SET_DISABLE_SCREEN,
        isDisabled: isDisabled
    }
}
export function storeNotification(notification){
    console.log(notification,"store888");
    return {
        type: SET_NOTICE,
        notification: notification
    }
}

export function storeUserCompanyReferenceNumberTypes(referenceNumberTypes) {
    return {
        type: SET_USERS_COMPANY_REFERENCE_NUMBER_TYPES,
        referenceNumberTypes: referenceNumberTypes
    }
}