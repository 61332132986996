import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {LoadedTrailerFormFields} from "./LoadedTrailerFormFields";
import FormDateTimeField from "../../common/FormDateTimeField";
import {TractorCard} from "./TractorCard";
import {BobTailArrival} from "./BobTailArrival";
import * as PropTypes from "prop-types";
import {ArrivalInformation} from "./ArrivalInformation";
import {DispatchDriverCard} from "../../Dispatch/components/DispatchDriverCard";

export class ArrivalCard extends Component {
    render() {
        return <Card className="simple-card">
            <CardHeader>Arrival Information</CardHeader>
            <CardBody>
                <div>
                    {this.props.trailerType === "loaded" &&
                    <LoadedTrailerFormFields
                        company={this.props.company}
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        touched={this.props.touched} values={this.props.values}
                        fieldTouched={this.props.fieldTouched}
                        fieldValue={this.props.fieldValue}
                        setFieldValue={this.props.fieldValue}
                        referenceTypes={this.props.referenceTypes}
                        onReferenceNumberListChange={this.props.onReferenceNumberListChange}
                        shipmentReferences={this.props.shipmentReferences}
                        referenceNumberFieldName={this.props.referenceNumberFieldName}
                    />
                    }
                    <ArrivalInformation
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        touched={this.props.touched}
                        values={this.props.values}
                        fieldTouched={this.props.fieldTouched}
                        fieldValue={this.props.fieldValue}
                        optionLabel={this.props.optionLabel}
                        onChange={this.props.onChange}
                        value={this.props.value}
                        timezone={this.props.timezone}
                    />
                    {this.props.displayInField ?
                        <FormDateTimeField
                            label="In *"
                            placeholder="Enter Value"
                            onChange={this.props.onFormDateTimeChange}
                            value={this.props.dateTimeValue}
                            icon="fa-calendar"
                            fieldname="date"
                            errors={this.props.errors}
                            handleBlur={this.props.handleBlur}
                            handleChange={this.props.handleChange}
                            touched={this.props.touched}
                            values={this.props.values}
                            setFieldTouched={this.props.fieldTouched}
                            setFieldValue={this.props.fieldValue}
                        /> : ""
                    }

                    <TractorCard
                        onClick={this.props.onClick}
                        className={this.props.className}
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        touched={this.props.touched} values={this.props.values}
                        fieldTouched={this.props.fieldTouched}
                        fieldValue={this.props.fieldValue}
                        optionLabel={this.props.tractorOptionLabel}
                        onChange={this.props.onChange}
                        sectionOpen={this.props.sectionOpen}
                    />

                    {this.props.trailerType !== "bobtail" &&
                    <BobTailArrival
                        onClick={this.props.onBobTailArrivalClick}
                        className={this.props.bobTailClassName}
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        touched={this.props.touched}
                        values={this.props.values}
                        fieldTouched={this.props.fieldTouched}
                        fieldValue={this.props.fieldValue}
                        optionLabel={this.props.tractorOptionLabel}
                        onChange={this.props.onChange}
                        driverSectionOpen={this.props.driverSectionOpen}
                    />
                    }
                    <DispatchDriverCard
                        advancedView={this.props.driverCardSectionOpen}
                        driver={this.props.values.driver}
                        touched={this.props.touched}
                        disabled={this.props.disabled}
                        errors={this.props.errors.driver || {}}
                        header={<div className="form-section-title">
                            <Button onClick={this.props.onDriverCardClick}>
                                <i className={this.props.driverCardClassName}/>
                            </Button> Driver Information
                        </div>}
                        onChange={(driver) => {
                            if(driver){
                                this.props.fieldValue("driverCell", driver.assetId);
                                this.props.fieldValue("driverCountryCode", driver.countryCode);
                                this.props.fieldValue("driverHasSmartPhone", driver.hasSmartPhone);
                                this.props.fieldValue("driverFirstname", driver.firstname);
                                this.props.fieldValue("driverLastname", driver.lastname);
                                this.props.fieldValue("driverCdl", driver.cdl);
                                this.props.fieldValue("driverState", driver.cdlState);
                                this.props.fieldValue("driverUuid", driver.uuid);
                                this.props.fieldValue("driver", driver);
                            }
                        }}
                    />
                </div>
            </CardBody>
        </Card>;
    }
}

ArrivalCard.propTypes = {
    trailerType: PropTypes.any,
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
    displayInField: PropTypes.bool,
    onFormDateTimeChange: PropTypes.func,
    dateTimeValue: PropTypes.any,
    onClick: PropTypes.func,
    className: PropTypes.string,
    tractorOptionLabel: PropTypes.func,
    onTractorCardChange: PropTypes.func,
    sectionOpen: PropTypes.any,
    onBobTailArrivalClick: PropTypes.func,
    bobTailClassName: PropTypes.string,
    onBobTailArrivalChange: PropTypes.func,
    driverSectionOpen: PropTypes.any,
    onDriverCardClick: PropTypes.func,
    driverCardClassName: PropTypes.string,
    onDriverCardChange: PropTypes.func,
    driverCardSectionOpen: PropTypes.any,
    timezone: PropTypes.any,
    onCountryCodeChange: PropTypes.any
};