import React, {Component} from "react";
import MomentDisplay from "../../assets/components/MomentDisplay";

class EZTrackEventMapPinPopup extends Component {
    constructor(props) {
        super(props);
    }
    formatCurrentAddress(event) {
        if (event == undefined) {
            // for some reason, render is being called when the popup is closed and the asset is false
            return ["No Address Found"];
        }
        if ((event.address && event.address !== "Unknown")
            || (event.city && event.city !== "Unknown")
            || (event.state && event.state !== "Unknown")
            || (event.postal && event.postal !== "Unknown")) {
            let address = event.address ? event.address : "Unknown address";
            let city = event.city ? event.city : "Unknown city";
            let state = event.state ? event.state : "Unknown state";
            let postal =  event.postal ? event.postal : "";
            return [address , city + ", " + state + " " + postal];
        } else {
            return ["No Address Found"];
        }
    }
    render() {
        return (
            <>
                <p><b>Report Time:</b>&nbsp;
                    <MomentDisplay date={this.props.event.timestamp}
                                   timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/></p>
                {this.props.event.dateOfLastMove ?
                    <p><b>Last Move:</b>&nbsp;
                        <MomentDisplay date={this.props.event.dateOfLastMove}
                                       timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/></p>
                    :  <p><b>Last Move:</b>&nbsp;</p>
                    }
                <p><b>Current Location:</b><br/>
                    {this.props.event.locationName ? this.props.event.locationName : ""}
                    {this.props.event.locationName ? <br/> : ""}
                {this.formatCurrentAddress(this.props.event)[0]}
                <br/>{this.formatCurrentAddress(this.props.event)[1]}</p>
            </>
        )
    }
}
export default EZTrackEventMapPinPopup;