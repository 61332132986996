import React, { Component } from 'react';
import * as uuid from "uuid";
import { Tooltip } from "reactstrap";
import PropTypes from 'prop-types';

class HelpIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      id: "ID_" + uuid.v4()
    }
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return <span className={this.props.className}>
      <i style={{ color: "grey" }}
        className={"fa fa-question-circle"}
        id={this.state.id}
      />
      <Tooltip placement={this.props.placement} isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
        {this.props.helpMessage}
      </Tooltip>
    </span>
  }
}

HelpIcon.defaultProps = {
  placement: "right"
};

HelpIcon.propTypes = {
  placement: PropTypes.string,
  helpMessage: PropTypes.string
};

export default HelpIcon;