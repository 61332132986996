import React, {Component} from "react";
import AsyncSelect from 'react-select/lib/Async';
import * as PropTypes from "prop-types";
import {promiseCarrierListDrop} from "../../NetworkHelpers";



export class FFCarriersSelect extends Component {
 
    constructor(props) {
        super(props);
        this.state = {
        }
        
    }

    render() {
        return <AsyncSelect
        name={this.props.name}
        placeholder={this.props.placeholder}
        valid={!this.props.error}
        invalid={
                    this.props.touched
                    && !!this.props.error
                }
        onChange={(v)=>this.props.onCarriersChange(v)}
        isMulti={!this.props.single}
        cacheOptions
        defaultOptions
        className={"basic-single short-select select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
        loadOptions={promiseCarrierListDrop}
        onBlur={this.props.onBlur}
         minimumInput={0}
         value={this.props.value ? this.props.value.map((v)=>{return {value:v.uuid, label:v.name}}) : null}
         
        />;
    }
}

FFCarriersSelect.propTypes = {
    name: PropTypes.any,
    placeholder: PropTypes.any,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    fieldname: PropTypes.any
};