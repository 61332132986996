import React, {Component} from "react";
import {Button} from "reactstrap";
import Moment from "react-moment";
import Switch from "react-switch";
import * as PropTypes from "prop-types";
import HelpIcon from "./HelpIcon";
export class RefreshReload extends Component {
    render() {
        return <>
            <Button color="link" size="small" onClick={this.props.onClick}><i className="fa fa-redo"></i></Button>
            &nbsp;
            Last Refresh: &nbsp;
            <Moment format="h:mm:ss a">{new Date()}</Moment> &nbsp;
            <Switch onChange={this.props.onChange} checked={this.props.checked}
                    uncheckedIcon={null}
                    disabled={this.props.disableAutoRefresh && this.props.disableAutoRefresh === true}
                    title="Auto refresh"
                    className="react-switch"/>
                    {this.props.disableAutoRefresh === true && this.props.disableAutoRefreshMsg && this.props.disableAutoRefreshMsg !== "" &&
                      <HelpIcon helpMessage={this.props.disableAutoRefreshMsg} />

                    }
        </>;
    }
}

RefreshReload.propTypes = {
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    checked: PropTypes.any
};