import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import { promiseEZTrackBaseEquipmentByPrefix } from "../../NetworkHelpers";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class EZTrackEquipmentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      assetUnderEdit: {
        name: "",
        baseEquipment: {},
      },
      errors: {},
      touched: {}
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.assetUnderEdit !== prevProps.assetUnderEdit) {
      this.setState(
        {
          assetUnderEdit: this.props.assetUnderEdit,
          modalIsOpen: this.props.showModal
        });
    }
  }
  cancelEdit() {
    this.setState({
      modalIsOpen: false,
      errors: {},
      touched: {}
    });
    this.props.cancel();
  }
  onSubmit() {
    this.updateSpinner("Saving...");
    this.props.save && this.props.save(this.state.assetUnderEdit, () => {
      this.setState({ modalIsOpen: false, touched: {} });
    }, () => {
      this.setState({ modalIsOpen: true });
    });
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    };
  }
  validationSchema(values) {
    let validationRules = {
      name: Yup.string()
        .required('Name is required.'),
      baseEquipment: Yup.object().nullable()
        .required('Base Type is required.')
    }
    return Yup.object().shape(validationRules)
  }
  render() {
    return (
      <Modal isOpen={this.state.modalIsOpen} size={'lg'}>
        <Loader show={this.props.loadedAsset} message={this.spinner}>
          <ModalHeader>
            {this.state.assetUnderEdit.isNew ? "Add Asset Type" : "Edit Asset Type"}
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => {
              let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);

              let errorsIsEmpty = Object.keys(errors).length === 0 && errors.constructor === Object;
              if (!errorsIsEmpty || this.state.errors.name || this.state.errors.baseEquipment) {
                let t = { ...this.state.touched };
                t.name = true;
                t.baseEquipment = true;
                this.setState({ touched: t, errors: errors });
                e.preventDefault();
                return;
              }
              this.onSubmit();
              e.preventDefault();
            }}
            >
              <Card className="simple-card">
                <CardHeader>Equipment Type</CardHeader>
                <CardBody>
                  <div>
                    <FFFormTextField
                      fieldname="Name"
                      label="Name"
                      placeholder="Enter a name for the Asset Type"
                      icon="fa-tag"
                      value={this.state.assetUnderEdit.name}
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.name = true;
                        this.setState({ errors: errors, touched: touched })
                      }}
                      error={this.state.errors.name}
                      touched={this.state.touched.name}
                      onChange={(v) => {
                        let s = { ...this.state.assetUnderEdit };
                        s.name = v;
                        this.setState({ assetUnderEdit: s });
                      }}
                    >
                    </FFFormTextField>
                    <FFFormAsyncSelectField
                      fieldname="baseEquipment"
                      icon="fa-truck-loading"
                      label="Base Type"
                      placeholder="Select the base equipment type"
                      loadOptions={promiseEZTrackBaseEquipmentByPrefix}
                      defaultOptions={true}
                      required={true}
                      value={this.state.assetUnderEdit.baseEquipment ?
                        this.state.assetUnderEdit.baseEquipment.equipmentType :
                        null}
                      onChange={(v) => {
                        let s = { ...this.state.assetUnderEdit };
                        s.baseEquipment = v ? v : "";
                        this.setState({ assetUnderEdit: s });
                      }}
                      onBlur={() => {
                        let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.baseEquipment = true;
                        this.setState({ errors: errors, touched: touched })
                      }}
                      error={this.state.errors.baseEquipment}
                      touched={this.state.touched.baseEquipment}
                    >
                    </FFFormAsyncSelectField>
                    <FFFormCheckboxField
                      fieldname="temperatureControlled"
                      icon="fa-power-off"
                      label="Temp Controlled"
                      value={this.state.assetUnderEdit.temperatureControlled}
                      onChange={(v) => {
                        let s = { ...this.state.assetUnderEdit };
                        s.temperatureControlled = v;
                        if (!v) { 
                            s.reefer = false;
                          }
                        this.setState({ assetUnderEdit: s });
                      }}
                    />
                    {((this.state.assetUnderEdit &&
                      this.state.assetUnderEdit.temperatureControlled)) && 
                      (
                        <FFFormCheckboxField
                          fieldname="reefer"
                          icon="fa-power-off"
                          label="Advance Temp Controlled"
                          value={this.state.assetUnderEdit.reefer}
                          onChange={(v) => {
                            let s = { ...this.state.assetUnderEdit };
                            s.reefer = v;
                            this.setState({ assetUnderEdit: s });
                          }}
                        />
                      )}

                    <FFFormCheckboxField
                      fieldname="enabled"
                      icon="fa-power-off"
                      label="Enabled"
                      value={this.state.assetUnderEdit.enabled}
                      onChange={(v) => {
                        let s = { ...this.state.assetUnderEdit };
                        s.enabled = v;
                        this.setState({ assetUnderEdit: s });
                      }}
                    >
                    </FFFormCheckboxField>
                    <FFFormCheckboxField
                      fieldname="def"
                      icon="fa-power-off"
                      label="Default"
                      value={this.state.assetUnderEdit.def}
                      onChange={(v) => {
                        let s = { ...this.state.assetUnderEdit };
                        s.def = v;
                        this.setState({ assetUnderEdit: s });
                      }}
                    >
                    </FFFormCheckboxField>
                    <FFFormCheckboxField
                      fieldname="pwaEnabled"
                      icon="fa-power-off"
                      label="PWA"
                      value={this.state.assetUnderEdit.pwaEnabled}
                      onChange={(v) => {
                        let s = { ...this.state.assetUnderEdit };
                        s.pwaEnabled = v;
                        this.setState({ assetUnderEdit: s });
                      }}
                    >
                    </FFFormCheckboxField>
                  </div>
                </CardBody>
              </Card>
              <div className="mb-3 text-right">
                <Button color="danger" onClick={() => this.cancelEdit()}>Cancel</Button> &nbsp;
                <Button color="primary" type="submit" disabled={this.props.loadedAsset}>Save</Button>
              </div>
            </Form>
          </ModalBody>
        </Loader>
      </Modal>
    )
  }
}
export default EZTrackEquipmentEdit;
