import React, {Component} from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import FormRadioField from "../../common/FormRadioField";
import * as PropTypes from "prop-types";

export class DepartureInformationCard extends Component {
    render() {
        return <Card className="simple-card">
            <CardHeader>Departure Information</CardHeader>
            <CardBody>
                <div>

                    <FormRadioField
                        label="Departing Trailer Type"
                        placeholder="Choose Value"
                        options={this.props.options}
                        fieldname="departingType"
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        touched={this.props.touched}
                        values={this.props.values}
                        setFieldTouched={this.props.fieldTouched}
                        setFieldValue={this.props.fieldValue}
                        icon="fa-dolly"
                    />
                    {this.props.children}
                </div>
            </CardBody>
        </Card>;
    }
}

DepartureInformationCard.propTypes = {
    options: PropTypes.any,
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    renderDepartingForm: PropTypes.any
};