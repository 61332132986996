import React, { Component } from 'react';
import { Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";

class FFFormTextAreaField extends Component {

  icon() {
    if (this.props.icon) {
      return <i className={"align-self-start fa " + this.props.icon}></i>
    } else if (this.props.iconText) {
      return this.props.iconText;
    }
    return " ";
  }
  clearField() {

    const input = document.getElementsByName(this.props.fieldname);

    input[0].value = "";
    this.props.onChange(null);

  }
  render() {
    return (
      <FormGroup>
        <Row>
          <Col lg="2" md="3" sm="4">
            <Label>{this.props.label} <span className={'text-danger'}>{this.props.label && this.props.required ? " *" : ""}</span></Label>
          </Col>
          <Col lg="10" md="9" sm="8">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="align-top">{this.icon()}</InputGroupText>
              </InputGroupAddon>
              <Input type="textarea" placeholder={this.props.placeholder}
                name={this.props.fieldname}
                valid={this.props.touched && !this.props.error}
                invalid={this.props.touched && !!this.props.error}
                onChange={this.props.onChange}
                onBlur={this.props.handleBlur}
                value={this.props.value}
                defaultValue={this.props.defaultValue}
                disabled={this.props.disabled}
                rows={this.props.rows}
              />
              {this.props.isClearable && (
                <div
                  className={"clearable-input"}
                  onClick={() => this.clearField()}
                >
                  <i className={"far fa-times"}></i>
                </div>
              )}
              <FormFeedback>{this.props.error}</FormFeedback>
            </InputGroup>
          </Col>
        </Row>
      </FormGroup>
    )
  }
}


export default FFFormTextAreaField;