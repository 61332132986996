import React, {Component} from "react";
import { Col, Row, Button, Container, Modal, ModalHeader, ModalBody } from 'reactstrap';
class ResetModal extends Component {
    constructor(props) {
        super(props);

    }


    render() {
        return <>
            <Modal isOpen={this.props.open} size={"lg"} >
                <ModalHeader>
                    <h1>Reset To Default</h1>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col>
                                <p>This will reset all the filters, columns and sorting to default in every page and the user will be logged Out.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right" style={{ marginTop: "10px" }} >
                                <Button color="danger" onClick={() => this.props.onCancel && this.props.onCancel()}>Cancel</Button> &nbsp;
                                <Button color="primary" onClick={() => this.doReset()} type="submit">Reset</Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        </>;
    }
    doReset() {
        return this.props.onSave && this.props.onSave(true);
    }


}

// ResetModal.propTypes = {
//     onSave: PropTypes.func,
//     onCancel: PropTypes.func,
//     onChange: PropTypes.func
// };
export default ResetModal;