import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import FFFormSelectLocationField from "../../common/formik-free/FFFormSelectLocationField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import {
	downloadEZTrackTrackingFrequencies,
	promiseCarriersByPrefixIncludingSelf,
	promiseEZTrackEquipmentByPrefix
} from "../../NetworkHelpers";
import EZTrackDevice from "./EZTrackDevice";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class PingAssetEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: false,
			assetUnderEdit: {
				assetId: "",
				imei: "",
				equipment: "",
				domicile: "",
				devices: [],
				isNew: true
			},
			errors: { devices: [] },
			touched: { devices: [] },
		}
	}
	updateSpinner(val) {
		this.spinner = (
			<span>
				<p className="loadingMessage">{val}</p>
				<LoaderIco
					loaded={false}
					lines={13}
					length={20}
					width={10}
					radius={30}
					corners={1}
					rotate={0}
					direction={1}
					color="#51b32e"
					speed={1}
					trail={60}
					shadow={false}
					hwaccel={false}
					className="spinner"
					zIndex={2e9}
					top="50%"
					left="50%"
					scale={1.0}
					loadedClassName="loadedContent"
				/>
			</span>
		);
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.assetUnderEdit !== prevProps.assetUnderEdit) {
			let isEmptyObj = Object.keys(this.props.assetUnderEdit).length === 0 && this.props.assetUnderEdit.constructor === Object;
			let asset = { ...this.props.assetUnderEdit };
			if (isEmptyObj) {
				asset.devices = [];
			}
			asset.devices.sort((a, b) => (a.zone > b.zone) ? 1 : -1);
			this.setState(
				{
					assetUnderEdit: asset,
					errors: this.validate(this.validationSchema.bind(this))(asset),
					touched: { devices: Array(asset.devices.length).fill({}) },
					modalIsOpen: !isEmptyObj
				});
		}
	}
	componentDidMount() {
		window.scrollTo(0, 0);
		downloadEZTrackTrackingFrequencies("", (success) => {
			success.map((d) => { d.value = d.name; d.label = d.displayName; return d });
			this.setState({ trackFrequencyOptions: success });
		})
	}
	filterTrackFrequencyOptions() {
		return this.state.trackFrequencyOptions.filter((d) => {
			return d.trackerType === this.state.assetUnderEdit.trackerType
		})
	}
	cancelEdit() {
		this.setState({
			modalIsOpen: false,
			errors: { devices: [] },
			touched: { devices: [] },
			assetUnderEdit: { devices: [] }
		});
		this.props.cancel();
	}
	onSubmit() {
		let asset = { ...this.state.assetUnderEdit };
		this.updateSpinner("Saving...");

		if (asset.equipment === "") {
			asset.equipment = null;
		} else if (asset.equipment.baseEquipment !== null) {
			asset["@type"] = asset.equipment.baseEquipment.equipmentType;
		}
		if (asset.imei === "") {
			asset.imei = null;
		}
		if (asset.trackerType === "") {
			asset.trackerType = null;
		}
		this.props.save(asset, () => {
			this.setState({ modalIsOpen: false, touched: { devices: [] }, errors: { devices: [] } });
		}, () => {
			// don't need to do anything on save error
			this.setState({ modalIsOpen: true });
		});
	}
	validate(getValidationSchema) {
		let errors = (values) => {
			const validationSchema = getValidationSchema(values);
			try {
				validationSchema.validateSync(values, { abortEarly: false });
				return {}
			} catch (error) {
				return getErrorsFromValidationError(error)
			}
		};
		return errors;
	}
	validationSchema(values) {
		let validationRules = {
			carrier: Yup.string().required('Trailer Name is required.'),
			assetId: Yup.string()
				.required('Trailer Number is required.'),
			domicile: Yup.string().nullable()
				.required('Domicile is required.'),
			equipment: Yup.string().nullable()
				.required('Asset type is required.'),
			devices: Yup.array().of(
				Yup.object().shape({
					imei: Yup.string().required("Device ID is required.")
				})
			)
		};
		return Yup.object().shape(validationRules)
	}
	addDevice() {
		let s = { ...this.state.assetUnderEdit };
		let maxIdx = 1;
		s.devices.forEach((d) => {
			if (d.zone >= maxIdx) {
				maxIdx = parseInt(d.zone, 10) + 1;
			}
		})
		s.devices = s.devices.concat([{
			imei: "",
			zone: maxIdx,
			trackerType: "",
			ezTrackTrackingFrequency: "",
			key: s.devices.length,
		}]);
		let t = { ...this.state.touched };
		if (!t.devices) {
			t.devices = [];
		}
		t.devices = t.devices.concat([{}]);
		let e = { ...this.state.errors };
		if (!e.devices) {
			e.devices = [];
		}
		e.devices = e.devices.concat([{}]);
		this.setState({ assetUnderEdit: s, touched: t, errors: e });
	}
	removeDevice(idx) {
		let s = { ...this.state.assetUnderEdit };
		s.devices.splice(idx, 1);
		this.setState({ assetUnderEdit: s });
	}
	render() {
		return (
			<Modal isOpen={this.state.modalIsOpen} size={'lg'}>
				<Loader show={this.props.loadedAssets} message={this.spinner}>

					<ModalHeader>
						{this.state.assetUnderEdit.isNew ? "Add Asset" : "Edit Asset"}
					</ModalHeader>
					<ModalBody>
						<Form onSubmit={(e) => {
							let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
							let errorsIsEmpty = Object.keys(errors).length === 0 && errors.constructor === Object;
							if (!errorsIsEmpty || this.state.errors.assetId || this.state.errors.domicile) {
								let t = { ...this.state.touched };
								t.carrier = true;
								t.assetId = true;
								t.equipment = true;
								t.domicile = true;
								this.setState({ touched: t, errors: errors });
								e.preventDefault();
								return;
							}
							this.onSubmit();
							e.preventDefault();
						}}
						>
							<Card className="simple-card">
								<CardHeader>Asset Info</CardHeader>
								<CardBody>
									<div>
										<FFFormAsyncSelectField
											defaultOptions={true}
											fieldname="carrier"
											label="Trailer Name"
											icon="fa-truck"
											value={this.state.assetUnderEdit.carrier ? this.state.assetUnderEdit.carrier.name : null}
											loadOptions={promiseCarriersByPrefixIncludingSelf(this.props.loggedInUser.company.name)}
											checkered={true}
											required={true}
											error={this.state.errors.carrier}
											touched={this.state.touched.carrier}
											onChange={(v) => {
												let s = { ...this.state.assetUnderEdit };
												s.carrier = {
													uuid: v.uuid,
													name: v.name
												};
												this.setState({ assetUnderEdit: s });
											}}
										/>
										<FFFormTextField
											fieldname="Asset ID"
											label="Trailer Number"
											placeholder="Enter Trailer Number"
											icon="fa-tag"
											value={this.state.assetUnderEdit.assetId}
											required={true}
											onBlur={() => {
												let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
												let touched = { ...this.state.touched };
												touched.assetId = true;
												this.setState({ errors: errors, touched: touched })
											}}
											error={this.state.errors.assetId}
											touched={this.state.touched.assetId}
											onChange={(v) => {
												let s = { ...this.state.assetUnderEdit };
												if(v) v = v.replace(/ /g, '').toUpperCase()
												s.assetId = v
												this.setState({ assetUnderEdit: s });
											}}
										>
										</FFFormTextField>
										{this.state.assetUnderEdit.devices && this.state.assetUnderEdit.devices.length >= 1 ?
											this.state.assetUnderEdit.devices.map((d, idx) => {
												return <>
													<Card>
														<CardHeader>
															Zone {idx}
														</CardHeader>
														<CardBody>
															<EZTrackDevice
																key={idx}
																zone={idx + 1}
																value={this.state.assetUnderEdit.devices[idx]}
																errors={this.state.errors.devices ? this.state.errors.devices[idx] ?
																	this.state.errors.devices[idx] : {}
																	: {}}
																touched={this.state.touched.devices ? this.state.touched.devices[idx] : {}}
																trackFrequencyOptions={this.state.trackFrequencyOptions}
																onChange={(device) => {
																	let a = { ...this.state.assetUnderEdit };
																	a.devices[idx] = device;
																	this.setState({ assetUnderEdit: a });
																}}
															/>
															<Button color="secondary" onClick={() => this.removeDevice(idx)}>Remove Zone</Button>&nbsp;&nbsp;
														</CardBody>
													</Card>
												</>
											})
											: null
										}
										<Button className="mb-3" color="primary" onClick={() => this.addDevice()}>Add Zone</Button>
										<FFFormAsyncSelectField
											fieldname="equipment"
											icon="fa-truck-loading"
											label="Asset Type"
											placeholder="Select the asset type"
											required={true}
											loadOptions={promiseEZTrackEquipmentByPrefix}
											defaultOptions={true}
											value={this.state.assetUnderEdit.equipment ?
												this.state.assetUnderEdit.equipment.name : null}
											onChange={(v) => {
												let s = { ...this.state.assetUnderEdit };
												s.equipment = v;
												this.setState({ assetUnderEdit: s });
												// this.props.onChange(this.state.assetUnderEdit);
											}}
											onBlur={() => {
												let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
												let touched = { ...this.state.touched };
												touched.equipment = true;
												this.setState({ errors: errors, touched: touched })
											}}
											error={this.state.errors.equipment}
											touched={this.state.touched.equipment}
										>
										</FFFormAsyncSelectField>
										<FFFormSelectLocationField
											fieldname="domicile"
											icon="fa-home"
											label="Domicile"
											placeholder="Select the asset's domicile"
											value={this.state.assetUnderEdit.domicile}
											required={true}
											onChange={(v) => {
												let s = { ...this.state.assetUnderEdit };
												s.domicile = v;
												this.setState({ assetUnderEdit: s });
												// this.props.onChange(this.state.assetUnderEdit);
											}}
											onBlur={() => {
												let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
												let touched = { ...this.state.touched };
												touched.domicile = true;
												this.setState({ errors: errors, touched: touched })
											}}
											error={this.state.errors.domicile}
											touched={this.state.touched.domicile}
										>
										</FFFormSelectLocationField>
										<FFFormCheckboxField
											fieldname="enabled"
											icon="fa-power-off"
											label="Enabled"
											value={this.state.assetUnderEdit.enabled}
											onChange={(v) => {
												let s = { ...this.state.assetUnderEdit };
												s.enabled = v;
												this.setState({ assetUnderEdit: s });
											}}
										>
										</FFFormCheckboxField>
										{/* <FFFormCheckboxField
                                        fieldname="pwa"
                                        icon="fa-power-off"
                                        label="PWA "
                                        value={this.state.assetUnderEdit.enabled}
                                        onChange={(v) => {
                                            let s = {...this.state.assetUnderEdit};
                                            s.pwaEnabled = v;
                                            this.setState({assetUnderEdit: s});
                                        }}
                                    >
                                    </FFFormCheckboxField> */}
									</div>
								</CardBody>
							</Card>
							<div className="mb-3 text-right">
								<Button color="danger" onClick={() => this.cancelEdit()}>Cancel</Button> &nbsp;
								<Button color="primary" type="submit" disabled={this.props.loadedAssets}>Save</Button>
							</div>
						</Form>
					</ModalBody>
				</Loader>
			</Modal>
		)
	}
}
export default PingAssetEdit;
