import React from "react";
import {getEZTrackAssetLink, getShipmentLink} from "../../common/utilities";


const AssetEntry = ({asset, user, requestCompanyUuid}) => {
    return <tr>
        {asset.ezTrackUuid ?
            <td key={1} >{getEZTrackAssetLink(asset.ezTrackUuid, asset.assetId)}</td>
            :
            <td key={1} >{asset.assetId}</td>
        }
        <td key={2} >{asset.assetType}</td>
        <td key={3} >{asset.latestShipmentId && getShipmentLink(user, asset.latestShipmentId, requestCompanyUuid)}</td>
    </tr>
}




const AssetList = ({assets, user, requestCompanyUuid}) => {
    return <table className="table table-striped w-auto table-responsive">
        <thead>
        <tr>
            <th>Asset ID</th>
            <th>Asset Type</th>
            <th>Latest Shipment</th>
        </tr>
        </thead>
        <tbody>
        {assets.map((asset, ix)=>asset && <AssetEntry key={ix} asset={asset} user={user} requestCompanyUuid={requestCompanyUuid} />)}
        </tbody>
    </table>
}


export default AssetList;