import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {
    promiseTractorByCarrierAndIdPrefix,
    promiseTractorByIdPrefix,
} from "../../NetworkHelpers";
import * as PropTypes from "prop-types";
import * as uuid from "uuid";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import FFFormSelectStateField from "../../common/formik-free/FFFormSelectStateField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";


export class FFTractorCard extends Component {
    render() {
        return <Card>
            <CardHeader>
                <div className="form-section-title">
                    <Button onClick={this.props.onClick}>
                        <i className={this.props.className}/>
                    </Button> Tractor
                </div>
            </CardHeader>
            <CardBody>
                <FFFormSelectCreatableField
                    key={"ID_"+uuid.v4()}
                    defaultOptions
                    label="Tractor Number"
                    subLabel="(Start typing to lookup tractor)"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-hashtag"
                    fieldname={"number"}
                    error={this.props.errors && this.props.errors["number"]}
                    touched={this.props.touched && this.props.touched["number"]}
                    onBlur={this.props.onBlur}
                    value={this.props.values && this.props.values["number"]}
                    getOptionLabel={this.props.optionLabel}
                    getOptionValue={this.props.optionLabel}
                    loadOptions={promiseTractorByIdPrefix}
                    onChange={(v)=>{
                        let values;
                        if(!v) {
                            v ={}
                        }
                        values = Object.assign(this.props.values, {});
                        values.number = v.assetId ? v.assetId : "";
                        values.assetId = v.assetId ? v.assetId : "";
                        values.uuid = v.uuid ? v.uuid : "";
                        values.tractorLicense = v.license ? v.license : "";
                        values.tractorLicenseState = v.licenseState ? v.licenseState : "";
                        this.props.onChange && this.props.onChange(values);
                    }}
                    isDisabled={this.props.isDisabled}
                />
                {this.props.sectionOpen ?
                    <FFFormTextField
                        label="License Plate"
                        placeholder="Enter Value"
                        icon="fa-hashtag"
                        fieldname="tractorLicense"
                        error={this.props.errors.tractorLicense}
                        onBlur={this.props.onBlur}
                        onChange={(v)=>{
                            this.props.values['tractorLicense'] = v;
                            this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched.tractorLicense}
                        value={this.props.values.tractorLicense}
                    /> : ""}
                {this.props.sectionOpen ?
                    <FFFormSelectStateField
                        label="License State"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="tractorLicenseState"
                        errors={this.props.errors.tractorLicenseState}
                        onBlur={this.props.onBlur}
                        touched={this.props.touched.tractorLicenseState}
                        value={this.props.values.tractorLicenseState}
                        onChange={(v)=>{
                            this.props.values['tractorLicenseState'] = v;
                            this.props.onChange(this.props.values);
                        }}
                        selectedCountry={"United States"}
                    /> : ""}
            </CardBody>
        </Card>;
    }

    hasCarrier() {
        return this.props.values && this.props.values["broker"] || this.props.values && this.props.values["carrier"];
    }
}

FFTractorCard.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.func,
    onChange: PropTypes.func,
    sectionOpen: PropTypes.any
};