import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { exportCarriersToExcel, importCarrierFile, postCarrier, searchCarriers } from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import CarrierForm from "./CarrierForm";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { showError, showInfo } from "../../MainApp";
import { FileDropModal } from "../../common/FileDropModal";
import HelpIcon from "../../common/HelpIcon";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class Carriers extends Component {
    tableColumns = [
        {
            Header: "Created Date",
            accessor: 'created',
            filterable: false,
            sortable: true,
            width: 250,
            Cell: row => { let v = row.original; return v.created ? <MomentDisplay date={v.created} timezone={this.props.currentLocation && this.props.currentLocation.timezone} /> : "New"; }
        },
        {
            Header: "Last Updated Date",
            accessor: 'updated',
            filterable: false,
            sortable: true,
            width: 250,
            Cell: row => { let v = row.original; return v.updated ? <MomentDisplay date={v.updated} timezone={this.props.currentLocation && this.props.currentLocation.timezone} /> : "New"; }
        },
        {
            Header: "Name",
            filterable: true,
            sortable: true,
            accessor: 'name'
        },
        {
            Header: "Type",
            filterable: false,
            sortable: true,
            accessor: 'type'
        },
        {
            Header: "DOT",
            filterable: true,
            sortable: true,
            accessor: 'usDotNumber'
        },
        {
            Header: "NRN",
            filterable: true,
            sortable: true,
            accessor: 'nationalRegistrationNumber'
        },
        {
            Header: "Enabled",
            accessor: 'enabled',
            filterable: true,
            sortable: true,
            Cell: row => { let v = row.original; return v.enabled === true ? "Yes" : "No"; },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={event => {
                            this.setState({ enabledFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.enabledFilter ? this.state.enabledFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: 'Action',
            filterable: false,
            accessor: 'action',
            Cell: row => { let v = row.original; return <span><Button onClick={() => this.editCarrier(v)}><i className="fa fa-edit" /> Edit Partner</Button></span>; },
            sortable: false
        }
    ];

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            assets: null,
            pages: -1,
            sorted: [],
            filtered: [],
            pageSize: props.itemsPerPage,
            editingCarrier: null,
            previousSearchRequest: {},
            importModalError: false,
            importModalShow: false
        }
        console.log(this.props.currentLocation);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.company.uuid !== this.props.company.uuid) {
            this.tableRef.current.fetchData();
        }
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    renderModal() {
        return (
            <CarrierForm
                loadedCarriers={this.state.loadedCarriers}

                editingCarrier={this.state.editingCarrier}
                cancel={() => this.cancelDialog()}
                save={(c, setErrors) => {
                    this.setState({ loadedCarriers: true })
                    this.saveCarrier(c, setErrors);
                }} />
        );
    }

    saveCarrier(c, setErrors) {
        if (!c.carrierShortCodes) {
            c.carrierShortCodes = [];
        }
        postCarrier(c, (response) => {
            this.setState({ loadedCarriers: false })
            if (response.code === '1000') {
                showError(response.message);
            } else {
                this.tableRef.current.fetchData();
                this.cancelDialog();
            }
        }, (error) => {
            this.setState({
                loadedCarriers: false
            })
            setErrors(error.formError);
        });
    }

    cancelDialog() {
        this.setState({ editingCarrier: null });
    }

    startNewCarrier() {
        this.setState({
            editingCarrier: {
                name: "",
                nationalRegistrationNumber: "",
                carrierShortCodes: "",
                isNew: true,
                enabled: true,
                type: "Carrier"
            }
        });
    }

    editCarrier(carrier) {
        this.setState({ editingCarrier: carrier })
    }
    exportCarriers() {
        if (this.state.previousSearchRequest) {
            exportCarriersToExcel(this.state.previousSearchRequest, 'companies-export.xlsx', (response) => {
                if (response.emailSent) {
                    showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
                }
            }, (e) => {
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.showImportInstructions}>
                    <ModalHeader>
                        Import Instructions
                    </ModalHeader>
                    <ModalBody>
                        EZCheck-In allows for importing certain data via a file in CSV format or XLSX format.
                        To get started with importing carriers or brokers click the Download Import Template to obtain a sample CSV file.
                        Add one row per carrier or broker that you wish to import.<br />
                        <br />
                        The following are the list of columns that should be included.
                        Any fields with an asterisk are required and must be non blank.
                        Two asterisks indicates that one of the fields is required, so at least one must have a value.
                        Any fields that contain a comma must be enclosed in quotes.<br />
                        <br />
                        Company Type* <HelpIcon helpMessage={"Carrier, Broker or LogisticsProvider"} /><br />
                        Name*<br />
                        US DOT Number** <HelpIcon placement="top" helpMessage={"Enter the USDOT Number. Only one of US DOT, FMCSA MC, FMCSA FF, FMCSA MX or National Registration number is required."} /><br />
                        FMCSA MC Number** <HelpIcon placement="top" helpMessage={"FMCSA MC Number. Only one of US DOT, FMCSA MC, FMCSA FF, FMCSA MX or National Registration number is required."} /><br />
                        FMCSA FF Number** <HelpIcon placement="top" helpMessage={"FMCSA FF Number. Only one of US DOT, FMCSA MC, FMCSA FF, FMCSA MX or National Registration number is required."} /><br />
                        FMCSA MX Number** <HelpIcon placement="top" helpMessage={"FMCSA MX Number. Only one of US DOT, FMCSA MC, FMCSA FF, FMCSA MX or National Registration number is required."} /><br />
                        National Registration Number** <HelpIcon placement="top" helpMessage={"Enter the National Registration Number. Only one of US DOT, FMCSA MC, FMCSA FF, FMCSA MX or National Registration number is required."} /><br />
                        Enabled* <HelpIcon helpMessage={"True or False"} /><br />
                        Short Codes <HelpIcon helpMessage={"In U.S., SCAC code(s) as assigned by NMFTA."} /><br />
                        Contact First Name<br />
                        Contact Last Name<br />
                        Contact Email<br />
                        Contact Phone Country Code<br />
                        Contact Phone<br />
                        Contact Mobile Country Code<br />
                        Contact Mobile<br />
                        <br />
                        Short Codes should be entered as a comma separated string (if multiple short codes are provided then the field must be enclosed in quotes).<br />
                        <br />
                        <div style={{ marginTop: '10px' }} className={'text-right'}>
                            <Button color="primary" onClick={() => this.setState({
                                showImportInstructions: false
                            })}>Close</Button>
                        </div>
                    </ModalBody>

                </Modal>
                <FileDropModal
                    open={this.state.importModalShow}
                    error={this.state.importModalError}
                    header={"Import Partners"}
                    onDrop={acceptedFiles => {
                        for (const file of acceptedFiles) {
                            if (file['type'] && !file['type'].includes('text')) {
                                //Or is it excel?
                                if (file['type'] && !(file['type'].includes('excel') || file['type'].includes('openxml'))) {
                                    this.setState({ importModalError: "File must be text" });
                                    return;
                                }
                            }
                            importCarrierFile(file, (res) => {
                                let response = res.content;
                                this.setState({ importModalShow: false });
                                showInfo(response.message + " in " + response.seconds + " seconds.");
                                this.tableRef.current.fetchData();
                            }, (response) => {
                                this.setState({ importModalError: response.message });
                            }, () => {
                                this.props.history.push("/");
                            })
                        }
                    }}
                    onClose={() => {
                        this.setState({
                            importModalError: false,
                            importModalShow: false
                        });

                    }}
                />
                <Loader show={this.state.loaded} message={this.spinner}>
                    <PaginationFilterTable
                        setloaded={(check) => {
                            this.setState({ loaded: check });
                            this.updateSpinner("");
                        }}
                        get={searchCarriers}
                        pageType="Admin"
                        stateStorage="ezcheckin-admin-carriers"
                        ref={this.tableRef}
                        renderModal={this.renderModal()}
                        columns={this.tableColumns}
                        baseFilter={[{ id: "self", value: "false" }]}
                        onFilterHide={() => {
                            this.setState({
                                enabledFilter: "undefined",
                            });
                        }}
                        onChange={(state) => {
                            this.setState({
                                previousSearchRequest: {
                                    page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered
                                }
                            });
                        }}
                        openFiltersCollaps={true}
                        successCallback={(response) => {
                            let filters = this.tableRef.current.state.filtered;
                            let sort = this.tableRef.current.state.sorted;
                            this.setState({
                                hasResults: response.totalElements > 0,
                                previousSearchRequest: { filtered: filters, sorted: sort }
                            });
                        }}
                        footerButton={
                            <Button disabled={!this.state.hasResults}
                                color="secondary" onClick={() => this.exportCarriers()}><i className="fa fa-file-excel" /> Export to Excel</Button>
                        }
                    >
                        <Button color="primary" onClick={() => this.startNewCarrier()}> <i className="fa fa-plus" /> Add New Partner </Button>
                        <Button style={{ marginLeft: '10px' }} color="secondary" className={"btn-sm"} onClick={() => {
                            this.setState({ importModalShow: true });
                        }}>
                            <i className="fa fa-file-import" /> Import
                        </Button>
                        <a style={{ marginLeft: '10px' }} href={"/assets/files/company-import-format.csv"}>Download Import Template</a>
                        <a style={{ marginLeft: '10px' }} href={"#"} onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showImportInstructions: true })
                        }}>Import Instructions</a>
                    </PaginationFilterTable>
                </Loader>
            </div>
        );
    }
}


export default Carriers;