import React, {Component} from 'react';
import DispatchForm from "./DispatchForm";
import {archiveDispatch, downloadDispatchPlan, postDispatch, startDispatch} from "../../NetworkHelpers";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {Stop} from "../model/Stop";
import {showError, showInfo} from "../../MainApp";
import DispatchHeader from "./DispatchHeader";
import {Card, CardHeader} from "reactstrap";


class DispatchEditor extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
        this.state = {
            advancedView: false,
            dispatch: {
                dispatchTrigger:"Manually",
                carrier: "",
                stops: [new Stop("Pickup"), new Stop("Delivery")],
                startConfiguration: "Empty",
                tractorNumber: "",
                trailerNumber:"",
                driver: {
                    hasSmartPhone: true
                },
                references: [],
                eventByShipmentTracking: false
            }
        };

    }


    componentDidMount(){
        window.scrollTo(0,0);
        if(this.props.match.params.uuid){
            //Get model
            this.getDispatch(this.props.match.params.uuid);
        }
    }

    getDispatch(uuid) {
        downloadDispatchPlan(uuid, (dispatch) => {
            // if(!dispatch.dispatchTrigger){
            //     dispatch.dispatchTrigger = "Manually";
            // }
            if (!dispatch.stops || dispatch.stops.length === 0) {
                dispatch.stops = [new Stop()];
            }

            //todo: bad way of doing this
            for (const stop of dispatch.stops) {
                if (stop.location) {
                    stop.locationUuid = stop.location.value;
                }

                if (stop.departingDriver) {
                    stop.differentDepartingDriver = true;
                }

                if (stop.departingTractor) {
                    stop.differentDepartingTractor = true;
                    stop.departingTractor.value = stop.departingTractor.assetId;
                }

                if (stop.departingTrailer) {
                    stop.differentDepartingTrailer = true;
                    stop.departingTrailer.trailerNumber = stop.departingTrailer.assetId;
                }

            }

            if (dispatch.startConfiguration === null) {
                dispatch.startConfiguration = "Empty";
            }
            this.setState({dispatch: dispatch});
        }, () => {
            //todo pop error
        })
    }

    onSubmit(values) {
        //fix the request - this is not a great place to do this.
        if(values && values.stops){
            for (const stop of values.stops) {
                if(stop.differentDepartingTrailer && stop.departingTrailer){
                    stop.departingTrailer.uuid = stop.departingTrailer.trailerUuid;
                    stop.departingTrailer.assetId = stop.departingTrailer.trailerNumber;
                }
            }
        }


        postDispatch(values, (response)=>{
            showInfo(values.dispatchTrigger === "Manually" ? "Dispatch Saved" : "Dispatch Scheduled");
            this.props.history.push("/dispatch/"+response.uuid);
            for (const stop of response.stops) {
                if(stop.location){
                    stop.location.value = stop.location.uuid;
                    stop.location.label = stop.location.name;
                }

                if(stop.departingTrailer){
                    stop.differentDepartingTrailer = true;
                }
                if(stop.departingTractor){
                    stop.differentDepartingTractor = true;
                }
                if(stop.departingDriver){
                    stop.differentDepartingDriver = true;
                }
            }

            this.setState({dispatch: response});
        }, (error)=>{
            showError("Failed to save dispatch: "+ error.message);
        });
    }


    saveStop(stop, index) {
        let modifiedStopPlan = Object.assign({}, this.state.dispatch.stopplan);

        modifiedStopPlan.stops[index] = stop;

        this.setState({...this.state.dispatch, stopplan: modifiedStopPlan});
    }

    onChange = function(field, value) {
        let newState = {...this.state.dispatch};
        newState[field] = value;
        // newState.errors = this.validate(this.validationSchema, this.driverValidationSchema);
        if(!newState.transportationServiceProviders) newState.transportationServiceProviders = [];
        if (field === "driver") {
            newState.eventByShipmentTracking = value.eventByShipmentTracking;
        }
        this.setState({dispatch: newState});
    };
    getHeaderHeight() {
        let hdr = document.getElementById('ez-app-header');
        if (hdr != null) {
            return hdr.offsetHeight;
        }
        return 55;
    }

    render() {
        return <>
            <Card style={{position: 'sticky', zIndex: 1, width: '100%', border: '0px', top: this.getHeaderHeight()}}>
                <CardHeader>
                    <DispatchHeader
                        title='Dispatch Details'
                        onSave={(v) => this.onSubmit(v)}
                        onStart={(uuid)=>{
                            startDispatch(uuid, null, ()=>{
                                showInfo("Started dispatch");
                                this.props.history.push("/dispatches")
                            }, ()=>{
                                showError("Failed to start dispatch");
                            })
                        }}
                        onArchive={(uuid)=>{
                            //reload disaptch
                            archiveDispatch(uuid, null, (res)=>{
                                let s = {...this.state.dispatch}
                                s.archived = res.archived;
                                this.setState({
                                    dispatch : s
                                });
                                showInfo(res.archived === false  ? "Dispatch Restarted" : "Dispatch Cancelled");
                            }, ()=>{
                                showError("Failed to archive dispatch");
                            });
                        }}
                        onCancel={()=>{
                            this.props.history.push("/dispatches")
                        }}
                        dispatch={this.state.dispatch}
                    />
                </CardHeader>
            </Card>
            <div className="text-left">
                <DispatchForm
                    onSubmit={(v) => this.onSubmit(v)}
                    onStart={()=>{
                        this.props.history.push("/dispatches")
                    }}
                    onArchive={()=>{
                        //reload disaptch
                        this.state.dispatch && this.getDispatch(this.state.dispatch.uuid)
                    }}
                    company={this.props.loggedInUser.company}
                    dispatch={this.state.dispatch}
                    onChange={(field, value) => {
                        this.onChange(field, value);
                    }}
                >
                </DispatchForm>
            </div>
            <Card style={{zIndex: 1, width: '100%', marginBottom: '0px'}}>
                <CardHeader>
                    <DispatchHeader
                         onSave={(v) => this.onSubmit(v)}
                         onStart={(uuid)=>{
                             startDispatch(uuid, null, ()=>{
                                 showInfo("Started dispatch");
                                 this.props.history.push("/dispatches")
                             }, ()=>{
                                 showError("Failed to start dispatch");
                             })
                         }}
                         onArchive={(uuid)=>{
                             //reload disaptch
                             archiveDispatch(uuid, null, (res)=>{
                                 let s = {...this.state.dispatch}
                                 s.archived = res.archived;
                                 this.setState({
                                     dispatch : s
                                 });
                                 showInfo(res.archived === false  ? "Dispatch Restarted" : "Dispatch Cancelled");
                             }, ()=>{
                                 showError("Failed to archive dispatch");
                             });
                         }}
                         onCancel={()=>{
                             this.props.history.push("/dispatches")
                         }}
                         dispatch={this.state.dispatch}
                    />
                </CardHeader>
            </Card>
        </>
    }

}

function mapStateToProps(state) {
    return {
        loggedInUser: state.login.user
    }
}

export default withRouter(connect(mapStateToProps)(DispatchEditor));