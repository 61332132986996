export default class ValidationService {

    constructor(){
    }

    static isPhoneValid(phone){
        if (!phone || typeof phone === "undefined" || phone ==="" || phone === null){
            return true;
        }
        let strippedPhone = phone.replace(/[()\.\- ]/g, '');
        return (!isNaN(parseInt(strippedPhone)) && ((""+parseInt(strippedPhone)).length >= 10));
    }

    static formatPhone(cc, phone, includeExt) {
        if ( !phone ) return phone;
        if(cc && (cc !== "+1" && cc !== "US") ) return phone;

        let forceExt = false;
        if(phone.length === 13 && phone.substring(12) === " "){
            forceExt = true;
        }
        let strippedPhone = phone.replace(/[\D]/g, '');
        if ( strippedPhone.substring(0,1) === '1' ) strippedPhone = strippedPhone.substring(1);
        phone = strippedPhone.substring(0,3) + "-" + strippedPhone.substring(3,6) + "-" + strippedPhone.substring(6,10) + (includeExt?" ext. " + strippedPhone.substring(10):"");
        phone = phone.trim();
        phone = phone.replace(/[\D]*$/g, '');
        if(forceExt && includeExt){
            phone += " ext."
        }
        return phone;
    }
}
