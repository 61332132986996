import * as React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { downloadFieldValidityForTable, saveFieldValidity } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { FileDropModal } from "../../common/FileDropModal";
import LocFieldValidityEdit, { ScreenCodOptions } from "./LocFieldValidityEdit";
import { fieldnamesProgress } from '../../common/poweryard/FieldNames';
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class FieldsValidity extends React.Component {
    tableColumns = [
        {
            Header: 'Screen Code',
            accessor: 'screenCode',
            sortable: false,
            filterable: true,
            Cell: row => {
                const foundOption = ScreenCodOptions.find(option => option.value === row.original.screenCode);
                return foundOption ? foundOption.label : "Label not found";

            },
            Filter: ({ filter, onChange }) => (
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={(event) => {
                            this.setState({ enabledFilter: event.target.value });
                            onChange(event.target.value);
                        }}
                        style={{ width: "100%" }}
                        value={this.state.enabledFilter}
                    >
                        <option value="undefined" hidden={true}>
                            Select
                        </option>
                        <option value="">All</option>
                        <option value="moved-task">Move Me Task Create</option>
                        <option value="task-in-progress">Task in Progress</option>
                    </select>
                </>
            ),

        },
        {
            Header: 'Location Nickname',
            accessor: 'location',
            sortable: false,
            filterable: true,
            Cell: row => {
                let v = row.original.locationDto;
                return v.nickname;
            }
        },

        {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: row => {
                return <Button title="Edit" aria-label="Edit Field Settings"
                    onClick={() => this.editSpot(row.original)}><i
                        className="fa fa-edit" /></Button>
            }
        }
    ];
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.enabledFilterRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem("poweryard-location-based-fields-settings"));
        if (localStorageState != null) {
            this.state = {
                fieldSettingUnderEdit: localStorageState.fieldSettingUnderEdit,
            }
        } else {
            this.state = {
                fieldSettingUnderEdit: {},
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.company.uuid !== this.props.company.uuid) {
            this.tableRef.current.fetchData();
        }
    }
    componentWillUnmount() {
        localStorage.setItem("poweryard-location-based-fields-settings", JSON.stringify({
            fieldSettingUnderEdit: this.state.fieldSettingUnderEdit,
        }))
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    editNewSpot() {
        this.setState({
            showModal: true,
            fieldSettingUnderEdit: {
                screenCode: "",
                mandatoryFields: [],
                locationDto: [],
                isNew: true,
            }
        });
    }
    renderModal() {
        return (
            <LocFieldValidityEdit
                fieldSettingUnderEdit={this.state.fieldSettingUnderEdit}
                loaded={this.state.loaded}
                cancel={() => this.cancelDialog()}
                save={(a, onSuccess, onError) => { this.saveFieldValidity(a, onSuccess, onError); }}
                onChange={(state) => {
                    this.setState({ fieldSettingUnderEdit: state })
                }}
                showModal={this.state.showModal}
            />
        );
    }
    cancelDialog() {
        this.setState({
            showModal: false, fieldSettingUnderEdit: {
                screenCode: "",
                mandatoryFields: [],
                locationDto: [],
                isNew: true,
            }
        });
    }
    editSpot(original) {
        let field = { ...original }
        field.locationDto = {
            label: field.locationDto.name,
            value: field.locationDto.uuid,
            ...field.locationDto
        };
        let fieldsArray = fieldnamesProgress;
        for (let index = 0; index < field.mandatoryFields.length; index++) {
            let value = field.mandatoryFields[index].fieldName;
            let label = fieldsArray.find(r => r.value == value).label
            field.mandatoryFields[index] = { label: label, value: value, fieldName: value };

        }

        // field.mandatoryFields = field.mandatoryFields.map(it=>{
        //     return {label : fieldnamesProgress.find(r=> r.value == it.fieldName).label, value: it.fieldName}
        // })
        this.setState({ showModal: true, fieldSettingUnderEdit: field })
    }
    saveFieldValidity(fieldInfo, onSuccess, onError) {
        this.setState({ loaded: true });
        let fields = fieldInfo.mandatoryFields.map((it) => {
            return { fieldName: it.value }
        });
        let addFieldSetting = { ...fieldInfo };
        addFieldSetting.locationDto = { uuid: fieldInfo.locationDto.value };
        addFieldSetting.mandatoryFields = fields;
        saveFieldValidity(addFieldSetting, (response) => {
            this.setState({ loaded: false });
            if (response.code === '1000') {
                showError(response.message);
            } else {
                showInfo("Field Setting Saved");
                this.tableRef.current.fetchData();
                this.props.onChange(this.state);
                onSuccess();
                this.setState({
                    showModal: false, fieldSettingUnderEdit: {
                        screenCode: "",
                        mandatoryFields: [],
                        locationDto: [],
                        isNew: true,
                    }
                });
            }
        }, (failure) => {
            showError(failure.message);
            onError();
        });
    }
    clearFilters() {
        // this.enabledFilterRef.current.value = undefined;
        // this.typeFilterRef.current.value = undefined;
        this.setState({ enabledFilter: undefined })
    }
    exportSpots() {
        // if ( this.state.previousSearchRequest ) {
        //     requestSpotsToExcel( this.state.previousSearchRequest, 'spot-export.xlsx', (response)=>{
        //         if (response.emailSent) {
        //             showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
        //         }
        //     }, (e)=>{
        //         console.log(e);
        //         showError("We are not able to generate the excel file at this time.  Please try again later.");
        //     });
        // }
    }
    render() {
        return (
            <div>
                <div className="column-wrapper text-left">
                    {/* <Modal isOpen={this.state.showImportInstructions}>
                        <ModalHeader>
                            Import Instructions
                        </ModalHeader>
                        <ModalBody>
                            EZCheck-In allows for importing certain data via a file in CSV format or XLSX format.
                            To get started with importing spots, click the Download Import Template to obtain a sample CSV
                            file.
                            Add one row per spot that you wish to import.<br/>
                            <br/>
                            The following columns must be included.
                            Any fields with an asterisk are required and must be non blank.
                            Any fields that contain a comma must be enclosed in quotes.<br/>
                            <br/>
                            Location* (case insensitive name, must already exist)<br/>
                            Yard Zone / Building*<br/>
                            Spot Type* (Dock Spot or Yard Spot)<br/>
                            Name<br/>
                            Enabled* (True or False)<br/>
                            <br/>
                            <div style={{marginTop: '10px'}} className={'text-right'}>
                                <Button color="primary" onClick={() => this.setState({
                                    showImportInstructions: false
                                })}>Close</Button>
                            </div>
                        </ModalBody>
                    </Modal> */}

                    <FileDropModal
                        open={this.state.importModalShow}
                        error={this.state.importModalError}
                        header={"Import Spots"}
                        onDrop={acceptedFiles => {
                            for (const file of acceptedFiles) {
                                if (file['type'] && !file['type'].includes('text')) {
                                    //Or is it excel?
                                    if (file['type'] && !(file['type'].includes('excel') || file['type'].includes('openxml'))) {
                                        this.setState({ importModalError: "File must be text" });
                                        return;
                                    }
                                }
                                // importSpotFile(file, (res)=>{
                                //     let response = res.content;
                                //     this.setState({importModalShow: false});
                                //     showInfo(response.message + " in " + response.seconds + " seconds.");
                                //     this.tableRef.current.fetchData();
                                // }, (response) => {
                                //     this.setState({importModalError: response.message});
                                // }, () => {
                                //     this.props.history.push("/");
                                // })
                            }
                        }}
                        onClose={() => {
                            this.setState({
                                importModalError: false,
                                importModalShow: false
                            });

                        }}
                    />
                    <Loader show={this.state.loaded} message={this.spinner}>
                        <PaginationFilterTable
                            setloaded={(check) => {
                                this.setState({ loaded: check });
                                this.updateSpinner("");
                            }}
                            onFilterHide={() => {
                                this.setState({
                                    enabledFilter: "undefined",
                                });
                            }}
                            openFiltersCollaps={true}
                            ref={this.tableRef}
                            get={downloadFieldValidityForTable}
                            pageType="poweryard-settings"
                            stateStorage="poweryard-location-based-fields-settings"
                            clearFilter={this.clearFilters.bind(this)}
                            renderModal={this.renderModal()}
                            columns={this.tableColumns}
                            successCallback={(response) => {
                                let filters = this.tableRef.current.state.filtered;
                                let sort = this.tableRef.current.state.sorted;
                                this.setState({
                                    hasResults: response.totalElements > 0,
                                    previousSearchRequest: { filtered: filters, sorted: sort }
                                });
                            }}
                        // footerButton={
                        //     <span title={"Select to extract your company spot data."}>
                        //     <Button disabled={!this.state.hasResults}
                        //             color="secondary" onClick={() => this.exportSpots()}><i className="fa fa-file-excel"/> Export to Excel</Button>
                        //     </span>
                        // }
                        >
                            <Button color="primary" onClick={() => this.editNewSpot()}> <i className="fa fa-plus" /> Add New Validity</Button>
                            {/* <Button style={{marginLeft: '10px'}} color="secondary" className={"btn-sm"} onClick={() => {
                            this.setState({importModalShow: true});
                        }}>
                            <i className="fa fa-file-import"/> Import
                        </Button> */}
                            {/* <a style={{marginLeft: '10px'}} href={"/assets/files/spot-import-format.csv"}>Download Import Template</a>
                        <a style={{marginLeft: '10px'}} href={"#"} onClick={(e)=>{
                            e.preventDefault();
                            this.setState({showImportInstructions: true})
                        }}>Import Instructions</a> */}
                        </PaginationFilterTable>
                    </Loader>
                </div>

            </div>
        )
    }
}
export default FieldsValidity;