import ValidationService from "./common/ValidationService";

const yup = require('yup');

const validationService = new ValidationService();

yup.addMethod(yup.string, "phone", function() {
    return this.test("phone", "Phone number is not valid", value =>
        ValidationService.isPhoneValid(value)
    );
})