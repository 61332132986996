import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./Login/components/Login";
import LoginPoweryard from "./Login/components/LoginPoweryard";
import LoginTA from "./TrackAssured/components/LoginTA";
import TrackAnnonamously from "./TrackAssured/components/TrackAnnonamously";
import PasswordResetTA from "./TrackAssured/components/PasswordResetTA";
import Register from "./Register/components/Register"
import RegisterCard from "./Register/components/RegisterCard"
import connect from "react-redux/es/connect/connect";
import { storeCurrentLocation, storeLogin, storeLogout, storeCurrentShift, storeCurrentUser,storeNotification } from "./Login/actions/Login";
import { withRouter } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultLayout from "./Dashboard/Main/components/DefaultLayout";
import { checkVersion, downloadAuthorizedLocations, logout,getNotice } from "./NetworkHelpers";
import PasswordReset from "./Login/components/PasswordReset";
import PasswordResetRequest from "./Login/components/PasswordResetRequest";
import { Col, Row } from 'reactstrap';
import Config from "./Config";
import EventByWeb from "./EventByWeb/components/EventByWeb";
import EventByWebDispatch from "./EventByWeb/components/EventByWebDispatch";
import Demo from "./Demo/Demo";
import Reset from "./Reset/Reset";


let getDefaultOptionsForToast = function () {
  let w = window.innerWidth;
  let opts = { position: toast.POSITION.TOP_CENTER, autoClose: true };
  if (w >= 800) {
    opts.className = 'wide-toast';
  } else {
    opts.className = '';
    opts.style = { width: w + 'px' };
  }
  return opts;
}
export function showError(message, error, customOptions) {
  let options = Object.assign(getDefaultOptionsForToast(), customOptions || {});
  toast(
    <div>
      <Row>
        <Col md={1}>
          <i style={{
            paddingTop: ".25em",
            paddingLeft: "1.5em",
            fontSize: "1.2em",
            color: "red"
          }} className={"fas fa-exclamation-triangle"} />
        </Col>
        <Col md={11}>
          {message}<br />
          <p style={{ fontSize: "10px", marginBottom: "0" }}>Drag or click the X to close</p>
        </Col>
      </Row>
    </div>, options);
}

export function showInfo(message, error, customOptions) {
  let options = Object.assign(getDefaultOptionsForToast(), customOptions || {});
  toast(<div>
    <Row>
      <Col md={1}>
        <i style={{
          paddingTop: ".25em",
          paddingLeft: "1.5em",
          fontSize: "1.2em",
          color: "green"
        }} className={"fas fa-clipboard-checkShu"} />
      </Col>
      <Col md={11}>
        {message}<br />
        <p style={{ fontSize: "10px", marginBottom: "0" }}>Drag or click the X to close</p>
      </Col>
    </Row>
  </div>, options);
}

class MainApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    }
    window.onload = () => {
      this.setState({ loaded: true });
    };
    let url = window.location.origin;
    let pathname = window.location.pathname;

    if (url.includes("vantix") && pathname == "/") {
      this.props.history.push("/login-trackassured");
    }
    // let PYPagesUrls = ['/', '/admin', '/poweryard-tasks', '/poweryard-onsite', '/poweryard-arrival', '/poweryard-task-history', '/poweryard-yardcheck-history', '/poweryard-arrival-history', '/poweryard-settings'];
    // let bool = PYPagesUrls.includes(pathname);
    // if (url.includes("poweryard") && PYPagesUrls.includes(pathname)) {
    //   this.props.history.push("/login-poweryard");
    // }
    if (url.includes("poweryard") && pathname == "/") {
      this.props.history.push("/login-poweryard");
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
      localStorage.setItem('APP_VERSION', Config.APP_VERSION);
    }

    if (localStorage.APP_VERSION !== Config.APP_VERSION) {
      localStorage.clear();
      localStorage.setItem('APP_VERSION', Config.APP_VERSION);
      window.location.reload(true);
    }

    getNotice(
      (response) => {
        this.props.setNotification(response.content); // Update state with notification value
      },
      (error) => {
        // onFailure callback
        console.error("Error fetching notification:", error);
      }
    );
    // Here
    // let variable = setInterval(() => {
    //     checkVersion(Config.version, (response) => {
    //         if (!response.result) {
    //             showInfo(response.message);
    //             clearInterval(variable)
    //         }
    //     }, () => {

    //     });
    // }, Config.checkVersionIntervalInMilliseconds)
  }

  defaultLocationIsSetAndUserHasPermissionToIt(user) {
    return user.defaultLocation && (user.locations.length === 0 || user.locations.some((l) => {
      return l.uuid === user.defaultLocation.uuid
    }))
  }

  login(username, user) {
    if (this.defaultLocationIsSetAndUserHasPermissionToIt(user)) {
      this.props.login(username, user, user.defaultLocation, user.shift);
    } else {
      // this.props.login(username, user, user.locations[0], user.shift);
      downloadAuthorizedLocations((data) => {
        if (data && data.content.length > 0) {
          this.props.login(username, user, data.content[0], user.shift);
        } else {
          this.props.login(username, user, null, user.shift)
        }
      },
        (e) => {
          this.setState({ loading: false });
          console.dir(e)
        }
        , "");
    }
  }

  logout() {
    logout((response) => {
      showInfo(response.message);
      this.props.logout();
      let url = window.location.origin;

      if (url.includes("vantix")) {
        this.props.history.push("/login-trackassured");
      } else if (url.includes("poweryard")) {
        this.props.history.push("/login-poweryard");
      } else {
        this.props.history.push("/");
      }
    }, () => console.log("Unable to logout"));
  }

  renderApp() {
    let url = window.location.origin;
    if (!this.state.loaded) {
      return <div></div>;
    }

    if (!this.props.loggedIn) {
      return <div>
        <Switch>
          <Route path="/password-reset/:code/:expiration/:uuid" component={(props) => <PasswordReset {...props} login={(username, user) => console.log(username, user)} />} />
          <Route path="/ebw/:uuid" component={(props) => <EventByWeb {...props} />} />
          <Route path="/:uuid/ebw" component={(props) => <EventByWebDispatch {...props} />} />
          <Route path="/password-reset-request" component={(props) => <PasswordResetRequest login={(username, user) => console.log(username, user)} />} />
          <Route path="/password-reset-request-trackassured" component={(props) => <PasswordResetTA login={(username, user) => console.log(username, user)} />} />
          <Route path="/track-anonymously/:uuid" component={(props) => <TrackAnnonamously login={(username, user) => console.log(username, user)} />} />
          <Route path="/register-card" component={() => <RegisterCard />} />
          {!Config.preventRegistration &&
            <Route path="/register" component={() => <Register login={(username, password) => this.login(username, password)} />} />
          }
          {Config.loginPageUrl === 'ez.e4score.com' &&
            <Route path="/login" component={() => <Login hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {Config.loginPageUrl === 'poweryard.lazerspot.com' &&
            <Route path="/login-poweryard" component={() => <LoginPoweryard notification={this.props.notification} hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {Config.loginPageUrl === 'poweryard-stage.lazerspot.com' &&
            <Route path="/login-poweryard" component={() => <LoginPoweryard notification={this.props.notification} hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {url.includes("poweryard") &&
            <Route path="/login-poweryard" component={() => <LoginPoweryard notification={this.props.notification} hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {Config.deployEnvironment === 'dev' &&
            <Route path="/login-poweryard" component={() => <LoginPoweryard notification={this.props.notification} hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {Config.loginPageUrl === 'vantix.e4score.com' &&
            <Route path="/login-trackassured" component={() => <LoginTA hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {url.includes("vantix") &&
            <Route path="/login-trackassured" component={() => <LoginTA hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          {Config.deployEnvironment === 'dev' &&
            <Route path="/login-trackassured" component={() => <LoginTA hideInformationOnSmall={true} login={(username, user) => this.login(username, user)} />} />
          }
          <Route path="/demo" component={() => <Demo />} />
          <Route path="/reset" component={() => <Reset />} />
          {/* <Route path="/" component={() => <Login login={(username, user) => this.login(username, user)} hideLoginOnSmall={!Config.hideInfoOnLogin} />} /> */}
          <Route path="/" component={() => <LoginPoweryard notification={this.props.notification} login={(username, user) => this.login(username, user)} hideLoginOnSmall={!Config.hideInfoOnLogin} />} />
        </Switch>
      </div>;
    } else {
      return <DefaultLayout logout={() => this.logout()} company={this.props.user.company} />;
    }
  }


  render() {
    return (<div>{this.renderApp()}
      <ToastContainer>
      </ToastContainer>
    </div>)
  }
}


function mapStateToProps(state) {
  return {
    user: state.login.user,
    loggedIn: state.login.loggedIn,
    notification: state.login.notification,
  }
}

function mapDispatchToProps(dispatch) {
  return ({
    login: function (username, user, location, shift) {
      dispatch(storeLogin(username, user, location, shift));
    },
    logout: function () {
      dispatch(storeLogout());
    },
    setCurrentLocation: function (location) {
      dispatch(storeCurrentLocation(location));
    },
    setCurrentUser: function (user) {
      dispatch(storeCurrentUser(user));
    },
    setCurrentShift: function (shift) {
      dispatch(storeCurrentShift(shift));
    },
    setNotification: function (notification) {
			dispatch(storeNotification(notification));
		}
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainApp));