import React, {Component} from 'react';
import {Form} from 'reactstrap';
import * as Yup from "yup";
import {getErrorsFromValidationError} from "../../common/utilities";
import {DispatchBasicInformationCard} from "./DispatchBasicInformationCard";
import {DispatchAssetInformation} from "./DispatchAssetInformation";
import DispatchStops from "./DispatchStops";
import {archiveDispatch, startDispatch} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import JSONPretty from "react-json-pretty";
import Config from "../../Config";

class DispatchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSections: {},
            errors: {},
            dispatch: props.dispatch,
        };
    }

    validationSchema(values) {
        if(values.dispatchTrigger === "Manually"){
            let validationRulesNoUuid = {
                carrier: Yup.string().required('TSP 1 is required.'),
            };
            return Yup.object().shape(validationRulesNoUuid)
        }else{
            let validationRules = {
                carrier: Yup.string().required('TSP 1 is required.'),
                startConfiguration: Yup.string().required('Trailer start is required.').nullable(),
                tractorNumber: Yup.string().required('Tractor number is required.').nullable(),
                trailerNumber: values.startConfiguration === "Bobtail" ? null : Yup.string().required('Trailer number is required.').nullable(),
            };

            return Yup.object().shape(validationRules)
        }
    }

    driverValidationSchema(values){
        if (!values.eventByShipmentTracking) {
            let validationRules = {
                cell: Yup.string().required('Cell is required.'),
                firstname: Yup.string().required('First name is required.'),
                lastname: Yup.string().required('Last name is required.')
            };

            return Yup.object().shape(validationRules)
        } else {
            return Yup.object().shape({});
        }
    }

    validate(getValidationSchema, getDriverValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            const driverValidationSchema = getDriverValidationSchema(values);

            let errors = {};

            try {
                validationSchema.validateSync(values, {abortEarly: false});
            } catch (error) {
                for (let inner of error.inner) {
                    if (inner.path === "driverCell") {
                        let openSections = this.state.openSections;
                        openSections["initialData"] = true;
                        this.setState({openSections: openSections});
                    }
                }
                errors = getErrorsFromValidationError(error)
            }

            if(values.driver && values.driver.touched){
                try {
                    driverValidationSchema.validateSync(values.driver, {abortEarly: false});
                    return errors;
                } catch (error) {
                    // for (let inner of error.inner) {
                    //     if (inner.path === "driverCell") {
                    //         let openSections = this.state.openSections;
                    //         openSections["initialData"] = true;
                    //         this.setState({openSections: openSections});
                    //     }
                    // }
                    errors.driver = getErrorsFromValidationError(error);
                    return errors;
                }
            }

            return errors;
        }
    }

    onChange(field, value) {
        this.props.onChange(field, value);
        let newState = {...this.state.dispatch};
        newState[field] = value;
        newState.errors = this.validate(this.validationSchema, this.driverValidationSchema)(this.props.dispatch);
        this.setState({dispatch: newState});
    };

    getReferenceNumberTypeOptions(shipmentReferenceTypeList, shipmentIdDisplay) {
        if(shipmentReferenceTypeList){
            return [{label: shipmentIdDisplay,
                value: shipmentIdDisplay}
            ].concat(
                shipmentReferenceTypeList.map((type) => {
                    return {label: type.name, value: type.name};
                }));
        }
        return [{label: "Shipment Id", value: ""}]
    }

    // validate={this.validate(this.validationSchema, this.driverValidationSchema)}
    render() {

        let referenceNumberTypeOptions = this.getReferenceNumberTypeOptions(
            this.props.dispatch.shipmentReferenceTypeList || this.props.company.shipmentReferenceTypeList,
            this.props.dispatch.defaultShipmentId || this.props.company.shipmentIdDisplay
        );

        return (<div>
                        <Form onSubmit={(e) => {
                            e.preventDefault();
                            this.props.onSubmit(this.props.dispatch);
                        }}>
                            <div className="column">
                                <DispatchBasicInformationCard
                                    errors={this.state.errors}
                                    onBlur={() => {
                                        let errors = this.validate(this.validationSchema, this.driverValidationSchema)(this.props.dispatch);
                                        this.setState({errors: errors});
                                    }}
                                    onChange={(field, value) => {
                                        this.onChange(field, value);
                                    }}
                                    disabled={this.props.dispatch.lockBasicInformation}
                                    onStartDispatch={
                                        (uuid) => {
                                            startDispatch(uuid, null, ()=>{
                                                showInfo("Started dispatch");
                                                this.props.onStart && this.props.onStart();
                                            }, ()=>{
                                                showError("Failed to start dispatch");
                                            })
                                        }
                                    }
                                    onArchiveDispatch={
                                        (uuid) => {
                                            archiveDispatch(uuid, null, (res)=>{
                                                showInfo(this.props.dispatch.archived === false ? "Dispatch Restarted" : "Dispatch Cancelled");
                                                this.props.onArchive && this.props.onArchive();
                                            }, ()=>{
                                                showError("Failed to archive dispatch");
                                            })
                                        }
                                    }
                                    values={this.props.dispatch}
                                />
                                {Config.debug && <JSONPretty data={this.props.dispatch.calls}/>}
                                <DispatchAssetInformation
                                    company={this.props.company}
                                    errors={this.state.errors}
                                    onBlur={() => {
                                        let errors = this.validate(this.validationSchema, this.driverValidationSchema)(this.props.dispatch);
                                        this.setState({errors: errors});
                                    }}
                                    onChange={(field, value) => {
                                        this.onChange(field, value);
                                    }}
                                    assetRoster={this.props.dispatch.assetRoster}
                                    values={this.props.dispatch}
                                    disabled={this.props.dispatch.lockAssetInformation}
                                    referenceNumberTypeOptions={referenceNumberTypeOptions}
                                />
                            </div>
                            <DispatchStops
                                company={this.props.company}
                                stops={this.props.dispatch.stops}
                                errors={this.state.errors}
                                onBlur={() => {
                                    let errors = this.validate(this.validationSchema, this.driverValidationSchema)(this.props.dispatch);
                                    this.setState({errors: errors});
                                }}
                                onChange={(field, value) => {
                                    this.onChange(field, value);
                                }}
                                values={this.props.dispatch.stops}
                                disabled={false}
                                dispatch={this.props.dispatch}
                                defaultPickupReferenceType={this.props.dispatch.shipmentIdDisplay || this.props.company.shipmentIdDisplay}
                                dispatchReferenceNumbers={this.props.dispatch.shipmentReferenceTypeList || this.props.company.shipmentReferenceTypeList}
                                referenceNumberTypeOptions={referenceNumberTypeOptions}
                                onSaveDispatch={()=>{
                                    this.props.onSubmit(this.props.dispatch);
                                }}
                            />
                        </Form>
            {this.props.children}
        </div>);
    }
}

export default DispatchForm;