import * as Actions from "../actions/Login";

let initialState = {
    user: null,
    loggedIn: false,
    location: null,
    isDisabled:false,
    notification:null
}

export default function login(state = initialState, action) {

    switch (action.type) {
        case Actions.LOGIN_USER:
            return Object.assign({},state, {loggedIn:true, username: action.username, user: action.user, location: action.location});
        case Actions.LOGOUT:
            return Object.assign({},state, {loggedIn:false, username: "", user: null});
        case Actions.SET_USERS_COMPANY:
            return Object.assign({},state, {...state, user:{...state.user, company: action.company}});
        case Actions.SET_USERS_COMPANY_REFERENCE_NUMBER_TYPES:
            return Object.assign({},state, {...state, user:{...state.user,
                    company: {...state.user.company, shipmentReferenceTypeList: action.referenceNumberTypes}}});
        case Actions.SET_CURRENT_LOCATION:
            return Object.assign({},state, {location:action.location});
        case Actions.SET_CURRENT_USER:
            return Object.assign({},state, {user:action.user});
        case Actions.SET_DISABLE_SCREEN:
            return Object.assign({},state, {isDisabled:action.isDisabled});
        case Actions.SET_NOTICE:
            return Object.assign({},state, {notification:action.notification});
        default: return state;

    }
}

