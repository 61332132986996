import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import ValidationService from "./ValidationService";
import CountryCodeSelect from "./CountryCodeSelect";

//import Creatable from "react-select";

class FormPhoneSelectCreatableField extends Component {
    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }


    renderSelect() {

        if (this.props.options ) {
            return (
                <CreatableSelect
                    name={this.props.fieldname}
                    placeholder={this.props.placeholder}
                    valid={!this.props.errors[this.props.fieldname]}
                    invalid={this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]}
                    onChange={(v) => {
                        if(v && v.value){
                            v.value = ValidationService.formatPhone(this.props.values[this.props.countryCodeFieldName], v.value, this.props.extension);
                        }
                        if(!this.props.disableFormik){
                            this.props.setFieldValue(this.props.fieldname, v ? v.value : "");
                            this.props.setFieldTouched(this.props.fieldname, true);
                        }
                    }}
                    onBlur={() => this.props.setFieldTouched(this.props.fieldname, true)}
                    value={this.props.values[this.props.fieldname] ? {
                        label: this.props.values[this.props.fieldname],
                        value: this.props.values[this.props.fieldname]} : null}
                    className={"basic-single select-in-form" + ((this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? " is-invalid" : "")}
                    classNamePrefix="select"
                    options={this.props.options.map((v) => {
                        return {value: v, label: v}
                    })}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}/>)
        } else {

            return (
                <AsyncCreatableSelect
                    name={this.props.fieldname}
                    defaultOptions={this.props.defaultOptions}
                    placeholder={this.props.placeholder}
                    valid={this.props.errors && !this.props.errors[this.props.fieldname]}
                    invalid={this.props.touched && this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]}
                    onBlur={() => this.props.setFieldTouched(this.props.fieldname, true)}
                    onChange={(v) => {
                        if(v && v.value){
                            v.value = ValidationService.formatPhone(this.props.values ? this.props.values[this.props.countryCodeFieldName] : "+1", v.value, this.props.extension);
                            console.log("Formatting to "+ v.value);
                        }
                        if ( this.props.onChange ) {
                            if(v && v.__isNew__) v.assetId = v.value;
                            this.props.onChange(v);
                        }
                        if(!this.props.disableFormik){
                            this.props.setFieldValue(this.props.fieldname, v ? v.value : "");
                            this.props.setFieldTouched(this.props.fieldname, true);
                        }
                    }}
                    value={this.props.values && this.props.values[this.props.fieldname] ? {
                        label: this.props.values[this.props.fieldname],
                        value: this.props.values[this.props.fieldname]} : null}
                    className={"basic-single select-in-form" + ((this.props.touched && this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? " is-invalid" : "")}
                    classNamePrefix="select"
                    loadOptions={this.props.loadOptions}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}/>)
        }
    }


    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''} {this.props.subLabel?<small><br />{this.props.subLabel}</small>:""}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <div className="country-code-select-wrapper">
                            <CountryCodeSelect
                                disableFormik={this.props.disableFormik}
                                fieldname={this.props.countryCodeFieldName}
                                value={this.props.values && this.props.values[this.props.countryCodeFieldName]}
                                values={this.props.values}
                                setFieldValue={this.props.setFieldValue}
                                setFieldTouched={this.props.setFieldTouched}
                                isDisabled={this.props.isDisabled}
                                onChange={(v) =>{
                                    this.props.onCountryCodeChange && this.props.onCountryCodeChange(v);
                                }}
                            />
                            </div>
                            {this.renderSelect()}
                            <FormFeedback>{this.props.errors && this.props.errors[this.props.fieldname]}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FormPhoneSelectCreatableField;