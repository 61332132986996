import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import {getErrorsFromValidationError} from "../../common/utilities";
import * as Yup from "yup";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import {promiseEZTrackBaseEquipmentByPrefix} from "../../NetworkHelpers";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class FreeFieldForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            editingFField: {
                name: "",
                baseEquipment: {},
            },
            errors: {},
            touched: {},
            types : [ 'string','int', 'bool','datetime'],
            localities : ["arrival","other","departure"],
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.editingFField !== prevProps.editingFField ) {
            this.setState(
                {   editingFField: this.props.editingFField,
                    modalIsOpen: this.props.showModal
                });
        }
    }
    cancelEdit() {
        this.setState( {
            modalIsOpen: false,
            editingFField:{
                name: "",
                fieldType: "",
                enabled: "",
                locality:"",
                isNew: true,
                company: this.props.company
            },
            errors: {},
            touched: {}
        });
        this.props.cancel();
    }
    updateSpinner(val) {
        this.spinner = (
          <span>
            <p className="loadingMessage">{val}</p>
            <LoaderIco
              loaded={false}
              lines={13}
              length={20}
              width={10}
              radius={30}
              corners={1}
              rotate={0}
              direction={1}
              color="#51b32e"
              speed={1}
              trail={60}
              shadow={false}
              hwaccel={false}
              className="spinner"
              zIndex={2e9}
              top="50%"
              left="50%"
              scale={1.0}
              loadedClassName="loadedContent"
            />
          </span>
        );
      }
    onSubmit() {
        this.updateSpinner('Saving ...');
        this.props.save && this.props.save(this.state.editingFField, () => {
            this.setState({modalIsOpen: false, touched: {}});
        }, () => {
            this.setState({modalIsOpen: true});
        });
    }
    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        };
    }
    validationSchema(values) {
        let validationRules = {
            name: Yup.string()
                .required('Name is required.'),
            type: Yup.string().nullable()
                .required('Type is required.'),
            locality: Yup.string().required('Locality is required'),
            label: Yup.string().required('Label is required'),
            placeHolder: Yup.string().required('Placeholder is required'),
        }
        return Yup.object().shape(validationRules)
    }
    render() {
        return(
            <Modal isOpen={this.state.modalIsOpen} size={'lg'}>
                <Loader show={this.props.loaded} message={this.spinner}>
                <ModalHeader>
                    {this.state.editingFField.isNew ? "Add Custom Field" : "Edit Custom Field"}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        let errors = this.validate(this.validationSchema.bind(this))(this.state.editingFField);
                        let errorsIsEmpty = Object.keys(errors).length === 0 && errors.constructor === Object;
                        if (!errorsIsEmpty || this.state.errors.name || this.state.errors.type) {
                            let t = {...this.state.touched};
                            t.name = true;
                            t.type = true;
                            this.setState({touched: t, errors: errors});
                            e.preventDefault();
                            return;
                        }
                        this.onSubmit();
                        e.preventDefault();
                        }}
                    >
                        <Card className="simple-card">
                            <CardHeader>Custom Field Type</CardHeader>
                            <CardBody>
                                <div>
                                    <FFFormTextField
                                        fieldname="label"
                                        label="Label"
                                        placeholder="Enter a Label for the Custom Field"
                                        icon="fa-tag"
                                        value={this.state.editingFField.label}
                                        required={true}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.editingFField);
                                            let touched = {...this.state.touched};
                                            touched.label = true;
                                            this.setState({errors: errors, touched: touched})
                                        }}
                                        error={this.state.errors.label}
                                        touched={this.state.touched.label}
                                        onChange={(v) => {
                                            let s = {...this.state.editingFField};
                                            s.label = v;
                                            this.setState({editingFField: s});
                                        }}
                                    ></FFFormTextField>
                                    <FFFormTextField
                                        fieldname="name"
                                        label="Name"
                                        placeholder="Enter a name for the Custom Field"
                                        icon="fa-tag"
                                        value={this.state.editingFField.name}
                                        required={true}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.editingFField);
                                            let touched = {...this.state.touched};
                                            touched.name = true;
                                            this.setState({errors: errors, touched: touched})
                                        }}
                                        error={this.state.errors.name}
                                        touched={this.state.touched.name}
                                        onChange={(v) => {
                                            v = v.trim();
                                            let s = {...this.state.editingFField};
                                            s.name = v;
                                            this.setState({editingFField: s});
                                        }}
                                    ></FFFormTextField>
                                     <FFFormTextField
                                        fieldname="placeHolder"
                                        label="Placeholder"
                                        placeholder="Enter a placeholder for the Custom Field"
                                        icon="fa-tag"
                                        value={this.state.editingFField.placeHolder}
                                        required={true}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.editingFField);
                                            let touched = {...this.state.touched};
                                            touched.placeHolder = true;
                                            this.setState({errors: errors, touched: touched})
                                        }}
                                        error={this.state.errors.placeHolder}
                                        touched={this.state.touched.placeHolder}
                                        onChange={(v) => {
                                            let s = {...this.state.editingFField};
                                            s.placeHolder = v;
                                            this.setState({editingFField: s});
                                        }}
                                    ></FFFormTextField>
                                    
                                    <FFFormSelectField
                                        fieldname="type"
                                        icon="fa-truck-loading"
                                        label="Custom Field Type"
                                        isDisabled={this.state.editingFField.fieldID ? true : false}
                                        placeholder="Select the Custom Field type"
                                        options={this.state.types}
                                        required={true}
                                        value={this.state.editingFField.type}
                                        onChange={(v) => {
                                            let s = {...this.state.editingFField};
                                            s.type = v.value;
                                            this.setState({editingFField: s});
                                        }}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.editingFField);
                                            let touched = {...this.state.touched};
                                            touched.type = true;
                                            this.setState({errors: errors, touched: touched})
                                        }}
                                        error={this.state.errors.type}
                                        touched={this.state.touched.type}
                                    >
                                    </FFFormSelectField>
                                    <FFFormSelectField
                                        fieldname="locality"
                                        icon="fa-truck-loading"
                                        label="Custom Field Locality"
                                        isDisabled={this.state.editingFField.fieldID ? true : false}
                                        placeholder="Select the Custom Field type"
                                        options={this.state.localities}
                                        required={true}
                                        value={ this.state.editingFField.locality}
                                        onChange={(v) => {
                                            let s = {...this.state.editingFField};
                                            s.locality = v.value;
                                            this.setState({editingFField: s});
                                        }}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.editingFField);
                                            let touched = {...this.state.touched};
                                            touched.locality = true;
                                            this.setState({errors: errors, touched: touched})
                                        }}
                                        error={this.state.errors.locality}
                                        touched={this.state.touched.locality}
                                    >
                                    </FFFormSelectField>
                                  
                                    <FFFormCheckboxField
                                        fieldname="isEnable"
                                        icon="fa-power-off"
                                        label="Enabled"
                                        value={this.state.editingFField.isEnable}
                                        onChange={(v) => {
                                            let s = {...this.state.editingFField};
                                            s.isEnable = v;
                                            this.setState({editingFField: s});
                                        }}
                                    >
                                    </FFFormCheckboxField>
                                    
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mb-3 text-right">
                            <Button color="danger" onClick={() => this.cancelEdit()}>Cancel</Button> &nbsp;
                            <Button color="primary" type="submit">Save</Button>
                        </div>
                    </Form>
                </ModalBody>
                </Loader>
            </Modal>
        )
    }
}
export default FreeFieldForm;
