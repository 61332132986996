
export const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";


export function storeItemsPerPage( itemsPerPage ) {
    return {
        type: SET_ITEMS_PER_PAGE,
        itemsPerPage: itemsPerPage
    }
}


