import React, {Component} from "react";
import {Input} from "reactstrap";
import RegisterInputGroup from "./RegisterInputGroup";

class RegisterTextField extends Component {

    render() {

        return (
            <RegisterInputGroup {...this.props}>
                <Input type={this.props.password ? "password":"text" }
                       placeholder={this.props.placeholder}
                       name={this.props.fieldname}
                       valid={this.props.touched && !this.props.error}
                       invalid={this.props.touched && !!this.props.error}
                       onChange={this.props.handleChange}
                       onBlur={this.props.handleBlur}
                       value={this.props.value}
                />
            </RegisterInputGroup>
        );

    }
}

export default RegisterTextField;