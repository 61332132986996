import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import AsyncSelect from "react-select/lib/Async";
import {
  promiseAuthorizedShiftSearch, downloadAuthorizedLocations,
  promiseAuthorizedLocationSearch, promiseAuthorizedLocationYardEnableSearch
} from "../../../NetworkHelpers";
import * as PropTypes from "prop-types";
import HelpIcon from "../../../common/HelpIcon";
export class ChangeShiftModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShift: null,
      selectedLocation: null
    };
  }

  render() {
    return <>
      <Modal isOpen>
        <ModalBody>
          <Row style={{ marginBottom: "15px" }}>
            <Col md={12}>
              <label className={"w-100"}>
                Your Current Location
                <AsyncSelect
                  placeholder="Choose a location"
                  defaultOptions

                  defaultValue={
                    this.props.currentLocation ?
                      { label: this.props.currentLocation.nickname ? this.props.currentLocation.nickname : this.props.currentLocation.name, value: this.props.currentLocation.uuid }
                      : null
                  }
                  onChange={(v) => {
                    this.setState({ selectedLocation: v })
                  }}
                  loadOptions={this.props.pageType === "onsite" ? promiseAuthorizedLocationYardEnableSearch : promiseAuthorizedLocationSearch}
                />
              </label>
            </Col>
          </Row>
          <Row style={{ marginBottom: "15px" }}>
            <Col md={12}>
              <label className={"w-100"}>
                Your Shift
                <AsyncSelect

                  placeholder="Choose Shift"
                  defaultOptions
                  defaultValue={
                    this.props.currentShift ?
                      { label: this.props.currentShift ? this.props.currentShift : this.props.currentShift, value: this.props.currentShift }
                      : null
                  }
                  onChange={(v) => {
                    this.setState({ selectedShift: v })
                  }}
                  loadOptions={promiseAuthorizedShiftSearch}
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col md={6} className={"text-left"}>

              {/* <Button color={"danger"} onClick={() => this.props.onCancel()}>
                                Cancel
                            </Button> */}
            </Col>
            <Col md={6} className={"text-right"}>
              <Button color={"primary"}
                onClick={() => this.props.onChange(this.state.selectedShift, this.state.selectedLocation)}
                disabled={!!!this.props.currentShift && !!!this.props.currentLocation}>
                Continue
              </Button>

            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>;
  }
}

export class CurrentShift extends Component {

  render() {
    return <span>

      <Button id="checkShift" onClick={this.props.onClick} color="link"></Button>
    </span>;
  }
}

export class ChangeShiftFormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationList: null,
      shiftList: null,
      open: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // this.startChangeLocation();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.open == true && prevState.open == false) {
      this.startChangeShift();
      this.startChangeLocation();
    }
  }

  onShiftsDownloaded(data) {
    this.setState({ shiftList: data })
  }

  onLocationsDownloaded(data) {
    this.setState({ locationList: data })
  }


  startChangeLocation() {
    downloadAuthorizedLocations((data) => {
      this.onLocationsDownloaded(data.content)
    }, (e) => {
      this.setState({ loading: false });
    });
  }

  startChangeShift() {

    this.onShiftsDownloaded([
      { label: "Day", value: "Day" },
      { label: "Mid", value: "Mid" },
      { label: "Night", value: "Night" }
    ]);

  }

  render() {
    if (this.state.open) {
      return <>
        <ChangeShiftModal
          yardCheckActive={this.props.yardCheckActive}
          currentShift={this.props.currentShift}
          setCurrentShift={this.props.setCurrentShift}
          currentLocation={this.props.currentLocation}
          pageType={this.props.pageType ? this.props.pageType : ""}
          setCurrentLocation={this.props.setCurrentLocation}
          onChange={(shift, location) => {
            if (shift) {
              for (const shiftEntity of this.state.shiftList) {
                if (shift.value === shiftEntity.value) {
                  if (location) {
                    for (const locationEntity of this.state.locationList) {
                      if (location.value === locationEntity.uuid) {
                        this.setState({ open: false }, () => {
                          this.props.onChange(shiftEntity, locationEntity);
                          return;
                        })
                      }
                    }
                  } else {
                    this.setState({ open: false }, () => {
                      this.props.onChange(shiftEntity);
                      return;
                    })
                  }
                }
              }
            } else {
              if (location) {
                for (const locationEntity of this.state.locationList) {
                  if (location.value === locationEntity.uuid) {
                    this.setState({ open: false }, () => {
                      this.props.onChange(undefined, locationEntity);
                      return;
                    })
                  }
                }
              } else {
                this.setState({ open: false })
              }
            }
          }}
          onCancel={() => {
            this.setState({ open: false })
          }}
        />

      </>;
    } else {
      return (
        <CurrentShift
          yardCheckActive={this.props.yardCheckActive}
          currentShift={this.props.currentShift}
          currentLocation={this.props.currentLocation}
          onClick={() => {
            this.setState({ open: true })
          }}
        />

      )
    }
  }
}

ChangeShiftFormField.propTypes = {
  onChange: PropTypes.func,
  currentShift: PropTypes.string,
  setCurrentShift: PropTypes.func,
  currentLocation: PropTypes.object,
  setCurrentLocation: PropTypes.func
}