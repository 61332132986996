import React, {Component} from "react";
import {Button} from "reactstrap";

export class DispatchStopButtons extends Component{
    render() {
        return <div>
            {this.props.showUpArrow &&
            <Button className="demo-bounds" onClick={this.props.onUp} title="Move Up">
                <i className="fa fa-arrow-up"/></Button>
            }
            {this.props.showDelete &&
            <div className="demo-card-button-group">
                <Button className="demo-card-button-utility bg-danger" onClick={this.props.onDelete} title="Delete This Step">
                    <i className="fa fa-trash"/> Delete Stop
                </Button>
            </div>
            }
        </div>;
    }
}