import React, { Component } from 'react';
import { Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";
import Select from "react-select";
import * as PropTypes from "prop-types";
let sample=""; 
class FFFormSelectFieldDepart extends Component {
    
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: this.getValue(props),
      inputValue: '' // New state to store raw input value
    };
  }

  icon() {
    if (this.props.icon) {
      return <i className={"fa " + this.props.icon}></i>
    } else if (this.props.iconText) {
      return <strong>{this.props.iconText}</strong>;
    }
    return " ";
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ value: this.getValue(nextProps) })
  }
  componentDidUpdate(){
    console.log("Search value",this.state.inputValue,sample)
  }
  render() {
    return (
      <FormGroup>
        <Row>
          <Col lg="2" md="3" sm="4">
            <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''}</span></Label>
          </Col>
          <Col lg="10" md="9" sm="8">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{this.icon()}</InputGroupText>
              </InputGroupAddon>
              <Select
                name={this.props.fieldname}
                maxMenuHeight={this.props.maxMenuHeight}
                fieldname={this.props.fieldname}
                isClearable={this.props.isClearable}
                placeholder={this.props.placeholder}
                isSearchable={this.props.isSearchable}
                errors={this.props.errors}
                touched={this.props.touched}
                isMulti={this.props.isMulti}
                onChange={(v) => {
                  this.setState({ value: v }, () => {
                    this.props.onChange && this.props.onChange(v);
                  })
                }}
                onInputChange={(inputValue) => {
                  this.setState({ inputValue }); // Update inputValue on each keystroke
                }}
                value={this.state.value}
                isDisabled={this.props.isDisabled}
                options={this.props.options && this.props.options.map((v) => {
                  if (v.value && v.label) {
                    return v
                  }
                  return { value: v, label: v }
                })
                }
                onBlur={() => {
                  sample=this.state.inputValue
                  this.props.onBlur && this.props.onBlur();
                }}
                className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
              />
              {this.props.departuretrailerno && (
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search"
                      onClick={() => {
                        const searchValue = sample;
                        sample = "";
                        this.props.onSearch && this.props.onSearch(searchValue);
                      }}
                    ></i>
                  </InputGroupText>
                </InputGroupAddon>
              )}
              <FormFeedback>{this.props.error}</FormFeedback>
            </InputGroup>
          </Col>
        </Row>
      </FormGroup>
    );
  }

  getValue(props) {
    if (!props) return {};
    return props.isMulti ? props.value : (props.value ? { label: props.value, name: props.value } : null);
  }
}


FFFormSelectFieldDepart.propTypes = {
  value: PropTypes.object,
  isDisabled: PropTypes.bool,
  error: PropTypes.object,
  options: PropTypes.array,
  touched: PropTypes.object,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  fieldname: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  required: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  iconText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export default FFFormSelectFieldDepart;