import React, { Component } from "react";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import MomentDisplay from "../../assets/components/MomentDisplay";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { showError } from "../../MainApp";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  CardHeader,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Row,
  Modal
} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import DatePicker from "react-datepicker";

import {
  promiseUserLocationSearch,
  promiseZoneByPrefix,
  promiseAllSpotTypes,
} from "../../NetworkHelpers";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class NoticeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utcStartDate: "",
      utcEndDate: "",
      wordscounter: 0,
      editorState: EditorState.createEmpty(),
      modalIsOpen: false,
      noticeUnderEdit: {
        notification: "",

      },
      errors: {},
      touched: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.noticeUnderEdit !== prevProps.noticeUnderEdit) {
      this.setState({
        noticeUnderEdit: this.props.noticeUnderEdit,
        modalIsOpen: this.props.showModal,
      });
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  cancelEdit() {
    const emptyEditorState = EditorState.createEmpty();
    this.setState({
      editorState: emptyEditorState,
      modalIsOpen: false,
      errors: {},
      touched: {},
    });
    this.props.cancel();
  }
  onSubmit() {
    this.updateSpinner("Saving...");

    const isValidTime = this.getTimeIn24HourFormat(this.state.noticeUnderEdit.startDate, this.state.noticeUnderEdit.endDate);

    if (isValidTime) {
      const emptyEditorState = EditorState.createEmpty();
      this.setState({ editorState: emptyEditorState });
      this.props.save &&
        this.props.save(
          this.state.noticeUnderEdit,
          () => {
            this.setState({ modalIsOpen: false, touched: {} });
          },
          () => {
            this.setState({ modalIsOpen: true });
          }
        );
    } else {
      showError("End Time cannot be earlier/equal than Start Time");
    }
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    let validationRules = {

    };
    return Yup.object().shape(validationRules);
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });

    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    let characterCount = plainText.length;
    const html = draftToHtml(convertToRaw(contentState));

    if (characterCount > 500) {
      const trimmedText = plainText.slice(0, 500);
      const newContentState = ContentState.createFromText(trimmedText);
      const newhtml = draftToHtml(convertToRaw(contentState));
      const newEditorState = EditorState.createWithContent(newContentState);
      characterCount = 500;
      let s = { ...this.state.noticeUnderEdit };
      s.notification = newhtml;
      this.setState({ editorState: newEditorState, noticeUnderEdit: s, wordscounter: characterCount });


    }
    else if (characterCount <= 500) {
      let s = { ...this.state.noticeUnderEdit };
      s.notification = html;
      this.setState({ noticeUnderEdit: s, wordscounter: characterCount });


    }
    else if (characterCount < 500) {
      let s = { ...this.state.noticeUnderEdit };
      s.notification = html;
      this.setState({ noticeUnderEdit: s, wordscounter: characterCount });

    }
    if (!editorState.getSelection().isCollapsed()) {
      this.setState({ editorState: editorState });
    }

  };
  handleCopyPaste = event => {
    if (
      event.ctrlKey &&
      (event.key === 'c' || event.key === 'x' || event.key === 'v')
    ) {
      event.preventDefault();
    }
  };
  handleBeforeInput = (chars) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const currentLength = plainText.length;
    const maxLength = 500;
    if (chars.length > 1) {
      return 'handled';
    }
    const remainingCharacters = maxLength - currentLength;

    if (remainingCharacters <= 0) {
      // Block input if the character limit is reached
      return 'handled';
    }

    return 'not-handled'; // Allow input if the character limit is not exceeded
  };
  toUTC = (date) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  };
  getTimeIn24HourFormat(startDate, endDate) {
    if (!startDate || !endDate) return false; // Handle the case when date is not provided

    if (startDate.toDateString() === endDate.toDateString()) {
      const startTime = startDate.getHours() * 60 + startDate.getMinutes();
      const endTime = endDate.getHours() * 60 + endDate.getMinutes();

      // Compare times only if dates are the same
      if (endTime > startTime) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }


  render() {
    console.log(this.state.noticeUnderEdit, `this is spotunder edit`);
    const { editorState } = this.state;
    const startDateFilled = !!this.state.noticeUnderEdit.startDate;
    const endDateFilled = !!this.state.noticeUnderEdit.endDate;

    let message = "";
    if (!startDateFilled && !endDateFilled) {
      message = "Fill all Datetime fields to enable it";
    } else if (!startDateFilled) {
      message = "Fill Start Datetime field to enable it";
    } else if (!endDateFilled) {
      message = "Fill End Datetime field to enable it";
    }

    return (
      <Modal isOpen={this.state.modalIsOpen} size={"lg"}>
        <Loader show={this.props.loaded} message={this.spinner}>
          <ModalHeader>
            {"Notification"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {

                this.onSubmit();
                e.preventDefault();


              }}
            >
              <Card className="simple-card">
                <CardHeader>Notification</CardHeader>
                <CardBody>
                  <div>
                    <div
                      style={{
                        border: '1px solid #ccc',
                        resize: 'vertical',
                        overflow: 'auto',
                        minHeight: '100px',
                        padding: '10px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        handleBeforeInput={this.handleBeforeInput}
                        placeholder="Please type or enter text (copy-paste is not allowed)"
                        toolbarClassName="toolbarClassName"
                        toolbar={{
                          options: ['inline', 'list', 'link'],
                          inline: {
                            options: ['bold', 'italic', 'underline'],
                          },
                          list: { inDropdown: true },

                        }}
                        onFocus={() => document.addEventListener("keydown", this.handleCopyPaste)}
                        onBlur={() => document.removeEventListener("keydown", this.handleCopyPaste)}

                      />
                    </div>
                    <Row>
                      <Col>
                        <p
                          className={"float-right"}
                          style={{
                            color: this.state.wordscounter >= 500 ? "red" : null,
                          }}
                        >
                          {this.state.wordscounter} / 500
                        </p>
                      </Col>
                    </Row>


                    {/* <FFFormTextField
                      fieldname="notification"
                      label="Notice"
                      placeholder="Write Notice for the PowerYard "
                      icon="fa-tag"
                      value={this.state.noticeUnderEdit.notification}
                      maxLength={150}
                      hideEmpty={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.noticeUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.notification = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.notification}
                      touched={this.state.touched.notification}
                      onChange={(v) => {
                        let s = { ...this.state.noticeUnderEdit };
                        s.notification = v;
                        this.setState({ noticeUnderEdit: s });
                      }}
                    ></FFFormTextField> */}
                    <FormGroup>
                      <Row>
                        <Col lg="2" md="3" sm="4">
                          <Label>
                            {"Select Start Datetime "}<span className={"text-danger"}>*</span>
                          </Label>
                        </Col>
                        <Col className={"task-form"} lg="10" md="9" sm="8">
                          <DatePicker

                            placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                            className={
                              this.state.errors &&
                              this.state.errors.startDate &&
                              "is-invalid"
                            }
                            selected={this.state.noticeUnderEdit.startDate}
                            onChange={(date) => {
                              let s = { ...this.state.noticeUnderEdit };
                              s.startDate = date;


                              // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                              if (date === null || !isNaN(date.getTime())) {
                                s.endDate = null;
                                this.setState({ noticeUnderEdit: s });
                              }
                            }}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm:ss a"
                            showTimeInput
                            isClearable
                            shouldCloseOnSelect={false}
                          />
                          <FormFeedback
                            className={"d-block"}
                            style={{ marginLeft: 0 }}
                          >
                            {this.state.errors && this.state.errors.startDate}
                          </FormFeedback>
                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col lg="2" md="3" sm="4">
                          <Label>
                            {"Select End Datetime "}<span className={"text-danger"}>*</span>
                          </Label>
                        </Col>
                        <Col className={"task-form"} lg="10" md="9" sm="8">
                          <DatePicker

                            placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                            className={
                              this.state.errors &&
                              this.state.errors.endDate &&
                              "is-invalid"
                            }
                            selected={this.state.noticeUnderEdit.endDate}
                            onChange={(date) => {

                              let s = { ...this.state.noticeUnderEdit };
                              s.endDate = date;

                              // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion

                              if (date === null || !isNaN(date.getTime())) {
                                this.setState({ noticeUnderEdit: s });
                              }
                            }}
                            timeInputLabel="Time:"
                            dateFormat="MM/dd/yyyy h:mm:ss a"
                            showTimeInput
                            isClearable
                            shouldCloseOnSelect={false}
                            minDate={this.state.noticeUnderEdit.startDate}
                          />
                          <FormFeedback
                            className={"d-block"}
                            style={{ marginLeft: 0 }}
                          >
                            {this.state.errors && this.state.errors.end}
                          </FormFeedback>
                        </Col>
                      </Row>
                    </FormGroup>

                  </div>
                  <div>
                    <h1 style={{
                      color: '#4a6670',
                      fontSize: '12px',
                      fontWeight: '550',
                      lineHeight: '30px',
                      display: 'inline'
                    }}>Start Date UTC: <MomentDisplay
                        date={this.state.noticeUnderEdit.startDate}

                      />
                    </h1>
                    <br />

                    <h1 style={{
                      color: '#4a6670',
                      fontSize: '12px',
                      fontWeight: '550',
                      lineHeight: '30px',
                      display: 'inline'
                    }}>End Date UTC: <MomentDisplay
                        date={this.state.noticeUnderEdit.endDate}

                      />

                    </h1>
                  </div>
                </CardBody>
              </Card>



              <div className="mb-3 text-right">
                <Button color="danger" onClick={() => this.cancelEdit()}>
                  Cancel
                </Button>{" "}
                &nbsp;
                <Button color="primary" type="submit" disabled={this.state.noticeUnderEdit.endDate && this.state.noticeUnderEdit.startDate ? false : true} >
                  Save
                </Button>
                {!startDateFilled || !endDateFilled ? (
                  <p style={{ color: "#FF0000" }}>{message}</p>
                ) : null}

              </div>
            </Form>
          </ModalBody>
        </Loader>
      </Modal>
    );
  }
}
export default NoticeEdit;
