import {Component} from "react";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import React from "react";
import Modal from "reactstrap/es/Modal";
import {Alert, Button, CardTitle, Col, FormGroup, Label, ModalBody, ModalHeader, Row} from "reactstrap";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {getErrorsFromValidationError} from "../../common/utilities";
import FFFormDateTimeField from "../../common/formik-free/FFFormDateTimeField";

export class DispatchDepartureModal extends Component {
    validationSchema(values) {
        return Yup.object().shape({

        })
    }

    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        this.props.onSave(values.departure);
        return false;
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        }
    }


    render() {
        return <>
            <Modal isOpen={this.props.open} size={"lg"}>
                <ModalHeader>
                    Set Departure Time
                </ModalHeader>
                <ModalBody style={{overflow: 'auto'}}>
                    <Formik
                        onSubmit={(v, o) => {
                            this.onSubmit(v, o)
                        }}
                        validate={this.validate(this.validationSchema.bind(this))}
                        initialValues={{
                            departure: new Date()
                        }}
                        render={({
                                     values,
                                     errors,
                                     touched,
                                     status,
                                     dirty,
                                     handleChange,
                                     handleBlur,
                                     handleSubmit,
                                     isSubmitting,
                                     isValid,
                                     handleReset,
                                     setTouched,
                                     setFieldValue,
                                     setFieldTouched
                                 }) => (
                            <Form onSubmit={(v) => {
                                return handleSubmit(v)
                            }}>
                                <Card body>
                                    <Row>
                                        <Col>
                                            <FFFormDateTimeField
                                                label={"Departure Time"}
                                                placeholder="Enter Value"
                                                icon="fa-calendar"
                                                fieldname="departure"
                                                error={errors.departure}
                                                touched={errors.departure}
                                                onBlur={handleBlur}
                                                value={values.departure}
                                                timezone={this.props.timezone}
                                                isDisabled={this.props.disabled}
                                                onChange={(date) => {
                                                    setFieldValue("departure", date);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Row>
                                                    <Col lg="2" md="3" sm="4">
                                                        <Label>
                                                            Asset Id:
                                                        </Label>
                                                    </Col>
                                                    <Col>
                                                        {this.props.assetId}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="3" sm="4">
                                                        <Label>
                                                            Shipment Id:
                                                        </Label>
                                                    </Col>
                                                    <Col>
                                                        {this.props.shipment}
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            This interface allows you to mark the asset as departed and ends the dwell
                                            time
                                            clock.
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Alert style={{marginTop: "20px"}}>
                                                This will dispatch shipment and assets associated.
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-right">
                                                <Button color="danger" onClick={() => this.props.onCancel()}>Cancel</Button> &nbsp;
                                                <Button color="primary" type="submit">Set Departure Time</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Form>
                        )}
                    />
                </ModalBody>
            </Modal>
        </>;
    }
}