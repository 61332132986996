import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from "formik";
import {Button, Col, Form, Row} from "reactstrap";
import * as Yup from "yup";
import {getDwellFromSeconds, getDwellSecondsFromField, getErrorsFromValidationError} from "../../common/utilities";
import FormCheckboxField from "../../common/FormCheckboxField";
import {saveDispatchSettings} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import {DwellInputWithUnit} from "./LocationFormFields";

const validationSchema = function (values) {
    return Yup.object().shape({})
};

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values);
        try {
            validationSchema.validateSync(values, {abortEarly: false});
            return {}
        } catch (error) {
            console.log(error);
            return getErrorsFromValidationError(error)
        }
    }
};

export default class DispatchSettings extends React.Component {
    constructor(props) {
        super(props);
        if(!props.company.dispatchSettings){
            props.company.dispatchSettings = {};
        }

        if(!props.company.dispatchSettings.defaultGeoFence){
            props.company.dispatchSettings.defaultGeoFence = ".5"
        }
        if(!props.company.dispatchSettings.graceWindowInMinutes){
            props.company.dispatchSettings.graceWindowInMinutes = "0";
        }
        if(!props.company.dispatchSettings.reportGraceWindowInMinutes){
            props.company.dispatchSettings.reportGraceWindowInMinutes = "0";
        }
    }


    onSubmit(values, {setSubmitting, setErrors, resetForm}) {
        //Setup for E4SCORE3-700
        setSubmitting(false);


        saveDispatchSettings(this.props.company.uuid, values, (settings) => {
            showInfo("Successfully saved event dispatch settings");
            resetForm();
            this.props.onChange(settings);
        }, () => {
            showError("Failed to save settings")
        });
        return false;
    }

    render() {
        return (
            <div className="flex-column flex flex-grow-1">
                <Formik
                    enableReinitialize
                    initialValues={this.props.company.dispatchSettings || {
                        backupMessage: true,
                        droppedLoadedTrailerMessage: true,
                        enabled: true,
                        loadedMessage: true,
                        preloadedTrailerMessage: true,
                        sealNumberMessage: true,
                        unloadedMessage: true,
                        defaultGeoFence: "0.5"
                    }}
                    validate={validate(validationSchema)}
                    onSubmit={(v, o) => {
                        this.onSubmit(v, o)
                    }}
                    render={
                        ({
                             values,
                             errors,
                             touched,
                             status,
                             dirty,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                             isValid,
                             handleReset,
                             setTouched,
                             setFieldValue,
                             setFieldTouched
                         }) => (
                            <Form onSubmit={(v) => {
                                return handleSubmit(v)
                            }}>
                                <h5 className="card-title">Dispatch Settings</h5>
                                <Row>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Backed Against Dock Door"
                                            fieldname="backupMessage"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Loaded"
                                            fieldname="loadedMessage"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Connected to Pre-loaded Trailer"
                                            fieldname="preloadedTrailerMessage"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Seal Number"
                                            fieldname="sealNumberMessage"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Unloaded"
                                            fieldname="unloadedMessage"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                    <Col xs="12">
                                        <FormCheckboxField
                                            label="Dropped Loaded Trailer"
                                            fieldname="droppedLoadedTrailerMessage"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FFFormTextField
                                            numbersOnly
                                            label={"Default Geo-Fence Radius (" + (this.props.company.distanceUnitsOfMeasure ? this.props.company.distanceUnitsOfMeasure : "Miles")+")"}
                                            placeholder=""
                                            icon="fa-globe"
                                            fieldname="defaultGeoFence"
                                            error={errors.defaultGeoFence}
                                            onTouch={handleBlur}
                                            onChange={(newValue) => {
                                                setFieldValue("defaultGeoFence", newValue);
                                                setFieldTouched("defaultGeoFence");
                                            }}
                                            touched={touched.defaultGeoFence}
                                            value={values.defaultGeoFence}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FFFormTextField
                                            numbersOnly
                                            label="Appointment Grace Window (Minutes)"
                                            placeholder=""
                                            icon="fa-clock"
                                            fieldname="graceWindowInMinutes"
                                            error={errors.graceWindowInMinutes}
                                            onTouch={handleBlur}
                                            onChange={(newValue) => {
                                                setFieldValue("graceWindowInMinutes", newValue);
                                                setFieldTouched("graceWindowInMinutes");
                                            }}
                                            touched={touched.graceWindowInMinutes}
                                            value={values.graceWindowInMinutes}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FFFormTextField
                                            numbersOnly
                                            label="Report Grace Window (Minutes)"
                                            placeholder=""
                                            icon="fa-clock"
                                            fieldname="reportGraceWindowInMinutes"
                                            error={errors.reportGraceWindowInMinutes}
                                            onTouch={handleBlur}
                                            onChange={(newValue) => {
                                                setFieldValue("reportGraceWindowInMinutes", newValue);
                                                setFieldTouched("reportGraceWindowInMinutes");
                                            }}
                                            touched={touched.reportGraceWindowInMinutes}
                                            value={values.reportGraceWindowInMinutes}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DwellInputWithUnit
                                            label="Warning Tractor Dwell Limit"
                                            value={getDwellFromSeconds(values.warningTractorDwellInSeconds, values.warningTractorDwellUnit)}
                                            touched={touched.warningTractorDwellInSeconds}
                                            unit={values.warningTractorDwellUnit}
                                            onChange={(obj) => {
                                                setFieldValue("warningTractorDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
                                                setFieldTouched("warningTractorDwellInSeconds");
                                                setFieldValue("warningTractorDwellUnit", obj.unit === '' ? null : obj.unit);
                                                setFieldTouched("warningTractorDwellUnit");

                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DwellInputWithUnit
                                            label="Critical Tractor Dwell Limit"
                                            value={getDwellFromSeconds(values.criticalTractorDwellInSeconds, values.criticalTractorDwellUnit)}
                                            touched={touched.criticalTractorDwellInSeconds}
                                            unit={values.criticalTractorDwellUnit}
                                            onChange={(obj) => {
                                                setFieldValue("criticalTractorDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
                                                setFieldTouched("criticalTractorDwellInSeconds");
                                                setFieldValue("criticalTractorDwellUnit", obj.unit === '' ? null : obj.unit);
                                                setFieldTouched("criticalTractorDwellUnit");

                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DwellInputWithUnit
                                            label="Warning Trailer Dwell Limit"
                                            value={getDwellFromSeconds(values.warningTrailerDwellInSeconds, values.warningTrailerDwellUnit)}
                                            touched={touched.warningTrailerDwellInSeconds}
                                            unit={values.warningTrailerDwellUnit}
                                            onChange={(obj) => {
                                                setFieldValue("warningTrailerDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
                                                setFieldTouched("warningTrailerDwellInSeconds");
                                                setFieldValue("warningTrailerDwellUnit", obj.unit === '' ? null : obj.unit);
                                                setFieldTouched("warningTrailerDwellUnit");

                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DwellInputWithUnit
                                            label="Critical Trailer Dwell Limit"
                                            value={getDwellFromSeconds(values.criticalTrailerDwellInSeconds, values.criticalTrailerDwellUnit)}
                                            touched={touched.criticalTrailerDwellInSeconds}
                                            unit={values.criticalTrailerDwellUnit}
                                            onChange={(obj) => {
                                                setFieldValue("criticalTrailerDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
                                                setFieldTouched("criticalTrailerDwellInSeconds");
                                                setFieldValue("criticalTrailerDwellUnit", obj.unit === '' ? null : obj.unit);
                                                setFieldTouched("criticalTrailerDwellUnit");

                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DwellInputWithUnit
                                            label="Warning Container Dwell Limit"
                                            value={getDwellFromSeconds(values.warningContainerDwellInSeconds, values.warningContainerDwellUnit)}
                                            touched={touched.warningContainerDwellInSeconds}
                                            unit={values.warningContainerDwellUnit}
                                            onChange={(obj) => {
                                                setFieldValue("warningContainerDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
                                                setFieldTouched("warningContainerDwellInSeconds");
                                                setFieldValue("warningContainerDwellUnit", obj.unit === '' ? null : obj.unit);
                                                setFieldTouched("warningContainerDwellUnit");

                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <DwellInputWithUnit
                                            label="Critical Container Dwell Limit"
                                            value={getDwellFromSeconds(values.criticalContainerDwellInSeconds, values.criticalContainerDwellUnit)}
                                            touched={touched.criticalContainerDwellInSeconds}
                                            unit={values.criticalContainerDwellUnit}
                                            onChange={(obj) => {
                                                setFieldValue("criticalContainerDwellInSeconds", obj.value === '' ? null : getDwellSecondsFromField(obj.value, obj.unit));
                                                setFieldTouched("criticalContainerDwellInSeconds");
                                                setFieldValue("criticalContainerDwellUnit", obj.unit === '' ? null : obj.unit);
                                                setFieldTouched("criticalContainerDwellUnit");

                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" className={"text-right"}>
                                        <Button color="primary" type="submit">Save</Button>
                                    </Col>
                                </Row>
                            </Form>)}
                />
            </div>
        );
    }

}
DispatchSettings.propTypes = {
    company: PropTypes.any,
}
