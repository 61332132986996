import React, {Component} from 'react';
import {
    Col,
    FormFeedback,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import * as uuid from "uuid";
import CreatableSelect from 'react-select/lib/Creatable';

class FFFormSelectCreatableField extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            tooltipOpen: false,
            id: "ID_"+uuid.v4()
        };
    }

    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    renderSelect() {
        if (this.props.options) {
            return (
                <CreatableSelect
                    name={this.props.fieldname}
                    placeholder={this.props.placeholder}
                    valid={!this.props.error}
                    invalid={this.props.touched&& !!this.props.error}
                    onChange={(v) => {
                        if ( this.props.onChange ) {
                            if (v && v.__isNew__) {
                                v.assetId = v.value;
                            }
                            this.props.onChange && this.props.onChange(v);
                        }
                    }}
                    onBlur={() => this.props.onBlur && this.props.onBlur()}
                    value={this.props.value ? {
                        label: this.props.value,
                        value: this.props.value
                    } : {label: "", value: ""}}
                    className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
                    // classNamePrefix="select"
                    options={this.props.options.map((v) => {
                        return {value: v, label: v}
                    })}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}/>)
        } else {
            return (
                <AsyncCreatableSelect
                    name={this.props.fieldname}
                    defaultOptions={this.props.defaultOptions}
                    placeholder={this.props.placeholder}
                    valid={this.props.errors && !this.props.error}
                    invalid={this.props.touched && !!this.props.error}
                    onBlur={this.props.onBlur}
                    onChange={(v) => {
                        if ( this.props.onChange ) {
                            if (v && v.__isNew__) {
                                v.assetId = v.value;
                            }
                            this.props.onChange && this.props.onChange(v);
                        }
                    }}
                    value={this.props.value && this.props.value ? {
                        label: this.props.value,
                        value: this.props.value
                    } : this.props.hideEmpty ? null : {label: "", value: ""}}
                    className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
                    classNamePrefix="select"
                    loadOptions={this.props.loadOptions}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}/>)
        }
    }


    render() {
        return (

            <FormGroup>
                <Row>
                    {!this.props.noLabel &&
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''} </span>{this.props.subLabel ?
                            <small><br/>{this.props.subLabel}</small> : ""}</Label>
                        {this.props.helpMessage &&
                        <div>
                            <i style={{
                                position: "absolute",
                                right: 0,
                                top: "5px",
                                color: "grey"
                            }}
                               className={"fa fa-question-circle"}
                               id={this.state.id}
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                                     toggle={() => {
                                         this.setState({
                                             tooltipOpen: !this.state.tooltipOpen
                                         })
                                     }}>
                                {this.props.helpMessage}
                            </Tooltip>
                        </div>
                        }
                    </Col>
                    }
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            {this.renderSelect()}
                            <FormFeedback>{this.props.error}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FFFormSelectCreatableField;