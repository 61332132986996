import React from 'react';
import { Formik } from "formik";
import { Button, Form } from "reactstrap";
import FormCheckboxField from "../../common/FormCheckboxField";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";

export default class PoweryardCompanies extends React.Component {
  constructor(props) {
    super(props);


    console.log('comp props', props)
  }
  validationSchema(values) {
    let validationRules = {
    };
    return Yup.object().shape(validationRules)
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false })
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }

  onSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(false);
    // if (!values.enableEzTrack) {
    //   values.ezTrackShipmentTrackingEnabled = false;
    // }
    values.uuid = this.props.company.uuid;
    // let settingObj = {
    //   enableGate: values.enableGate,
    //   enableOnsite: values.enableOnsite,
    //   enableTask: values.enableTask
    // }
    this.props.save(values, setErrors);
    return false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // if (!this.props.company.maTrackReportFrequencyInHours) {
    //   this.props.company.maTrackReportFrequencyInHours = 12;
    // }
  }

  render() {
    return (
      <div className="column-wrapper">
        <Formik
          enableReinitialize
          initialValues={this.props.company}
          validate={this.validate(this.validationSchema.bind(this))}
          onSubmit={(v, o) => {
            this.onSubmit(v, o)
          }}
          render={
            ({
              values,
              errors,
              touched,
              status,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              handleReset,
              setTouched,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form onSubmit={(v) => {
                return handleSubmit(v)
              }}>
                <FormCheckboxField
                  label="PowerYard"
                  icon="fa-power-off"
                  fieldname="enableTask"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="PowerYard On-Site"
                  icon="fa-power-off"
                  isInfoIcon={true}
                  fieldname="enableOnsite"

                  infoText={`When turned on, show the On-site and Yard History page in the main menu.`}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="PowerYard Gate"
                  icon="fa-power-off"
                  infoText={`When turned on, show the Gate and GateHistory page in the main menu.`}
                  fieldname="enableGate"
                  isInfoIcon={true}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />



                <div className="mb-3 text-right">
                  <Button color="primary" type="submit">Save</Button>
                </div>
              </Form>
            )} />
      </div>
    );
  }
}
PoweryardCompanies.propTypes = {}
