import React, {Component} from "react";
import * as uuid from "uuid";
import {Tooltip} from "reactstrap";
import {DwellAlert} from "./AssetsHistoryComponent";

export function getDwell(dwellInSeconds) {
    let dwell = null;
    if (dwellInSeconds > (60 * 60 * 24)) {
        dwell = (dwellInSeconds / (60 * 60 * 24)).toFixed(2);
        return dwell + " Days"
    } else if (dwellInSeconds > (60 * 60)) {
        dwell = (dwellInSeconds / (60 * 60)).toFixed(2);
        return dwell + " Hours"
    } else if (dwellInSeconds > 60) {
        dwell = (dwellInSeconds / (60)).toFixed(2);
        return dwell + " Minutes"
    } else {
        return 1 + " Second"
    }
}

export function getDwellForLimit(dwell, unit) {
    if (!unit || unit === "Minutes") {
        return <> {dwell} Minutes</>;
    } else if (unit === "Hours") {
        return <> {dwell / (60)} {unit}</>;
    } else if (unit === "Days") {
        return <> {dwell / ( 60 * 24)} {unit}</>;
    }
    return <> {dwell} {unit}</>;
}

export class DwellAlertTooltip extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return <span id={this.state.id} className={this.props.showOn === "progress" ? this.props.type == "Tractor" ? "tractorPro": "trailorPro": ""}>
            <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.state.id}
                     toggle={() => {
                         this.setState({
                             tooltipOpen: !this.state.tooltipOpen
                         })
                     }}>
               Dwell: {getDwell(this.props.dwellInSeconds)}<br/>
               Warning Dwell Limit: {getDwellForLimit(this.props.warningDwellInSeconds/60, this.props.warningDwellUnit)}<br/>
               Critical Dwell Limit: {getDwellForLimit(this.props.criticalDwellInSeconds/60, this.props.criticalDwellUnit)} <br/>
            </Tooltip>
            <DwellAlert {...this.props}/>
        </span>
    }

}