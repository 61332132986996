import React, {Component} from 'react';
import {downloadWorkflow, downloadWorkflows, saveWorkflowCustomizations} from "../../NetworkHelpers";
import {showError, showInfo} from "../../MainApp";
import {Col, FormFeedback, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import Select from "react-select";
import Workflow from "./Workflow"



class Workflows extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedWorkflow: null,
            workflowAndCustomizations: null
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.downloadWorkflows();
    }


    downloadWorkflows() {
        downloadWorkflows((data)=>this.onWorkflowsDownloaded(data), (error)=> {
                showError("Unable to download workflow list");
                this.setState({loading: false});
            }
        )
    }

    onWorkflowsDownloaded( data ) {
        this.setState({workflows: data, loading: false});
    }

    setSelectedWorkflow( workflow ) {
        this.setState( {selectedWorkflow: workflow});
        this.loadWorkflow(workflow );
    }

    loadWorkflow( workflow ) {
        if ( workflow ) {
            downloadWorkflow(workflow.uuid, (workflowData) => {
                    this.setState({workflowAndCustomizations: workflowData})
                },
                (error) => showError("Unable to load workflow"));
        } else {
            this.setState({workflowAndCustomizations: null})
        }
    }

    saveWorkflowCustomizations(customizations) {
        saveWorkflowCustomizations(this.state.selectedWorkflow.uuid, customizations, (workflowData)=>{showInfo("The Workflow Customizations have been Saved", null, {autoClose:true});this.setState({workflowAndCustomizations: workflowData})}, (error)=>showError("Unable to save workflow"));
    }

    render() {
        if ( this.state.loading ) {
            return <div>Loading workflows</div>;
        }


        return <div><div><InputGroup className="mb-3 select-form-group">
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <i className="fa fa-clipboard-list"></i>
                </InputGroupText>
            </InputGroupAddon>
            <Select
                placeholder="Workflow"
                onChange={(v) => {
                    this.setSelectedWorkflow(v?{name: v.label, uuid: v.value}:null )
                }}
                value={this.state.selectedWorkflow ? {
                    label: this.state.selectedWorkflow.name,
                    value: this.state.selectedWorkflow.uuid
                } : null}
                className="basic-single select-in-form"
                classNamePrefix="select"
                options={this.state.workflows?this.state.workflows.map((wf)=>{return {label:wf.name, value: wf.uuid}}):[]}
                isClearable={true}/>
        </InputGroup></div>
            {this.state.workflowAndCustomizations?
                <Workflow
                    workflow={this.state.workflowAndCustomizations.workflow}
                    customizations={this.state.workflowAndCustomizations.customizations}
                    resetWorkflow={()=>this.loadWorkflow(this.state.selectedWorkflow)}
                    saveWorkflowCustomizations={(customizations)=>this.saveWorkflowCustomizations(customizations)}
                />:""}
        </div>



    }

}

export default Workflows;