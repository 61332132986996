import * as React from "react";
import {Button , Col, Row} from "reactstrap";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {ITEMS_PER_PAGE_DEFAULT, MILLISECONDS_IN_24_HOURS} from "../../common/constants";
import {showError, showInfo} from "../../MainApp";
import {DISTANCE_FROM_DOMICILE, MOVE_FREQUENCY} from "./EZTrackEventTableColumn";
import Link from "react-router-dom/Link";
import {convertCtoF, getLocalStorageName,endOfDay,
    startOfDay} from "../../common/utilities";
import {requestTrackExcelToAssets} from "../../NetworkHelpers";
import Switch from "react-switch";
import DatePicker from "react-datepicker/es";
import moment from "moment-timezone";
import Moment from "react-moment";

class EZTrackAssets extends React.Component {
   

    tableColumns = [
        {
            Header: 'Asset ID',
            accessor: 'asset.assetId',
            sortable: true,
            filterable: true,
            default: true,
            Cell: row => {if (row.original.asset.assetId) {
                return row.original.asset.assetId ?
                <Link to={"asset-profile/"+row.original.asset.uuid}>{row.original.asset.assetId}</Link>
                : ""
                    // <span className={'fake-link'}>{row.original.asset.assetId}</span>
                    // : ""
            } else {
                return null;
            }}
        },
        {
            Header: 'Zone',
            accessor: 'zone',
            sortable: true,
            filterable: true,
            default: true,
        },
        {
            Header: 'Equipment Type',
            accessor: 'asset.equipment.name',
            sortable: true,
            filterable: true,
            default: false,
        },
        {
            Header: 'Device',
            accessor: 'trackerType',
            sortable: true,
            filterable: true,
            default: false,
            Filter: ({filter, onChange}) =>
                <>
                    <select
                        ref={this.trackerTypeFilterRef}
                        onChange={event => {
                            this.setState({trackerTypeFilter: event.target.value});
                            onChange(event.target.value)}}
                        style={{width: "100%"}}
                        value={this.state.trackerTypeFilter ? this.state.trackerTypeFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="MT9090">MT9090</option>
                        <option value="PG2400">PG2400</option>
                    </select>
                </>
        },
        {
            Header: 'Device ID',
            accessor: 'imei',
            filterable: true,
            sortable: true,
            default: false,
        },
        {
            Header: 'Domicile Name',
            accessor: 'asset.domicile.name',
            filterable: true,
            sortable: true,
            default: true,
        },
        {
            Header: 'Last Report Time',
            accessor: 'latestEventDate',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {
                let v = row.original;
                if (v.latestEventDate) {
                    return <MomentDisplay date={v.latestEventDate} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/>;
                } else {
                    return null;
                }
            },
        },
        {
            Header: 'Battery',
            accessor: 'battery',
            filterable: false,
            default: true,
            sortable: true,
            Cell: row => {let bat = row.original.battery;
                if (bat == null) {
                    return null;
                }
                else if (bat >= 95.0) {
                    return <span>{bat}% <i className="fa fa-battery-full">&nbsp;</i></span>
                }
                else if (bat >= 75.0) {
                    return <span>{bat}% <i className="fa fa-battery-three-quarters">&nbsp;</i></span>
                }
                else if (bat >= 50.0) {
                    return <span>{bat}% <i className="fa fa-battery-half">&nbsp;</i></span>
                }
                else if (bat >= 25.0) {
                    return <span>{bat}% <i className="fa fa-battery-quarter">&nbsp;</i></span>
                }
                else if (bat <= 25.0) {
                    return <span>{bat}% <i className="fa fa-battery-empty">&nbsp;</i></span>
                } else {
                    return <span>{bat}% <i className="fa fa-battery-empty">&nbsp;</i></span>
                }}
        },
        {
            Header: 'Temp (℉)',
            accessor: 'temperatureInC',
            filterable: false,
            sortable: true,
            default: false,
            Cell: row => {
                return row.original.temperatureInC ?
                    <span>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2})
                        .format(convertCtoF(row.original.temperatureInC))} ℉</span> :
                    null

            }
        },
        {
            Header: 'Location Name',
            accessor: 'locationName',
            filterable: true,
            sortable: true,
            default: true,
        },
        {
            Header:
            <span title={"Number of days the asset has been within the current EZTrack Location Geo-fence."}>
                        Dwell Time </span>,
        accessor: 'dwellTimeStart',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {
                let v = row.original;
                if (v.dwellTimeStart && v.isDomicile === true) {
                    let movingDay = new Date(v.dwellTimeStart);
                    return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1})
                        .format((new Date().getTime() - movingDay.getTime()) / MILLISECONDS_IN_24_HOURS);
                } else {
                    return null;
                }
            }
        },
        {
            Header: 'Last Address',
            accessor: 'latestEventAddress',
            filterable: true,
            sortable: true,
            default: true,
        },
        {
            Header: 'Last City',
            accessor: 'latestEventCity',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {return this.formatCity(row) }
        },
        {
            Header: 'Last State',
            accessor: 'latestEventState',
            filterable: true,
            sortable: true,
            default: true,
        },
        DISTANCE_FROM_DOMICILE(this.props.distanceUnitsOfMeasure),
        {
            Header:
            <span title={"Number of days between the event and when the asset left a domicile geo-fence."}>
                        Excursion Time </span>,
        accessor: 'excrusionTimeStart',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {
                let v = row.original;
                if (v.excrusionTimeStart && v.isDomicile !== true ) {
                    let movingDay = new Date(v.excrusionTimeStart);
                    return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1})
                        .format((new Date().getTime() - movingDay.getTime()) / MILLISECONDS_IN_24_HOURS);
                } else {
                    return null;
                }
            }
        },
        {
            Header:
            <span title={"Number of days between the current time and when the asset last moved."}>
                        Idle Time (Days Since Last Move)</span>,
        accessor: 'dateOfLastMove',
            filterable: false,
            default: true,
            sortable: true,
            Cell: row => {
                let v = row.original;
                if (v.dateOfLastMove) {
                    let movingDay = new Date(v.dateOfLastMove);
                    return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1})
                        .format((new Date().getTime() - movingDay.getTime()) / MILLISECONDS_IN_24_HOURS);
                } else {
                    return null;
                }
            }
        },
        MOVE_FREQUENCY(),
        {
            Header: 'Latitude',
            accessor: 'lastEventLatitude',
            filterable: false,
            sortable: true,
            default: false,
            Cell: row => {return row.original.lastEventLatitude ?
                new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
                .format(row.original.lastEventLatitude) :
                null}
        },
        {
            Header: 'Longitude',
            accessor: 'lastEventLongitude',
            filterable: false,
            sortable: true,
            default: false,
            Cell: row => {return row.original.lastEventLongitude ?
                new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
                .format(row.original.lastEventLongitude) : null}
        },
    ];



    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.trackerTypeFilterRef=React.createRef();
        const parse = JSON.parse(localStorage.getItem(getLocalStorageName("eztrack-assets", props.user)), this.mapFromJson);
        if (parse) {
            this.state = {
                trackerTypeFilter: parse.trackerTypeFilter,
                previousSearchRequest: parse.previousSearchRequest,
                
               
            };
        } else {
            this.state = {
                previousSearchRequest: {},
                
                pages: -1,
              
                itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
                filtered: [{id: 'enabled', value: true}],
                tableData: [],
                trackerTypeFilter: undefined,
            };
        }
        if (props.ezTrackShipmentTrackingEnabled) {
            this.tableColumns.splice(2, 0, {
                Header: "Shipment ID",
                accessor: 'mostRecentEventShipmentName',
                filterable: true,
                sortable: true,
                default: false,
                Cell: row => {return row.original.mostRecentEventShipmentName ?
                    <Link to={"/shipment-profile/"+row.original.mostRecentEventShipmentName}>
                        {row.original.mostRecentEventShipmentName}</Link>
                    : ""}
            })
        }
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        // window.addEventListener('resize', this.calculateTableSize.bind(this));
        // this.calculateTableSize();

    
   
    }
    componentDidUpdate() {
        console.log('Here');
       
    
    }

  

    componentWillUnmount() {
        // window.removeEventListener('resize', this.calculateTableSize.bind(this));
        window.scrollTo(0, 0);
        this.savePageState();
    }
    mapToJson(key, value) {
        let objectToSerialize = this[key];
        if (objectToSerialize instanceof Map) {
            return {
                dataType: 'Map',
                values: Array.from(objectToSerialize.entries()),
            };
        } else {
            return value;
        }
    }
    mapFromJson(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.values);
            }
        }
        return value;
    }
    savePageState() {
        localStorage.setItem(getLocalStorageName("eztrack-assets", this.props.user), JSON.stringify(this.state,this.mapToJson));
    }
    clearFilterCallback() {
        // this.trackerTypeFilterRef.current.value = undefined;
        this.setState({trackerTypeFilter: undefined});
    }
    dataLoadSuccessCallback(data) {
        this.setState({tableData: data.content,
            previousSearchRequest: {page: data.request.page,
                        pageSize: data.request.pageSize,
                        sorted: data.request.sorted,
                        filtered: data.request.filtered
                    }
        });
       
    }
    formatCity(row) {
        if ("clipboard" in navigator) {
            return <div><span title={this.formatLatitudeLongitudePopup(row.original.lastEventLatitude,
                row.original.lastEventLongitude)}>
                    {row.original.latestEventCity}</span></div>
        } else {
            return row.original.latestEventCity || null;
        }
    }
    formatLatitudeLongitudePopup(latitude, longitude) {
        let lat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
            .format(latitude );
        let lng = new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
            .format(longitude );
        return lat + ", " + lng + " (Click to copy)";
    }
    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                if ("clipboard" in navigator && column.id === 'latestEventCity') {
                    let coords = rowInfo.original.lastEventLatitude + ", "
                        + rowInfo.original.lastEventLongitude;
                    navigator.clipboard.writeText(coords);
                    showInfo(coords + " copied to clipboard!", {}, {
                        autoClose: 2000
                    });
                }
                if (column.id === 'asset.assetId') {
                    this.props.history.replace({
                        pathname: "/eztrack-assets/",
                        state: {
                            assetList: this.state.tableData,
                            searchParams: this.state.previousSearchRequest,
                            index: rowInfo.index,
                            trackerTypeFilter: this.state.trackerTypeFilter,
                        }
                    });
                    this.props.history.push({
                        pathname: "/asset-profile/" + rowInfo.original.asset.uuid,
                        state: {
                            assetList: this.state.tableData,
                            searchParams: this.state.previousSearchRequest,
                            index: rowInfo.index,
                            trackerTypeFilter: this.state.trackerTypeFilter,
                        }
                    });
                }
            }
        }
    };
    // calculateTableSize = () => {
    //     let size = getTableSize("power-yard-task-page", "power-yard-tasks-header");
    //     this.setState({ tableSize: { height: size.height, width: size.width } });
    // }

    render() {
        return <>
            <div id="power-yard-task-page">

                
                <div className="column-wrapper text-left">
                <div className="page-header">
                        <h1>EZTrack Assets</h1>
                    </div>
                    <PaginationFilterTable
                        openFiltersCollaps={true}
                        showEditColumns
                        autoRefreshEnabled={false}
                       
                        ref={this.tableRef}
                        user={this.props.user}
                        get={this.props.get}
                        successCallback={this.dataLoadSuccessCallback.bind(this)}
                        baseFilter={[{id: 'enabled', value: true}]}
                        clearFilter={this.clearFilterCallback.bind(this)}
                        
                        
                        getTdProps={this.onRowClick.bind(this)}
                        
                        
                        stateStorage="eztrack-assets"
                        columns={this.tableColumns}
                        onChange={(state) => {
                            this.setState({itemsPerPage: state.itemsPerPage,
                                previousSearchRequest: {page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered
                                }
                            })
                        }}
                        footerButton={
                            <Button color="secondary" onClick={() => this.exportEvents()}><i className="fa fa-file-excel"/> Export to Excel</Button>
                        }
                    >
                       
                    </PaginationFilterTable>
                </div>
            </div>
        </>
    }

    removeFilter(id) {
        let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
        let filtered = previousSearchRequest.filtered;
        if (filtered == null) {
            filtered = [];
        }
        for (let i = 0; i < filtered.length; i++) {
            if (filtered[i].id === id) {
                filtered.splice(i, 1);
                break;
            }
        }
        previousSearchRequest.filtered = filtered;
        this.setState({ previousSearchRequest: previousSearchRequest }, () => { this.savePageState() })
    }
    // updateProperty(id, value) {
    //     let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    //     let filtered = previousSearchRequest.filtered;
    //     let found = false;
    //     if (filtered == null) {
    //         filtered = [];
    //     }
    //     for (const filter of filtered) {
    //         if (filter.id === id) {
    //             filter.value = value;
    //             found = true;
    //         }
    //     }

    //     if (!found) {
    //         if (id === 'start') {
    //             filtered.push({ id: id, value: value, operator: 'GT' })
    //         } else if (id === 'end') {
    //             filtered.push({ id: id, value: value, operator: 'LT' })
    //         } else if (id === "filterDateType") {
    //             filtered.push({ id: id, value: value, operator: 'EQ' })
    //         } else {
    //             filtered.push({ id: id, value: value, operator: 'EQ' })
    //         }

    //     }
    //     previousSearchRequest.filtered = filtered;
    //     this.setState({
    //         previousSearchRequest: previousSearchRequest
    //     }, () => {
    //         this.savePageState();

    //     }
    //     );
    // }
    // getProperty(field) {
    //     let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
    //     let filtered = previousSearchRequest.filtered;
    //     if (filtered) {
    //         for (const filter of filtered) {
    //             if (filter && field) {
    //                 if (filter.id === field) {
    //                     return filter.value;
    //                 }
    //             }
    //         }
    //     }

    // }
    exportEvents() {
        if ( this.state.previousSearchRequest ) {
            requestTrackExcelToAssets( this.state.previousSearchRequest, (res)=>{
                let a = res.message;
                if(a && a.includes("https://e4score-ezcheckin")){
                    window.location.href=a;
                } else {
                    // showError("We are not able to generate the excel file at this time.  Please try again later.");
                    showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
                }
                // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
            
            }, (e)=>{
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

}
export default EZTrackAssets;

