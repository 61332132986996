import * as Actions from "../actions/DispatchAssets";
import {ITEMS_PER_PAGE_DEFAULT} from "../../common/constants";

let initialState = {
    itemsPerPage: ITEMS_PER_PAGE_DEFAULT
}

export default function dispatchAssets(state = initialState, action) {
    switch (action.type) {
        case Actions.SET_ITEMS_PER_PAGE:
            return Object.assign({},state, {itemsPerPage:action.itemsPerPage})
        default: return state;
    }
}
