import React, { Component } from "react";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import TaskTypes from "./TaskTypes";
import SpotTypes from "./SpotType";
import Notice from "./Notice";
import RoleConfig from "./RoleConfig";
import AsyncSelect from "react-select/lib/Async";
import { withRouter } from "react-router";
import { GetFreeFieldsByOrganizationUuid, promiseCompanyByPrefix, savePoweryardCompany, setCompanyAdministrationUuid } from "../../NetworkHelpers";
import connect from "react-redux/es/connect/connect";
import { setUsersCompany, storeCurrentLocation, storeUserCompanyReferenceNumberTypes } from "../../Login/actions/Login";
import { storeItemsPerPage } from "../../assets/actions/Assets";
import AssetStatuses from "./AssetStatuses";
import CancelReasonCodeSettings from "./CancelReasonCodeSettings";
import Spots from "./Spots";
import PoweryardCompanies from "./PoweryardCompanies";
import { showError, showInfo } from "../../MainApp";
import FreeFields from "../components/FreeFields";
import PyApi from "./PyApi";
import FieldsValidity from "./FieldsValidity";
class PowerYardSettings extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: '1',
			hideFilters: true,
			loader: true
		};
	}
	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
				loader: false
			});
		}
	}
	componentDidMount() {
		this.setState({ activeTab: '1', loader: false })
	}

	render() {
		return (
			<div className="admin text-left p-3 form-bk">
				<h1>PowerYard Settings</h1>
				{this.props.superAdmin &&
					<Row style={{ marginBottom: "10px" }}>
						<Col md={12}>
							<h3>Change Company</h3>
						</Col>
						<Col md={12}>
							<AsyncSelect
								cacheOptions
								defaultOptions
								loadOptions={promiseCompanyByPrefix}
								placeholder={this.props.defaultCompany.name}
								onChange={(option) => {
									this.setState({ selectedCompany: option });
									setCompanyAdministrationUuid(option.uuid, (company) => {
										this.props.setUsersCompany(company.content);
									}, () => {

									})
								}}
							/>
						</Col>
					</Row>
				}
				<div className="admin-main">
					<Nav tabs>
						<NavItem>
							<NavLink
								className={this.state.activeTab === '1' ? "active" : ""}
								onClick={() => {
									this.toggle('1');
								}}
							>Task Types</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab === '2' ? "active" : ""}
								onClick={() => {
									this.toggle('2');
								}}
							>Asset Status</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab === '4' ? "active" : ""}
								onClick={() => {
									this.toggle('4');
								}}
							>Spots</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab === '16' ? "active" : ""}
								onClick={() => {
									this.toggle('16');
								}}
							>Spot Types</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab === '3' ? "active" : ""}
								onClick={() => {
									this.toggle('3');
								}}
							>Cancel Reason Codes</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={this.state.activeTab === '6' ? "active" : ""}
								onClick={() => {
									this.toggle('6');
								}}
							>Screen Configurations</NavLink>
						</NavItem>
						{this.props.defaultCompany.enableGate &&
							<NavItem>
								<NavLink
									className={this.state.activeTab === '15' ? "active" : ""}
									onClick={() => {
										this.toggle('15');
									}}
								>
									Gate Custom Fields
								</NavLink>
							</NavItem>
						}
						{this.props.superAdmin &&
							<NavItem>
								<NavLink
									className={this.state.activeTab === '5' ? "active" : ""}
									onClick={() => {
										this.toggle('5');
									}}
								>
									Super User Settings
								</NavLink>
							</NavItem>
						}
						{this.props.superAdmin &&
							<NavItem>
								<NavLink
									className={this.state.activeTab === '12' ? "active" : ""}
									onClick={() => {
										this.toggle('12');
									}}
								>
									PY-API
								</NavLink>
							</NavItem>
						}
						<NavItem>
								<NavLink
									className={this.state.activeTab === '18' ? "active" : ""}
									onClick={() => {
										this.toggle('18');
									}}
								>
									Task Priority Configurations
								</NavLink>
							</NavItem>
							{this.props.superAdmin &&
							<NavItem>
								<NavLink
									className={this.state.activeTab === '19' ? "active" : ""}
									onClick={() => {
										this.toggle('19');
									}}
								>
									Site Notices
								</NavLink>
							</NavItem>
							}
					</Nav>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId='1'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "1" && !this.state.loader ? (
									<TaskTypes
										company={this.props.loggedInUser.company}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						<TabPane tabId='2'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "2" && !this.state.loader ? (
									<AssetStatuses company={this.props.loggedInUser.company}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						<TabPane tabId='4'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "4" && !this.state.loader ? (
									<Spots company={this.props.loggedInUser.company}
										user={this.props.loggedInUser}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						<TabPane tabId='16'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "16" && !this.state.loader ? (
									<SpotTypes
										company={this.props.loggedInUser.company}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						<TabPane tabId='18'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "18" && !this.state.loader ? (
									<RoleConfig  

                                        defaultLocation={this.props.loggedInUser.location}
										company={this.props.loggedInUser.company}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						<TabPane tabId='19'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "19" && !this.state.loader ? (
									<Notice  
                                        user={this.props.loggedInUser}
										currentLocation={this.props.currentLocation}
										company={this.props.loggedInUser.company}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						<TabPane tabId='6'>
							<div className="flex-column flex flex-grow-1">
								{this.state.activeTab === "6" && !this.state.loader ? (
									<FieldsValidity company={this.props.loggedInUser.company}
										user={this.props.loggedInUser}
										setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
										itemsPerPage={this.props.itemsPerPage}
										hideFilters={this.state.hideFilters}
										history={this.props.history}
										onChange={() => {
											this.props.setUsersCompany(this.props.loggedInUser.company);
										}}
									/>) : false}
							</div>
						</TabPane>
						{
							this.props.defaultCompany.enableGate &&
							<TabPane tabId="15">
								<FreeFields user={this.props.loggedInUser} title={"Gate Custom Fields"}
									get={GetFreeFieldsByOrganizationUuid}
									company={this.props.loggedInUser.company}
									// currentLocation={this.props.currentLocation}
									onChange={state => {
										this.props.setUsersCompany(this.props.loggedInUser.company);
									}} />
							</TabPane>
						}
						<TabPane tabId="3">
							{this.state.activeTab === "3" && !this.state.loader ? (
								<CancelReasonCodeSettings />) : false}
						</TabPane>
						<TabPane tabId="5">
							{
								this.state.activeTab === "5" && !this.state.loader ?
									(<PoweryardCompanies
										company={this.props.defaultCompany}
										save={(values, setErrors) => {
											savePoweryardCompany(values, (company) => {
												showInfo("Successfully updated company")
												company = { ...company, ...values };
												this.props.setUsersCompany(company);
											}, () => {
												showError("Failed to updated company");
											})
										}}
									/>) : false}
						</TabPane>
						<TabPane tabId="12">
							{this.state.activeTab === "12" && !this.state.loader ? (
								<PyApi
									setCurrentLocation={this.props.setCurrentLocation}
									loggedInUser={this.props.loggedInUser}
									onChange={state => { this.props.setUsersCompany(this.props.loggedInUser.company); }}
									setItemsPerPage={(itemsPerPage, callback) => this.props.setItemsPerPage(itemsPerPage, callback)}
									itemsPerPage={this.props.itemsPerPage} hideFilters={this.state.hideFilters}
									history={this.props.history}
									currentLocation={this.props.currentLocation}
									company={this.props.loggedInUser.company} />) : false}
						</TabPane>
					</TabContent>
				</div>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		loggedInUser: state.login.user,
		itemsPerPage: state.assets.itemsPerPage
	}
}
function mapDispatchToProps(dispatch) {
	return ({
		setUsersCompany: function (company) {
			dispatch(setUsersCompany(company));
		},
		setCurrentLocation: function (location) {
			dispatch(storeCurrentLocation(location));
		},
		setUsersReferenceNumberTypes: function (referenceNumberTypes) {
			dispatch(storeUserCompanyReferenceNumberTypes(referenceNumberTypes));
		},
		setItemsPerPage: function (itemsPerPage) {
			dispatch(storeItemsPerPage(itemsPerPage));
		}
	});
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PowerYardSettings));