import React, { Component } from "react";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import {
  promiseUserLocationSearch,
  promiseZoneByPrefix,
} from "../../NetworkHelpers";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import FormAsyncSelectField from "../../common/FormAsyncSelectField";
import { fieldnamesProgress, fieldnamesMoveTask } from "../../common/poweryard/FieldNames";

export const ScreenCodOptions = [
  { value: "task-in-progress", label: 'Task In Progress' },
  { value: "moved-task", label: 'Move Me Task Create' },


];
class LocFieldValidityEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      fieldSettingUnderEdit: {
        screenCode: "",
        mandatoryFields: [],
        locationDto: [],
      },
      locations: [],
      errors: {},
      touched: {},
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(
      "locationDto here",
      this.state.fieldSettingUnderEdit.locationDto,
      this.state.fieldSettingUnderEdit.screenCode,
      this.state.fieldSettingUnderEdit.mandatoryFields
    );
    if (this.props.fieldSettingUnderEdit !== prevProps.fieldSettingUnderEdit) {
      promiseUserLocationSearch("").then((res) => {
        this.setState({
          locations: res,
          locationNames: res.map((it) => it.label),
        });
      });
      this.setState({
        fieldSettingUnderEdit: this.props.fieldSettingUnderEdit,
        modalIsOpen: this.props.showModal,
      });
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  cancelEdit() {
    this.setState({
      modalIsOpen: false,
      errors: {},
      touched: {},
    });
    this.props.cancel();
  }
  onSubmit() {
    this.updateSpinner("Saving...");
    this.props.save &&
      this.props.save(
        this.state.fieldSettingUnderEdit,
        () => {
          this.setState({ modalIsOpen: false, touched: {} });
        },
        () => {
          this.setState({ modalIsOpen: true });
        }
      );
  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    let validationRules = {
      locationDto: Yup.object().required("Location is required.").nullable(),
      screenCode: Yup.string().required("Screen Code is required."),
      mandatoryFields: Yup.array().required("Mandatory Fields / Pre-Populated fields is required."),
    };
    return Yup.object().shape(validationRules);
  }
  render() {
    return (
      <Modal isOpen={this.state.modalIsOpen} size={"lg"}>
        <Loader show={this.props.loaded} message={this.spinner}>
          <ModalHeader>
            {this.state.fieldSettingUnderEdit.isNew
              ? "New Screen configure"
              : "Edit Screen configure"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                let errors = this.validate(this.validationSchema.bind(this))(
                  this.state.fieldSettingUnderEdit
                );
                let errorsIsEmpty =
                  Object.keys(errors).length === 0 &&
                  errors.constructor === Object;
                if (!errorsIsEmpty || this.state.errors.screenCode) {
                  let t = { ...this.state.touched };
                  t.locationDto = true;
                  t.screenCode = true;
                  t.mandatoryFields = true;
                  this.setState({ touched: t, errors: errors });
                  e.preventDefault();
                  return;
                }
                this.onSubmit();
                e.preventDefault();
              }}
            >
              <Card className="simple-card">
                <CardHeader>Location Bases Fields</CardHeader>
                <CardBody>
                  <div>
                    {/* <FormAsyncSelectField
                                        defaultOptions
                                        isMulti = {false}
                                        fieldname="locationDto"
                                        label="Locations"
                                        placeholder="Select a Location"
                                        icon="fa-building"
                                        minimumInput={0}
                                        loadOptions={promiseUserLocationSearch}
                                        values={this.state.fieldSettingUnderEdit ? 
                                            this.state.fieldSettingUnderEdit.locationDto?
                                            this.state.fieldSettingUnderEdit.locationDto.value : null : null}
                                        required={true}
                                        onBlur={() => {
                                            let errors = this.validate(this.validationSchema.bind(this))(this.state.fieldSettingUnderEdit);
                                            let touched = {...this.state.touched};
                                            touched.locationDto = true;
                                            this.setState({errors: errors, touched: touched})
                                        }}
                                        error={this.state.errors.locationDto}
                                        touched={this.state.touched.locationDto}
                                        onChange={(loc) => {
                                            let s = {...this.state.fieldSettingUnderEdit};
                                            s.locationDto = loc;
                                            this.setState({fieldSettingUnderEdit: s});
                                        }}
                                    /> */}
                    <FFFormSelectField
                      fieldname="locationDto"
                      label="Location"
                      placeholder="Select Location"
                      icon="fa-parking"
                      value={
                        this.state.fieldSettingUnderEdit.locationDto.value
                          ? this.state.fieldSettingUnderEdit.locationDto
                          : null
                      }
                      required={true}
                      options={this.state.locations}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.fieldSettingUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.locationDto = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.locationDto}
                      touched={this.state.touched.locationDto}
                      onChange={(v) => {
                        let s = { ...this.state.fieldSettingUnderEdit };
                        s.locationDto = v;
                        this.setState({ fieldSettingUnderEdit: s });
                      }}
                    />
                    <FFFormSelectField
                      fieldname="screenCode"
                      label="Screen Code"
                      icon="fa-parking"
                      placeholder="Select Screen Code"
                      value={
                        ((ScreenCodOptions.find(option => option.value === this.state.fieldSettingUnderEdit.screenCode) || {}).label) || ''
                      }

                      required={true}
                      // options={[ "new-task","edit-task","task-in-progress"]}
                      options={ScreenCodOptions}//moved-task
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.fieldSettingUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.screenCode = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.screenCode}
                      touched={this.state.touched.screenCode}
                      onChange={(v) => {
                        let s = { ...this.state.fieldSettingUnderEdit };
                        s.screenCode = v.value;
                        this.setState({ fieldSettingUnderEdit: s });
                      }}
                    />
                    <FFFormSelectField
                      fieldname="mandatoryFields"
                      label=
                      {
                        this.state.fieldSettingUnderEdit.screenCode === "moved-task"
                          ? "Pre-populate (From Current Task)"
                          : this.state.fieldSettingUnderEdit.screenCode === "task-in-progress" ?
                            "Mandatory Fields" : "Mandatory Fields / Auto Filled"
                      }
                      icon="fa-parking"
                      isDisabled={!this.state.fieldSettingUnderEdit.screenCode}
                      placeholder=
                      {
                        this.state.fieldSettingUnderEdit.screenCode === "moved-task"
                          ? "Select Pre-populate Fields"
                          : this.state.fieldSettingUnderEdit.screenCode === "task-in-progress" ?
                            "Select Mandatory Fields" : "Select Mandatory Fields / Auto Filled"
                      }
                      value={
                        this.state.fieldSettingUnderEdit
                          ? this.state.fieldSettingUnderEdit.screenCode === "moved-task"
                            ? this.state.fieldSettingUnderEdit.mandatoryFields
                              .filter(
                                (v) =>
                                  v.value !== "type" &&
                                  v.value !== "moveFrom" &&
                                  v.value !== "carrier" &&
                                  v.value !== "assetId" &&
                                  v.value !== "moveTo"
                              )
                              .map((v) => ({ value: v.value, label: v.label }))
                            : this.state.fieldSettingUnderEdit.mandatoryFields.map((v) => ({
                              value: v.value,
                              label: v.label,
                            }))
                          : null
                      }

                      required={true}
                      isMulti={true}
                      options={this.state.fieldSettingUnderEdit.screenCode === "moved-task"
                        ? fieldnamesMoveTask : fieldnamesProgress}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.fieldSettingUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.mandatoryFields = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.mandatoryFields}
                      touched={this.state.touched.mandatoryFields}
                      onChange={(fields) => {
                        let s = { ...this.state.fieldSettingUnderEdit };
                        if (s.screenCode === "moved-task") {
                          s.mandatoryFields = fields.filter(
                            (field) =>
                              field.value !== "type" &&
                              field.value !== "moveFrom" &&
                              field.value !== "moveTo"
                          );
                        } else {
                          s.mandatoryFields = fields;
                        } this.setState({ fieldSettingUnderEdit: s });
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="mb-3 text-right">
                <Button color="danger" onClick={() => this.cancelEdit()}>
                  Cancel
                </Button>{" "}
                &nbsp;
                <Button color="primary" type="submit">
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Loader>
      </Modal>
    );
  }
}
export default LocFieldValidityEdit;
