import {Component} from "react";
import React from "react";
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

export class FFAsyncCreatableSelect extends Component {
    render(){
        return <>
            <AsyncCreatableSelect
            name={this.props.fieldname}
            isMulti="true"
            placeholder={this.props.placeholder}
            valid={!this.props.error}
            invalid={this.props.touched && !!this.props.error}
            onChange={(v) => {
                this.props.onChange && this.props.onChange(v)
            }}
            onBlur={() => this.props.onBlur()}
            className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
            // classNamePrefix="select"
            style={{width: "700px"}}
            isClearable={true}
            loadOptions={this.props.promise}
            value={this.props.value}
        />
        </>
    }
}