import React, {Component} from "react";
import Moment from "react-moment";

var moment = require('moment-timezone');
var Timezones = require('../../common/timezones-list.json');


class MomentDisplayTracking extends Component {
    render() {
        var zone = "";
        var timezone = "";
     
        if(!this.props.timezone){
             zone = moment.tz.guess();
             timezone =  moment.tz(zone).zoneAbbr();
        }
        if(this.props.timezone){
            zone = this.props.timezone;
            let obj;
            for(var i = 0; i < Timezones.length; i++){
               let a = Timezones[i].utc.find(it=> it === zone);
               if(a){
                   obj = Timezones[i];
                   break;
               }
            }
            if(obj && obj.abbr){
                timezone =  obj.abbr;
            }
        }

        return (
            <span>
                <span className="no-wrap">
                    <Moment format="MM/DD/YYYY">{this.props.date}</Moment>
                </span>
                {' '}
                {!this.props.noTime &&
                <span className="no-wrap">
                    <Moment format="h:mm a" tz={this.getTz()}>
                        {this.props.date}
                    </Moment>
                    {" " + timezone}
                </span>
                }
            </span>
        );


    }

    getTz() {
        if(this.props.noOffset) return null;
        return this.props.timezone ? this.props.timezone : moment.tz.guess();
    }
}

export default MomentDisplayTracking;