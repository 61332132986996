import React, {Component} from 'react';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {} from "./FFFormStateSelectInputGroup";
import {FFFormStateSelectInputGroup} from "./FFFormStateSelectInputGroup";
import {downloadStates} from "../../NetworkHelpers";
import {showError} from "../../MainApp";

class FFFormSelectStateField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            states: []
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        downloadStates(this.props.selectedCountry ? this.props.selectedCountry : "United States", (json) => {
            this.setStates(json)
        }, (error) => {
            showError(error)
         })
    }

    setStates(json) {
        let stateOptions = json.states.map((s) => {
            return {value: s.name, label: s.name}
        });
        this.setState({
            states: json.states,
            stateOptions: stateOptions
        });
    }
    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}/>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <FFFormStateSelectInputGroup
                            icon={this.icon()}
                            name={this.props.fieldname}
                            placeholder={this.props.placeholder}
                            errors={this.props.errors}
                            touched={this.props.touched}
                            onChange={(v) => this.props.onChange && this.props.onChange(v ? v.value : "")}
                            onBlur={() => this.props.onBlur && this.props.onBlur()}
                            value={this.props.value}
                            disabled={this.props.disabled}
                            selectedCountry={this.props.selectedCountry}
                        />
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FFFormSelectStateField;