import React, {Component} from "react";
import FormSelectCreatableField from "../../common/FormSelectCreatableField";
import {promiseCarriersByPrefix} from "../../NetworkHelpers";
import FormDateTimeField from "../../common/FormDateTimeField";
import FormTextField from "../../common/FormTextField";
import * as PropTypes from "prop-types";

export class ArrivalInformation extends Component {
    render() {
        return <div>
            <FormSelectCreatableField
                label="Carrier *"
                defaultOptions
                placeholder="Enter Value"
                fieldname="carrier"
                errors={this.props.errors}
                handleBlur={this.props.handleBlur}
                handleChange={this.props.handleChange}
                touched={this.props.touched}
                values={this.props.values}
                setFieldTouched={this.props.fieldTouched}
                setFieldValue={this.props.fieldValue}
                getOptionLabel={this.props.optionLabel}
                getOptionValue={this.props.optionLabel}
                icon="fa-building"
                loadOptions={promiseCarriersByPrefix}
            />
            <FormSelectCreatableField
                label="Broker"
                defaultOptions
                placeholder="Enter Value"
                fieldname="broker"
                errors={this.props.errors}
                handleBlur={this.props.handleBlur}
                handleChange={this.props.handleChange}
                touched={this.props.touched}
                values={this.props.values}
                setFieldTouched={this.props.fieldTouched}
                setFieldValue={this.props.fieldValue}
                icon="fa-building"
                getOptionLabel={this.props.optionLabel}
                getOptionValue={this.props.optionLabel}
                loadOptions={promiseCarriersByPrefix}
            />

            <FormDateTimeField
                label={"Appointment Time"}
                timezone={this.props.timezone}
                placeholder="Enter Value"
                onChange={this.props.onChange}
                value={this.props.value}
                icon="fa-calendar"
                fieldname="appointment"
                errors={this.props.errors}
                handleBlur={this.props.handleBlur}
                handleChange={this.props.handleChange}
                touched={this.props.touched}
                values={this.props.values}
                setFieldTouched={this.props.fieldTouched}
                setFieldValue={this.props.fieldValue}
            />

            <FormTextField
                numbersOnly="true"
                label="Minutes Waiting"
                placeholder="Enter Value"
                fieldname="minutesWaiting"
                errors={this.props.errors}
                handleBlur={this.props.handleBlur}
                handleChange={this.props.handleChange}
                touched={this.props.touched}
                values={this.props.values}
                icon="fa-calendar"
            />

        </div>;
    }
}

ArrivalInformation.propTypes = {
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
    timezone: PropTypes.any
};