import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import FormCheckboxField from "../../common/FormCheckboxField";
import { Button, Form, FormGroup, Label, Table } from "reactstrap";
import { Formik } from "formik";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import FormSelectField from "../../common/FormSelectField";
import FormTextField from "../../common/FormTextField";
import { showError, showInfo } from "../../MainApp";
import { generateApiSecret, saveTrackAssuredIntegrationSettings } from "../../NetworkHelpers";
import Config from "../../Config";
import FFFormTextField from "../../common/formik-free/FFFormTextField";

class FormRow extends Component<{ label: any, value: any }> {
  render() {
    return <FormGroup>
      <Row>
        <Col lg="2" md="3" sm="4">
          <Label>{this.props.label}</Label>
        </Col>
        <Col lg="10" md="9" sm="8">
          {this.props.value}
        </Col>
      </Row>
    </FormGroup>;
  }
}

class WebhookHeader extends Component {
  render() {
    return <>
      <Row>
        <Col md={5}>
          <FFFormTextField
            label="Header"
            placeholder=""
            fieldname="header"
            onChange={(newValue) => {
              let header = this.props.header;
              header.header = newValue;
              this.props.onChange && this.props.onChange(header);
            }}
            value={this.props.header.header}
          />
        </Col>
        <Col md={5}>
          <FFFormTextField
            label="Content"
            placeholder=""
            fieldname="value"
            onChange={(newValue) => {
              let header = this.props.header;
              header.value = newValue;
              this.props.onChange && this.props.onChange(header);
            }}
            value={this.props.header.value}
          />
        </Col>
      </Row>
    </>
  }
}

class WebhookHeaders extends Component<{}> {
  render() {
    return <div>
      {this.props.headers && this.props.headers.map((index) => {
        return <>
          <WebhookHeader header={index} onChange={
            (header) => {
              index = header;
              this.props.onChange && this.props.onChange(this.props.headers);
            }
          } />
        </>;
      })}
      <Row>
        <Col>
          <Button color="secondary" className="sm" onClick={() => this.props.onAddHeader()}><i className="fa fa-plus" /> Add Header</Button>
        </Col>
      </Row>
    </div>;
  }
}

class WebhookSettings extends Component {
  render() {
    return <>
      <div>
        <h4>Event Webhooks</h4>
        <FormRow label={"Documentation"} value={<a href={"/docs/index.html"} target="_blank" >{window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/docs/index.html"}</a>} />
        <FFFormTextField
          label="Web Hook URL"
          icon="fa-browser"
          fieldname="url"
          value={this.props.settings.url}
          // labelClassName={"text-center"}
          onChange={(value) => {
            this.props.settings.url = value;
            this.props.onChange(this.props.settings)
          }}
          labelStyle={{ paddingTop: "5px" }}
        />
        <WebhookHeaders
          headers={this.props.settings.headers}
          onChange={(headers) => {
            let settings = this.props.settings;
            settings.headers = headers;
            this.props.onChange && this.props.onChange(settings)
          }}
          onAddHeader={() => {
            if (!this.props.settings.headers) this.props.settings.headers = [];
            this.props.settings.headers.push({});
            this.props.onChange && this.props.onChange(this.props.settings)
            // setFieldValue("webhookSettings", values.webhookSettings);
          }}
        />
      </div>
    </>
  }
}

export default class TrackAssuredSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      ediEnabled: false
    };
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    }
  }

  validationSchema(values) {
    let validationRules = {};
    if (values['ediEnabled'] || values['enabled']) {
      validationRules = {
        shortCodeType: Yup.string().required('Short code is required.').nullable(),
      };

      if (values['ediEnabled']) {
        validationRules.ediHost = Yup.string().required('Hostname is required.').nullable();
        validationRules.ediUser = Yup.string().required('User name is required.').nullable();
        validationRules.ediPassword = Yup.string().required('Password is required.').nullable();
      }
    }
    return Yup.object().shape(validationRules)
  }

  onSubmit(values, { setErrors }) {
    saveTrackAssuredIntegrationSettings(this.props.company.uuid, values, (settings) => {
      showInfo("Successfully updated settings");
      this.props.onChange(settings);
    }, (error) => {
      showError("Failed to update settings");
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col>
            <h4>Integration Settings</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Formik
              enableReinitialize={true}
              initialValues={
                this.getInitialValues()
              }
              validate={this.validate(this.validationSchema.bind(this))}
              onSubmit={(v, o) => {
                this.onSubmit(v, o)
              }}
            >{
                ({
                  values,
                  errors,
                  touched,
                  status,
                  dirty,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  handleReset,
                  setTouched,
                  setFieldValue,
                  setFieldTouched
                }) => (

                  <Form
                    autoComplete="off"
                    onSubmit={(v) => {
                      return handleSubmit(v)
                    }}>
                    <FormCheckboxField
                      label="Track Assured Integration"
                      icon="fa-power-off"
                      fieldname="enabled"
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <FormCheckboxField
                      label="EDI Integration"
                      icon="fa-power-off"
                      fieldname="ediEnabled"
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <FormSelectField
                      label="Short Code to Use"
                      required={false}
                      placeholder="Enter Value"
                      icon="fa-cog"
                      fieldname="shortCodeType"
                      name="shortCodeType"
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      values={values}
                      maxMenuHeight={150}
                      options={["Carrier", "Broker", "Override"]}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      noSearch={true}
                      disabled={false}
                      isSearchable={false}
                    />
                    {values['shortCodeType'] === "Override" &&
                      <FormTextField
                        label="Override"
                        required={true}
                        placeholder="Enter Value"
                        icon="fa-truck"
                        fieldname="override"
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched}
                        values={values}
                      />
                    }
                    {values['ediEnabled'] &&
                      <>
                        <FormTextField
                          label="EDI Host"
                          icon="fa-server"
                          fieldname="ediHost"
                          required={true}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          // labelClassName={"text-center"}
                          labelStyle={{ paddingTop: "5px" }}
                        />
                        <FormTextField
                          label="EDI User"
                          icon="fa-user"
                          fieldname="ediUser"
                          errors={errors}
                          required={true}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          // labelClassName={"text-center"}
                          labelStyle={{ paddingTop: "5px" }}
                        />
                        <FormTextField
                          password
                          label="EDI Password"
                          icon="fa-key"
                          fieldname="ediPassword"
                          errors={errors}
                          required={true}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          // labelClassName={"text-center"}
                          labelStyle={{ paddingTop: "5px" }}
                        /></>}
                    {this.props.company.enableTailwind &&
                      <div>
                        <h4>Tailwind Integration</h4>
                        <FormTextField
                          label="Token"
                          icon="fa-server"
                          fieldname="tailwindToken"
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          // labelClassName={"text-center"}
                          labelStyle={{ paddingTop: "5px" }}
                        />
                      </div>
                    }
                    <WebhookSettings settings={values.webhookSettings} onChange={(settings) => {
                      setFieldValue("webhookSettings", settings);
                      setFieldTouched("webhookSettings", true);
                    }} />

                    <div className="mb-3 text-right">
                      <Button color="primary" type="submit">Save</Button>
                    </div>
                    {this.props.company.apiEnabled &&
                      <div>
                        <h4>EZCheck-In API</h4>
                        <FormRow label={"Endpoint"} value={Config.primaryApiServer} />
                        <FormRow label={"Documentation"} value={<a href={Config.primaryApiServer + "/swagger-ui.html"} target="_blank" >{Config.primaryApiServer + "/swagger-ui.html"}</a>} />
                        <FormRow label={"Client Id"} value={this.props.company.clientId} />
                        <FormRow label={"Client Secrets"} value={<>
                          <Table>
                            <thead>
                              <tr>
                                <th>Secret</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.company && this.props.company.authentications && this.props.company.authentications.map((listValue, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{listValue.secretSubString}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>} />
                        <FormCheckboxField
                          label="Invalidate Previously Created Client Secret Values"
                          icon="fa-power-off"
                          fieldname="invalidateSecrets"
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                        <FormRow label={"New Secret"} value={values.newApiSecret} />
                        <div className="mb-3 text-left">
                          <Button color="secondary" onClick={() => {
                            generateApiSecret(this.props.company.uuid, { disablePrevious: values.invalidateSecrets }, (company) => {
                              this.props.onChange(company);
                            }, () => {
                              showError("Failed to generate settings");
                            })
                          }}>{!this.props.company.clientId ? "Generate Client Id And Secret" : "Generate Client Secret"}</Button>
                        </div>
                      </div>}

                  </Form>
                )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }

  getInitialValues() {
    let settings = this.props.company.integrationSettings ?
      {
        ...this.props.company.integrationSettings,
        ediHost: this.props.company.ediHost,
        ediUser: this.props.company.ediUser,
        ediPassword: this.props.company.ediPassword,
        clientId: this.props.company.clientId
      } :
      {
        shortCodeType: null,
        ediHost: this.props.company.ediHost,
        ediUser: this.props.company.ediUser,
        ediPassword: this.props.company.ediPassword,
        clientId: this.props.company.clientId
      };

    if (this.props.company.tailwindSettings) {
      settings = { ...settings, ...this.props.company.tailwindSettings };
    }

    if (this.props.company.webhookSettings) {
      settings = { ...settings, webhookSettings: this.props.company.webhookSettings };
    } else {
      settings = { ...settings, webhookSettings: { url: null, headers: [{ header: null, value: null }] } };
    }

    return settings;
  }
}
TrackAssuredSettings.propTypes = {
  company: PropTypes.any
};
