import {Component} from "react";
import {Col, Tooltip} from "reactstrap";
import React from "react";
import * as uuid from "uuid";
import JSONPretty from "react-json-pretty";
import Config from "../Config";

export class Debug extends Component {
    id = "ID_"+uuid.v4();

    constructor(props){
        super(props);
        this.state = {
            open: false
        }
    }

    toggle(){
        this.setState({open: this.state && !this.state.open})
    }


    render(){
        return <>
            {this.props.children}
            {Config.debug && (this.state.open || this.props.open) && <>
                <JSONPretty data={this.props.data} onClick={()=> {this.toggle()}}/>
            </>}
            {Config.debug && !this.state.open && <>
                <i id={this.id} className="fas fa-bug" onClick={()=> {this.toggle()}}></i>
            </>}
        </>
    }
}