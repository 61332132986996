import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import AsyncSelect from "react-select/lib/Async";
import {promiseUserLocationSearch} from "../../NetworkHelpers";


class FFFormSelectLocationField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locationList: []
        }
    }

    icon() {
        if (this.props.icon) {
            return <i className={"fa " + this.props.icon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? <span className="text-danger">*</span> : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <AsyncSelect
                                name={this.props.fieldname}
                                fieldname={this.props.fieldname}
                                placeholder={this.props.placeholder}
                                touched={this.props.touched}
                                onChange={(v) => {
                                    if (this.props.onChange) {
                                        this.props.onChange(v);
                                    }
                                }}
                                className={"basic-single short-select select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
                                // classNamePrefix="select"
                                defaultOptions
                                maxMenuHeight={this.props.maxMenuHeight || 500}
                                loadOptions={(inputValue) => {
                                    return new Promise(resolve => {
                                        promiseUserLocationSearch(inputValue).then(
                                            (data) => {
                                               
                                                // data = data.filter(it=> it.name === "Alfatah model Town");
                                                if (this.props.optionsFilter) {
                                                    data = this.props.optionsFilter(data);
                                                }
                                                if (this.props.customFirstOption) {
                                                    data = [this.props.customFirstOption].concat(data);
                                                }
                                                data = data.filter(it=> it.isDomicile === true);
                                                this.setState({locationList: data});
                                                resolve(data);
                                            }
                                        );
                                    });
                                }}
                                onBlur={() => this.props.onBlur && this.props.onBlur()}
                                value={this.props.value}
                                isDisabled={this.props.disabled}
                                ref={this.props.selectRef}
                            />
                            <FormFeedback>{this.props.error}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FFFormSelectLocationField;