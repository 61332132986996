import * as React from "react";
import {
    Col, FormFeedback,
    FormGroup, InputGroup,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import moment from "moment-timezone";
import {FFDateTimePicker} from "./date-time-picker/FFDateTimePicker";
var Timezones = require('../timezones-list.json');

export default class FFFormDateTimeField extends React.Component {
    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return this.props.iconText;
        }
        return " ";
    }

    timezoneShortCode() {
        if(!this.props.value){
            return moment(new Date()).tz(this.props.timezone ? this.props.timezone : moment.tz.guess()).format("z");
        }if ( this.props.timezone ) {
            let obj;
            for(var i = 0; i < Timezones.length; i++){
               let a = Timezones[i].utc.find(it=> it === this.props.timezone);
               if(a){
                   obj = Timezones[i];
                   break;
               }
            }
            if(obj && obj.abbr){
                return obj.abbr;
            } else {
                return moment(this.props.value).tz(this.props.timezone).format("z"); 
            }
        } else {
            return moment(this.props.value).tz(moment.tz.guess()).format("z");
        }
    }


    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} ({this.timezoneShortCode()})
                            {this.props.label && this.props.required ? '*' : ''}</Label>
                        {this.props.helpMessage &&
                        <div>
                            <i style={{
                                position: "absolute",
                                right: 0,
                                top: "5px",
                                color:"grey"
                            }}
                               className={"fa fa-question-circle"}
                               id={this.state.id}
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
                                {this.props.helpMessage}
                            </Tooltip>
                        </div>
                        }
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <FFDateTimePicker
                            placeholder={"MM/DD/YYYY"}
                            fieldName={this.props.fieldname}
                            disabled={this.props.isDisabled}
                            value={this.props.value}
                            timezone={this.props.timezone ? this.props.timezone : moment.tz.guess()}
                            onChange={(date) => this.props.onChange && this.props.onChange(moment(date).toDate())}
                            error={this.props.error}
                            touched={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}