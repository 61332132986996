import React, { Component } from "react";
import * as Yup from "yup";
import Modal from "reactstrap/es/Modal";
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import {getDataByArrivalID} from "../../NetworkHelpers";
import FFFormTextField from "../../common/formik-free/FFFormTextField";

import FFFormCheckboxField from "../formik-free/FFFormCheckboxField";


class ShowCustomList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedArrival: {
        firstname: "",
        mobile: ""
      },
      updatePopupState: false,
      errors: {},
        loaded: false,
        touched: {},


    };
  }
 

  componentWillUpdate(previousProps, prevState, snapshot) {

    if (previousProps.open && this.state.updatePopupState === false && prevState.updatePopupState !== true) {

      this.setState({
        selectedArrival: previousProps.selectedArrival,
        updatePopupState: true
      });
      this.fetchRecord(previousProps.selectedArrival.arrivalID);

    }



  }
  fetchRecord(arrivalID){
    getDataByArrivalID(arrivalID, (res) => {
      if (res.statusCode === "200") {
        this.setState({
          selectedArrival: res.data
        });
      }
     
    },
      (err) => {
        showError(err);
      }
    );
  }



  render() {
    return (
      <div>
     
<Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1>Custom Field List</h1>
        </ModalHeader>
        <ModalBody>
    
                   <Card className="simple-card">
                    <CardBody>
                      <div>
                      {this.state.selectedArrival && this.state.selectedArrival.customFieldList && this.state.selectedArrival.customFieldList.map((cfl,index)=>{
                              if(cfl.type == "string"){
                                return (
                                  <FFFormTextField
                                      label={cfl.name}
                                      placeholder=""
                                      icon="fa-tag"
                                      key={ index }
                                      value={
                                        cfl.value
                                      }
                                      required={false}
                                      disabled={true}
                                      onChange={(v) => {
                                        let s = { ...this.state.selectedArrival };
                                        s.customFieldList[index].value = v;
                                        this.setState({ selectedArrival: s });
                                      }}
                                  />
                                )
                              }
                              if(cfl.type == "bool"){
                                return (
                                  <FFFormCheckboxField                                
                                      label={cfl.name}
                                      placeholder=""
                                      disabled={true}
                                      icon="fa-power-off"
                                      value={
                                        cfl.value
                                      }
                                      onChange={(v) => {
                                        let s = { ...this.state.selectedArrival };
                                        s.customFieldList[index].value = v;
                                        this.setState({ selectedArrival: s });
                                      }}
                                    />
                                )
                              }
                              if(cfl.type == "int"){
                                return (
                                  <FFFormTextField
                                      label={cfl.name}
                                      placeholder=""
                                      numbersOnly={true}
                                      icon="fa-tag"
                                      disabled={true}
                                      key={ index }
                                      value={
                                        cfl.value
                                      }
                                      required={false}
                                    
                                      onChange={(v) => {
                                        let s = { ...this.state.selectedArrival };
                                        s.customFieldList[index].value = v;
                                        this.setState({ selectedArrival: s });
                                      }}
                                  />
                                )
                              }
                              if(cfl.type == "datetime"){
                                return (
                                  <FormGroup>
                                  <Row>
                                    <Col lg="2" md="3" sm="4">
                                      <Label>
                                        {cfl.name+" (" +
                                          this.timezoneShortCode(
                                            this.props.currentLocation &&
                                            this.props.currentLocation.timezone,
                                            new Date()
                                          ) +
                                          ")"}
                                      </Label>
                                    </Col>
                                    <Col className={"task-form"} lg="10" md="9" sm="8">
                                      <DatePicker
                                        placeholderText={"MM/DD/YYYY HH:MM AP"}
                                        selected={cfl.value}
                                        readOnly={true}
                                        onChange={(v) => {
                                          let s = { ...this.state.selectedArrival };
                                          s.customFieldList[index].value = v;
                                          this.setState({ selectedArrival: s });
                                        }}
                                        timeInputLabel="Time:"
                                        dateFormat="MM/dd/yyyy h:mm a"
                                        showTimeInput
                                        isClearable={false}
                                        shouldCloseOnSelect={false}
                                      />
                                      
                                    </Col>
                                  </Row>
                                </FormGroup>
                                )
                              }
                              
                            })}
                      </div>
                    </CardBody>
                  </Card>
                 
        </ModalBody>
        <ModalFooter>
        <div className="mb-3 text-right">
                  
                  <Button
                    color="danger"
                    type="button"
                    onClick={() => this.closePopup()}
                  >
                    Close
                  </Button>
                
                </div>
        </ModalFooter>
      </Modal>
      </div>
      
    )
  }
  closePopup() {
    this.setState({updatePopupState : false});
    return this.props.onClose && this.props.onClose();

  }
  timezoneShortCode(timezone, inp) {
    if (!inp) inp = new Date();
    if (timezone) {
      return moment(inp).tz(timezone).format("z");
    } else {
      return moment(inp).tz(moment.tz.guess()).format("z");
    }
  }
 
 
}
export default ShowCustomList;