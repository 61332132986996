import React, {Component} from "react";

export class GPSCoordinatedWrapper extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount(){
        window.scrollTo(0,0);
        this.startWatchLocation();
    }

    startWatchLocation() {
        if (navigator.geolocation) {
            let positionOptions = {enableHighAccuracy: true, timeout: 5000, maximumAge: 60 * 60 * 1000};
            navigator.geolocation.getCurrentPosition((position) => {
                let results = {};
                results.latitude = position.coords.latitude;
                results.longitude = position.coords.longitude;
                this.setState({
                    position: results,
                    locationError: null,
                    loadingLocation: false
                });
            }, (error) => {
                this.setState({
                    position: null,
                    locationError: true,
                    locationPermissionsDenied: error.code === error.PERMISSION_DENIED
                });
            }, positionOptions);
        } else {
            this.setState({locationError: "GEO Location Services Not Available", loadingLocation: false});
        }
    }

    render() {
        return React.cloneElement(this.props.children, {...this.state})
    }
}