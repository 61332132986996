import React, {Component} from 'react';
import FormTextField from "../../common/FormTextField";
import FormDateTimeField from "../../common/FormDateTimeField";
import {Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Row} from "reactstrap";
import {
    postDeparture, postUserDefaultLocation,
    promiseCarriersByPrefix,
    promiseDriversByPhonePrefix, promiseTractorByCarrierAndIdPrefix,
    promiseTractorByIdPrefix, promiseTrailerByCarrierAndIdPrefix, promiseTrailerByIdPrefix
} from "../../NetworkHelpers";
import {showError} from "../../MainApp";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {Formik} from "formik";
import * as Yup from "yup";
import FormSelectStateField from "../../common/FormSelectStateField";
import FormRadioField from "../../common/FormRadioField"
import FormSelectCreatableField from "../../common/FormSelectCreatableField";
import {getErrorsFromValidationError,padNumber} from "../../common/utilities";
import FormTextAreaField from "../../common/FormTextAreaField";
import $ from "jquery";
import FormSelectField from "../../common/FormSelectField";
import {ROLE_ADMIN, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import FormPhoneSelectCreatableField from "../../common/FormPhoneSelectCreatableField";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import {ChangeLocationFormField, CurrentLocation} from "../../Dashboard/Main/components/CurrentLocation"
import {storeCurrentLocation} from "../../Login/actions/Login";


class Departure extends Component {


    constructor(props) {
        super(props);
        this.displayInField = false;
        this.displayMinutesWaiting = false;
        this.state = {
            date: new Date(),
            arrival: false,
            carrierOptions:[],
            formValues: {
                shipmentNumber:"",
                date: new Date(),
                appointment: new Date(),
                carrier:"",
                broker:"",
                tractorNumber:"",
                tractorLicense:"",
                tractorLicenseState:"",
                trailerNumber:"",
                trailerLicense:"",
                trailerLicenseState:"",
                trailerTemperature:"",
                driverCdl:"",
                driverFirstname:"",
                driverLastname:"",
                driverState:"",
                driverCell:"",
                driverHasSmartPhone: false,
                driverCountryCode: "US",
                minutesWaiting: "0"
            },
            openSections: {}
        };
        this.setupStateWithData(this.state,  this.props.departureData);
    }

    componentDidMount(){
        window.scrollTo(0,0);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.departureData !== this.props.departureData) {
            let s = {};
            this.setupStateWithData(s, this.props.departureData);
            this.setState(s);
        }
    }


    setupStateWithData(state, departureData) {
        if ( !departureData ) return state;
        state.formValues.shipmentNumber = departureData.shipment ? departureData.shipment.assetId : "";
        state.trailerType = departureData.trailerType;
        state.formValues.carrier = departureData.carrier;
        state.formValues.broker = departureData.broker;

        state.formValues.tractorNumber = departureData.tractor ? departureData.tractor.assetId: "";
        state.formValues.tractorLicense = departureData.tractor && departureData.tractor.license || "";
        state.formValues.tractorLicenseState = departureData.tractor && departureData.tractor.licenseState || "";

        state.formValues.trailerNumber = departureData.trailer ? departureData.trailer.assetId: "";
        state.formValues.trailerLicense = departureData.trailer && departureData.trailer.license || "";
        state.formValues.trailerLicenseState = departureData.trailer && departureData.trailer.licenseState || "";
        state.formValues.trailerTemperature = departureData.trailer && departureData.trailer.temperature || "";

        state.formValues.driverCdl = departureData.driver ? departureData.driver.cdl: "";
        state.formValues.driverFirstname = departureData.driver ? departureData.driver.firstname: "";
        state.formValues.driverLastname = departureData.driver ? departureData.driver.lastname: "";
        state.formValues.driverState = departureData.driver ? departureData.driver.cdlState: "";
        state.formValues.driverCell = departureData.driver ? departureData.driver.cell: "";
        state.formValues.driverUuid = departureData.driver ? departureData.driver.uuid: "";
        state.formValues.driverHasSmartPhone = departureData.driver && departureData.driver.hasSmartPhone ? "Yes": "No";
        state.formValues.liveDrop = departureData.liveDrop;

        return state;
    }



    validationSchema(values) {

        let validationRules = {
            date: Yup.string()
                .required('Date is required.'),
            carrier: Yup.string()
                .required('Carrier is required.'),
            driverFirstname: Yup.string()
                .required('Driver firstname is required.'),
            driverLastname: Yup.string()
                .required('Driver lastname is required.'),
            tractorNumber: Yup.string().required('Tractor number is required.'),
            driverCell: Yup.string()
                .required('Driver mobile is required.')
            // driverCdl: Yup.string()
            //     .required('Driver cdl is required.'),
            // driverState: Yup.string()
            //     .required('Driver state is required.'),
            // minutesWaiting: Yup.number("Please enter a valid number.").typeError("Please enter a valid number.").nullable()
        };

        if ( this.state.trailerType === "loaded" ) {
            validationRules.shipmentNumber = Yup.string()
                .required('Shipment Number is required.');
            validationRules.liveDrop = Yup.string()
                .required('Destination Delivery Plan is required.');
            validationRules.trailerNumber = Yup.string()
                .required('Trailer number is required.');
        } else if ( this.state.trailerType === "bobtail" ) {

        } else if ( this.state.trailerType === "empty" ) {
            validationRules.trailerNumber = Yup.string()
                .required('Trailer number is required.');
        }
        return Yup.object().shape(validationRules)
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                return getErrorsFromValidationError(error)
            }
        }
    }


    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        values.trailerType = this.state.trailerType;
        values.locationUuid =   this.props.currentLocation?this.props.currentLocation.uuid:"";
        this.saveDeparture(values);
        return false;
    }

    classForSectionButton(section) {
        if ( this.state.openSections[section]) {
            return "fa fa-minus";
        } else {
            return "fa fa-plus"
        }
    }

    sectionOpen( section ) {
        return this.state.openSections[section];
    }

    toggleSection(section ) {
        let sections = Object.assign({},this.state.openSections); // copy sections
        if ( sections[section]) {
            sections[section] = false;
        } else {
            sections[section] = true;
        }
        this.setState({openSections: sections});
    }


    scrollToFirstError() {
        // set timeout is needed to yield to formix so it re-renders with the errors visible.
        setTimeout( () => {
            if ($(".invalid-feedback:visible")[0]) {
                let newTop = $(".invalid-feedback:visible").offset().top - 140;  // -140 for AppHeader, etc.
                $('html, body').animate({
                    scrollTop: newTop
                });
            }
        }, 100);
    }

    renderForm() {
        return (
            <Formik
                initialValues={this.state.formValues}
                validate={this.validate(this.validationSchema.bind(this))}
                onSubmit={(v, o) => {
                    this.onSubmit(v, o)
                }}
                render={
                    ({
                         values,
                         errors,
                         touched,
                         status,
                         dirty,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         isValid,
                         handleReset,
                         setTouched,
                         setFieldValue,
                         setFieldTouched
                     }) => (


                        <Form onSubmit={(v) => {
                            this.scrollToFirstError();
                            return handleSubmit(v);
                        }}>

                            <Card className="simple-card">
                                <CardHeader>Process Form</CardHeader>
                                <CardBody>
                                    <div>
                                        {this.state.trailerType === "loaded" ?
                                            <FormTextField
                                                label="Shipment Number"
                                                required={true}
                                                placeholder="Enter Value"
                                                icon="fa-hashtag"
                                                fieldname="shipmentNumber"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                            /> : ""}
                                        {this.state.trailerType === "loaded" ?
                                            <FormRadioField
                                                label="Destination Delivery Plan *"
                                                required={true}
                                                placeholder="Choose Value"
                                                options={[{name:"Live Load", value: "live"}, {name:"Pre-Load", value:"drop"}]}
                                                fieldname="liveDrop"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                                icon="fa-truck"
                                            /> : ""}
                                        <FormSelectCreatableField
                                            label="Carrier"
                                            defaultOptions
                                            required={true}
                                            placeholder="Enter Value"
                                            fieldname="carrier"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldTouched={setFieldTouched}
                                            setFieldValue={setFieldValue}
                                            loadOptions={promiseCarriersByPrefix}
                                            icon="fa-building"
                                        />
                                        <FormSelectCreatableField
                                            label="Broker"
                                            defaultOptions
                                            placeholder="Enter Value"
                                            fieldname="broker"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldTouched={setFieldTouched}
                                            setFieldValue={setFieldValue}
                                            icon="fa-building"
                                            loadOptions={promiseCarriersByPrefix}
                                        />


                                        {this.state.trailerType === "loaded"?
                                        <FormDateTimeField
                                            label="Appointment Time"
                                            placeholder="Enter Value"
                                            onChange={(v) => this.setState({appointment: v})}
                                            value={this.state.appointment}
                                            icon="fa-calendar"
                                            fieldname="appointment"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            setFieldTouched={setFieldTouched}
                                            setFieldValue={setFieldValue}
                                            timezone={this.props.currentLocation.timezone}
                                        />:""}

                                        {this.displayMinutesWaiting?
                                        <FormTextField
                                            numbersOnly="true"
                                            label="Minutes Waiting"
                                            placeholder="Enter Value"
                                            fieldname="minutesWaiting"
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            icon="fa-calendar"
                                        />:""}


                                        {this.displayInField?
                                            <FormDateTimeField
                                                label="In"
                                                required={true}
                                                placeholder="Enter Value"
                                                onChange={(v) => this.setState({date: v})}
                                                value={this.state.date}
                                                icon="fa-calendar"
                                                fieldname="date"
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                handleChange={handleChange}
                                                touched={touched}
                                                values={values}
                                                setFieldTouched={setFieldTouched}
                                                setFieldValue={setFieldValue}
                                            />:""
                                        }

                                        <Card>
                                            <CardHeader>
                                                <div className="form-section-title"><Button onClick={()=>this.toggleSection("tractor")}><i className={this.classForSectionButton("tractor")}></i></Button> Tractor</div>
                                            </CardHeader>
                                            <CardBody>
                                                <FormSelectCreatableField
                                                    key={(values["broker"]||values["carrier"])}
                                                    defaultOptions
                                                    label="Tractor Number"
                                                    subLabel="(Start typing to lookup tractor)"
                                                    required={true}
                                                    placeholder="Enter Value"
                                                    fieldname="tractorNumber"
                                                    icon="fa-hashtag"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    getOptionLabel={(option)=>option.assetId}
                                                    getOptionValue={(option)=>option.assetId}
                                                    loadOptions={(values["broker"]||values["carrier"])?
                                                        promiseTractorByCarrierAndIdPrefix(values["broker"]||values["carrier"]):
                                                        promiseTractorByIdPrefix}
                                                    onChange={(tractor)=>{
                                                        if ( tractor) {
                                                            setFieldValue("tractorLicense", tractor.license || "");
                                                            setFieldValue("tractorLicenseState", tractor.licenseState || "");
                                                        } else {/*could clear fields here, but maybe we don't want to*/}
                                                    }}
                                                />
                                                {this.sectionOpen("tractor")?
                                                <FormTextField
                                                    label="License Plate"
                                                    placeholder="Enter Value"
                                                    icon="fa-hashtag"
                                                    fieldname="tractorLicense"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                />:""}
                                                {this.sectionOpen("tractor")?
                                                <FormSelectStateField
                                                    label="License State"
                                                    placeholder="Enter Value"
                                                    icon="fa-id-card"
                                                    fieldname="tractorLicenseState"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                />:""}
                                            </CardBody>
                                        </Card>

                                        {this.state.trailerType !== "bobtail" ?

                                            <Card>
                                                <CardHeader>
                                                    <div className="form-section-title"><Button onClick={()=>this.toggleSection("trailer")}><i className={this.classForSectionButton("trailer")}></i></Button> Trailer</div>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormSelectCreatableField
                                                        key={(values["broker"]||values["carrier"])}
                                                        defaultOptions
                                                        label="Trailer Number"
                                                        subLabel="(Start typing to lookup trailer)"
                                                        required={true}
                                                        placeholder="Enter Value"
                                                        icon="fa-hashtag"
                                                        fieldname="trailerNumber"
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        values={values}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        getOptionLabel={(option)=>option.assetId}
                                                        getOptionValue={(option)=>option.assetId}
                                                        loadOptions={(values["broker"]||values["carrier"])?
                                                            promiseTrailerByCarrierAndIdPrefix(values["broker"]||values["carrier"]):
                                                            promiseTrailerByIdPrefix}
                                                        onChange={(trailer)=>{
                                                            if ( trailer) {
                                                                setFieldValue("trailerLicense", trailer.license || "");
                                                                setFieldValue("trailerLicenseState", trailer.licenseState || "");
                                                            } else {/*could clear fields here, but maybe we don't want to*/}
                                                        }}
                                                    />
                                                    <FormTextAreaField
                                                        label="Trailer Comment"
                                                        placeholder="Enter Value"
                                                        icon="fa-comment"
                                                        fieldname="trailerComment"
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        values={values}
                                                    />
                                                    {this.sectionOpen("trailer")?
                                                    <FormTextField
                                                        label="License Plate"
                                                        placeholder="Enter Value"
                                                        icon="fa-id-card"
                                                        fieldname="trailerLicense"
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        values={values}
                                                    />:""}
                                                    {this.sectionOpen("trailer")?
                                                    <FormSelectStateField
                                                        label="License State"
                                                        placeholder="Enter Value"
                                                        icon="fa-id-card"
                                                        fieldname="trailerLicenseState"
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        values={values}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                    />:""}
                                                    {this.sectionOpen("trailer")?
                                                    <FormTextField
                                                        label="Temperature"
                                                        placeholder="Enter Value"
                                                        icon="fa-thermometer-three-quarters"
                                                        fieldname="trailerTemperature"
                                                        errors={errors}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        touched={touched}
                                                        values={values}
                                                    />:""}
                                                </CardBody>
                                            </Card>
                                            : ""}

                                        <Card>
                                            <CardHeader>
                                                <div className="form-section-title"><Button
                                                    onClick={() => this.toggleSection("driver")}><i
                                                    className={this.classForSectionButton("driver")}/></Button>
                                                    Driver Information
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <FormPhoneSelectCreatableField
                                                    label="Mobile"
                                                    required={true}
                                                    subLabel="(Start typing number to lookup drivers)"
                                                    placeholder="Drivers mobile number without country code."
                                                    fieldname="driverCell"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    icon="fa-phone"
                                                    getOptionLabel={this.props.optionLabel}
                                                    getOptionValue={this.props.optionLabel}
                                                    countryCodeFieldName={"driverCountryCode"}
                                                    loadOptions={promiseDriversByPhonePrefix(values['driverCountryCode'])}
                                                    onChange={(driver)=>{
                                                        if ( driver ) {
                                                            setFieldValue("driverFirstname", driver.firstname);
                                                            setFieldValue("driverLastname", driver.lastname);
                                                            setFieldValue("driverCdl", driver.cdl);
                                                            setFieldValue("driverState", driver.cdlState || "");
                                                            setFieldValue("driverHasSmartPhone", !driver.uuid || driver.hasSmartPhone === "Yes");
                                                            setFieldValue("driverUuid", driver.uuid);
                                                            setFieldValue("driverCountryCode", driver.countryCode);

                                                        } else {/*could clear fields here, but maybe we don't want to*/}
                                                    }}
                                                    onCountryCodeChange={(e)=>{
                                                        setFieldValue("driverCountryCode", e.target.value);
                                                    }}
                                                />
                                                <FFFormSelectField
                                                    label="Mobile is Smart Phone"
                                                    required={false}
                                                    placeholder="Enter Value"
                                                    icon="fa-phone"
                                                    fieldname="driverHasSmartPhone"
                                                    name="driverHasSmartPhone"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    value={values && values.driverHasSmartPhone ? "Yes" : "No"}
                                                    maxMenuHeight={150}
                                                    options={["No", "Yes"]}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    noSearch={true}
                                                    onChange={(select) =>{;
                                                        setFieldValue("driverHasSmartPhone", select.value === "Yes");
                                                    }}
                                                    disabled={values && values["driverCell"].trim() === ""}
                                                />
                                                <FormTextField
                                                    label="First Name"
                                                    required={true}
                                                    placeholder="Enter Value"
                                                    icon="fa-user"
                                                    fieldname="driverFirstname"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                />
                                                <FormTextField
                                                    label="Last Name"
                                                    required={true}
                                                    placeholder="Enter Value"
                                                    icon="fa-user"
                                                    fieldname="driverLastname"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                />
                                                {this.sectionOpen("driver")?
                                                <FormTextField
                                                    label="CDL"
                                                    placeholder="Enter Value"
                                                    icon="fa-id-card"
                                                    fieldname="driverCdl"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                />:""}
                                                {this.sectionOpen("driver")?
                                                <FormSelectStateField
                                                    label="License State"
                                                    placeholder="Enter Value"
                                                    icon="fa-id-card"
                                                    fieldname="driverState"
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    handleChange={handleChange}
                                                    touched={touched}
                                                    values={values}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                />:""}
                                            </CardBody>
                                        </Card>


                                        <Button color="primary" type="submit">Save Departure</Button>


                                    </div>
                                </CardBody>
                            </Card>
                        </Form>)}>
            </Formik>

        );
    }

    dateToStringLocalTime( date ) {
        if ( date ) {
            return date.getFullYear() + "-" + padNumber(date.getMonth()+1, 2) + "-" + padNumber(date.getDate(),2) + "T" + padNumber(date.getHours(),2) + ":" + padNumber(date.getMinutes(),2) + ":00";
        }
    }

    saveDeparture(values) {
        let request = Object.assign({}, values);
        request.appointment =  this.dateToStringLocalTime(request.appointment);
        postDeparture(request,
            () => {
                this.props.history.push("/")
            },
            (e) => {
                showError("Unable to process departure.");
                console.log(e);
            })
    }

    render() {
        if ( !this.props.currentLocation && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN])) {
            this.props.history.push("/admin");
            return <div></div>;
        }else if (!this.props.currentLocation ){
            return <div className="no-location-error">Please choose a location.</div>
        }
        return (
            <div className="text-left p-3 form-bk">
                <Row>
                    <Col><h1>Process Departure: {this.props.currentLocation.name}</h1></Col>
                    <Col style={{
                        textAlign: "right"
                    }}>
                        <ChangeLocationFormField
                            currentLocation={this.props.currentLocation}
                            onChange={(location)=>{
                                this.props.setCurrentLocation(location);
                                if (location && location.uuid) {
                                    postUserDefaultLocation(location.uuid);
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Card className="simple-card">
                    <CardHeader>Trailer Type</CardHeader>
                    <CardBody>
                        <div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <Input className="form-check-input" type="radio" id="radioTrailerTypeLoaded"
                                       name="radios" checked={this.state.trailerType === "loaded"}
                                       onChange={(e) => this.setState({trailerType: (e.target.checked ? "loaded" : "")})}/>
                                <Label check className="form-check-label"
                                       htmlFor="radioTrailerTypeLoaded">Loaded Trailer</Label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <Input className="form-check-input" type="radio" id="radioTrailerTypeEmpty"
                                       name="radios" checked={this.state.trailerType === "empty"}
                                       onChange={(e) => this.setState({trailerType: (e.target.checked ? "empty" : "")})}/>
                                <Label check className="form-check-label" htmlFor="radioTrailerTypeEmpty">Empty
                                    Trailer</Label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                                <Input className="form-check-input" type="radio" id="radioTrailerTypeBobtail"
                                       name="radios" checked={this.state.trailerType === "bobtail"}
                                       onChange={(e) => this.setState({trailerType: (e.target.checked ? "bobtail" : "")})}/>
                                <Label check className="form-check-label" htmlFor="radioTrailerTypeBobtail">Bobtail (No
                                    Trailer)</Label>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {this.state.trailerType ? <div className="mt-2">{this.renderForm()}</div> : ""}
            </div>);
    }

}


function mapStateToProps(state) {
    return {
        departureData: state.departure.departureData,
        currentLocation: state.login.location,
        user: state.login.user
    }
}

function mapDispatchToProps(dispatch) {
    return ({
            setCurrentLocation: function (location) {
                dispatch(storeCurrentLocation(location));
            }
        }
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Departure));