import * as React from "react";
import {Button , Col, Row} from "reactstrap";
import {PaginationFilterTable} from "../../common/PaginationFilterTable";
import MomentDisplay from "../../assets/components/MomentDisplay";
import {ITEMS_PER_PAGE_DEFAULT} from "../../common/constants";
import {showError, showInfo} from "../../MainApp";
import {DISTANCE_FROM_DOMICILE, IDLE_TIME, MOVE_FREQUENCY, SHIPMENT , EXCURSION_TIME , DWELL_TIME} from "./EZTrackEventTableColumn";
import Link from "react-router-dom/Link";
import {requestEventsToExcel} from "../../NetworkHelpers";
import TableDateRangePicker from "../../common/TableDateRangePicker";
import { convertCtoF, endOfDay, startOfDay } from "../../common/utilities";

class EZTrackEvents extends React.Component {
    tableColumns = [
        {
            Header: 'Asset ID',
            accessor: 'assetName',
            sortable: true,
            filterable: true,
            default: true,
            Cell: row => {return row.original.assetName ?
                <Link to={"asset-profile/"+row.original.assetUuid}>{row.original.assetName}</Link>
                : ""}
        },
        {
            Header: 'Zone',
            accessor: 'zone',
            sortable: true,
            filterable: true,
            default: false,
            Cell: row => {return row.original.zone}
        },
        {
            Header: 'Device',
            accessor: 'trackerType',
            filterable: true,
            sortable: true,
            default: false,
            Filter: ({filter, onChange}) =>
                <>
                    <select
                        ref={this.trackerTypeFilterRef}
                        onChange={event => {
                            this.setState({trackerTypeFilter: event.target.value});
                            onChange(event.target.value)}}
                        style={{width: "100%"}}
                        value={this.state.trackerTypeFilter ? this.state.trackerTypeFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="MT9090">MT9090</option>
                        <option value="PG2400">PG2400</option>
                    </select>
                </>
        },
        {
            Header: 'Device ID',
            accessor: 'imei',
            filterable: true,
            sortable: true,
            default: true,
        },
        IDLE_TIME(),
        MOVE_FREQUENCY(),
        DISTANCE_FROM_DOMICILE(this.props.distanceUnitsOfMeasure),
        {
            Header: 'Domicile Name',
            accessor: 'assetDomicileName',
            filterable: true,
            default: true,
            sortable: true,
        },
        EXCURSION_TIME(),
        {
            Header: 'Location Name',
            accessor: 'locationName',
            filterable: true,
            default: true,
            sortable: true,
        },
        DWELL_TIME(),
        {
            Header: 'Address',
            accessor: 'address',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {return row.original.locationName ? row.original.geofenceAddress : row.original.address}
        },
        {
            Header: 'City',
            accessor: 'city',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {return this.formatCity(row) }
        },
        {
            Header: 'State',
            accessor: 'state',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {return row.original.locationName ? row.original.geofenceState : row.original.state}
        },
        {
            Header: 'Latitude',
            accessor: 'primaryLocation.latitude',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {return row.original.primaryLocation.latitude ?
                new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
                    .format(row.original.primaryLocation.latitude) :
                null}
        },
        {
            Header: 'Longitude',
            accessor: 'primaryLocation.longitude',
            filterable: false,
            sortable: true,
            default: true,
            Cell: row => {return row.original.primaryLocation.longitude ?
                new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
                    .format(row.original.primaryLocation.longitude) : null}
        },
        {
            Header: 'Temp (℉)',
            accessor: 'sensors.temperature',
            filterable: false,
            sortable: true,
            default: false,
            Cell: row => {
                return row.original.sensors.temperature ?
                    <span>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2})
                        .format(convertCtoF(row.original.sensors.temperature))} ℉</span> :
                    null

            }
        },
        {
            Header: 'Report Time',
            accessor: 'sourceTimestamp',
            filterable: false,
            sortable: true,
            default: false,
            Cell: row => {
                let v = row.original;
                if (v.timestamp) {
                    return <MomentDisplay date={v.timestamp} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/>;
                } else {
                    return null;
                }
            },
        },
        {
            Header: 'First Move',
            accessor: 'firstMoveOfDay',
            filterable: true,
            sortable: true,
            default: true,
            Cell: row => {
                return row.original.firstMoveOfDay ? "1" : "0";
            },
            Filter: ({filter, onChange}) =>
                <>
                    <select
                        ref={this.firstMoveFilterRef}
                        onChange={event => {
                            this.setState({firstMoveFilter: event.target.value});
                            onChange(event.target.value)}}
                        style={{width: "100%"}}
                        value={this.state.firstMoveFilter ? this.state.firstMoveFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">1</option>
                        <option value="false">0</option>
                    </select>
                </>
        },
    ];
    constructor(props){
        super(props);
        this.tableRef = React.createRef();
        this.trackerTypeFilterRef=React.createRef();
        this.firstMoveFilterRef=React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem('eztrack-events'));
        if (localStorageState != null) {
            this.state = {
                showFilterCollaps: false,
                previousSearchRequest: localStorageState.previousSearchRequest,
                trackerTypeFilter: localStorageState.trackerTypeFilter,
                firstMoveFilter: localStorageState.firstMoveFilter,
                filterStartDate: localStorageState.filterStartDate,
                filterEndDate: localStorageState.filterEndDate,
                filterDateType: localStorageState.filterDateType,
                dateFilterEnabled: localStorageState.dateFilterEnabled,
            }
        } else {
            this.state = {
                showFilterCollaps: false,
                previousSearchRequest: {},
                itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
                filtered: [],
                filterStartDate: startOfDay(new Date(),14),
                filterEndDate: endOfDay(new Date()),
                filterDateType: 'created',
            };
        }
        if (props.ezTrackShipmentTrackingEnabled) {
            this.tableColumns.splice(1, 0, SHIPMENT());
        }
    }
    componentWillUnmount() {
        this.savePageState();
    }
    formatCity(row) {
        if ("clipboard" in navigator) {
            return <div><span title={this.formatLatitudeLongitudePopup(row.original.primaryLocation.latitude,
                row.original.primaryLocation.longitude)}>
                    {row.original.locationName ? row.original.geofenceCity : row.original.city}</span></div>
        } else {
            return row.original.locationName ? row.original.geofenceCity : row.original.city;
        }
    }
    formatLatitudeLongitudePopup(latitude, longitude) {
        let lat = new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
            .format(latitude );
        let lng = new Intl.NumberFormat('en-US', {maximumFractionDigits: 5})
            .format(longitude );
        return lat + ", " + lng + " (Click to copy)";
    }
    onRowClick = (state, rowInfo, column, instance) => {
        return {
            onClick: e => {
                if ("clipboard" in navigator && column.id === 'city') {
                    let coords = rowInfo.original.primaryLocation.latitude + ", "
                        + rowInfo.original.primaryLocation.longitude;
                    navigator.clipboard.writeText(coords);
                    showInfo(coords + " copied to clipboard!", {}, {
                        autoClose: 2000
                    });
                }
            }
        }
    }
    clearFilterCallback() {
        // this.trackerTypeFilterRef.current.value = undefined;
        this.firstMoveFilterRef.current.value = undefined;
        this.tableRef.current.value = undefined;
        this.setState({trackerTypeFilter: undefined,
            firstMoveFilter: undefined});
    }
    updateProperty(id, value) {
        let previousSearchRequest = Object.assign({}, this.state.previousSearchRequest);
        let filtered = previousSearchRequest.filtered;
        let found = false;
        if (filtered == null) {
            filtered = [];
        }
        for (const filter of filtered) {
            if (filter.id === id) {
                filter.value = value;
                found = true;
            }
        }

        if (!found) {
            if (id === 'start') {
                filtered.push({ id: id, value: value, operator: 'GT' })
            } else if (id === 'end') {
                filtered.push({ id: id, value: value, operator: 'LT' })
            } else if (id === "filterDateType") {
                filtered.push({ id: id, value: value, operator: 'EQ' })
            } else {
                filtered.push({ id: id, value: value, operator: 'EQ' })
            }

        }
        previousSearchRequest.filtered = filtered;
        this.setState({
            previousSearchRequest: previousSearchRequest
        }, () => {
            this.savePageState();

        }
        );
    }
    savePageState(){
        localStorage.setItem("eztrack-events", JSON.stringify({
            trackerTypeFilter: this.state.trackerTypeFilter,
            firstMoveFilter: this.state.firstMoveFilter,
            previousSearchRequest: this.state.previousSearchRequest,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            filterDateType: this.state.filterDateType,
            dateFilterEnabled: this.state.dateFilterEnabled,
        }))
    }
    render() {
        return(
            <div>
                <div className="column-wrapper text-left">
                    <div className="page-header">
                    <Row>
                            <Col>
                                <h1>EZTrack - Track History</h1>
                            </Col>
                           
                            <Col className={"text-right"}>
                            
                                <Button onClick={() => {
                                    if (this.state.showFilterCollaps === true) {
                                        this.setState({ showFilterCollaps: false });
                                    } else {
                                        this.setState({ showFilterCollaps: true });
                                    }
                                }}><i className={this.state.showFilterCollaps === true ? "fa fa-chevron-up" : "fa fa-chevron-down"} /></Button>
                            </Col>
                        </Row>
                        
                    </div>
                    <PaginationFilterTable
                    ref={this.tableRef}
                        get={this.props.get}
                        showEditColumns
                        stateStorage={"eztrack-events"}
                        pageType="Admin"
                        columns={this.tableColumns}
                        itemsPerPage={this.state.itemsPerPage}
                        page={this.state.page}
                        baseFilter={[]}
                        clearFilter={this.clearFilterCallback.bind(this)}
                        getTdProps={this.onRowClick}
                        successCallback={
                            (res)=>{
                                this.setState({
                                    previousSearchRequest : res.request
                                });
                            }
                        }
                        openFiltersCollaps={this.state.showFilterCollaps}
                        onChange={(state) => {
                            this.setState({itemsPerPage: state.itemsPerPage,
                                previousSearchRequest: {page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered
                                }
                            });
                        }}
                        footerButton={
                            <Button color="secondary" onClick={() => this.exportEvents()}><i className="fa fa-file-excel"/> Export to Excel</Button>
                        }
                    >
                       
                    </PaginationFilterTable>
                </div>

            </div>
        )
    }
    exportEvents() {
        if ( this.state.previousSearchRequest ) {
            requestEventsToExcel( this.state.previousSearchRequest, (res)=>{
                let a = res.message;
                if(a && a.includes("https://e4score-ezcheckin")){
                    window.location.href=a;
                } else {
                    // showError("We are not able to generate the excel file at this time.  Please try again later.");
                    showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
                }
                // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
            
            }, (e)=>{
                console.log(e);
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }
}
export default EZTrackEvents;
