import React, {Component} from 'react';
import {Col, FormGroup, Label, Row} from "reactstrap";
import {FormStateSelectInputGroup} from "./FormStateSelectInputGroup";
import FormSelectCreatableField from "./FormSelectCreatableField";

class FormSelectStateField extends Component {

    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}/>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <FormStateSelectInputGroup
                            icon={this.icon()}
                            name={this.props.fieldname}
                            placeholder={this.props.placeholder}
                            errors={this.props.errors}
                            touched={this.props.touched}
                            onChange={(v) => {
                                if(!this.props.formikOverride){
                                    this.props.setFieldValue(this.props.fieldname, v ? v.value : "");
                                    this.props.setFieldTouched(this.props.fieldname, true);
                                }else{
                                    this.props.setFieldValue(this.props.formikOverride, v ? v.value : "");
                                    this.props.setFieldTouched(this.props.formikOverride, true);
                                }
                                this.props.onChange && this.props.onChange(v);
                            }}
                            onBlur={() => this.props.setFieldTouched(this.props.fieldname, true)}
                            values={this.props.values}
                            disabled={this.props.disabled}
                        />
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FormSelectStateField;