import React, { Component } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { showError, showInfo } from "../../MainApp";
import { generateClientSecret } from "../../NetworkHelpers";
import { generateRandomNumber } from "../utilities";

class FFFormLabelButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      secretObject: {},
    };
    console.log('state', this.state)
  }
  onBtnClickHandler() {
    // generateClientSecret((response) => {
    //   if (response.statusCode === 200) {
    //     this.props.onClick(response);
    //   }
    //   else {
    //     showError('Something went wrong');
    //   }
    // }, (error) => {
    //   showError(error.message);
    //   console.log(error)
    // })
    return this.props.onClick();
  }
  render() {
    return (
      <FormGroup>
        <Row>
          <Col lg="2" md="3" sm="4" >
            {this.props.isLblShow && <Label className={"mb-0"} >
              {this.props.label}&nbsp;
              <span className={'text-danger'}>{this.props.label && this.props.required ? " *" : ""}</span>
            </Label>}
          </Col>
          <Col lg="10" md="9" sm="8" className="text-right">
            <Button disabled={this.props.isDisabled} color={this.props.btnColor} onClick={() => {
              this.onBtnClickHandler()
              // this.props.onClick && this.props.onClick()
            }} >{this.props.btnTxt}</Button>
          </Col>
        </Row>
      </FormGroup>
    )
  }
}
export default FFFormLabelButton;
