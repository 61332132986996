import React from 'react';
import { Formik } from "formik";
import { Button, Form } from "reactstrap";
import FormCheckboxField from "../../common/FormCheckboxField";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import { LocationsSelect } from "../../common/LocationsSelect";
import FormField from "./FormField";
import FormTextField from "../../common/FormTextField";

export default class Companies extends React.Component {
  constructor(props) {
    super(props);
  }
  validationSchema(values) {
    let validationRules = {
    };
    return Yup.object().shape(validationRules)
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false })
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }

  onSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(false);
    if (!values.enableEzTrack) {
      values.ezTrackShipmentTrackingEnabled = false;
    }
    console.log("Saving: ", values);
    this.props.save(values, setErrors);
    return false;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.company.maTrackReportFrequencyInHours) {
      this.props.company.maTrackReportFrequencyInHours = 12;
    }
  }

  render() {
    return (
      <div className="column-wrapper">
        <Formik
          enableReinitialize
          initialValues={this.props.company}
          validate={this.validate(this.validationSchema.bind(this))}
          onSubmit={(v, o) => {
            this.onSubmit(v, o)
          }}
          render={
            ({
              values,
              errors,
              touched,
              status,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              handleReset,
              setTouched,
              setFieldValue,
              setFieldTouched
            }) => (
              <Form onSubmit={(v) => {
                return handleSubmit(v)
              }}>
                <FormCheckboxField
                  label="SMS"
                  icon="fa-power-off"
                  fieldname="smsEnabled"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="Allow Locations With Just Name"
                  icon="fa-power-off"
                  fieldname="allowLocationsByName"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="EZTrack"
                  icon="fa-power-off"
                  fieldname="enableEzTrack"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="EZDispatch"
                  icon="fa-power-off"
                  fieldname="enableEzCheckInDispatch"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelStyle={{ paddingTop: "5px" }}
                />

                <FormCheckboxField
                  label="PowerYard"
                  icon="fa-power-off"
                  fieldname="enablePowerYard"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelStyle={{ paddingTop: "5px" }}
                />
                {values["enablePowerYard"] &&
                  <FormCheckboxField
                    label="PowerYard On-Site"
                    icon="fa-power-off"
                    fieldname="powerYardOnSite"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    labelStyle={{ paddingTop: "5px" }}
                  />
                }
                {values["enablePowerYard"] &&
                  <FormCheckboxField
                    label="PowerYard Gate"
                    icon="fa-power-off"
                    fieldname="powerYardGate"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    labelStyle={{ paddingTop: "5px" }}
                  />
                }
                <FormCheckboxField
                  label="PowerYard - Pro"
                  icon="fa-power-off"
                  fieldname="enablePowerYardPro"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="TrackAssured"
                  icon="fa-power-off"
                  fieldname="enableTrackAssured"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="EZCheck-In Welcome Center"
                  icon="fa-power-off"
                  fieldname="enableEzCheckInWelcome"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelStyle={{ paddingTop: "5px" }}
                />
                {values["enableEzTrack"] &&
                  <FormTextField
                    numbersOnly={true}
                    appendText={"Hours"}
                    placeholder={"Number of hours between reports"}
                    label="MaTrack Report Frequency (Hours)"
                    icon="fa-clock"
                    fieldname="maTrackReportFrequencyInHours"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    // labelClassName={"text-center"}
                    labelStyle={{ paddingTop: "5px" }}
                  />
                }
                {values["enableEzTrack"] &&
                  <FormCheckboxField
                    label="EZTrack Shipment Tracking"
                    icon="fa-power-off"
                    fieldname="ezTrackShipmentTrackingEnabled"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    // labelClassName={"text-center"}
                    labelStyle={{ paddingTop: "5px" }}
                  />
                }
                <FormCheckboxField
                  label="Tailwind Integration"
                  icon="fa-power-off"
                  fieldname="enableTailwind"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
                <FormCheckboxField
                  label="Demo"
                  icon="fa-power-off"
                  fieldname="demo"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
                {values.demo &&
                  <FormField
                    label={"Demo Origin Location"}
                    errors={errors}
                    icon={"fa-building"}
                    fieldname={"demoOrigin"}
                  >
                    <LocationsSelect
                      single
                      name={"demoOrigin"}
                      fieldname={"demoOrigin"}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      values={values}
                      required={true}
                      onLocationsChange={(location) => {
                        setFieldValue("demoOrigin", [{ uuid: location.value, name: location.label }]);
                        setFieldTouched("demoOrigin", true);
                      }}
                    />
                  </FormField>
                }
                {values.demo &&
                  <FormField
                    label={"Demo Destination Location"}
                    errors={errors}
                    icon={"fa-building"}
                    fieldname={"demoDestination"}
                  >
                    <LocationsSelect
                      single
                      name={"demoDestination"}
                      fieldname={"demoDestination"}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      values={values}
                      required={true}
                      onLocationsChange={(location) => {
                        setFieldValue("demoDestination", [{ uuid: location.value, name: location.label }]);
                        setFieldTouched("demoDestination", true);
                      }}
                    />
                  </FormField>
                }
                <FormCheckboxField
                  label="API"
                  icon="fa-power-off"
                  fieldname="apiEnabled"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  // labelClassName={"text-center"}
                  labelStyle={{ paddingTop: "5px" }}
                />
             
                <div className="mb-3 text-right">
                  <Button color="primary" type="submit">Save</Button>
                </div>
              </Form>
            )} />
      </div>
    );
  }
}
Companies.propTypes = {}
