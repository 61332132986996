import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";

class FFFormRadioField extends Component {


    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }


    renderFields() {
        return this.props.options.map((v, ix)=>{
            let checked = this.props.value === v.value;
            return (
                <div key={ix} className="custom-control custom-radio custom-control-inline">
                    <Label check className="form-check-label">
                        <Input className="form-check-input" type="radio"
                               name={this.props.fieldname} checked={checked}
                               onChange={(e) => {
                                   if ( e.target.checked ) {
                                       this.props.onChange(e.target.checked ? v.value : "");
                                   }
                               }}/>
                        {v.name}</Label>
                </div>)

        });
    }

    getClassName() {
        let ret = "radio-group";
        if ( !!this.props.error && this.props.touched) {
            ret += " is-invalid";
        }
        return ret;
    }

    render() {
        return (

            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup className={this.getClassName()}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <div className={((this.props.touched && !!this.props.error) ? "radio-list is-invalid" : "radio-list")} >
                                {this.renderFields()}
                            </div>
                            <FormFeedback>{this.props.error}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FFFormRadioField;