import React, {Component} from "react";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import Moment from "react-moment";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import {storeDepartureData} from "../../Departure/actions/Departure";
import {storeItemsPerPage} from "../actions/Assets";
import AssessorialView from "./AssessorialView";
import AssetView from "./AssetView"
import Select from 'react-select'
import {downloadDepartureGateEventFromAsset, postUserDefaultLocation} from "../../NetworkHelpers";
import {showError} from "../../MainApp";
import {ROLE_ADMIN, ROLE_ATTENDANT, ROLE_E4SCORE_ADMIN, userHasRole} from "../../Roles";
import Switch from "react-switch";
import {latLongLink} from "../../common/utilities";
import {ChangeLocationFormField} from "../../Dashboard/Main/components/CurrentLocation";
import {storeCurrentLocation} from "../../Login/actions/Login";


const assetTypeOptions = [
    {value: "trailer", label: "Trailers"},
    {value: "tractor", label: "Tractors"},
    {value: "driver", label: "Drivers"}
];
const assetTypeDefaults = [{value:"trailer",label:"Trailers"},{value:"tractor",label:"Tractors"}];


class Assets extends Component {
    constructor( props ) {
        super(props);
        this.state = {
            viewBy:"All",
            checkoutShipment: null,
            gateEvents: null,
            filterBy: "All",
            assessorialView: this.props.assessorial,
            assetTypes: assetTypeDefaults,
            hideFilters: true
        }
    }

    showEvents( events, itemName ) {
        this.setState({gateEvents:events, gateEventsItem: itemName});
    }


    checkoutByEvent(asset) {
        try {
            // do the network call to ask for the filled in event
            downloadDepartureGateEventFromAsset(this.props.currentLocation.uuid, asset.uuid, (data)=>{
                this.props.setDepartureData(data);
                this.showDepartureScreen();
            }, (error)=>{showError(error)})
        } catch (e ){
            console.log("ERROR ", e);
        }
    }

    showDepartureScreen() {
        this.props.history.push("/departure");
    }
    showArrivalScreen() {
        this.props.history.push("/arrival");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.assessorials !== this.props.assessorial) {
            if ( !!this.props.assessorial !== !!this.state.assessorialView ) {
                this.setState({assessorialView: this.props.assessorial});
            }
        }
    }

    renderBody() {
        if ( this.state.assessorialView ) {
            return <AssessorialView showArrivalScreen={() => this.showArrivalScreen()}
                                    showDepartureScreen={() => this.showDepartureScreen()} assetIdName="Asset ID"
                                    assetTypes={this.state.assetTypes} user={this.props.user}
                                    filterBy={this.state.filterBy}
                                    showEvents={(events, itemName) => this.showEvents(events, itemName)}
                                    itemsPerPage={this.props.itemsPerPage}
                                    latLongLink={this.latLongLink.bind(this)}
                                    setItemsPerPage={(itemsPerPage, callback)=>this.props.setItemsPerPage(itemsPerPage, callback)}
                                    currentLocation={this.props.currentLocation}
                                    displayShipmentProfile={(shipmentId)=>this.displayShipmentProfile(shipmentId)}
            />;

        } else {
            return <AssetView showArrivalScreen={() => this.showArrivalScreen()} hideFilters={this.state.hideFilters}
                              showDepartureScreen={() => this.showDepartureScreen()} assetIdName="Asset ID"
                              assetTypes={this.state.assetTypes} user={this.props.user}
                              filterBy={this.state.filterBy} checkoutAsset={(asset) => this.checkoutByEvent(asset)}
                              showEvents={(events, itemName) => this.showEvents(events, itemName)}
                              itemsPerPage={this.props.itemsPerPage}
                              latLongLink={this.latLongLink.bind(this)}
                              setItemsPerPage={(itemsPerPage, callback)=>this.props.setItemsPerPage(itemsPerPage, callback)}
                              currentLocation={this.props.currentLocation}
                              displayShipmentProfile={(shipmentId)=>this.displayShipmentProfile(shipmentId)}
            />;
        }
    }

    latLongLink( item ) {
        return latLongLink(item);
    }


    locationString() {
        if ( this.props.currentLocation ) {
            return this.props.currentLocation.name;
        } else {
            return "No Location Selected";
        }
    }

    displayShipmentProfile( shipmentId ) {
        this.props.history.push("/shipment-profile/" + shipmentId);
    }

    render() {
        return (<div className="column-wrapper text-left">
            <div className="page-header">
                <Row>
                    <Col>
                        <h1>{this.state.assessorialView?"Assessorial View":"On Site Assets: " + this.locationString()}</h1>
                    </Col>
                    <Col style={{
                        textAlign: "right"
                    }}>
                        {!this.state.assessorialView &&
                        <ChangeLocationFormField
                            currentLocation={this.props.currentLocation}
                            onChange={(location)=>{
                                this.props.setCurrentLocation(location);
                                if (location && location.uuid) {
                                    postUserDefaultLocation(location.uuid);
                                }
                            }}
                        />}
                    </Col>
                </Row>

                <Modal isOpen={this.state.gateEvents!=null} size={"lg"} >
                    <ModalHeader>
                        Gate Events for {this.state.gateEventsItem}
                    </ModalHeader>
                    <ModalBody style={{overflow:'auto'}}>
                        {this.state.gateEvents?this.state.gateEvents.map((v, ix)=>(

                            <div key={ix}>{v.arrival?"Arrival: ":"Departure: "}<Moment format="MMMM Do YYYY, h:mm:ss a">{v.date}</Moment></div>

                        )):""}
                        <div className="mb-5 mt-5">
                            <Button color="primary" onClick={()=>this.setState({gateEvents: null})}>Close</Button>
                        </div>
                    </ModalBody>
                </Modal>


                {!this.state.assessorialView?
                <div className="table-action-bar">
                    <Row>
                        <Col sm="8">
                            {this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT])?<Button color="link" onClick={()=>this.showArrivalScreen()}> <i className="fa fa-arrow-circle-left"></i> Process Arriving </Button>:""}
                            {this.props.user.company.enableEzCheckInWelcome && userHasRole(this.props.user, [ROLE_ADMIN, ROLE_E4SCORE_ADMIN, ROLE_ATTENDANT])?<Button color="link" onClick={()=>this.showDepartureScreen()}> <i className="fa fa-arrow-circle-right"></i> Process Departing </Button>:""}
                        </Col>
                        <Col sm="4" className="filter-box">
                               <div className="type-select">
                                    <Select
                                        placeholder="Choose Asset Types"
                                        onChange={(v) => {if (v.length===0) v = assetTypeDefaults; this.setState({assetTypes:v})}}
                                        value={this.state.assetTypes}
                                        isMulti="true"
                                        classNamePrefix="select"
                                        options={assetTypeOptions}
                                        isClearable={true}/>
                               </div>

                            {!this.state.assessorialView?
                                <div className="filter-switch" title="Show Filter"><Switch onChange={(checked)=>this.setState({hideFilters:!checked})} checked={!this.state.hideFilters}
                                                                                           uncheckedIcon={<div className="text-center pt-1"><i className="fa fa-filter"></i></div>}
                                                                                           checkedIcon={<div className="text-center pt-1"><i className="fa fa-filter"></i></div>}
                                                                                           title="Show Filter"
                                                                                           className="react-switch"/></div>:""}
                        </Col>
                    </Row>
                </div>:""}

                <Row>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>



                    </Col>

                    <Col md={4}>
                    </Col>
                </Row></div>
            {this.renderBody()}
        </div>);
    }


}




function mapStateToProps(state) {
    return {
        itemsPerPage: state.assets.itemsPerPage,
        currentLocation: state.login.location,
        user: state.login.user
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setItemsPerPage: function(itemsPerPage) {
            dispatch(storeItemsPerPage(itemsPerPage));
        },
        setDepartureData: function(data, asset) {
            dispatch(storeDepartureData(data, asset));
        },
        setCurrentLocation: function(location) {
            dispatch(storeCurrentLocation(location));
        }
    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assets));