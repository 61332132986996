import React, { Component } from "react";

import Modal from "reactstrap/es/Modal";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  InputGroup,
  ModalBody,
  ModalHeader
} from "reactstrap";
import { showInfo, showError } from "../../MainApp";
import moment from "moment";
import { getMessagesFromPWA } from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { TEXT_MESSAGE } from "../../common/constants"
class TextMessage extends React.Component<{ messages: Object, uuid: T, currentLocation: any }> {
  render() {
    return <Row>
      <Col md={12}>
        <Card body className={this.props.messages[this.props.uuid].messageType == false ? 'text-left' : 'text-right'}>
          <CardTitle >
            <Row>
              <Col sm={12}>
                {this.props.messages[this.props.uuid].messageType == false ? 'Admin' : 'Driver'}
              </Col>
              <Col sm={12}>
                <MomentDisplay date={this.props.messages[this.props.uuid].created}
                  timezone={this.props.currentLocation && this.props.currentLocation.timezone} />
              </Col>
            </Row>
          </CardTitle>
          <CardText>{this.props.messages[this.props.uuid].message}</CardText>
        </Card>
      </Col>
    </Row>;
  }
}
class SMSModel extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      selectedArrival: {
        firstname: "",
        mobile: ""
      },
      message: "",
      updatePopupState: false,
      currentCompany: "",
      currentLocation: "",
      lstMessages: [],


    };
  }
  componentWillUpdate(previousProps, prevState, snapshot) {

    if (previousProps.open && this.state.updatePopupState === false) {

      this.setState({
        selectedArrival: previousProps.selectedArrival,
        updatePopupState: true,
        currentCompany: previousProps.currentCompany,
        currentLocation: previousProps.currentLocation,
        message: "",
        hideChat: previousProps.hideChat,
        lstMessages: []
      });
      getMessagesFromPWA(previousProps.selectedArrival.arrivalID, null, (res) => {
        if (res.statusCode === "200") {
          if(this.props.tableRef){
            this.props.tableRef.current.fetchData();
          }
          this.setState({
            lstMessages: res.data
          });
        }
      },
        (err) => {
          showError(err);
        }
      );

    }



  }
  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalHeader>
          <h1>{this.state.hideChat?'Chat History':'Message Driver'}: {this.state.selectedArrival && this.state.selectedArrival.firstname} (***-***-{this.state.selectedArrival.mobile && this.state.selectedArrival.mobile.substr(this.state.selectedArrival.mobile.length - 4)}) </h1>
        </ModalHeader>
        <ModalBody>
          <Container>
            {this.state.lstMessages && Object.keys(this.state.lstMessages).length > 0 &&
              <Row>
                <Col md={12}>
                  <h3>Chat</h3>
                </Col>
              </Row>
            }
            <div className="note-scollbox">
              {this.state.lstMessages && Object.keys(this.state.lstMessages).length > 0 && Object.keys(this.state.lstMessages).map((uuid, ix) =>
                <TextMessage key={ix} messages={this.state.lstMessages} uuid={uuid} currentLocation={this.state.currentLocation} />
              )}
            </div>
            {this.state.hideChat == true && this.state.lstMessages && this.state.lstMessages.length === 0 &&

              <Row>
                <Col md={12}>
                  <h5 className="text-center">No chat to show </h5>
                </Col>
              </Row>
            }
            {this.state.hideChat == false &&
              <div>
                <Row>
                  <Col md={12}>
                    <h3>New Chat</h3>
                    <h5>Message will be sent via SMS and EZC Messenger: </h5>
                    {/* <p>You have a new Message from {this.state.currentCompany} {this.state.currentLocation} in EzCheck-In portal, click HERE to view.</p> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      placeholder="Write something here ...."
                      rows={3}
                      type="textarea"
                      onChange={(v) => {
                        this.setState({ message: v.target.value.trim() })
                      }}
                    />

                  </Col>
                </Row>
              </div>

            }
            <Row>
              <Col className="text-right" style={{ marginTop: "10px" }} >
                {this.state.hideChat == false &&
                  <Button disabled={!this.state.message} color="primary" onClick={() => this.onSubmit()} >Send</Button>
                }

                &nbsp;<Button color="danger" onClick={() => this.closePopup()} type="submit">Close</Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    )
  }
  closePopup() {
    this.setState({ updatePopupState: false })
    return this.props.onClose && this.props.onClose();

  }
  onSubmit() {
    let da = moment.utc();
    let gt = da.valueOf();
    let obj =
    {
      arrival: {
        arrivalID: this.state.selectedArrival.arrivalID
      },
      created: gt,

      toPhone: this.state.selectedArrival.mobile,
      message: this.state.message,
      contactMethod: "messenger & sms",
      messageType: false,
      orgName: this.state.currentCompany,
      orgUuid: this.state.selectedArrival.organizationUuid,
      locName: this.state.currentLocation.name !== "" ? this.state.currentLocation.name : this.state.currentLocation.nickname
    }
    this.setState({ updatePopupState: false })
    return this.props.onAdd && this.props.onAdd(obj);
  }
  localDateToUTC(dateISOLocalString) {
    let localLoginTime = moment.utc(dateISOLocalString).utc();
    var returnDate = new Date(localLoginTime.format('MMM-DD-YYYY HH:mm:ss'));
    return returnDate;
  }
}
export default SMSModel;