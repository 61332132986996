import React, {Component} from "react";
import FormRadioField from "../../common/FormRadioField";
import * as PropTypes from "prop-types";
import ReferenceNumberList from "../../Dispatch/components/ReferenceNumberList";
import {Col, Form, FormFeedback, InputGroup} from "reactstrap";

export class LoadedTrailerFormFields extends Component {
    render() {
        return <>
            <ReferenceNumberList
                title="Receiving Shipment Reference Numbers"
                error={this.props.touched[this.props.referenceNumberFieldName] && this.props.errors[this.props.referenceNumberFieldName]}
                referenceNumbers={this.props.shipmentReferences || []}
                referenceNumberOptions={(this.props.referenceTypes && LoadedTrailerFormFields.getReferenceNumberOptions(this.props.referenceTypes, this.props.company)) || []}
                defaultReferenceType={this.props.company.shipmentIdDisplay}
                onChange={(refNums)=>{
                    this.props.onReferenceNumberListChange && this.props.onReferenceNumberListChange(refNums);
                    // setFieldValue("referenceNumbers", refNums);
                }}
            />
            <FormRadioField
                label="Delivery Plan *"
                placeholder="Choose Value"
                options={[{name: "Live Unload", value: "live"}, {
                    name: "Drop Trailer",
                    value: "drop"
                }]}
                fieldname="liveDrop"
                errors={this.props.errors}
                handleBlur={this.props.handleBlur}
                handleChange={this.props.handleChange}
                touched={this.props.touched}
                values={this.props.values}
                setFieldTouched={this.props.fieldTouched}
                setFieldValue={this.props.fieldValue}
                icon="fa-dolly"
            />
        </>;
    }

    static getReferenceNumberOptions(referenceTypes, company) {
        let list = [];
        list.push({label: company.shipmentIdDisplay, value: company.shipmentIdDisplay, required: true});
        if(referenceTypes){
            for (const referenceType of referenceTypes) {
                list.push({label: referenceType.name, value: referenceType.name});
            }
        }
        return list;
    }
}

LoadedTrailerFormFields.propTypes = {
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any
};