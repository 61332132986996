import React, {Component} from "react";
import {FormFeedback, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {FFStateSelect} from "./FFStateSelect";

export class FFFormStateSelectInputGroup extends Component {
    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}/>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
        return <InputGroup>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>{this.icon()}</InputGroupText>
            </InputGroupAddon>
            <FFStateSelect name={this.props.name}
                         fieldname={this.props.name}
                         placeholder={this.props.placeholder}
                         error={this.props.error}
                         touched={this.props.touched}
                         onChange={this.props.onChange}
                         onBlur={this.props.onBlur}
                         value={this.props.value}
                         disabled={this.props.disabled}
                           selectedCountry={this.props.selectedCountry}
            />
            <FormFeedback>{this.props.error}</FormFeedback>
        </InputGroup>;
    }
}