import React, {Component} from "react";
import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import Switch from "react-switch";
import * as PropTypes from "prop-types";
import {promiseTractorByIdPrefixAndTagEZTrackAssets, searchShipmentsByAssetId} from "../../NetworkHelpers";
import {DispatchTrailerCard} from "./DispatchTrailerCard";
import {DispatchDriverCard} from "./DispatchDriverCard";
import ReferenceNumberList from "./ReferenceNumberList";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectStateField from "../../common/formik-free/FFFormSelectStateField";
import * as uuid from "uuid";
import {Debug} from "../../common/Debug";

export class DispatchAssetInformation extends Component {
    constructor(props){
        super(props);
        if(!props.errors){
            this.state = {
                advanced: false,
                errors: {
                    driver: {}
                },
                touched: {},
                disableEventByMessageToggle: !props.values.tractorNumber.includes(" (EZTrack)") &&
                    !props.values.trailerNumber.includes(" (EZTrack)")
            }
        }else {
            this.state = {
                advanced: false,
                errors: props.errors,
                touched: {},
                disableEventByMessageToggle: !props.values.tractorNumber.includes(" (EZTrack)") &&
                    !props.values.trailerNumber.includes(" (EZTrack)")
            };
            if(!props.errors.driver){
              this.state.errors.driver = {}
            }
        }
    }

    addStopReferencesToDispatchReferences() {
        let dispatchRefNos = this.props.dispatchReferenceNumbers ? this.props.dispatchReferenceNumbers.slice() : [];
        this.props.values.stops.forEach((stop) => {
            if (!!stop.pickupReferenceNumbers) {
                stop.pickupReferenceNumbers.forEach((v) => {
                    let matchingReferences = dispatchRefNos.filter((d) => {
                        return v.type === d.type && v.value === d.value;
                    });
                    if (matchingReferences.length === 0) {
                        dispatchRefNos.push(v);
                    }
                });
            }
            if (!!stop.deliverReferenceNumbers) {
                stop.deliverReferenceNumbers.forEach((v) => {
                    let matchingReferences = dispatchRefNos.filter((d) => {
                        return v.type === d.type && v.value === d.value;
                    });
                    if (matchingReferences.length === 0) {
                        dispatchRefNos.push(v);
                    }
                });
            }
        });
        return dispatchRefNos;
    }

    onChange = (field, value) => {
        this.props.onChange(field, value);
        let touched = {...this.state.touched};
        touched[field] = true;
        this.setState({touched: touched});
    };

    render() {


        return <Card className="dispatch-card-sub demo-card">
            <CardHeader>Asset Information</CardHeader>
            <CardBody className="demo-card-body">
                <div className="demo-toggle">
                    <span style={{marginRight: "10px"}}>
                        Advanced View
                    </span>
                    <Switch
                        checked={this.state.advanced}
                        onChange={()=>{
                            this.setState({
                                advanced: !this.state.advanced
                            })
                        }}
                        height={20}
                        width={38}
                        // disabled={this.props.disabled}
                    />
                </div>
                <FFFormSelectField
                    label="Trailer Start"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-truck"
                    fieldname="startConfiguration"
                    name="startConfiguration"
                    error={this.props.errors.startConfiguration}
                    onBlur={this.props.onBlur}
                    onChange={(newValue) => {
                        let touched = {...this.state.touched};
                        touched.startConfiguration = true;
                        this.setState({touched});
                        this.props.onChange("startConfiguration", newValue ? newValue.value : "");
                    }}
                    touched={this.state.touched}
                    value={this.props.values.startConfiguration}
                    options={["Empty", "Bobtail"]}
                    noSearch={true}
                    isDisabled={this.props.disabled}
                />
                <ReferenceNumberList
                    title="Dispatch Reference Numbers"
                    referenceNumbers={this.props.values.references}
                    defaultReferenceType={this.props.company.shipmentIdDisplay}
                    referenceNumberTypeOptions={this.props.referenceNumberTypeOptions}
                    references={this.addStopReferencesToDispatchReferences()}
                    onChange={(shipmentReference) =>{
                        if(shipmentReference && shipmentReference.value){
                            if(shipmentReference.type === this.props.company.shipmentIdDisplay){
                                searchShipmentsByAssetId(shipmentReference.value, (response) => {
                                    this.setState({
                                        errors: {
                                            shipmentId: (shipmentReference.type ? shipmentReference.type : "Shipment Id") + " " +(shipmentReference.value ? shipmentReference.value : "") + " is already taken"
                                        }
                                    });
                                }, ()=>{
                                    this.pushAndUpdateReferences(shipmentReference, this.props.company.shipmentIdDisplay);
                                });
                            }else{
                                this.pushAndUpdateReferences(shipmentReference, this.props.company.shipmentIdDisplay);
                            }
                        }
                    }}
                    onDelete={(referenceNumber)=>{
                        for (let i = 0; i < this.props.values.references.length; i++) {
                            if(this.props.values.references[i].type === referenceNumber.type && this.props.values.references[i].value === referenceNumber.value){
                                this.props.values.references.splice(i,1);
                            }
                        }

                        this.onChange("references", this.props.values.references);
                        this.setState({
                            errors: {
                                shipmentId: null
                            }
                        });
                    }}
                    disabled={this.props.disabled}
                    useLookup={true}
                    touched={this.state.touched.references}
                    error={this.state.errors.shipmentId}
                    onBlur={this.props.onBlur}
                    value={this.props.values.references}
                />
                <Card>
                    <CardBody>
                        <FFFormSelectCreatableField
                            key={"ID_"+uuid.v4()}
                            defaultOptions
                            label="Tractor Number"
                            subLabel="Start typing to lookup tractor"
                            required={true}
                            placeholder="Enter Value"
                            fieldname="tractorNumber"
                            icon="fa-hashtag"
                            error={this.props.errors.tractorNumber}
                            onBlur={this.props.onBlur}
                            touched={this.state.touched.tractorNumber}
                            value={this.props.values.tractorNumber}
                            getOptionLabel={"Tractor"}
                            getOptionValue={"Tractor"}
                            loadOptions={promiseTractorByIdPrefixAndTagEZTrackAssets}
                            onChange={(select) => {
                                this.onChange("tractorNumber", select ? select.value : "");
                                this.setState({disableEventByMessageToggle: !(select && select.devices && select.devices.length > 0)});
                            }}
                            checkered={true}
                            dispatch={true}
                            isDisabled={this.props.disabled}
                        />
                        {this.state.advanced &&
                        <FFFormTextField
                            label="License Plate"
                            placeholder="Enter Value"
                            icon="fa-hashtag"
                            fieldname="tractorLicense"
                            error={this.props.errors.tractorLicense}
                            onBlur={this.props.onBlur}
                            onChange={(v) => {
                                let touched = {...this.state.touched};
                                touched.tractorLicense = true;
                                this.setState({touched});
                                this.props.onChange("tractorLicense", v);
                            }}
                            touched={this.state.touched.tractorLicense}
                            value={this.props.values.tractorLicense}
                            disabled={this.props.disabled}
                        />}
                        {this.state.advanced &&
                        <FFFormSelectStateField
                            label="License State"
                            placeholder="Enter Value"
                            icon="fa-id-card"
                            fieldname="tractorLicenseState"
                            errors={this.props.errors.tractorLicenseState}
                            onBlur={this.props.onBlur}
                            onChange={(v) => {
                                let touched = {...this.state.touched};
                                touched.tractorLicenseState = true;
                                this.setState({touched});
                                this.props.onChange("tractorLicenseState", v);
                            }}
                            touched={this.state.touched.tractorLicenseState}
                            value={this.props.values.tractorLicenseState}
                            disabled={this.props.disabled}
                            selectedCountry={"United States"}
                        />}
                    </CardBody>
                </Card>
                {
                    this.props.values["startConfiguration"] === "Empty" &&
                    <DispatchTrailerCard
                        errors={this.props.errors}
                        trailer={this.props.assetRoster}
                        optionLabel={"Tractor"}
                        values={this.props.values}
                        advancedView={this.state.advanced}
                        disabled={this.props.disabled}
                        onChange={(field, value) => {
                            if (field === "trailerIsEzTrack") {
                                this.setState({disableEventByMessageToggle: !value});
                            } else {
                                this.props.onChange && this.props.onChange(field, value);
                            }
                        }}
                        onBlur={this.props.onBlur}
                    />
                }
                <Debug data={this.props.values && this.props.values.driver}>
                    <DispatchDriverCard
                        advancedView={this.state.advanced}
                        driver={this.props.values.driver || {
                            hasSmartPhone: true
                        }}
                        disabled={this.props.disabled}
                        errors={this.props.errors.driver || {}}
                        onBlur={this.props.onBlur}
                        onChange={(ddriver) => {
                            if (ddriver) {
                                ddriver.touched = true;
                                this.props.onChange("driver", ddriver);
                            }
                        }}
                        dispatchUuid={this.props.values.uuid}
                        ezTrackShipmentTrackingEnabled={this.props.company.ezTrackShipmentTrackingEnabled}
                        disableEventByMessageToggle={this.state.disableEventByMessageToggle}
                        eventByShipmentTracking={this.props.values.eventByShipmentTracking}
                    />
                </Debug>
            </CardBody>
        </Card>;
    }

    pushAndUpdateReferences(shipmentReference, ref) {
        let pushed = false;
        if (!this.props.values.references) {
            this.props.values.references = [];
        }

        if(this.props.values.references.length > 0){
            for (let i = 0; i < this.props.values.references.length; i++) {
                if(this.props.values.references[i].type === shipmentReference.type && shipmentReference.type === ref){
                    this.props.values.references[i] = shipmentReference;
                    pushed = true;
                }
            }
        }else {
            this.props.values.references.push(shipmentReference);
            pushed = true;
        }

        if(!pushed){
            let matchingReferences = this.props.values.references.filter((v) => {
                return v.type === shipmentReference.type && v.value === shipmentReference.value;
            });
            if (matchingReferences.length === 0) {
                this.props.values.references.push(shipmentReference);
            }
        }

        this.onChange("references", this.props.values.references);
        this.setState({
            errors: {
                shipmentId: null
            }
        });
    }
}

DispatchAssetInformation.propTypes = {
    advancedToggleComponent: PropTypes.any,
    errors: PropTypes.any,
    touched: PropTypes.any,
    assetRoster: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    tractorInfo: PropTypes.any,
    values: PropTypes.any,
    driverInfo: PropTypes.any,
    transportationServiceProviders: PropTypes.object
};