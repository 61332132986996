import * as React from "react";
import { Button } from "reactstrap";
import { downloadSpotTypesForTable, saveSpotType, getSpotNamesForDropdown } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import SpotTypeEdit from "./SpotTypeEdit";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class SpotTypes extends React.Component {
    tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Type ID',
            accessor: 'id',
            sortable: false,
            filterable: false
        },
        {
            Header: 'Default',
            accessor: 'def',
            filterable: true,
            sortable: true,
            Cell: row => {
                return row.original.def ? "Yes" : "No";
            },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.isDefaultFilterRef}
                        onChange={event => {
                            this.setState({ isDefaultFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.isDefaultFilter}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: 'Enabled',
            accessor: 'enabled',
            filterable: true,
            sortable: true,
            Cell: row => {
                return row.original.enabled ? "Yes" : "No";
            },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={event => {
                            this.setState({ enabledFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.enabledFilter}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: row => {
                return <Button title="Edit" aria-label="Edit Spot Type"
                    onClick={() => this.EditSpotType(row.original)}><i
                        className="fa fa-edit" /></Button>
            }
        }
    ];
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.enabledFilterRef = React.createRef();
        this.isDefaultFilterRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem("poweryar-spot-type-settings"));
        this.state = {
            names: []
        }
        if (localStorageState != null) {
            this.state = {
                enabledFilter: localStorageState.enabledFilter,
                isDefaultFilter: localStorageState.isDefaultFilter,
                SpotTypeUnderEdit: localStorageState.SpotTypeUnderEdit
            }
        } else {
            this.state = {
                SpotTypeUnderEdit: {},
            }
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.company.uuid !== this.props.company.uuid) {
            this.tableRef.current.fetchData();
        }
    }
    componentWillUnmount() {
        localStorage.setItem("poweryar-spot-type-settings", JSON.stringify({
            enabledFilter: this.state.enabledFilter,
            isDefaultFilter: this.state.isDefaultFilter,
            SpotTypeUnderEdit: this.state.SpotTypeUnderEdit,
        }))
    }

    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    EditNewSpotType() {
        this.setState({
            showModal: true,
            SpotTypeUnderEdit: {
                name: "",
                def: false,
                enabled: true,
                isNew: true
            }
        });
    }
    renderModal() {
        return (
            <SpotTypeEdit
                SpotTypeUnderEdit={this.state.SpotTypeUnderEdit}
                loaded={this.state.loaded}
                cancel={() => this.cancelDialog()}
                save={(a, onSuccess, onError) => { this.saveSpotType(a, onSuccess, onError); }}
                onChange={(state) => {
                    this.setState({ SpotTypeUnderEdit: state })
                }}
                showModal={this.state.showModal}
            />
        );
    }
    cancelDialog() {
        this.setState({ showModal: false, SpotTypeUnderEdit: {} });
    }
    EditSpotType(SpotType) {
        this.setState({ showModal: true, SpotTypeUnderEdit: SpotType })
    }
    saveSpotType(asset, onSuccess, onError) {
        this.setState({ loaded: true })
        saveSpotType(asset, (response) => {
            this.setState({ loaded: false })
            if (response.code === '1000') {
                showError(response.message);
            } else {
                showInfo("Spot Type Saved");
                this.tableRef.current.fetchData();
                this.props.onChange(this.state);
                onSuccess();
            }
            this.setState({ showModal: false, SpotTypeUnderEdit: {} });
        }, (failure) => {
            showError(failure.message);
            onError();
        });
    }
    clearFilters() {
        this.enabledFilterRef.current.value = undefined;
        this.isDefaultFilterRef.current.value = undefined;
        this.setState({
            enabledFilter: undefined, isDefaultFilter: undefined,
            trackerTypeFilter: undefined
        })
    }
    render() {
        return (
            <div>
                <div className="column-wrapper text-left">
                    <Loader show={this.state.loaded} message={this.spinner}>
                        <PaginationFilterTable
                            setloaded={(check) => {
                                this.setState({ loaded: check });
                                this.updateSpinner("");
                            }}
                            onFilterHide={() => {
                                this.setState({
                                    enabledFilter: "undefined",
                                    isDefaultFilter: "undefined"
                                });
                            }}
                            openFiltersCollaps={true}
                            ref={this.tableRef}
                            pageType="poweryard-settings"
                            get={downloadSpotTypesForTable}
                            stateStorage="poweryar-spot-type-settings"
                            clearFilter={this.clearFilters.bind(this)}
                            renderModal={this.renderModal()}
                            columns={this.tableColumns}
                        >
                            <Button color="primary" onClick={() => this.EditNewSpotType()}> <i className="fa fa-plus" /> Add New Spot Type</Button>
                        </PaginationFilterTable>
                    </Loader>

                </div>

            </div>
        )
    }
}
export default SpotTypes;