import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router';
import {
    downloadShipmentProfile,
    downloadShipmentStatuses,
    promiseAddressByPrefix,
    saveShipmentStatusEvent,
    updateShipmentStatusEvent,
    downloadShipmentTAProfile,
    searchPreviousShipments,
    searchNextShipments
} from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import ShipmentEvents from "./ShipmentEvents";
import AssetList from "./AssetList";
import ShipmentProfileProgressBar from "./ShipmentProfileProgressBar";
import ShipmentTAProfileProgressBar from "../../TrackAssured/components/ShipmentTAProfileProgressBar";
import ShipmentTAProfileProgressBarBasic from "../../TrackAssured/components/ShipmentTAProfileProgressBarBasic";
import Select from "react-select";
import Badge from "reactstrap/es/Badge";
import * as PropTypes from "prop-types";
import * as queryString from "query-string";
import MapView from "../../PingAssets/components/MapView";
import FFFormDateTimeField from "../../common/formik-free/FFFormDateTimeField";
import FFFormRadioField from "../../common/formik-free/FFFormRadioField";
import { CHECK_CALL, ON_SITE_STATUSES } from "../../common/constants";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import Switch from "react-switch";
class ReferenceDisplay extends Component {
    render() {
        return <div><Badge>{this.props.reference.type.name}</Badge> {this.props.reference.value}</div>
    }
}

ReferenceDisplay.propTypes = { reference: PropTypes.any };

class ShipmentProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: true,
            loaded: false,
            shipmentProfile: null,
            shipmentProfileStepsTemp: null,
            detailEnabled: true,
            shipmentStatus: "",
            stopData: [],
            track: false,
            searchByTracking: "",
            trackedShipments: [],
            openSections: {
                "map": true,
                "location": true,
                "events": true,
                "eztrackEvents": true,
                "assets": true
            },
            statusMin: -1,
            statusMax: 100,
            nextArrivalEventStatus: {},
            nextEventStatus: {},
            mapFilter: "Origin & Destination",
            mapSize: { height: "500px", width: "500px" },
            currentDispatch: this.props.match.params.duuid
        }
        this.updateSpinner("Loading ...");
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        let tempArr = JSON.parse(localStorage.getItem('shipments'));
        this.downloadShipmentProfile();
        this.downloadShipmentStatuses();
        window.addEventListener('resize', this.getMapSize.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.getMapSize.bind(this));
    }

    downloadShipmentProfile() {
        let companyUuid = this.getCompanyRequestUuid();
        let trackObj = this.getTrackingObject();
        trackObj.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        downloadShipmentTAProfile(this.props.match.params.uuid, companyUuid, trackObj, (shipmentProfile) => {
            let nextEventStatus = {};
            let nextArrivalEventStatus = this.state.nextArrivalEventStatus;
            if (this.state.shipmentStatuses) {
                nextEventStatus = this.state.shipmentStatuses.filter((s) => { return s.status > shipmentProfile.ShipmentProfile.status })[0];
                nextArrivalEventStatus = this.state.shipmentStatuses.filter((s) => {
                    return (s.status > shipmentProfile.ShipmentProfile.status &&
                        ON_SITE_STATUSES.includes(s.status))
                })[0];
            }
            let activeStatus = shipmentProfile.ShipmentProfile.shipmentProfileProgress.steps.find(it => it.active === true);
            if (activeStatus.event !== null) {
                this.setState({
                    shipmentStatus: activeStatus ? activeStatus.event.shipmentStatus.statusName : null
                });
            }
            this.setState({
                loading: false, trackType: shipmentProfile.TrackedShipments[0].trackType, trackID: shipmentProfile.TrackedShipments[0].trackID, shipmentProfile: shipmentProfile.ShipmentProfile, nextEventStatus: nextEventStatus,
                nextArrivalEventStatus: nextArrivalEventStatus, stopData: shipmentProfile.Stops,shipmentStatus: shipmentProfile.ShipmentStatus
            });
            // this.state.shipmentProfile.status = shipmentProfile.ShipmentStatus;
            const tempProfile = JSON.stringify(shipmentProfile.ShipmentProfile.shipmentProfileProgress);
            let check = localStorage.getItem(this.props.user.username + "-viewswitch");
            if (check) {
                check = JSON.parse(check);
                this.setState({ detailEnabled: check });
                if (check === false) {
                    let s = this.state.shipmentProfile;
                    // let dddd=this.state.shipmentstatus
                    let sd = this.state.stopData;
                    let tempArr = this.getBasicView(s, sd);
                    s.shipmentProfileProgress.steps = tempArr;
                    this.setState({ shipmentProfile: s, shipmentProfileStepsTemp: JSON.parse(tempProfile) });
                }
            } else {
                this.setState({ detailEnabled: true });
            }
            this.getAppointmentDate(1);
            this.getAppointmentDate(4);
            this.getMapSize();
            this.generateMarkers();
            this.generateMarkersForEvents();
        }, (error) => {
            showError("Unable to load shipment.");
        })
    }

    downloadShipmentStatuses() {
        downloadShipmentStatuses((statuses) => {
            statuses.sort();
            let nextArrivalEventStatus = statuses.filter((s) => { return ON_SITE_STATUSES.includes(s.status) })[0];
            let nextEventStatus = {};
            if (this.state.shipmentProfile) {
                nextEventStatus = statuses.filter((s) => { return s.status > this.state.shipmentProfile.status })[0];
                nextArrivalEventStatus = statuses.filter((s) => {
                    return (s.status > this.state.shipmentProfile.status &&
                        ON_SITE_STATUSES.includes(s.status))
                })[0];
            }
            this.setState({
                shipmentStatuses: statuses, nextArrivalEventStatus: nextArrivalEventStatus,
                nextEventStatus: nextEventStatus
            });
        }, (error) => {
            showError("Unable to load shipment statuses.");
        })
    }
    getCompanyRequestUuid() {
        let company = this.props.user.company;
        let companyUuid = company.uuid;
        const values = queryString.parse(this.props.location.search);

        if (values.company) {
            companyUuid = values.company;
        }
        return companyUuid;
    }
    getTrackingObject() {
        var values = this.props.location.pathname;
        values = values.split("/");
        values = values.filter(it => it !== "");
        let obj = {
            trackType: values[3],
            trackID: values[4]
        }
        return obj;
    }
    classForSectionButton(section) {
        if (this.state.openSections[section]) {
            return "fa fa-minus";
        } else {
            return "fa fa-plus"
        }
    }

    sectionOpen(section) {
        return this.state.openSections[section];
    }

    toggleSection(section) {
        let sections = Object.assign({}, this.state.openSections); // copy sections
        if (sections[section]) {
            sections[section] = false;
        } else {
            sections[section] = true;
        }
        this.setState({ openSections: sections });
    }

    statusInRangeForMap(status) {
        return status >= this.state.statusMin && status <= this.state.statusMax;
    }
    generateMarkers() {
        let markers = [];
        if (this.state.shipmentProfile.source && this.state.shipmentProfile.source.latitude &&
            this.state.shipmentProfile.source.longitude && this.statusInRangeForMap(4)) {
            markers.push({
                title: "Origin: " + this.state.shipmentProfile.source.name,
                position: {
                    lat: this.state.shipmentProfile.source.latitude,
                    lng: this.state.shipmentProfile.source.longitude
                },
                name: "origin",
                zIndex: 0
            });
        }
        if (this.state.shipmentProfile.destination && this.state.shipmentProfile.destination.latitude &&
            this.state.shipmentProfile.destination.longitude && this.statusInRangeForMap(12)) {
            markers.push({
                title: "Destination: " + this.state.shipmentProfile.destination.name,
                position: {
                    lat: this.state.shipmentProfile.destination.latitude,
                    lng: this.state.shipmentProfile.destination.longitude
                },
                name: "destination",
                zIndex: 0
            });
        }
        return markers;
    }
    generateMarkersForEvents() {
        let events = [];
        let textMessageEvents = this.state.shipmentProfile.textMessageEvents;
        for (let i = 0; i < textMessageEvents.length; i++) {
            let tme = textMessageEvents[i];
            if (tme.latitude && tme.longitude && this.statusInRangeForMap(tme.status)) {
                tme.shipmentNumber = this.state.shipmentProfile.shipment.assetId;
                let icon = tme.eventDescription === "EZTrack" ? "/assets/img/icons/truck-orange.png" : "/assets/img/icons/truck.png";
                events.push({
                    title: tme.eventDescription,
                    position: { lat: tme.latitude, lng: tme.longitude },
                    name: "tme:" + i,
                    event: tme,
                    icon: icon,
                    zIndex: i + 1000000,
                    supportMouseOver: true,
                });
            }
        }
        return events;
    }

    mapFilterChange(filter) {
        let min = -1;
        let max = 99;
        if (filter === "Origin & Destination") {
        } else if (filter === "Destination") {
            min = 8;
        } else if (filter === "Origin") {
            max = 7.1;
        }
        this.setState({ mapFilter: filter, statusMin: min, statusMax: max })
    }

    getMapSize = () => {
        let w = window.innerWidth;
        let s = document.getElementById("app-sidebar");
        if (s === null) {
            this.setState({ mapSize: { height: "500px", width: "500px" } });
            return { height: "500px", width: "500px" }
        }
        w = w - s.offsetWidth - 145; // 40 for card padding, 2 for card border, 34 for page padding, 13 for scroll bar
        let d = document.getElementById("shipment-map-container");
        if (d === null) {
            this.setState({ mapSize: { height: "500px", width: "500px" } });
            return { height: "500px", width: "500px" }
        }
        let h = d.offsetHeight - 25;
        for (let i = 0; i < d.children.length - 1; i++) {
            h = h - d.children[i].offsetHeight;
        }
        if (h < 500) {
            h = 500;
        }
        this.setState({ mapSize: { height: h, width: w } });
        return { height: h, width: w };
    }
    addNewEvent() {
        this.setState({ addEventModalOpen: true, errors: {}, touched: {}, newEvent: { date: new Date(), eventType: CHECK_CALL } });
    }
    cancelEditEvent() {
        this.setState({
            editEventModalOpen: false,
            errors: {},
            touched: {}
        });
    }
    cancelAddNewEvent() {
        this.setState({
            addEventModalOpen: false,
            errors: {},
            touched: {}
        });
    }
    getEventTypeOptions() {
        let options = [{ name: CHECK_CALL, value: CHECK_CALL }];
        if (this.state.nextArrivalEventStatus) {
            options.push({ name: this.state.nextArrivalEventStatus.statusName, value: this.state.nextArrivalEventStatus.statusName });
        }
        return options;
    }
    getTime(stepStr){
        let seperatedArr = stepStr.split("\n");
          seperatedArr = seperatedArr.filter(it => it !== "" && it !== "&nbsp;");
          return seperatedArr[1];
        }
        getAppointmentDate(index){
            let s = this.state.stopData;
            if(index === 1){
              let stopappointment = s[0].appointment;
              let stopTimezone = s[0].location ? s[0].location.timezone: null;
              let stopgraceInMins = this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert.graceInMins: null;
              this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert = {
                appointment : stopappointment,
                timezone : stopTimezone,
                graceInMins : stopgraceInMins
              };
            }
            if(index === 4){
              let stopappointment = s[s.length -1].appointment;
              let stopTimezone = s[s.length -1].location ? s[s.length -1].location.timezone: null;
              let stopgraceInMins = this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert? this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert.graceInMins: null;
              this.state.shipmentProfile.shipmentProfileProgress.steps[index].alert.arrivalAlert = {
                appointment : stopappointment,
                timezone : stopTimezone,
                graceInMins : stopTimezone,
                graceInMins : stopgraceInMins
              };
            }
            }
    getBasicView(profile, totalStops) {
        console.log(profile, totalStops);
        let activeStepsArr = profile.shipmentProfileProgress.steps[3];
        let stopArr = [];
        for (var obj in totalStops) {
          stopArr.push(totalStops[obj]);
        }
    
        stopArr.map((it) => (it.active = false));
        const length = stopArr.length;
        let currentStop = profile.shipment.currentStop;
    
        let firstStop = stopArr[0];
        if (firstStop.location !== null) {
          firstStop.stepText =
            firstStop.typeOfStop +
            "\n\nArrival Time:" +
            this.getTime(profile.shipmentProfileProgress.steps[1].stepText) +
            "\n\n**" +
            firstStop.location.name +
            "**\n\n" +
            firstStop.location.code +
            "\n\n" +
            firstStop.location.address1 +
            "\n\n \n\n" +
            firstStop.location.city +
            ", " +
            firstStop.location.state +
            " " +
            firstStop.location.postal +
            "\n\n" +
            firstStop.location.country;
        } else {
          firstStop.stepText =
            firstStop.typeOfStop +
            "\n\nArrival Time:" +
            this.getTime(profile.shipmentProfileProgress.steps[1].stepText)+
            "\n\n**" +
            "N/A" +
            "**\n\n" +
            "N/A" +
            "\n\n" +
            "N/A" +
            "\n\n \n\n" +
            "N/A" +
            ", " +
            "N/A" +
            " " +
            "N/A" +
            "\n\n" +
            "N/A";
        }
        firstStop.active = false;
        firstStop.showLocation = true;
        let lastStop = stopArr[length - 1];
        if (lastStop.location !== null) {
          lastStop.stepText =
            lastStop.typeOfStop +
            "\n\nArrival Time:" +
            this.getTime(profile.shipmentProfileProgress.steps[4].stepText) +
            "\n\n**" +
            lastStop.location.name +
            "**\n\n" +
            lastStop.location.code +
            "\n\n" +
            lastStop.location.address1 +
            "\n\n \n\n" +
            lastStop.location.city +
            ", " +
            lastStop.location.state +
            " " +
            lastStop.location.postal +
            "\n\n" +
            lastStop.location.country;
        } else {
          lastStop.stepText =
          lastStop.typeOfStop +
            "\n\nArrival Time:" +
            this.getTime(profile.shipmentProfileProgress.steps[4].stepText) +
            "\n\n**" +
            "N/A" +
            "**\n\n" +
            "N/A" +
            "\n\n" +
            "N/A" +
            "\n\n \n\n" +
            "N/A" +
            ", " +
            "N/A" +
            " " +
            "N/A" +
            "\n\n" +
            "N/A";
        }
    
        lastStop.active = false;
        lastStop.showLocation = true;
        if (currentStop === 0) {
          if (profile.status > 3 && profile.status < 7) {
            firstStop.active = true;
            firstStop.showGrey = false;
            lastStop.showGrey = false;
          } else {
            firstStop.active = false;
            firstStop.showGrey = true;
            lastStop.showGrey = true;
          }
        }
        if (currentStop > 0 && currentStop === length) {
          lastStop.active = true;
        }
        let arr = [];
        arr.push(firstStop);
        if (currentStop > 0 && currentStop <= length) {
          let doneStop = stopArr[currentStop - 1];
          if (activeStepsArr.locationUpdates === null) {
            if(doneStop.location !== null){
              activeStepsArr.stopLocation =
              "**" +
              doneStop.location.name +
              "**\n" +
              doneStop.location.code +
              "\n\n" +
              doneStop.location.address1 +
              "\n\n \n\n" +
              doneStop.location.city +
              ", " +
              doneStop.location.state +
              " " +
              doneStop.location.postal +
              "\n\n" +
              doneStop.location.country;
            } else {
              activeStepsArr.stopLocation =
              "**" +
              "N/A"+
              "**\n" +
              "N/A"+
              "\n\n" +
              "N/A"+
              "\n\n \n\n" +
              "N/A"+
              ", " +
              "N/A"+
              " " +
              "N/A"+
              "\n\n" +
              "N/A";
            }
          } else {
            if (activeStepsArr.locationUpdates.length === 0) {
              if(doneStop.location !== null){
                activeStepsArr.stopLocation =
                "**" +
                doneStop.location.name +
                "**\n" +
                doneStop.location.code +
                "\n\n" +
                doneStop.location.address1 +
                "\n\n \n\n" +
                doneStop.location.city +
                ", " +
                doneStop.location.state +
                " " +
                doneStop.location.postal +
                "\n\n" +
                doneStop.location.country;
              } else {
                activeStepsArr.stopLocation =
                "**" +
                "N/A"+
                "**\n" +
                "N/A"+
                "\n\n" +
                "N/A"+
                "\n\n \n\n" +
                "N/A"+
                ", " +
                "N/A"+
                " " +
                "N/A"+
                "\n\n" +
                "N/A";
              }
            } else {
              activeStepsArr.stopLocation =
                activeStepsArr.locationUpdates[0].geocodeLocation.fullLocation;
            }
          }
          activeStepsArr.completedInTwoSteps = true;
    
          arr.push(activeStepsArr);
        }
    
        arr.push(lastStop);
        return arr;
        // if()
      }
    render() {
        if (this.state.loading) {
            return <div>Loading...</div>
        }
        return (
            <Loader show={this.state.loaded} message={this.spinner}>
                <div className="text-left p-3">
                    {/* <h1>Shipment {this.state.shipmentProfile.shipment.assetId}</h1>
                {
                    this.state.shipmentProfile.shipment.references &&  this.state.shipmentProfile.shipment.references.map(r =>{
                        return (<ReferenceDisplay reference={r}/>);
                    })
                }
                <br/>
                Carriers: {this.getCarrier()}{this.getBroker()}{this.getTsps()}<br/>
                <strong>Status:</strong> {this.state.shipmentProfile.status} - {this.state.shipmentProfile.statusName}
               */}
                    <Card>

                        <CardBody>
                            {/* <div className={"btn-navigation"}>
                                <Button onClick={() => {
                                    let trackedShipments = localStorage.getItem('shipments');
                                    if (trackedShipments) {
                                        let tempArr = JSON.parse(trackedShipments);
                                        let index = tempArr.findIndex(it => it.assetID === this.state.shipmentProfile.shipment.assetId);
                                        if (index > -1 && index !== 0) {
                                            this.props.history.push("/shipment-profile/" + tempArr[index - 1].assetID + "/" + tempArr[index - 1].dispatchUUID);
                                        }
                                        if (index == 0) {
                                            showInfo("No More data to show.");
                                        }
                                    } else {
                                        this.setState({
                                            loaded: true,
                                        });
                                        let s = { ...this.state.currentDispatch };
                                        let p = { ...this.props };
                                        let companyUid = p.user.company.uuid;
                                        let str = "";
                                        const keys = Object.keys(s);
                                        for (const key of keys) {
                                            str += s[key];
                                        }
                                        var data = { "page": 0, "pageSize": 20, "sorted": [], "filtered": [] };
                                        searchPreviousShipments(companyUid, str, data, (response) => {
                                            // console.log(response);
                                            this.setState({
                                                loaded: false,
                                            });
                                            this.props.history.push("/shipment-profile/" + response.assetID + "/" + response.dispatchUUID);
                                        });
                                    }
                                }}><i className="fa fa-chevron-left"></i></Button>
                                <Button className={"float-right"} onClick={() => {
                                    let trackedShipments = localStorage.getItem('shipments');
                                    if (trackedShipments) {
                                        let tempArr = JSON.parse(trackedShipments);
                                        let index = tempArr.findIndex(it => it.assetID === this.state.shipmentProfile.shipment.assetId);
                                        if (index > -1 && index < (tempArr.length - 1)) {
                                            this.props.history.push("/shipment-profile/" + tempArr[index + 1].assetID + "/" + tempArr[index + 1].dispatchUUID);
                                        } if (tempArr.length == (index + 1)) {
                                            showInfo("No More data to show.");
                                        }
                                    } else {
                                        this.setState({
                                            loaded: true,
                                        });
                                        let s = { ...this.state.currentDispatch };
                                        let p = { ...this.props };
                                        let companyUid = p.user.company.uuid;
                                        let str = "";
                                        const keys = Object.keys(s);
                                        for (const key of keys) {
                                            str += s[key];
                                        }
                                        var data = { "page": 0, "pageSize": 20, "sorted": [], "filtered": [] };
                                        searchNextShipments(companyUid, str, data, (response) => {
                                            this.setState({
                                                loaded: false,
                                            });
                                            this.props.history.push("/shipment-profile/" + response.assetID + "/" + response.dispatchUUID);
                                        });
                                    }
                                }}><i className="fa fa-chevron-right"></i></Button>
                            </div> */}
                            {this.state.stopData.length > 1 &&
                                <div className="mb-4">
                                    <div className={"float-right"}>
                                        <label>Basic View</label>&nbsp;
                                        <Switch
                                            offHandleColor="#51B32E"
                                            onHandleColor="#51B32E"
                                            offColor="#d5d3d3"
                                            onColor="#d5d3d3"

                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            onChange={(checked) => {
                                                let s = { ...this.state.shipmentProfile };
                                                let d = { ...this.state.detailEnabled };
                                                let p = { ...this.state.shipmentProfileStepsTemp }
                                                let totalStops = { ...this.state.stopData };
                                                localStorage.setItem(this.props.user.username + "-viewswitch", checked);
                                                if (checked == false) {
                                                    d = false;
                                                    let allsteps = JSON.stringify(s.shipmentProfileProgress);
                                                    let tempArr = this.getBasicView(s, totalStops);
                                                    s.shipmentProfileProgress.steps = tempArr;
                                                    this.setState({ shipmentProfile: s, detailEnabled: d, shipmentProfileStepsTemp: JSON.parse(allsteps) });


                                                } else {
                                                    d = true;
                                                    s.shipmentProfileProgress = p;
                                                    this.setState({ shipmentProfile: s, detailEnabled: d, shipmentProfileStepsTemp: null });
                                                }
                                            }}
                                            checked={this.state.detailEnabled}
                                            title="Show Filter"
                                            className="react-switch" /> &nbsp;
                                        <label>Detailed View</label>
                                    </div>
                                    <strong >{this.state.trackType.toLowerCase()} : {this.state.trackID.toLowerCase()}</strong>
                                </div>

                            }

                            <h3 className={"font-weight-bold text-center mt-4"}>Current Status : {this.state.shipmentStatus}</h3>
                            {this.state.shipmentProfile.shipmentProfileProgress && this.state.detailEnabled === true &&
                                <ShipmentTAProfileProgressBar progress={this.state.shipmentProfile.shipmentProfileProgress} cancelled={this.state.shipmentStatus} laststop={this.state.shipmentProfile.destination} currentLatLong={this.state.shipmentProfile.textMessageEvents} user={this.props.user} />}
                            {this.state.shipmentProfile.shipmentProfileProgress && this.state.detailEnabled === false &&
                                <ShipmentTAProfileProgressBarBasic progress={this.state.shipmentProfile.shipmentProfileProgress} cancelled={this.state.shipmentStatus} laststop={this.state.shipmentProfile.destination} currentLatLong={this.state.shipmentProfile.textMessageEvents} user={this.props.user} />}
                        </CardBody>
                    </Card>


                    <Card>
                        <CardHeader><Button onClick={() => this.toggleSection("map")}>
                            <i className={this.classForSectionButton("map")} /></Button> Map</CardHeader>
                        {this.sectionOpen("map") && <CardBody>
                            <div className="map-header">
                                <Select onChange={e => this.mapFilterChange(e.value)}
                                    value={{ value: this.state.mapFilter, label: this.state.mapFilter }}
                                    classNamePrefix="simple_select"
                                    options={["Origin & Destintation", "Origin", "Destination"].map((v) => {
                                        return { value: v, label: v }
                                    })}
                                >
                                </Select>
                            </div>
                            <div id="shipment-map-container" style={{
                                height: "500px"
                            }}>
                                <MapView
                                    onMarkerClick={(marker) => {
                                        // figure out if it's origin or destination or TME and highlight
                                        console.log(marker);
                                    }}
                                    lat={this.state.lat}
                                    lng={this.state.lng}
                                    zoom={this.state.zoom}
                                    getMapSize={this.getMapSize.bind(this)}
                                    mapHeight={this.state.mapSize.height}
                                    mapWidth={this.state.mapSize.width}
                                    boundsMarkers={this.generateMarkers().concat(this.generateMarkersForEvents())}
                                    markers={this.generateMarkers().concat(this.generateMarkersForEvents())}
                                    showAssetPopup={false}
                                    showShipmentEventPopup={true}
                                />
                            </div>
                        </CardBody>}
                    </Card>
                    {this.state.addEventModalOpen &&
                        <Modal isOpen={this.state.addEventModalOpen} size={'lg'}>
                            <ModalHeader>Add Event</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={(event) => {
                                    event.preventDefault();
                                    console.log('Form submitted.', this.state.newEvent);
                                    let statusEvent = { eventDate: this.state.newEvent.date, statusMessage: this.state.newEvent.eventType };
                                    if (ON_SITE_STATUSES.includes(this.state.shipmentProfile.status)) {
                                        statusEvent.status = this.state.nextEventStatus.status;
                                        statusEvent.statusMessage = this.state.nextEventStatus.statusName;
                                    } else if (this.state.newEvent.eventType === CHECK_CALL) {
                                        statusEvent.latitude = this.state.newEvent.address.latitude;
                                        statusEvent.longitude = this.state.newEvent.address.longitude;
                                        let cmpstatus = { ...this.state.shipmentProfile.shipment };
                                        if (this.state.shipmentProfile.shipment.status == 12) {
                                            statusEvent.status = 12;
                                        }
                                        else if (this.state.shipmentProfile.shipment.status <= 3) {
                                            statusEvent.status = 3;
                                        } else {
                                            statusEvent.status = 7.1;
                                        }
                                    } else {
                                        statusEvent.status = this.state.nextArrivalEventStatus.status;
                                    }
                                    saveShipmentStatusEvent(this.state.shipmentProfile.shipment.uuid, statusEvent, () => {
                                        showInfo("Shipment Status Event saved.");
                                        this.setState({ addEventModalOpen: false });
                                        this.downloadShipmentProfile();
                                    }, () => {
                                        showError("Shipment Status Event not saved.");
                                    })
                                }}>
                                    <Card className="simple-card">
                                        <CardBody>
                                            <FFFormDateTimeField
                                                label={"Event Time"}
                                                placeholder="Select a time for the event"
                                                icon="fa-calendar"
                                                fieldname="date"
                                                error={this.state.errors.date}
                                                touched={this.state.touched.date}
                                                onBlur={() => {
                                                    console.log("Date field onBlur.");
                                                }}
                                                value={this.state.newEvent.date}
                                                timezone={this.state.location && this.state.location.timezone}
                                                isDisabled={this.state.disabled}
                                                className={"stop-datepicker"}
                                                onChange={(date) => {
                                                    let e = this.state.newEvent;
                                                    e.date = date;
                                                    this.setState({ newEvent: e });
                                                }}
                                            />
                                            {this.state.shipmentProfile && !ON_SITE_STATUSES.includes(this.state.shipmentProfile.status) &&
                                                <>
                                                    <FFFormRadioField
                                                        label="Type:"
                                                        icon="fa-phone"
                                                        fieldname="eventType"
                                                        options={this.getEventTypeOptions()}
                                                        error={this.state.errors.eventType}
                                                        value={this.state.newEvent.eventType}
                                                        touched={this.state.touched.eventType}
                                                        onChange={(v) => {
                                                            let e = this.state.newEvent;
                                                            e.eventType = v;
                                                            this.setState({ newEvent: e });
                                                        }}
                                                    />
                                                    {this.state.newEvent.eventType === CHECK_CALL &&
                                                        <FFFormAsyncSelectField
                                                            fieldname="address"
                                                            icon="fa-map-marker-alt"
                                                            label="Location"
                                                            placeholder="Type the address"
                                                            loadOptions={promiseAddressByPrefix}
                                                            defaultOptions={false}
                                                            required={true}
                                                            value={this.state.newEvent.address ? this.state.newEvent.address.fullLocation : ''}
                                                            isClearable
                                                            onChange={(v) => {
                                                                let e = { ...this.state.newEvent };
                                                                e.address = v ? v : "";
                                                                this.setState({ newEvent: e });
                                                            }}
                                                            // onBlur={() => {
                                                            //     let errors = this.validate(this.validationSchema.bind(this))(this.state.assetUnderEdit);
                                                            //     let touched = {...this.state.touched};
                                                            //     touched.baseEquipment = true;
                                                            //     this.setState({errors: errors, touched: touched})
                                                            // }}
                                                            error={this.state.errors.address}
                                                            touched={this.state.touched.address}
                                                        />
                                                    }
                                                </>
                                            }
                                            {this.state.shipmentProfile && ON_SITE_STATUSES.includes(this.state.shipmentProfile.status) &&
                                                <FFFormTextField
                                                    fieldname={'eventType'}
                                                    icon="fa-tag"
                                                    value={this.state.nextEventStatus.statusName}
                                                    disabled={true}
                                                    label={'Type'} />
                                            }
                                        </CardBody>
                                    </Card>
                                    <div className="mb-3 text-right">
                                        <Button color="primary" onClick={() => this.cancelAddNewEvent()}>Cancel</Button> &nbsp;
                                        <Button color="primary" type="submit">Save</Button>
                                    </div>
                                </Form>
                            </ModalBody>
                        </Modal>
                    }
                    {this.state.editEventModalOpen &&
                        <Modal isOpen={this.state.editEventModalOpen} size={'lg'}>
                            <ModalHeader>Edit Event</ModalHeader>
                            <ModalBody>
                                <Form onSubmit={(event) => {
                                    event.preventDefault();
                                    updateShipmentStatusEvent(this.state.eventToEdit.uuid, this.state.eventToEdit.date, () => {
                                        showInfo("Shipment Status Event saved.");
                                        this.setState({ editEventModalOpen: false, eventToEdit: undefined });
                                        this.downloadShipmentProfile();
                                    }, () => {
                                        showError("Shipment Status Event not saved.");
                                    })
                                }}>
                                    <Card className="simple-card">
                                        <CardBody>
                                            <FFFormDateTimeField
                                                label={"Event Time"}
                                                placeholder="Select a time for the event"
                                                icon="fa-calendar"
                                                fieldname="date"
                                                error={this.state.errors.date}
                                                touched={this.state.touched.date}
                                                onBlur={() => {
                                                    console.log("Date field onBlur.");
                                                }}
                                                value={this.state.eventToEdit.date}
                                                timezone={this.state.location && this.state.location.timezone}
                                                isDisabled={this.state.disabled}
                                                className={"stop-datepicker"}
                                                onChange={(date) => {
                                                    let e = this.state.eventToEdit;
                                                    e.date = date;
                                                    this.setState({ eventToEdit: e });
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                    <div className="mb-3 text-right">
                                        <Button color="primary" onClick={() => this.cancelEditEvent()}>Cancel</Button> &nbsp;
                                        <Button color="primary" type="submit">Save</Button>
                                    </div>
                                </Form>
                            </ModalBody>
                        </Modal>
                    }
                    <Card>
                        <CardHeader>
                            <Button onClick={() => this.toggleSection("events")}>
                                <i className={this.classForSectionButton("events")} />
                            </Button>
                            Events
                            <Button style={{ float: 'right' }} onClick={() => this.addNewEvent()}>Add Event</Button>
                        </CardHeader>
                        {this.state.shipmentProfile.textMessageEvents && this.state.shipmentProfile.textMessageEvents.length > 0 ?
                            <CardBody>
                                {this.sectionOpen("events") &&
                                    <ShipmentEvents events={this.state.shipmentProfile.textMessageEvents}
                                        onEdit={(event) => {
                                            this.setState({ editEventModalOpen: true, eventToEdit: event, errors: {}, touched: {} });
                                        }}
                                    />}

                                {this.hasForcedDispatch(this.state.shipmentProfile.textMessageEvents) &&
                                    <Alert color="warning">
                                        *  Indicates that this was not a GPS verified timestamp as a user manually set the time for the asset at this location.
                                    </Alert>}
                            </CardBody>
                            : ""}
                    </Card>
                    <Card>
                        <CardHeader><Button onClick={() => this.toggleSection("assets")}>
                            <i className={this.classForSectionButton("assets")} /></Button> Associated Assets</CardHeader>
                        {this.sectionOpen("assets") && <CardBody>
                            <AssetList displayShipmentProfile={(shipmentId) => this.displayShipmentProfile(shipmentId)}
                                assets={this.state.shipmentProfile.associatedAssets}
                                user={this.props.user}
                                requestCompanyUuid={this.getCompanyRequestUuid()}
                            />
                        </CardBody>}
                    </Card>
                </div>
            </Loader>
        );
    }

    getBroker() {
        if (this.state.shipmentProfile.shipment) {
            if (this.state.shipmentProfile.shipment.broker) {
                return ", " + this.state.shipmentProfile.shipment.broker;
            } else {
                if (this.state.shipmentProfile.gateEvents && this.state.shipmentProfile.gateEvents.length > 0 && this.state.shipmentProfile.gateEvents[0].broker) {
                    return this.state.shipmentProfile.gateEvents[0].broker.name;
                } else {
                    return "";
                }
            }
        } else {
            return "";
        }
    }

    getCarrier() {
        if (this.state.shipmentProfile.shipment) {
            if (this.state.shipmentProfile.shipment.carrier) {
                return this.state.shipmentProfile.shipment.carrier.name;
            } else {
                if (this.state.shipmentProfile.gateEvents && this.state.shipmentProfile.gateEvents.length > 0 && this.state.shipmentProfile.gateEvents[0].carrier) {
                    return this.state.shipmentProfile.gateEvents[0].carrier.name;
                } else {
                    return "";
                }
            }
        } else {
            return "";
        }
    }

    getTsps() {
        let providers = "";
        if (this.state.shipmentProfile.transportationServiceProviders !== null &&
            typeof this.state.shipmentProfile.transportationServiceProviders[Symbol.iterator] === 'function') {
            for (const provider of this.state.shipmentProfile.transportationServiceProviders) {
                providers += provider + " "
            }
        }
        return providers;
    }

    displayShipmentProfile(shipmentId) {
        this.props.history.push("/shipment-profile/" + shipmentId);
    }

    hasForcedDispatch(textMessageEvents) {
        if (textMessageEvents) {
            for (const textMessageEvent of textMessageEvents) {
                if (textMessageEvent.forcedDeparture) return true;
            }
        }
        return false;
    }
}


export default withRouter(ShipmentProfile);