import React, {Component} from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import {promiseTrailerByIdPrefixAndTagEZTrackAssets,} from "../../NetworkHelpers";
import * as PropTypes from "prop-types";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectStateField from "../../common/formik-free/FFFormSelectStateField";
import * as uuid from "uuid";

export class DispatchTrailerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            touched: {}
        }
    }

    onChange(field, value) {
        let touched = {...this.state.touched};
        touched[field] = true;
        this.setState({touched});
        this.props.onChange(field, value);
    }
    render() {
        return <Card>
            <CardBody>
                <FFFormSelectCreatableField
                    defaultOptions
                    key={"ID_"+uuid.v4()}
                    label="Trailer Number"
                    subLabel="Start typing to lookup trailer"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-hashtag"
                    fieldname="trailerNumber"
                    error={this.props.errors.trailerNumber}
                    onBlur={this.props.onBlur}
                    touched={this.state.touched.trailerNumber}
                    value={this.props.values.trailerNumber}
                    loadOptions={promiseTrailerByIdPrefixAndTagEZTrackAssets}
                    onChange={(value) => {
                        this.onChange("trailerNumber", value ? value.value : "");
                        this.props.onChange("trailerIsEzTrack", value && value.devices && value.devices.length > 0);
                    }}
                    isDisabled={this.props.disabled}
                />
                <FFFormTextAreaField
                    label="Trailer Comment"
                    placeholder="Enter Value"
                    icon="fa-comment"
                    fieldname="trailerComment"
                    error={this.props.errors.trailerComment}
                    onBlur={this.props.onBlur}
                    touched={this.state.touched.trailerComment}
                    value={this.props.values.trailerComment}
                    disabled={this.props.disabled}
                    onChange={(event) => {
                        this.onChange("trailerComment", event.target.value);
                    }}
                />
                {this.props.advancedView &&
                <FFFormTextField
                    label="License Plate"
                    placeholder="Enter Value"
                    icon="fa-id-card"
                    fieldname="trailerLicense"
                    error={this.props.errors.trailerLicense}
                    onBlur={this.props.onBlur}
                    touched={this.state.touched.trailerLicense}
                    value={this.props.values.trailerLicense}
                    disabled={this.props.disabled}
                    onChange={(value) => {
                        this.onChange("trailerLicense", value);
                    }}
                />}
                {this.props.advancedView &&
                <FFFormSelectStateField
                    label="License State"
                    placeholder="Enter Value"
                    icon="fa-id-card"
                    fieldname="trailerLicenseState"
                    error={this.props.errors.trailerLicenseState}
                    onBlur={this.props.onBlur}
                    onChange={(value) => {
                        this.onChange("trailerLicenseState", value);
                    }}
                    touched={this.state.touched.trailerLicenseState}
                    value={this.props.values.trailerLicenseState}
                    disabled={this.props.disabled}
                    selectedCountry={"United States"}
                />}
                {this.props.advancedView &&
                <FFFormTextField
                    label="Temperature"
                    placeholder="Enter Value"
                    icon="fa-thermometer-three-quarters"
                    fieldname="trailerTemperature"
                    error={this.props.errors.trailerTemperature}
                    onBlur={this.props.onBlur}
                    touched={this.state.touched.trailerTemperature}
                    value={this.props.values.trailerTemperature}
                    disabled={this.props.disabled}
                    onChange={(value) => {
                        this.onChange("trailerTemperature", value);
                    }}
                />}
            </CardBody>
        </Card>;
    }
}

DispatchTrailerCard.propTypes = {
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    trailer: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.any,
    values: PropTypes.any,
    onChange: PropTypes.func,
    advancedView: PropTypes.any,
    fieldTouched1: PropTypes.any
};