import React, { Component } from "react";
import * as Yup from "yup";
import FFFormTextField from "../../common/formik-free/FFFormTextField";

import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import {
  convertCtoF,
  convertFtoC,
  getErrorsFromValidationError} from "../utilities";

import HelpIcon from "../HelpIcon";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class TemperatureModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
       
        taskUnderEdit: this.props.taskUnderEdit,
        errors: {},
        touched: {},
        loadedTemp: false,
      };
      this.updateSpinner("Saving...");
    }
    
    componentWillUpdate(previousProps, prevState, snapshot) {
      if(previousProps.open){
        let success = previousProps.taskUnderEdit;
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
          if(success.tempSetPoint !== null){
            success.tempSetPoint = this.getFlooredFixed(success.tempSetPoint,1);
          }
          success.actualTemp = convertCtoF(success.temperatureInC);
          if(success.actualTemp !== null){
            success.actualTemp = this.getFlooredFixed(success.actualTemp,1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          success.tempSetPoint = success.temperatureSetPointInC;
          if(success.temperatureSetPointInC !== null){
            success.tempSetPoint = this.getFlooredFixed(success.tempSetPoint,1);
          }
          success.actualTemp = success.temperatureInC;
          if(success.actualTemp !== null){
            success.actualTemp = this.getFlooredFixed(success.actualTemp,1);
          }
        }
        this.state.taskUnderEdit = previousProps.taskUnderEdit;
  
      }
    }
    updateSpinner(val) {
      this.spinner = (
        <span>
          <p className="loadingMessage">{"Saving..."}</p>
          <LoaderIco
            loaded={false}
            lines={13}
            length={20}
            width={10}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#51b32e"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </span>
      );
    }
    validate(getValidationSchema) {
      return (values) => {
        const validationSchema = getValidationSchema(values);
        try {
          validationSchema.validateSync(values, { abortEarly: false });
          return {};
        } catch (error) {
          return getErrorsFromValidationError(error);
        }
      };
    }
    validationSchema(values) {
      if (values.acceptDate && values.acceptDate === "Invalid Date") {
        values.acceptDate = null;
      }
      if (values.start && values.start === "Invalid Date") {
        values.start = null;
      }
      if (values.end && values.end === "Invalid Date") {
        values.end = null;
      }
      const NumberRegex = /^[0-9\b]+$/;
      let validationRules = {
        // type: Yup.string().required("Type is required."),
        // moveTo: Yup.object().required("Move To is required."),
        // asset: Yup.object().nullable(),
        // asset: Yup.object().required("Asset ID is required."),
        //   moveFrom: Yup.object().nullable(),
        boxingAssets: Yup.number()
          .typeError("Number must be between 1 to 9")
          .positive("Number must be between 1 to 9")
          .max(9, ({ max }) => "Number must be between 1 to 9")
          .min(1, ({ min }) => "Number must be between 1 to 9")
          .integer("Number must be between 1 to 9")
          .nullable(),
        assignee: Yup.object().nullable(),
        tempSetPoint: Yup.string()
          .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
          .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
          .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.9)')
          .nullable(true)
          .transform((_, val) => val === val ? val : null),
        actualTemp: Yup.string()
          .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
          .min(-999.9, ({ min }) => "Invalid Entry,maximum of 1 digit after the decimal (999.9)")
          .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.9)')
          .nullable(true)
          .transform((_, val) => val === val ? val : null),
  
  
  
        //   moveTo: Yup.object()
        //     .nullable()
        //     .test("equal", "Move To must not equal Move From", function (v) {
        //       const ref = Yup.ref("moveFrom");
        //       if (v == null) {
        //         return true;
        //       }
        //       if (this.resolve(ref) != null) {
        //         return v.uuid !== this.resolve(ref).uuid;
        //       } else {
        //         return true;
        //       }
        //     }),
        acceptDate: Yup.string()
          .max(
            new Date(),
            ({ max }) =>
              `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
          ),
        // acceptDate: Yup.date()
        //   .nullable()
        //   .max(
        //     new Date(),
        //     ({ max }) =>
        //       `Task Start Timestamp needs to be prior to the the Task Start Timestamp.`
        //   ),
        // start: Yup.date()
        //   .nullable()
        //   .min(
        //     Yup.ref("acceptDate"),
        //     ({ min }) =>
        //       `Task Start Timestamp needs to be after the assignment time.`
        //   ),
        // end: Yup.date()
        //   .nullable()
        //   .min(
        //     Yup.ref("start"),
        //     ({ min }) =>
        //       `Task End Timestamp needs to be after the Task Start Timestamp.`
        //   ),
      };
      return Yup.object().shape(validationRules);
    }
    getFlooredFixed(v, d) {
      return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
    }
    render() {
      return (
  
        <Modal isOpen={this.props.open} size={"lg"}>
          <Loader show={this.props.loadedTemp} message={this.spinner}>
            <ModalHeader>Temperature Controlled</ModalHeader>
            <ModalBody className={"bg-white"}>
  
              <Container>
                <FFFormTextField
                  fieldname="tempSetPoint"
                  onBlur={() => {
                    let errors = this.validate(
                      this.validationSchema.bind(this)
                    )(this.state.taskUnderEdit);
                    let touched = { ...this.state.touched };
                    touched.tempSetPoint = true;
                    this.setState({ errors: errors, touched: touched });
                  }}
                  error={this.state.errors.tempSetPoint}
                  touched={this.state.touched.tempSetPoint}
                  label={
                    "Temp - Set Point (" +
                    (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit"
                      ? "℉"
                      : "℃") +
                    ")"
                  }
                  placeholder="Enter the Set Point temperature"
                  icon="fa-temperature-high"
                  defaultValue={this.state.taskUnderEdit.tempSetPoint}
                  numbersOnly={false}
                  required={false}
                  onChange={(v) => {
                    let s = { ...this.state.taskUnderEdit };
                    s.tempSetPoint = v;
                    this.setState({ taskUnderEdit: s });
                  }}
                />
  
                <FFFormTextField
                  fieldname="actualTemp"
                  label={
                    "Temp - Actual (" +
                    (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit"
                      ? "℉"
                      : "℃") +
                    ")"
                  }
                  onBlur={() => {
                    let errors = this.validate(
                      this.validationSchema.bind(this)
                    )(this.state.taskUnderEdit);
                    let touched = { ...this.state.touched };
                    touched.actualTemp = true;
                    this.setState({ errors: errors, touched: touched });
                  }}
                  error={this.state.errors.actualTemp}
                  touched={this.state.touched.actualTemp}
                  placeholder="Enter the actual temperature"
                  icon="fa-temperature-high"
                  defaultValue={this.state.taskUnderEdit.actualTemp}
                  numbersOnly={false}
                  required={false}
                  valid={true}
                  invalid={false}
                  onChange={(v) => {
                    let s = { ...this.state.taskUnderEdit };
                    s.actualTemp = v;
                    this.setState({ taskUnderEdit: s });
                  }}
                />
  
                <Row>
                  <Col className="text-right" style={{ marginTop: "10px" }}>
                    <Button
                      color="danger"
                      onClick={() => {
  
                        let s = { ...this.props.taskUnderEdit };
                        if (
                          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
                          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
                        ) {
                          s.tempSetPoint = convertCtoF(s.temperatureSetPointInC);
                          if (s.tempSetPoint !== null) {
                            s.tempSetPoint = this.getFlooredFixed(s.tempSetPoint, 1);
                          }
                          s.actualTemp = convertCtoF(s.temperatureInC);
                          if (s.actualTemp !== null) {
                            s.actualTemp = this.getFlooredFixed(s.actualTemp, 1);
                          }
                        }
                        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
                          s.tempSetPoint = s.temperatureSetPointInC;
                          if (s.temperatureSetPointInC !== null) {
                            s.tempSetPoint = this.getFlooredFixed(s.tempSetPoint, 1);
                          }
                          s.actualTemp = s.temperatureInC;
                          if (s.actualTemp !== null) {
                            s.actualTemp = this.getFlooredFixed(s.actualTemp, 1);
                          }
                        }
                        this.state.taskUnderEdit.tempSetPoint = s.tempSetPoint;
                        this.state.taskUnderEdit.actualTemp = s.actualTemp;
                        this.setState({ touched: [], errors: [] });
                        this.props.onCancel && this.props.onCancel()
                      }
                      }
                    >
                      Cancel
                    </Button>{" "}
                    &nbsp;
                    <Button
                      color="primary"
                      // disabled={this.props.taskUnderEdit.start === undefined || this.props.taskUnderEdit.start === null || this.props.taskUnderEdit.type === undefined || this.props.taskUnderEdit.type === null || this.props.taskUnderEdit.asset === null || this.props.taskUnderEdit.asset === undefined || this.props.taskUnderEdit.carrier === undefined || this.props.taskUnderEdit.carrier === null || this.props.taskUnderEdit.moveTo === null || this.props.taskUnderEdit.moveTo === undefined}
                      onClick={() => {
                        this.updateSpinner("Saving...");
                        this.setState({
                          loadedTemp: true
                        });
                        this.onSubmit();
                      }}
  
                    >
                      Save
                    </Button>
                    {/* <HelpIcon helpMessage={"Do not leave Datetime, Task Type, Asset ID or Move To field/fields empty."} /> */}
  
                  </Col>
                </Row>
              </Container>
  
            </ModalBody>
          </Loader>
        </Modal>
  
      );
    }
  
    onSubmit() {
      this.setState({ loadedTemp: true });
      let errors = this.validate(this.validationSchema.bind(this))(
        this.state.taskUnderEdit
      );
  
      let errorsIsEmpty =
        Object.keys(errors).length === 0 &&
        errors.constructor === Object;
      if (!errorsIsEmpty || this.state.errors.name) {
        let t = { ...this.state.touched };
        t.tempSetPoint = true;
        t.actualTemp = true;
  
        this.setState({ touched: t, errors: errors, loadedTemp: false });
        return;
      }
      if (this.state.taskUnderEdit.actualTemp === undefined) {
        let s = this.props.taskUnderEdit;
        this.state.taskUnderEdit.actualTemp = s.actualTemp;
      }
      if (this.state.taskUnderEdit.tempSetPoint === undefined) {
        let s = this.props.taskUnderEdit;
        this.state.taskUnderEdit.tempSetPoint = s.tempSetPoint;
      }
  
      return (
        this.props.onSave &&
        this.props.onSave(this.state && this.state.taskUnderEdit)
      );
  
    }
  }
  
export default TemperatureModal;