import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import { PyApiFormFields } from "./PyApiFormFields";
import { showError } from '../../MainApp';
import { disableApiSetting } from '../../NetworkHelpers';
class PyApiForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editingApiSetting: this.props.editingApiSetting,
      formValues: this.props.editingApiSetting
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editingApiSetting !== prevProps.editingApiSetting) {
      this.setState(
        {
          editingApiSetting: this.props.editingApiSetting,
          formValues: this.props.editingApiSetting
        });
    }
  }

  //Latitudes range from 0 to 90. Longitudes range from 0 to 180
  validationSchema(values) {
    let validationRules = {
      locationName: Yup.string().required('Location name is required.').nullable(),
    };
    return Yup.object().shape(validationRules)
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }
  onSubmit(values, { setSubmitting, setErrors }) {
    setSubmitting(false);
    if (values.clientSecret === null) {
      showError("Please generate client secret")
    }
    else {
      console.log("values", values)
      this.props.save(values, setErrors);
      return false;
    }
  }

  handleDisableLocation() {
    let bool = !this.state.editingApiSetting.enabled;
    this.state.formValues.enabled = bool;
    let obj = { ...this.state.formValues }
    this.setState({ editingApiSetting: obj })
    // disableApiSetting(
    //   obj.uuid,
    //   (success) => {
    //     if (success.code === "200") {
    //       let response = success.content;
    //     }
    //     else if (success.code === "500") {
    //     } else if (success.code === "1000") {
    //     }
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  renderForm() {
    return (
      <Formik
        initialValues={this.state.formValues}
        // validate={this.validate(this.validationSchema.bind(this))}
        onSubmit={(v, o) => {
          this.onSubmit(v, o)
        }}
        enableReinitialize={true}
      >{
          ({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form
              autoComplete="off"
              onSubmit={(v) => {
                return handleSubmit(v)
              }}>
              <ModalBody style={{ overflow: 'auto' }}>
                <Card className="simple-card">

                  <CardBody>
                    <PyApiFormFields
                      loggedInUser={this.props.loggedInUser}
                      errors={errors}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      secret={this.state.editingApiSetting}
                      onChange={(object) => {
                        this.setState({ formValues: object });
                      }}
                      onLocationsChange={(locations) => {
                        console.log("locations", locations);
                        locations = locations.map((l) => { return { uuid: l.value, name: l.label } });
                        setFieldValue("locations", locations ? locations : []);
                        setFieldTouched("locations", true);
                      }}
                      onHeaderClick={(headerSetting) => {
                        console.log("headerSetting", headerSetting);
                        this.setState(prevState => {
                          let editingApiSetting = Object.assign({}, prevState.editingApiSetting);
                          editingApiSetting.settingHeaders = headerSetting;
                          return { editingApiSetting };
                        });
                        console.log("this.state.formValues", this.state.formValues);
                      }}
                      onWebUrlChange={(webhookUrl) => {
                        console.log("webhookUrl", webhookUrl);
                        this.setState(prevState => {
                          let formValues = Object.assign({}, prevState.formValues);
                          formValues.webhookUrl = webhookUrl;
                          return { formValues };
                        })
                        console.log("webhookUrl", this.state.formValues);
                      }}
                    />
                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter>
                <Row style={{ width: "100%" }}>
                  <Col>
                    <div className="mb-3 float-left">
                      {this.state.editingApiSetting.uuid !== null && <Button color={this.state.formValues.enabled ? 'danger' : 'primary'} type="button" onClick={() => this.handleDisableLocation()}>
                        {this.state.formValues.enabled ? 'Disable Location' : 'Enable Location'}
                      </Button>}
                    </div>
                    <div className="mb-3 text-right">
                      <Button color="danger" onClick={() => this.props.cancel()}>Cancel</Button> &nbsp;
                      <Button color="primary" type="submit">Save</Button>
                    </div>
                  </Col>
                </Row>

              </ModalFooter>
            </Form>
          )}
      </Formik>);
  }


  render() {
    return (
      <Modal isOpen={this.state.editingApiSetting != null} size={"lg"} >
        <ModalHeader>
          Enable PowerYard Location
        </ModalHeader>

        {this.renderForm()}

      </Modal>);

  }
}

export default PyApiForm;