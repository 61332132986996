import React, {Component} from "react";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import {promiseDriversByPhonePrefix} from "../../NetworkHelpers";
import * as PropTypes from "prop-types";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormSelectStateField from "../../common/formik-free/FFFormSelectStateField";
import FFFormPhoneSelectCreatableField from "../../common/formik-free/FFFormPhoneSelectCreatableField";
import ValidationService from "../../common/ValidationService";
import {Alert, CardHeader} from "reactstrap";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";

export class DispatchDriverCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            touched: {},
            eventByShipmentTracking: props.eventByShipmentTracking
        }
    }

    onTouch = (field) => {
        this.setState((prevState) => (
            Object.assign(
                {},
                this.state,
                {touched: {...prevState.touched, [field]: true}}
            )
        ));
    };

        render() {
            return <Card>
                {this.props.header &&
            <CardHeader>
                {this.props.header}
            </CardHeader>
            }
            <CardBody>
                {this.props.driver && this.props.driver.attachedToDispatchAssetId &&
                    <Alert color={"danger"}>
                        This driver is currently part of an in-progress dispatch for {this.props.driver.attachedToDispatchAssetId}. If you proceed with starting this dispatch then shipment {this.props.driver.attachedToDispatchAssetId} will be automatically marked as Completed.
                    </Alert>
                }
                {this.props.ezTrackShipmentTrackingEnabled &&
                    <FFFormCheckboxField
                        label="Driver Event By Message"
                        icon="fa-power-off"
                        fieldname="eventByShipmentTracking"
                        disabled={this.props.disableEventByMessageToggle}
                        value={!this.props.eventByShipmentTracking}
                        onChange={(evt) => {
                            this.setState({eventByShipmentTracking: !this.state.eventByShipmentTracking})
                            this.setDriverProperty("eventByShipmentTracking", !evt);
                            this.props.onBlur();
                        }}
                        error={false}
                        touched={false}
                        />
                }
                <FFFormPhoneSelectCreatableField
                    label="Mobile"
                    required={!this.props.eventByShipmentTracking}
                    subLabel="Start typing number to lookup drivers"
                    placeholder="Drivers mobile number without country code."
                    fieldname="cell"
                    error={this.props.errors.cell}
                    touched={this.state.touched.cell}
                    value={ValidationService.formatPhone(this.props.driver && this.props.driver.countryCode ? this.props.driver.countryCode : "US" , this.props.driver && this.props.driver.assetId, false)}
                    countryCodeValue={this.props.driver && this.props.driver.countryCode ? this.props.driver.countryCode : "US"}
                    icon="fa-phone"
                    countryCodeFieldName={"countryCode"}
                    loadOptions={promiseDriversByPhonePrefix(this.props.driver && this.props.driver.countryCode ? this.props.driver.countryCode : "US", this.props.dispatchUuid)}
                    onBlur={this.props.onBlur}
                    onChange={(newDriver) => {
                        this.onTouch("cell");
                        if(newDriver == null){
                            let reset = Object.assign({}, {
                                hasSmartPhoneLabel: "Event By Web",
                                firstname: "",
                                lastname: "",
                                countryCode: "US",
                                assetId: "",
                                uuid: "",
                                cell: "",
                                hasSmartPhone: true
                            });
                            this.props.onChange(reset)
                        }else{
                            let driver = {};
                            driver.hasSmartPhone = newDriver.hasSmartPhone;
                            driver.cdl = newDriver.cdl;
                            driver.cdlState = newDriver.cdlState;
                            driver.firstname = newDriver.firstname;
                            driver.lastname = newDriver.lastname;
                            driver.cell =  ValidationService.formatPhone(newDriver.countryCode ? newDriver.countryCode : "US" , newDriver.value, false);
                            driver.assetId = newDriver.value;
                            // driver.assetId = newDriver.cell;
                            driver.countryCode = newDriver.countryCode;
                            driver.uuid = newDriver.uuid;
                            driver.attachedToDispatchAssetId = newDriver.attachedToDispatchAssetId;
                            if(!newDriver.uuid){
                                driver.hasSmartPhone = true;
                            }
                            this.props.onChange(driver);
                        }
                    }}
                    isDisabled={this.props.disabled}
                    onCountryCodeChange={(v)=>{
                        this.onTouch("countryCode");
                        this.setDriverProperty("countryCode", v);
                    }}
                />
                <FFFormSelectField
                    label="Interface Type"
                    required={false}
                    placeholder="Enter Value"
                    icon="fa-phone"
                    fieldname="hasSmartPhone"
                    name="hasSmartPhone"
                    touched={this.state.touched.hasSmartPhone}
                    value={!this.props.driver ? "Event By Web" : (this.props.driver && this.props.driver.hasSmartPhone ? "Event By Web" : "Event By Text")}
                    maxMenuHeight={150}
                    options={["Event By Web", "Event By Text"]}
                    noSearch={true}
                    boolean={true}
                    dispatch={true}
                    isDisabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    onChange={(value)=>{
                        let touched = {...this.state.touched};
                        touched.hasSmartPhone = true;
                        this.setDriverProperty("hasSmartPhone", value.value === "Event By Web");
                    }}
                />
                <FFFormTextField
                    label="First Name"
                    required={!this.props.eventByShipmentTracking}
                    placeholder="Enter Value"
                    icon="fa-user"
                    fieldname="firstname"
                    error={this.props.errors.firstname}
                    touched={this.state.touched.firstname}
                    value={this.props.driver && this.props.driver.firstname}
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    onChange={(text)=>{
                        this.onTouch("firstname");
                        this.setDriverProperty("firstname", text);
                    }}
                />
                <FFFormTextField
                    label="Last Name"
                    required={!this.props.eventByShipmentTracking}
                    placeholder="Enter Value"
                    icon="fa-user"
                    fieldname="lastname"
                    error={this.props.errors.lastname}
                    touched={this.state.touched.lastname}
                    value={this.props.driver && this.props.driver.lastname}
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    onChange={(text)=>{
                        this.onTouch("lastname");
                        this.setDriverProperty("lastname", text);

                    }}
                />
                {this.props.advancedView &&
                <FFFormTextField
                    label="CDL"
                    placeholder="Enter Value"
                    icon="fa-id-card"
                    fieldname="cdl"
                    error={this.props.error}
                    touched={this.state.touched.cdl}
                    value={this.props.driver && this.props.driver.cdl}
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    onChange={(text)=>{
                        this.onTouch("cdl");
                        this.setDriverProperty("cdl", text);

                    }}
                />}
                {this.props.advancedView &&
                <FFFormSelectStateField
                    label="License State"
                    placeholder="Enter Value"
                    icon="fa-id-card"
                    fieldname="cdlState"
                    selectedCountry={"United States"}
                    errors={this.props.errors}
                    touched={this.state.touched.cdlState}
                    value={this.props.driver && this.props.driver.cdlState}
                    disabled={this.props.disabled}
                    onBlur={this.props.onBlur}
                    onChange={(text)=>{
                        this.onTouch("cdlState");
                        this.setDriverProperty("cdlState", text);
                    }}
                />}
            </CardBody>
        </Card>;
    }

    setDriverProperty(property, value) {
        let driver = this.props.driver;
        if (!driver) {
            driver = {};
        }
        driver[property] = value;
        this.props.onChange(driver);
    }
}

DispatchDriverCard.propTypes = {
    errors: PropTypes.any,
    touched: PropTypes.any,
    assetRoster: PropTypes.any,
    onChange: PropTypes.func,
    advancedView: PropTypes.any,
};