import React, { Component, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import SwitchBtn from "react-switch";
import { Container } from 'reactstrap';
import { withRouter } from 'react-router';
import { Button, Card, CardBody, CardHeader, Input, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import ShipmentTAProfileProgressBar from "./ShipmentTAProfileProgressBar";
import ShipmentTAProfileProgressBarBasic from "./ShipmentTAProfileProgressBarBasic";
import connect from "react-redux/es/connect/connect";
import { showError, showInfo } from "../../MainApp";

import {

  trackShipmentsUsingTrackingIDAnonymously,

  // searchLocations
} from "../../NetworkHelpers";



import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";


// // sidebar nav config
// import navigation from '../../_nav';
// // routes config
// import routes from '../../routes';


// const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeaderAnnon = React.lazy(() => import('./shared/DefaultHeaderAnnon'));
const DefaultFooter = React.lazy(() => import('./shared/DefaultFooterAnnon'));

class TrackAnnonamously extends Component {

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>;



  constructor(props) {
    super(props);
    this.state = {
      searchByTracking: "",
      shipmentProfile: null,
      shipmentProfileStepsTemp: null,
      detailEnabled: false,
      
      loading: true,
      loader: false
    }
    this.updateSpinner("Searching ...");


  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let tempArr = JSON.parse(localStorage.getItem("trackedShipments"));
    if (tempArr) {
      tempArr = tempArr.map((it) => (it = it.trackID));
      let s = "";
      for (var i = 0; i < tempArr.length; i++) {
        if (i === 0) {
          s = s + tempArr[0];
        } else {
          s = s + " " + tempArr[i];
        }
      }
      this.setState({ searchByTracking: s });
    }

    this.downloadShipmentProfile();

  }
  componentWillUnmount() {


  }
  refreshView() {
    this.setState({loading: true});
    this.downloadShipmentProfile();


  }
  downloadShipmentProfile() {

    var data = { "page": 0, "pageSize": 20, "sorted": [], "filtered": [] };
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let trackObj = this.getTrackingObject();
    if(trackObj.trackType !== "Invalid ID"){
    trackShipmentsUsingTrackingIDAnonymously(this.props.match.params.uuid,timeZone, data, (response) => {
      if (response.ShipmentProfile) {
        let activeStatus = response.ShipmentProfile.shipmentProfileProgress.steps.find(it => it.active === true);
        if (activeStatus.event !== null) {
          this.setState({
            shipmentStatus: activeStatus ? activeStatus.event.shipmentStatus.statusName : null
          });
        }
        this.setState({
          loading: false, shipmentProfile: response.ShipmentProfile, stopData: response.Stops, shipmentStatus: response.ShipmentStatus, trackType: response.TrackedShipments[0].trackType,
          trackID: response.TrackedShipments[0].trackID,
        });
        let s = this.state.shipmentProfile;
        let sd = this.state.stopData;
        let tempArr = this.getBasicView(s, sd);
        s.shipmentProfileProgress.steps = tempArr;
        this.setState({ shipmentProfile: s });
        this.getShipmentName();

      } else {
        let trackObj1 = this.getTrackingObject();
        this.setState({
          loading: false, shipmentProfile: null, stopData: [],
          trackType: trackObj1.trackType,
            trackID: trackObj1.trackID,
        });
      }



    }, (error) => {
      showError("Unable to load shipment.");

    })
  } else {
    
        this.setState({
          loading: false, shipmentProfile: null, stopData: [],
          trackType: trackObj.trackType,
            trackID: trackObj.trackID,
        });
  }
  }
  getTrackingObject() {
    var values = this.props.location.pathname;
    values = values.split("/");
    values = values.filter((it) => it !== "");
    let obj = {
      trackType: values[2],
      trackID: values[3],
    };
    return obj;
  }
  getShipmentName() {
    var values = this.props.location.pathname;
    values = values.split("/");
    values = values.filter(it => it !== "");
    let obj = {
      trackType: values[2],
      trackID: values[3]
    }
    this.setState({
      trackType: obj.trackType, trackID: obj.trackID
    });
  }
  getTime(stepStr){
    let seperatedArr = stepStr.split("\n");
      seperatedArr = seperatedArr.filter(it => it !== "" && it !== "&nbsp;");
      return seperatedArr[1];
    }
  getBasicView(profile, totalStops) {
    console.log(profile, totalStops);
    let activeStepsArr = profile.shipmentProfileProgress.steps[3];
    let stopArr = [];
    for (var obj in totalStops) {
      stopArr.push(totalStops[obj]);
    }

    stopArr.map((it) => (it.active = false));
    const length = stopArr.length;
    let currentStop = profile.shipment.currentStop;

    let firstStop = stopArr[0];
    if (firstStop.location !== null) {
      firstStop.stepText =
        firstStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[1].stepText) +
        "\n\n**" +
        firstStop.location.name +
        "**\n\n" +
        firstStop.location.code +
        "\n\n" +
        firstStop.location.address1 +
        "\n\n \n\n" +
        firstStop.location.city +
        ", " +
        firstStop.location.state +
        " " +
        firstStop.location.postal +
        "\n\n" +
        firstStop.location.country;
    } else {
      firstStop.stepText =
        firstStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[1].stepText)+
        "\n\n**" +
        "N/A" +
        "**\n\n" +
        "N/A" +
        "\n\n" +
        "N/A" +
        "\n\n \n\n" +
        "N/A" +
        ", " +
        "N/A" +
        " " +
        "N/A" +
        "\n\n" +
        "N/A";
    }
    firstStop.active = false;
    firstStop.showLocation = true;
    let lastStop = stopArr[length - 1];
    if (lastStop.location !== null) {
      lastStop.stepText =
        lastStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[4].stepText) +
        "\n\n**" +
        lastStop.location.name +
        "**\n\n" +
        lastStop.location.code +
        "\n\n" +
        lastStop.location.address1 +
        "\n\n \n\n" +
        lastStop.location.city +
        ", " +
        lastStop.location.state +
        " " +
        lastStop.location.postal +
        "\n\n" +
        lastStop.location.country;
    } else {
      lastStop.stepText =
      lastStop.typeOfStop +
        "\n\nArrival Time:" +
        this.getTime(profile.shipmentProfileProgress.steps[4].stepText) +
        "\n\n**" +
        "N/A" +
        "**\n\n" +
        "N/A" +
        "\n\n" +
        "N/A" +
        "\n\n \n\n" +
        "N/A" +
        ", " +
        "N/A" +
        " " +
        "N/A" +
        "\n\n" +
        "N/A";
    }

    lastStop.active = false;
    lastStop.showLocation = true;
    if (currentStop === 0) {
      if (profile.status > 3 && profile.status < 7) {
        firstStop.active = true;
        firstStop.showGrey = false;
        lastStop.showGrey = false;
      } else {
        firstStop.active = false;
        firstStop.showGrey = true;
        lastStop.showGrey = true;
      }
    }
    if (currentStop > 0 && currentStop === length) {
      lastStop.active = true;
    }
    let arr = [];
    arr.push(firstStop);
    if (currentStop > 0 && currentStop <= length) {
      let doneStop = stopArr[currentStop - 1];
      if (activeStepsArr.locationUpdates === null) {
        if(doneStop.location !== null){
          activeStepsArr.stopLocation =
          "**" +
          doneStop.location.name +
          "**\n" +
          doneStop.location.code +
          "\n\n" +
          doneStop.location.address1 +
          "\n\n \n\n" +
          doneStop.location.city +
          ", " +
          doneStop.location.state +
          " " +
          doneStop.location.postal +
          "\n\n" +
          doneStop.location.country;
        } else {
          activeStepsArr.stopLocation =
          "**" +
          "N/A"+
          "**\n" +
          "N/A"+
          "\n\n" +
          "N/A"+
          "\n\n \n\n" +
          "N/A"+
          ", " +
          "N/A"+
          " " +
          "N/A"+
          "\n\n" +
          "N/A";
        }
      } else {
        if (activeStepsArr.locationUpdates.length === 0) {
          if(doneStop.location !== null){
            activeStepsArr.stopLocation =
            "**" +
            doneStop.location.name +
            "**\n" +
            doneStop.location.code +
            "\n\n" +
            doneStop.location.address1 +
            "\n\n \n\n" +
            doneStop.location.city +
            ", " +
            doneStop.location.state +
            " " +
            doneStop.location.postal +
            "\n\n" +
            doneStop.location.country;
          } else {
            activeStepsArr.stopLocation =
            "**" +
            "N/A"+
            "**\n" +
            "N/A"+
            "\n\n" +
            "N/A"+
            "\n\n \n\n" +
            "N/A"+
            ", " +
            "N/A"+
            " " +
            "N/A"+
            "\n\n" +
            "N/A";
          }
        } else {
          activeStepsArr.stopLocation =
            activeStepsArr.locationUpdates[0].geocodeLocation.fullLocation;
        }
      }
      activeStepsArr.completedInTwoSteps = true;

      arr.push(activeStepsArr);
    }

    arr.push(lastStop);
    return arr;
    // if()
  }
  getUniqueIds(names){
    var uniqueArr = [];
    for(var value of names){
      if(uniqueArr.indexOf(value)=== -1){
        uniqueArr.push(value);
      }
    }
    return uniqueArr;
  }
  hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }
  renderBody() {
    if (this.state.loading) {
      return <div className="app-body trackassured-body" style={{ overflowX: 'visible' }}><p className={"text-center m-auto"}>Loading...</p></div>
    } else {
      return (
        <div className="app-body trackassured-body" style={{ overflowX: 'visible' }}>{/*if overflow-x is anything but visible, the dispatch editor's header won't stick correctly */}

          <main className="main column-wrapper">
            <Container fluid className="main-container form-bk column-wrapper">
              <div className="text-left p-3">



                {/* <h1>Shipment {this.state.shipmentProfile.shipment.assetId}</h1> */}

                <br />
                {this.state.shipmentProfile !== null &&
                  <Card>

                    <CardBody>
                      <div className={"btn-navigation"}>
                        <Button onClick={() => {
                          let trackedShipments = localStorage.getItem('trackedShipments');
                          if (trackedShipments) {
                            let tempArr = JSON.parse(trackedShipments);
                            let index = tempArr.findIndex(it => it.assetID === this.state.shipmentProfile.shipment.assetId);
                            if (index > -1 && index !== 0) {
                              this.props.history.push("/track-anonymously/" + tempArr[index - 1].assetID + "/" + tempArr[index - 1].trackType + "/" + tempArr[index - 1].trackID);
                            }
                            if (index == 0) {
                              showInfo("No More data to show.");
                            }
                          } else {
                            showInfo("No More data to show.");


                          }
                        }}><i className="fa fa-chevron-left"></i></Button>
                        <Button className={"float-right"} onClick={() => {
                          let trackedShipments = localStorage.getItem('trackedShipments');
                          if (trackedShipments) {
                            let tempArr = JSON.parse(trackedShipments);
                            let index = tempArr.findIndex(it => it.assetID === this.state.shipmentProfile.shipment.assetId);
                            if (index > -1 && index < (tempArr.length - 1)) {
                              this.props.history.push("/track-anonymously/" + tempArr[index + 1].assetID + "/" + tempArr[index + 1].trackType + "/" + tempArr[index + 1].trackID);
                            } if (tempArr.length == (index + 1)) {
                              showInfo("No More data to show.");
                            }
                          } else {
                            showInfo("No More data to show.");
                          }
                        }
                        }><i className="fa fa-chevron-right"></i></Button>
                      </div>
                      {/* {this.state.stopData.length > 1 && */}
                      <div className="mb-4">
                        {/* <div className={"float-right"}>
                        <label>Basic View</label>&nbsp;
  <SwitchBtn
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onChange={(checked) => {
                            let s = { ...this.state.shipmentProfile };
                            let d = { ...this.state.detailEnabled };
                            let p = { ...this.state.shipmentProfileStepsTemp }
                            let totalStops = { ...this.state.stopData };
                            if (checked == false) {
                              d = false;
                              let allsteps = JSON.stringify(s.shipmentProfileProgress);
                              let tempArr = this.getBasicView(s, totalStops);
                              s.shipmentProfileProgress.steps = tempArr;
                              this.setState({ shipmentProfile: s, detailEnabled: d, shipmentProfileStepsTemp: JSON.parse(allsteps) });


                            } else {
                              d = true;
                              s.shipmentProfileProgress = p;
                              this.setState({ shipmentProfile: s, detailEnabled: d, shipmentProfileStepsTemp: null });
                            }
                          }}
                          checked={this.state.detailEnabled}
                          title="Show Filter"
                          className="react-switch" /> &nbsp;
  <label>Detailed View</label>
                      </div> */}
                        <strong > {this.state.trackType.toLowerCase()} : {this.state.trackID.toLowerCase()}</strong>
                      </div>

                      {/* } */}

                      <h3 className={"font-weight-bold text-center mt-4"}>Current Status : {this.state.shipmentStatus}</h3>
        
                      {this.state.shipmentProfile.shipmentProfileProgress && this.state.detailEnabled === false &&
                        <ShipmentTAProfileProgressBarBasic progress={this.state.shipmentProfile.shipmentProfileProgress} laststop={this.state.shipmentProfile.destination} cancelled={this.state.shipmentStatus} currentLatLong={this.state.shipmentProfile.textMessageEvents} user={this.state.shipmentProfile.shipment} />}
                    </CardBody>
                  </Card>
                }
                {this.state.shipmentProfile === null &&
                  <Card>

                    <CardBody>
                      <div className={"btn-navigation"}>
                        <Button onClick={() => {
                          let trackedShipments = localStorage.getItem('trackedShipments');
                          if (trackedShipments) {
                            let tempArr = JSON.parse(trackedShipments);
                            let index = tempArr.findIndex(it => it.trackID === this.state.trackID);
                            if (index > -1 && index !== 0) {
                              this.props.history.push("/track-anonymously/" + tempArr[index - 1].assetID + "/" + tempArr[index - 1].trackType + "/" + tempArr[index - 1].trackID);
                            }
                            if (index == 0) {
                              showInfo("No More data to show.");
                            }
                          } else {
                            showInfo("No More data to show.");


                          }
                        }}><i className="fa fa-chevron-left"></i></Button>
                        <Button className={"float-right"} onClick={() => {
                          let trackedShipments = localStorage.getItem('trackedShipments');
                          if (trackedShipments) {
                            let tempArr = JSON.parse(trackedShipments);
                            let index = tempArr.findIndex(it => it.trackID === this.state.trackID);
                            if (index > -1 && index < (tempArr.length - 1)) {
                              this.props.history.push("/track-anonymously/" + tempArr[index + 1].assetID + "/" + tempArr[index + 1].trackType + "/" + tempArr[index + 1].trackID);
                            } if (tempArr.length == (index + 1)) {
                              showInfo("No More data to show.");
                            }
                          } else {
                            showInfo("No More data to show.");
                          }
                        }
                        }><i className="fa fa-chevron-right"></i></Button>
                      </div>
                      {/* {this.state.stopData.length > 1 && */} <div className="mb-4">

                        <strong > {this.state.trackType} : {this.state.trackID} </strong>
                      </div>

                      {/* } */}

                      <h3 className={"font-weight-bold text-center mt-4"}>Current Status : <span className={"text-danger"}>Shipment Not Found</span></h3>
                    </CardBody>
                  </Card>
                }

              </div>

            </Container>
          </main>

        </div>);
    }

  }

  render() {
    return (
      <Loader show={this.state.loader} message={this.spinner}>
        <div className="app">
          <AppHeader fixed id="ez-app-header" className={"bg-trackassured"}>
            <Suspense fallback={this.loading()}>
              <DefaultHeaderAnnon onLogout={e => this.signOut(e)} />
            </Suspense>
          </AppHeader>

          <div className="float-right tracking-header">

          </div>
          <div className="mb-2 px-3">

            <div className="float-right d-flex">
              <Input
                className=""
                type="text"
                placeholder="Insert ID(s)"

                value={this.state.searchByTracking ? this.state.searchByTracking : ""}
                onChange={(e) => {
                  let s = { ... this.state.searchByTracking };
                  s = e.target.value;
                  this.setState({ searchByTracking: s });
                }}
                onBlur={
                  (e)=>{
                    e.target.value = e.target.value.trim();
                    let s = { ...this.state.searchByTracking };
                    s = e.target.value;
                    this.setState({ searchByTracking: s });
                  }
                }

              />&nbsp;
              <Button
                color="primary"
                className="px-4"
                disabled={this.state.searchByTracking === ""}
                onClick={() => {
                  this.setState({ loaded: true });
                  let s = this.state.searchByTracking.trim();
                  s = s.split(" ");
                  s = s.filter(it=> it !== "");
                  s = s.map(it=> it = it.toLowerCase());
                  let chec = this.hasDuplicates(s);
                  if(chec === true){
                    showInfo("Duplicate IDs are removed.");
                  }
                  s = this.getUniqueIds(s);
                  s = s.toString();
                  var data = { "page": 0, "pageSize": 20, "sorted": [], "filtered": [] };

                  this.setState({ loader: true });
                  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  trackShipmentsUsingTrackingIDAnonymously(s,timeZone, data, (response) => {
                    this.setState({ loader: false });
                    if (response.TrackedShipments.length > 0) {
                      localStorage.setItem("trackedShipments", JSON.stringify(response.TrackedShipments));
                      this.props.history.push("/track-anonymously/" + response.TrackedShipments[0].assetID + "/" + response.TrackedShipments[0].trackType + "/" + response.TrackedShipments[0].trackID);
                    }

                    showInfo("Data Loaded.");
                  }, (error) => {
                    this.setState({ loader: false });
                    s = s.split(",");
                    let arr = [];
                    for (var i = 0; i < s.length; i++) {
                      arr.push({ trackID: s[i] });
                    }
                    arr = JSON.stringify(arr);
                    localStorage.setItem("trackedShipments", arr);
                    this.props.history.push("/track-anonymously/" + s[0]);
                  })

                }}
              >
                Track
              </Button> &nbsp;
              <Button
                color={"primary"}

                className="text-white d-640px-none"
                onClick={() => {
                  this.props.history.push("/login-trackassured");
                }}
              >
                Login
              </Button>&nbsp;
              <Button
                style={{ background: "#ff9e19" }}

                className="text-white"
                onClick={() => this.refreshView()}
              >
                <i className="far fa-repeat-alt"></i>
              </Button>
            </div>

          </div>
          {this.renderBody()}
          <AppFooter id="ez-app-footer">
            <Suspense fallback={this.loading()}>
              <DefaultFooter />
            </Suspense>
          </AppFooter>

        </div>
      </Loader>
    );
  }
}
export default withRouter(TrackAnnonamously);