import React from 'react';
import { EventLocation } from "./EventLocation";
import { DwellAlert } from "../../Dispatch/components/AssetsHistoryComponent";
import { ArrivalAlertClock } from "./ArrivalAlertClock";
import { ReportTime } from "./ReportTime";
import { AppointmentAlertClock } from "./AppointmentAlertClock";
import { latLongDistance } from "../../common/utilities";
import { DwellTimeTool } from "./DwellTimeTool";
import { DwellLocationTool } from "./DwellLocationTool";
import { ArrivalTool } from "./ArrivalTool";
import { DwellDateTool } from "./DwellDateTool";
import { ArrivalDateTool } from "./ArrivalDateTool";
import { RangeAlertCircle } from "./RangeAlertCircle";
import { DwellAlertTooltip } from "../../Dispatch/components/DwellAlertTooltip";
import { LocationTooltip } from "../../Dispatch/components/LocationTooltip";
const ReactMarkdown = require('react-markdown');

const renderUpdate = (event) => {
    return <div>
        <EventLocation
            showDate={true}
            geocodeLocation={event.geocodeLocation}
            latitude={event.latitude}
            longitude={event.longitude}
            timezone={event.timezone}
            date={event.date}
        />
    </div>;
};

class Node extends React.Component {
    seperateSteptext() {
        var location = "";
        var address = "";
        var type = "";
        var startType = "";
        var startDate = "";
        var date = "";
        var dwellTime = "";
        var enrouteCityState = "";
        let stepStr = this.props.step.stepText;
        let seperatedArr = stepStr.split("\n");
        seperatedArr = seperatedArr.filter(it => it !== "" && it !== "&nbsp;");
        if (this.props.ix !== 3) {
            type = seperatedArr[0];
        }
        type = seperatedArr[0];
        if (this.props.ix === 1) {
            type = seperatedArr[0];
            enrouteCityState = seperatedArr[1];
        }
        if (this.props.ix !== 3) {
            date = seperatedArr[1];
            
        }

        if (this.props.ix === 0) {
            startType = seperatedArr[2];
            startDate = seperatedArr[3];
        }

        let locName = seperatedArr.find(it => it.includes("**"));
        location = locName;
        let index = seperatedArr.findIndex(it => it === locName);
        for (var i = index + 1; i < seperatedArr.length; i++) {
            address += seperatedArr[i] + " ";
        }


        if (this.props.ix === 2) {
            dwellTime = seperatedArr[2];
        }
        let obj = {
            type: type, date: date, dwellTime: dwellTime, location: location, address: address, startDate: startDate, startType: startType,enrouteCityState: enrouteCityState
        }
        return obj;

    }
    getEnrouteTime(arr){
        let enrouteObj = arr.filter(it=> it.status == 7.1);
        if(enrouteObj.length > 0){
            enrouteObj = enrouteObj[enrouteObj.length -1];
            return enrouteObj.datee;
        } else {
            return "";
        }

    }
    render() {
        const arrivalAlert = this.props.step && this.props.step.arrival;
        const appointmentAlert = this.props.step && this.props.step.appointment;
        const allDay = this.props.step && this.props.step.allDayAppointment;
        const timezone = this.props.step && this.props.step.location && this.props.step.location.timezone;
        const enrouteTime = this.getEnrouteTime(this.props.currentLatLong && this.props.currentLatLong);
        // const locationDwell = this.props.alert && this.props.alert.arrivalAlert;
        const returnedObj = this.seperateSteptext();
        const type = returnedObj.type;
        const date = returnedObj.date;
        // if(adate){
        //     adate = adate.split(":");
        //     adate = adate[1].split(" ");
        //     if(adate[1]){
        //         let a = adate[1].split("/");
        //         adate[1]=a[1]+a[0]
        //     }
        // }
        const location = returnedObj.location;
        const address = returnedObj.address;
        const enrouteCityState = returnedObj.enrouteCityState;
        return <li className={this.props.cancelled !== "Cancelled" ? this.props.active ? "is-active" : this.props.inactive ? "is-not-active": "" :   "deactive"  }>






            <ReactMarkdown source={this.props.cancelled !== "Cancelled" ?type : ""} />
            {(this.props.step.completedInTwoSteps === undefined) &&
                <ReactMarkdown source={this.props.cancelled !== "Cancelled" ?location : ""} />
            }
             {(this.props.step.completedInTwoSteps === true && this.props.active === true && this.props.cancelled && this.props.cancelled !== "Cancelled") &&
                <ReactMarkdown source={this.props.currentLatLong ? this.props.currentLatLong[this.props.currentLatLong.length-1].geocodeLocation !== null ? this.props.currentLatLong[this.props.currentLatLong.length-1].geocodeLocation.locality+","+this.props.currentLatLong[this.props.currentLatLong.length-1].geocodeLocation.state : "N/A": "N/A"} />
               
            }
            
            {(this.props.step.completedInTwoSteps === true && this.props.active === true && this.props.cancelled && this.props.cancelled !== "Cancelled") &&
                 <DwellDateTool
                 arrivalAlert={"Enroute Time: "+enrouteTime}
             />
            
            }
            {(this.props.step.completedInTwoSteps === true && this.props.active === true && this.props.cancelled && this.props.cancelled !== "Cancelled") &&
              <ReactMarkdown source={"Remaining Miles: "+   latLongDistance(
                this.props.laststop.latitude,
                this.props.laststop.longitude,
                this.props.currentLatLong[this.props.currentLatLong.length-1].latitude,
                this.props.currentLatLong[this.props.currentLatLong.length-1].longitude,
                this.props.user.company.distanceUnitsOfMeasure)
        } />
            
            }
            {/* <ReactMarkdown source={startType} /> */}

            {/* {this.props.step.locationUpdates && this.props.step.locationUpdates.map(this.props.renderUpdate)} */}

            {(this.props.step.completedInTwoSteps === undefined) &&
                <ArrivalAlertClock

                    stopAppointment={this.props.cancelled !== "Cancelled" ?appointmentAlert : ""}
                    graceInMins={""}
                    timezone={this.props.cancelled !== "Cancelled" ?timezone : ""}
                    allDay={this.props.cancelled !== "Cancelled" ?allDay : ""}
                />
            }
            {(this.props.step.completedInTwoSteps === undefined) &&
                // <ArrivalTool
                //     arrivalAlert={this.props.cancelled !== "Cancelled" ?adate[0] : ""}
                //     stopAppointment={this.props.cancelled !== "Cancelled" ?adate[0] : ""}
                //     graceInMins={""}
                //     timezone={this.props.cancelled !== "Cancelled" ?adate[1] : ""}
                //     allDay={this.props.cancelled !== "Cancelled" ?allDay : ""}
                // />
                <ArrivalDateTool
                arrivalAlertToolColor = {this.props.step.alert && this.props.step.alert.arrivalAlert && this.props.step.alert.arrivalAlert.arrivalAlert}
                arrivalAlert={(this.props.cancelled !== "Cancelled" ? date ? date : "Not Calculated": "")}
            />
            }
            {(this.props.step.showLocation === true ) &&
                <DwellLocationTool
                    arrivalAlert={this.props.cancelled !== "Cancelled" ?address : ""}
                />
            }
            {/* {(this.props.step.completedInTwoSteps === true) &&
                 <ReactMarkdown source={this.props.cancelled !== "Cancelled" ?this.props.step.stopLocation : ""} />
          
            } */}
        </li>
    }
}

const ShipmentTAProfileProgressBarBasic = ({ progress,cancelled,laststop,currentLatLong,company, user }) => {
    return <div>
        <br /><br />
        <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <ul className="list-unstyled multi-steps ">
                {progress.steps.map(((step, ix) => {
                    return <Node
                        key={ix}
                        step={step}
                        company={company}
                        laststop={laststop}
                        currentLatLong={currentLatLong}
                        renderUpdate={(e) => renderUpdate(e)}
                        ix={ix}
                        inactive={step.showGrey}
                        active={step.active}
                        user={user}
                        cancelled = {cancelled}
                        alert={step.alert}
                    />
                }))}
            </ul>
        </div>
        <div className="d-block d-md-none d-lg-none d-xl-none">
            <ul className="list-unstyled multi-steps-vertical">
                {progress.steps.map(((step, ix) => {
                    return <Node
                        key={ix}
                        step={step}
                        company={company}
                        laststop={laststop}
                        currentLatLong={currentLatLong}
                        renderUpdate={(e) => renderUpdate(e)}
                        ix={ix}
                        inactive={step.showGrey}
                        active={step.active}
                        user={user}
                        cancelled = {cancelled}
                        alert={step.alert}
                    />
                }))}
            </ul>
        </div>
    </div>;
};


export default ShipmentTAProfileProgressBarBasic