import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import { LocationFormFields } from "./LocationFormFields";
import Config from "../../Config";
import { KILOMETERS_PER_MILE, METERS_PER_KILOMETER } from "../../common/constants";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class LocationForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disable:false,
      editingLocation: this.props.editingLocation,
      formValues: this.props.editingLocation
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editingLocation !== prevProps.editingLocation) {
      if (this.props.editingLocation != null) {
        if (this.props.editingLocation.company &&
          this.props.editingLocation.company.distanceUnitsOfMeasure === "Miles") {
          if (this.props.editingLocation.geofenceEnabled) {
            this.props.editingLocation.geofenceRadius =
              parseFloat((this.props.editingLocation.geofenceRadiusInMeters / KILOMETERS_PER_MILE
                / METERS_PER_KILOMETER).toFixed(3));
          }
        } else if (this.props.editingLocation.company &&
          this.props.editingLocation.company.distanceUnitsOfMeasure === "Kilometers") {
          if (this.props.editingLocation.geofenceEnabled) {
            this.props.editingLocation.geofenceRadius =
              parseFloat((this.props.editingLocation.geofenceRadiusInMeters / METERS_PER_KILOMETER).toFixed(3));
          }
        }
      }
      this.setState(
        {
          editingLocation: this.props.editingLocation,
          formValues: this.props.editingLocation
        });
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  //Latitudes range from 0 to 90. Longitudes range from 0 to 180
  validationSchema(values) {
    let validationRules = {
      name: Yup.string().required('Name is required.').nullable(),
      address1: Yup.string().required('Address 1 is required.').nullable(),
      state: Yup.string().required('State / County / Province is required.').nullable(),
      city: Yup.string().required('City is required.').nullable(),
      country: Yup.string().required('Country is required.').nullable(),
      latitude: Yup.number()
        .typeError("Latitude must be a number.")
        .required('Latitude is required.')
        .min(-90, "Latitude can not be less than -90.")
        .max(90, "Latitude can not be greater than 90."),
      longitude: Yup.number()
        .typeError("Longitude must be a number.")
        .required('Longitude is required.')
        .min(-180, "Longitude can not be less than -180.")
        .max(180, "Longitude can not be greater than 180."),
      timezone: Yup.string().required('Timezone is required.').nullable(),
      billingCode: Yup.string().nullable(),
        // .max(20, "Billing code can be up to 20"),
      postal: Yup.string().required('Postal is required.').nullable().trim(),
    };
    if (this.props.editingLocation.ezCheckInSite) {

      validationRules = { ...validationRules, ...{ nickname: Yup.string().required('Nickname is required.').nullable() } }
    }
    if (this.props.editingLocation.pwaEnabled) {
      validationRules = { ...validationRules, ...{ code: Yup.string().required('Code is required.').nullable() } }
    }

    return Yup.object().shape(validationRules)
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }

  onSubmit(values, { setSubmitting, setErrors }) {
    this.setState({ loaded: true })
    this.updateSpinner("Saving...");
    setSubmitting(false);
    if (values.geofenceType === "Draw on map" && values.shape === null) {
      values.geofenceType = "Specify radius";
      values.geofenceRadius = 1;
    }
    if (values.geofenceRadius) {
      if (values.company && values.company.distanceUnitsOfMeasure && values.company.distanceUnitsOfMeasure === "Miles") {
        values.geofenceRadiusInMeters = values.geofenceRadius * KILOMETERS_PER_MILE * METERS_PER_KILOMETER;
      } else if (values.company && values.company.distanceUnitsOfMeasure && values.company.distanceUnitsOfMeasure === "Kilometers") {
        values.geofenceRadiusInMeters = values.geofenceRadius * METERS_PER_KILOMETER;
      }
    } else {
      values.geofenceRadiusInMeters = null;
    }
    this.props.save(values, setErrors);
    return false;
  }

  cancel(values) {
    if (values.geofenceRadius) {
      if (values.company && values.company.distanceUnitsOfMeasure && values.company.distanceUnitsOfMeasure === "Miles") {
        values.geofenceRadiusInMeters = values.geofenceRadius * KILOMETERS_PER_MILE * METERS_PER_KILOMETER;
      } else if (values.company && values.company.distanceUnitsOfMeasure && values.company.distanceUnitsOfMeasure === "Kilometers") {
        values.geofenceRadiusInMeters = values.geofenceRadius * METERS_PER_KILOMETER;
      }
    }
    this.props.cancel();
  }


  renderForm() {
    return (<Formik
      initialValues={this.state.formValues}
      validate={this.validate(this.validationSchema.bind(this))}
      onSubmit={(v, o) => {
        this.onSubmit(v, o)
      }}
    >{
        ({
          values,
          errors,
          touched,
          status,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          handleReset,
          setTouched,
          setFieldValue,
          setFieldTouched
        }) => (


          <Form
            autoComplete="off"
            onSubmit={(v) => {
              return handleSubmit(v)
            }}>
            <Card className="simple-card">
              <CardHeader>Location Info</CardHeader>
              <CardBody>
                <LocationFormFields
                  errors={errors}
                  user={this.props.user}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  onChange={(object) => {
                    this.setState({ formValues: object });
                    this.validate();
                  }}
                  setDisable={(check) => {
                  this.setState({ disable: check });
                }}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  onCountryChange={(object) => {
                    setFieldValue("country", object ? object.value : "");
                    setFieldTouched("country", true);
                  }}
                  onStateChange={(object) => {
                    setFieldValue("state", object ? object.value : "");
                    setFieldTouched("state", true);
                  }}
                  onCountryCodeChange={(e) => {
                    setFieldValue("countryCode", e.target.value);
                    setFieldTouched("countryCode", true);
                  }}
                  onAssetTypeChange={(equipments) => {
                    equipments = equipments.map((l) => { return { uuid: l.value, name: l.label } });
                    setFieldValue("equipments", equipments ? equipments : []);
                    setFieldTouched("equipments", true);

                  }}
                  onTaskTypeChange={(taskType) => {
                    taskType = taskType.map((l) => { return { uuid: l.value, name: l.label } });
                    setFieldValue("taskType", taskType ? taskType : []);
                    setFieldTouched("taskType", true);

                  }}
                  onAssetStatusChange={(assetStatuses) => {
                    assetStatuses = assetStatuses.map((l) => { return { uuid: l.value, name: l.label } });
                    setFieldValue("assetStatuses", assetStatuses ? assetStatuses : []);
                    setFieldTouched("assetStatuses", true);

                  }}
                  onCancelReasonCodeChange={(cancelReasons) => {
                    cancelReasons = cancelReasons.map((l) => { return { uuid: l.value, name: l.label } });
                    setFieldValue("cancelReasons", cancelReasons ? cancelReasons : []);
                    setFieldTouched("cancelReasons", true);

                  }}
                  onCarrierChange={(carrier) => {
                    carrier = carrier.map((l) => { return { uuid: l.value, name: l.label } });
                    setFieldValue("carrier", carrier ? carrier : []);
                    setFieldTouched("carrier", true);

                  }}
                  onCellCountryCodeChange={(e) => {
                    setFieldValue("cellCountryCode", e.target.value);
                    setFieldTouched("cellCountryCode", true);
                  }}
                  hideEzCheckInLocation={this.props.hideCancel}
                  allowQuickAdd={this.props.allowQuickAdd}
                />
              </CardBody>
            </Card>
            <div className="mb-3 text-right">
              {!this.props.hideCancel && <Button color="danger" onClick={() => this.cancel(values)}>Cancel</Button>}&nbsp;
              <Button color="primary" type="submit" disabled={this.props.loadedLocation|| this.state.disable}>Save</Button>
            </div>
          </Form>
        )}
    </Formik>);
  }


  render() {
    return (
      <Modal isOpen={this.state.editingLocation != null} size={"lg"} >
        <Loader show={this.props.loadedLocation} message={this.spinner}>
          <ModalHeader>
            Location: {this.state.editingLocation ? this.state.editingLocation.name : ""}  {Config.showUuid && "[" + (this.state.editingLocation && this.state.editingLocation.uuid ? this.state.editingLocation.uuid : "") + "]"}
          </ModalHeader>
          <ModalBody style={{ overflow: 'auto' }}>
            {this.renderForm()}
          </ModalBody>
        </Loader>
      </Modal>);

  }
}

export default LocationForm;