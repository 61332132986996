
export const fieldnames = [
    { value: 'location', label: 'Current Location' },
    { value: 'assignee', label: 'Assignee' },
    { value: 'type', label: 'Task Type' },
    { value: 'secondaryRef', label: 'Reference Number' },
    { value: 'carrier', label: 'Trailer Name' },
    { value: 'assetId', label: 'Trailer Number' },
    { value: 'assetType', label: 'Asset Type' },
    { value: 'assetStatus', label: 'Asset Status' },
    { value: 'tempSetPoint', label: 'Temperature Setpoint' },
    { value: 'actualTemp', label: 'Temperature Actual' },
    { value: 'moveFrom', label: 'Move From Spot' },
    { value: 'boxedIn', label: 'Boxed In' },
    { value: 'moveTo', label: 'Move To Spot' },
    { value: 'shipmentNumber', label: 'Shipment Number' },
    { value: 'sealed', label: 'Sealed' },
    { value: 'sealNumber', label: 'Seal #' },
    { value: 'comments', label: 'Comments' },
    { value: 'shift', label: 'Shift' }
  ];

  export const fieldnamesProgress = [
    { value: 'type', label: 'Task Type' },
    { value: 'secondaryRef', label: 'Reference Number' },
    { value: 'carrier', label: 'Trailer Name' },
    { value: 'assetId', label: 'Trailer Number' },
    { value: 'assetType', label: 'Asset Type' },
    { value: 'assetStatus', label: 'Asset Status' },
    { value: 'tempSetPoint', label: 'Temperature Setpoint' },
    { value: 'actualTemp', label: 'Temperature Actual' },
    { value: 'boxedIn', label: 'Boxed In' },
    { value: 'moveFrom', label: 'Move From Spot' },
    { value: 'moveTo', label: 'Move To Spot' },
    { value: 'shipmentNumber', label: 'Shipment Number' },
    { value: 'fuelLevel', label: 'Fuel Level'},
    { value: 'continous', label: 'Continuous (Yes/No)'},
    { value: 'reefer', label: 'Reefer (On/Off )'},
    { value: 'sealed', label: 'Sealed' },
    { value: 'sealNumber', label: 'Seal #' },
    { value: 'comments', label: 'Comments' }
  ];
  export const fieldnamesMoveTask = [
    { value: 'secondaryRef', label: 'Reference Number' },
    { value: 'assetType', label: 'Asset Type' },
    { value: 'assetStatus', label: 'Asset Status' },
    { value: 'tempSetPoint', label: 'Temperature Setpoint' },
    { value: 'actualTemp', label: 'Temperature Actual' },
    { value: 'boxedIn', label: 'Boxed In' },
    { value: 'shipmentNumber', label: 'Shipment Number' },
    { value: 'sealed', label: 'Sealed' },
    { value: 'fuelLevel', label: 'Fuel Level'},
    { value: 'continous', label: 'Continuous (Yes/No)'},
    { value: 'reefer', label: 'Reefer (On/Off )'},
    { value: 'sealNumber', label: 'Seal #' },
    { value: 'comments', label: 'Comments' }
  ];