import React, {Component} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardGroup,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    Row
} from "reactstrap";
import {Link} from "react-router-dom";
import {postRegisterUser} from "../../NetworkHelpers";
import {showError} from "../../MainApp";
import {Formik} from 'formik';
import * as Yup from 'yup'
import RegisterTextField from "./RegisterTextField";
import {getErrorsFromValidationError} from "../../common/utilities";
import RegisterInputGroup from "./RegisterInputGroup";
import {TypeOfEntitySelect} from "../../common/TypeOfEntitySelect";
import {FFCountrySelect} from "../../common/formik-free/FFFormCountrySelect";
import {FFFormStateSelectInputGroup} from "../../common/formik-free/FFFormStateSelectInputGroup";
import FormCheckboxField from "../../common/FormCheckboxField";
import {withRouter} from 'react-router';
import Config from "../../Config";

const validationSchema = function (values) {
    Yup.addMethod(Yup.object, 'atLeastOneOf', function(list) {
        return this.test({
            name: 'atLeastOneOf',
            message: 'At least one service is required',
            exclusive: true,
            params: { keys: list.join(', ') },
            test: value => list.some(f => value[f] === true)
        })
    });
    return Yup.object().shape({
        entityName: Yup.string()
            .min(2, `Legal Entity Name has to be at least 2 characters`)
            .required('Legal Entity Name is required'),
        typeOfUser: Yup.string().required("Please select a Type of Entity"),
        address1: Yup.string()
            .required('Address 1 is required'),
        firstname: Yup.string()
            .min(2, `First name has to be at least 2 characters`)
            .required('First name is required'),
        lastname: Yup.string()
            .min(2, `Last name has to be at least 2 characters`)
            .required('Last name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        country: Yup.string()
            .required('Country is required'),
        city: Yup.string()
            .required('City is required'),
        state: Yup.string()
            .required('State / County / Province is required'),
        postalCode: Yup.string()
            .required('Postal code is required'),
        password: Yup.string()
            .min(6, `Password has to be at least ${6} characters!`)
            .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/, 'Password must contain: numbers, uppercase and lowercase letters\n')
            .required('Password is required'),
        passwordConfirm: Yup.string()
            .oneOf([values.password], 'Passwords must match')
            .required('Password confirmation is required'),
        enableEzTrack: Yup.boolean(),
        enableEzCheckInDispatch: Yup.boolean(),
        enableEzCheckInWelcome: Yup.boolean(),
        enablePowerYard: Yup.boolean()
    }).atLeastOneOf(['enableEzTrack', 'enableEzCheckInWelcome', 'enableEzCheckInDispatch', 'enablePowerYard'])
};

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values);
        try {
            validationSchema.validateSync(values, {abortEarly: false});
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
};

const initialValues = {
    entityName: "",
    typeOfUser: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",
    country: "United States",
    postalCode: "",
    dbaName:""
};

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canDownloadListOfStatesForCountries: ["United States", "Canada"],
            showInfographic: false,
            countries: []
        }
    }

    componentWillUnmount() {
        document.body.style.background = null;
    }

    componentDidMount() {
        window.scrollTo(0,0);
        document.body.style.background = "url(assets/img/background/truck-bg4.jpg)";}

    register(values) {
        let newUser = Object.assign({}, values);
        postRegisterUser(newUser,
            (user) => {
                this.props.login(this.state.username, user);
                this.props.history.push("/admin");
            },
            (e) => {
                showError(e.message ? e.message : "Unable to register.");
                console.log("Error", e);
            })
    }

    checkValid() {
        this.setState({formValid: this.validForm()});
    }

    validForm() {
        let r = this.state.email && this.state.password && (this.state.passwordConfirm === this.state.password) && this.state.firstname && this.state.lastname;
        r = r && (this.state.entityName && this.state.typeOfUser && this.state.address1 && this.state.city && this.state.country && this.state.postalCode);
        return r;
    }

    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        // copy over email to username.
        values.username = values.email;
        this.register(values);
        return false;
    }

    render() {
        return (
            <div className="app flex-row mt-5 animated fadeIn">
                <Container>
                    <Row>
                        <Col className="text-left">
                            <img alt="Logo" className="login-logo" src={Config.preLoginBrand ? Config.preLoginBrand : "/assets/img/brand/ezc_by-e4.png"} />
                        </Col>
                        <Col className="text-right">
                                <span>Already registered?</span>&nbsp; &nbsp;<Link to="/"><Button color="primary" className="bg-btn" >Login</Button></Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-center registration">
                        <Col md="8">
                            <CardGroup>
                                <Card className="">
                                    <CardHeader className="text-left bg-primary ">
                                        <Row>
                                            <Col sm="10">
                                                <h4>Register</h4>
                                                <div className="subhead">Create your account</div>
                                            </Col>
                                            <Col sm="2" className="text-right">
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    <CardBody className="text-left">
                                        <div>
                                            <Modal isOpen={this.state.showInfographic} size={"lg"}
                                                   onClick={() => this.setState({showInfographic: false})}>
                                                <ModalBody style={{overflow: 'auto'}}>
                                                    <img alt="Infographic" src="assets/img/infographic/demo.jpg" style={{width:"100%"}}></img>
                                                </ModalBody>
                                            </Modal>


                                            <Formik
                                                initialValues={initialValues}
                                                validate={validate(validationSchema)}
                                                onSubmit={(v, o) => {
                                                    this.onSubmit(v, o)
                                                }}
                                                >{
                                                    ({
                                                         values,
                                                         errors,
                                                         touched,
                                                         status,
                                                         dirty,
                                                         handleChange,
                                                         handleBlur,
                                                         handleSubmit,
                                                         isSubmitting,
                                                         isValid,
                                                         handleReset,
                                                         setTouched,
                                                         setFieldValue,
                                                         setFieldTouched
                                                     }) => (


                                                        <Form onSubmit={(v) => {
                                                            return handleSubmit(v)
                                                        }}>
                                                            <h5 className="card-title">Entity</h5>
                                                            <Row>

                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        icontype="fa fa-gavel"
                                                                        placeholder="Legal Entity Name"
                                                                        fieldname="entityName"
                                                                        error={errors["entityName"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["entityName"]}
                                                                        value={values["entityName"]}
                                                                    />

                                                                </Col>
                                                                <Col sm="6">
                                                                    <InputGroup className="mb-3 select-form-group">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fa fa-city"></i>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <TypeOfEntitySelect
                                                                            error={errors["typeOfUser"]}
                                                                            touched={touched["typeOfUser"]}
                                                                            onChange={(v) => {
                                                                                setFieldValue("typeOfUser", v ? v.value : "");
                                                                                setFieldTouched("typeOfUser", true);
                                                                            }}
                                                                            onBlur={() => setFieldTouched("typeOfUser", true)}
                                                                            value={values["typeOfUser"]}
                                                                        />
                                                                        <FormFeedback>{errors.typeOfUser}</FormFeedback>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        icontype="fa fa-gavel"
                                                                        placeholder="DBA Name"
                                                                        fieldname="dbaName"
                                                                        error={errors["dbaName"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["dbaName"]}
                                                                        value={values["dbaName"]}
                                                                    />
                                                                </Col>
                                                                {this.showIdentification(values) && <div>
                                                                    <Col sm="12">
                                                                        <h5 className="card-title">Identification
                                                                            Numbers</h5>
                                                                        <p>Please enter at least one value below. This
                                                                            is used by other companies to uniquely
                                                                            identify your company.</p>
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <RegisterTextField
                                                                            icontype="fa fa-gavel"
                                                                            placeholder="US DOT Number"
                                                                            fieldname="usDotNumber"
                                                                            error={errors["usDotNumber"]}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={handleChange}
                                                                            touched={touched["usDotNumber"]}
                                                                            value={values["usDotNumber"]}
                                                                        />
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <RegisterTextField
                                                                            prependText={"MC-"}
                                                                            placeholder="FMCSA MC Number"
                                                                            fieldname="fmcsaMcNumber"
                                                                            error={errors["fmcsaMcNumber"]}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={handleChange}
                                                                            touched={touched["fmcsaMcNumber"]}
                                                                            value={values["fmcsaMcNumber"]}
                                                                        />
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <RegisterTextField
                                                                            prependText={"FF-"}
                                                                            placeholder="FMCSA FF Number"
                                                                            fieldname="fmcsaFfNumber"
                                                                            error={errors["fmcsaFfNumber"]}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={handleChange}
                                                                            touched={touched["fmcsaFfNumber"]}
                                                                            value={values["fmcsaFfNumber"]}
                                                                        />
                                                                    </Col>
                                                                    <Col sm="6">
                                                                        <RegisterTextField
                                                                            prependText={"MX-"}
                                                                            placeholder="FMCSA MX Number"
                                                                            fieldname="fmcsaMxNumber"
                                                                            error={errors["fmcsaMxNumber"]}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={handleChange}
                                                                            touched={touched["fmcsaMxNumber"]}
                                                                            value={values["fmcsaMxNumber"]}
                                                                        />
                                                                    </Col>
                                                                    {values["country"] !== "United States" &&
                                                                    <Col sm="12">
                                                                        <RegisterTextField
                                                                            icontype="fa fa-gavel"
                                                                            placeholder="National Registration Number"
                                                                            fieldname="nationalRegistrationNumber"
                                                                            error={errors["nationalRegistrationNumber"]}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={handleChange}
                                                                            touched={touched["nationalRegistrationNumber"]}
                                                                            value={values["nationalRegistrationNumber"]}
                                                                        />
                                                                    </Col>
                                                                    }
                                                                </div>}
                                                            </Row>

                                                            <h5 className="card-title">Services</h5>
                                                            {errors.undefined && (touched.enableEzTrack || touched.enableEzCheckInDispatch
                                                                || touched.enableEzCheckInWelcome || touched.enablePowerYard) ?
                                                                <h6 style={{'color':'red'}}>{errors.undefined}</h6> : null}
                                                                <FormCheckboxField
                                                                    icon="fa fa-map-marked-alt"
                                                                    label="EZTrack"
                                                                    fieldname="enableEzTrack"
                                                                    errors={errors}
                                                                    handleBlur={handleBlur}
                                                                    handleChange={handleChange}
                                                                    touched={touched}
                                                                    values={values}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                       />
                                                            <FormCheckboxField
                                                                icon="fa fa-map-marked-alt"
                                                                label="EZCheck-In Dispatch"
                                                                fieldname="enableEzCheckInDispatch"
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                touched={touched}
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                            />
                                                            <FormCheckboxField
                                                                icon="fa fa-map-marked-alt"
                                                                label="EZCheck-In Welcome Center"
                                                                fieldname="enableEzCheckInWelcome"
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                touched={touched}
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                            />
                                                            <FormCheckboxField
                                                                icon="fa fa-map-marked-alt"
                                                                label="Power Yard"
                                                                fieldname="enablePowerYard"
                                                                errors={errors}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                touched={touched}
                                                                values={values}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                            />


                                                            <h5 className="card-title">Corporate Address</h5>


                                                            <RegisterTextField
                                                                icontype="fa fa-building"
                                                                placeholder="Address 1"
                                                                fieldname="address1"
                                                                error={errors["address1"]}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                touched={touched["address1"]}
                                                                value={values["address1"]}
                                                            />

                                                            <RegisterTextField
                                                                icontype="fa fa-building"
                                                                placeholder="Address 2"
                                                                fieldname="address2"
                                                                error={[]}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                touched={false}
                                                                value={values["address2"]}
                                                            />

                                                            <Row>
                                                                <Col sm="6">
                                                                    <RegisterInputGroup
                                                                        fieldname={"country"}
                                                                        error={errors["country"]}
                                                                        icontype="fa fa-building">
                                                                        <FFCountrySelect
                                                                            name="country"
                                                                            placeholder={"Country"}
                                                                            icon={"fa-building"}
                                                                            error={errors["country"]}
                                                                            touched={touched["country"]}
                                                                            onChange={(object) => {
                                                                                setFieldValue("country", object ? object.value : "");
                                                                                setFieldValue("state", "");
                                                                                setFieldTouched("country", true);
                                                                            }}
                                                                            onBlur={() => setFieldTouched("country", true)}
                                                                            value={values["country"]}
                                                                            options={this.state.countryOptions}
                                                                        />
                                                                    </RegisterInputGroup>
                                                                </Col>
                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        icontype="fa fa-building"
                                                                        placeholder="City"
                                                                        fieldname="city"
                                                                        error={errors["city"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["city"]}
                                                                        value={values["city"]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                    {values['country'] && this.state.canDownloadListOfStatesForCountries.indexOf(values['country']) !== -1 ?
                                                                        <FFFormStateSelectInputGroup
                                                                            name="state"
                                                                            icon="fa-building"
                                                                            placeholder={values['country'] ? (values['country'] === "United States" ? "US State" : "State/County/Province") : "State"}
                                                                            error={errors["state"]}
                                                                            onBlur={() => {
                                                                                setFieldTouched("state", true);
                                                                            }}
                                                                            handleChange={handleChange}
                                                                            touched={touched["state"]}
                                                                            value={values["state"]}
                                                                            onChange={(object) => {
                                                                                setFieldValue("state", object ? object.value : "");
                                                                                setFieldTouched("state", true);
                                                                            }}
                                                                            selectedCountry={values['country'] ? values.country : "United States"}
                                                                            required={true}
                                                                        /> :
                                                                        <RegisterTextField
                                                                            icontype="fa fa-building"
                                                                            placeholder={"State/County/Province"}
                                                                            fieldname="state"
                                                                            name="state"
                                                                            error={errors["state"]}
                                                                            handleBlur={() => {
                                                                                setFieldTouched("state", true);
                                                                            }}
                                                                            handleChange={(e) => {
                                                                                setFieldValue("state", e.target.value);
                                                                                handleBlur(e);
                                                                            }}
                                                                            touched={touched["state"]}
                                                                            value={values["state"]}
                                                                        />
                                                                    }

                                                                </Col>
                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        icontype="fa fa-building"
                                                                        placeholder={this.state.country ? (this.state.country === "United States" ? "Zip Code" : "Postal Code") : "Postal Code"}
                                                                        fieldname="postalCode"
                                                                        error={errors["postalCode"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["postalCode"]}
                                                                        value={values["postalCode"]}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <h5 className="card-title">Contact</h5>

                                                            <Row>

                                                                <Col sm="6">

                                                                    <RegisterTextField
                                                                        icontype="fa fa-user"
                                                                        placeholder="First Name"
                                                                        fieldname="firstname"
                                                                        error={errors["firstname"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["firstname"]}
                                                                        value={values["firstname"]}
                                                                    />
                                                                </Col>
                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        icontype="fa fa-user"
                                                                        placeholder="Last Name"
                                                                        fieldname="lastname"
                                                                        error={errors["lastname"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["lastname"]}
                                                                        value={values["lastname"]}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <RegisterTextField
                                                                icontype="fa fa-envelope"
                                                                placeholder="Email Address"
                                                                fieldname="email"
                                                                error={errors["email"]}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                touched={touched["email"]}
                                                                value={values["email"]}
                                                            />
                                                            <Row>

                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        password={true}
                                                                        placeholder="Password"
                                                                        icontype="fa fa-lock"
                                                                        fieldname="password"
                                                                        error={errors["password"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["password"]}
                                                                        value={values["password"]}
                                                                    />
                                                                </Col>
                                                                <Col sm="6">
                                                                    <RegisterTextField
                                                                        password={true}
                                                                        placeholder="Password confirmation"
                                                                        icontype="fa fa-lock"
                                                                        fieldname="passwordConfirm"
                                                                        error={errors["passwordConfirm"]}
                                                                        handleBlur={handleBlur}
                                                                        handleChange={handleChange}
                                                                        touched={touched["passwordConfirm"]}
                                                                        value={values["passwordConfirm"]}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xs="12">
                                                                    <Button color="primary" className="bg-btn mt-3"
                                                                            type="submit">Register</Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}</Formik>
                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>);
    }

    showIdentification(values) {
        return values["typeOfUser"] === "Carrier" || values["typeOfUser"] === "Broker" || values["typeOfUser"] === "Logistics Provider";
    }
}

export default withRouter(Register);
