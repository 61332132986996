import React, {Component} from "react";
import {
    Button,
    Col,
    FormFeedback,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from "reactstrap";


class ContactList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editingContact: false,
            touched: {}
        }
    }


    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}/>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    editContact( contact ) {
        let c = Object.assign({}, contact);
        c.originalContact = contact;
        c.isNew = false;
        this.props.onEditContactClick(c);
    }

    deleteContact( contact ) {
        let contacts = this.props.values[this.props.fieldname].slice();
        for ( let i=0;i<contacts.length;i++ ) {
            if ( contacts[i] === contact ) {
                contacts.splice(i,1);
                this.props.setFieldValue(this.props.fieldname, contacts);
                break;
            }
        }
        console.log("On Change contacts", contacts);
        this.props.onChange(contacts);
    }

    cancelEdit() {
        this.setState( {
            editingContact: false
        });
    }

    listContacts() {
        return this.props.values && this.props.values[this.props.fieldname] && this.props.values[this.props.fieldname].map((contact, ix) => {
            return <div key={ix}>
                <Button color="secondary" className="sm" onClick={()=>this.deleteContact(contact)}><i className="fa fa-trash"></i></Button>
                <Button color="secondary" className="sm"  onClick={()=>this.editContact(contact)}><i className="fa fa-edit"></i></Button>
                {contact.firstName} {contact.lastName}: {contact.email}
            </div>
        })
    }


    render() {
        return <FormGroup className="contacts-list">
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend" className="align-top">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <div className={((this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? "form-control is-invalid" : "form-control")}>
                                {this.listContacts(this.props)}
                                <div className="text-right">
                                    <Button onClick={(e) => {
                                        this.props.onNewCarrierContactClick()
                                    }}>Add</Button>
                                </div>
                            </div>
                            <FormFeedback>{this.props.errors[this.props.fieldname]}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
    }
}

export default ContactList;


