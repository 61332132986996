import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormField from "./FormField";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormPhoneTextField from "../../common/formik-free/FFFormPhoneTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import { FFLocationsSelect } from "../../common/formik-free/FFLocationsSelect";
import { FFCarriersSelect } from "../../common/formik-free/FFCarriersSelect";
import { FFRoleSelect } from "../../common/formik-free/FFRoleSelect";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";

export class UserFormFields extends Component {
  constructor(props) {
    super(props);

  }
  onChange(fieldName, newValue) {
    this.props.values[fieldName] = newValue;
    this.props.onChange(this.props.values);
  }
  getEnabledModules() {
    let modules = [];
    if (this.props.loggedInUser.company.enableEzCheckInWelcome) {
      modules.push("enableEzCheckInWelcome");
    }
    if (this.props.loggedInUser.company.enableEzCheckInDispatch) {
      modules.push("enableEzCheckInDispatch");
    }
    if (this.props.loggedInUser.company.enableEzTrack) {
      modules.push("enableEzTrack");
    }
    if (this.props.loggedInUser.company.enablePowerYard) {
      modules.push("enablePowerYard");
    }
    if (this.props.loggedInUser.company.enableTrackAssured) {
      modules.push("enableTrackAssured");
    }
    return modules;
  }
  render() {
    return <>
      <div>
        <FFFormTextField
          label="Email"
          icon="fa-building"
          placeholder=""
          fieldname="email"
          error={this.props.errors["email"]}
          onChange={(newValue) => {
            this.onChange("email", newValue);
          }}
          onBlur={this.props.handleBlur}
          touched={this.props.touched["email"]}
          value={this.props.values["email"]}
          required={true}
        />
        <FFFormTextField
          label="First Name"
          placeholder=""
          icon="fa-id-card"
          fieldname="firstname"
          error={this.props.errors["firstname"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("firstname", newValue);
          }}
          touched={this.props.touched["firstname"]}
          value={this.props.values["firstname"]}
          required={true}
        />
        <FFFormTextField
          label="Last Name"
          placeholder=""
          icon="fa-id-card"
          fieldname="lastname"
          error={this.props.errors["lastname"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("lastname", newValue);
          }}
          touched={this.props.touched["lastname"]}
          value={this.props.values["lastname"]}
          required={true}
        />
        <FFFormPhoneTextField
          label="Phone"
          icon="fa-phone"
          fieldname="phone"
          countryCodeFieldName="countryCode"
          error={this.props.errors["phone"]}
          handleBlur={this.props.handleBlur}
          onChange={(e) => {
            this.onChange("phone", e.target.value);
          }}
          onCountryCodeChange={(value) => {
            this.onChange("countryCode", value);
          }}
          countryCode={this.props.values["countryCode"]}
          touched={this.props.touched["phone"]}
          value={this.props.values["phone"]}
          placeholder={this.props.values["countryCode"] === "US" || !this.props.values["countryCode"] ? "###-###-#### ext. XXXX" : "#########"}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        <FFFormPhoneTextField
          label="Mobile"
          icon="fa-phone"
          fieldname="cell"
          countryCodeFieldName="cellCountryCode"
          error={this.props.errors["cell"]}
          handleBlur={this.props.handleBlur}
          onChange={(e) => {
            this.onChange("cell", e.target.value);
          }}
          onCountryCodeChange={(value) => {
            this.onChange("cellCountryCode", value);
          }}
          countryCode={this.props.values["cellCountryCode"]}
          touched={this.props.touched["cell"]}
          value={this.props.values["cell"]}
          placeholder={this.props.values["cellCountryCode"] === "US" || !this.props.values["cellCountryCode"] ? "###-###-####" : "#########"}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        <FFFormCheckboxField
          label="Enabled"
          icon="fa-power-off"
          fieldname="enabled"
          error={this.props.errors["enabled"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("enabled", newValue);
          }}
          touched={this.props.touched["enabled"]}
          value={this.props.values["enabled"]}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        <FormField
          label={"Locations"}
          errors={this.props.errors}
          icon={"fa-building"}
          fieldname={"locations"}
        >
          <FFLocationsSelect
            name={"locations"}
            placeholder={'Locations must be assigned to the user...'}
            fieldname={"locations"}
            error={this.props.errors["locations"]}
            handleBlur={this.props.handleBlur}
            handleChange={this.props.handleChange}
            touched={this.props.touched["locations"]}
            value={this.props.values["locations"]}
            required={true}
            onLocationsChange={this.props.onLocationsChange}
          >
          </FFLocationsSelect>
        </FormField>
        <FormField
          label={"Partners"}
          errors={this.props.errors}
          icon={"fa-building"}
          fieldname={"carriers"}
        >
          <FFCarriersSelect
            name={"carriers"}
            fieldname={"carriers"}
            error={this.props.errors["carriers"]}
            handleBlur={this.props.handleBlur}
            handleChange={this.props.handleChange}
            touched={this.props.touched["carriers"]}
            value={this.props.values["carriers"]}
            required={true}
            onCarriersChange={this.props.onCarriersChange}
          >
          </FFCarriersSelect>
        </FormField>
        <FFFormSelectField
          name={"shift"}
          fieldname={"shift"}
          label={"Shift"}
          icon={"fa-business-time"}
          required={false}
          placeholder={"Select a shift for the user..."}
          isClearable={true}
          options={["Day", "Mid", "Night"]}
          onChange={(shift) => {
            this.onChange("shift", shift ? shift.value : null);
          }}
          value={this.props.values["shift"]}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        <FormField
          label={"Roles"}
          required={true}
          errors={this.props.errors}
          icon={"fa-building"}
          fieldname={"roles"}
        >
          <FFRoleSelect
            name={"roles"}
            fieldname={"roles"}
            loggedInUser={this.props.loggedInUser}
            error={this.props.errors["roles"]}
            handleBlur={this.props.handleBlur}
            handleChange={this.props.handleChange}
            touched={this.props.touched["roles"]}
            value={this.props.values["roles"]}
            required={true}
            onRolesChange={this.props.onRolesChange}
            typeOfUser={this.props.loggedInUser.company.typeOfUser}
            enabledModules={this.getEnabledModules()}
            menuPosition={"fixed"}
          >
          </FFRoleSelect>
        </FormField>
        <FFFormTextField
          password={true}
          label="Password"
          placeholder=""
          icon="fa-id-card"
          isPasswordFiled={true}
          fieldname="password"
          error={this.props.errors["password"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("password", newValue);
          }}
          touched={this.props.touched["password"]}
          value={this.props.values["password"]}
          required={true}
        />
        <FFFormTextField
          password={true}
          label="Password confirm"
          placeholder=""
          icon="fa-id-card"
          isPasswordFiled={true}
          fieldname="passwordConfirm"
          error={this.props.errors["passwordConfirm"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("passwordConfirm", newValue);
          }}
          touched={this.props.touched["passwordConfirm"]}
          value={this.props.values["passwordConfirm"]}
          required={true}
        />
      </div>
    </>;
  }
}

UserFormFields.propTypes = {
  errors: PropTypes.any,
  handleBlur: PropTypes.any,
  handleChange: PropTypes.any,
  touched: PropTypes.any,
  values: PropTypes.any,
  onCountryChange: PropTypes.any,
  onStateChange: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any
};
