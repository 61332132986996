export class Stop {
    constructor(typeOfStop,
                typeOfPickup,
                typeOfDelivery,
                appointment,
                location
    ) {
        this.typeOfStop = typeOfStop ? typeOfStop : "Pickup";
        this.typeOfPickup = typeOfPickup ? typeOfPickup : "Live";
        this.typeOfDelivery = typeOfDelivery ? typeOfDelivery : "Live";
        this.appointment = appointment ? appointment : null;
        this.location = location ? location : {label: "", value: ""};
        this.departingTractor ={};
        this.differentDepartingTractor=false;
        this.departingTrailer ={};
        this.differentDepartingTrailer=false;
        this.differentDepartingDriver=false;
        this.departingDriver ={};
        this.canEdit = true;
        this.editableStop = true;
        this.canMoveUp = true;
        this.showUp = true;
        this.actionStep = 0;
    }
}