import * as React from "react";
import DurationDisplay from "../../assets/components/DurationDisplay";
import {getDwellForAssetType} from "../../common/utilities";

export class AlertModelDurationDisplay extends React.Component {
    render() {
        return <DurationDisplay
            date={this.props.arrival}
            criticalDwellInMinutes={
                getDwellForAssetType(
                    this.props.assetType,
                    this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.criticalDwellAlertInSeconds / 60,
                    this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.criticalDwellAlertInSeconds / 60
                )
            }
            cricitalDwellAlertUnit={
                getDwellForAssetType(
                    this.props.assetType,
                    this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.criticalDwellAlertUnit,
                    this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.criticalDwellAlertUnit
                )
            }
            warningDwellInMinutes={
                getDwellForAssetType(
                    this.props.assetType,
                    this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.warningDwellAlertInSeconds / 60,
                    this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.warningDwellAlertInSeconds / 60
                )
            }
            warningDwellAlertUnit={
                getDwellForAssetType(
                    this.props.assetType,
                    this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.warningDwellAlertUnit,
                    this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.warningDwellAlertUnit
                )
            }
        />;
    }
}