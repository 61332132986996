import React, { Component } from "react";
import Moment from "react-moment";
import { DateTime } from "luxon";
var moment = require('moment-timezone');


class MomentDisplay extends Component {

  timezone() {
    const timezoneAbbr = moment().tz(this.props.timezone ? this.props.timezone : moment.tz.guess()).format('z');
    return this.mapTimezoneNameToAbbr(timezoneAbbr);

  }

  mapTimezoneNameToAbbr(timezoneName) {
    // Add your timezone to abbreviation mapping logic here
    switch (timezoneName) {
      case "+06":
        return "BDT";
      // Add more timezone mappings as needed
      default:
        return timezoneName;
    }
  }


  render() {
    if (this.props.timezone) {
      return (
        <span>

          <span>

            <span className="no-wrap">
              <Moment format="MM/DD/YYYY hh:mm A">{moment(this.props.date).tz(this.props.timezone)}</Moment>
              {" " + this.timezone()}
            </span>


          </span>

        </span>

      );
    }
    else {
      return (
        <span>

          <span>

            <span className="no-wrap">
            <Moment format="MM/DD/YYYY hh:mm A">
                {moment(this.props.date).utc()}
            </Moment>
            {" UTC"}
            
            </span>


          </span>

        </span>

      );

    }


  }

  getTz() {
    if (this.props.noOffset) return null;
    return this.props.timezone ? this.props.timezone : moment.tz.guess();
  }
}

export default MomentDisplay;