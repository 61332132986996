import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardGroup, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import { downloadCurrentStep, sendGps, sendResponseToStep } from "../../NetworkHelpers";
import { withRouter } from 'react-router';
import Config from "../../Config";


const ReactMarkdown = require('react-markdown')

class EventByWeb extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loadingLocation: true,
      loading: true,
      step: null,
      enteredValue: "",
      position: null,
      locationPermissionsDenied: false,
      gpsReportButton: false,
      disableGpsButton: false
    }
  }

  componentWillUnmount() {
    if (this.watchPositionRef) {
      navigator.geolocation.clearWatch(this.watchPositionRef);
      this.watchPositionRef = null;
    }
    document.body.style.background = null;
    clearInterval(this.timerId);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.background = null;
    document.body.style.backgroundColor = "#aaa";
    this.downloadedCurrentStep = false;
    this.startWatchLocation(() => this.downloadCurrentStep());
    this.timerId = setInterval(() => {
      this.setState({ loadingLocation: false });
      this.downloadCurrentStep();
    }, Config.ebwLocationTimeout || 5000);
  }

  startWatchLocation(onComplete) {
    if (navigator.geolocation) {
      this.watchPositionRef = navigator.geolocation.watchPosition((position) => {
        this.setState({ position: position, locationError: null, loadingLocation: false });
        if (onComplete) onComplete();
        onComplete = null;
      }, (error) => {
        this.setState({
          position: null, locationError: error, loadingLocation: false,
          locationPermissionsDenied: error.code === error.PERMISSION_DENIED
        });
        if (onComplete) onComplete();
        onComplete = null;
      }, { enableHighAccuracy: true });

    } else {
      this.setState({ locationError: "GEO Location Services Not Available", loadingLocation: false });
      if (onComplete) onComplete();
    }
  }


  downloadCurrentStep() {
    // if (!this.downloadedCurrentStep) this.downloadedCurrentStep = true;
    // else return;
    downloadCurrentStep(this.props.match.params.uuid, (step) => {
      this.setState({ loading: false, enteredValue: this.state.enteredValue, step: step })
    },
      (error) => {
        this.setState({
          loading: false, error: { message: "Unable to Load Session" },
          step: { complete: true, htmlMessage: "# Error Loading Data" }
        })
      });

  }

  renderError() {
    return (<div>
      <h4>The link you used is no longer valid.</h4>
      <h6>Please try requesting another email link.</h6><br /><br />
      <Button color="primary" onClick={() => this.props.history.push("/")}>OK</Button></div>);
  }

  onNextClick() {
    this.setState({ loading: true, error: null });
    this.sendResponse(this.state.position);
  }

  onGpsClick() {
    let position = this.state.position;
    if (position && position.coords) {
      let data = {
        sessionUuid: this.props.match.params.uuid
      };
      this.setLocationInformation(data);
      this.setState({ disableGpsButton: true });
      this.timerId = setTimeout(() => {
        this.setState({ disableGpsButton: false });
        this.timerId = false;
      }, Config.maxGpsInterval || 5000);
      sendGps(data);
    }
  }

  setLocationInformation(data) {
    let position = this.state.position;
    data.position = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      heading: position.coords.heading,
      speed: position.coords.speed,
      altitude: position.coords.altitude,
      accuracy: position.coords.accuracy,
      altitudeAccuracy: position.coords.altitudeAccuracy
    }
  }

  sendResponse(position) {
    let data = {
      sessionUuid: this.props.match.params.uuid,
      response: this.state.step.freeForm ? this.state.enteredValue : this.state.step.defaultValue
    };
    if (position && position.coords) {
      this.setLocationInformation(data);
    }
    sendResponseToStep(
      data,
      (step) => this.setState({ loading: false, enteredValue: "", step: step }),
      (error) => { this.setState({ loading: false, error: error }) }
    );
  }

  checkLocationSuccess() {
    if (this.state.locationError) {
      return <span><i className="fa fa-times"></i>{this.state.locationPermissionsDenied &&
        " Please enable Location Services and when prompted share your location so that we can obtain more " +
        "accurate information about your shipment."}</span>
    }
    return <></>
  }

  render() {
    return (
      <div className="app flex-row align-items-center animated fadeIn">
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              <CardGroup>
                <Card className="p-1 text-left" >
                  <CardHeader><div className="text-center"><img alt="Logo" className="ebw-logo" src={Config.preLoginBrand ? Config.preLoginBrand : "/assets/img/brand/ezc_by-e4.png"} /></div>
                    {this.state.step && this.state.step.header &&
                      <span className={"ebw-header"}>
                        {this.state.step.header}
                      </span>}
                  </CardHeader>
                  <CardBody>
                    <div className="mb-5">
                      {this.state.loading ? (this.state.loadingLocation ? <div>Waiting for location...</div> : <div>Loading...</div>) : <>
                        {this.state.error ? <h5>{this.state.error.message}</h5> : ""}
                        <br />
                        {!this.state.locationPermissionsDenied && this.state.step.gpsReportButton &&
                          <div className={"text-right"}>
                            <Button size="sm" disabled={this.state.disableGpsButton} onClick={() => this.onGpsClick()} style={{ marginBottom: "10px" }}>Report Location</Button>
                          </div>}
                        <div>
                          <ReactMarkdown source={this.state.step.htmlMessage} />
                        </div>
                        {this.state.step.complete ? <div><i className="fa fa-check" /> You may close this browser window.</div> : <div>
                          {this.state.step.freeForm ? <div><Input type="text" placeholder="Enter Value"
                            onChange={(v) => { this.setState({ enteredValue: v.target.value }) }}
                            value={this.state.enteredValue} /><br />
                          </div> : ""}
                          <Button color="primary" onClick={() => this.onNextClick()}><i className="fa fa-check" />  {this.state.step.nextButtonText ? this.state.step.nextButtonText : "Next"}</Button>
                        </div>}
                      </>}
                    </div>
                  </CardBody>
                  <CardFooter>
                    <div className="text-right small text-muted">{this.checkLocationSuccess()}</div>
                  </CardFooter>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


export default withRouter(EventByWeb);