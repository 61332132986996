import React, { Component } from 'react';
import Select from "react-select";
import {
    Col,
    FormFeedback,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Tooltip,
    Button
} from "reactstrap";
import * as uuid from "uuid";
import HelpIcon from "../../common/HelpIcon";

class FFFormSelectFieldButton extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            value: this.getValue(props)
        }
    }

    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    componentWillReceiveProps(nextProps, nextContext){
        this.setState({value: this.getValue(nextProps)})
    }
    btnIcon() {
        if (this.props.btnIcon) {
            return <i className={"fa " + this.props.btnIcon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }
    renderSelect() {
        return (
            <Select name={this.props.fieldname}
            maxMenuHeight={this.props.maxMenuHeight}
            fieldname={this.props.fieldname}
            isClearable={this.props.isClearable}
            placeholder={this.props.placeholder}
            isSearchable={this.props.isSearchable}
            errors={this.props.errors}
            touched={this.props.touched}
            isMulti={this.props.isMulti}
            onChange={(v) => {
                this.setState({value: v}, ()=>{
                    this.props.onChange && this.props.onChange(v);
                })
            }}
            className={"basic-single select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
            // classNamePrefix="select"
            options={this.props.options && this.props.options.map((v)=>{
                if(v.value && v.label){
                    return v
                }
                return {value:v, label:v}})
            }
            onBlur={() => {
                //Set Touched
                this.props.onBlur && this.props.onBlur();
            }}
            value={this.state.value}
            isDisabled={this.props.isDisabled}
    />)
    }


    render() {
        if (this.props.button) {
            return (

                <FormGroup>
                    <Row>
                        {!this.props.noLabel &&
                            <Col lg="2" md="3" sm="4">
                                <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''}</span> {this.props.subLabel ?
                                    <small><br />{this.props.subLabel}</small> : ""}</Label>
                                {this.props.helpMessage &&
                                    <div>
                                        <i style={{
                                            position: "absolute",
                                            right: 0,
                                            top: "5px",
                                            color: "grey"
                                        }}
                                            className={"fa fa-question-circle"}
                                            id={this.state.id}
                                        />
                                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                                            toggle={() => {
                                                this.setState({
                                                    tooltipOpen: !this.state.tooltipOpen
                                                })
                                            }}>
                                            {this.props.helpMessage}
                                        </Tooltip>
                                    </div>
                                }
                            </Col>
                        }
                        <Col lg="7" md="5" sm="4">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                {this.renderSelect()}
                                <FormFeedback>{this.props.error}</FormFeedback>
                            </InputGroup>
                        </Col>
                        <Col lg="3" md="4" sm="4">

                            <Button hidden={!this.props.btnHidden} disabled={this.props.btnEmptyState} className={"float-right text-uppercase font-temperature-btn"}  color="primary" onClick={this.props.onClick}  >Temperature <i className={"fa "+ this.props.btnIcon} /></Button>
                            {this.props.btnEmptyState === true && this.props.btnHidden ==true &&
                            <HelpIcon className={"float-right"} helpMessage={"Do not leave Datetime, Task Type, Asset ID or Move To field/fields empty."} />
                            }
                        </Col>
                    </Row>
                </FormGroup>
            )
        } else {
            return (

                <FormGroup>
                    <Row>
                        {!this.props.noLabel &&
                            <Col lg="2" md="3" sm="4">
                                <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''}</span> {this.props.subLabel ?
                                    <small><br />{this.props.subLabel}</small> : ""}</Label>
                                {this.props.helpMessage &&
                                    <div>
                                        <i style={{
                                            position: "absolute",
                                            right: 0,
                                            top: "5px",
                                            color: "grey"
                                        }}
                                            className={"fa fa-question-circle"}
                                            id={this.state.id}
                                        />
                                        <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                                            toggle={() => {
                                                this.setState({
                                                    tooltipOpen: !this.state.tooltipOpen
                                                })
                                            }}>
                                            {this.props.helpMessage}
                                        </Tooltip>
                                    </div>
                                }
                            </Col>
                        }
                        <Col lg="10" md="9" sm="8">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                {this.renderSelect()}
                                <FormFeedback>{this.props.error}</FormFeedback>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>
            )
        }

    }
    getValue(props) {
        if(!props) return {};

        let values;
        if (props.isMulti) {
            values = props.value;
        } else {
            values = props.value ? (
                props.value.label && props.value.name ? props.value : {
                label: props.value,
                name: props.value
            }) : null;
        }
        return values;
    }
}


export default FFFormSelectFieldButton;