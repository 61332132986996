import React, { Component } from "react";
import * as PropTypes from "prop-types";
import FormField from "./FormField";
import { Button, Form, Row, Col, FormGroup, Label, Table } from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import FFFormLabelButton from "../../common/formik-free/FFFLabelButton";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import { generateClientSecret, promiseAuthorizedLocationSearch } from "../../NetworkHelpers";
import { showError } from "../../MainApp";

class FormRow extends Component<{ label: any, value: any }> {
  render() {
    return <FormGroup>
      <Row>
        <Col lg="2" md="3" sm="4">
          <Label>{this.props.label}</Label>
        </Col>
        <Col lg="10" md="9" sm="8">
          {this.props.value}
        </Col>
      </Row>
    </FormGroup>;
  }
}
class WebhookHeader extends Component {
  render() {
    return <>
      <Row>
        <Col md={6}>
          <FFFormTextField
            label="Header"
            placeholder="Enter header"
            fieldname="header"
            onChange={(newValue) => {
              let header = this.props.header;
              header.header = newValue;
              this.props.onChange && this.props.onChange(header);
            }}
            value={this.props.header.header}
          />
        </Col>
        <Col md={6}>
          <FFFormTextField
            label="Content"
            placeholder="Enter content"
            fieldname="headerValue"
            onChange={(newValue) => {
              let header = this.props.header;
              header.headerValue = newValue;
              this.props.onChange && this.props.onChange(header);
            }}
            value={this.props.header.headerValue}
          />
        </Col>
      </Row>
    </>
  }
}

class WebhookHeaders extends Component<{}> {
  render() {
    return <div>
      {this.props.headers && this.props.headers.map((index) => {
        return <>
          <WebhookHeader header={index} onChange={
            (header) => {

              index = header;
              this.props.onChange && this.props.onChange(this.props.headers);

            }
          } />
        </>;
      })}
      <Row>
        <Col>
          <Button color="secondary" className="sm" onClick={() => this.props.onAddHeader()}><i className="fa fa-plus" /> Add Header</Button>
        </Col>
      </Row>
    </div>;
  }
}

class WebhookSettings extends Component {
  constructor(props) {
    super(props)
     
    console.log("props in webhook", props)
  }
  render() {
    return <>
      <div>
        {/* <h4>Event Webhooks</h4> */}
        {/* <FormRow label={"Documentation"} value={<a href={"/docs/index.html"} target="_blank" >{window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/docs/index.html"}</a>} /> */}
        <FFFormTextField
          label="Web Hook URL"
          icon="fa-browser"
          fieldname="webhookUrl"
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
          onChange={(newValue) => {

            this.props.onChangeUrl(newValue)
          }}
          value={this.props.values.webhookUrl}
          labelStyle={{ paddingTop: "5px" }}
        />
        <WebhookHeaders
          headers={this.props.headerArr}
          onChange={(headers) => {

            let settings = this.props.headerArr;
            settings = headers;
            this.props.onChange && this.props.onChange(settings)
            this.props.onHeaderClickEvent(settings)
          }}
          onAddHeader={() => {
             
            console.log('on add header', this.props)
            if (!this.props.headerArr) this.props.headerArr = [];
            this.props.headerArr.push({});
            this.props.onChange && this.props.onChange(this.props.headerArr);
            // this.onChange("settingHeaders", this.props.settingHeaders);
          }}
        />
      </div>
    </>
  }
}
export class PyApiFormFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSendOnTasks: true,
      clientSecret: this.props.secret.clientSecret,
      touched: {},
      // clientSecretStr: 'Please generate client secret.',
      clientSecretStr: "Please Generate Client Secret",
      clientSecretTemp: null
    }
    console.log("props in fields", this.props);

  }

  options = [
    // { label: "All", value: -1 },
    { label: "Pending", value: "Pending" },
    { label: "Assigned", value: "IsAssinged" },
    { label: "In Progress", value: "InProgress" },
    { label: "Complete", value: "Complete" },
    { label: "Cancelled", value: "Canceled" }
  ];
  onChange(fieldName, newValue) {

    this.props.values[fieldName] = newValue;
    this.props.values[fieldName] = newValue;
    // this.props.setFieldTouched(fieldName);
    this.props.onChange(this.props.values);
    if (fieldName === "taskEventApi") {
      this.props.values.taskStatusList = [];
      this.props.values.webhookUrl = null;
      this.setState({
        showSendOnTasks: newValue,
      })
    }
    if (fieldName === 'updateSecret') {
      if (newValue) {
        this.props.values.clientSecret = null;
        this.setState({ clientSecret: null })
      } else {
        let temp = this.state.clientSecretTemp;
        this.props.values.clientSecret = temp;
        this.setState({ clientSecret: temp })
      }
    }
  }
  render() {
    return <>
      <div>
        <FFFormAsyncSelectField
          defaultOptions={true}
          isClearable={false}
          touched={this.state.touched.locationName}
          isDisabled={this.props.values.isEdit}
          errors={this.props.errors.locationName}
          fieldname="locationName"
          label="Name"
          icon="fa-warehouse"
          value={
            this.props.values.locationName ? this.props.values.locationName : ""
          }
          valid={this.props.errors["locationName"]}
          required={true}
          loadOptions={promiseAuthorizedLocationSearch}
          onChange={(v) => {

            let s = { ...this.props.values };
            s.locationName = v.name;
            s.locationUuid = v.value;
            this.props.values.locationId = v.id;
            this.props.values.clientId = v.value;
            this.props.values.locationUuid = s.locationUuid;
            let touched = { ...this.state.touched };
            touched.locationName = true;
            this.onChange("locationName", v.name);
            // this.setState({ editingApiSetting: s, locationName: v.name, touched: touched });

          }}
        />
        <FFFormCheckboxField
          label="Task API"
          icon="fa-power-off"
          fieldname="taskApi"
          error={this.props.errors["taskApi"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("taskApi", newValue);
          }}
          touched={this.props.touched["taskApi"]}
          value={this.props.values["taskApi"]}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        <FFFormCheckboxField
          label="Reference API"
          icon="fa-power-off"
          fieldname="refernceApi"
          error={this.props.errors["refernceApi"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {

            this.onChange("refernceApi", newValue);
          }}
          touched={this.props.touched["refernceApi"]}
          value={this.props.values["refernceApi"]}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        <FFFormCheckboxField
          label="Task Events API"
          icon="fa-power-off"
          fieldname="taskEventApi"
          error={this.props.errors["taskEventApi"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("taskEventApi", newValue);
          }}
          touched={this.props.touched["taskEventApi"]}
          value={this.props.values["taskEventApi"]}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
        {this.props.values.taskEventApi &&
          <FFFormSelectField
            label="Send On Task Events API"
            required={true}
            isMulti={true}
            icon="fa-truck"
            fieldname="taskStatusList"
            name="taskStatusList"
            value={this.props.values.taskStatusList}
            onChange={(arr) => {

              this.onChange("taskStatusList", arr);
              // var arr = arr.map(it => it.value);
              // this.props.values.taskStatusList = arr
            }}
            // value={this.getLabel(values.type || "Carrier")}
            options={this.options}
            noSearch={true}
            isDisabled={this.props.disabled}
          />

        }
        {this.props.values.taskEventApi &&
          <WebhookSettings headerArr={this.props.values.settingHeaders}
            onChange={(headerArr) => {
              this.props.setFieldValue("settingHeaders", headerArr);
              this.props.setFieldTouched("settingHeaders", true);
            }}
            setFieldValue={this.props.setFieldValue}
            setFieldTouched={this.props.setFieldTouched}
            values={this.props.values}
            onChangeUrl={(url) => {
              this.props.onWebUrlChange(url);
            }}
            onHeaderClickEvent={(headerSet) => {
              this.props.onHeaderClick(headerSet)
            }}
          // settings={this.props.values.webhookUrl} onChange={(settings) => {
          //   this.props.setFieldValue("webhookUrl", settings);
          //   this.props.setFieldTouched("webhookUrl", true);
          // }}
          />
          // <FFFormTextField
          //   label="Url"
          //   placeholder="Enter Web hook Url"
          //   icon="fa-link"
          //   fieldname="webhookUrl"
          //   error={this.props.errors["webhookUrl"]}
          //   onTouch={this.props.handleBlur}
          //   onChange={(newValue) => {
          //     this.onChange("webhookUrl", newValue);
          //   }}
          //   onBlur={this.props.handleBlur}
          //   touched={this.props.touched["webhookUrl"]}
          //   value={this.props.values["webhookUrl"]}
          //   required={true}
          // />
        }

        <FFFormLabelButton
          label="New Secret"
          isDisabled={false}
          btnColor={'secondary'}
          isLblShow={false}
          btnTxt="Generate Client Secret"
          fieldname="clientSecret"
          onClick={() => {
            generateClientSecret((response) => {
              if (response.statusCode === 200) {
                this.props.values.clientSecret = response.data.clientSecret;
                this.setState({ clientSecret: response.data.clientSecret, clientSecretTemp: response.data.clientSecret })
              } else {
                this.setState({ clientSecret: null, clientSecretStr: 'Something went wrong while generating client secret.Please try again.' })
              }
            }, (error) => {
              showError(error.message);
              console.log(error)
            })
          }}
        />
        <FormRow label={"Client Secrets"} value={<>
          <Table>
            {/* <thead >
              <tr>
                <th className="border-top-0">Secret</th>
              </tr>
            </thead> */}
            <tbody>
              <tr >
                <td>{this.state.clientSecret === null ? this.state.clientSecretStr : this.state.clientSecret}</td>

              </tr>
            </tbody>
          </Table>
        </>} />
        {/* <FFFormTextField
          label="Clear Secrets"
          placeholder="0915***********"
          icon=""
          fieldname="secret"
          error={this.props.errors["secret"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("secret", newValue);
          }}
          touched={this.props.touched["secret"]}
          value={this.props.values["secret"]}
        /> */}
        <FFFormCheckboxField
          label="Invalidate Previously Created Client Secret Values"
          icon="fa-power-off"
          fieldname="updateSecret"
          error={this.props.errors["updateSecret"]}
          handleBlur={this.props.handleBlur}
          onChange={(newValue) => {
            this.onChange("updateSecret", newValue);
          }}
          touched={this.props.touched["updateSecret"]}
          value={this.props.values["updateSecret"]}
          setFieldValue={this.props.setFieldValue}
          setFieldTouched={this.props.setFieldTouched}
        />
      </div>
    </>;
  }
}

PyApiFormFields.propTypes = {
  errors: PropTypes.any,
  handleBlur: PropTypes.any,
  handleChange: PropTypes.any,
  touched: PropTypes.any,
  values: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any
};
