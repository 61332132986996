import React, { Component } from "react";
import {
  convertCtoF,
  convertFtoC,
  getErrorsFromValidationError,
} from "../../common/utilities";
import * as Yup from "yup";
import MomentDisplay from "../../assets/components/MomentDisplay";

import { confirmAlert } from "react-confirm-alert";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Collapse
} from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import FFFormSelectCreatableField from "../../common/formik-free/FFFormSelectCreatableField";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import * as PropTypes from "prop-types";
import {
  downloadTaskByUuid,
  putTaskCancel,
  getDefaultCancelReasonCode,
  getDefaultEZTrackEquipment,
  promiseAssetByPrefix,
  promiseAssetStatusesByLocationUUid,
  getUsersByLocation,
  carriersByLocationUUid,
  promiseEZTrackEquipmentTypeByPrefix,
  promiseSpotForLocationByZonePrefixOrSpotPrefix,
  promiseTaskTypeByLocationUUid,
  getDefaultTaskTypeByLocation,
  saveTask,
  getFieldSettingsForScreen,
  downloadEZTrackEquipmentForTable,
  promiseAuthorizedLocationSearch,
  postUserDefaultLocation,
  getAllSecondaryReferenceByRefID,
  getTaskReferenceByRefID,
  getSecondaryReferenceByRefID,
  clientResponseBySpotUuid,
  getRolesByLocation,
  promiseEZTrackEquipmentFuel
} from "../../NetworkHelpers";
import FFFormAsyncSelectField from "../../common/formik-free/FFFormAsyncSelectField";
import { fieldnamesMoveTask } from "../../common/poweryard/FieldNames";
import { showError, showInfo } from "../../MainApp";
import TaskSpotHistory from "./TaskSpotHistory";
import FFFormSelectField from "../../common/formik-free/FFFormSelectField";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import FFFormTextAreaField from "../../common/formik-free/FFFormTextAreaField";
import CancelTaskModal from "../../common/CancelTaskModal";
import { FFReferenceSelect } from "../../common/formik-free/FFReferenceSelect";
import FFFormLabelButton from "../../common/formik-free/FFFLabelButton";
import ReferenceModal from "./ReferenceModal";
import HelpIcon from "../../common/HelpIcon";

const priorityOptions = [
  { value: 3, label: 'High' },
  { value: 2, label: 'Normal' },
  { value: 1, label: 'Low' }


];
class TaskEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isonsite: false,
      SpotonSite: false,
      SpotonSiteData: undefined,
      check: false,
      priorityroles: "",
      priorityEditAllowed: true,
      priorityAllowed: this.props.currentLocation.enablePriority,
      taskUnderEdit: {
        name: "",
        location: this.props.currentLocation,
        enabled: true,
        isNew: true,
        completedManually: false,
        secondaryRef: '',
        priority: priorityOptions[1],
      },


      secRefNumber: null,
      errors: {},
      touched: {},
      showSpotHistory: true,
      defaultEquipmentType: {},
      allLocations: [],
      allEquipmentTypes: [],
      newCheck: false,
      user: props.user,
      loaded: false,
      locations: [],
      selectedTask: {
        refData: {
          prNumber: null,
          prName: null,
          prAppointmentDate: null,
          prAppointmentWindow: null,
          prAppointmentType: null,
          prAppointmentPlan: null,
          prScac: null,
          prRefIdFlagDate: null,
          cancelled: null,
          prRefIdFlag: null,
          uuid: null,
          carrier: {},
          location: null,
          secondaryRef: "",
        }
      }
    };


  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentDidUpdate() { console.log(this.state.taskUnderEdit, "success113"); }

  componentDidMount() {




    let pathfromOnsite
    window.scrollTo(0, 0);
    getRolesByLocation(
      this.props.currentLocation.uuid,
      (rolesResponse) => {
        const roles = rolesResponse.content.roles;
        const allowed = rolesResponse.content.allowed;
        if (this.props.match.params.uuid !== "new" &&

          roles.includes(this.props.user.roles[0].role)) {
          this.setState({ priorityEditAllowed: false });


        }
        else if (this.props.match.params.uuid === "new" &&
          roles.includes(this.props.user.roles[0].role)) {
          this.setState({ priorityEditAllowed: false });

        }
        console.log('All roles location:', this.state.priorityroles);
      },
      (error) => {
        console.error('Error fetching roles:', error);
      }
    );
    getDefaultCancelReasonCode((res) => {
      let data = res.content;
      data.label = data.name;
      data.value = data.uuid;
      this.setState({
        defaultCancelReasonCode: data
      });
    }, () => {
      this.setState({
        defaultCancelReasonCode: null
      });
    });
    this.updateSpinner("Saving ...");
    getDefaultEZTrackEquipment(this.props.currentLocation.uuid, (def) => {
      let t = { ...this.state.taskUnderEdit };
      t.assetType = def.content;
      this.setState({
        defaultEquipmentType: def.content,
        taskUnderEdit: t
      });
    });
    getDefaultTaskTypeByLocation(this.props.currentLocation.uuid, (res) => {
      let s = res.content.find(it => it.def === true);
      if (s) {
        let t = { ...this.state.taskUnderEdit };
        t.type = s;
        this.setState({
          defaultTaskType: s,
          taskUnderEdit: t,

        });

      }
    });
    let settingObj = {
      screenCode: "moved-task",
      locationUuid: this.props.currentLocation.uuid
    }
    getFieldSettingsForScreen(settingObj, (res) => {
      this.setState({ requiredFields: res.content.mandatoryFields })
    });
    let searchRequest = { page: 0, pageSize: 50, sorted: [], filtered: [] };
    downloadEZTrackEquipmentForTable(searchRequest, (response) => {
      this.setState({ allEquipmentTypes: response.content.results });
    });
    // searchLocations(searchRequest, (response) => {
    //   this.setState({ allLocations: response.content.results });
    // });
    if (this.props.match.params.uuid !== "new") {
      this.loadTask(this.props.match.params.uuid);
    }
    if (this.props.match.params.uuid === "new" && this.props.match.isExact === false) {
      pathfromOnsite = this.props.location.pathname.split("/").filter(it => it !== "")[2]
      if (pathfromOnsite) {
        this.loadTask(pathfromOnsite);
      }
    }
    promiseTaskTypeByLocationUUid(this.props.currentLocation.uuid, (res) => {
      let task = { ...this.state.taskUnderEdit };
      if (!task.type) {
        task.type = res.filter((t) => t.def)[0];
      }
      if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY") {
        task.assignee = this.props.user;
      }
      this.setState({ taskTypeOptions: res, taskUnderEdit: task });
    })

    promiseAssetStatusesByLocationUUid(this.props.currentLocation.uuid, (res) => {
      let listData = res.content.map((d) => {
        return {
          value: d.name,
          label: d.name,
          ...d
        }
      });
      let task = { ...this.state.taskUnderEdit };
      if (!task.assetStatus) {
        task.assetStatus = listData.filter((t) => t.default)[0];

      }

      this.setState({ assetStatusOptions: listData, taskUnderEdit: task, defaultAssetStatus: listData.filter((t) => t.default)[0] });
    });
  }
  getFlooredFixed(v, d) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
  }
  loadTask(uuid) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading Task ...");
    downloadTaskByUuid(
      uuid,
      (data) => {
        let success = data.content;
        let moveduuid, movefrom;
        if (
          this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
          this.props.company.temperatureUnitsOfMeasure !== "Celsius"
        ) {
          success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
          if (success.tempSetPoint !== null) {
            success.tempSetPoint = this.getFlooredFixed(success.tempSetPoint, 1);
          }
          success.actualTemp = convertCtoF(success.temperatureInC);
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          success.tempSetPoint = success.temperatureSetPointInC;
          if (success.temperatureSetPointInC !== null) {
            success.tempSetPoint = this.getFlooredFixed(success.tempSetPoint, 1);
          }
          success.actualTemp = success.temperatureInC;
          if (success.actualTemp !== null) {
            success.actualTemp = this.getFlooredFixed(success.actualTemp, 1);
          }
        }
        if (this.props.match.params.uuid === "new" && this.props.match.isExact === false) {
          this.setState({ isonsite: true });
          success.type = null;
          success.acceptDate = null;
          success.created = null;
          success.end = null;
          success.endDate = null;
          success.assignDate = null;
          success.updated = null;
          success.start = null;
          success.startDate = null;
          success.taskStatus = null;
          success.priority = 2;
          success.movedTaskUuid = success.uuid;
          success.uuid = "";
          success.moveFrom = success.moveTo;
          success.moveTo = null;
          fieldnamesMoveTask.forEach(field => {
            if (this.getRequiredCheck(field.value) == false) {
              success[field.value] = null;
            }
          });



          if (success.assetId === null) {

            success.asset.assetId = null;
          }
          if (success.assetType === null) {
            delete success.asset.equipment;

          }
          if (success.assetId === null && success.assetType === null) {

            success.asset = null;
          }


          delete success.assignee;

          if (success.type === null) {
            delete success.type;
          }
        }
        //   if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
        //     success.tempSetPoint = convertCtoF(success.temperatureSetPointInC);
        //     success.actualTemp = convertCtoF(success.temperatureInC);
        // }
        // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
        //     success.tempSetPoint = success.temperatureSetPointInC;
        //     success.actualTemp = success.temperatureInC;
        // }
        if (success.acceptDate) {
          success.acceptDate = this.convertDateTimeToLocationTZ(success.acceptDate, success.location.timezone);
        } else {
          success.acceptDate = null;
        }
        if (success.startDate) {
          success.start = this.convertDateTimeToLocationTZ(success.startDate, success.location.timezone);
        } else {
          success.start = null;
        }
        if (success.endDate) {
          success.end = this.convertDateTimeToLocationTZ(success.endDate, success.location.timezone);
        } else {
          success.end = null;
        }
        if (this.props.user.roles[0].role === "ROLE_POWERYARD_JOCKEY" && !(success.taskStatus == "Complete")) {
          success.assignee = this.props.user;
        }
        let taskBackup = JSON.stringify(success);
        success.secondaryRef = success.secondaryRef === '' ? null : success.secondaryRef;
        if (success.secondaryRef !== null) {
          var singleRefObj = success.secondaryRef.split(',')[0]
          var seRefObj = {
            value: singleRefObj,
            label: singleRefObj,
            uuid: singleRefObj,
            valueSubmit: success.secondaryRef
          }
          success.secondaryRef = seRefObj;
        }
        if (success.fuelLevel) {

          success.fuelLevel.label = success.fuelLevel.fuelLevel;

        }
        if (success.priority) {
          success.priority = priorityOptions.find((obj) => obj.value === success.priority);
        } else {
          success.priority = null;
        }
        console.log(success, "success112");


        this.setState({ taskUnderEdit: success, taskBackup: taskBackup, showSealNumber: data.content.sealed, secRefNumber: success.secondaryRef, loaded: false });
      },
      (failure) => {
        showError("Unable to access task.");
      }
    );
  }
  convertDateTimeToLocationTZ(tdate, timezone) {
    const datetime = moment.utc(tdate).clone().tz(timezone);
    const date = datetime.date();
    const month = datetime.month();
    const year = datetime.year();
    const hours = datetime.hours();
    const minutes = datetime.minutes();
    const seconds = datetime.seconds();
    const millisecond = datetime.millisecond();

    const localDate = moment();
    localDate.set({
      year: year,
      month: month,
      date: date,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      millisecond: millisecond

    });
    return localDate.toDate();
  }

  cancelEdit() {

    let path = this.props.location.pathname;
    path = path.split('/');
    path = path.filter(it => it !== "");
    if (path[2] === "onsite") {
      this.props.history.push("/poweryard-onsite");
    }
    else if (this.state.isonsite) {
      this.props.history.push("/poweryard-onsite");

      this.setState({ isonsite: false });
    }
    else {
      this.props.history.push("/poweryard-tasks");
    }
  }
  openRefModal(ref, elem) {

    this.setState({ loaded: true })
    this.updateSpinner("Loading...");
    ref.clickedRef = elem;
    this.onGetRefDataByRefID(ref)
  }
  onGetRefDataByRefID(ref) {
    getSecondaryReferenceByRefID(ref.clickedRef,
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({ isRefModalOpen: true, selectedTask: ref, loaded: false });
        }
        else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      }, (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      })
  }
  onSubmit(val) {
    // loaded = true;
    this.setState({ loaded: true })
    // this.setState(prevState => {
    //   let taskUnderEdit = Object.assign({}, prevState.taskUnderEdit);  // creating copy of state variable jasper
    //   taskUnderEdit.secondaryRef = secondaryRef;                     // update the name property, assign a new value                 
    //   return { taskUnderEdit };
    // });


    let task = { ...this.state.taskUnderEdit };

    let taskToBeSubmit = {};
    // taskToBeSubmit.boxedIn = task.boxedIn === 0 ? null : task.boxedIn; 
    taskToBeSubmit.boxedIn = task.boxedIn;
    taskToBeSubmit.type = {
      uuid: task.type.uuid,
      name: task.type.name
    };
    taskToBeSubmit.location = task.location;
    if (task.isNew === true) {
      taskToBeSubmit.isNew = true;
    }
    if (task.carrier) {
      taskToBeSubmit.carrier = {
        uuid: task.carrier.uuid,
        name: task.carrier.name
      };
    }
    if (task.assignee) {
      taskToBeSubmit.assignee = {
        uuid: task.assignee.uuid,
        username: task.assignee.username
      };
    }
    if (task.movedTaskUuid) {
      taskToBeSubmit.movedTaskUuid = task.movedTaskUuid;

    }
    if (task.assetStatus) {
      taskToBeSubmit.assetStatus = {
        uuid: task.assetStatus.uuid,
        name: task.assetStatus.name
      };
    }
    if (task.assetType) {
      taskToBeSubmit.assetType = task.assetType.name;
    }
    if (task.moveTo) {
      taskToBeSubmit.moveTo = {
        uuid: task.moveTo.uuid,
        name: task.moveTo.zoneName
      };
    }
    if (task.fuelLevel) {
      if (!taskToBeSubmit.fuelLevel) {
        taskToBeSubmit.fuelLevel = {}; // Initialize fuelLevel if it doesn't exist
      }

      taskToBeSubmit.fuelLevel.uuid = task.fuelLevel.uuid;
      taskToBeSubmit.fuelLevel.name = task.fuelLevel.name;
      taskToBeSubmit.fuelLevel.fuelLevel = task.fuelLevel.fuelLevel;
   

    }
    else {

      taskToBeSubmit.fuelLevel = null;
    }
    if (task.moveFrom) {
      taskToBeSubmit.moveFrom = {
        uuid: task.moveFrom.uuid,
        name: task.moveFrom.zoneName
      };
    }
    if (task.asset != null) {
      if (task.asset.equipment === undefined || task.asset.equipment === "") {
        task.asset.equipment = null;
        // task.asset["@type"] = this.state.defaultEquipmentType.equipmentType;
        task.asset.equipment = this.state.defaultEquipmentType;
      } else if (
        task.asset.equipment !== null &&
        task.asset.equipment.baseEquipment !== null &&
        task.asset.equipment.baseEquipment !== undefined
      ) {
        // task.asset["@type"] = task.asset.equipment.baseEquipment.equipmentType;
      }

      // if (task.asset.__isNew__ || !task.asset.domicile) {
      //   task.asset.domicile = this.props.currentLocation;
      // }
    }
    // else {
    //   if (task.assetType) {
    //     task.asset = {
    //       // "@type": task.assetType.baseEquipment.equipmentType,
    //       // domicile: this.props.currentLocation,
    //       // carrier: task.carrier,
    //       equipment: task.assetType,
    //     };
    //   }

    // if(!task.yardStatusCheck){
    //   task.yardStatusCheck = false;
    // }

    if (task.asset) {
      taskToBeSubmit.asset = {
        label: task.asset.label,
        value: task.asset.value,
        __isNew__: task.asset.__isNew__,
        assetId: task.asset.assetId,
        uuid: task.asset.uuid,
        equipment: {
          uuid: task.asset.equipment.uuid,
          name: task.asset.equipment.name,
          baseEquipment: {
            uuid: task.asset.equipment.baseEquipment.uuid,
            equipmentType: task.asset.equipment.baseEquipment.equipmentType
          }
        }
      }
      taskToBeSubmit.assetType = task.asset.equipment.name;
    }
    // else {
    //   if (task.assetType) {
    //     task.asset = {
    //       "@type": task.assetType.baseEquipment.equipmentType,
    //       // domicile: this.props.currentLocation,

    //       equipment: task.assetType,
    //     };
    //     taskToBeSubmit.asset = task.asset;
    //   }
    // }
    if (navigator.geolocation) {
      let positionOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60 * 60 * 1000,
      };
      navigator.geolocation.getCurrentPosition(
        (position) => {
          task.latitude = position.coords.latitude;
          task.longitude = position.coords.longitude;





        },
        (error) => {
          this.setState({ loaded: false });
          switch (error.code) {
            case error.PERMISSION_DENIED:
              showError('User denied the request for Geolocation.Please allow.');
              break;
            case error.POSITION_UNAVAILABLE:
              showError('Location information is unavailable.Please try again!');
              break;
            case error.TIMEOUT:
              showError('The request to get user location timed out.Please refresh!');
              break;
            case error.UNKNOWN_ERROR:
              showError('An unknown error occurred.');
              break;
          }
        },
        positionOptions
      );
    }

    // It will only save if Lat Long are coming
    if (task.actualTemp === "") {
      task.actualTemp = null;
    }
    if (task.tempSetPoint === "") {
      task.tempSetPoint = null;
    }
    if (
      this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" ||
      this.props.company.temperatureUnitsOfMeasure !== "Celsius"
    ) {
      task.temperatureSetPointInC = convertFtoC(task.tempSetPoint);
      task.temperatureInC = convertFtoC(task.actualTemp);
      this.setState({ taskUnderEdit: task });
    }
    if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
      task.temperatureSetPointInC = task.tempSetPoint;
      task.temperatureInC = task.actualTemp;
      this.setState({ taskUnderEdit: task });
    }
    taskToBeSubmit.tempSetPoint = task.tempSetPoint;
    taskToBeSubmit.actualTemp = task.actualTemp;
    taskToBeSubmit.temperatureSetPointInC = task.temperatureSetPointInC;
    taskToBeSubmit.temperatureInC = task.temperatureInC;
    taskToBeSubmit.comments = task.comments;
    taskToBeSubmit.shipmentNumber = task.shipmentNumber;
    taskToBeSubmit.sealed = task.sealed === true ? task.sealed : false;
    taskToBeSubmit.sealNumber = task.sealNumber;
    taskToBeSubmit.enabled = task.enabled;
    taskToBeSubmit.completedManually = task.completedManually;
    taskToBeSubmit.shift = task.shift;
    taskToBeSubmit.company = this.props.company;
    taskToBeSubmit.uuid = task.uuid;
    taskToBeSubmit.assignDate = task.assignDate;
    taskToBeSubmit.acceptDate = this.convertDateTime(task.acceptDate, this.state.taskUnderEdit.location.timezone);
    taskToBeSubmit.startDate = this.convertDateTime(task.start, this.state.taskUnderEdit.location.timezone);
    taskToBeSubmit.endDate = this.convertDateTime(task.end, this.state.taskUnderEdit.location.timezone);
    taskToBeSubmit.latitude = task.latitude;
    taskToBeSubmit.longitude = task.longitude;

    var secondaryRef = null;

    if (this.state.taskUnderEdit.secondaryRef !== null && this.state.taskUnderEdit.secondaryRef !== '' && this.state.taskUnderEdit.secondaryRef !== undefined) {
      secondaryRef = this.state.taskUnderEdit.secondaryRef.valueSubmit
      taskToBeSubmit.secondaryRef = secondaryRef;
    } else {
      taskToBeSubmit.secondaryRef = secondaryRef;
    }
    // add updated
    taskToBeSubmit.taskVersion = this.state.taskUnderEdit.taskVersion;
    // add priority
    if (task.priority) {
      taskToBeSubmit.priority = task.priority.value;
    }
    if (task.reefer) {


      taskToBeSubmit.reefer = task.reefer;
    
    }

    else {
      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.reefer = false;

      }
    }
    if (task.continous) {
      taskToBeSubmit.continous = task.continous;
    
    }
    else {

      if ((task.asset &&
        task.asset.equipment &&
        task.asset.equipment.reefer) ||
        (task.assetType &&
          task.assetType.reefer) ||
        (this.state.defaultEquipmentType &&
          this.state.defaultEquipmentType.reefer == true &&
          this.state.newAsset === true)) {
        taskToBeSubmit.continous = false;

      }
    } 
   

    saveTask(
      taskToBeSubmit,
      (response) => {
        if (response.code === '1000') {
          showError(response.message);
        }
        postUserDefaultLocation(taskToBeSubmit.location.uuid)
        this.props.setCurrentLocation(taskToBeSubmit.location);
        this.setState({ touched: {} });
        this.setState({ SpotonSite: false });

        showInfo("Task saved.");


        let path = this.props.location.pathname;
        path = path.split('/');
        path = path.filter(it => it !== "");
        if (path[2] === "onsite" && val !== true) {
          this.props.history.push("/poweryard-onsite");
        } else {
          if (val === true) {
            let taskUnderEdit = {
              name: "",
              location: this.props.currentLocation,
              enabled: true,
              isNew: true,
              completedManually: false,
              comments: "",
              boxedIn: "",
              type: this.state.defaultTaskType,
              assetStatus: this.state.defaultAssetStatus,
              priority: priorityOptions[1],
            };
            this.setState({ taskUnderEdit: taskUnderEdit });
            let s = { ...this.state.taskUnderEdit };
            if (s.boxedIn == "") {
              s.boxedIn = null;
            }
            this.setState({ taskUnderEdit: s, showSealNumber: false });
            this.props.history.push("/task/new");
          } else {
            if (this.state.isonsite) {
              this.props.history.push("/poweryard-onsite");

              this.setState({ isonsite: false });
            }
            else { this.props.history.push("/poweryard-tasks"); }
          }
        }


        this.setState({
          loaded: false,
          loaderMessage: "",
        });
      },
      () => {
        this.setState({
          loaded: false,
          loaderMessage: "",
        });
        showError("Unable to save task.");
      }
    );
    this.setNewVariable(false);
  }
  convertDateTime(tdate, timezone) {
    if (tdate !== undefined && tdate !== null) {
      const datetime = moment(tdate);
      const date = datetime.date();
      const month = datetime.month();
      const year = datetime.year();
      const hours = datetime.hours();
      const minutes = datetime.minutes();
      const seconds = datetime.seconds();
      const millisecond = datetime.millisecond();

      const localDate = moment(datetime);

      // Convert the date to the "America/Chicago" timezone
      const convertedDate = localDate.clone().tz(timezone);

      convertedDate.set({
        year: year,
        month: month,
        date: date,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        millisecond: millisecond
      });
      return convertedDate.toISOString();
    } else {
      return null;
    }

  }
  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }
  validationSchema(values) {
    if (values.acceptDate && values.acceptDate === "Invalid Date") {
      values.acceptDate = null;
    }
    if (values.start && values.start === "Invalid Date") {
      values.start = null;
    }
    if (values.end && values.end === "Invalid Date") {
      values.end = null;
    }
    const NumberRegex = /^[0-9\b]+$/;
    let validationRules = {
      type: Yup.string().required("Task Type is required."),
      asset: Yup.object().nullable(),
      boxedIn: Yup.number()
        .typeError("Number must be between 1 to 9")
        .positive("Number must be between 1 to 9")
        .max(9, ({ max }) => "Number must be between 1 to 9")
        .min(1, ({ min }) => "Number must be between 1 to 9")
        .integer("Number must be between 1 to 9")
        .nullable(),
      assignee: Yup.object().nullable(),
      tempSetPoint: Yup.string()
        .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.9)')
        .nullable(true)
        .transform((_, val) => val === val ? val : null),
      actualTemp: Yup.string()
        .max(999.9, ({ max }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .min(-999.9, ({ min }) => "Invalid Entry, maximum of 1 digit after the decimal (999.9)")
        .matches(/^(?:^-?\d{1,3}(\.\d{1,1})?)?$/, 'Invalid Entry, maximum of 1 digit after the decimal (999.9)')
        .nullable(true)
        .transform((_, val) => val === val ? val : null),
      acceptDate: Yup.date()
        .nullable(),
      start: Yup.date()
        .nullable()
        .min(
          Yup.ref("acceptDate"),
          ({ min }) =>
            `Task Start Timestamp needs to be after the assignment time.`
        ),
      end: Yup.date()
        .nullable()
        .min(
          Yup.ref("start"),
          ({ min }) =>
            `Task End Timestamp needs to be after the Task Start Timestamp.`
        ),
    };

    return Yup.object().shape(validationRules);
  }
  getRequiredCheck(field) {
    if (this.state.requiredFields && this.state.requiredFields.length > 0) {
      let index = this.state.requiredFields.findIndex(it => it.fieldName == field);
      if (index > -1) {
        return true;
      } else {
        return false
      }
    } else {
      return false
    }
  }
  spotHistoryLoaded(history) {
    if (!history || history.totalElements === 0) {
      this.setState({ showSpotHistory: false });
    }
  }
  timezoneShortCode(timezone, inp) {
    if (!inp) inp = new Date();
    if (timezone) {
      return moment(inp).tz(timezone).format("z");
    } else {
      return moment(inp).tz(moment.tz.guess()).format("z");
    }
  }
  handleInputChange(newValue) {

    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };
  onTypingRefNumber() {
    getAllSecondaryReferenceByRefID(this.state.secRefNumber)
  }
  onChangeRefNum(obj) {
    let taskUnderEditObj = { ...this.state.taskUnderEdit };
    getTaskReferenceByRefID(obj.value,
      (success) => {

        if (success.code === "200") {
          let response = success.content;
          if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
            response.tempSetPoint = convertCtoF(response.temperatureSetPointInC);
            if (response.tempSetPoint !== null) {
              response.tempSetPoint = this.getFlooredFixed(response.tempSetPoint, 1);
            }
            response.actualTemp = convertCtoF(response.temperatureInC);
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(response.actualTemp, 1);
            }
          }
          if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
            response.tempSetPoint = response.temperatureSetPointInC;
            if (response.temperatureSetPointInC !== null) {
              response.tempSetPoint = this.getFlooredFixed(response.tempSetPoint, 1);
            }
            response.actualTemp = response.temperatureInC;
            if (response.actualTemp !== null) {
              response.actualTemp = this.getFlooredFixed(response.actualTemp, 1);
            }
          }
          // let task = taskUnderEditObj;
          let task = response;
          task.uuid = taskUnderEditObj.uuid;
          task.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          task.acceptDate = taskUnderEditObj.acceptDate;
          task.start = taskUnderEditObj.start;
          task.end = taskUnderEditObj.end;
          task.type = taskUnderEditObj.type;
          var secRefObj = {
            value: obj.value,
            label: obj.label,
            uuid: obj.uuid,
            valueSubmit: task.secondaryRef
          }
          task.moveTo = task.moveTo === null ? {} : task.moveTo;
          task.secondaryRef = secRefObj;
          this.setState({ taskUnderEdit: task });
          // success.content.isNew = taskUnderEditObj.isNew === undefined ? false : taskUnderEditObj.isNew;
          // success.content.uuid = taskUnderEditObj.uuid;
          // success.content.acceptDate = taskUnderEditObj.acceptDate;
          // success.content.startDate = taskUnderEditObj.startDate;
          // success.content.endDate = taskUnderEditObj.endDate;
          // success.content.type = taskUnderEditObj.type === undefined ? {} : taskUnderEditObj.type;
          // // for temperature converting 
          // if (this.props.company.temperatureUnitsOfMeasure === "Fahrenheit" || this.props.company.temperatureUnitsOfMeasure !== "Celsius") {
          //   success.content.tempSetPoint = convertCtoF(success.content.temperatureSetPointInC);
          //   if (success.content.tempSetPoint !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = convertCtoF(success.content.temperatureInC);
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // if (this.props.company.temperatureUnitsOfMeasure === "Celsius") {
          //   success.content.tempSetPoint = success.content.temperatureSetPointInC;
          //   if (success.content.temperatureSetPointInC !== null) {
          //     success.content.tempSetPoint = this.getFlooredFixed(success.content.tempSetPoint, 1);
          //   }
          //   success.content.actualTemp = success.content.temperatureInC;
          //   if (success.content.actualTemp !== null) {
          //     success.content.actualTemp = this.getFlooredFixed(success.content.actualTemp, 1);
          //   }
          // }
          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: success.content.secondaryRef
          // }
          // success.content.moveTo = success.content.moveTo === null ? {} : success.content.moveTo;
          // success.content.secondaryRef = secRefObj;
          // this.setState({ taskUnderEdit: success.content, showSealNumber: success.content.sealed })
        }
        if (success.code === "500") {

          // var secRefObj = {
          //   value: obj.value,
          //   label: obj.label,
          //   uuid: obj.uuid,
          //   valueSubmit: obj.valueSubmit
          // }
          // let errors = {
          //   boxedIn: true
          // }
          // let touched = {
          //   boxedIn: true
          // }
          // this.setState({
          //   taskUnderEdit: {
          //     name: "",
          //     location: this.props.currentLocation,
          //     enabled: true,
          //     isNew: true,
          //     completedManually: false,
          //     secondaryRef: secRefObj,
          //     boxedIn: null,
          //     type: objType === undefined ? {} : objType,
          //   },
          //   errors: errors,
          //   touched: touched,
          //   showSealNumber: false,
          // })
          console.log(`${success.code} : no data against this ${obj.value}`)
        }
        else if (success.code === "1000") {
          showError("Something Went Wrong");
        }
      }, (error) => {

        console.log(error);
      })
  }
  handleSubmit = (e) => {
    let errors = this.validate(this.validationSchema.bind(this))(this.state.taskUnderEdit);
    let errorsIsEmpty =
      Object.keys(errors).length === 0 &&
      errors.constructor === Object;
    if (!errorsIsEmpty || this.state.errors.name) {
      let t = { ...this.state.touched };
      t.type = true;
      t.assetType = true;

      this.setState({ touched: t, errors: errors });
      e.preventDefault();
      return;
    }
    if (this.props.match.params.uuid === "new") {
      this.updateSpinner("Saving ...");
    } else {
      this.updateSpinner("Updating ...");
    }
    setTimeout(() => {
      this.onSubmit(this.state.newCheck);
    }, 200);
    e.preventDefault();
  }

  render() {
    console.log(this.state.taskUnderEdit.asset, `this .state. task under edit`)
    return (
      <>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        <Loader show={this.state.loaded} message={this.spinner}>
          <CancelTaskModal
            open={this.state.cancelModalOpen}
            defaultReasonCode={this.state.defaultCancelReasonCode}
            currentLocation={this.props.currentLocation}
            loaded={this.state.loaded}
            onSave={(reasonCode) => {
              const data = this.props.position;
              data.cancelReasonCode = reasonCode;
              let o = {
                uuid: this.state.taskUnderEdit.uuid,
                latitude: data.latitude,
                longitude: data.longitude,
                cancelReason: data.cancelReasonCode,
              };
              putTaskCancel(
                o,
                data,
                () => {
                  // this.tableRef.current.fetchData();
                  this.setState({ cancelModalOpen: false });
                  showInfo("Task Canceled.");
                  this.props.history.push("/poweryard-tasks");
                },
                () => { }
              );
            }}
            onCancel={() => this.setState({ cancelModalOpen: false })}
          />
          <div className="text-left p-5">
            <h2 className="dispatch-title">
              {this.props.match.params.uuid === "new" ? this.state.isonsite ? "Create Move Me " : "Create New " : "Edit "}
              Task
            </h2>
            <div>
              <Form onSubmit={this.handleSubmit}>
                <Card className="simple-card">
                  <CardBody>
                    <div>
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={false}
                        // fieldname="location"
                        label="Current Location"
                        icon="fa-warehouse"
                        value={
                          this.state.taskUnderEdit.location
                            ? this.state.taskUnderEdit.location.nickname
                            : ""
                        }
                        required={false}
                        loadOptions={promiseAuthorizedLocationSearch}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.location.enableTaskAssigment =
                            v.enableTaskAssigment;
                          s.location.enableTaskInProcess =
                            v.enableTaskInProcess;
                          s.location.name = v.name;
                          s.location.nickname = v.nickname;
                          s.location.timezone = v.timezone;
                          s.location.uuid = v.value;
                          let touched = { ...this.state.touched };
                          touched.location = true;
                          this.setState({ taskUnderEdit: s, touched: touched });
                        }}
                      />

                      {this.state.priorityAllowed && (
                        <FFFormSelectField
                          key={"ID_" + (this.state.taskUnderEdit.priority ? this.state.taskUnderEdit.priority : "")}
                          fieldname="priority"
                          label="Task Priority"
                          icon="fa-exclamation-circle"
                          required={false}
                          isDisabled={this.state.priorityEditAllowed}
                          placeholder={
                            this.state.taskUnderEdit.priority === undefined ? "Select Task Priority" : ""
                          }
                          value={
                            this.state.taskUnderEdit.priority
                              ? this.state.taskUnderEdit.priority.label
                              : "Normal"
                          }
                          options={priorityOptions}
                          onBlur={() => {
                            let errors = this.validate(this.validationSchema.bind(this))(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.priority = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.priority}
                          touched={this.state.touched.priority}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.priority = v;
                            let touched = { ...this.state.touched };
                            touched.priority = true;

                            this.state.taskUnderEdit = s;
                            this.state.touched = touched;

                          }}
                        />
                      )}

                      {/* {this.props.user.roles[0].role !==
                        "ROLE_POWERYARD_JOCKEY" && (
                          <FFFormAsyncSelectField
                            key={
                              "ID_" +
                              (this.state.taskUnderEdit.location
                                ? this.state.taskUnderEdit.location.uuid
                                : "")
                            }
                            defaultOptions={true}
                            isClearable={
                              this.state.taskUnderEdit.taskStatus === "Complete"
                                ? false
                                : true
                            }
                            fieldname="assignee"
                            label="Assignee"
                            icon="fa-user"
                            checkered={true}
                            value={
                              this.state.taskUnderEdit.assignee
                                ? this.state.taskUnderEdit.assignee.firstname +
                                " " +
                                this.state.taskUnderEdit.assignee.lastname
                                : ""
                            }
                            isDisabled={!this.state.taskUnderEdit.location.uuid}
                            required={false}
                            loadOptions={getUsersByLocation(
                              this.state.taskUnderEdit.location.uuid
                            )}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.assignee = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            error={this.state.errors.assignee}
                            touched={this.state.touched.assignee}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.assignee = v;
                              if (v === null) {
                                s.assignDate = null;
                                s.acceptDate = null;
                              }
                              let touched = { ...this.state.touched };
                              touched.assignee = true;
                              this.setState({
                                taskUnderEdit: s,
                                touched: touched,
                              });
                            }}
                          />
                        )} */}
                      {this.props.user.roles[0].role !==
                        "ROLE_POWERYARD_JOCKEY" && (
                          <FFFormAsyncSelectField
                            key={
                              "ID_" +
                              (this.state.taskUnderEdit.location
                                ? this.state.taskUnderEdit.location.uuid
                                : "")
                            }
                            hideEmpty={true}
                            placeholder={
                              this.state.taskUnderEdit.assignee === undefined
                                ? "Select Task Assignee"
                                : ""
                            }
                            defaultOptions={true}
                            isClearable={
                              this.state.taskUnderEdit.taskStatus === "Complete"
                                ? false
                                : true
                            }
                            fieldname="assignee"
                            label="Assignee"
                            icon="fa-user"
                            checkered={true}
                            value={
                              this.state.taskUnderEdit.assignee
                                ? this.state.taskUnderEdit.assignee.firstname +
                                " " +
                                this.state.taskUnderEdit.assignee.lastname
                                : ""
                            }
                            isDisabled={!this.state.taskUnderEdit.location.uuid}
                            required={false}
                            loadOptions={getUsersByLocation(
                              this.state.taskUnderEdit.location.uuid
                            )}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.assignee = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            error={this.state.errors.assignee}
                            touched={this.state.touched.assignee}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.assignee = v;
                              if (v === null) {
                                s.assignDate = null;
                                s.acceptDate = null;
                              }
                              let touched = { ...this.state.touched };
                              touched.assignee = true;
                              this.setState({
                                taskUnderEdit: s,
                                touched: touched,
                              });
                            }}
                          />
                        )}
                      {this.props.user.roles[0].role ===
                        "ROLE_POWERYARD_JOCKEY" && (
                          <FFFormAsyncSelectField
                            key={
                              "ID_" +
                              (this.state.taskUnderEdit.location
                                ? this.state.taskUnderEdit.location.uuid
                                : "")
                            }
                            hideEmpty={true}
                            defaultOptions={true}
                            isClearable={
                              this.state.taskUnderEdit.taskStatus === "Complete"
                                ? false
                                : true
                            }
                            fieldname="assignee"
                            placeholder={
                              this.state.taskUnderEdit.assignee === undefined
                                ? "Select Task Assignee"
                                : ""
                            }
                            label="Assignee"
                            icon="fa-user"
                            value={
                              this.state.taskUnderEdit.assignee
                                ? this.state.taskUnderEdit.assignee.firstname +
                                " " +
                                this.state.taskUnderEdit.assignee.lastname
                                : ""
                            }
                            required={false}
                            loadOptions={getUsersByLocation(
                              this.state.taskUnderEdit.location.uuid
                            )}
                            onBlur={() => {
                              let errors = this.validate(
                                this.validationSchema.bind(this)
                              )(this.state.taskUnderEdit);
                              let touched = { ...this.state.touched };
                              touched.assignee = true;
                              this.setState({ errors: errors, touched: touched });
                            }}
                            error={this.state.errors.assignee}
                            touched={this.state.touched.assignee}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.assignee = v;
                              let touched = { ...this.state.touched };
                              touched.assignee = true;
                              this.setState({
                                taskUnderEdit: s,
                                touched: touched,
                              });
                            }}
                          />
                        )}

                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        fieldname="type"
                        label="Task Type"
                        hideEmpty={true}
                        placeholder="Select the Task Type from Drop-Down (required)"
                        icon="fa-keynote"
                        isClearable={false}
                        value={
                          this.state.taskUnderEdit.type
                            ? this.state.taskUnderEdit.type.name
                            : ""
                        }
                        required={true}
                        loadOptions={promiseTaskTypeByLocationUUid(
                          this.props.currentLocation.uuid
                        )}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };

                          touched.type = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.type}
                        touched={this.state.touched.type}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.type = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      {this.props.currentLocation.apiSetting !== null &&
                        this.props.currentLocation.apiSetting !== undefined &&
                        this.props.currentLocation.apiSetting.refernceApi && (
                          <FFFormLabelButton
                            label=""
                            isDisabled={
                              this.state.secRefNumber === null ? true : false
                            }
                            btnColor={"primary"}
                            isLblShow={false}
                            btnTxt="Reference Data"
                            fieldname="clientSecret"
                            onClick={() =>
                              this.openRefModal(
                                this.state.selectedTask.refData,
                                this.state.taskUnderEdit.secondaryRef.value
                              )
                            }
                          />
                        )}
                      {this.props.currentLocation.apiSetting !== null &&
                        this.props.currentLocation.apiSetting !== undefined &&
                        this.props.currentLocation.apiSetting.refernceApi && (
                          <FFFormAsyncSelectField
                            // key={
                            //   "ID_" +
                            //   (this.state.taskUnderEdit.secondaryRef !== null
                            //     ? this.state.taskUnderEdit.secondaryRef.value
                            //     : "")
                            // }
                            // isDisabled={this.state.isNew}
                            isDisabled={false}
                            defaultOptions={false}
                            placeholder="Select Your Company ID for the Shipment on this Trailer"
                            isClearable={true}
                            hideEmpty={true}
                            fieldname="secondaryRef"
                            label="Reference Number"
                            icon="fa-regular fa-hashtag"
                            value={
                              this.state.taskUnderEdit.secondaryRef
                                ? this.state.taskUnderEdit.secondaryRef
                                  .valueSubmit
                                : ""
                            }
                            required={false}
                            loadOptions={
                              this.state.taskUnderEdit.location
                                ? getAllSecondaryReferenceByRefID(
                                  this.state.taskUnderEdit.location.uuid
                                )
                                : getAllSecondaryReferenceByRefID
                            }
                            // isDisabled={this.state.secRefNumber}
                            // onBlur={() => {
                            //   let errors = this.validate(
                            //     this.validationSchema.bind(this)
                            //   )(this.state.taskUnderEdit);
                            //   let touched = { ...this.state.touched };
                            //   touched.secondaryRef = true;
                            //   this.setState({ errors: errors, touched: touched });
                            // }}
                            // error={this.state.errors.secondaryRef}
                            // touched={this.state.touched.secondaryRef}
                            onChange={(v) => {
                              let s = { ...this.state.taskUnderEdit };
                              s.secondaryRef = v;
                              if (v !== null) {
                                if (s.isNew) {
                                  this.onChangeRefNum(v);
                                }
                                this.setState({
                                  taskUnderEdit: s,
                                  secRefNumber: v.valueSubmit,
                                });
                              } else {
                                this.setState({
                                  taskUnderEdit: s,
                                  secRefNumber: null,
                                });
                              }

                              // let touched = {...this.state.touched};
                              // touched.assignee = true;
                              // this.setState({taskUnderEdit: s, touched: touched });
                            }}
                          />
                        )}
                      {/* <FFFormSelectField
                      key={"ID_taskType"}
                      
                      value={
                        this.state.taskUnderEdit.type &&
                        this.state.taskUnderEdit.type.name
                      }
                      options={this.state.taskTypeOptions}
                      checkered={true}
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.taskUnderEdit);
                        let touched = { ...this.state.touched };
                        touched.type = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.type}
                      touched={this.state.touched.type}
                      onChange={(v) => {
                        let s = { ...this.state.taskUnderEdit };
                        s.type = v;
                        this.setState({ taskUnderEdit: s });
                      }}
                    /> */}
                      {this.state.isonsite ? <FFFormTextField
                        fieldname="carrier"
                        label="Trailer Name"
                        placeholder="Select the Trailer Carrier Name (from the list)"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset &&
                            this.state.taskUnderEdit.asset.carrier
                            ? this.state.taskUnderEdit.asset.carrier.name
                            : this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.name
                              : ""
                        }
                        isClearable={false}
                        readOnly={true}
                        required={false}
                        isonsite={this.state.isonsite}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.carrier = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.carrier}
                        touched={this.state.touched.carrier}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (
                            v == null ||
                            (s.carrier && s.carrier.uuid !== v.uuid)
                          ) {
                            s.asset = null;
                          }
                          if (v && v.__isNew__) {
                            v.name = v.label;
                          }
                          if (s.asset) {
                            s.asset.carrier = v;
                          }
                          s.carrier = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      /> : <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={true}
                        fieldname="carrier"
                        label="Trailer Name"
                        hideEmpty={true}
                        isDisabled={this.state.isonsite == true ? true : false}
                        placeholder="Select the Trailer Carrier Name (from the list)"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset &&
                            this.state.taskUnderEdit.asset.carrier
                            ? this.state.taskUnderEdit.asset.carrier.name
                            : this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.name
                              : ""
                        }
                        // loadOptions={promiseCarriersByPrefix}
                        loadOptions={
                          this.props.currentLocation
                            ? carriersByLocationUUid(
                              this.props.currentLocation.uuid
                            )
                            : carriersByLocationUUid
                        }
                        checkered={true}
                        required={false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.carrier = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.carrier}
                        touched={this.state.touched.carrier}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (
                            v == null ||
                            (s.carrier && s.carrier.uuid !== v.uuid)
                          ) {
                            s.asset = null;
                          }
                          if (v && v.__isNew__) {
                            v.name = v.label;
                          }
                          if (s.asset) {
                            s.asset.carrier = v;
                          }
                          s.carrier = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />}
                      {/* <FFFormSelectCreatableField
                        defaultOptions={true}
                        fieldname="carrier"
                        label="Trailer Name"
                        hideEmpty={true}
                        placeholder="Select the Trailer Carrier Name (from the list)"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset &&
                            this.state.taskUnderEdit.asset.carrier
                            ? this.state.taskUnderEdit.asset.carrier.name
                            : this.state.taskUnderEdit.carrier
                              ? this.state.taskUnderEdit.carrier.name
                              : ""
                        }
                        // loadOptions={promiseCarriersByPrefix}
                        loadOptions={
                          this.props.currentLocation
                            ? carriersByLocationUUid(
                              this.props.currentLocation.uuid
                            )
                            : carriersByLocationUUid
                        }
                        checkered={true}
                        required={false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.carrier = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.carrier}
                        touched={this.state.touched.carrier}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (
                            v == null ||
                            (s.carrier && s.carrier.uuid !== v.uuid)
                          ) {
                            s.asset = null;
                          }
                          if (v && v.__isNew__) {
                            v.name = v.label;
                          }
                          if (s.asset) {
                            s.asset.carrier = v;
                          }
                          s.carrier = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      /> */}
                      {this.state.isonsite ? <FFFormTextField
                        fieldname="asset.assetId"
                        label="Trailer Number"
                        placeholder="Input/Select Trailer Number (Type Ahead, Drop Down)"
                        icon="fa-truck"
                        value={
                          this.state.taskUnderEdit.asset && this.state.taskUnderEdit.asset.assetId
                            ? this.state.taskUnderEdit.asset.assetId.replace(/ /g, '').toUpperCase()
                            : ""
                        }
                        isClearable={false}
                        readOnly={true}
                        required={false}
                        number={true}
                        isonsite={this.state.isonsite}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.carrier = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.carrier}
                        touched={this.state.touched.carrier}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (
                            v == null ||
                            (s.carrier && s.carrier.uuid !== v.uuid)
                          ) {
                            s.asset = null;
                          }
                          if (v && v.__isNew__) {
                            v.name = v.label;
                          }
                          if (s.asset) {
                            s.asset.carrier = v;
                          }
                          s.carrier = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      /> : <FFFormSelectCreatableField
                        key={
                          "ID_" +
                          (this.state.taskUnderEdit.carrier
                            ? this.state.taskUnderEdit.carrier.uuid
                            : "")
                        }
                        fieldname="asset.assetId"
                        label="Trailer Number"
                        placeholder="Input/Select Trailer Number (Type Ahead, Drop Down)"
                        icon="fa-truck"
                        hideEmpty={true}
                        value={
                          this.state.taskUnderEdit.asset && this.state.taskUnderEdit.asset.assetId
                            ? this.state.taskUnderEdit.asset.assetId.replace(/ /g, '').toUpperCase()
                            : ""
                        }
                        isClearable={true}
                        isDisabled={!this.state.taskUnderEdit.carrier}
                        defaultOptions={true}
                        loadOptions={promiseAssetByPrefix(
                          this.state.taskUnderEdit.carrier
                            ? this.state.taskUnderEdit.carrier.uuid
                            : ""
                        )}
                        checkered={true}
                        required={false}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.asset = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.asset}
                        touched={this.state.touched.asset}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.asset = v;
                          s.assetType =
                            v && v.equipment ? v.equipment.name : "";
                          if (v && v.__isNew__ === true) {
                            s.asset.assetId = s.asset.assetId.replace(/ /g, '').toUpperCase()
                            s.asset.label = s.asset.label.replace(/ /g, '').toUpperCase()
                            s.asset.value = s.asset.value.replace(/ /g, '').toUpperCase()
                          }
                          if (
                            v &&
                            v.latestTaskSpot != null &&
                            v.latestTaskSpot.location &&
                            v.latestTaskSpot.location.uuid ===
                            this.props.currentLocation.uuid
                          ) {
                            s.moveFrom = v.latestTaskSpot;
                          }
                          if (
                            this.state.defaultEquipmentType
                              .temperatureControlled === true &&
                            v &&
                            v.__isNew__ === true
                          ) {
                            console.log(v, `v in in__new`)
                            v.assetId = v.assetId.replace(/ /g, '').toUpperCase()
                            v.label = v.label.replace(/ /g, '').toUpperCase()
                            v.value = v.value.replace(/ /g, '').toUpperCase()
                            this.setState({
                              newAsset: true,
                            });
                          } else {
                            this.setState({
                              newAsset: false,
                            });
                          }

                          if( v.equipment&&!v.equipment.temperatureControlled){
                              s.tempSetPoint = null;
                              s.actualTemp = null;

                            }
                            if ( v.equipment&&!v.equipment.reefer) {
                              s.fuelLevel = null;
                              s.reefer = null;
                              s.continous = null;

                            }
                          this.setState({ taskUnderEdit: s });
                        }}
                      ></FFFormSelectCreatableField>}
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={false}
                        fieldname="assetType"
                        label="Asset Type"
                        icon="fa-spot"
                        value={this.getAssetType()}
                        required={false}
                        hideEmpty={true}
                        placeholder="Asset Type - Select the Trailer Type"
                        // placeholder={this.state.defaultEquipmentType}
                        loadOptions={
                          this.props.currentLocation
                            ? promiseEZTrackEquipmentTypeByPrefix(
                              this.props.currentLocation.uuid
                            )
                            : promiseEZTrackEquipmentTypeByPrefix
                        }
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.assetType = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.assetType}
                        touched={this.state.touched.assetType}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          if (s.asset) {
                            s.asset.equipment =
                              v === null ? this.state.defaultEquipmentType : v;
                          }
                          s.assetType = v;
                          if (v !== null) {
                            if (s.assetType.temperatureControlled !== true) {
                              s.tempSetPoint = null;
                              s.actualTemp = null;
                            }
                            if (s.assetType.reefer !== true) {
                              s.fuelLevel = null;
                              s.reefer = null;
                              s.continous = null;

                            }
                          }
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        fieldname="assetStatus"
                        label="Asset Status"
                        hideEmpty={true}
                        placeholder="Select the status of the trailer (from the list)"
                        icon="fa-balance-scale-left"
                        isClearable={true}
                        value={
                          this.state.taskUnderEdit.assetStatus
                            ? this.state.taskUnderEdit.assetStatus.name
                            : ""
                        }
                        required={false}
                        loadOptions={promiseAssetStatusesByLocationUUid(
                          this.props.currentLocation.uuid
                        )}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };

                          touched.assetStatus = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.assetStatus}
                        touched={this.state.touched.assetStatus}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.assetStatus = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />

                      {((this.state.taskUnderEdit.asset &&
                        this.state.taskUnderEdit.asset.equipment &&
                        this.state.taskUnderEdit.asset.equipment
                          .reefer) ||
                        (this.state.taskUnderEdit.assetType &&
                          this.state.taskUnderEdit.assetType
                            .reefer) ||
                        (this.state.defaultEquipmentType &&
                          this.state.defaultEquipmentType.reefer == true &&
                          this.state.newAsset === true)) && (
                          <>
                            <FFFormAsyncSelectField
                              defaultOptions={true}
                              fieldname="fuelLevel"
                              label="Fuel Level"
                              hideEmpty={true}
                              placeholder="Select the fuel Level of the trailer (from the list)"
                              icon="fa-solid fa-gas-pump"
                              isClearable={true}
                              value={
                                this.state.taskUnderEdit.fuelLevel
                                  ? this.state.taskUnderEdit.fuelLevel.label
                                  : ""
                              }
                              required={false}
                              loadOptions={promiseEZTrackEquipmentFuel}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };

                                touched.fuelLevel = true;
                                this.setState({ errors: errors, touched: touched });
                              }}
                              error={this.state.errors.fuelLevel}
                              touched={this.state.touched.fuelLevel}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.fuelLevel = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />
                            <FFFormCheckboxField
                              fieldname="reefer"
                              icon="fa-power-off"

                              label="Reefer (On/Off )"
                              required={false}
                              value={
                                this.state.taskUnderEdit.reefer == null
                                  ? false
                                  : this.state.taskUnderEdit.reefer
                              }
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.reefer = v;
                                if (!v) { // If Reefer is false, set Continous to false as well
                                  s.continous = false;
                                }
                                this.setState({
                                  taskUnderEdit: s,
                                });
                              }}
                            />
                            <FFFormCheckboxField
                              fieldname="continous"
                              icon="fa-power-off"
                              label="Continuous (Yes/No)"

                              disabled={!this.state.taskUnderEdit.reefer}
                              required={false}
                              value={
                                this.state.taskUnderEdit.continous == null
                                  ? false
                                  : this.state.taskUnderEdit.continous
                              }
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.continous = v;
                                this.setState({
                                  taskUnderEdit: s,
                                });
                              }}
                            />



                          </>
                        )}
                      {((this.state.taskUnderEdit.asset &&
                        this.state.taskUnderEdit.asset.equipment &&
                        this.state.taskUnderEdit.asset.equipment
                          .temperatureControlled) ||
                        (this.state.taskUnderEdit.assetType &&
                          this.state.taskUnderEdit.assetType
                            .temperatureControlled) ||
                        this.state.newAsset === true) && (
                          <>

                            <FFFormTextField
                              fieldname="tempSetPoint"
                              isClearable={true}
                              label={
                                "Temp - Set Point (" +
                                (this.props.company.temperatureUnitsOfMeasure ===
                                  "Fahrenheit"
                                  ? "℉"
                                  : "℃") +
                                ")"
                              }
                              placeholder="Enter the temperature set point"
                              icon="fa-temperature-low"
                              value={this.state.taskUnderEdit.tempSetPoint}
                              numbersOnly={false}
                              required={false}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };
                                touched.tempSetPoint = true;
                                this.setState({ errors: errors, touched: touched });
                              }}
                              error={this.state.errors.tempSetPoint}
                              touched={this.state.touched.tempSetPoint}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.tempSetPoint = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />
                            <FFFormTextField
                              fieldname="actualTemp"
                              isClearable={true}
                              label={
                                "Temp - Actual (" +
                                (this.props.company.temperatureUnitsOfMeasure ===
                                  "Fahrenheit"
                                  ? "℉"
                                  : "℃") +
                                ")"
                              }

                              placeholder="Enter the actual temperature"
                              icon="fa-temperature-high"
                              value={this.state.taskUnderEdit.actualTemp}
                              numbersOnly={false}
                              required={false}
                              onBlur={() => {
                                let errors = this.validate(
                                  this.validationSchema.bind(this)
                                )(this.state.taskUnderEdit);
                                let touched = { ...this.state.touched };
                                touched.actualTemp = true;
                                this.setState({ errors: errors, touched: touched });
                              }}
                              error={this.state.errors.actualTemp}
                              touched={this.state.touched.actualTemp}
                              onChange={(v) => {
                                let s = { ...this.state.taskUnderEdit };
                                s.actualTemp = v;
                                this.setState({ taskUnderEdit: s });
                              }}
                            />


                          </>
                        )}

                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={true}
                        fieldname="moveFrom"
                        label="Move From Spot"
                        placeholder="Select Move From Spot (Type Ahead, Drop Down)"
                        icon="fa-play"
                        value={
                          this.state.taskUnderEdit.moveFrom
                            ? this.state.taskUnderEdit.moveFrom.uuid &&
                              this.state.taskUnderEdit.moveFrom.name
                              ? this.state.taskUnderEdit.moveFrom.zone +
                              " - " +
                              this.state.taskUnderEdit.moveFrom.name
                              : this.state.taskUnderEdit.moveFrom.zone
                            : null
                        }
                        hideEmpty={true}
                        required={false}
                        loadOptions={
                          this.props.currentLocation &&
                          promiseSpotForLocationByZonePrefixOrSpotPrefix(
                            this.props.currentLocation.uuid
                          )
                        }
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.moveFrom = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.moveFrom}
                        touched={this.state.touched.moveFrom}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.moveFrom = v;
                          let touched = { ...this.state.touched };
                          touched.moveFrom = true;
                          this.setState({ taskUnderEdit: s, touched: touched });
                        }}
                      />

                      <FFFormTextField
                        fieldname="boxedIn"
                        label={"Boxed In"}
                        placeholder="Enter the number of boxing assets"
                        icon="fa-box"
                        required={false}
                        value={this.state.taskUnderEdit.boxedIn}
                        numbersOnly={true}
                        isClearable={true}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.boxedIn = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.boxedIn}
                        touched={this.state.touched.boxedIn}
                        onChange={(v) => {
                          if (v === "") {
                            v = null;
                          }
                          let s = { ...this.state.taskUnderEdit };
                          s.boxedIn = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                        max={9}
                        min={1}

                      />

                      <FFFormAsyncSelectField
                        defaultOptions={true}
                        isClearable={true}
                        fieldname="moveTo"
                        label="Move To Spot"
                        placeholder="Select Move To Spot (Type Ahead,  Drop Down)"
                        icon="fa-stop"
                        hideEmpty={true}
                        value={
                          this.state.taskUnderEdit.moveTo
                            ? this.state.taskUnderEdit.moveTo.uuid
                              ? this.state.taskUnderEdit.moveTo.zone +
                              " - " +
                              this.state.taskUnderEdit.moveTo.name
                              : this.state.taskUnderEdit.moveTo.zone
                            : null
                        }
                        required={false}
                        loadOptions={
                          this.props.currentLocation &&
                          promiseSpotForLocationByZonePrefixOrSpotPrefix(
                            this.props.currentLocation.uuid
                          )
                        }
                        onBlur={() => {
                          let touched = { ...this.state.touched };
                          touched.moveTo = true;
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.moveTo}
                        touched={this.state.touched.moveTo}
                        onChange={(v) => {
                          this.setState({ SpotonSite: false });
                          if (this.props.currentLocation.enableSpotAvailability == true) {


                            let s = { ...this.state.taskUnderEdit };
                            let samesopts = false;
                            s.moveTo = v;
                            if (s.moveTo && s.moveFrom && (s.moveTo.uuid == s.moveFrom.uuid)) {
                              samesopts = true;

                            }

                            let touched = { ...this.state.touched };
                            touched.moveTo = true;
                            this.setState({ taskUnderEdit: s, touched: touched });
                            let isany = !s.moveTo ? false : s.moveTo.value.toLowerCase().includes("any") ? false : true;
                            if (!samesopts) {
                              if (v && s.location && s.priority && isany) {
                                console.log(s, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, s.location.uuid, s.priority.value, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });
                              }
                              else if (v && s.priority && isany) {
                                console.log(s, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, s.priority.value, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });
                              }
                              else if (v && s.location && isany) {
                                console.log(s, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, s.location.uuid, 2, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });
                              }
                              else if (v && isany) {
                                console.log(v, "locationuuid")
                                this.setState({ loaded: true })
                                this.updateSpinner("Loading...");
                                clientResponseBySpotUuid(v.uuid, (success) => {
                                  if (success.code === "301") {
                                    this.setState({
                                      SpotonSite: true, SpotonSiteData: success.content.taskDetails
                                    });
                                  }
                                  this.setState({ loaded: false });
                                });


                              }
                            }


                          }

                          else {

                            let s = { ...this.state.taskUnderEdit };
                            s.moveTo = v;
                            let touched = { ...this.state.touched };
                            touched.moveTo = true;
                            this.setState({ taskUnderEdit: s, touched: touched });


                          }

                        }}
                      />
                      <FFFormTextField
                        fieldname="shipmentNumber"
                        label="Shipment Number"
                        placeholder="Enter the shipment number"
                        icon="fa-tag"
                        value={
                          this.state.taskUnderEdit.shipmentNumber
                            ? this.state.taskUnderEdit.shipmentNumber
                            : ""
                        }
                        required={false}
                        onBlur={() => {

                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.shipmentNumber = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        isClearable={true}
                        error={this.state.errors.shipmentNumber}
                        touched={this.state.touched.shipmentNumber}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.shipmentNumber = v;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />
                      <FFFormCheckboxField
                        fieldname="sealed"
                        icon="fa-power-off"
                        label="Sealed"
                        required={false}
                        value={
                          this.state.taskUnderEdit.sealed == null
                            ? false
                            : this.state.taskUnderEdit.sealed
                        }
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.sealed = v;
                          this.setState({
                            taskUnderEdit: s,
                            showSealNumber: v,
                          });
                        }}
                      />
                      {this.state.taskUnderEdit.sealed && (
                        <FFFormTextField
                          fieldname="sealNumber"
                          label="Seal #"
                          placeholder="Enter the seal number"
                          icon="fa-tag"
                          isClearable={true}
                          value={
                            this.state.taskUnderEdit.sealNumber
                              ? this.state.taskUnderEdit.sealNumber
                              : ""
                          }
                          required={false}
                          onBlur={() => {
                            let errors = this.validate(
                              this.validationSchema.bind(this)
                            )(this.state.taskUnderEdit);
                            let touched = { ...this.state.touched };
                            touched.sealNumber = true;
                            this.setState({ errors: errors, touched: touched });
                          }}
                          error={this.state.errors.sealNumber}
                          touched={this.state.touched.sealNumber}
                          onChange={(v) => {
                            let s = { ...this.state.taskUnderEdit };
                            s.sealNumber = v;
                            this.setState({ taskUnderEdit: s });
                          }}
                        />
                      )}
                      <FFFormTextAreaField
                        label="Comments"
                        required={false}
                        placeholder="Enter Task Comments"
                        icon="fa-comment"
                        fieldname="comments"
                        isClearable={true}
                        error={this.state.errors.comments}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.comments = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        touched={this.state.touched.comments}
                        value={this.state.taskUnderEdit.comments}
                        onChange={(event) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.comments =
                            event !== null ? event.target.value : null;
                          this.setState({ taskUnderEdit: s });
                        }}
                        rows={"5"}
                      />
                      <Row>
                        <Col>
                          <p
                            className={"float-right"}
                            style={{
                              color: this.getNumber(300) < 0 ? "red" : null,
                            }}
                          >
                            Text remaining: {this.getNumber(300)}
                          </p>
                        </Col>
                      </Row>
                      <Alert>
                        <span>
                          Only populate the below Timestamp fields if you are
                          recording a task that was completed without triggering
                          the Start and End buttons on the Task page. Assignee
                          must be assigned to enable these fields.
                        </span>
                      </Alert>
                      <FFFormSelectField
                        fieldname="shift"
                        label="Shift"
                        icon="fa-business-time"
                        required={false}
                        value={
                          this.state.taskUnderEdit.shift
                            ? this.state.taskUnderEdit.shift
                            : ""
                        }
                        // required={true}
                        options={["Day", "Mid", "Night"]}
                        onBlur={() => {
                          let errors = this.validate(
                            this.validationSchema.bind(this)
                          )(this.state.taskUnderEdit);
                          let touched = { ...this.state.touched };
                          touched.shift = true;
                          this.setState({ errors: errors, touched: touched });
                        }}
                        error={this.state.errors.shift}
                        touched={this.state.touched.shift}
                        onChange={(v) => {
                          let s = { ...this.state.taskUnderEdit };
                          s.shift = v.value;
                          this.setState({ taskUnderEdit: s });
                        }}
                      />

                      <Button
                        color="warning"
                        className={"mb-3"}
                        type="button"
                        onClick={() => {
                          this.manualCompletion();
                        }}
                      >
                        {this.state.taskUnderEdit.completedManually == false
                          ? "Edit Timestamps"
                          : "Cancel Changes"}
                      </Button>

                      <Collapse
                        isOpen={this.state.taskUnderEdit.completedManually}
                      >
                        <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"Accepted Timestamp (" +
                                  this.timezoneShortCode(
                                    this.state.taskUnderEdit.location &&
                                    this.state.taskUnderEdit.location
                                      .timezone,
                                    this.state.taskUnderEdit.acceptDate
                                  ) +
                                  ")"}
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                disabled={
                                  this.state.taskUnderEdit.assignee ===
                                  undefined ||
                                  this.state.taskUnderEdit.assignee === "" ||
                                  this.state.taskUnderEdit.assignee === null
                                }
                                placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.acceptDate &&
                                  "is-invalid"
                                }
                                selected={this.state.taskUnderEdit.acceptDate}
                                onChange={(date) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.acceptDate = date;
                                  if (
                                    s.acceptDate !== null &&
                                    s.start !== null &&
                                    s.end !== null &&
                                    s.acceptDate !== undefined &&
                                    s.start !== undefined &&
                                    s.end !== undefined
                                  ) {
                                    this.setState({ disableSave: false });
                                  } else {
                                    this.setState({ disableSave: true });
                                  }
                                  // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                                  if (date === null || !isNaN(date.getTime())) {
                                    s.start = null;
                                    s.end = null;
                                    this.setState({ taskUnderEdit: s });
                                  }
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm:ss a"
                                showTimeInput
                                isClearable
                                shouldCloseOnSelect={false}
                              />
                              <FormFeedback
                                className={"d-block"}
                                style={{ marginLeft: 0 }}
                              >
                                {this.state.errors &&
                                  this.state.errors.acceptDate}
                              </FormFeedback>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"Task Start Timestamp (" +
                                  this.timezoneShortCode(
                                    this.state.taskUnderEdit.location &&
                                    this.state.taskUnderEdit.location
                                      .timezone,
                                    this.state.taskUnderEdit.start
                                  ) +
                                  ")"}
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                disabled={
                                  this.state.taskUnderEdit.assignee ===
                                  undefined ||
                                  this.state.taskUnderEdit.assignee === "" ||
                                  this.state.taskUnderEdit.assignee === null ||
                                  this.state.taskUnderEdit.acceptDate === null
                                }
                                placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.start &&
                                  "is-invalid"
                                }
                                selected={this.state.taskUnderEdit.start}
                                onChange={(date) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.start = date;

                                  if (
                                    s.acceptDate !== null &&
                                    s.start !== null &&
                                    s.end !== null &&
                                    s.acceptDate !== undefined &&
                                    s.start !== undefined &&
                                    s.end !== undefined
                                  ) {
                                    this.setState({ disableSave: false });
                                  } else {
                                    this.setState({ disableSave: true });
                                  }
                                  // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion
                                  if (date === null || !isNaN(date.getTime())) {
                                    s.end = null;
                                    this.setState({ taskUnderEdit: s });
                                  }
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm:ss a"
                                showTimeInput
                                isClearable
                                shouldCloseOnSelect={false}
                              />
                              <FormFeedback
                                className={"d-block"}
                                style={{ marginLeft: 0 }}
                              >
                                {this.state.errors && this.state.errors.start}
                              </FormFeedback>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col lg="2" md="3" sm="4">
                              <Label>
                                {"Task End Timestamp (" +
                                  this.timezoneShortCode(
                                    this.state.taskUnderEdit.location &&
                                    this.state.taskUnderEdit.location
                                      .timezone,
                                    this.state.taskUnderEdit.end
                                  ) +
                                  ")"}
                              </Label>
                            </Col>
                            <Col className={"task-form"} lg="10" md="9" sm="8">
                              <DatePicker
                                disabled={
                                  this.state.taskUnderEdit.assignee ===
                                  undefined ||
                                  this.state.taskUnderEdit.assignee === "" ||
                                  this.state.taskUnderEdit.assignee === null ||
                                  this.state.taskUnderEdit.start === null
                                }
                                placeholderText={"MM/DD/YYYY HH:MM:SS AP"}
                                className={
                                  this.state.errors &&
                                  this.state.errors.end &&
                                  "is-invalid"
                                }
                                selected={this.state.taskUnderEdit.end}
                                onChange={(date) => {
                                  let s = { ...this.state.taskUnderEdit };
                                  s.end = date;
                                  if (
                                    s.acceptDate !== null &&
                                    s.start !== null &&
                                    s.end !== null &&
                                    s.acceptDate !== undefined &&
                                    s.start !== undefined &&
                                    s.end !== undefined
                                  ) {
                                    this.setState({ disableSave: false });
                                  } else {
                                    this.setState({ disableSave: true });
                                  }
                                  // if the date is invalid because the user cleared the time component, calling setState will result in infinite recursion

                                  if (date === null || !isNaN(date.getTime())) {
                                    this.setState({ taskUnderEdit: s });
                                  }
                                }}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm:ss a"
                                showTimeInput
                                isClearable
                                shouldCloseOnSelect={false}
                              />
                              <FormFeedback
                                className={"d-block"}
                                style={{ marginLeft: 0 }}
                              >
                                {this.state.errors && this.state.errors.end}
                              </FormFeedback>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Collapse>
                    </div>
                  </CardBody>
                </Card>
                <div className="row">
                  <Col sm={"6"}>
                    {!this.state.isonsite && this.state.taskUnderEdit.isNew !== true &&
                      this.state.taskUnderEdit.taskStatus !== "Canceled" && (
                        <div className="mb-3 text-left">
                          <Button
                            color="danger"
                            onClick={() => {
                              this.setState({
                                cancelTask: this.state.taskUnderEdit,
                                cancelModalOpen: true,
                              });
                            }}
                          >
                            Cancel Task
                          </Button>{" "}
                        </div>
                      )}
                  </Col>
                  <Col sm={"6"}>
                    <div className="mb-3 text-right">
                      <Button color="danger" onClick={() => this.cancelEdit()}>
                        Cancel Changes
                      </Button>{" "}
                      &nbsp;&nbsp;
                      <Button
                        color="primary"
                        type="button"
                        disabled={
                          !(this.getNumber(300) >= 0) ||
                          this.state.disableSave === true
                        }
                        onClick={(e) => {
                          let samespot = this.state.taskUnderEdit.moveFrom &&
                            this.state.taskUnderEdit.moveTo &&
                            this.state.taskUnderEdit.moveFrom.uuid ===
                            this.state.taskUnderEdit.moveTo.uuid ? true : false;
                          if (this.state.SpotonSite == true && !samespot) {


                            confirmAlert({

                              customUI: ({ onClose }) => {
                                return (
                                  <div style={{
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    width: '400px',
                                    padding: '30px',
                                    textAlign: 'left',
                                    background: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: '0 20px 75px rgba(0, 0, 0, .13)',
                                    color: '#666'
                                  }}>
                                    <h1 style={{ color: '#4a6670', fontSize: '24px', fontWeight: '700', lineHeight: '30px' }}>Spot Already Occupied</h1>

                                    This spot is already occupied or will be occupied by a Task with equal or higher Priority to your Task.  Do you still want to continue?
                                    <br />

                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Task Status:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.taskStatus === "IsAssinged" ? "Assigned" : this.state.SpotonSiteData.taskStatus}

                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Name:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.trailerName}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Type:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.trailerType}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Number:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.trailerNumber}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Status:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.assetStatus}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Shipment Number:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.shipmentNumber}
                                      </h1>
                                    </h1>
                                    <br />

                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Created By:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.createdBy}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Created Time:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        <MomentDisplay
                                          date={this.state.SpotonSiteData.createdDate}
                                          timezone={
                                            this.props.currentLocation &&
                                            this.props.currentLocation.timezone
                                          }
                                        />
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Task Priority:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.taskPriority === 1 && 'Low'}
                                        {this.state.SpotonSiteData.taskPriority === 2 && 'Normal'}
                                        {this.state.SpotonSiteData.taskPriority === 3 && 'High'}
                                        {!this.state.SpotonSiteData.taskPriority && 'Normal'}

                                      </h1>
                                    </h1>
                                    <br />


                                    <Button className="btn btn-danger" color="danger"
                                      onClick={(e) => {
                                        onClose();
                                      }} >Return</Button>
                                    &nbsp;&nbsp;
                                    <Button className="btn btn-primary" color="primary"
                                      onClick={(e) => {
                                        this.handleSubmit(e); onClose();
                                      }} >Proceed</Button>

                                  </div>
                                );
                              },
                              closeOnEscape: false,
                              closeOnClickOutside: false,
                              overlayClassName: "overlay-custom-class-name",
                            });

                          } else {
                            this.handleSubmit(e);
                          }
                        }}
                      >
                        Save &#38; Close
                      </Button>{" "}
                      &nbsp;&nbsp;

                      {!this.state.isonsite && (<Button
                        color="primary"
                        type="button"
                        disabled={
                          !(this.getNumber(300) >= 0) ||
                          this.state.disableSave === true
                        }

                        onClick={(e) => {
                          let samespot = this.state.taskUnderEdit.moveFrom &&
                            this.state.taskUnderEdit.moveTo &&
                            this.state.taskUnderEdit.moveFrom.uuid ===
                            this.state.taskUnderEdit.moveTo.uuid ? true : false;
                          if (this.state.SpotonSite == true && !samespot) {
                            confirmAlert({

                              customUI: ({ onClose }) => {
                                return (
                                  <div style={{
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    width: '400px',
                                    padding: '30px',
                                    textAlign: 'left',
                                    background: '#fff',
                                    borderRadius: '10px',
                                    boxShadow: '0 20px 75px rgba(0, 0, 0, .13)',
                                    color: '#666'
                                  }}>
                                    <h1 style={{ color: '#4a6670', fontSize: '24px', fontWeight: '700', lineHeight: '30px' }}>Spot Already Occupied</h1>

                                    This spot is already occupied or will be occupied by a Task with equal or higher Priority to your Task.  Do you still want to continue?
                                    <br />

                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Task Status:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.taskStatus}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Name:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.trailerName}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Type:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.trailerType}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Number:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.trailerNumber}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Trailer Status:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.assetStatus}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Shipment Number:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.shipmentNumber}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Ref ID:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {/* {this.state.SpotonSiteData.taskStatus} */}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Created By:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.createdBy}
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Created Time:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        <MomentDisplay
                                          date={this.state.SpotonSiteData.createdDate}
                                          timezone={
                                            this.props.currentLocation &&
                                            this.props.currentLocation.timezone
                                          }
                                        />
                                      </h1>
                                    </h1>
                                    <br />
                                    <h1 style={{
                                      color: '#4a6670',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      lineHeight: '30px',
                                      display: 'inline'
                                    }}>
                                      Task Priority:
                                      <h1 style={{
                                        color: '#4a6670',
                                        fontSize: '12px',
                                        fontWeight: '550',
                                        lineHeight: '30px',
                                        display: 'inline'
                                      }}>
                                        {this.state.SpotonSiteData.taskPriority === 1 && 'Low'}
                                        {this.state.SpotonSiteData.taskPriority === 2 && 'Normal'}
                                        {this.state.SpotonSiteData.taskPriority === 3 && 'High'}
                                        {!this.state.SpotonSiteData.taskPriority && 'Normal'}

                                      </h1>
                                    </h1>
                                    <br />


                                    <Button className="btn btn-danger" color="danger"
                                      onClick={(e) => {
                                        onClose();
                                      }} >Return</Button>
                                    &nbsp;&nbsp;
                                    <Button className="btn btn-primary" color="primary"
                                      onClick={(e) => {
                                        this.setNewVariable(true);
                                        this.handleSubmit(e);
                                        onClose();
                                      }} >Proceed</Button>

                                  </div>
                                );
                              },
                              closeOnEscape: false,
                              closeOnClickOutside: false,
                              overlayClassName: "overlay-custom-class-name",
                            });
                          } else {
                            this.setNewVariable(true);
                            this.handleSubmit(e);
                          }
                        }}
                      >
                        Save &#38; New Task
                      </Button>)}
                    </div>
                  </Col>
                </div>
              </Form>
              {this.state.taskUnderEdit.uuid && this.state.showSpotHistory && (
                <TaskSpotHistory
                  task={this.state.taskUnderEdit}
                  successCallback={(success) => this.spotHistoryLoaded(success)}
                  failureCallback={(failure) => this.spotHistoryLoaded()}
                />
              )}
            </div>
          </div>
        </Loader>
      </>
    );
  }
  setNewVariable(val) {
    this.setState({ newCheck: val });
  }
  manualCompletion() {
    let s = { ...this.state.taskUnderEdit };
    s.completedManually = !s.completedManually;
    if (s.completedManually === false) {
      if (this.state.taskBackup) {
        let taskBackup = JSON.parse(this.state.taskBackup);
        s.acceptDate = taskBackup.acceptDate;
        s.start = taskBackup.start;
        s.end = taskBackup.end;
      }
      this.setState({ disableSave: false });
    } else {
      if (s.acceptDate !== null && s.start !== null && s.end !== null && s.acceptDate !== undefined && s.start !== undefined && s.end !== undefined) {
        this.setState({ disableSave: false });
      } else {
        this.setState({ disableSave: true });
      }
    }
    this.setState({ taskUnderEdit: s });

  }
  getNumber(textCharacterLimit) {
    let value = this.state.taskUnderEdit.comments;

    return textCharacterLimit - (value ? value.length : 0);
  }
  getAssetType() {
    // if (
    //   this.state.taskUnderEdit.asset &&
    //   this.state.taskUnderEdit.asset.equipment
    // ) {
    //   return this.state.taskUnderEdit.asset.equipment.name;
    // } else if (this.state.taskUnderEdit.assetType) {
    //   return this.state.taskUnderEdit.assetType.name === 'UNKNOWN TRAILER' ? "" : this.state.taskUnderEdit.assetType.name;
    // } else if (this.state.defaultEquipmentType) {
    //   return this.state.defaultEquipmentType.name === 'UNKNOWN TRAILER' ? "" : this.state.defaultEquipmentType.name;
    // } else {
    //   return "";
    // }
    if (
      this.state.taskUnderEdit.asset &&
      this.state.taskUnderEdit.asset.equipment
    ) {
      return this.state.taskUnderEdit.asset.equipment.name;
    } else if (this.state.taskUnderEdit.assetType) {
      return this.state.taskUnderEdit.assetType.name;
    } else if (this.state.defaultEquipmentType) {
      return this.state.defaultEquipmentType.name;
    } else {
      return "";
    }
  }
}
TaskEdit.propTypes = {
  currentLocation: PropTypes.object,
  setCurrentLocation: PropTypes.func,
  company: PropTypes.object,
};
export default TaskEdit;
