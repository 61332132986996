import React, {Component} from "react";
import {Card, CardBody, CardHeader, Input, Label} from "reactstrap";
import * as PropTypes from "prop-types";

export class TrailerTypeCard extends Component {
    render() {
        return <Card className="simple-card">
            <CardHeader>Trailer Type</CardHeader>
            <CardBody>
                <div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <Input className="form-check-input" type="radio" id="radioTrailerTypeLoaded"
                               name="radios" checked={this.props.trailerType === "loaded"}
                               onChange={this.props.onLoadedTrailerChange}/>
                        <Label check className="form-check-label"
                               htmlFor="radioTrailerTypeLoaded">Loaded Trailer</Label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <Input className="form-check-input" type="radio" id="radioTrailerTypeEmpty"
                               name="radios" checked={this.props.trailerType === "empty"}
                               onChange={this.props.onEmptyTrailerChange}/>
                        <Label check className="form-check-label" htmlFor="radioTrailerTypeEmpty">Empty
                            Trailer</Label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                        <Input className="form-check-input" type="radio" id="radioTrailerTypeBobtail"
                               name="radios" checked={this.props.trailerType === "bobtail"}
                               onChange={this.props.onBobTailTrailerChange}/>
                        <Label check className="form-check-label" htmlFor="radioTrailerTypeBobtail">
                            Bobtail (No Trailer)
                        </Label>
                    </div>
                </div>
            </CardBody>
        </Card>;
    }
}

TrailerTypeCard.propTypes = {
    trailerType: PropTypes.any,
    onLoadedTrailerChange: PropTypes.func,
    onEmptyTrailerChange: PropTypes.func,
    onBobTailTrailerChange: PropTypes.func
};