import React, {Component} from "react";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import Config from "../../Config";
import {convertShapeJsonToMapObject} from "../../common/utilities";

export class GeofenceMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: false,
        };
        this.mapRef = React.createRef();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.center.lat !== prevProps.center.lat || this.props.center.lng !== prevProps.center.lng) {
            this.mapRef.current.map.setCenter(this.props.center);
            this.mapRef.current.map.fitBounds(this.bounds());
        }
    }
    bounds() {
        const bounds = new this.props.google.maps.LatLngBounds();
        const {lat, lng} = this.props.center;
        bounds.extend(new this.props.google.maps.LatLng(lat, lng));
        return this.boundsAround(bounds.getCenter().lat(),bounds.getCenter().lng(), 0.1);
    }
    boundsAround( lat, long, r ) {
        lat = parseFloat(lat);
        long = parseFloat(long);
        let dl = r/69;       // North-south distance in degrees
        let df = dl / (Math.cos(lat * (Math.PI/180.0) ) ); // East-west distance in degrees
        const bounds = new this.props.google.maps.LatLngBounds();
        bounds.extend(new this.props.google.maps.LatLng(lat-df,long-dl));
        bounds.extend(new this.props.google.maps.LatLng(lat+df,long-dl));
        bounds.extend(new this.props.google.maps.LatLng(lat+df,long+dl));
        bounds.extend(new this.props.google.maps.LatLng(lat-df,long+dl));
        return bounds;
    }
    removeExistingShapeFromMap() {
        if (this.state.shape) {
            this.state.shape.setMap(null);
        }
    }
    shapeComplete(shape) {
        this.removeExistingShapeFromMap();
        let type = 'unknown';
        if (shape instanceof this.props.google.maps.Circle) {
            type = 'circle';
        } else if (shape instanceof this.props.google.maps.Rectangle) {
            type = 'rectangle';
        } else if (shape instanceof this.props.google.maps.Polygon) {
            type = 'polygon';
        }
        let json = '';
        if (type === 'circle') {
            json = {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [shape.getCenter().lng(), shape.getCenter().lat()],
                },
                properties: {
                    radius: shape.getRadius()
                }
            }
        } else if (type === 'rectangle') {
            let bounds = shape.getBounds();
        } else if (type === 'polygon') {
            let path = shape.getPath();
            let points = [];
            path.forEach((latLng, idx) => {
                points.push([latLng.lng(), latLng.lat()]); // geojson uses lng,lat instead of lat,lng
            });
            points.push(points[0]); //org.locationtech.jts.geom.Polygon requires the last point of the polygon == the first
            points.reverse();
            json = {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [points],
                },
            };
        }
        this.setState({geofenceType: type, shape: shape, json: json});
        this.props.onChange && this.props.onChange(type, json);
    }
    initialize(mapProps, map) {
        const {google} = mapProps;
        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.CIRCLE,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.CIRCLE,
                    google.maps.drawing.OverlayType.POLYGON,
                    // google.maps.drawing.OverlayType.RECTANGLE
                ]
            },
        });
        drawingManager.setMap(map);
        google.maps.event.addListener(drawingManager, 'circlecomplete', this.shapeComplete.bind(this));
        google.maps.event.addListener(drawingManager, 'polygoncomplete', this.shapeComplete.bind(this));
        google.maps.event.addListener(drawingManager, 'rectanglecomplete', this.shapeComplete.bind(this));
        if (this.props.shape) {
            let geofenceShape = convertShapeJsonToMapObject(this.props.shape, map, google);
            this.setState({shape: geofenceShape});
        }
    }
    render() {
        return (
            <Map google={this.props.google}
                 onReady={this.initialize.bind(this)}
                 containerStyle={{
                     width: this.props.size.width,
                     height: this.props.size.height,
                 }}
                 initialCenter={this.props.center}
                 streetViewControl={false}
                 scaleControl={true}
                 yesIWantToUseGoogleMapApiInternals
                 ref={this.mapRef}
            >
                <Marker
                    position={this.props.center}
                    label={this.props.locationName}
                    />
            </Map>
        )
    }
}
export default GoogleApiWrapper({
    apiKey: (Config.googleMapsApiKey),
    libraries: ['drawing'],
})(GeofenceMap)
