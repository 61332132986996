import React, { Component } from "react";
import AsyncSelect from 'react-select/lib/Async';
import * as PropTypes from "prop-types";
import { getLocationsForUserForm } from "../../NetworkHelpers";



export class FFReferenceSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <AsyncSelect
            name={this.props.name}
            placeholder={this.props.placeholder}
            valid={!this.props.error}
            isMulti={!this.props.single}

            invalid={
                this.props.touched
                && !!this.props.error
            }
            defaultOptions
            minimumInput={0}
            onChange={(v) => this.props.onLocationsChange(v)}
            onBlur={this.props.onBlur}
            value={this.props.value ? this.props.value.map((v) => { return { value: v.uuid, label: v.name } }) : null}
            className={"basic-single short-select select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : "")}
            // classNamePrefix="select"
            loadOptions={getLocationsForUserForm}
            isClearable={true}
        />;
    }
}

FFReferenceSelect.propTypes = {
    name: PropTypes.any,
    placeholder: PropTypes.any,
    errors: PropTypes.any,
    touched: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    values: PropTypes.any,
    fieldname: PropTypes.any
};