import React, {Component} from "react";
import {showError} from "../MainApp";
import Select from "react-select";
import {downloadCountryCodeData} from "../NetworkHelpers";

export default class CountryCodeSelect  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options:[]
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.downloadCountryCodeData();
    }

    downloadCountryCodeData() {
        downloadCountryCodeData((countryCodeData)=>{
            this.setState({loading: false, countryCodeData: countryCodeData, options: this.optionsFromData(countryCodeData)});
        }, (error) => {
            console.log(error);
            showError("Unable to load options.");
        })
    }

    optionsFromData(data) {
        return data.map((d) => {  return {label: "+" + d.code + " - " + d.regionName, value: d.regionName,code: d.code, region: d.region}});
    }

    lookupCode( region ) {
        if ( this.state.countryCodeData ) {
            for (let i = 0; i < this.state.countryCodeData.length; i++) {
                if (this.state.countryCodeData[i].code === region) {
                    return "+" + this.state.countryCodeData[i].code;
                }
            }
        } else {
            return region;
        }
    }

    lookupName( shortRegionName ) {
        if ( this.state.countryCodeData ) {
            for (let i = 0; i < this.state.countryCodeData.length; i++) {
                if (this.state.countryCodeData[i].code === shortRegionName) {
                    return this.state.countryCodeData[i].regionName;
                }
            }
        } else {
            return shortRegionName;
        }
    }


    lookupShortRegionName( regionName ) {
        if ( this.state.countryCodeData ) {
            for (let i = 0; i < this.state.countryCodeData.length; i++) {
                if (this.state.countryCodeData[i].regionName === regionName) {
                    return this.state.countryCodeData[i].region;
                }
            }
        } else {
            return regionName;
        }
    }

    render() {
        return (
            <Select name={this.props.fieldname}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    fieldname={this.props.fieldname}
                    isClearable={this.props.isClearable}
                    onChange={(v) => {
                        if(this.props.disableFormik){
                            this.props.setFieldValue(this.props.fieldname, (v ? this.lookupShortRegionName(v.value) : "US"));
                            this.props.setFieldTouched(this.props.fieldname, true);
                        }
                        this.props.onChange && this.props.onChange(v);
                    }}
                    classNamePrefix="country-code"
                    options={this.state.options}
                    isDisabled={this.props.isDisabled}
                    value={( this.props.values && this.props.values[this.props.fieldname]? {
                        value: this.lookupName(this.props.values[this.props.fieldname]),
                        label: this.lookupCode(this.props.values[this.props.fieldname])
                    } : {
                        value: this.lookupName("US"),
                        label: "+1"
                    })}
            />
        )
    }

}

