import React, { Component } from "react";
import {
    promiseCancelReasonByLocationUUid
} from "../NetworkHelpers";
import { Alert, Button, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import AsyncSelect from "react-select/lib/Async";
import * as PropTypes from "prop-types";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class CancelTaskModal extends Component {
    constructor(props) {
        super(props);
        this.state = { reasonCode: this.props.defaultReasonCode};
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.defaultReasonCode == null && this.props.defaultReasonCode != null) {
            this.setState({ reasonCode: this.props.defaultReasonCode });
        }
    }

    render() {
        return <>
            <Modal isOpen={this.props.open} size={"lg"} >
        <Loader show={this.props.loaded} message={this.spinner}>
                <ModalHeader>
                    <h1>Cancel Task</h1>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col>
                                {this.state && this.state.error && <Alert color={"danger"}>{this.state.error}</Alert>}
                                <AsyncSelect
                                    placeholder="Choose a Reason Code"
                                    defaultOptions
                                    defaultValue={this.props.defaultReasonCode}
                                    onChange={(v) => {
                                        this.setState({ reasonCode: v }, () => {
                                            this.props.onChange && this.props.onChange(v);
                                        })
                                    }}
                                    loadOptions={promiseCancelReasonByLocationUUid(this.props.currentLocation.uuid)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right" style={{ marginTop: "10px" }} >
                                <Button color="danger" onClick={() => this.props.onCancel && this.props.onCancel()}>Don't Cancel</Button> &nbsp;
                                <Button color="primary" onClick={() => this.onSubmit()} type="submit">Complete Cancel</Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
        </Loader>
            </Modal>
        </>;
    }
    updateSpinner(val) {
        this.spinner = (
          <span>
            <p className="loadingMessage">{val}</p>
           
          </span>
        );
      }
    onSubmit() {
        if (!this.state || !this.state.reasonCode) {
            this.setState({ error: "Please select a reason code." });
            return;
        }
        else{
            this.updateSpinner("");
        }
        return this.props.onSave && this.props.onSave(this.state && this.state.reasonCode);
    }
}

CancelTaskModal.propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func
};
export default CancelTaskModal;