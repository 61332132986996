import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import AsyncSelect from "react-select/lib/Async";
import {promiseCompanyByIdentification, promiseLocationSearch} from "../../NetworkHelpers";
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';



class FFFormSelectCompanyIdField extends Component {
    constructor(props) {
        super(props);
    }

    icon() {
        if (this.props.icon) {
            return <i className={"fa " + this.props.icon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
        const common = {
            name: this.props.fieldname,
            fieldname: this.props.fieldname,
            placeholder: this.props.placeholder,
            touched: this.props.touched,
            onChange: (v) => {
                if (this.props.onChange) {
                    this.props.onChange(v);
                }
            },
            className: "basic-single short-select select-in-form" + ((this.props.touched && !!this.props.error) ? " is-invalid" : ""),
            // classNamePrefix: "select",
            defaultOptions: true,
            loadOptions: promiseCompanyByIdentification(this.props.type),
            onBlur: () => this.props.onBlur && this.props.onBlur(),
            value: this.props.value,
            isDisabled: this.props.disabled
        };

        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                 
                                    {this.props.prepend ? this.props.prepend: this.icon()}
                                </InputGroupText>
                            </InputGroupAddon>
                            {this.props.create ?
                                <AsyncCreatableSelect {...common}/> :
                                <AsyncSelect {...common}/>
                            }
                            <FormFeedback>{this.props.error}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FFFormSelectCompanyIdField;