import * as React from "react";
import { Button } from "reactstrap";
import { savePriority, downloadRolesForTable, getSpotNamesForDropdown } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import RoleEdit, { roleOptions } from "./RoleEdit";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class RoleConfig extends React.Component {
    tableColumns = [
        {
            Header: 'Role',
            accessor: 'roles',
            sortable: false,
            filterable: false,
            Cell: row => {
                return row.original.roles.map((role) => roleOptions.find((option) => option.value === role).label).join(', ');
            },
        },
        {
            Header: "Location Nickname",
            accessor: "location.nickname",
            sortable: true,
            filterable: true,
            Cell: (row) => {
                return row.original.location.nickname;
            },
        },


        {
            Header: 'Action',
            accessor: 'action',
            sortable: false,
            filterable: false,
            Cell: row => {
                return <Button title="Edit" aria-label="Edit Task Priority Configurations"
                    onClick={() => this.EditRole(row.original)}><i
                        className="fa fa-edit" /></Button>
            }
        }
    ];
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.enabledFilterRef = React.createRef();
        this.isDefaultFilterRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem("poweryard-Role-Config-settings"));
        this.state = {
            roles: []
        }
        if (localStorageState != null) {
            this.state = {
                enabledFilter: localStorageState.enabledFilter,
                isDefaultFilter: localStorageState.isDefaultFilter,
                RoleUnderEdit: localStorageState.RoleUnderEdit
            }
        } else {
            this.state = {
                RoleUnderEdit: {},
            }
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.company.uuid !== this.props.company.uuid) {
            this.tableRef.current.fetchData();
        }
    }
    componentWillUnmount() {

        localStorage.setItem("poweryard-Role-Config-settings", JSON.stringify({
            enabledFilter: this.state.enabledFilter,
            isDefaultFilter: this.state.isDefaultFilter,
            RoleUnderEdit: this.state.RoleUnderEdit,
        }))

    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    EditNewRole() {
        this.setState({
            showModal: true,
            RoleUnderEdit: {
                name: "",
                def: false,
                allowed: true,
                isNew: true
            }
        });
    }
    renderModal() {
        return (
            <RoleEdit
                currentLocation={this.props.defaultLocation}
                RoleUnderEdit={this.state.RoleUnderEdit}
                loaded={this.state.loaded}
                cancel={() => this.cancelDialog()}
                save={(a, onSuccess, onError) => { this.savePriority(a, onSuccess, onError); }}
                onChange={(state) => {
                    this.setState({ RoleUnderEdit: state })
                }}
                showModal={this.state.showModal}
            />
        );
    }
    cancelDialog() {
        this.setState({ showModal: false, RoleUnderEdit: {} });
    }
    EditRole(Role) {
        this.setState({ showModal: true, RoleUnderEdit: Role })
    }
    savePriority(asset, onSuccess, onError) {
        this.setState({ loaded: true })
        savePriority(asset, (response) => {
            this.setState({ loaded: false })
            if (response.code === '1000') {
                showError(response.message);
            } else {
                showInfo("Priority  Saved");
                this.tableRef.current.fetchData();
                this.props.onChange(this.state);
                onSuccess();
            }
            this.setState({ showModal: false, RoleUnderEdit: {} });
        }, (failure) => {
            showError(failure.message);
            onError();
        });
    }
    clearFilters() {
        if (this.isDefaultFilterRef.current) {
            this.isDefaultFilterRef.current.value = undefined;
        }
        if (this.enabledFilterRef.current) { this.enabledFilterRef.current.value = undefined; }
        this.setState({
            enabledFilter: undefined,
            isDefaultFilter: undefined,
            trackerTypeFilter: undefined
        });
    }

    render() {
        return (
            <div>
                <div className="column-wrapper text-left">
                    <Loader show={this.state.loaded} message={this.spinner}>
                        <PaginationFilterTable
                            setloaded={(check) => {
                                this.setState({ loaded: check });
                                this.updateSpinner("");
                            }}

                            onFilterHide={() => {
                                this.setState({
                                    enabledFilter: "undefined",
                                });
                            }}
                            openFiltersCollaps={true}
                            ref={this.tableRef}
                            pageType="poweryard-settings"
                            get={downloadRolesForTable}
                            stateStorage="poweryard-Role-Config-settings"
                            clearFilter={this.clearFilters.bind(this)}
                            renderModal={this.renderModal()}
                            columns={this.tableColumns}
                        >
                            <Button color="primary" onClick={() => this.EditNewRole()}> <i className="fa fa-plus" /> Add Task Priority Configuration</Button>
                        </PaginationFilterTable>
                    </Loader>
                </div>

            </div>
        )
    }
}
export default RoleConfig;