import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { importLocationFile, postUserDefaultLocation, postLocation, requestLocationsToExcel, searchLocations } from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import LocationForm from "./LocationForm";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { FileDropModal } from "../../common/FileDropModal";
import { showError, showInfo } from "../../MainApp";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { storeCurrentLocation, storeCurrentUser } from "../../Login/actions/Login";
import Switch from "react-switch";
import { SPECIFY_RADIUS } from "../../common/constants";


class Locations extends Component {
    tableColumns = [
        {
            Header: "Created",
            accessor: 'created',
            filterable: false,
            sortable: true,
            width: 200,
            Cell: row => { let v = row.original; return v.created ? <MomentDisplay date={v.created} timezone={this.props.currentLocation && this.props.currentLocation.timezone} /> : "New"; }
        },
        {
            Header: "Name",
            filterable: true,
            accessor: 'name',
            sortable: true,
            Cell: row => {
                let v = row.original;
                // if (!((v.address1) && (v.city) && (v.state) && (v.country) &&
                //     (v.latitude) && (v.longitude) && (v.timezone))) {

                //     return <span><i className={"fa fa-flag"}>&nbsp;</i>{v.name}</span>;
                // }
                return row.original.name;
            }
        },
        {
            Header: "Location Code",
            accessor: 'code',
            filterable: true,
            sortable: true,
            Cell: row => { let v = row.original; return v.code ? v.code : ""; }
        },
        {
            Header: "Billing Code",
            accessor: 'billingCode',
            filterable: true,
            sortable: true,
            default: true
        },
        {
            Header: "PowerYard Site",
            accessor: 'ezCheckInSite',
            filterable: true,
            sortable: true,
            Cell: row => { let v = row.original; return v.ezCheckInSite === true ? "Yes" : "No"; },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.poweryardFilterRef}
                        onChange={event => {
                            this.setState({ poweryardFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.poweryardFilter ? this.state.poweryardFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },

        // {
        //     Header: "Geofence",
        //     accessor: 'geofenceEnabled',
        //     filterable: true,
        //     sortable: true,
        //     Cell: row => { let v = row.original; return v.geofenceEnabled === true ? "Yes" : "No"; },
        //     Filter: ({ filter, onChange }) =>
        //         <>
        //             <select
        //                 ref={this.geoFenceFilterRef}
        //                 onChange={event => {
        //                     this.setState({ geoFenceFilter: event.target.value });
        //                     onChange(event.target.value)
        //                 }}
        //                 style={{ width: "100%" }}
        //                 value={this.state.geoFenceFilter ? this.state.geoFenceFilter : undefined}
        //             >
        //                 <option value="undefined" hidden={true}>Select</option>
        //                 <option value="">All</option>
        //                 <option value="true">Yes</option>
        //                 <option value="false">No</option>
        //             </select>
        //         </>
        // },
        // {
        //     Header: "Domicile",
        //     accessor: 'isDomicile',
        //     filterable: true,
        //     sortable: true,
        //     Cell: row => { let v = row.original; return v.isDomicile === true ? "Yes" : "No"; },
        //     Filter: ({ filter, onChange }) =>
        //         <>
        //             <select
        //                 ref={this.domicileFilterRef}
        //                 onChange={event => {
        //                     this.setState({ domicileFilter: event.target.value });
        //                     onChange(event.target.value)
        //                 }}
        //                 style={{ width: "100%" }}
        //                 value={this.state.domicileFilter ? this.state.domicileFilter : undefined}
        //             >
        //                 <option value="undefined" hidden={true}>Select</option>
        //                 <option value="">All</option>
        //                 <option value="true">Yes</option>
        //                 <option value="false">No</option>
        //             </select>
        //         </>
        // },
        {
            Header: "Enabled",
            accessor: 'enabled',
            filterable: true,
            sortable: true,
            Cell: row => { let v = row.original; return v.enabled === true ? "Yes" : "No"; },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={event => {
                            this.setState({ enabledFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.enabledFilter ? this.state.enabledFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: "Incomplete",
            accessor: 'value',
            filterable: false,
            sortable: false,
            Cell: row => {
                let v = row.original;

                if (!v.address1 || v.address1.trim() === "" || !v.city || v.city.trim() === "" || !v.state || v.state.trim() === "" || !v.country || v.country.trim() === "" || !v.latitude || v.latitude.trim() === ""
                    || !v.longitude || v.longitude.trim() === "" || !v.timezone || v.timezone.trim() === "") {

                    return <span><i className={"fa fa-flag"}>&nbsp;</i></span>;
                }
                else {
                    return "";
                }
            }
        },
        {
            Header: 'Action',
            filterable: false,
            accessor: 'action',
            Cell: row => { let v = row.original; return <span><Button onClick={() => this.setState({ editingLocation: { ...v } })}><i className="fa fa-edit" /> Edit </Button></span>; },
            sortable: false
        }
    ];


    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.poweryardFilterRef = React.createRef();
        this.geoFenceFilterRef = React.createRef();
        this.domicileFilterRef = React.createRef();
        this.enabledFilterRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem("ezcheckin-admin-locations"));
        if (localStorageState != null) {
            this.state = localStorageState;
        } else {
            this.state = {
                pages: -1,
                editingLocation: null,
                showOnlyIncomplete: false,
                previousSearchRequest: {},
                importModalError: false,
                importModalShow: false,
                // poweryardFilter: parse.poweryardFilter,
                // geoFenceFilter: parse.geoFenceFilter,
                // domicileFilter: parse.domicileFilter,
                // enabledFilter: parse.enabledFilter,
            }
        }
    }
    componentWillUnmount() {
        localStorage.setItem("ezcheckin-admin-locations", JSON.stringify({
            showOnlyIncomplete: this.state.showOnlyIncomplete,
            poweryardFilter: this.state.poweryardFilter,
            geoFenceFilter: this.state.geoFenceFilter,
            domicileFilter: this.state.domicileFilter,
            enabledFilter: this.state.enabledFilter,
            previousSearchRequest: this.state.previousSearchRequest,
            importModalError: false,
            importModalShow: false,
        }));
    }

    pageSearch(searchRequest, successFn, failureFn) {
        if (this.state.showOnlyIncomplete) {
            if (searchRequest.filtered.filter(f => f.id === 'showOnlyIncomplete').length === 0) {
                searchRequest.filtered = searchRequest.filtered.concat({ id: 'showOnlyIncomplete', value: true });
            }
        } else {
            searchRequest.filtered = searchRequest.filtered ? searchRequest.filtered.filter(f => f.id !== 'showOnlyIncomplete') : [];
        }

        this.setState({ previousSearchRequest: searchRequest });

        searchLocations(searchRequest,
            (response) => {
                successFn && successFn(response);
            },
            (response) => {
                failureFn && failureFn(response);
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.company.uuid !== this.props.company.uuid) {
            this.tableRef.current.fetchData()
        }
    }

    renderModal() {
        return (
            <LocationForm
                user={this.props.user}
                loadedLocation={this.state.loadedLocation}
                editingLocation={this.state.editingLocation}
                cancel={() =>
                    this.setState({ editingLocation: null })
                }
                save={(c, setErrors) => {
                    this.setState({ loadedLocation: true })
                    this.saveLocation(c, () => {
                        this.setState({
                            editingLocation: null,
                        },)
                    }, (error) => {
                        this.setState({
                            loadedLocation: false
                        })
                        setErrors(error.formError);
                    });

                }}
                allowQuickAdd={false}
            />
        );
    }


    saveLocation(location, onSuccess, onError) {
        let companyPoweryard = this.props.user.company.enablePowerYard;
        postLocation(location, companyPoweryard, (c) => {
            this.setState({
                loadedLocation: false
            })
            if (c.code === '1000') {
                showError(c.message);
            } else {

                let s = { ...this.props.currentLocation };
                if (c.uuid === this.props.currentLocation.uuid) {
                    let obj = {
                        code: c.code,
                        enableTaskAssigment: c.enableTaskAssigment,
                        enableTaskInProcess: c.enableTaskInProcess,
                        enableYardCheck: c.enableYardCheck,
                        name: c.name,
                        nickname: c.nickname,
                        timezone: c.timezone,
                        uuid: c.uuid
                    }
                    this.props.setCurrentLocation(obj);
                }
                this.setState({ editingLocation: null })
                this.tableRef.current.fetchData();
            }
        }, (e) => {
            this.setState({
                loadedLocation: false
            })
            onError(e);
        })
    }

    showOnlyIncompleteFilter() {

        return <div className="filter-switch" title="Show Only Incomplete Locations">
            <label>Show Only Incomplete Locations</label>
            <Switch
                onChange={(checked) => {
                    if (this.state.showOnlyIncomplete) {
                        this.setState({
                            showOnlyIncomplete: !this.state.showOnlyIncomplete,
                            baseFilter: []
                        });
                    } else {
                        this.setState({
                            showOnlyIncomplete: !this.state.showOnlyIncomplete,
                            baseFilter: [{ id: 'showOnlyIncomplete', value: true }]
                        });
                    }
                }}
                checked={this.state.showOnlyIncomplete}
                uncheckedIcon={<div className="text-center pt-1"><i className="fa fa-building" /></div>}
                checkedIcon={<div className="text-center pt-1"><i className="fa fa-building" /></div>}
                title="Show Filter"
                className="react-switch" />
        </div>
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }

    startNewLocation() {
        this.setState({
            editingLocation: {
                name: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                country: "United States",
                postal: "",
                email: "",
                contactFirstName: "",
                contactLastName: "",
                phone: "",
                latitude: "",
                longitude: "",
                code: "",
                nickname: "",
                timezone: "",
                geofenceType: SPECIFY_RADIUS,
                isNew: true,
                company: this.props.company
            }
        });
    }


    exportTable() {
        if (this.state.previousSearchRequest) {
            requestLocationsToExcel(this.state.previousSearchRequest, "location-export.xls", (response) => {
                if (response.emailSent) {
                    showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
                }
            }, (e) => {
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.showImportInstructions}>
                    <ModalHeader>
                        Import Instructions
                    </ModalHeader>
                    <ModalBody>
                        EZCheck-In allows for importing certain data via a file in CSV format or XLS format.
                        To get started with importing locations click the
                        Download Import Template to obtain a sample CSV file.
                        Add one row per location that you wish to import.<br />
                        <br />
                        The following are the list of columns that should be included.
                        Any fields with an asterisk are required and must be non blank.
                        Any fields that contain a comma must be enclosed in quotes.<br />
                        <br />
                        Name*<br />
                        Address 1*<br />
                        Address 2<br />
                        City*<br />
                        State/County/Province*<br />
                        Country*<br />
                        Postal*<br />
                        Email Address<br />
                        Contact First Name<br />
                        Contact Last Name<br />
                        Phone Country Code<br />
                        Phone Number<br />
                        Mobile Country Code<br />
                        Mobile Number<br />
                        Latitude<br />
                        Longitude<br />
                        Location Code<br />
                        Nickname<br />
                        EZCheck-In Location (T or F)*<br />
                        GeoFence Radius (Miles or Km based on company settings)<br />
                        Enable EZTrack Geofence (T/F) [if not provided then "False"]<br />
                        EZTrack Geofence Type ("Specify radius" or "Draw on map").<br />
                        EZTrack Geofence Shape (GeoJson Object)<br />
                        Warning Tractor Dwell (min)<br />
                        Critical Tractor Dwell (min)<br />
                        Warning Trailer Dwell (min)<br />
                        Critical Trailer Dwell (min)<br />
                        Warning Container Dwell (min)<br />
                        Critical Container Dwell (min)<br />
                        <div style={{ marginTop: '10px' }} className={'text-right'}>
                            <Button color="primary" onClick={() => this.setState({
                                showImportInstructions: false
                            })}>Close</Button>
                        </div>
                    </ModalBody>

                </Modal>
                <FileDropModal
                    open={this.state.importModalShow}
                    error={this.state.importModalError}
                    header={"Import Locations"}
                    onDrop={acceptedFiles => {
                        for (const file of acceptedFiles) {
                            if (file['type'] && !file['type'].includes('text')) {
                                //Or is it excel?
                                if (file['type'] && !(file['type'].includes('excel') || file['type'].includes('openxml'))) {
                                    this.setState({ importModalError: "File must be text" });
                                    return;
                                }
                            }
                            importLocationFile(file, (res) => {
                                let response = res.content;
                                this.setState({ importModalShow: false });
                                showInfo(response.message + " in " + response.seconds + " seconds.");
                                this.tableRef.current.fetchData();
                            }, (response) => {
                                this.setState({ importModalError: response.message });
                            }, () => {
                                this.props.history.push("/");
                            })
                        }
                    }}
                    onClose={() => {
                        this.setState({
                            importModalError: false,
                            importModalShow: false
                        });

                    }}
                />
                <Loader show={this.state.loaded} message={this.spinner}>
                    <PaginationFilterTable
                        setloaded={(check) => {
                            this.setState({ loaded: check });
                            this.updateSpinner("");
                        }} ref={this.tableRef}
                        openFiltersCollaps={true}
                        pageType="Admin"
                        get={this.pageSearch.bind(this)}
                        baseFilter={this.state.baseFilter}
                        stateStorage={"ezcheckin-admin-locations"}
                        renderModal={this.renderModal()}
                        columns={this.tableColumns}
                        pageCount={this.state.pages}
                        additionalFilter={this.showOnlyIncompleteFilter()}
                        footerButton={<Button color="secondary" onClick={() => this.exportTable()}><i className="fa fa-file-excel" /> Export to Excel</Button>}
                        onFilterHide={() => {
                            this.setState({
                                enabledFilter: "undefined",
                                poweryardFilter: "undefined",
                            });
                        }}
                        onChange={(state) => {
                            this.setState({
                                itemsPerPage: state.itemsPerPage,
                                previousSearchRequest: {
                                    page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered
                                }
                            });
                        }}>
                        <Button color="primary" onClick={() => this.startNewLocation()}> <i className="fa fa-plus" /> Add New Location </Button>
                        <Button style={{ marginLeft: '10px' }} color="secondary" className={"btn-sm"} onClick={() => {
                            this.setState({ importModalShow: true });
                        }}>
                            <i className="fa fa-file-import" /> Import
                        </Button>
                        <a style={{ marginLeft: '10px' }} href={"/assets/files/location-import-format.csv"}>Download Import Template</a>
                        <a style={{ marginLeft: '10px' }} href={"#"} onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showImportInstructions: true })
                        }}>Import Instructions</a>
                    </PaginationFilterTable>
                </Loader>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        currentLocation: state.login.location,
        user: state.login.user
    }
}


function mapDispatchToProps(dispatch) {
    return ({
        setCurrentLocation: function (location) {
            dispatch(storeCurrentLocation(location));
        },
        setCurrentUser: function (location) {
            dispatch(storeCurrentUser(location));
        }
    });
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Locations));