import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import {promiseLocationSearch} from "../NetworkHelpers";
import AsyncSelect from "react-select/lib/Async";


class FormSelectLocationField extends Component {


    constructor(props) {
        super(props);
        this.state = {
            locationList: []
        }
    }

    icon() {
        if (this.props.icon) {
            return <i className={"fa " + this.props.icon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    lookupByUuid(uuid) {
        for (let i = 0; i < this.state.locationList.length; i++) {
            if (this.state.locationList[i].value === uuid) {
                return this.state.locationList[i]
            }
        }
        return "";
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <AsyncSelect
                                name={this.props.fieldname}
                                fieldname={this.props.fieldname}
                                placeholder={this.props.placeholder}
                                errors={this.props.errors}
                                touched={this.props.touched}
                                onChange={(v) => {
                                    if(!this.props.formikOverride){
                                        this.props.setFieldValue(this.props.fieldname, v ? v.value : "");
                                        this.props.setFieldTouched(this.props.fieldname, true);
                                    }else{
                                        this.props.setFieldValue(this.props.formikOverride, v ? v.value : "");
                                        this.props.setFieldTouched(this.props.formikOverride, true);
                                    }

                                    if (this.props.onChange) {
                                        this.props.onChange(v);
                                    }
                                }}
                                className={"basic-single short-select select-in-form" + ((this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? " is-invalid" : "")}
                                classNamePrefix="select"
                                defaultOptions
                                loadOptions={(inputValue) => {
                                    return new Promise(resolve => {
                                        promiseLocationSearch(inputValue).then(
                                            (data) => {
                                                if (this.props.optionsFilter) {
                                                    data = this.props.optionsFilter(data);
                                                }
                                                this.setState({locationList: data});
                                                resolve(data);
                                            }
                                        );
                                    });
                                }}
                                onBlur={() =>
                                    !this.props.formikOverride ?
                                        this.props.setFieldTouched(this.props.fieldname, true)
                                        : this.props.setFieldTouched(this.props.formikOverride, true)}
                                values={this.props.values}
                                value={this.props.values[this.props.fieldname]? this.lookupByUuid(this.props.values[this.props.fieldname]) : ""}
                            />
                            <FormFeedback>{this.props.errors[this.props.fieldname]}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FormSelectLocationField;