import React, {Component} from 'react';
import {
    Col,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as uuid from "uuid";
import parseISO from 'date-fns/parseISO';


class FormDateTimeField extends Component {
    constructor(props) {
        super(props);
        let date = props.values[this.props.fieldname] || new Date();
        this.toggle = this.toggle.bind(this);
        this.state = {
            date: date,
            hh: moment(date).format("h"),
            mm: moment(date).format("mm"),
            ap: moment(date).format("A"),
            tooltipOpen: false,
            id: "ID_"+uuid.v4()
        }
    }


    recalcDateTime() {
        let date = new Date(this.state.date);
        let hours = parseInt(this.state.hh) % 12;  // limit to 0 - 11.
        hours += (this.state.ap === "PM"?12:0); // add 12 if PM.
        let minutes = parseInt(this.state.mm) % 60;
        date.setHours(hours || 0, minutes || 0);// convert Nan to 0
        if(!this.props.formikOverride){
            this.props.setFieldValue(this.props.fieldname, date);
            this.props.setFieldTouched(this.props.fieldname);
        }else {
            this.props.setFieldValue(this.props.formikOverride, date);
            this.props.setFieldTouched(this.props.formikOverride);
        }
        this.setState({date: date});
    }

    recalcTimes() {
        let date = new Date(this.state.date);
        this.setState({
            date: date,
            hh: moment(date).format("h"),
            mm: moment(date).format("mm"),
            ap: moment(date).format("A")
        });
    }

    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return this.props.iconText;
        }
        return " ";
    }

    timezoneShortCode() {
        if ( this.props.timezone ) {
            return moment(this.state.date).tz(this.props.timezone).format("z");
        } else {
            return moment(this.state.date).tz(moment.tz.guess()).format("z");
        }
    }


    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    componentDidMount() {
        window.scrollTo(0,0);
        let hh = (!this.props.startTimeBlank) ? this.state.hh : "";
        let mm = (!this.props.startTimeBlank) ? this.state.mm : "";

        this.setState({hh: hh, mm: mm});
    }

    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} ({this.timezoneShortCode()})
                            {this.props.label && this.props.required ? '*' : ''}</Label>
                        {this.props.helpMessage &&
                        <div>
                            <i style={{
                                position: "absolute",
                                right: 0,
                                top: "5px",
                                color:"grey"
                            }}
                               className={"fa fa-question-circle"}
                               id={this.state.id}
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
                                {this.props.helpMessage}
                            </Tooltip>
                        </div>
                        }
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup className={this.props.touched[this.props.fieldname] &&
                        !!this.props.errors[this.props.fieldname]?"is-invalid datetime-in-form":"datetime-in-form"}>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <DatePicker
                                name={this.props.fieldname}
                                className={this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]?"is-invalid":""}
                                valid={!this.props.errors[this.props.fieldname]}
                                invalid={this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]}
                                onChange={(v) => {this.setState({date: v}, ()=>this.recalcDateTime());}}
                                selected={typeof this.state.date === "string"?parseISO(this.state.date):this.state.date}
                                disabled={this.props.isDisabled}
                                isDisabled={this.props.isDisabled}
                                onFocus={(e) => e.target.select()}
                            />
                            <Input type="text" placeholder="HH"
                                   className="text-short"
                                   name={this.props.fieldname + "_HH"}
                                   onChange={(v) => {this.setState({hh:v.target.value}, ()=>this.recalcDateTime());}}
                                   value={this.state.hh}
                                   bsSize="2char"
                                   onBlur={(e)=>this.recalcTimes()}
                                   maxLength="2"
                                   disabled={this.props.isDisabled}
                                   onFocus={(e) => e.target.select()}
                            />
                            <Input type="text" placeholder="MM"
                                   className="text-short"
                                   name={this.props.fieldname + "_MM"}
                                   onChange={(v) => {this.setState({mm:v.target.value}, ()=>this.recalcDateTime());}}
                                   value={this.state.mm}
                                   bsSize="2char"
                                   onBlur={(e)=>this.recalcTimes()}
                                   maxLength="2"
                                   disabled={this.props.isDisabled}
                                   onFocus={(e) => e.target.select()}
                            />
                            <select type="text" placeholder="AP"
                                    className="text-short"
                                    name={this.props.fieldname + "_AP"}
                                    onChange={(v) => {this.setState({ap:v.target.value}, ()=>this.recalcDateTime());}}
                                    value={this.state.ap}
                                    onBlur={(e)=>this.recalcTimes()}
                                    disabled={this.props.isDisabled}
                                    // onFocus={(e) => e.target.select()}
                            >
                                <option>AM</option>
                                <option>PM</option>
                            </select>
                            <FormFeedback>{this.props.errors[this.props.fieldname]}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FormDateTimeField;




