import React, { Component } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import FormTextField from "../../common/FormTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import { getReferenceTypes, newReferenceType, saveCompany, updateReferenceTypes } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import {

	ROLE_ADMIN,

	ROLE_CARRIER_ADMIN,
	ROLE_E4SCORE_ADMIN,

	ROLE_POWERYARD_ADMIN,

	ROLE_TRACKASSURED_ADMIN,

	userHasRole
} from "../../Roles";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
const validationSchema = function (values) {
	return Yup.object().shape({

	})
};

const validate = (getValidationSchema) => {
	return (values) => {
		const validationSchema = getValidationSchema(values);
		try {
			validationSchema.validateSync(values, { abortEarly: false });
			return {}
		} catch (error) {
			return getErrorsFromValidationError(error)
		}
	}
};

const validationDefaultSchema = function (values) {
	return Yup.object().shape({
		shipmentIdDisplay: Yup.string().required('Default Shipment ID is required.').nullable()
	})
};


export class ReferenceNumberTypes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			referenceNumberTypes: {},
			new: {
				name: "",
			}
		};
		if ((this.props.loggedInUser.company.enableEzTrack || this.props.loggedInUser.company.enableEzCheckInDispatch ||
			this.props.loggedInUser.company.enableEzCheckInWelcome || this.props.loggedInUser.company.enablePowerYard || this.props.loggedInUser.company.enableTrackAssured) &&
			this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
			userHasRole(this.props.loggedInUser, [ROLE_E4SCORE_ADMIN, ROLE_ADMIN, ROLE_CARRIER_ADMIN, ROLE_POWERYARD_ADMIN, ROLE_TRACKASSURED_ADMIN])) {

			this.getReferenceTypes();
		}
	}

	updateSpinner(val) {
		this.spinner = (
			<span>
				<p className="loadingMessage">{val}</p>
				<LoaderIco
					loaded={false}
					lines={13}
					length={20}
					width={10}
					radius={30}
					corners={1}
					rotate={0}
					direction={1}
					color="#51b32e"
					speed={1}
					trail={60}
					shadow={false}
					hwaccel={false}
					className="spinner"
					zIndex={2e9}
					top="50%"
					left="50%"
					scale={1.0}
					loadedClassName="loadedContent"
				/>
			</span>
		);
	}
	getReferenceTypes() {


		getReferenceTypes(this.props.company.uuid, (types) => {
			this.setState({
				...this.state,
				referenceNumberTypes: types
			});
		}, () => showError("Failed to get types"))
	}

	onSubmitDefaultLabel(values) {
		this.updateSpinner("Loading...");
		this.setState({ loadedDefaultLabel: true });
		saveCompany(values, (company) => {
			showInfo("Successfully updated company")
			this.props.onCompanyChange(company);
			this.setState({ loadedDefaultLabel: false });

		}, () => {
			this.setState({ loadedDefaultLabel: false });

			showError("Failed to updated company");
		})
	}

	onSubmitNew(values, { setSubmitting, setErrors, setFieldValue }) {
		this.updateSpinner("Loading...");
		this.setState({ loadedNewReferenceType: true });
		setSubmitting(false);
		newReferenceType(this.props.company.uuid, values, () => {
			this.setState({
				new: {
					name: "",
					def: false,
					loadedNewReferenceType: false
				}
			});
			setFieldValue("name", "");
			setFieldValue("def", false);
			this.getReferenceTypesRequest();
		}, (e) => {
			showError("Failed to save new reference type.");
			setErrors(e.formError);
			this.setState({ loadedNewReferenceType: false });

		});
		return false;
	}

	getReferenceTypesRequest() {
		getReferenceTypes(this.props.company.uuid, (types) => {
			this.setState({
				...this.state,
				referenceNumberTypes: types
			}, () => {
				this.props.onChange && this.props.onChange(this.state.referenceNumberTypes);
			});
		}, () =>
			showError("Failed to get types")
		);
	}

	render() {
		return <Row>
			<Col>
				<Row>
					<Col>
						<h5 className="card-title">Reference Number Types</h5>
					</Col>
				</Row>
				<Row>
					<Col>
						<h6>Default Shipment Reference Number</h6>
					</Col>
				</Row>
				<Row>
					<Col>
						<Loader show={this.state.loadedNewReferenceType} message={this.spinner}>

							<Formik
								enableReinitialize
								initialValues={this.props.company}
								validate={validate(validationDefaultSchema)}
								onSubmit={(v, o) => {
									this.onSubmitDefaultLabel(v, o)
								}}
								render={
									({
										values,
										errors,
										touched,
										status,
										dirty,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										isValid,
										handleReset,
										setTouched,
										setFieldValue,
										setFieldTouched
									}) => (
										<Form onSubmit={(v) => {
											return handleSubmit(v)
										}}>
											<Row>
												<Col>
													<FormTextField
														label="Label"
														placeholder=""
														icon="fa-id-card"
														fieldname="shipmentIdDisplay"
														errors={errors}
														handleBlur={handleBlur}
														handleChange={handleChange}
														touched={touched}
														values={values}
														required={true}
													/>
												</Col>
											</Row>
											<Row>
												<Col className={"text-right"}>
													<Button color="primary" type="submit" disabled={this.state.loadedNewReferenceType}>
														Save
													</Button>
												</Col>
											</Row>
										</Form>
									)}
							/>
						</Loader>
					</Col>
				</Row>
				<Row>
					<Col>
						<h6>Additional Reference Number</h6>
					</Col>
				</Row>
				<Row>
					<Col>
						<Loader show={this.state.loadedDefaultLabel} message={this.spinner}>

							<Formik
								enableReinitialize
								initialValues={this.state.new}
								validate={validate(validationSchema)}
								onSubmit={(v, o) => {
									this.onSubmitNew(v, o)
								}}
								render={
									({
										values,
										errors,
										touched,
										status,
										dirty,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										isValid,
										handleReset,
										setTouched,
										setFieldValue,
										setFieldTouched
									}) => (
										<Form onSubmit={(v) => {
											return handleSubmit(v)
										}}>
											<Row>
												<Col>
													<FormTextField
														label="Label"
														placeholder=""
														icon="fa-id-card"
														fieldname="name"
														errors={errors}
														handleBlur={handleBlur}
														handleChange={(e) => {
															this.setState({
																new: {
																	...this.state.new,
																	name: e.target.value
																}
															});
															handleChange(e);
														}}
														touched={touched}
														values={values}
														required={false}
													/>
												</Col>
											</Row>
											<Row>
												<Col className={"text-right"}>
													<Button color="primary" type="submit" disabled={this.state.loadedDefaultLabel}>
														Add
													</Button>
												</Col>
											</Row>
										</Form>
									)}
							/>
						</Loader>
					</Col>
				</Row>
				{this.props.company.shipmentReferenceTypeList && this.props.company.shipmentReferenceTypeList.length > 0 &&
					<Row>
						<Col>
							<h6>Shipment Reference Number Types</h6>
						</Col>
					</Row>
				}
				{this.props.company.shipmentReferenceTypeList && this.props.company.shipmentReferenceTypeList.map((obj) => (
					<Formik
						enableReinitialize
						initialValues={obj}
						validate={validate(validationSchema)}
						onSubmit={(v, o) => {
							this.onSubmit(v, o)
						}}
						render={
							({
								values,
								errors,
								touched,
								status,
								dirty,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								isValid,
								handleReset,
								setTouched,
								setFieldValue,
								setFieldTouched
							}) => (
								<Form onSubmit={(v) => {
									return handleSubmit(v)
								}}>
									<FormTextField
										label="Name"
										placeholder=""
										fieldname="name"
										errors={errors}
										handleBlur={handleBlur}
										handleChange={(e) => {
											let type = obj;
											type.name = e.target.value;
											handleChange(e);
										}}
										touched={touched}
										values={values}
										required={true}
									/>
									<hr />
								</Form>)}
					/>))
				}
				{this.props.company.shipmentReferenceTypeList && this.props.company.shipmentReferenceTypeList.length > 0 &&
					<Row>
						<Col className={"text-right"}>
							<Button color="primary" onClick={() => {
								this.updateExisting();
							}}>
								Update
							</Button>
						</Col>
					</Row>
				}
			</Col>
		</Row>
	}

	updateExisting() {
		updateReferenceTypes(this.props.company.uuid, this.state.referenceNumberTypes, () => {
			this.getReferenceTypesRequest();
		}, () => {
			showError("Failed to update reference type");
		});
	}

}
