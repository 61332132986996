import Card from "reactstrap/lib/Card";
import CardHeader from "reactstrap/lib/CardHeader";
import CardBody from "reactstrap/lib/CardBody";
import CardDeck from "reactstrap/lib/CardDeck";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Label from "reactstrap/lib/Label";
import MomentDisplay from "../../assets/components/MomentDisplay";
import React, {Component} from "react";
import * as moment from "moment";
import {KILOMETERS_PER_MILE, MOVE_THRESHOLD_IN_MILES, NUMBER_OF_MILLISECONDS_IN_30_DAYS} from "../../common/constants";
import Link from "react-router-dom/Link";
import {convertCtoF} from "../../common/utilities";

class  EZTrackAssetDetailCard extends Component {
    formatCurrentAddress() {
        if (this.props.asset.latestEventAddress || this.props.asset.latestEventCity || this.props.asset.latestEventState
            || this.props.asset.latestEventPostal) {
            let address = this.props.asset.latestEventAddress ? this.props.asset.latestEventAddress : "Unknown address";
            let city = this.props.asset.latestEventCity ? this.props.asset.latestEventCity : "Unknown city";
            let state = this.props.asset.latestEventState ? this.props.asset.latestEventState : "Unknown state";
            let postal =  this.props.asset.latestEventPostal ? this.props.asset.latestEventPostal : "";
            return [address , city + ", " + state + " " + postal];
        } else {
            return [""];
        }
    }
    showBackLink() {
        if (this.props.location && this.props.location.state) {
            let lat = this.props.location.state.lat;
            let lng = this.props.location.state.lng;
            let zoom = this.props.location.state.zoom;
            if (lat !== undefined && lng !== undefined && zoom !== undefined) {
                return <Link to={{
                    pathname: "/eztrack-map",
                    state: {
                        lat: lat,
                        lng: lng,
                        zoom: zoom,
                    }
                }}>&laquo;&nbsp;Back to Map</Link>
            }
            let assetList = this.props.location.state.assetList;
            let searchParams = this.props.location.state.searchParams;
            let index = this.props.location.state.index;
            let trackerTypeFilter = this.props.location.state.trackerTypeFilter;
            if (assetList !== undefined && searchParams !== undefined && index !== undefined) {
                let previous = "";
                let prevAssetIndex = null;
                for (let idx=index-1; idx >= 0; idx--) {
                    if (assetList[idx].asset !== null && assetList[index].asset !== null &&
                        assetList[idx].asset.uuid !== assetList[index].asset.uuid) {
                        prevAssetIndex = idx;
                        break;
                    }
                }
                let nextAssetIndex = null;
                for (let idx=index+1; idx < assetList.length; idx++) {
                    if (assetList[idx].asset !== null && assetList[index].asset !== null &&
                        assetList[idx].asset.uuid !== assetList[index].asset.uuid) {
                        nextAssetIndex = idx;
                        break;
                    }
                }
                if (prevAssetIndex !== null) {
                    previous = <Link to={{
                            pathname: "/asset-profile/" + assetList[prevAssetIndex].asset.uuid,
                            state: {
                                assetList: assetList,
                                searchParams: searchParams,
                                index: prevAssetIndex,
                                trackerTypeFilter: trackerTypeFilter,
                            }
                        }}>&laquo;&nbsp;Previous Asset ({assetList[prevAssetIndex].asset.assetId})</Link>
                }
                let back = <Link to={{
                    pathname: "/eztrack-assets",
                    state: {
                        assetList: assetList,
                        searchParams: searchParams,
                        index: index-1,
                        trackerTypeFilter: trackerTypeFilter,
                    }
                }}>Back to List</Link>
                let next = "";
                if (nextAssetIndex !== null) {
                    next = <Link to={{
                        pathname: "/asset-profile/" + assetList[nextAssetIndex].asset.uuid,
                        state: {
                            assetList: assetList,
                            searchParams: searchParams,
                            index: nextAssetIndex,
                            trackerTypeFilter: trackerTypeFilter,
                        }
                    }}>Next Asset ({assetList[nextAssetIndex].asset.assetId})&raquo;&nbsp;</Link>
                }
                return <> {previous} {previous && "|"} {back} {next && "|"} {next} </>
            }
        }
    }
    formatMoveThreshold() {
        if (this.props.asset.company.distanceUnitsOfMeasure === 'Miles') {
            return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1})
                .format(MOVE_THRESHOLD_IN_MILES).concat(" miles");
        } else {
            return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1})
                .format(MOVE_THRESHOLD_IN_MILES * KILOMETERS_PER_MILE).concat(" kilometers");
        }
    }
    formatDistanceFromDomicile() {
        if (this.props.asset.company.distanceUnitsOfMeasure === 'Miles') {
            return this.props.asset.distanceFromDomicileInMeters ?
                new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                    .format(this.props.asset.distanceFromDomicileInMeters / 1000.0 / KILOMETERS_PER_MILE) + " Miles" :
                null
        } else {
            let distanceInMeters = this.props.asset.distanceFromDomicileInMeters;
            if (distanceInMeters != null) {
                return new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                    .format(distanceInMeters / 1000.0) + " Km";
            } else {
                return null;
            }
        }
    }
    formatIdleTime() {
        let v = this.props.asset;
        if (v.dateOfLastMove) {
            let movingDay = new Date(v.dateOfLastMove);
            let durationInMillis = new Date().getTime() - movingDay.getTime();
            let d = moment.duration(durationInMillis);
            return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1}).format(d.asDays());
        } else {
            return null;
        }
    }
    formatExcursionTime() {
        let v = this.props.asset;
        if (v.excursionTime) {
            let movingDay = new Date(v.excursionTime);
            let durationInMillis = new Date().getTime() - movingDay.getTime();
            let d = moment.duration(durationInMillis);
            return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1}).format(d.asDays());
        } else {
            return "Not Calculated";
        }
    }
    formatDwellTime() {
        let v = this.props.asset;
        if (v.dwellTime) {
            let movingDay = new Date(v.dwellTime);
            let durationInMillis = new Date().getTime() - movingDay.getTime();
            let d = moment.duration(durationInMillis);
            return new Intl.NumberFormat("en-US", {maximumFractionDigits: 1}).format(d.asDays());
        } else {
            return "Not Calculated";
        }
    }
    formatMoveFrequency() {
        if (this.props.asset.movesInLast30Days >= 0) {
            return new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                .format(this.props.asset.movesInLast30Days / 30 * 100) + "%";
        }
        return null;
    }
    showMoveFrequency() {
        if (this.props.asset.oldestEventDate &&
            new Date(this.props.asset.oldestEventDate).getTime() >
            new Date().getTime() - NUMBER_OF_MILLISECONDS_IN_30_DAYS) {
            return <><Label> Move Frequency: {this.formatMoveFrequency()} </Label>
                <br/><i>Move frequency is based off of 30 days from yesterday, but the earliest day we have an event for this asset is&nbsp;
                    <MomentDisplay date={this.props.asset.oldestEventDate}
                                   timezone={Intl.DateTimeFormat().resolvedOptions().timeZone} />.
                    Based on the timezone of the asset's domicile ({moment.tz(this.props.asset.domicile.timezone).format('z')}).</i></>
        } else if (this.props.asset.oldestEventDate) {
            return <><Label> Move Frequency: {this.formatMoveFrequency()} </Label>
                <br/><i>Percentage of days with a move in the 30 day period ending yesterday. Based on the timezone of the asset's domicile.</i></>
        } else {
            return <> <Label> Move Frequency: </Label>
                <br/><i>Move frequency is based off of 30 days from yesterday, but there are no events within that window for this asset.
                    Based on the timezone of the asset's domicile ({moment.tz(this.props.asset.domicile.timezone).format('z')}).</i></>
        }
    }
    render() {
        return (
            <Card>
                <CardHeader className="text-left">
                    <h1>EZTrack Asset {this.props.asset.assetId}</h1>
                    { this.showBackLink()}
                </CardHeader>
                <CardBody className="p-1">
                    <CardDeck style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        <Card className="dispatch-card text-left" style={{flexGrow: 1}}>
                            <CardBody>
                                <div>
                                    <Row>
                                        <Col xs={1}><i className="fa fa-tag"/>
                                        </Col>
                                        <Col>
                                            <Label>Asset ID: {this.props.asset.assetId } </Label>
                                        </Col>
                                    </Row>
                                    {this.props.asset.mostRecentEventShipmentName &&
                                        <Row>
                                            <Col xs={1}>
                                                <i className="fa fa-box-up" />
                                            </Col>
                                            <Col>
                                                <Label>Shipment ID: {this.props.asset.mostRecentEventShipmentName }</Label>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col xs={1}><i className="fa fa-truck-loading"/>
                                        </Col>
                                        <Col>
                                            <Label>Equipment Type: {this.props.asset.equipment.name } </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={1}><i className="fa fa-home"/>
                                        </Col>
                                        <Col>
                                            <Label>Domicile Location: {this.props.asset.domicile.name} </Label>
                                        </Col>
                                    </Row>
                                    {this.props.asset.devices.map(d => {
                                        return <>
                                            <Row>
                                                <Card className="m-1">
                                                    <CardHeader>
                                                        <Col>Zone {d.zone}</Col>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs={1}><i className="fa fa-barcode"/>
                                                            </Col>
                                                            <Col>
                                                                {d.eventCount === 1 ?
                                                                    <Label>Device ID: {d.imei} (1 event)</Label>
                                                                    :
                                                                    <Label>Device ID: {d.imei} ({d.eventCount || 0} events)</Label>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={1}><i className="fa fa-broadcast-tower"/>
                                                            </Col>
                                                            <Col>
                                                                <Label>Device: {d.trackerType}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={1}><i className="fa fa-temperature-high"/>
                                                            </Col>
                                                            <Col>
                                                                <Label>Temperature (℉): {d.temperatureInC != null ? convertCtoF(d.temperatureInC) : null}</Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs={1}><i className="fa fa-calendar"/>
                                                            </Col>
                                                            <Col>
                                                                <Label>Last Report Time: {d.latestEventDate ?
                                                                    <MomentDisplay date={d.latestEventDate} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/>
                                                                    : null
                                                                } </Label>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Row>
                                        </>
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="text-left" style={{flexGrow: 1}}>
                            <CardBody>
                                <Row>
                                    <Col xs={1}><i className="fa fa-map-signs"/>
                                    </Col>
                                    <Col>
                                        <Label> Current Address:<br/>
                                            {this.props.asset.locationName ? this.props.asset.locationName : ""}
                                            {this.props.asset.locationName ? <br/> : ""}
                                            {this.formatCurrentAddress()[0]}
                                            {this.formatCurrentAddress().length === 2 ? <br/> : null}
                                            {this.formatCurrentAddress().length === 2 ? this.formatCurrentAddress()[1] : null}</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1}><i className="fa fa-shipping-timed"/>
                                    </Col>
                                    <Col>
                                        <><Label>Dwell Time: {this.formatDwellTime()} </Label>
                                            <br/><i>Number of days the asset has been within the current EZTrack Location Geo-fence.</i><br/>&nbsp;</>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1}><i className="fa fa-clock"/>
                                    </Col>
                                    <Col>
                                        <><Label>Idle Time (Days Since Last Move): {this.formatIdleTime()} </Label>
                                            <br/><i>Number of days between the current time and when the asset last moved more than {this.formatMoveThreshold()} (from the prior event).</i><br/>&nbsp;</>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1}><i className="fa fa-tape"/>
                                    </Col>
                                    <Col>
                                        <Label> Distance from Domicile: {this.formatDistanceFromDomicile()} </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1}><i className="fa fa-route"/>
                                    </Col>
                                    <Col>
                                        <><Label>Excursion Time: {this.formatExcursionTime()} </Label>
                                            <br/><i>Number of days between the event and when the asset left a domicile geo-fence.</i><br/>&nbsp;</>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1}><i className="fa fa-calendar"/>
                                    </Col>
                                    <Col>
                                        <Label> Last Move Date: {this.props.asset.dateOfLastMove ?
                                            <MomentDisplay date={this.props.asset.dateOfLastMove} timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}/>
                                            : null
                                        } </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={1}><i className="fa fa-business-time"/>
                                    </Col>
                                    <Col>
                                        {this.showMoveFrequency()}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </CardDeck>
                </CardBody>
            </Card>
            )
    }
}
export default EZTrackAssetDetailCard;
