import React, { Component } from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { getFormattedDwellDateString } from "../../assets/components/StaticDurationDisplay";
class ReferenceModal extends Component {
  constructor(props) {
    super(props);
    console.log("props in modal ", props);
    // this.props.selectedTask.refData.prAppointmentDate = 1231211;
    //  let a = this.props.selectedTask.refData.prAppointmentWindow;
    //  a= 9999666333
  }

  renderForm() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-0">
            <div className="card-body">
              <ul class="list-group list-group-flush">
                {/* <li class="list-group-item">
                  Ref Number:{" "}
                  {this.props.selectedTask.refData.secondaryRef === null
                    ? "N/A"
                    : this.props.selectedTask.refData.secondaryRef}
                </li>
                <li class="list-group-item">
                  Ref Name:{" "}
                  {this.props.selectedTask.refData.prName === null
                    ? "N/A"
                    : this.props.selectedTask.refData.prName}
                </li> */}
                <li class="list-group-item">
                  Ref Partner:{" "}
                  {
                    this.props.selectedTask.refData.partnerName === null
                      ? "N/A"
                      : this.props.selectedTask.refData.partnerName
                  }
                </li>
                <li class="list-group-item">
                  Appointment Start Datetime:{" "}
                  {this.props.selectedTask.refData.prAppointmentStartDate ===
                    null ? (
                    "N/A"
                  ) : (
                    <MomentDisplay
                      date={this.props.selectedTask.refData.prAppointmentStartDate}
                      timezone={
                        this.props.currentLocation &&
                        this.props.currentLocation.timezone
                      }
                    />
                  )}
                </li>
                <li class="list-group-item">
                  Appointment End Datetime:{" "}
                  {this.props.selectedTask.refData.prAppointmentEndDate ===
                    null ? (
                    "N/A"
                  ) : (
                    <MomentDisplay
                      date={this.props.selectedTask.refData.prAppointmentEndDate}
                      timezone={
                        this.props.currentLocation &&
                        this.props.currentLocation.timezone
                      }
                    />
                  )}
                </li>
                <li class="list-group-item">
                  Appointment Window:{" "}
                  {this.props.selectedTask.refData.prAppointmentWindow === null
                    ? "N/A" :
                    this.props.selectedTask.refData.prAppointmentWindow
                    // getFormattedDwellDateString(this.props.selectedTask.refData.prAppointmentWindow)
                  }
                  {/* : this.props.selectedTask.refData.prAppointmentWindow} */}
                </li>
                <li class="list-group-item">
                  Appointment Type:{" "}
                  {this.props.selectedTask.refData.prAppointmentType === null
                    ? "N/A"
                    : this.props.selectedTask.refData.prAppointmentType}
                </li>
                <li class="list-group-item">
                  Appointment Plan:{" "}
                  {this.props.selectedTask.refData.prAppointmentPlan === null
                    ? "N/A"
                    : this.props.selectedTask.refData.prAppointmentPlan}
                </li>
                <li class="list-group-item">
                  Assigned Carrier Name:{" "}
                  {this.props.selectedTask.refData.carrier === null
                    ? "N/A"
                    : this.props.selectedTask.refData.carrier.name}
                </li>
                <li class="list-group-item">
                  SCAC:{" "}
                  {this.props.selectedTask.refData.prScac === null
                    ? "N/A"
                    : this.props.selectedTask.refData.prScac}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <Modal isOpen={this.props.isRefModalOpen} size={"md"}>
        <ModalHeader>
          {this.props.selectedTask === null ||
            this.props.selectedTask === undefined
            ? "Reference: " + ""
            : "Reference: " + this.props.selectedTask.clickedRef}
        </ModalHeader>
        <ModalBody style={{ overflow: "auto" }}>{this.renderForm()}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.props.cancel()}>
            Close
          </Button>{" "}
          &nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}
export default ReferenceModal;
