import React, {Component} from 'react';
import {Table, Button, Row, Col} from "reactstrap";
import {camelCaseToWords} from "../../common/utilities";
const ReactMarkdown = require('react-markdown')


class Workflow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customizations: props.customizations.slice(),
            uncheckedSteps: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.props.customizations !== prevProps.customizations ) {
            this.setState( {customizations: this.props.customizations.slice()});
        }
    }


    lookupCustomizationByStepUuid(uuid ) {
        for ( let i=0;i<this.state.customizations.length;i++ ) {
            if ( this.state.customizations[i].stepUuid === uuid ) {
                return this.state.customizations[i];
            }
        }
        return null;
    }

    //for now, skip is the only thing that can be customized
    onStepChanged( step, skip, messageTemplate, htmlTemplate, invalidResponseTemplate ) {
        let customization = this.lookupCustomizationByStepUuid(step.uuid);
        if ( customization ) {
            // avoid mutating actual state:
            let newCustomizations = this.state.customizations.slice();
            if ( !skip && !messageTemplate && !htmlTemplate && !invalidResponseTemplate ) {
                // remove the existing customization, since there is no customization
                for (let i = 0; i < newCustomizations.length; i++) {
                    if (newCustomizations[i].stepUuid === step.uuid) {
                        newCustomizations.splice(i,1);
                        break;
                    }
                }
            } else {
                // avoid mutating actual state:
                let replacementCustomization = Object.assign({}, customization);
                replacementCustomization.skip = skip;
                replacementCustomization.edited = true;
                replacementCustomization.messageTemplate = messageTemplate;
                replacementCustomization.htmlTemplate = htmlTemplate;
                replacementCustomization.invalidResponseTemplate = invalidResponseTemplate;
                for (let i = 0; i < newCustomizations.length; i++) {
                    if (newCustomizations[i].stepUuid === step.uuid) {
                        newCustomizations[i] = replacementCustomization;
                        break;
                    }
                }
            }
            this.setState({customizations: newCustomizations});
        } else {
            if ( !skip && !messageTemplate && !htmlTemplate && !invalidResponseTemplate ) {
                // do nothing...there was no customization
            } else {
                let newCustomization = {
                    stepUuid: step.uuid,
                    messageTemplate:messageTemplate,
                    htmlTemplate: htmlTemplate,
                    invalidResponseTemplate: invalidResponseTemplate,
                    uuid: null,
                    edited: true,
                    skip: true
                }
                let newCustomizations = this.state.customizations.slice();
                newCustomizations.push( newCustomization);
                this.setState({customizations:newCustomizations});
            }
        }
    }

    checkboxForStep(step ) {
        // onStepChange needs the template changes also once we implement template customizations
        return <>
            <input type="checkbox" onChange={(v)=>this.onStepChanged(step, !v.target.checked )} checked={this.isStepChecked(step)}/>
        </>
    }

    isStepChecked( step ) {
        let customization = this.lookupCustomizationByStepUuid(step.uuid);
        return !customization || !customization.skip;
    }

    render() {
        return <div><h4>{this.props.workflow.name}</h4>
            <Row className="pb-5">
                <Col>
                    <h4>At workflow start, set:</h4><br />
                    <strong>Arriving shipment status:</strong> {this.props.workflow.shipmentStatus?this.props.workflow.shipmentStatus:"N/A"}<br />
                    <strong>Departing shipment status:</strong> {this.props.workflow.departingShipmentStatus?this.props.workflow.departingShipmentStatus:"N/A"}<br />
                    <strong>Welcome Message:</strong> <div className="d-inline">{this.props.workflow.welcomeMessage}</div>
                </Col>
            </Row>

            <Table responsive striped bordered>
                <thead>
                    <tr>
                        <th>Step</th>
                        <th>Enabled</th>
                        <th>Description</th>
                        <th>Action</th>
                        <th>Inclusion<br />Criteria</th>
                        <th>Next Button</th>
                        <th className="text-center">Arriving<br />Shipment<br />Status</th>
                        <th className="text-center">Departing<br />Shipment<br />Status</th>
                        <th>Web Message</th>
                        <th>SMS Message</th>
                        <th>SMS Invalid Reponse</th>
                        <th>Input</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.workflow.steps.map((step, ix)=>{
                        return <tr key={ix}>
                            <td>
                                <h3>{ix+1}</h3>
                            </td>
                            <td className="text-center">
                                {step.required?"Required":this.checkboxForStep(step)}
                            </td>
                            <td>
                                {step.description}
                            </td>
                            <td className="">
                                {step.workflowStepAction?camelCaseToWords(step.workflowStepAction):"None"}
                            </td>
                            <td className="">
                                {step.inclusionCriteria?camelCaseToWords(step.inclusionCriteria):"None"}
                            </td>
                            <td className="text-center">
                                {ix===this.props.workflow.steps.length-1?"N/A":(step.nextButtonText?step.nextButtonText:"Next")}
                            </td>
                            <td className="text-center">
                                {step.shipmentStatus?step.shipmentStatus:"N/A"}
                            </td>
                            <td className="text-center">
                                {step.departingShipmentStatus?step.departingShipmentStatus:"N/A"}
                            </td>
                            <td>
                                {step.header && <div className={"workflow-header"} >{step.header}</div>}
                                <ReactMarkdown source={step.htmlTemplate} />
                            </td>
                            <td>
                                <ReactMarkdown source={step.messageTemplate} />
                            </td>
                            <td>
                                <ReactMarkdown source={step.invalidResponseTemplate} />
                            </td>
                            <td>
                                {step.freeForm?step.acceptedResponseRegex:"N/A"}
                            </td>
                    </tr>
                    })}
                </tbody>
            </Table>
            <div>
                <Button color="primary" onClick={()=>this.props.saveWorkflowCustomizations(this.state.customizations)} >Save</Button>&nbsp;
                <Button color="secondary" onClick={()=>this.props.resetWorkflow()} >Reset</Button>
            </div>
        </div>
    }

}


export default Workflow;