import React from "react";
import PropTypes from "prop-types";
import Modal from "reactstrap/es/Modal";
import { Button, Card, Form, ModalBody } from "reactstrap";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import { postRegisterUser } from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import FFFormPhoneTextField from "../../common/formik-free/FFFormPhoneTextField";
import FormTextAreaField from "../../common/FormTextAreaField";
import CardBody from "reactstrap/lib/CardBody";

import FFFormTextField from "../../common/formik-free/FFFormTextField";

import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
const initialValues = {
  entityName: "",
  typeOfUser: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  firstname: "",
  lastname: "",
  username: "",
  email: "",

  country: "United States",
  postalCode: "",
  dbaName: "",
};

export default class AccessModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestForm: {},
      errors: [],
      touched: {},
      loader: false,
    };
    this.updateSpinner("Submitting ...");
    // this.setState({

    // });
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }

  validationSchema(values) {
    let validationRules = {
      firstname: Yup.string().required("First name is required.").nullable(),
      lastname: Yup.string().required("Last name is required.").nullable(),

      phoneNumber: Yup.string().required("Phone is required.").nullable(),
      entityName: Yup.string()
        .required("Legal Entity Name is required.")
        .nullable(),
      email: Yup.string().required("Email is required.").nullable(),

      confirmEmail: Yup.string()
        .oneOf([values.email], "Email must match")
        .required("Email confirmation is required"),
    };
    return Yup.object().shape(validationRules);
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  }

  onSubmit() {
    let s = { ...this.state.requestForm };
    s.enableTrackAssured = true;
    s.username = s.email;
    this.setState({ loader: true });
    postRegisterUser(
      s,
      (user) => {
        this.setState({ loader: false });
        showInfo("Request form has been successfully submitted.");
        this.setState({
          requestForm: {},
          errors: [],
          touched: {},
        });
        return this.props.onSave && this.props.onSave(true);
      },
      (e) => {
        this.setState({ loader: false });
        showError(e.message ? e.message : "Unable to register.");
        
      }
    );
  }
  validForm() {
    let r =
      this.state.email &&
      this.state.password &&
      this.state.passwordConfirm === this.state.password &&
      this.state.firstname &&
      this.state.lastname;
    r =
      r &&
      this.state.entityName &&
      this.state.typeOfUser &&
      this.state.address1 &&
      this.state.city &&
      this.state.country &&
      this.state.postalCode;
    return r;
  }

  onChange(fieldName, newValue) {
    this.props.values[fieldName] = newValue;
    this.props.setFieldTouched(fieldName);
    this.props.onChange(this.props.values);
  }
  render() {
    return (
      <Modal isOpen={this.props.open} size={"lg"}>
        <ModalBody className={"p-0 rounded"} style={{ overflow: "auto" }}>
          <Card className={"mb-0 rounded"}>
            <Loader show={this.state.loader} message={this.spinner}>
              <CardBody className={"px-4 rounded"}>
                <h3
                  className={"text-center primary-text-color font-weight-bold"}
                >
                  Request Access{" "}
                  <Button
                    color={"primary"}
                    onClick={() => this.props.onCancel && this.props.onCancel()}
                    className={"float-right rounded-circle"}
                  >
                    <i className={"far fa-times"}></i>
                  </Button>
                </h3>
                <Form
                  onSubmit={(e) => {
                    let errors = this.validate(
                      this.validationSchema.bind(this)
                    )(this.state.requestForm);

                    let errorsIsEmpty =
                      Object.keys(errors).length === 0 &&
                      errors.constructor === Object;
                    if (!errorsIsEmpty || this.state.errors.name) {
                      let t = { ...this.state.touched };
                      t.firstname = true;
                      t.lastname = true;

                      t.phoneNumber = true;
                      t.entityName = true;
                      t.email = true;
                      t.confirmEmail = true;
                      t.website = true;

                      this.setState({ touched: t, errors: errors });
                      e.preventDefault();
                      return;
                    }

                    this.onSubmit();
                    e.preventDefault();
                  }}
                >
                  <div>
                    <FFFormTextField
                      fieldname="firstname"
                      label="First Name"
                      icon="fa-user"
                      value={
                        this.state.requestForm.firstname
                          ? this.state.requestForm.firstname
                          : ""
                      }
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.firstname = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.firstname}
                      touched={this.state.touched.firstname}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.firstname = v;
                        this.setState({ requestForm: s });
                      }}
                    />
                    <FFFormTextField
                      fieldname="lastname"
                      label="Last Name"
                      icon="fa-user"
                      value={
                        this.state.requestForm.lastname
                          ? this.state.requestForm.lastname
                          : ""
                      }
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.lastname = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.lastname}
                      touched={this.state.touched.lastname}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.lastname = v;
                        this.setState({ requestForm: s });
                      }}
                    />

                    <FFFormTextField
                      fieldname="entityName"
                      label="Legal Entity Name"
                      lowerLabel="(Company Name)"
                      icon="fa-id-card"
                      value={
                        this.state.requestForm.entityName
                          ? this.state.requestForm.entityName
                          : ""
                      }
                      required={true}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.entityName = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.entityName}
                      touched={this.state.touched.entityName}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.entityName = v;
                        this.setState({ requestForm: s });
                      }}
                    />
                    <FFFormTextField
                      fieldname="dbaName"
                      label="DBA Name"
                      icon="fa-gavel"
                      value={
                        this.state.requestForm.dbaName
                          ? this.state.requestForm.dbaName
                          : ""
                      }
                      required={false}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.dbaName = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      error={this.state.errors.dbaName}
                      touched={this.state.touched.dbaName}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.dbaName = v;
                        this.setState({ requestForm: s });
                      }}
                    />
                    <FFFormPhoneTextField
                      label="Phone"
                      icon="fa-phone"
                      required={true}
                      fieldname="phoneNumber"
                      countryCodeFieldName="countryCode"
                      error={this.state.errors.phoneNumber}
                      onChange={(e) => {
                        let s = { ...this.state.requestForm };
                        s.phoneNumber = e.target.value;
                        this.setState({ requestForm: s });
                      }}
                      onCountryCodeChange={(value) => {
                        let s = { ...this.state.requestForm };
                        s.countryCode = value;
                        this.setState({ requestForm: s });
                      }}
                      countryCode={this.state.requestForm.countryCode}
                      touched={this.state.touched.phoneNumber}
                      placeholder={
                        this.state.requestForm.countryCode === "US" ||
                        !this.state.requestForm.countryCode
                          ? "###-###-#### ext. XXXX"
                          : "#########"
                      }
                    />
                    <FFFormPhoneTextField
                      label="Mobile"
                      icon="fa-phone"
                      required={false}
                      fieldname="mobileNumber"
                      countryCodeFieldName="countryCode"
                      error={this.state.errors.mobile}
                      onChange={(e) => {
                        let s = { ...this.state.requestForm };
                        s.mobileNumber = e.target.value;
                        this.setState({ requestForm: s });
                      }}
                      onCountryCodeChange={(value) => {
                        let s = { ...this.state.requestForm };
                        s.countryCode = value;
                        this.setState({ requestForm: s });
                      }}
                      countryCode={this.state.errors.mobile}
                      touched={this.state.touched.mobile}
                      placeholder={
                        this.state.requestForm.countryCode === "US" ||
                        !this.state.requestForm.countryCode
                          ? "###-###-#### ext. XXXX"
                          : "#########"
                      }
                    />
                    <FFFormTextField
                      label="Work Email"
                      icon="fa-envelope-square"
                      placeholder=""
                      fieldname="email"
                      error={this.state.errors.email}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.email = v;
                        this.setState({ requestForm: s });
                      }}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.email = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      touched={this.state.touched.email}
                      required={true}
                    />
                    <FFFormTextField
                      label="Confirm &nbsp; Email"
                      icon="fa-envelope-square"
                      placeholder=""
                      fieldname="confirmEmail"
                      error={this.state.errors.confirmEmail}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.confirmEmail = v;
                        this.setState({ requestForm: s });
                      }}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.confirmEmail = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      touched={this.state.touched.confirmEmail}
                      required={true}
                    />
                    <FFFormTextField
                      label="Company Website"
                      icon="fa-globe"
                      type="url"
                      placeholder=""
                      fieldname="website"
                      error={this.state.errors.website}
                      onChange={(v) => {
                        let s = { ...this.state.requestForm };
                        s.website = v;
                        this.setState({ requestForm: s });
                      }}
                      onBlur={() => {
                        let errors = this.validate(
                          this.validationSchema.bind(this)
                        )(this.state.requestForm);
                        let touched = { ...this.state.touched };
                        touched.website = true;
                        this.setState({ errors: errors, touched: touched });
                      }}
                      touched={this.state.touched.website}
                    />
                    <FormTextAreaField
                      label="Message Here"
                      placeholder=""
                      fieldname="message"
                      errors={this.state.errors.message}
                      handleChange={(k) => {
                        let s = { ...this.state.requestForm };
                        s.message = k.target.value;
                        this.setState({ requestForm: s });
                      }}
                      touched={this.state.touched.message}
                    />
                    <Row>
                      <Col>
                        <p
                          className={"float-right"}
                          style={{
                            color: this.getNumber(100) < 0 ? "red" : null,
                          }}
                        >
                          Text remaining: {this.getNumber(100)}
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-3 text-center">
                    <Button
                      color="danger"
                      onClick={() =>
                        this.props.onCancel && this.props.onCancel()
                      }
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      color="primary"
                      type="submit"
                      disabled={!(this.getNumber(100) >= 0)}
                    >
                      Send Request
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Loader>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
  showIdentification(values) {
    return (
      values["typeOfUser"] === "Carrier" ||
      values["typeOfUser"] === "Broker" ||
      values["typeOfUser"] === "Logistics Provider"
    );
  }
  getNumber(textCharacterLimit) {
    let value = this.state.requestForm.message;

    return textCharacterLimit - (value ? value.length : 0);
  }
}

AccessModal.propTypes = {
  open: PropTypes.any,
  requestForm: PropTypes.any,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
