import React from 'react';
import PropTypes from 'prop-types';
import Modal from "reactstrap/es/Modal";
import {
    Button,
    Card,
    CardText,
    CardTitle,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    ModalBody,
    ModalHeader
} from "reactstrap";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {getErrorsFromValidationError} from "../../common/utilities";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import MomentDisplay from "./MomentDisplay";

export default class NoteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            note: ""
        }
    }

    validationSchema(values) {
        return Yup.object().shape({
            note: Yup.string().required('Note is required.').nullable()
        })
    }

    onSubmit(values, {setSubmitting, setErrors}) {
        setSubmitting(false);
        this.props.onSave(values['note'], setErrors);
        return false;
    }

    validate(getValidationSchema) {
        return (values) => {
            const validationSchema = getValidationSchema(values);
            try {
                validationSchema.validateSync(values, {abortEarly: false});
                return {}
            } catch (error) {
                console.log("error", error);
                return getErrorsFromValidationError(error)
            }
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.open} size={"lg"}>
                <ModalHeader>
                    Add Note
                </ModalHeader>
                <ModalBody style={{overflow: 'auto'}}>
                    <Formik
                        onSubmit={(v, o) => {
                            this.onSubmit(v, o)
                        }}
                        validate={this.validate(this.validationSchema.bind(this))}
                        initialValues={this.state}
                        render={({
                                     values,
                                     errors,
                                     touched,
                                     status,
                                     dirty,
                                     handleChange,
                                     handleBlur,
                                     handleSubmit,
                                     isSubmitting,
                                     isValid,
                                     handleReset,
                                     setTouched,
                                     setFieldValue,
                                     setFieldTouched
                                 }) => (
                            <Form onSubmit={(v) => {
                                return handleSubmit(v)
                            }}>
                                {this.props.notes && Object.keys(this.props.notes).length > 0 &&
                                    <Row>
                                        <Col md={12}>
                                            <h3>Notes</h3>
                                        </Col>
                                    </Row>
                                }
                                <div className="note-scollbox">
                                {this.props.notes && Object.keys(this.props.notes).length > 0 && Object.keys(this.props.notes).map((uuid, ix) =>
                                    <Row key={ix}>
                                        <Col md={12}>
                                            <Card body>
                                                <CardTitle>
                                                    <Row>
                                                        <Col sm={12}>
                                                            {this.props.notes[uuid].user}
                                                        </Col>
                                                        <Col sm={12}>
                                                            <MomentDisplay date={this.props.notes[uuid].created} timezone={this.props.currentLocation && this.props.currentLocation.timezone}/>
                                                        </Col>
                                                    </Row>
                                                </CardTitle>
                                                <CardText>{this.props.notes[uuid].note}</CardText>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                                </div>
                                <Row>
                                    <Col md={12}>
                                        <h3>New Note</h3>
                                    </Col>
                                </Row>
                                {this.props.error &&
                                <Row>
                                    <Col md={12}>
                                        <p style={{color: "red"}}>{this.props.error}</p>
                                    </Col>
                                </Row>
                                }
                                <FormGroup>
                                    <InputGroup>
                                        <Input
                                            type={'textarea'}
                                            name={'note'}
                                            valid={touched['note'] && !errors['note']}
                                            invalid={touched['note'] && !!errors['note']}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values['notes']}
                                        />
                                        <FormFeedback>{errors['note']}</FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <div className="text-right">
                                    <Button color="primary" onClick={() => this.props.onCancel()}>Cancel</Button> &nbsp;
                                    <Button color="primary" type="submit">Save</Button>
                                </div>
                            </Form>)}
                    />
                </ModalBody>
            </Modal>
        );
    }
}
NoteModal.propTypes = {
    notes: PropTypes.object,
    assetId: PropTypes.any,
    open: PropTypes.any,
    uuid: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};
    