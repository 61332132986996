import React, { Component } from 'react';
import {
    Col,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import * as uuid from 'uuid';
import FFCountryCodeSelect from "./FFCountryCodeSelect";
import ValidationService from "../ValidationService";

class FFFormPhoneTextField extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4(),
        };
    }

    icon() {
        if (this.props.icon) {
            return <i className={"fa " + this.props.icon}></i>
        } else if (this.props.iconText) {
            return this.props.iconText;
        }
        return " ";
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }


    render() {
        return (
            <FormGroup>
                <Row>
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} <span className={"text-danger"}>{this.props.label && this.props.required ? '*' : ''}</span></Label>
                        {this.props.helpMessage &&
                            <div>
                                <i style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "5px",
                                    color: "grey"
                                }}
                                    className={"fa fa-question-circle"}
                                    id={this.state.id}
                                />
                                <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
                                    {this.props.helpMessage}
                                </Tooltip>
                            </div>
                        }
                    </Col>
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            <div className="country-code-select-wrapper">
                                <FFCountryCodeSelect
                                    fieldname={this.props.countryCodeFieldName}

                                    onChange={this.props.onCountryCodeChange}

                                    value={this.props.countryCode}
                                    setFieldValue={this.props.setFieldValue}
                                    setFieldTouched={this.props.setFieldTouched}
                                    // onChange={(cc) => {
                                    //     this.props.onCountryCodeChange(cc.region);
                                    // }}
                                />
                            </div>
                            <Input type={this.props.password ? "password" : (this.props.numbersOnly ? "number" : "text")}
                                className="phone-input-field"
                                placeholder={this.props.placeholder}
                                step="any"
                                name={this.props.fieldname}
                                valid={this.props.touched && !this.props.error}
                                invalid={this.props.touched && !!this.props.error}
                                onChange={(e) => {
                                    this.props.onChange(e);
                                }}
                                onBlur={(e) => {
                                    e.target.value = ValidationService.formatPhone(this.props.countryCode, e.target.value, true);
                                    this.props.onChange(e);
                                }}
                                value={this.props.value}
                            />
                            <FormFeedback>{this.props.error}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FFFormPhoneTextField;