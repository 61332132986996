import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { importLocationFile, postUpdateFreeField, postFreeField, requestLocationsToExcel, GetFreeFieldsByOrganizationUuid } from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import FreeFieldForm from "./FreeFieldForm";
import { PaginationFilterTableArrival } from "../../common/PaginationFilterTableArrival";
import { FileDropModal } from "../../common/FileDropModal";
import { showError, showInfo } from "../../MainApp";
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { storeCurrentLocation, storeCurrentUser } from "../../Login/actions/Login";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class FreeFields extends Component {
    tableColumns = [
        {
            Header: "Created",
            accessor: 'createdDate',
            filterable: false,
            sortable: true,
            width: 200,
            Cell: row => { let v = row.original; return v.createdDate ? <MomentDisplay date={v.createdDate + "Z"} timezone={this.props.currentLocation && this.props.currentLocation.timezone} /> : "New"; }
        },
        {
            Header: "Name",
            filterable: true,
            accessor: 'name',
            sortable: true,
            Cell: row => {
                return row.original.name;
            }
        },
        {
            Header: "Type",
            accessor: 'type',
            filterable: true,
            sortable: true,
            Cell: row => { let v = row.original; return v.type ? v.type : ""; },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.typeFilterRef}
                        onChange={event => {
                            this.setState({ typeFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.typeFilter ? this.state.typeFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="int">Int</option>
                        <option value="string">String</option>
                        <option value="bool">Bool</option>
                        <option value="datetime">Datetime</option>
                    </select>
                </>
        },

        {
            Header: "Enabled",
            accessor: 'isEnable',
            filterable: true,
            sortable: true,
            Cell: row => { let v = row.original; return v.isEnable === true ? "Yes" : "No"; },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={event => {
                            this.setState({ enabledFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.enabledFilter ? this.state.enabledFilter : undefined}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },

        {
            Header: 'Action',
            filterable: false,
            accessor: 'action',
            Cell: row => { let v = row.original; return <span><Button onClick={() => this.setState({ editingFField: { ...v }, showModal: true })}><i className="fa fa-edit" /> Edit </Button></span>; },
            sortable: false
        }
    ];


    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.enabledFilterRef = React.createRef();
        this.typeFilterRef = React.createRef();
        let localStorageState = JSON.parse(localStorage.getItem("ezcheckin-admin-freefields"));
        if (localStorageState != null) {
            this.state = localStorageState;
        } else {
            this.state = {
                pages: -1,
                editingFField: null,

                // showOnlyIncomplete: false,
                previousSearchRequest: {
                    sorted: [],
                    filtered: [{ id: 'OrganizationUuid', value: props.company.uuid }]
                },
                filtered: [{ id: 'OrganizationUuid', value: props.company.uuid }],
                typeFilter: "undefined"
                // importModalError: false,
                // importModalShow: false,
                // poweryardFilter: parse.poweryardFilter,
                // geoFenceFilter: parse.geoFenceFilter,
                // domicileFilter: parse.domicileFilter,
                // enabledFilter: parse.enabledFilter,
            }
        }
    }
    componentWillUnmount() {
        localStorage.setItem("ezcheckin-admin-freefields", JSON.stringify({

            enabledFilter: this.state.enabledFilter,
            typeFilter: this.state.typeFilter,
            previousSearchRequest: this.state.previousSearchRequest,
            filtered: this.state.filtered
            // importModalError: false,
            // importModalShow: false,
        }));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.company.uuid !== this.props.company.uuid) {
            let prevState = { ...this.state.previousSearchRequest };
            let prevFilter = [...this.state.filtered];
            prevState.filtered[this.findIndexOfOrganizationUuid(prevState.filtered)].value = this.props.company.uuid;
            prevFilter[this.findIndexOfOrganizationUuid(prevFilter)].value = this.props.company.uuid;
            this.setState({ previousSearchRequest: prevState, filtered: prevFilter });
            this.tableRef.current.fetchData();
        }
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    findIndexOfOrganizationUuid(arr) {
        let index = arr.findIndex(it => it.id == "OrganizationUuid");
        if (index > -1) {
            return index;
        }
    }
    pageSearch(searchRequest, successFn, failureFn) {
        // if (this.state.showOnlyIncomplete) {
        //     if (searchRequest.filtered.filter(f => f.id === 'showOnlyIncomplete').length === 0) {
        //         searchRequest.filtered = searchRequest.filtered.concat({id: 'showOnlyIncomplete', value: true});
        //     }
        // } else {
        //     searchRequest.filtered = searchRequest.filtered ? searchRequest.filtered.filter(f => f.id !== 'showOnlyIncomplete') : [];
        // }

        // this.setState({previousSearchRequest: searchRequest});

        GetFreeFieldsByOrganizationUuid(searchRequest,
            (response) => {
                successFn && successFn(response);
            },
            (response) => {
                failureFn && failureFn(response);
            })
    }


    renderModal() {
        return (

            <FreeFieldForm
                showModal={this.state.showModal}
                loaded={this.state.loaded}
                user={this.props.user}
                editingFField={this.state.editingFField}
                cancel={() =>
                    this.setState({
                        editingFField: {
                            name: "",
                            fieldType: "",
                            isEnable: true,
                            isNew: true
                        },
                        showModal: false
                    })
                }
                save={(c, setErrors) => {
                    this.setState({ loaded: true });
                    this.saveFreeField(c, () => {
                        this.setState({ loaded: false });
                        this.setState({
                            editingFField: {
                                name: "",
                                fieldType: "",
                                isEnable: true,
                                isNew: true
                            },
                            showModal: false
                        },)
                    }, (error) => {
                        setErrors(error.formError);
                    });

                }}
                allowQuickAdd={false}
            />

        );
    }


    saveFreeField(ffield, onSuccess, onError) {
        ffield.organizationUUid = this.props.company.uuid;
        if (ffield.fieldID == undefined || ffield.fieldID == null) {
            postFreeField(ffield
                , (c) => {
                    if (c.statusCode === "200") {
                        this.tableRef.current.fetchData();
                        this.props.onChange(this.state);
                        onSuccess();
                        this.setState({
                            editingFField: {
                                name: "",
                                fieldType: "",
                                isEnable: true,
                                isNew: true
                            },
                            showModal: false
                        })
                        showInfo('Field saved successfully.')
                    } else if (c.statusCode === "410") {
                        showError('Duplicate Name.')
                    } else {
                        showError(c.data.status)
                    }
                    this.setState({ loaded: false });
                }, (e) => { onError(e) })
        } else {
            postUpdateFreeField(ffield
                , (c) => {
                    if (c.statusCode === "200") {

                        this.tableRef.current.fetchData();
                        this.props.onChange(this.state);
                        onSuccess();
                        this.setState({
                            editingFField: {
                                name: "",
                                fieldType: "",
                                isEnable: true,
                                isNew: true
                            },
                            showModal: false
                        })
                        showInfo('Field saved successfully.')
                    } else {
                        showError(c.data.status)
                    }
                    this.setState({ loaded: false });
                    // this.tableRef.current.fetchData();
                }, (e) => { onError(e) })
        }


    }



    startNewFField() {
        this.setState({
            editingFField: {
                name: "",
                fieldType: "",
                locality: "",
                isEnable: true,
                isNew: true,
            },
            showModal: true
        });
    }


    exportTable() {
        if (this.state.previousSearchRequest) {
            requestLocationsToExcel(this.state.previousSearchRequest, "location-export.xls", (response) => {
                if (response.emailSent) {
                    showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
                }
            }, (e) => {
                showError("We are not able to generate the excel file at this time.  Please try again later.");
            });
        }
    }

    render() {
        return (
            <div>
                {/* <Modal isOpen={this.state.showImportInstructions}>
                    <ModalHeader>
                        Import Instructions
                    </ModalHeader>
                    <ModalBody>
                        EZCheck-In allows for importing certain data via a file in CSV format or XLS format.
                        To get started with importing locations click the
                        Download Import Template to obtain a sample CSV file.
                        Add one row per location that you wish to import.<br/>
                        <br/>
                        The following are the list of columns that should be included.
                        Any fields with an asterisk are required and must be non blank.
                        Any fields that contain a comma must be enclosed in quotes.<br/>
                        <br/>
                        Name*<br/>
                        Address 1*<br/>
                        Address 2<br/>
                        City*<br/>
                        State/County/Province*<br/>
                        Country*<br/>
                        Postal*<br/>
                        Email Address<br/>
                        Contact First Name<br/>
                        Contact Last Name<br/>
                        Phone Country Code<br/>
                        Phone Number<br/>
                        Mobile Country Code<br/>
                        Mobile Number<br/>
                        Latitude<br/>
                        Longitude<br/>
                        Location Code<br/>
                        Nickname<br/>
                        EZCheck-In Location (T or F)*<br/>
                        GeoFence Radius (Miles or Km based on company settings)<br/>
                        Enable EZTrack Geofence (T/F) [if not provided then "False"]<br/>
                        EZTrack Geofence Type ("Specify radius" or "Draw on map").<br/>
                        EZTrack Geofence Shape (GeoJson Object)<br/>
                        Warning Tractor Dwell (min)<br/>
                        Critical Tractor Dwell (min)<br/>
                        Warning Trailer Dwell (min)<br/>
                        Critical Trailer Dwell (min)<br/>
                        Warning Container Dwell (min)<br/>
                        Critical Container Dwell (min)<br/>
                        <div style={{marginTop: '10px'}} className={'text-right'}>
                            <Button color="primary" onClick={() => this.setState({
                                showImportInstructions: false
                            })}>Close</Button>
                        </div>
                    </ModalBody>

                </Modal>
                <FileDropModal
                    open={this.state.importModalShow}
                    error={this.state.importModalError}
                    header={"Import Locations"}
                    onDrop={acceptedFiles =>{
                        for (const file of acceptedFiles) {
                            if(file['type'] && !file['type'].includes('text')){
                                //Or is it excel?
                                if(file['type'] && !file['type'].includes('excel')){
                                    this.setState({importModalError: "File must be text"});
                                    return;
                                }
                            }
                            importLocationFile(file, (response)=>{
                                this.setState({importModalShow: false});
                                showInfo(response.message + " in "+ response.seconds + " seconds.");
                                this.tableRef.current.fetchData();
                            },(response)=>{
                                this.setState({importModalError: response.message});
                            }, ()=>{
                                this.props.history.push("/");
                            })
                        }
                    }}
                    onClose={()=>{
                        this.setState({
                            importModalError: false,
                            importModalShow: false
                        });

                    }}
                /> */}
                <Loader show={this.state.loaded} message={this.spinner}>
                    <PaginationFilterTableArrival
                        setloaded={(check) => {
                            this.setState({ loaded: check });
                            this.updateSpinner("");
                        }}

                        onFilterHide={() => {
                            this.setState({

                                enabledFilter: "undefined",
                                typeFilter: "undefined"
                            });
                        }}
                        ref={this.tableRef}
                        hideNotificationButton={true}
                        openFiltersCollaps={true}
                        pageType="Admin"
                        get={GetFreeFieldsByOrganizationUuid}
                        filtered={this.state.previousSearchRequest.filtered}
                        baseFilter={this.state.filtered}
                        onFilteredChange={(filter) => {
                            let psr = { ...this.state.previousSearchRequest };
                            psr.filtered = { ...this.state.filtered }
                            psr.filtered = filter;

                            this.setState({ previousSearchRequest: psr }, () => { this.tableRef.current.requestPageSearch() });
                        }}
                        clearFilter={(resetAll) => {
                            if (resetAll) {
                                let psr = { ...this.state.previousSearchRequest };
                                psr.filtered = [];

                                this.setState({
                                    previousSearchRequest: psr,
                                    taskStatusFilter: undefined
                                }, () => {
                                    // this.tableRef.current.restoreColumns(true);
                                });
                            } else {
                                let psr = { ...this.state.previousSearchRequest };
                                psr.filtered = psr.filtered.filter((f) => f.id === 'start' || f.id === 'end' || f.id === 'dateFilterField');

                                this.setState({ previousSearchRequest: psr }, () => this.tableRef.current.requestPageSearch());
                            }
                        }}
                        stateStorage={"ezcheckin-admin-freefields"}
                        renderModal={this.renderModal()}
                        columns={this.tableColumns}
                        pageCount={this.state.pages}
                        // additionalFilter={this.showOnlyIncompleteFilter()}
                        // footerButton={<Button color="secondary" onClick={() => this.exportTable()}><i className="fa fa-file-excel"/> Export to Excel</Button>}
                        onChange={(state) => {
                            this.setState({
                                itemsPerPage: state.itemsPerPage,
                                previousSearchRequest: {
                                    page: state.page,
                                    itemsPerPage: state.itemsPerPage,
                                    page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered
                                }
                            });
                        }}>
                        <Button color="primary" onClick={() => this.startNewFField()}> <i className="fa fa-plus" /> Add New Custom Field </Button>
                        {/* <Button style={{marginLeft: '10px'}} color="secondary" className={"btn-sm"} onClick={()=>{
                        this.setState({importModalShow: true});
                    }}>
                        <i className="fa fa-file-import"/> Import
                    </Button> */}
                        {/* <a style={{marginLeft: '10px'}} href={"/assets/files/location-import-format.csv"}>Download Import Template</a> */}
                        {/* <a style={{marginLeft: '10px'}} href={"#"} onClick={(e)=>{
                        e.preventDefault();
                        this.setState({showImportInstructions: true})
                    }}>Import Instructions</a> */}
                    </PaginationFilterTableArrival>
                </Loader>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        currentLocation: state.login.location,
        user: state.login.user
    }
}


function mapDispatchToProps(dispatch) {
    return ({
        setCurrentLocation: function (location) {
            dispatch(storeCurrentLocation(location));
        },
        setCurrentUser: function (location) {
            dispatch(storeCurrentUser(location));
        }
    });
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FreeFields));