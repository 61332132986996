import React, {Component} from "react";
import {Button} from "reactstrap";
import {withRouter} from "react-router";

class DispatchHeader extends Component {
    render() {
        return (
            <div>
                <h2 className="dispatch-title" style={{float: 'left', marginLeft: '10px'}}>{this.props.title}</h2>
                <div style={{float: 'right'}}>
                    <Button onClick={() => this.props.onCancel && this.props.onCancel()} style={{marginLeft: "10px"}}>
                        <i className='fas fa-times-circle' title='Cancel without saving'/>
                    </Button>
                    <Button color="primary" className="demo-save-button" style={{marginLeft: "10px"}}
                    onClick={() => this.props.onSave(this.props.dispatch)}>
                        <i className="fa fa-save"/> Save Dispatch
                    </Button>
                    {this.props.match.params.uuid && this.props.dispatch.canStart &&
                    <Button className="demo-save-button" disabled={this.props.dispatch.started} onClick={()=> this.props.onStart(this.props.match.params.uuid)}>
                        Start Dispatch
                    </Button>}
                    {this.props.match.params.uuid &&
                    <Button className="demo-save-button" style={{marginLeft: "10px"}} onClick={()=> this.props.onArchive(this.props.match.params.uuid)}>
                        <i className="fas fa-archive"/> {!this.props.dispatch.archived ?"Cancel" : "Restart"}
                    </Button>}
                </div>
            </div>
        )
    }
}
export default withRouter(DispatchHeader);