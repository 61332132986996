import React, {Component} from 'react';
import {
    Col,
    FormFeedback,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    Tooltip
} from "reactstrap";
import CreatableSelect from 'react-select/lib/Creatable';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import * as uuid from "uuid";

//import Creatable from "react-select";

class FormSelectCreatableField extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            tooltipOpen: false,
            id: "ID_"+uuid.v4()
        };
    }

    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    setValue(props) {
        if (props.dispatch) {
            if (props.value !== null)
                return props.value;
            else
                return "";
        } else {
            return props.values[props.fieldname] ? {
                label: props.values[props.fieldname],
                value: props.values[props.fieldname]
            } : {label: "", value: ""}
        }
    }

    renderSelect() {

        if (this.props.options ) {
            return (
                <CreatableSelect
                    name={this.props.fieldname}
                    placeholder={this.props.placeholder}
                    valid={!this.props.errors[this.props.fieldname]}
                    invalid={this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]}
                    onChange={(v) => {
                        if(!this.props.disableFormik) {
                            if (!this.props.formikOverride) {
                                this.props.setFieldValue(this.props.fieldname, v ? v.value : "");
                                this.props.setFieldTouched(this.props.fieldname, true);
                            } else {
                                this.props.setFieldValue(this.props.formikOvveride, v ? v.value : "");
                                this.props.setFieldTouched(this.props.formikOvveride, true);
                            }
                        }
                        v[this.props.fieldname] = v.label;
                        this.props.onChange && this.props.onChange(v);
                    }}
                    onBlur={() => this.props.setFieldTouched(this.props.fieldname, true)}
                    value={this.props.values[this.props.fieldname] ? {
                        label: this.props.values[this.props.fieldname],
                        value: this.props.values[this.props.fieldname]
                    } : {label: "", value: ""}}
                    className={"basic-single select-in-form" + ((this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? " is-invalid" : "")}
                    classNamePrefix="select"
                    options={this.props.options.map((v) => {
                        return {value: v, label: v}
                    })}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}/>)
        } else {

            return (
                <AsyncCreatableSelect
                    name={this.props.fieldname}
                    defaultOptions={this.props.defaultOptions}
                    placeholder={this.props.placeholder}
                    valid={this.props.errors && !this.props.errors[this.props.fieldname]}
                    invalid={this.props.touched && this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]}
                    onBlur={() => this.props.setFieldTouched(this.props.fieldname, true)}
                    onChange={(v) => {
                        if ( this.props.onChange ) {
                            if (v && v.__isNew__) {
                                v.assetId = v.value;
                            }

                            this.props.onChange && this.props.onChange(v);
                        }

                        if(!this.props.disableFormik){
                            if(!this.props.formikOverride){
                                this.props.setFieldValue(this.props.fieldname, v ? v.value : "");
                                // this.props.setFieldTouched(this.props.fieldname, true);
                            }else{
                                this.props.setFieldValue(this.props.formikOvveride, v ? v.value : "");
                                this.props.setFieldTouched(this.props.formikOvveride, true);
                            }
                        }
                    }}
                    value={this.props.values && this.props.values[this.props.fieldname] ? {
                        label: this.props.values[this.props.fieldname],
                        value: this.props.values[this.props.fieldname]
                    } : this.props.hideEmpty ? null : {label: "", value: ""}}
                    className={"basic-single select-in-form" + ((this.props.touched && this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? " is-invalid" : "")}
                    classNamePrefix="select"
                    loadOptions={this.props.loadOptions}
                    isClearable={true}
                    isDisabled={this.props.isDisabled}/>)
        }
    }


    render() {
        return (

            <FormGroup>
                <Row>
                    {!this.props.noLabel &&
                    <Col lg="2" md="3" sm="4">
                        <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''} {this.props.subLabel ?
                            <small><br/>{this.props.subLabel}</small> : ""}</Label>
                        {this.props.helpMessage &&
                        <div>
                            <i style={{
                                position: "absolute",
                                right: 0,
                                top: "5px",
                                color: "grey"
                            }}
                               className={"fa fa-question-circle"}
                               id={this.state.id}
                            />
                            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                                     toggle={() => {
                                         this.setState({
                                             tooltipOpen: !this.state.tooltipOpen
                                         })
                                     }}>
                                {this.props.helpMessage}
                            </Tooltip>
                        </div>
                        }
                    </Col>
                    }
                    <Col lg="10" md="9" sm="8">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>{this.icon()}</InputGroupText>
                            </InputGroupAddon>
                            {this.renderSelect()}
                            <FormFeedback>{this.props.errors && this.props.errors[this.props.fieldname]}</FormFeedback>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
        )
    }
}


export default FormSelectCreatableField;