import React, { Component } from 'react';
import { Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Button, Label, Row } from "reactstrap";
import Switch from "react-switch";
import HelpIcon from "../../common/HelpIcon";

class FFFormCheckboxField extends Component {
    icon() {
        if (this.props.icon) {
            return <i className={"fa " + this.props.icon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }
    btnIcon() {
        if (this.props.btnIcon) {
            return <i className={"fa " + this.props.btnIcon}></i>
        } else if (this.props.iconText) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    getClassName() {
        let ret = "radio-group";
        if (!!this.props.error && this.props.touched) {
            ret += " is-invalid";
        }
        return ret;
    }


    render() {
        if (this.props.button) {
            return (
                <FormGroup>
                    <Row id={this.props.labelId}>
                        <Col lg="2" md="3" sm="4" className={this.props.labelClassName} style={this.props.labelStyle}>
                            <Label>{this.props.label} <span className={'text-danger'}>{this.props.label && this.props.required ? " *" : ""}</span></Label>
                        </Col>
                        <Col lg="4" md="4" sm="4">
                            <InputGroup className={this.getClassName()}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                <div className="radio-list" style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                                    <Label style={{ marginBottom: "0px" }}>
                                        <Switch type="checkbox"
                                            height={20}
                                            width={40}
                                            className="checkbox-in-form"
                                            name={this.props.fieldName}
                                            checked={this.props.value}
                                            disabled={this.props.disabled}
                                            onChange={this.props.onChange}
                                        />
                                        {this.props.additionalText ?
                                            <span className="text-muted" style={{position: "absolute",
                                                left: "90px",
                                                fontSize: "11px"}}>{this.props.additionalText}</span>
                                            : ""
                                        }
                                        
                                    </Label>
                                </div>

                                <FormFeedback>{this.props.error}</FormFeedback>
                            </InputGroup>
                        </Col>
                        <Col lg="6" md="4" sm="4">
                           
                            {this.props.showCommentButton === true &&
                            <>
                            <Button disabled={this.props.btnEmptyState} className={"font-temperature-btn text-uppercase float-right"} color={this.props.btnEmpty === undefined ||  this.props.btnEmpty === null || this.props.btnEmpty === "" ? "secondary":"primary"} onClick={this.props.onClick} >{this.props.btnEmpty === undefined ||  this.props.btnEmpty === null || this.props.btnEmpty === "" ? "Enter Comment": "View Comments"} <i className={"fa " + this.props.btnIcon} /></Button>
                            <HelpIcon className={"float-right"} helpMessage={"Do not leave Datetime, Task Type, Asset ID or Move To field/fields empty."} />
                            </>
                            }
                        </Col>
                    </Row>
                </FormGroup>
            )
        } else {
            return (
                <FormGroup>
                    <Row id={this.props.labelId}>
                        <Col lg="2" md="3" sm="4" className={this.props.labelClassName} style={this.props.labelStyle}>
                            <Label>{this.props.label} <span className={'text-danger'}>{this.props.label && this.props.required ? " *" : ""}</span></Label>
                        </Col>
                        <Col lg="10" md="9" sm="8">
                            <InputGroup className={this.getClassName()}>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                <div className="radio-list" style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                                    <Label style={{ marginBottom: "0px" }}>
                                        <Switch type="checkbox"
                                            height={20}
                                            width={40}
                                            className="checkbox-in-form"
                                            name={this.props.fieldName}
                                            checked={this.props.value}
                                            disabled={this.props.disabled}
                                            onChange={this.props.onChange}
                                        />
                                         &nbsp; &nbsp;{this.props.additionalText ?
                                            <span className="text-muted">{this.props.additionalText}</span>
                                            : ""
                                        }
                                    </Label>
                                </div>

                                <FormFeedback>{this.props.error}</FormFeedback>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>
            )
        }

    }
}


export default FFFormCheckboxField;