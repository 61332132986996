import React, {Component} from 'react';
import {withRouter} from "react-router";
import {showError} from "../../MainApp";
import {downloadEZTrackAssetEvents, downloadEZTrackAssetProfile} from "../../NetworkHelpers";
import EZTrackEventTable from "./EZTrackEventTable";
import MapView from "./MapView";
import EZTrackAssetDetailCard from "./EZTrackAssetDetailCard";
import {GoogleApiWrapper} from "google-maps-react";
import Config from "../../Config";
import Button from "reactstrap/lib/Button";
import {Card, CardBody, CardHeader} from "reactstrap";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import FormGroup from "reactstrap/lib/FormGroup";

class EZTrackAssetProfile extends Component {
    constructor(props) {
        super(props);
        let localStorageState = JSON.parse(localStorage.getItem("eztrack-asset-profile"));
        if (localStorageState !== null) {
            this.state = {
                mapFilter: localStorageState.mapFilter,
                loading: true,
                asset: {},
                mapSize: {height: "500px", width: "500px"},
                mapEventsCount: 100,
                markers: [],
                eventPolylines: [],
                openSections: {
                    "map":true,
                },
            }
        } else {
            this.state = {
                loading: true,
                asset: {},
                mapSize: {height: "500px", width: "500px"},
                mapEventsCount: 100,
                markers: [],
                eventPolylines: [],
                openSections: {
                    "map":true,
                },
                mapFilter: false
            }
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        this.downloadEZTrackAsset();
        window.addEventListener('resize', this.getMapSize.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.getMapSize.bind(this));
        localStorage.setItem("eztrack-asset-profile", JSON.stringify({
            mapFilter: !!this.state.mapFilter,
        }))
    }
    toggleSection(section ) {
        let sections = Object.assign({},this.state.openSections); // copy sections
        if ( sections[section]) {
            sections[section] = false;
        } else {
            sections[section] = true;
        }
        this.setState({openSections: sections});
    }
    classForSectionButton(section) {
        if ( this.state.openSections[section]) {
            return "fa fa-minus";
        } else {
            return "fa fa-plus"
        }
    }
    sectionOpen( section ) {
        return this.state.openSections[section];
    }
    calculateDwellExcusrion(res){
        // if(res.length > 0){
        //     // sorting

        //     // logic
        //     let index = res.findIndex(it=>it.);
        // }
    }
    downloadEZTrackAsset() {
        downloadEZTrackAssetProfile(this.props.match.params.uuid, (assetProfile) => {
            this.setState({loading: false, asset: assetProfile});
            this.getMapSize();
        }, () => {
            showError("Unable to load EZTrack Asset.");
        })
    }
    tableReloaded(success) {
        this.setState({mapEvents: success.content, eventPolylines: [
                this.generateEventPolyline(success.content)],
            markers: this.generateMarkers(success.content, success.pageable.offset),
            totalEventCount: success.totalElements,
            adjustMap: true,
        });
    }
    generateMarkers(events, indexOffset) {
        if (events == null || events.size === 0) {
            return [];
        }
        let markers = [];
        events.forEach((event, idx) => {
            if (event.primaryLocation != null && event.primaryLocation.latitude != null &&
                event.primaryLocation.longitude != null) {
                markers.push({position:
                        {lat: event.primaryLocation.latitude,
                            lng: event.primaryLocation.longitude},
                    label: (idx + 1 + indexOffset).toString(),
                    zIndex: 100-idx,
                    supportMouseOver: true,
                    supportMouseOut: true,
                    event: event
                });
            }
        });
        return markers;
    }
    generateEventPolyline(ezTrackEvents) {
        if (ezTrackEvents == null || ezTrackEvents.size === 0) {
            return [];
        }
        let path = [];
        let arrow = this.props.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW;
        let circle = this.props.google.maps.SymbolPath.CIRCLE;
        let lineSymbol = {path: arrow};
        let circleSymbol = {path: circle};
        ezTrackEvents.forEach(event => {
            if (event.primaryLocation != null && event.primaryLocation.latitude != null &&
                event.primaryLocation.longitude != null) {
                path.push({lat: event.primaryLocation.latitude, lng: event.primaryLocation.longitude});
            }
        });
        return {path: path,
            geodesic: false,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            icons: [{
                icon: circleSymbol,
                offset: '100%',
            },{
                icon: lineSymbol,
                offset: '100px',
                repeat: '100px'
            }],
        }
    }
    pad( s) {
        if ( !s ) return "00";
        if ( s < 10 ) return "0" + s;
        return s;
    }
    getMapSize() {
        let w = window.innerWidth;
        let s = document.getElementById("app-sidebar");
        if (s === null) {
            this.setState({mapSize: {height: "500px", width: "500px"}});
            return {height: "500px", width: "500px"}
        }
        w = w - s.offsetWidth - 55;
        let d = document.getElementById("eztrack-asset-profile");
        if (d === null) {
            this.setState({mapSize: {height: "500px", width: "500px"}});
            return {height: "500px", width: "500px"}
        }
        let h = d.offsetHeight - 25;
        for (let i=0; i < d.children.length ; i++) {
            if (d.children[i].id !== 'eztrack-asset-map') {
                h = h - d.children[i].offsetHeight;
            }
        }
        if (h < 500) {
            h = 500;
        }
        this.setState({mapSize: {height: h, width: w}});
        return {height: h, width: w};
    }
    toggleView() {
        this.setState({mapFilter: !this.state.mapFilter});
    }
    render() {
        if (this.state.loading) {
            return <div>Loading...</div>
        }
        return (
            <>
                <div className="text-left p3" id="eztrack-asset-profile">
                <EZTrackAssetDetailCard
                    asset={this.state.asset}
                    location={this.props.location}
                    />
                <Card>
                    <CardHeader><Button onClick={()=>this.toggleSection("map")}>
                        <i className={this.classForSectionButton("map")}/></Button> Map</CardHeader>
                    {this.sectionOpen("map") && <CardBody>
                        <FormGroup check>
                            <Label check>
                                <Input type='checkbox' onChange={() => this.toggleView()}
                                       checked={this.state.mapFilter}/>
                                &nbsp;Hide Way-Points and Display Route as Connected Line</Label>
                        </FormGroup>
                <div>
                        <div style={{
                        height: "500px"
                    }}>
                        <MapView id='eztrack-asset-map'
                            lat={this.state.lat}
                            lng={this.state.lng}
                            zoom={this.state.zoom}
                            getMapSize={this.getMapSize.bind(this)}
                            mapHeight={this.state.mapSize.height}
                            mapWidth={this.state.mapSize.width}
                            markers={this.state.mapFilter ? [] : this.state.markers}
                            boundsMarkers={this.state.markers}
                            polylines={this.state.mapFilter ? this.state.eventPolylines : []}
                            showAssetPopup={false}
                            showShipmentEventPopup={true}
                            showEZTrackEventPopup={true}
                            adjustMap={this.state.adjustMap}
                        />
                        </div>
                </div>
                    </CardBody>}
                        </Card>
                <div>
                    <EZTrackEventTable
                        get={downloadEZTrackAssetEvents}
                        assetUuid={this.state.asset.uuid}
                        distanceUnitsOfMeasure={this.state.asset.company.distanceUnitsOfMeasure}
                        assetDomicile={this.state.asset.domicile}
                        successCallback={(success) => this.tableReloaded(success)}
                    />
                </div>
                </div>
            </>
        )
    }
}
export default withRouter(GoogleApiWrapper({
    apiKey: (Config.googleMapsApiKey),
    libraries: ['drawing'],
})(EZTrackAssetProfile));
