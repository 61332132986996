import * as React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  downloadNoticeForTable,
  importSpotFile,
  requestNoticeToExcel,
  saveNotice,

} from "../../NetworkHelpers";
import { showError, showInfo } from "../../MainApp";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import NoticeEdit from "./NoticeEdit";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { FileDropModal } from "../../common/FileDropModal";
import { confirmAlert } from "react-confirm-alert";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
class Notice extends React.Component {
  tableColumns = [
    {
      Header: "Created By User",
      accessor: "createdBy",
      sortable: false,
      filterable: false,
      Cell: (row) => {
        let task = row.original.createdBy;
        if (task) {
          return (
            <span>{task.firstname}{" "}{task.lastname}</span>

          );
        } else {
          return null;
        }
      },

    },
    {
      accessor: "created",
      Header: "Created Datetime",
      default: true,
      filterable: false,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.created) {
          return (
            <MomentDisplay
              date={task.created}

            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "removed",
      Header: "Removed Datetime",
      default: true,
      filterable: false,
      sortable: false,
      dataType: "date",
      operator: "GT",
      Cell: (row) => {
        let task = row.original;
        if (task.removed) {
          return (
            <MomentDisplay
              date={task.removed}

            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "startDate",
      Header: "Start Datetime",
      default: true,
      filterable: false,
      sortable: false,
      dataType: "date",
      operator: "GT",
      Cell: (row) => {
        let task = row.original;
        if (task.startDate) {
          return (
            <MomentDisplay
              date={task.startDate}

            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "endDate",
      Header: "End Datetime",
      default: true,
      filterable: false,
      sortable: false,
      dataType: "date",
      operator: "GT",
      Cell: (row) => {
        let task = row.original;
        if (task.endDate) {
          return (
            <MomentDisplay
              date={task.endDate}

            />
          );
        } else {
          return null;
        }
      },
    },


    {
      Header: "Notice",
      accessor: "notification",
      sortable: false,
      filterable: false,
      Cell: (row) => {
        let task = row.original;
        if (task.notification) {
          return (
            <div dangerouslySetInnerHTML={{ __html: task.notification }} />

          );
        } else {
          return null;
        }
      },

    },
    {
      accessor: "current",
      Header: "Active",
      default: false,
      filterable: false,
      sortable: true,
      Cell: (row) => {
        return row.original.current ? "Yes" : "No";
      },

    },



  ];
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.enabledFilterRef = React.createRef();
    this.typeFilterRef = React.createRef();
    let localStorageState = JSON.parse(
      localStorage.getItem("poweryard-notice-settings")
    );
    if (localStorageState != null) {
      this.state = {
        enabledFilter: localStorageState.enabledFilter,
        noticeUnderEdit: localStorageState.noticeUnderEdit,
      };
    } else {
      this.state = {
        noticeUnderEdit: {},
      };
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company.uuid !== this.props.company.uuid) {
      this.tableRef.current.fetchData();
    }
  }
  componentWillUnmount() {
    localStorage.setItem(
      "poweryard-notice-settings",
      JSON.stringify({
        enabledFilter: this.state.enabledFilter,
        noticeUnderEdit: this.state.noticeUnderEdit,
      })
    );
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  editNewNotice() {
    this.setState({
      showModal: true,
      noticeUnderEdit: {
        notification: "",
        enabled: true,
      },
    });
  }
  renderModal() {
    return (
      <NoticeEdit
        noticeUnderEdit={this.state.noticeUnderEdit}
        defaultLocation={this.props.user.location}
        loaded={this.state.loaded}
        cancel={() => this.cancelDialog()}
        save={(a, onSuccess, onError) => {
          this.saveNotice(a, onSuccess, onError);
        }}
        onChange={(state) => {
          this.setState({ noticeUnderEdit: state });
        }}
        showModal={this.state.showModal}
      />
    );
  }
  cancelDialog() {
    this.setState({ showModal: false, noticeUnderEdit: {} });
  }
  editSpot(taskType) {
    this.setState({ showModal: true, noticeUnderEdit: taskType });
  }
  saveNotice(asset, onSuccess, onError) {
    this.setState({ loaded: true });
    console.log(`this runs on save notice`, asset, onSuccess, onError);
    saveNotice(
      asset,
      (response) => {
        this.setState({ loaded: false });
        if (response.code === "1000") {
          console.log(`this runs on response failure`, response.message);
          showError(response.message);
        } else {
          showInfo("Notice Saved");
          console.log(`notice saved`);
          this.tableRef.current.fetchData();
          this.props.onChange(this.state);
          onSuccess();
          this.setState({ showModal: false, noticeUnderEdit: {} });
        }
      },
      (failure) => {
        console.log(`this runs on failue`, failure);
        showError(failure.message);
        onError();
      }
    );
  }
  clearFilters() {
    this.enabledFilterRef.current.value = undefined;
    this.typeFilterRef.current = undefined;
    this.setState({ enabledFilter: undefined, typeFilterRef: undefined });
  }
  exportSpots() {
    if (this.state.previousSearchRequest) {
      requestNoticeToExcel(
        this.state.previousSearchRequest,
        "notification-export.xlsx",
        (response) => {
          if (response.emailSent) {
            showInfo(
              "We are currently generating the Excel file and you will receive it via email shortly."
            );
          }
        },
        (e) => {
          console.log(e);
          showError(
            "We are not able to generate the excel file at this time.  Please try again later."
          );
        }
      );
    }
  }
  render() {
    return (
      <div>
        <div className="column-wrapper text-left">
          <Modal isOpen={this.state.showImportInstructions}>
            <ModalHeader>Import Instructions</ModalHeader>
            <ModalBody>
              EZCheck-In allows for importing certain data via a file in CSV
              format or XLSX format. To get started with importing spots, click
              the Download Import Template to obtain a sample CSV file. Add one
              row per spot that you wish to import.
              <br />
              <br />
              The following columns must be included. Any fields with an
              asterisk are required and must be non blank. Any fields that
              contain a comma must be enclosed in quotes.
              <br />
              <br />
              Location* (case insensitive name, must already exist)
              <br />
              Yard Zone / Building*
              <br />
              Spot Type* (Name of your Spot Type)
              <br />
              Name
              <br />
              Enabled* (True or False)
              <br />
              <br />
              <div style={{ marginTop: "10px" }} className={"text-right"}>
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({
                      showImportInstructions: false,
                    })
                  }
                >
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <FileDropModal
            open={this.state.importModalShow}
            error={this.state.importModalError}
            header={"Import Spots"}
            onDrop={(acceptedFiles) => {
              for (const file of acceptedFiles) {
                if (file["type"] && !file["type"].includes("text")) {
                  //Or is it excel?
                  if (
                    file["type"] &&
                    !(
                      file["type"].includes("excel") ||
                      file["type"].includes("openxml")
                    )
                  ) {
                    this.setState({ importModalError: "File must be text" });
                    return;
                  }
                }
                importSpotFile(
                  file,
                  (res) => {
                    let response = res.content;
                    this.setState({ importModalShow: false });
                    showInfo(
                      response.message + " in " + response.seconds + " seconds."
                    );
                    this.tableRef.current.fetchData();
                  },
                  (response) => {
                    this.setState({ importModalError: response.message });
                  },
                  () => {
                    this.props.history.push("/");
                  }
                );
              }
            }}
            onClose={() => {
              this.setState({
                importModalError: false,
                importModalShow: false,
              });
            }}
          />
          <Loader show={this.state.loaded} message={this.spinner}>
            <PaginationFilterTable
              setloaded={(check) => {
                this.setState({ loaded: check });
                this.updateSpinner("");
              }}
              onFilterHide={() => {
                this.setState({
                  enabledFilter: "undefined",
                });
              }}
              hideFilter={true}
              openFiltersCollaps={true}
              ref={this.tableRef}
              get={downloadNoticeForTable}
              pageType="poweryard-settings"
              stateStorage="poweryard-notice-settings"
              renderModal={this.renderModal()}
              columns={this.tableColumns}
              successCallback={(response) => {
                let filters = this.tableRef.current.state.filtered;
                let sort = this.tableRef.current.state.sorted;
                this.setState({
                  hasResults: response.totalElements > 0,
                  previousSearchRequest: { filtered: filters, sorted: sort },
                });
              }}
              footerButton={
                <span title={"Select to extract your company spot data."}>
                  <Button
                    disabled={!this.state.hasResults}
                    color="secondary"
                    onClick={() => this.exportSpots()}
                  >
                    <i className="fa fa-file-excel" /> Export to Excel
                  </Button>
                </span>
              }
            >
              <Button color="primary" onClick={() => this.editNewNotice()}>
                {" "}
                <i className="fa fa-plus" /> Add New Notice
              </Button>

            </PaginationFilterTable>
          </Loader>
        </div>
      </div>
    );
  }
}
export default Notice;
