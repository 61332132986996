import React, {Component} from "react";
import * as uuid from "uuid";
import {Tooltip} from "reactstrap";
import {DwellAlert} from "./AssetsHistoryComponent";

export class LocationTooltip extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return <span>
             <i id={this.state.id} className="fas fa-marker-alt" aria-hidden="true"
               style={{marginLeft: "10px", color: "black"}}/>
            <Tooltip placement="right" isOpen={this.state.tooltipOpen} target={this.state.id}
                     toggle={() => {
                         this.setState({
                             tooltipOpen: !this.state.tooltipOpen
                         })
                     }}>
               Location: {this.props.dwellLocation}<br/>
            </Tooltip>
            <DwellAlert {...this.props}/>
        </span>
    }

}