import * as PropTypes from "prop-types";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import React, { useEffect } from "react";

const evtNames = [
  'click',
  'dblclick',
  'dragend',
  'mousedown',
  'mouseout',
  'mouseover',
  'mouseup',
  'recenter',
]

const MarkerCluster = (props) => {
  const { map, google, markers, mouseout, mouseover, markerstyle } = props

  const handleEvent = ({ event, marker, entry }) => {
    if (props[event]) {
      props[event]({
        props: props,
        marker: marker,
        event: event,
        entry: entry
      })
    }
  }
  useEffect(() => {
    if (map && markers) {
      const mapMarkers = markers.map((marker) => {
        const entry = new google.maps.Marker({
          position: {
            lat: parseFloat(marker.position.lat),
            lng: parseFloat(marker.position.lng)
          },
          map: map,
          title: marker.title ? marker.title : null,
          label: marker.label ? marker.label : null,
          icon: marker.icon ? marker.icon : null,
          shape: marker.shape ? marker.shape : null,
          // onClick: marker.supportClick ? this.onMarkerClick.bind(this) : null,
          mouseover: marker.supportMouseOver ? mouseover : null,
          // onMouseout: marker.supportMouseOut ? this.onMouseOut.bind(this) : null,
          asset: marker.asset,
          event: marker.event,
          zIndex: marker.zIndex,
          optimized: false,
          name: marker.name
        })

        evtNames.forEach(e => {
          entry.addListener(e, () => handleEvent({
            event: e,
            marker: marker,
            entry: entry
          }))
        })

        return entry
      })

      let options = { imagePath: '/assets/img/icons/m', averageCenter: true, maxZoom: 20, gridSize: 40 };
      if (markerstyle === 'circle') {
        options = {
          averageCenter: true, maxZoom: 20, clusterClass: "custom-clustericon",
          imagePath: '/assets/img/icons/m',
          gridSize: 40,
          styles: [{ width: 30, height: 30, className: "custom-clustericon-1" },
          { width: 40, height: 40, className: "custom-clustericon-2" },
          { width: 50, height: 50, className: "custom-clustericon-3" }]
        }
      }
      const clusterer = new MarkerClusterer(map, mapMarkers, options)

      return () => {
        clusterer.clearMarkers()
      }
    }
  }, [map, google, markers])
  return null;
}
MarkerCluster.propTypes = {
  map: PropTypes.object,
  google: PropTypes.object,
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    name: PropTypes.string.isRequired,
  })),
  markerstyle: PropTypes.string,
}

export default MarkerCluster;