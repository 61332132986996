import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Form, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import FFFormTextField from "../../common/formik-free/FFFormTextField";
import FFFormCheckboxField from "../../common/formik-free/FFFormCheckboxField";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { downloadCancelReasonCodes, postCancelReasonCode } from "../../NetworkHelpers";
import { showError } from "../../MainApp";
import { Formik } from "formik";
import { getErrorsFromValidationError } from "../../common/utilities";
import * as Yup from "yup";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
const validationSchema = function (values) {
    return Yup.object().shape({
        name: Yup.string().required('Name is required.').min(1, 'Can not be empty').max(40, 'Must be less than 40 characters'),
    })
};

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values);
        try {
            validationSchema.validateSync(values, { abortEarly: false });
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
};

class CancelReasonCodeForm extends React.Component {

    render() {
        return <div>
            <Card className="simple-card">

                <CardHeader>Cancel Reason Code</CardHeader>
                <CardBody>
                    <div>
                        <Row>
                            <Col>
                                <FFFormTextField
                                    fieldname="name"
                                    label={"Name"}
                                    placeholder="Enter a name for the Cancel Reason code"
                                    value={this.props.values.name}
                                    error={this.props.errors.name}
                                    touched={this.props.touched.name}
                                    onBlur={() => {
                                        this.props.setFieldTouched("name");
                                    }}
                                    onChange={(v) => {
                                        this.props.setFieldValue("name", v)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FFFormCheckboxField
                                    label="Default"
                                    fieldname="isDefault"
                                    onChange={(v) => {
                                        this.props.setFieldTouched("isDefault");
                                        this.props.setFieldValue("isDefault", v)
                                    }}
                                    value={this.props.values.isDefault}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FFFormCheckboxField
                                    label="Enabled"
                                    fieldname="enabled"
                                    onChange={(v) => {
                                        this.props.setFieldTouched("enabled");
                                        this.props.setFieldValue("enabled", v)
                                    }}
                                    value={this.props.values.enabled}
                                />
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
            <div className="mb-3 text-right" style={{ marginTop: "10px" }}>
                <Button color="danger" onClick={() => this.props.onCancel()}>Cancel</Button> &nbsp;
                <Button color="primary" type="submit">Save</Button>
            </div>
        </div>;
    }
}

class ReasonCodeModal extends React.Component {
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    render() {
        return <>
            <Modal isOpen={this.props.open} size={'lg'}>
                <Loader show={this.props.loaded} message={this.spinner}>
                    <ModalHeader>
                        {this.props.code && this.props.code.uuid ? "Edit Cancel Reason Code" : "New Cancel Reason Code"}
                    </ModalHeader>
                    <ModalBody>
                        <Formik
                            onSubmit={(code) => {
                                this.updateSpinner('Saving ...');
                                this.props.onSubmit(code)
                            }}
                            initialValues={this.props.code}
                            validate={validate(validationSchema.bind(this))}
                        >{
                                ({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                    setFieldTouched
                                }) => (
                                    <Form onSubmit={(v) => {
                                        return handleSubmit(v)
                                    }}>
                                        <CancelReasonCodeForm
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            values={values}
                                            loaded={this.props.loaded}
                                            setFieldValue={setFieldValue}
                                            setFieldTouched={setFieldTouched}
                                            onCancel={this.props.onCancel}
                                        />

                                    </Form>
                                )}
                        </Formik>
                    </ModalBody>
                </Loader>
            </Modal>
        </>
    }
}

export default class extends React.Component {
    tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            sortable: true,
            filterable: true
        },
        {
            Header: 'Cancel Reason ID',
            accessor: 'id',
            sortable: false,
            filterable: false
        },
        {
            Header: 'Default',
            accessor: 'isDefault',
            sortable: true,
            filterable: true,
            Cell: row => {
                return row.original.isDefault ? "Yes" : "No";
            },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.isDefaultFilterRef}
                        onChange={event => {
                            this.setState({ isDefaultFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.isDefaultFilter}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: 'Enabled',
            accessor: 'enabled',
            sortable: true,
            filterable: true,
            Cell: row => {
                return row.original.enabled ? "Yes" : "No";
            },
            Filter: ({ filter, onChange }) =>
                <>
                    <select
                        ref={this.enabledFilterRef}
                        onChange={event => {
                            this.setState({ enabledFilter: event.target.value });
                            onChange(event.target.value)
                        }}
                        style={{ width: "100%" }}
                        value={this.state.enabledFilter}
                    >
                        <option value="undefined" hidden={true}>Select</option>
                        <option value="">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </>
        },
        {
            Header: 'Action',
            sortable: false,
            filterable: false,
            Cell: row => {
                return <>
                    <Button title="Edit Cancel Reason Code"
                        aria-label="Edit Cancel Reason Code"
                        onClick={() => {
                            this.setState({
                                showModal: true,
                                editingCancelReasonCode: {
                                    ...row.original
                                }
                            });
                        }}>
                        <i className="fa fa-edit" />
                    </Button> &nbsp;
                </>
            }
        },

    ];

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.enabledFilterRef = React.createRef();
        this.isDefaultFilterRef = React.createRef();
        const parse = JSON.parse(localStorage.getItem("poweryard-cancel-reason-codes"));
        if (parse) {
            this.state = {
                name: null,
                previousSearchRequest: parse.previousSearchRequest,
                isDefaultFilter: parse.isDefaultFilter,
                enabledFilter: parse.enabledFilter,
            };
        }
        this.state = {
            name: null,
        };
    };


    componentWillUnmount() {
        this.savePageState();
    }

    savePageState() {
        localStorage.setItem("poweryard-cancel-reason-codes", JSON.stringify({
            previousSearchRequest: this.state.previousSearchRequest,
            isDefaultFilter: this.state.isDefaultFilter,
            enabledFilter: this.state.enabledFilter,
        }));
    }
    updateSpinner(val) {
        this.spinner = (
            <span>
                <p className="loadingMessage">{val}</p>
                <LoaderIco
                    loaded={false}
                    lines={13}
                    length={20}
                    width={10}
                    radius={30}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#51b32e"
                    speed={1}
                    trail={60}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </span>
        );
    }
    render() {
        return <>
            <div>
                <ReasonCodeModal
                    open={this.state.showModal}
                    code={this.state.editingCancelReasonCode}
                    loaded={this.state.loaded}
                    onSubmit={(code) => {

                        this.setState({ loaded: true });
                        postCancelReasonCode(code, (response) => {
                            this.setState({ loaded: false });
                            if (response.code === '1000') {
                                showError(response.message);
                            } else {
                                this.setState({ showModal: false });
                                this.tableRef.current.fetchData();
                            }
                        }, (error) => {
                            showError(error.message || "Unable to save Cancel Reason Code");
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            showModal: false,
                            editingCancelReasonCode: {
                                name: "",
                                isDefault: false,
                                enabled: true,
                            }
                        })
                    }}
                />
                <Loader show={this.state.loaded} message={this.spinner}>
                    <PaginationFilterTable
                        setloaded={(check) => {
                            this.setState({ loaded: check });
                            this.updateSpinner("");
                        }}
                        onFilterHide={() => {
                            this.setState({
                                enabledFilter: "undefined",
                                isDefaultFilter: "undefined"
                            });
                        }}
                        openFiltersCollaps={true}
                        ref={this.tableRef}
                        get={downloadCancelReasonCodes}
                        pageType="poweryard-settings"
                        stateStorage="poweryard-cancel-reason-codes"
                        columns={this.tableColumns}
                        tableData={this.state.tableData}
                        onChange={(state) => {
                            this.setState({
                                previousSearchRequest: {
                                    itemsPerPage: state.itemsPerPage,
                                    page: state.page,
                                    pageSize: state.pageSize,
                                    sorted: state.sorted,
                                    filtered: state.filtered
                                }
                            }, () => {
                                this.savePageState();
                            });
                        }}
                        failureCallback={(message) => {
                            showError(message)
                        }}
                    >
                        <Button color="primary" onClick={() => {
                            this.setState({
                                showModal: true,
                                editingCancelReasonCode: {
                                    name: "",
                                    isDefault: false,
                                    enabled: true,
                                }
                            });
                        }}> <i className="fa fa-plus" /> Add New Cancel Reason Code</Button>
                    </PaginationFilterTable>
                </Loader>
            </div>
        </>
    }
}