import {KILOMETERS_PER_MILE} from "../../common/constants";
import * as React from "react";
import Link from "react-router-dom/Link";
import {formatIdleTime, formatMoveFrequency} from "../../common/utilities";

export function DISTANCE_FROM_DOMICILE(distanceUnitsOfMeasure) {
    return {
        Header: 'Distance from Domicile',
            accessor: 'distanceFromDomicileInMeters',
        sortable: true,
        filterable: false,
        default: true,
        Cell: row => {
        if (distanceUnitsOfMeasure === 'Miles') {
            return row.original.distanceFromDomicileInMeters ?
                <span>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                    .format(row.original.distanceFromDomicileInMeters / 1000.0 / KILOMETERS_PER_MILE)} {distanceUnitsOfMeasure}</span> :
                null
        } else {
            let distanceInMeters = row.original.distanceFromDomicileInMeters;
            if (distanceInMeters != null) {
                return <span>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 1})
                    .format(distanceInMeters / 1000.0)} {distanceUnitsOfMeasure}</span>
            } else {
                return null;
            }
        }
    }
    }
}
export function MOVE_FREQUENCY() {
    return {
        Header:
            <span
                title={"Percentage of days with a move in the 30 day period ending yesterday.\n" +
                "Based on the timezone of the asset's domicile."}>
                    Move Frequency</span>,
        accessor: 'movesInLast30Days',
        sortable: true,
        filterable: false,
        default: true,
        Cell: row => {return formatMoveFrequency(row.original.movesInLast30Days)}
    }
}
export function IDLE_TIME() {
    return {
        Header: <span
            title={"Number of days between the event time and when the asset last moved."}>
            Idle Time (Days Since Last Move)</span>,
        accessor: 'idleTime',
        sortable: true,
        filterable: false,
        default: true,
        Cell: row => {return formatIdleTime(row.original.idleTime)}
    }
}
export function EXCURSION_TIME() {
    return {
        Header: <span
            title={"Number of days between the event and when the asset left a domicile geo-fence."}>
            Excursion Time</span>,
        accessor: 'excrusionTime',
        sortable: true,
        filterable: false,
        default: true,
        Cell: row => {return formatIdleTime(row.original.excrusionTime)}
           
        
    }
}
export function DWELL_TIME() {
    return {
        Header: <span
            title={"Number of days the asset has been within the current EZTrack Location Geo-fence."}>
            Dwell Time</span>,
        accessor: 'dwellTime',
        sortable: true,
        filterable: false,
        default: true,
        Cell: row => {return formatIdleTime(row.original.dwellTime)}
            
        
    }
}
export function SHIPMENT() {
    return {
        Header: 'Shipment ID',
        accessor: 'shipmentId',
        sortable: false,
        filterable: true,
        Cell: row => {return row.original.shipments && row.original.shipments[0] ?
            row.original.shipments.map((shipment, index) => {
                if (index + 1 === row.original.shipments.length) {
                    return <Link to={"/shipment-profile/"+shipment.assetId}>{shipment.assetId}</Link>
                } else {
                    return <><Link to={"/shipment-profile/"+shipment.assetId}>{shipment.assetId}</Link>,&nbsp;</>
                }
            })
                : ""}
    }
}
export var ASSET_ID =
    {
        Header: 'Asset ID',
        accessor: 'name',
        sortable: true,
        filterable: true,
        Cell: row => {return row.original.name ?
            <Link to={"asset-profile/"+row.original.uuid}>{row.original.name}</Link>
            : ""}
    }
export var DOMICILE_NAME =
    {
        Header: 'Domicile Name',
        accessor: 'domicile.name',
        filterable: true,
        sortable: true,
    }

