import {
  downloadYardHistory,
  getSecondaryReferenceByRefID,
  requestYardHistoryToExcel,
} from "../../NetworkHelpers";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { getFormattedDwellDateString } from "../../assets/components/StaticDurationDisplay";
import React, { Component } from "react";
import { PaginationFilterTable } from "../../common/PaginationFilterTable";
import { getTemp, isF, getTempSetpoint } from "./Tasks";
import * as PropTypes from "prop-types";
import TableDateRangePickerGO from "../../common/TableDateRangePickerGo";
import {
  endOfDay,
  getLocalStorageName,
  getTableSize,
  startOfDay,
  startOfDayEST,
  endOfDayEST
} from "../../common/utilities";
import { Button, Tooltip } from "reactstrap";
import { showError, showInfo } from "../../MainApp";
import LoaderIco from "react-loader";
import Loader from "react-loader-advanced";
import ReferenceModal from "./ReferenceModal";
class YardCheckHistory extends Component {
  handleClickComments = (rowindex) => {
    if (this.state.istab === true) {
      this.setState({ Showhover: true });
      if (this.state.clickedRowIndex === rowindex) {
        this.setState((prevState) => ({
          showTooltip: !prevState.showTooltip,
          clickedRowIndex: rowindex,
        }));
      } else {
        this.setState({
          showTooltip: true,
          clickedRowIndex: rowindex,
        });
      }
    }

    console.log("tooltip", this.state.showTooltip);
  };
  tableColumns = [
    {
      accessor: "taskLocationName",
      Header: "Location Name",
      filterable: true,
      sortable: true,
      default: false,
      show: false,
    },
    {
      accessor: "taskLocationNickName",
      Header: "Location Nickname",
      filterable: true,
      sortable: true,
      default: false,
      show: false,
    },
    {
      accessor: "taskLocationCode",
      Header: "Location Code",
      filterable: true,
      sortable: true,
      default: false,
      show: false,
    },
    {
      accessor: "yardCheckDate",
      Header: "Yard Check Completed Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",

      Cell: (row) => {
        let task = row.original;

        if (task.yardCheckDate) {
          return (
            <MomentDisplay
              historyPageType={true}
              date={task.yardCheckDate}
              timezone={
                row.original.taskLocationTimezone &&
                row.original.taskLocationTimezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "completedDate",
      Header: "Task Complete Datetime",
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.completedDate) {
          return (
            <MomentDisplay
              date={task.completedDate}
              timezone={
                row.original.taskLocationTimezone &&
                row.original.taskLocationTimezone
              }
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      accessor: "checkerName",
      Header: "Yard Check Completed User",
      default: true,
      filterable: false,
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.checkerName) {
          return task.checkerName;
        } else {
          return null;
        }
      },
    },
    {
      accessor: "movedToSpotType",
      Header: "Spot Type",
      filterable: true,
      sortable: true,
      default: true,
      // Filter: ({ filter, onChange }) => (
      //   <>
      //     <select
      //       ref={this.sealedFilterRef}
      //       onChange={(event) => {
      //         this.setState({ sealedFilter: event.target.value });
      //         onChange(event.target.value);
      //       }}
      //       style={{ width: "100%" }}
      //       value={
      //         this.state.sealedFilter ? this.state.sealedFilter : undefined
      //       }
      //     >
      //       <option value="">Select</option>
      //       <option value="Dock">Dock Spot</option>
      //       <option value="Yard">Yard Spot</option>
      //     </select>
      //   </>
      // ),
    },
    {
      accessor: "moveToZone",
      Header: "Zone Name",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "movedToSpot",
      Header: "Spot #",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "trailerName",
      Header: "Trailer Name",
      filterable: true,
      default: true,
      sortable: true,
    },
    // {
    //   accessor: "secondaryRef",
    //   Header: "Reference Number",
    //   default: true,
    //   filterable: false,
    //   sortable: false,
    //   Cell: (row) => {
    //     if (row.value !== null && row.value !== '' && row.value !== undefined) {
    //       let v = row.original;
    //       return row.value.split(",").map((elem, index) => (
    //         <a href={"javascript:;"} onClick={() => this.openRefModal(v, elem)}>
    //           {" "}
    //           {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //             }`} */}
    //           {`${elem}`}
    //           {row.value.split(",").length - 1 === index ? "" : ","} <br />
    //         </a>
    //       ));
    //     }
    //     else {
    //       return 'N/A'
    //     }
    //   },
    // },
    {
      accessor: "trailerNumber",
      Header: "Trailer Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.trailerNumber ? row.original.trailerNumber.replace(/ /g, '').toUpperCase() : "";
      },
    },
    {
      accessor: "assetType",
      Header: "Trailer Type",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "assetStatus",
      Header: "Trailer Status",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "shipmentNumber",
      Header: "Shipment Number",
      filterable: true,
      sortable: true,
      default: true,
    },

    {
      accessor: "sealed",
      Header: "Sealed",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.sealed ? "Yes" : "No";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.sealedFilterRef}
            onChange={(event) => {
              this.setState({ sealedFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.sealedFilter ? this.state.sealedFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </>
      ),
    },
    {
      accessor: "sealNumber",
      Header: "Seal Number",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return (
          <span title={row.original.sealNumber}>{row.original.sealNumber}</span>
        );
      },
    },
    {
      accessor: "comments",
      Header: "Comments",
      filterable: true,
      sortable: true,
      default: true,
      Cell: (row) => {
        const targetId = `commentsCell_${row.index}`;

        return (
          <span
            id={targetId}
            title={this.state.Showhover ? " " : row.original.comments}
            onClick={() => {
              this.handleClickComments(row.index);
            }}
          >
            {this.state.showTooltip &&
              this.state.clickedRowIndex == row.index && (
                <Tooltip
                  style={{ color: "#fff" }}
                  target={targetId}
                  isOpen={this.state.showTooltip}
                  placement="right"
                >
                  {row.original.comments}
                </Tooltip>
              )}
            {console.log(this.state.Showhover, "titlestate")}
            {row.original.comments}
          </span>
        );
      },
    },
    {
      accessor: "fuelLevel",
      Header: "Fuel Level",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.fuelLevel ? row.original.fuelLevel : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.fuelFilterRef}
            onChange={(event) => {
              this.setState({ fuelFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.fuelFilter ? this.state.fuelFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Empty">Empty</option>
            <option value="1/8">1/8</option>
            <option value="1/4">1/4</option>
            <option value="3/8">3/8</option>
            <option value="1/2">1/2</option>
            <option value="5/8">5/8</option>
            <option value="3/4">3/4</option>
            <option value="7/8">7/8</option>
            <option value="Full">Full</option>
          </select>
        </>
      ),
    },
    {
      accessor: "reefer",
      Header: "Reefer (On/Off )",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.reefer == true ? "Yes" : row.original.reefer == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.reeferFilterRef}
            onChange={(event) => {
              this.setState({ reeferFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.reeferFilter ? this.state.reeferFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "continous",
      Header: "Continuous (Yes/No)",
      default: true,
      filterable: true,
      sortable: true,
      Cell: (row) => {
        return row.original.continous == true ? "Yes" : row.original.continous == false ? "No" : "";
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.continousFilterRef}
            onChange={(event) => {
              this.setState({ continousFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.continousFilter ? this.state.continousFilter : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="YES">Yes</option>
            <option value="NO">No</option>
            <option value="EMPTY">Blank</option>
          </select>
        </>
      ),
    },
    {
      accessor: "temperatureSetPointInc",
      Header: "Temp - Setpoint" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTempSetpoint(row, this.props.user);
      },
    },

    {
      accessor: "temperatureInc",
      Header: "Temp - Actual" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      sortable: true,
      default: true,
      Cell: (row) => {
        return getTemp(row, null, this.props.user);
      },
    },
    {
      accessor: "temperatureDeviationInc",
      Header:
        "Temp - Deviation" + (isF(this.props.user) ? " (℉)" : " (℃)"),
      filterable: false,
      default: true,
      sortable: true,
      Cell: (row) => {
        return getTemp(
          row,
          row.original.temperatureSetPointInc,
          this.props.user
        );
      },
    },
    {
      accessor: "taskType",
      Header: "Task Type",
      filterable: true,
      sortable: true,
      default: true,
    },
    {
      accessor: "taskStatus",
      Header: "Task Status",
      default: true,
      filterable: true,
      sortable: true,
      maxWidth: 200,
      Cell: (row) => {
        if (row.original.taskStatus === "Canceled") {
          return (
            row.original.taskStatus +
            " (" +
            row.original.cancelReasonCode.name +
            ")"
          );
        } else {
          return row.original.taskStatus;
        }
      },
      Filter: ({ filter, onChange }) => (
        <>
          <select
            ref={this.taskStatusFilterRef}
            onChange={(event) => {
              this.setState({ taskStatusFilter: event.target.value });
              onChange(event.target.value);
            }}
            style={{ width: "100%" }}
            value={
              this.state.taskStatusFilter
                ? this.state.taskStatusFilter
                : undefined
            }
          >
            <option value="undefined" hidden={true}>
              Select
            </option>
            <option value="">All</option>
            <option value="Complete">Complete</option>
            <option value="Canceled">Canceled</option>
          </select>
        </>
      ),
    },
    {
      accessor: "assigneeName",
      Header: "Assignee",
      width: 180,
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "createdBy",
      Header: "Task Created By User",
      default: true,
      filterable: true,
      sortable: true,
    },
    {
      accessor: "createdDate",
      Header: "Task Created Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.createdDate) {
          return (
            <MomentDisplay
              date={task.createdDate}
              timezone={
                row.original.taskLocationTimezone &&
                row.original.taskLocationTimezone
              }
            />
          );
        } else {
          return null;
        }
      },
    },
    {
      accessor: "lastModifiedBy",
      Header: "Last Updated By User",
      filterable: true,
      sortable: true,
      default: true,
      Cell: (row) => {
        let task = row.original;
        if (task.lastModifiedBy) {
          return task.lastModifiedBy;
        } else {
          return null;
        }
      },
    },
    /*{
      accessor: "lastUpdated",
      Header: "Last Updated Datetime",
      default: true,
      filterable: false,
      sortable: true,
      dataType: "date",
      operator: "GT",
      sortable: true,
      Cell: (row) => {
        let task = row.original;
        if (task.lastUpdated) {
          return (
            <MomentDisplay
              date={task.lastUpdated}
              timezone={ row.original.taskLocationTimezone &&
                row.original.taskLocationTimezone}
            />
          );
        } else {
          return null;
        }
      },
    },*/


    // {
    //   accessor: "primaryReferenceNumber",
    //   Header: "Reference Number",
    //   default: true,
    //   filterable: false,
    //   sortable: false,
    // Cell: (row) => (
    //   <a
    //     href={"javascript:;"}
    //     onClick={() => alert(row.original.primaryReferenceID)}
    //   >
    //     {" "}
    //     {row.value}{" "}
    //   </a>
    // ),
    //   Cell: (row) => {
    //     let v = row.original;
    //     console.log("v", v);
    //     return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null? "": row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
    //       }`}</a>
    //   }
    // },

    // {
    //     accessor: 'sealId',
    //     Header: 'Seal ID',
    //     filterable: true,
    //     sortable: true,
    //     default: false
    // },
  ];
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.sealedFilterRef = React.createRef();
    this.reeferFilterRef = React.createRef();
    this.fuelFilterRef = React.createRef();
    this.continousFilterRef = React.createRef();
    this.taskStatusFilterRef = React.createRef();
    const parse = JSON.parse(
      localStorage.getItem(
        getLocalStorageName("poweryard-yardcheck-history", props.user)
      ),
      this.mapFromJson
    );
    this.state = {
      hideFilters:true,
      isdisabled: false,
      showTooltip: false,
      clickedRowIndex: 0,
      Showhover: true,
      istab: false,
    };
    // const parse = JSON.parse(localStorage.getItem("poweryard-task-history"));
    if (parse) {
      this.state = {
        name: null,
        previousSearchRequest: {
          sorted: [],
          filtered: [
            { id: "start", value: startOfDay(new Date(), 7), operator: "GT" },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "yardCheckDate", operator: "EQ" },
          ],
        },
        sealedFilter: parse.sealedFilter,
        reeferFilter: parse.reeferFilter,
        fuelFilter: parse.fuelFilter,
        continousFilter: parse.continousFilter,
        taskStatusFilter: parse.taskStatusFilter,
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "yardCheckDate",
        dateFilterEnabled: true,
        tableSize: parse.tableSize,
        autoRefreshEnabled: parse.autoRefreshEnabled,
        loaded: false,

      };
    } else {
      this.state = {
        previousSearchRequest: {
          sorted: [{ id: "start", desc: "true" }],
          filtered: [
            {
              id: "start",
              value: startOfDay(
                new Date(new Date().setDate(new Date().getDate() - 7))
              ),
              operator: "GT",
            },
            { id: "end", value: endOfDay(new Date()), operator: "LT" },
            { id: "dateFilterField", value: "yardCheckDate", operator: "EQ" },
          ],
        },
        filterStartDate: startOfDayEST(new Date()),
        filterEndDate: endOfDayEST(new Date()),
        filterDateType: "yardCheckDate",
        tableSize: { height: "500px", width: "800px" },
        dateFilterEnabled: true,
        autoRefreshEnabled: false,
        loaded: false,

      };
    }
    if (!this.state.filterEndDate) {
      this.state.filterEndDate = endOfDay(new Date());
    }
    if (!this.state.filterStartDate) {
      this.state.filterStartDate = startOfDay(
        new Date(new Date().setDate(new Date().getDate() - 7))
      );
    }
    if (!this.state.filterDateType) {
      this.state.filterDateType = "yardCheckDate";
    }
    if (!this.state.tableSize) {
      this.state.tableSize = { height: "500px", width: "800px" };
    }
    if (
      this.props.currentLocation.apiSetting !== null
        ? this.props.currentLocation.apiSetting.refernceApi
        : false
    ) {
      let length = this.tableColumns.length;
      if (length != 0) {
        this.tableColumns = this.tableColumns;
        let item = {
          accessor: "secondaryRef",
          Header: "Reference Number",
          default: true,
          filterable: true,
          sortable: false,
          show: true,
          //  Cell: row => <ReferencePopup isPopoverOpen={true} primaryReferenceNumber={row.original.primaryReferenceNumber} primaryRefId={row.original.primaryReferenceID}/>
          // Cell: (row) => {
          //   let v = row.original;
          //   // console.log("v", v);
          //   return <a href={"javascript:;"} onClick={() => this.openRefModal(v, row.value)}>{`${row.value === null ? "" : row.value} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
          //     }`}</a>
          // }
          Cell: (row) => {
            if (
              row.value !== null &&
              row.value !== "" &&
              row.value !== undefined
            ) {
              let v = row.original;
              return row.value.split(",").map((elem, index) => (
                <a
                  href={"javascript:;"}
                  onClick={() => this.openRefModal(v, elem.trim())}
                >
                  {" "}
                  {/* {`${elem} ${v.primaryRefName === null ? "" : `(${v.primaryRefName})`
                    }`} */}
                  {`${elem}`}
                  {row.value.split(",").length - 1 === index ? "" : ","} <br />
                </a>
              ));
            } else {
              return "N/A";
            }
          },
        };
        this.tableColumns.splice(length >= 3 ? 3 : 0, 0, item);
      }
      //For Assersorials
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  openRefModal(ref, elem) {
    this.setState({ loaded: true });
    this.updateSpinner("Loading...");
    ref.clickedRef = `${elem.split(" ")[0]}  (${elem.split(" ")[1]})`;
    this.onGetRefDataByRefID(ref, elem.split(" ")[0]);
  }
  onGetRefDataByRefID(ref, elem) {
    getSecondaryReferenceByRefID(
      elem.trim(),
      (success) => {
        if (success.code === "200") {
          ref.refData = success.content;
          this.setState({
            isRefModalOpen: true,
            selectedTask: ref,
            loaded: false,
          });
        } else if (success.code === "1000") {
          showError(success.message);
          this.setState({ loaded: false });
        }
      },
      (error) => {
        this.setState({ loaded: false });
        showError(error.message);
        console.log(error);
      }
    );
  }
  componentDidMount() {
    window.addEventListener("resize", this.calculateTableSize.bind(this));
    window.scrollTo(0, 0);
    this.calculateTableSize();
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    if (isTablet === true) {
      const datePickers = document.getElementsByClassName(
        "react-datepicker__input-container"
      );
      Array.from(datePickers).forEach((el) =>
        el.childNodes[0].setAttribute("readOnly", true)
      );

      this.setState({ istab: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calculateTableSize.bind(this));
    localStorage.setItem(
      getLocalStorageName("poweryard-yardcheck-history", this.props.user),
      JSON.stringify({
        sealedFilter: this.state.sealedFilter,
        reeferFilter: this.state.reeferFilter,
        fuelFilter: this.state.fuelFilter,
        continousFilter: this.state.continousFilter,
        taskStatusFilter: this.state.taskStatusFilter,
        previousSearchRequest: this.state.previousSearchRequest,
        filterStartDate: this.state.filterStartDate,
        filterEndDate: this.state.filterEndDate,
        filterDateType: this.state.filterDateType,
        dateFilterEnabled: this.state.dateFilterEnabled,
      })
    );
  }
  savePageState() {
    localStorage.setItem(
      getLocalStorageName("poweryard-yardcheck-history", this.props.user),
      JSON.stringify({
        previousSearchRequest: this.state.previousSearchRequest,
        taskStatusFilter: this.state.taskStatusFilter,
        filterStartDate: this.state.filterStartDate,
        filterEndDate: this.state.filterEndDate,
        filterDateType: this.state.filterDateType,
        dateFilterEnabled: this.state.dateFilterEnabled,
        autoRefreshEnabled: this.state.autoRefreshEnabled,
      })
    );
  }
  exportYardHistory() {
    this.setState({ isdisabled: true, loaded: true });
    this.updateSpinner("Exporting...");
    if (this.state.previousSearchRequest) {
      requestYardHistoryToExcel(

        this.state.previousSearchRequest,
        (res) => {
          if (
            res.content &&
            res.content.message &&
            res.content.message.includes("s3.amazonaws.com")
          ) {
            window.location.href = res.content.message;
          } else {
            if (res.code === "1001" || res.code === "1000") {
              showError(res.message);
            } else {
              showError(
                "We are not able to generate the excel file at this time.  Please try again later."
              );
            }
          }
          setTimeout(() => {
            this.setState({ isdisabled: false ,loaded: false });
          }, 3000);
          // showInfo("We are currently generating the Excel file and you will receive it via email shortly.");
        },
        (e) => {
          console.log(e);
          showError(
            "We are not able to generate the excel file at this time.  Please try again later."
          );
          this.setState({ isdisabled: false });

        }
      );
    }
  }
  calculateTableSize = () => {
    let size = getTableSize(
      "power-yard-task-history-page",
      "power-yard-task-history-header"
    );
    this.setState({ tableSize: { height: size.height, width: size.width } });
  };
  render() {
    return (
      <div
        className="column-wrapper text-left"
        id="power-yard-task-history-page"
      >
        <div className="page-header" id={"power-yard-task-history-header"}>
          <h1>Yard History : Yard Check Detail</h1>
        </div>
        {this.state.selectedTask !== undefined && (
          <ReferenceModal
            selectedTask={this.state.selectedTask}
            currentLocation={this.props.currentLocation}
            cancel={() => this.setState({ isRefModalOpen: false })}
            isRefModalOpen={this.state.isRefModalOpen}
          />
        )}
        <Loader show={this.state.loaded} message={this.spinner}>

          <PaginationFilterTable
            openFiltersCollaps={true}
            showEditColumns
            user={this.props.user}
            setloaded={(check) => {
                  this.updateSpinner("");
                  this.setState({ loaded: check });
                  this.updateSpinner("");
                }}
            style={{
              maxHeight: this.state.tableSize.height,
              maxWidth: this.state.tableSize.width,
              minHeight: "120px",
            }}
            onTaskHistory={true}
            ref={this.tableRef}
            autoRefreshEnabled={this.state.autoRefreshEnabled}
            autoRefreshInterval={120000}
            pageType="Admin"
            get={downloadYardHistory}
            stateStorage={"poweryard-yardcheck-history"}
            defaultSorted={[]}
            filtered={this.state.previousSearchRequest.filtered}
            onFilterHide={() => {
              this.setState({
                taskStatusFilter: "undefined",
                enabledFilter: "undefined",
                sealedFilter: "undefined",
                reeferFilter: "undefined",
                fuelFilter: "undefined",
                continousFilter: "undefined",
              });
            }}
            onFilteredChange={(filter) => {
              const delayFilters = ['taskStatus', 'fuelLevel', 'priority', 'boxedIn', 'sealed', 'reefer', 'continous'];
              let psr = { ...this.state.previousSearchRequest };
              psr.filtered = filter;
             const hasDelayFilter = psr.filtered.some((filter, index, array) => {
                    if (array.length === 1) {
                      return delayFilters.includes(filter.id);
                    } else if (array.length > 1) {
                      return index === array.length - 1 && delayFilters.includes(filter.id);
                    }
                    return false;
                  });
              this.setState({ previousSearchRequest: psr }, () => {
                if (hasDelayFilter) {
                  this.updateSpinner("");

                  this.tableRef.current.requestPageSearch();
                } else {
                  setTimeout(() => {
                    this.updateSpinner("");

                    this.tableRef.current.requestPageSearch();
                  }, 3000); // 3000 milliseconds = 3 seconds
                }
              });
            }}
            successCallback={(response) => {
              this.setState({ hasResults: response.totalElements > 0 });
            }}
            clearFilter={(resetAll) => {
              if (resetAll) {
                let psr = { ...this.state.previousSearchRequest };
                psr.filtered = [];
                // this.taskStatusFilterRef.current.value = undefined;

                this.setState(
                  {
                    filterStartDate: startOfDayEST(new Date()),
                    filterEndDate: endOfDayEST(new Date()),
                    dateFilterEnabled: true,
                    previousSearchRequest: psr,
                    // taskStatusFilter: undefined,
                  },
                  () => {
                    // this.tableRef.current.restoreColumns(true);
                    this.updateSpinner("");
                    this.tableRef.current.setState({ page: 0, forcePage: 0 });
                    this.updateProperty("start", this.state.filterStartDate);
                    this.updateProperty("end", this.state.filterEndDate);
                    this.updateProperty(
                      "dateFilterField",
                      this.state.filterDateType
                    );
                  }
                );
              } else {
                let psr = { ...this.state.previousSearchRequest };
                psr.filtered = psr.filtered.filter(
                  (f) =>
                    f.id === "start" ||
                    f.id === "end" ||
                    f.id === "dateFilterField"
                );
                // this.taskStatusFilterRef.current.value = undefined;
                this.setState(
                  {
                    previousSearchRequest: psr,
                    //taskStatusFilter: undefined,
                  },
                  () => {

                    if (this.state.hideFilters == false) {
                      this.updateSpinner("");
                      this.tableRef.current.requestPageSearch()
                    }
                  }
                );
              }
            }}
            setFilterToogle={(value)=>{
                  this.setState({
                    hideFilters:value,
                    
                  });}}
            dateFilterEnabled={this.state.dateFilterEnabled}
            columns={this.tableColumns}
            onChange={(state) => {
              this.setState({
                previousSearchRequest: {
                  dateFilter: state.dateFilter,
                  itemsPerPage: state.itemsPerPage,
                  page: state.page,
                  pageSize: state.pageSize,
                  sorted: state.sorted,
                  filtered: state.filtered,
                },
              });
            }}
            footerButton={
              <Button
                disabled={!this.state.hasResults || this.state.isdisabled}
                color="secondary"
                onClick={() => this.exportYardHistory()}
              >
                <i className="fa fa-file-excel" /> Export to Excel
              </Button>
            }
          >
            <TableDateRangePickerGO
              showTaskSelector={true}
              start={this.state.filterStartDate}
              end={this.state.filterEndDate}
              filterEnabled={this.state.dateFilterEnabled}
              dateType={this.state.filterDateType}
              hisotryPageType={"yardcheckPage"}
              onDateFilterEnable={(enabled) => {
                this.setState({ dateFilterEnabled: enabled });
                if (enabled) {
                  this.updateProperty("start", this.state.filterStartDate);
                  this.updateProperty("end", this.state.filterEndDate);
                  this.updateProperty(

                    "dateFilterField",
                    this.state.filterDateType
                  );
                } else {
                  this.removeFilter("start");
                  this.removeFilter("end");
                  this.removeFilter("dateFilterField");
                }
                //  this.tableRef.current.requestPageSearch();
              }}


              DateChangeApiCall={(date) => {
                this.setState({ loaded: true });
                this.updateSpinner("");
                this.tableRef.current.requestPageSearch();


              }}
              onStartDateChange={(date) => {
                this.updateProperty("start", date);
                this.setState({ filterStartDate: date });
              }}
              onEndDateChange={(date) => {
                this.updateProperty("end", date);
                this.setState({ filterEndDate: date });
              }}
              onDateTypeChange={(val) => {
                this.updateProperty("dateFilterField", val);
                this.setState({ filterDateType: val });
              }}
            />
          </PaginationFilterTable>
        </Loader>
      </div>
    );
  }
  removeFilter(id) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered == null) {
      filtered = [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].id === id) {
        filtered.splice(i, 1);
        break;
      }
    }
    previousSearchRequest.filtered = filtered;
    this.setState({ previousSearchRequest: previousSearchRequest }, () => {
      this.savePageState();
    });
  }
  updateProperty(id, value) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    let found = false;
    if (filtered == null) {
      filtered = [];
    }
    for (const filter of filtered) {
      if (filter.id === id) {
        filter.value = value;
        found = true;
      }
    }
    previousSearchRequest.filtered = filtered;
    if (!found) {
      if (id === "start") {
        filtered.push({ id: id, value: value, operator: "GT" });
      } else if (id === "end") {
        filtered.push({ id: id, value: value, operator: "LT" });
      } else if (id === "filterDateType") {
        filtered.push({ id: id, value: value, operator: "EQ" });
      } else {
        filtered.push({ id: id, value: value, operator: "EQ" });
      }
    }

    this.setState(
      {
        previousSearchRequest: previousSearchRequest,
      },
      () => {
        this.savePageState();
      }
    );
  }
  getProperty(field) {
    let previousSearchRequest = Object.assign(
      {},
      this.state.previousSearchRequest
    );
    let filtered = previousSearchRequest.filtered;
    if (filtered) {
      for (const filter of filtered) {
        if (filter && field) {
          if (filter.id === field) {
            return filter.value;
          }
        }
      }
    }
  }
}
YardCheckHistory.propTypes = {
  user: PropTypes.object,
};
export default YardCheckHistory;
