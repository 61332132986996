import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { getErrorsFromValidationError } from "../../common/utilities";
import { UserFormFields } from "./UserFormFields";
import Config from "../../Config";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";

class UserForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editingUser: this.props.editingUser,
      formValues: this.props.editingUser
    }
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.editingUser !== prevProps.editingUser) {
      this.setState(
        {
          editingUser: this.props.editingUser,
          formValues: this.props.editingUser
        });
    }
  }

  //Latitudes range from 0 to 90. Longitudes range from 0 to 180
  validationSchema(values) {
    let validationRules = {
      firstname: Yup.string().required('First name is required.').nullable(),
      lastname: Yup.string().required('Last name is required.').nullable(),
      email: Yup.string().required('Email is required.').nullable().email("Email not valid format").trim(),
      password: Yup.string()
        .min(6, 'Password has to be at least 6 characters!')
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}|PASSWORD SAVED, BUT NOT DISPLAYED FOR SECURITY/, 'Password must contain: numbers, uppercase and lowercase letters')
        .required('Password is required'),
      passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], "Passwords must match").required('Password confirm is required'),
      phone: Yup.string().nullable().phone(),
      roles: Yup.array().min(1, "Needs at least one role")
    };
    return Yup.object().shape(validationRules)
  }

  validate(getValidationSchema) {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {}
      } catch (error) {
        return getErrorsFromValidationError(error)
      }
    }
  }



  onSubmit(values, { setSubmitting, setErrors }) {
    this.updateSpinner("Saving...");
    setSubmitting(false);
    this.props.save(values, setErrors);
    return false;
  }



  renderForm() {
    return (
      <Formik
        initialValues={this.state.formValues}
        validate={this.validate(this.validationSchema.bind(this))}
        onSubmit={(v, o) => {
          this.onSubmit(v, o)
        }}
        enableReinitialize={true}
      >{
          ({
            values,
            errors,
            touched,
            status,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            handleReset,
            setTouched,
            setFieldValue,
            setFieldTouched
          }) => (
            <Form
              autoComplete="off"
              onSubmit={(v) => {
                return handleSubmit(v)
              }}>
              <Card className="simple-card">
                <CardHeader>User Info</CardHeader>
                <CardBody>
                  <UserFormFields
                    loggedInUser={this.props.loggedInUser}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    onChange={(object) => {
                      this.setState({ formValues: object });
                    }}
                    onCountryChange={(object) => {
                      setFieldValue("country", object ? object.value : "");
                      setFieldTouched("country", true);
                    }}
                    onStateChange={(object) => {
                      setFieldValue("state", object ? object.value : "");
                      setFieldTouched("state", true);
                    }}
                    onRolesChange={(roles) => {
                      setFieldValue("roles", roles ? roles : []);
                      setFieldTouched("roles", true);
                    }}
                    onLocationsChange={(locations) => {
                      locations = locations.map((l) => { return { uuid: l.value, name: l.label } });
                      setFieldValue("locations", locations ? locations : []);
                      setFieldTouched("locations", true);

                    }}
                    onCarriersChange={(carriers) => {
                      carriers = carriers.map((l) => { return { uuid: l.value, name: l.label } });
                      setFieldValue("carriers", carriers ? carriers : []);
                      setFieldTouched("carriers", true);

                    }}
                    onCountryCodeChange={(e) => {
                      setFieldValue("countryCode", e.target.value);
                      setFieldTouched("countryCode", true);
                    }}
                    onCellCountryCodeChange={(e) => {
                      setFieldValue("cellCountryCode", e.target.value);
                      setFieldTouched("cellCountryCode", true);
                    }}
                  />
                </CardBody>
              </Card>
              <div className="mb-3 text-right">
                <Button color="danger" onClick={() => this.props.cancel()}>Cancel</Button> &nbsp;
                <Button color="primary" type="submit" disabled={this.props.loadedUser}>Save</Button>
              </div>
            </Form>
          )}
      </Formik>);
  }


  render() {
    return (
      <Modal isOpen={this.state.editingUser != null} size={"lg"} >
        <Loader show={this.props.loadedUser} message={this.spinner}>

          <ModalHeader>
            User: {this.state.editingUser ? this.state.editingUser.email : ""}  {Config.showUuid && "[" + (this.state.editingUser && this.state.editingUser.uuid ? this.state.editingUser.uuid : "") + "]"}
          </ModalHeader>
          <ModalBody style={{ overflow: 'auto' }}>
            {this.renderForm()}
          </ModalBody>
        </Loader>
      </Modal>);

  }
}

export default UserForm;