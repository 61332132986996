import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem,
    Col,
    Row
} from "reactstrap";
import trucklogo from "../../assets/img/brand/truck_logo.png";
import * as PropTypes from "prop-types";
import {Link} from "react-router-dom";
import Config from "../../Config.js";


let itemStyle = {padding: "12px", paddingTop: "5px", minHeight: "323px" };

const firstSlide = <div style={itemStyle}>
    <h5>No up-front costs to start and provides real-time app-less visibility to
        truckload shipment events</h5>
    <ul className={"text-left"} style={{fontSize: "12px"}}>
        <li>Including check-in, at dock, loaded/unloaded and check-out</li>
        <li>Every shipment (including Customer Pick-Up and Vendor Delivered).</li>
        <li>Every location (including Consignee Destination and Supplier Origin).</li>
        <li>Every carrier (including brokered loads).</li>
        <li>Register today. Start using today. Begin saving now.</li>
    </ul>
</div>;

const secondSlide = <div style={itemStyle}>
    <h5>Manufacturers/Shippers/Warehouses</h5>
    <ul className={"text-left"} style={{fontSize: "12px"}}>
        <li>End-to-end supply chain event visibility. Confidence in execution.</li>
        <li>Improve site operations. Faster check-in. Reduced dwell times. Become a preferred
            shipper.
        </li>
        <li>Control costs. Validate detention/demurrage assessorials with indisputable check-in/out
            timestamps.
        </li>
        <li>Enforce compliance. Confirm consignee compliance with terms of sale and CPU
            agreements.
        </li>
    </ul>
</div>;

const thirdSlide = <div style={itemStyle}>
    <h5>Carriers/Brokers</h5>
    <ul className={"text-left"} style={{fontSize: "12px"}}>
        <li>Every shipment. Easy, app-less solution. No "app fatigue" excuses.</li>
        <li>Increase productivity. Real-time visibility. No check calls.</li>
        <li>Wow your shipper customer! Excel with 100% compliance on their reporting requirements.
        </li>
        <li>Out-perform your competitors. Grow now.</li>
    </ul>
</div>;

const items = [{
    key: "1",
    content: firstSlide
}, {
    key: "2",
    content: secondSlide
}, {
    key: "3",
    content: thirdSlide
}];

const slideInterval = 1000 * 10;

export default class FrontPageInformationCard extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0, interval: slideInterval, playing: true };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    pause() {
        this.setState({playing: false, interval: false});
    }

    play() {
        this.setState({playing: true, interval: slideInterval});
    }

    render() {
        const { activeIndex } = this.state;
        // the interval at which the carousel automatically cycles (default: 5000)
        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.key}
                    pause={false}
                >
                    {item.content}
                    {/*<CarouselCaption captionText={item.caption} captionHeader={item.caption} />*/}
                </CarouselItem>
            );
        });


        return <Card className={this.props.className}>
            <CardBody className="text-center"  style={{padding: "2%"}}>
                <Row>
                    <Col md={12}>
                        <div className="text-right" style={{marginBottom: "5px"}}>
                            <Link to="/login" className={"d-lg-none"}><Button color="primary" size="sm">Login</Button></Link>&nbsp; &nbsp;
                            {!Config.preventRegistration &&
                            <Button color="primary" size="sm" onClick={this.props.onClick}>Register Now!</Button>
                            }
                        </div>
                    </Col>
                </Row>
                {!Config.preventRegistration &&
                <Row>
                    <Col md={12}>
                        <span className={"text-right d-none d-md-block d-md-none"}>Not yet registered?</span>
                    </Col>
                </Row>
                }
                <div>

                    <div>
                        <img alt="Truck Logo" className="truck-logo" src={trucklogo} style={{width: "95%", marginTop:"5px", marginBottom:"5px"}}/>
                        <h3>EZCheck-In</h3>
                        <h4><b>Shipment Events Everywhere</b></h4>
                        <div>
                            <div className={"carouselWrapper"}>
                                <Carousel
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                    interval={this.state.interval}
                                    pause={false}
                                    ride="carousel"
                                >
                                    <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides}
                                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                    <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                </Carousel>
                                <div className={"carouselButtons"}>
                                    {this.state.playing?<Button className={"verysimple"} title={"Pause Slides"} onClick={()=>this.pause()}><i className={"fa fa-pause"}></i></Button>:<Button className={"verysimple"}  title={"Play Slides"} onClick={()=>this.play()}><i className={"fa fa-play"}></i></Button>}
                                </div>
                            </div>
                            <Row>
                                <Col md={12}>
                                    <a style={{color: "white", textDecoration: "underline"}}
                                       href={"https://www.gete4score.com/contact-us/"}>Contact</a> us now for a demo and to register
                                    for your free 30 day trial.
                                </Col>
                                <Col md={12} className={"d-lg-none"}>
                                    <span style={{fontSize:"6px"}}>COPYRIGHT 2017. IGIT ENTERPRISES, INC. D/B/A E4SCORE.COM. ALL RIGHTS RESERVED.</span>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>;
    }
}

FrontPageInformationCard.propTypes = {onClick: PropTypes.func};