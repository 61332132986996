import React from "react";
import MomentDisplay from "../../assets/components/MomentDisplay";
import { latLongDistance } from "../../common/utilities";
import connect from "react-redux/es/connect/connect";
import { ClipboardClickable } from "../../common/ClipboardClickable";
import * as uuid from 'uuid';
import { EventLocation } from "./EventLocation";
import { RangeAlertCircle } from "./RangeAlertCircle";
import { ArrivalAlertClock } from "./ArrivalAlertClock";
import { DwellAlertTooltip } from "../../Dispatch/components/DwellAlertTooltip";
import { Button } from "reactstrap";

const formatLatLong = ( number ) => {
    try {
        if ( number ) {
            return parseFloat(number).toFixed(5);
        }
    } catch ( e ) {}
    return "N/A";
};

export const formatEventLocation = (showDate, geocodeLocation, latitude, longitude, timezone, date) => {
    function getLocation() {
        let location = geocodeLocation.locality + ", " + geocodeLocation.state;
        if (geocodeLocation.country !== "US") {
            location += (", " + geocodeLocation.country);
        }
        return location;
    }

    if (geocodeLocation ) {
        if ( geocodeLocation.locality && geocodeLocation.state && geocodeLocation.country ) {
            let location = getLocation();
            return <div>
                <p>
                    <span title={geocodeLocation.fullLocation || "N/A"} className="pointer-cursor">
                        {location} {showDate ? <span> at <MomentDisplay timezone={timezone} date={date}/></span> : ""}
                    </span>
                </p>
                <p>
                    {formatLatLong(latitude) + ", " + formatLatLong(longitude)}
                </p>
            </div>
        }
    }
    return formatLatLong(latitude) + ", " + formatLatLong(longitude);
};

const ShipmentEvent = ({event, user, onEdit}) => {
        let stopNumber = event.stop + 1;

    function getProviders() {
        let col = "";
        if(event && event.providers){
            for (const provider of event.providers) {
                col += provider + " ";
            }
        }
        return col;
    }

    return <tr>
        {/* <td key={1}><MomentDisplay timezone={event.timezone}
                                   date={event.date}/>&nbsp; {event.forcedDeparture ? "*" : ""}</td> */}
         <td key={1}>{event.datee}&nbsp; {event.forcedDeparture ? "*" : ""}</td>
        <td key={2}>{event.eventDescription} </td>
        <td key={3}>{event.status} - {event.stop !== undefined ? "Stop " + stopNumber + " - " : ""} {event.statusName}</td>
        <td key={4}>{event.location}</td>
        <td key={5} style={{minWidth: "300px"}}>
            <ClipboardClickable
                id={"ID_" + uuid.v4()}
                value={formatLatLong(event.latitude) + ", " + formatLatLong(event.longitude)}
                hover={"Click to Copy Coordinates"}>
                <EventLocation
                    showDate={false}
                    geocodeLocation={event.geocodeLocation}
                    latitude={event.latitude}
                    longitude={event.longitude}
                    timezone={event.timezone}
                    date={event.date}
                    event={event}
                />
            </ClipboardClickable>
        </td>
        <td key={7} style={{minWidth: "75px"}}>
            {event.arrivalAlert &&
            <ArrivalAlertClock
                arrivalAlert={event && event.arrivalAlert}
                stopAppointment={event && event.stopAppointment}
                graceInMins={event && event.graceInMins}
                timezone={event && event.timezone}
                allDay={event && event.allDay}
            />}
            <RangeAlertCircle
                distanceUnitsOfMeasure={user.company.distanceUnitsOfMeasure}
                geoFenceRange={event && event.geoFenceRadiusInMeters}
                rangeAlert={event && event.rangeAlert}
                latitude={event && event.latitude}
                longitude={event && event.longitude}
                locationLatitude={event && event.locationLatitude}
                locationLongitude={event && event.locationLongitude}
                showBlack
            />
        </td>
        <td key={6} style={{minWidth: "100px"}}>
            {event.tractorDwellAlert &&
            <DwellAlertTooltip
                showBlack
                type={"Tractor"}
                style={{marginLeft: "10px"}}
                alert={event.tractorDwellAlert.dwellAlert}
                dwellInSeconds={event.tractorDwellAlert.dwellInSeconds}
                criticalDwellInSeconds={event.tractorDwellAlert.criticalDwellAlertInSeconds}
                criticalDwellUnit={event.tractorDwellAlert.criticalDwellAlertUnit}
                warningDwellInSeconds={event.tractorDwellAlert.warningDwellAlertInSeconds}
                warningDwellUnit={event.tractorDwellAlert.warningDwellAlertUnit}
            />}
            {event.tractorDwellAlert &&
            <DwellAlertTooltip
                showBlack
                type={"Trailer"}
                alert={event.trailerDwellAlert.dwellAlert}
                dwellInSeconds={event.trailerDwellAlert.dwellInSeconds}
                criticalDwellInSeconds={event.trailerDwellAlert.criticalDwellAlertInSeconds}
                criticalDwellUnit={event.trailerDwellAlert.criticalDwellAlertUnit}
                warningDwellInSeconds={event.trailerDwellAlert.warningDwellAlertInSeconds}
                warningDwellUnit={event.trailerDwellAlert.warningDwellAlertUnit}
            />}
        </td>
        <td key={8} style={{minWidth: "150px"}}>{
            latLongDistance(
                event.latitude,
                event.longitude,
                event.locationLatitude,
                event.locationLongitude,
                user.company.distanceUnitsOfMeasure)
        }</td>
        
            {/* <td key={9}>{getProviders()}</td> */}
        
      
    </tr>
};


const ShipmentEvents = ({events, user, onEdit}) => {
    return <table className="table table-striped w-auto table-responsive">
        <thead>
            <tr>
                <th>Date/Time</th>
                <th>Event</th>
                <th>Shipment Status</th>
                <th>Location</th>
                <th>GPS</th>
                <th colSpan={2}>Alerts</th>
                <th>Distance From Location</th>
                {/* <th>Carrier/Broker</th> */}
                
              
            </tr>
        </thead>
        <tbody>
        {events.slice(0).reverse().map((event, ix)=><ShipmentEvent key={ix} user={user} event={event} onEdit={onEdit}/>)}
        </tbody>
    </table>
};

function mapStateToProps(state) {
    return {
        user: state.login.user
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentEvents);