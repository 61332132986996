import React, {Component} from "react";
import {FFDateSegmentPicker} from "./FFDateSegmentPicker";
import moment from "moment-timezone";
import {FormFeedback, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import DatePicker from "react-datepicker";
import * as uuid from "uuid";
import {Debug} from "../../Debug";

export class FFDateTimePicker extends Component {
    // selected={moment(this.props.value).toDate()}
    constructor() {
        super();
        this.state = {
            hh: false,
            mm: false,
            ap: false,
            touched: false
        }
    }

    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    getTz() {
        return this.props.timezone ? this.props.timezone : moment.tz.guess();
    }

    render() {
        return <>
            <InputGroup className={this.formValid() ? "is-invalid datetime-in-form" : "datetime-in-form"}>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>{this.icon()}</InputGroupText>
                </InputGroupAddon>
                <DatePicker
                    name={this.props.fieldname}
                    className={this.props.touched && !!this.props.error ? "is-invalid" : ""}
                    valid={!this.props.error}
                    invalid={this.props.touched && !!this.props.error}
                    onBlur={this.props.onBlur}
                    onChange={(v) => {
                        let now = new Date();
                        v.setMinutes(now.getMinutes());
                        v.setHours(now.getHours());
                        this.props.onChange(v);
                    }}
                    selected={this.props.value ? new Date(this.props.value) : null}
                    disabled={this.props.isDisabled}
                    onFocus={(e) => e.target.select()}
                />
                <FFDateSegmentPicker
                    value={this.getHours()}
                    onChange={(v) => {
                        this.setState({hh: v})
                    }}
                    disabled={this.props.disabled}
                    fieldname={this.props.fieldname}
                    placeholder={"HH"}
                    onBlur={() => {
                        let value = this.props.value;
                        if(!value){
                            value = new Date();
                        }
                        let date = new Date(value);
                        let hours = (this.state.hh ? this.state.hh : 0) % 12;
                        hours += (moment(value).format("A") === "PM" ? 12 : 0); // add 12 if PM.
                        let m = moment(date).tz(this.getTz(), false);
                        m.hours(hours);
                        date = m.toDate();
                        this.setState({
                            hh: hours % 12,
                            mm: moment(date).tz(this.getTz()).format("mm"),
                            ap: moment(date).tz(this.getTz()).format("A")
                        },()=>{this.props.onChange(date)});
                    }}
                />
                <FFDateSegmentPicker
                    value={this.getMins()}
                    onChange={(v) => {
                        this.setState({mm: v})
                    }}
                    disabled={this.props.disabled}
                    fieldname={this.props.fieldname}
                    placeholder={"MM"}
                    onBlur={() => {
                        let value = this.props.value;
                        if(!value){
                            value = new Date();
                        }
                        let date = new Date(value);
                        let mins = this.state.mm % 60;
                        let m = moment(date).tz(this.getTz(), false);
                        m.minutes(mins);
                        date = m.toDate();
                        this.props.onChange(date);
                        this.setState({
                            hh: moment(date).tz(this.getTz()).format("hh"),
                            mm: moment(date).tz(this.getTz()).format("mm"),
                            ap: moment(date).tz(this.getTz()).format("A")
                        },()=>{this.props.onChange(date)});

                    }}
                />
                <select type="text" placeholder="AP"
                        className="text-short"
                        name={this.props.fieldname + "_AP"}
                        onChange={(v) => {
                            this.setState({ap: v.target.value})
                        }}
                        value={this.getAp()}
                        onBlur={(e) => {
                            let value = this.props.value;
                            if(!value){
                                value = new Date();
                            }
                            let date = new Date(value);
                            let hours = moment(date).format("h") % 12;
                            if(moment(this.props.value).format("A") === "PM"){
                                hours -= 12;
                            }else {
                                hours += 12;
                            }
                            hours += (moment(this.props.value).format("A") === "PM"?12:0); // add 12 if PM.
                            date.setHours(hours);
                            this.setState({
                                hh: moment(date).tz(this.getTz()).format("hh"),
                                mm: moment(date).tz(this.getTz()).format("mm"),
                                ap: moment(date).tz(this.getTz()).format("A")
                            },()=>{this.props.onChange(date)});
                        }}
                        disabled={this.props.disabled}
                        onFocus={(e) => e && e.target && e.target.select && e.target.select()}
                >
                    <option>AM</option>
                    <option>PM</option>
                </select>
                <FormFeedback className={this.formValid() && "d-block"}>{this.props.error}</FormFeedback>
            </InputGroup>
        </>;
    }

    formValid() {
        return this.props.touched &&
            !!this.props.error;
    }

    getAp() {
        if(this.state.ap){
            return this.state.ap;
        } else if (!this.state.ap && this.props.value) {
            return moment(this.props.value).tz(this.props.timezone).format("A");
        } else {
            return null;
        }
    }

    getMins() {
        if(this.state.mm){
            return this.state.mm;
        } else if (!this.state.mm && this.props.value) {
            return moment(this.props.value).tz(this.props.timezone).format("mm");
        } else {
            return null;
        }
    }

    getHours() {
        if(this.state.hh){
          return this.state.hh;
        } else if (!this.state.hh && this.props.value) {
            return moment(this.props.value).tz(this.props.timezone).format("h");
        } else {
            return null;
        }
    }
}