import React from "react";
import * as uuid from "uuid";
import {Tooltip} from "reactstrap";
import MomentDisplay from "../../assets/components/MomentDisplay";

export class ArrivalAlertClock extends React.Component{
    constructor(props) {
        super();
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        }
    }

    getColor(arrivalAlert) {
        if(!arrivalAlert){
            return "black";
        }else if(arrivalAlert === "NotCalculated"){
            return "black";
        }else if(arrivalAlert === "Late"){
            return "red";
        }else if(arrivalAlert === "OnTime"){
            return "green";
        }else {
            return "black";
        }
    }

    toggle(){
        this.setState({tooltipOpen: !this.state.tooltipOpen})
    }

    render() {
        return <span>
            <i id={this.state.id} className="fas fa-clock" aria-hidden="true"
               style={{marginLeft: "10px", color: this.getColor(this.props.arrivalAlert)}}/>
            <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={() => this.toggle()}>
                {!this.props.stopAppointment && "Not Calculated"}
                {this.getTooltip()}
                {!this.props.allDay && <br/>}
                {!this.props.allDay && this.getGrace()}
            </Tooltip>
        </span>
            ;
    }

    getGrace() {
        if(!this.props.stopAppointment){
            return <></>;
        }
        //copy and paste from dwell time function, which takes in seconds
        return <> Grace Limit: {this.getGraceTimeFormatted(this.props.graceInMins*60)}</>;
    }

    getGraceTimeFormatted(graceInSeconds){
        let dwell = null;
        if(graceInSeconds > (60*60*24)){
            dwell = Math.floor(graceInSeconds/(60*60*24));
            return dwell + " Days"
        }
        else if(graceInSeconds > (60*60)){
            dwell = Math.floor(graceInSeconds/(60*60));
            return dwell + " Hours"
        }
        else if(graceInSeconds > 60){
            dwell = Math.floor(graceInSeconds/(60));
            return dwell + " Minutes"
        }else {
            if(graceInSeconds < -(60*60*24)){
                dwell = Math.floor(graceInSeconds/(60*60*24));
                return dwell + " Days"
            }
            else if(graceInSeconds < -(60*60)){
                dwell = Math.floor(graceInSeconds/(60*60));
                return dwell + " Hours"
            }
            else if(graceInSeconds < -60){
                dwell = Math.floor(graceInSeconds/(60));
                return dwell + " Minutes"
            }else {
                return Math.floor(graceInSeconds)+ " Seconds";
            }
        }
    }

    getTooltip() {
        if(!this.props.stopAppointment){
            return <></>;
        }
        return <>
            Appointment Time: {
            this.props.stopAppointment &&
            <MomentDisplay
                timezone={this.props.timezone}
                date={this.props.stopAppointment}
                noTime={this.props.allDay}
            />
        }
        {this.props.allDay && <div>All Day</div>}
        </>;
    }
}