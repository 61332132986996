import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { checkLogin, postLoginUser, postLoginUserV2 } from "../../NetworkHelpers";
import { withRouter } from "react-router";
import { showError } from "../../MainApp";
import FrontPageInformationCard from "./FrontPageInformationCard";
import * as PropTypes from "prop-types";
import Config from "../../Config";

class LoginCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: null,
    };
  }

  render() {
    return (
      <Card className={this.props.className}>
        <CardBody>
          <a href={"https://www.gete4score.com/"}>
            <img
              alt="Logo"
              className="login-logo-poweryard"
              // className="login-logo"
              src={
                Config.preLoginBrand
                  ? Config.preLoginBrand
                  : "/assets/img/brand/ezc_by-e4.png"
              }
            />
          </a>
          <div className="v-space" />
          <Form>
            <h1>Login</h1>
            <p>Sign In to your account.</p>
            <div className="v-space" />
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-user" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Username"
                value={this.props.value}
                autoComplete="username"
                onChange={this.props.onUsernameChange}
                onKeyPress={this.props.onKeyPress}
              />

            </InputGroup>
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fa fa-lock" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type={this.state.passwordType === null ? 'password' : this.state.passwordType}
                placeholder="Password"
                value={this.props.password}
                autoComplete="current-password"
                onChange={this.props.onPasswordChange}
                onKeyPress={this.props.onKeyPress}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className={(this.state.passwordType === null || this.state.passwordType === 'password') ? 'fa fa-eye c-pointer' : 'fa fa-eye-slash c-pointer'} onClick={() => {
                    let type = (this.state.passwordType === null || this.state.passwordType === 'password') ? 'text' : 'password'
                    this.setState({
                      passwordType: type,
                    })
                  }} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <div className="v-space" />
            <Row>
              <Col xs="12">
                <Button
                  color="primary"
                  className="px-4"
                  onClick={this.props.onLoginClick}
                >
                  Login
                </Button>{" "}
                &nbsp; &nbsp; &nbsp; &nbsp;
                <Button
                  color="link"
                  className="px-0 muted-link"
                  onClick={this.props.onPasswordResetClick}
                >
                  Forgot password?
                </Button>
              </Col>

            </Row>

            {this.props.notification && this.props.notification.notification && (<div
              style={{
                border: "2px solid red",
                backgroundColor: "#e64747",
                color: "yellow",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <p>NOTICE: <div dangerouslySetInnerHTML={{ __html: this.props.notification.notification }} />
              </p>
            </div>)
            }
          </Form>
          <div className="v-space" />
          <div className="v-space" />
        </CardBody>
        <span style={{ fontSize: "9.5px" }}>
          <a href={Config.footerBrandUrl} target="_new">
            {Config.footerBrand}
          </a>{" "}
          &#xAE; {new Date().getFullYear()} {Config.footerCopyright}
          &nbsp; Version : {Config.version}
        </span>
      </Card>
    );
  }
}

LoginCard.propTypes = {
  value: PropTypes.any,
  onUsernameChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  password: PropTypes.any,
  onPasswordChange: PropTypes.func,
  onLoginClick: PropTypes.func,
  onPasswordResetClick: PropTypes.func,
  className: PropTypes.any,
};

class LoginPoweryard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: true,
      userIsLoggedIn: false,
      passwordType: null
    };
  }

  componentWillUnmount() {
    document.body.style.background = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.background = null;
    document.body.style.backgroundColor = "#000";
    this.checkLogin();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  registerClicked(e) {
    e.preventDefault();
    this.nextPath("/register");
  }

  checkLogin() {
    checkLogin(
      (user) => {
        this.props.login(user.username, user);
        this.setState({ loading: false, userIsLoggedIn: true });
      },
      () => {
        this.setState({ loading: false, userIsLoggedIn: false });
      }
    );
  }

  login() {
    postLoginUserV2(
      { username: this.state.username, password: this.state.password },
      (user) => {
        localStorage.setItem('taskPopUpCheck', false);
        this.props.login(this.state.username, user.content);
      },
      () => {
        showError("You could not be logged in. Please check your credentials.");
      }
    );
  }

  onUsernameChange(username) {
    this.setState({ username: username });
  }

  onPasswordChange(password) {
    this.setState({ password: password });
  }

  onEnterLogin(e) {
    if (e.charCode === 13) this.login();
  }

  showRequestPasswordReset() {
    this.props.history.push("password-reset-request");
  }

  getCardWidth() {
    return Config.hideInfoOnLogin ? 6 : 12;
  }
  // old
  render() {
    if (!this.state.loading && !this.state.userIsLoggedIn) {
      return (
        <div className="app flex-row align-items-center animated fadeIn">
          <Container>
            <Row className="justify-content-center">
              <Col md={this.getCardWidth()}>
                <CardGroup>
                  <LoginCard
                    className={
                      "p-4 text-left login-card " +
                      (this.props.hideLoginOnSmall ? "d-md-down-none" : "")
                    }
                    value={this.state.username}
                    notification={this.props.notification}
                    onUsernameChange={(e) =>
                      this.onUsernameChange(e.target.value)
                    }
                    onKeyPress={(e) => this.onEnterLogin(e)}
                    password={this.state.password}
                    onPasswordChange={(e) =>
                      this.onPasswordChange(e.target.value)
                    }
                    onLoginClick={() => this.login()}
                    onPasswordResetClick={(e) => {
                      e.preventDefault();
                      this.showRequestPasswordReset();
                    }}
                    passwordType={null}
                  />
                  {!Config.hideInfoOnLogin && (
                    <FrontPageInformationCard
                      className={
                        "text-white bg-primary " +
                        (this.props.hideInformationOnSmall
                          ? "d-md-down-none"
                          : "")
                      }
                      onClick={(e) => this.registerClicked(e)}
                    />
                  )}
                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return <div></div>;
  }
}

export default withRouter(LoginPoweryard);
