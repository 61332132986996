import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {promiseTrailerByCarrierAndIdPrefix,} from "../../NetworkHelpers";
import FormTextAreaField from "../../common/FormTextAreaField";
import FormTextField from "../../common/FormTextField";
import FormSelectStateField from "../../common/FormSelectStateField";
import * as PropTypes from "prop-types";
import {promiseReturningEmptyList} from "../../common/utilities";
import FormSelectCreatableField from "../../common/FormSelectCreatableField";

export class BobTailArrival extends Component {
    render() {
        return <Card>
            <CardHeader>
                <div className="form-section-title">
                    <Button onClick={this.props.onClick}>
                        <i className={this.props.className}/>
                    </Button> Trailer
                </div>
            </CardHeader>
            <CardBody>
                <FormSelectCreatableField
                    key={this.props.values && (this.props.values["broker"]||this.props.values["carrier"])}
                    defaultOptions
                    label="Trailer Number"
                    subLabel="(Start typing to lookup trailer)"
                    required={true}
                    placeholder="Enter Value"
                    icon="fa-hashtag"
                    fieldname="trailerNumber"
                    errors={this.props.errors}
                    touched={this.props.touched}
                    values={this.props.values}
                    setFieldTouched={this.props.fieldTouched}
                    setFieldValue={this.props.fieldValue}
                    handleBlur={this.props.handleBlur}
                    handleChange={this.props.handleChange}
                    getOptionLabel={this.props.optionLabel}
                    getOptionValue={this.props.optionLabel}
                    loadOptions={this.props.values && (this.props.values["carrier"])?
                        promiseTrailerByCarrierAndIdPrefix(this.props.values["carrier"]):
                        promiseReturningEmptyList()}
                    onChange={(v)=>{
                        let values;
                        if(!v) v ={};
                        values = Object.assign({}, this.props.values);
                        values.trailerNumber = v.assetId;
                        values.trailerUuid = v.uuid;
                        this.props.fieldValue("trailerNumber", v.assetId);
                        this.props.fieldValue("trailerUuid", v.uuid);
                        this.props.onChange && this.props.onChange(values);
                    }}
                    isDisabled={this.props.isDisabled}
                />
                <FormTextAreaField
                    label="Trailer Comment"
                    placeholder="Enter Value"
                    icon="fa-comment"
                    fieldname="trailerComment"
                    errors={this.props.errors}
                    handleBlur={this.props.handleBlur}
                    handleChange={(k)=>{
                        this.props.values.trailerComment = k.target.value;
                        this.props.handleChange(k);
                        this.props.onChange && this.props.onChange(this.props.values);
                    }}
                    touched={this.props.touched}
                    values={this.props.values}
                    isDisabled={this.props.isDisabled}
                />
                {this.props.driverSectionOpen ?
                    <FormTextField
                        label="License Plate"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="trailerLicense"
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={(k)=>{
                            this.props.values.trailerLicense = k.target.value;
                            this.props.handleChange(k);
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched}
                        values={this.props.values}
                        setFieldTouched={this.props.fieldTouched}
                        setFieldValue={this.props.fieldValue}
                    /> : ""}
                {this.props.driverSectionOpen ?
                    <FormSelectStateField
                        label="License State"
                        placeholder="Enter Value"
                        icon="fa-id-card"
                        fieldname="trailerLicenseState"
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={this.props.handleChange}
                        onChange={v=>{
                            this.props.values.trailerLicenseState = v.label;
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched}
                        values={this.props.values}
                        setFieldTouched={this.props.fieldTouched}
                        setFieldValue={this.props.fieldValue}
                    /> : ""}
                {this.props.driverSectionOpen ?
                    <FormTextField
                        label="Temperature"
                        placeholder="Enter Value"
                        icon="fa-thermometer-three-quarters"
                        fieldname="trailerTemperature"
                        errors={this.props.errors}
                        handleBlur={this.props.handleBlur}
                        handleChange={(v)=>{
                            this.props.values.trailerTemperature = v.target.value;
                            this.props.handleChange(v);
                            this.props.onChange && this.props.onChange(this.props.values);
                        }}
                        touched={this.props.touched}
                        values={this.props.values}
                    /> : ""}
            </CardBody>
        </Card>;
    }
}

BobTailArrival.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    errors: PropTypes.any,
    handleBlur: PropTypes.any,
    handleChange: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    fieldTouched: PropTypes.any,
    fieldValue: PropTypes.any,
    optionLabel: PropTypes.func,
    onChange: PropTypes.func,
    driverSectionOpen: PropTypes.any
};