import MomentDisplay from "../assets/components/MomentDisplay";
import React from "react";

export function getStartColumn(row, props) {
    if (row.original.manual && row.original.status === "Pending Manual Dispatch") {
        return "Manual";
    } else if (row.original.scheduledStart === null) {
        return null;
    } else {
        return <MomentDisplay
            date={row.original.scheduledStart}
            timezone={row.original.firstLocationTimezone || props.currentLocation && props.currentLocation.timezone}
        />
    }
}

export function formatAdditionalReferences(refs, startIndex) {
    if (!refs || refs.length === startIndex) {
        return null;
    }
    let contents = [];
    for (let i=startIndex; i < refs.length; i++) {
        contents = contents.concat([refs[i].value + " (" + refs[i].type] + ")");
    }
    let uniqueContents = contents.filter((item, index, origArray) => origArray.indexOf(item) === index)
    return uniqueContents.map((r) => <>{r}<br/></>);
}
