import React, { Component } from 'react';
import { Col, FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from "reactstrap";
import Switch from "react-switch";
import ReferenceNumberList from "../Dispatch/components/ReferenceNumberList";

class FormCheckboxField extends Component {
  icon() {
    if (this.props.icon) {
      return <i className={"fa " + this.props.icon}></i>
    } else if (this.props.iconText) {
      return <strong>{this.props.iconText}</strong>;
    }
    return " ";
  }


  getClassName() {
    let ret = "radio-group";
    if (!!this.props.errors[this.props.fieldname] && this.props.touched[this.props.fieldname]) {
      ret += " is-invalid";
    }
    return ret;
  }

  render() {
    return (

      <FormGroup>
        <Row>
          <Col lg="2" md="3" sm="4" className={this.props.labelClassName} style={this.props.labelStyle}>
            <Label>{this.props.label}
              {this.props.isInfoIcon &&
                <i className="pl-2 fas fa-info-circle text-primary c-pointer fa-lg" title={this.props.infoText}></i>

              }
            </Label>
          </Col>
          <Col lg="10" md="9" sm="8">
            <InputGroup className={this.getClassName()}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{this.icon()}</InputGroupText>
              </InputGroupAddon>
              <div className="radio-list" style={{ paddingLeft: "5px", paddingTop: "5px" }}>
                <Label style={{ marginBottom: "0px" }}>
                  <Switch type="checkbox"
                    height={20}
                    width={40}
                    className="checkbox-in-form"
                    name={this.props.fieldName}
                    checked={this.props.values[this.props.fieldname] || false}
                    disabled={this.props.disabled}
                    onChange={(v) => {
                      this.props.onChange && this.props.onChange(v);
                      if (!this.props.formikOverride) {
                        this.props.setFieldValue(this.props.fieldname, v);
                        this.props.setFieldTouched(this.props.fieldname, true);
                      } else {
                        this.props.setFieldValue(this.props.formikOverride, v);
                        this.props.setFieldTouched(this.props.formikOverride, true);
                      }
                    }}
                  />
                  {this.props.additionalText ?
                    <span className="text-muted">{this.props.additionalText}</span>
                    : ""
                  }
                </Label>
              </div>
              <FormFeedback>{this.props.errors[this.props.fieldname]}</FormFeedback>
            </InputGroup>
          </Col>
        </Row>
      </FormGroup>
    )
  }
}


export default FormCheckboxField;