import React, {Component} from 'react';
import {Button, Card, CardBody, CardGroup, CardHeader, Col, Container, Form, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Formik} from 'formik';
import * as Yup from 'yup'
import {getErrorsFromValidationError} from "../common/utilities";
import FormPhoneTextField from "../common/FormPhoneTextField";
import ValidationService from "../common/ValidationService";
import {postDemo} from "../NetworkHelpers";
import Config from "../Config";

const validationSchema = function (values) {
    return Yup.object().shape({
        phone: Yup.string()
            .required('Phone is required'),
        countryCode: Yup.string().required("Please select a country code")
    })
}

const validate = (getValidationSchema) => {
    return (values) => {
        const validationSchema = getValidationSchema(values)
        try {
            validationSchema.validateSync(values, {abortEarly: false})
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
}

const initialValues = {
    phone: "",
    countryCode: 1
};

class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitted: false
        }
    }

    componentWillUnmount() {
        document.body.style.background = null;
    }

    componentDidMount() {
        window.scrollTo(0,0);
        document.body.style.background = "url(assets/img/background/truck-bg4.jpg)";
    }

    demo(values) {
        postDemo(values, (response)=>{
            // showInfo(response.message);
        }, ()=>{
            // showError("An unexpected error has occurred.");
        })
    }

    onSubmit(values, {setSubmitting, setErrors, resetForm}) {
        console.log("ON submit");
        setSubmitting(false);
        values.countryCode = "+"+values.countryCode;
        this.demo(values);
        resetForm();
        this.setState({
            submitted: true
        });
        return false;
    }

    render() {
        return (
            <div className="app flex-row mt-5 animated fadeIn">
                <Container>
                    <Row>
                        <Col className="text-left">
                            <img alt="Logo" className="login-logo" src={Config.preLoginBrand ? Config.preLoginBrand : "/assets/img/brand/ezc_by-e4.png"} />
                        </Col>
                        <Col className="text-right">
                            <span>Already registered?</span>&nbsp; &nbsp;<Link to="/"><Button color="primary" className="bg-btn" >Login</Button></Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-center registration">
                        <Col md="8">
                            <CardGroup>
                                <Card className="">
                                    <CardHeader className="text-left bg-primary ">
                                        <Row>
                                            <Col sm="10">
                                                <h5>EZCheck-In “Event by Message” Driver Portal Demo</h5>
                                            </Col>
                                            <Col sm="2" className="text-right">
                                            </Col>
                                        </Row>

                                    </CardHeader>
                                    <CardBody className="text-left">
                                        <div>
                                            {this.state.submitted ?
                                                <Row>
                                                    <Col>
                                                        <p>
                                                            We just now sent a text message to your mobile device.
                                                            Please click the link in the text message to open the "Event
                                                            by Message" driver portal in your browser. You do NOT have
                                                            to download an app. Then click through the events to see how
                                                            easy it is for the driver to submit shipment events and
                                                            report their location while en route. The workflow in this
                                                            demo is "Arrive Empty + LiveLoad + Depart Loaded for Live
                                                            Unload at Destination". EZCheck-In supports all workflows
                                                            (including, for example, pre-loaded/drop trailers, IM
                                                            shipments with drays, hand-offs and transloads). Workflows
                                                            can also be easily customized. </p>

                                                            <p>To learn more about EZCheck-In, including how the shipper,
                                                            carrier or broker triggers the "Event by Message" process
                                                            and views the event data submitted by the driver, please
                                                            view our video below. Please also <a
                                                            href={"https://www.gete4score.com/contact-us/"} target="_blank">contact
                                                            us</a> to discuss how EZCheck-In can save you money and grow
                                                                your business. We look forward to talking with you.</p>

                                                            <iframe src="https://player.vimeo.com/video/366512492"
                                                                    width="100%" height="360" frameBorder="0"
                                                                    allow="autoplay; fullscreen"
                                                                    allowFullScreen></iframe>

                                                    </Col>
                                                </Row>
                                                :
                                                <Formik
                                                    initialValues={initialValues}
                                                    validate={validate(validationSchema)}
                                                    onSubmit={(v, o) => {
                                                        this.onSubmit(v, o)
                                                    }}
                                                    render={
                                                        ({
                                                             values,
                                                             errors,
                                                             touched,
                                                             status,
                                                             dirty,
                                                             handleChange,
                                                             handleBlur,
                                                             handleSubmit,
                                                             isSubmitting,
                                                             isValid,
                                                             handleReset,
                                                             setTouched,
                                                             setFieldValue,
                                                             setFieldTouched
                                                         }) => (


                                                            <Form onSubmit={(v) => {
                                                                return handleSubmit(v)
                                                            }}>
                                                                <Row>
                                                                    <Col>
                                                                        <p>
                                                                            <h4>Thank you for your interest
                                                                                in <b>EZCheck-In</b></h4>
                                                                            <br/>
                                                                            EZCheck-In "Event by Message" (patent
                                                                            pending) is the only
                                                                            app-less mobile tool that drivers can use to
                                                                            easily submit events for every stop,
                                                                            including the consignee, and report location
                                                                            while en route. Shippers and 3PL's use
                                                                            EZCheck-In to close the visibility gap and
                                                                            eliminate check-calls. To experience the
                                                                            EZCheck-In "Event by Message" driver portal
                                                                            right now, please enter your smart phone
                                                                            number below. Your number will not be used
                                                                            for anything other than this demo.
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <FormPhoneTextField
                                                                            label="Smart Phone"
                                                                            icon="fa-phone"
                                                                            fieldname="phone"
                                                                            countryCodeFieldName="countryCode"
                                                                            errors={errors}
                                                                            handleBlur={handleBlur}
                                                                            handleChange={(e) => {
                                                                                e.target.value = ValidationService.formatPhone(values['countryCode'], e.target.value, true);
                                                                                handleChange(e);
                                                                            }}
                                                                            onCountryCodeChange={(e) => {
                                                                                setFieldValue("countryCode", e.code);
                                                                            }}
                                                                            touched={touched}
                                                                            values={values}
                                                                            placeholder={values["countryCode"] === "US" || !values["countryCode"] ? "###-###-####" : "#########"}
                                                                            setFieldValue={setFieldValue}
                                                                            setFieldTouched={setFieldTouched}
                                                                            labelStyle={{
                                                                                marginTop:"7px"
                                                                            }}
                                                                        >
                                                                        </FormPhoneTextField>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xs="12">
                                                                        <Button color="primary" className="bg-btn mt-3"
                                                                                type="submit">Submit</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Form>
                                                        )}/>
                                            }

                                        </div>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>);
    }

}

export default Demo;
