import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert
} from "reactstrap";
import { checkLogin, requestPasswordResetTA, trackShipmentsUsingTrackingIDAnonymously } from "../../NetworkHelpers";
import { withRouter } from "react-router";
import { showError, showInfo } from "../../MainApp";
import Loader from "react-loader-advanced";
import LoaderIco from "react-loader";
import * as PropTypes from "prop-types";
import Config from "../../Config";

class LoginCard extends Component {
  render() {
    return (
      <Card className={"pb-0" + this.props.className}>
        <CardBody className={"pb-0"}>
          <Row>
            <div className={"text-center w-100"}>
              <img className={"w-15"} src={"/assets/img/brand/vantix.png"}></img>
            </div>
          </Row>
          <Row>
            <Col lg={6} className={"br-2px d-none d-lg-block d-xl-block"}>
              <div className="v-space" />
              <Form className={"pl-5 pr-5"}>
                <h1 className="text-center ta-text-color">Track Your Shipment</h1>
                <h6 className="text-center ta-text-color">(No login Required)</h6>

                <div className="v-space" />
                <InputGroup className="mb-3 mt-2">

                  <Input
                    type="text"
                    placeholder="[Enter your Reference Number(s) here.]"
                    value={this.props.valueShipmentId}

                    rows={6}
                    onBlur={this.props.onShipmentIdBlur}
                    onChange={this.props.onShipmentIdChange}

                  />
                </InputGroup>
                <Alert>
                  <span className={"font-10"}>
                    Enter your Reference Number(s) above. Can enter multiple space separated Reference numbers provided by your provider. Example Numbers: LOAD ID, BOL, PO, Shipment ID, PRO
                  </span>
                </Alert>
                <Row className={"mt-4"}>
                  <Col xs="12">
                    <Button
                      color="primary"
                      className="px-4 w-100"

                      disabled={this.props.valueShipmentId === ""}
                      onClick={this.props.onTrackClick}
                    >
                      Track
                    </Button>

                  </Col>

                </Row>
              </Form>
              <div className="v-space" />
              <div className="v-space" />
            </Col>
            <Col lg={6} className={"bl-2px"}>
              <div className="v-space" />
              <Form className={"pl-5 pr-5"}>
                <h1 className="text-center ta-text-color">Password Reset</h1>
                <h1 className="text-center ta-text-color"><i className={"fa fa-lock-alt font-40"}></i></h1>

                <div className="v-space" />
                <p>Please provide your registered email below. </p>
                <InputGroup className="">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Enter Email here"
                    value={this.props.value}
                    autoComplete="username"
                    onChange={this.props.onUsernameChange}
                    onKeyPress={this.props.onKeyPress}
                  />
                </InputGroup>

                <div className="v-space" />

                <Row className={"mt-4"}>
                  <Col xs="12 text-center">
                    <Button
                      color="danger"
                      className="px-4 text-uppercase"
                      onClick={this.props.onCancelClick}
                    >
                      Cancel
                    </Button> &nbsp; &nbsp;
                    <Button
                      color="primary"
                      className="px-4 text-uppercase"
                      onClick={this.props.onResetClick}
                    >
                      Submit
                    </Button>

                  </Col>

                </Row>

              </Form>

              <div className="v-space" />
              <div className="v-space" />
            </Col>
          </Row>


        </CardBody>
        <img className={"branding-logo"} src="/assets/img/brand/e4logo.png"></img>
      </Card >
    );
  }
}

LoginCard.propTypes = {
  value: PropTypes.any,
  onUsernameChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  password: PropTypes.any,
  onPasswordChange: PropTypes.func,
  onResetClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onPasswordResetClick: PropTypes.func,
  className: PropTypes.any,
  onShipmentIdChange: PropTypes.func,
  onShipmentIdBlur: PropTypes.func,
  onTrackClick: PropTypes.func,
};

class PasswordResetTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipmentId: "",
      password: "",
      loading: true,
      loader: false,
      userIsLoggedIn: false,
    };
    this.updateSpinner("Searching ...");
  }
  updateSpinner(val) {
    this.spinner = (
      <span>
        <p className="loadingMessage">{val}</p>
        <LoaderIco
          loaded={false}
          lines={13}
          length={20}
          width={10}
          radius={30}
          corners={1}
          rotate={0}
          direction={1}
          color="#51b32e"
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className="spinner"
          zIndex={2e9}
          top="50%"
          left="50%"
          scale={1.0}
          loadedClassName="loadedContent"
        />
      </span>
    );
  }
  componentWillUnmount() {
    document.body.style.background = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.background = null;
    document.body.style.backgroundColor = "#000";
    this.checkLogin();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  registerClicked(e) {
    e.preventDefault();
    this.nextPath("/register");
  }

  checkLogin() {
    checkLogin(
      (user) => {
        this.props.login(user.username, user);
        this.setState({ loading: false, userIsLoggedIn: true });
      },
      () => {
        this.setState({ loading: false, userIsLoggedIn: false });
      }
    );
  }

  reset() {
    if (!this.state.username) {
      showError("Please enter an email address.");
    } else {
      requestPasswordResetTA(this.state.username, () => {
        showInfo("If you have an existing account an email will be sent with a link for resetting your password.");
        this.props.history.push("/login-trackassured");
      },
        () => {
          showError("Unable to request password reset, please try again later.");
        });
    }
  }
  onShipmentIdBlur(shipmentId) {
    shipmentId = shipmentId.trim();
    this.setState({ shipmentId: shipmentId });
  }
  onUsernameChange(username) {
    this.setState({ username: username });
  }

  onPasswordChange(password) {
    this.setState({ password: password });
  }

  onEnterLogin(e) {
    if (e.charCode === 13) this.login();
  }

  showRequestPasswordReset() {
    this.props.history.push("/password-reset-request-trackassured");
  }
  showLogin() {
    this.props.history.push("/login-trackassured");
  }
  getCardWidth() {
    return Config.hideInfoOnLogin ? 6 : 12;
  }
  onShipmentIdChange(shipmentId) {
    this.setState({ shipmentId: shipmentId });
  }
  getUniqueIds(names) {
    var uniqueArr = [];
    for (var value of names) {
      if (uniqueArr.indexOf(value) === -1) {
        uniqueArr.push(value);
      }
    }
    return uniqueArr;
  }
  hasDuplicates(array) {
    return (new Set(array)).size !== array.length;
  }
  track() {

    let s = this.state.shipmentId.trim();
    s = s.split(" ");
    s = s.filter(it => it !== "");
    s = s.map(it => it = it.toLowerCase());
    let chec = this.hasDuplicates(s);
    if (chec === true) {
      showInfo("Duplicate IDs are removed.");
    }
    s = this.getUniqueIds(s);
    s = s.toString();
    var data = { "page": 0, "pageSize": 20, "sorted": [], "filtered": [] };
    this.setState({ loader: true });
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    trackShipmentsUsingTrackingIDAnonymously(s, timeZone, data, (response) => {
      this.setState({ loader: false });
      if (response.TrackedShipments.length > 0) {
        localStorage.setItem("trackedShipments", JSON.stringify(response.TrackedShipments));
        this.props.history.push("/track-anonymously/" + response.TrackedShipments[0].assetID + "/" + response.TrackedShipments[0].trackType + "/" + response.TrackedShipments[0].trackID);
      }

      showInfo("Data Loaded.");
    }, (error) => {
      this.setState({ loader: false });
      s = s.split(",");

      let arr = [];
      for (var i = 0; i < s.length; i++) {
        arr.push({ trackID: s[i] });
      }
      arr = JSON.stringify(arr);
      localStorage.setItem("trackedShipments", arr);

      this.props.history.push("/track-anonymously/" + s[0]);
    })

  }
  render() {
    if (!this.state.loading && !this.state.userIsLoggedIn) {
      return (
        <div className="app flex-row align-items-center animated fadeIn login-TA-background">
          <Container>
            <Loader show={this.state.loader} message={this.spinner}>
              <Row className="justify-content-center">
                <Col md={this.getCardWidth()}>
                  <CardGroup>
                    <LoginCard
                      className={
                        "p-4 text-left" +
                        (this.props.hideLoginOnSmall ? "d-md-down-none" : "")
                      }
                      value={this.state.username}
                      onUsernameChange={(e) =>
                        this.onUsernameChange(e.target.value)
                      }
                      onTrackClick={(e) => {
                        e.preventDefault();
                        this.track()
                      }}
                      onShipmentIdChange={(e) =>
                        this.onShipmentIdChange(e.target.value)
                      }
                      onShipmentIdBlur={(e) => {
                        this.onShipmentIdBlur(e.target.value)
                      }
                      }
                      password={this.state.password}
                      onPasswordChange={(e) =>
                        this.onPasswordChange(e.target.value)
                      }
                      onResetClick={() => this.reset()}
                      onCancelClick={(e) => {
                        e.preventDefault();
                        this.showLogin();
                      }}
                    />

                  </CardGroup>
                </Col>
              </Row>
            </Loader>
          </Container>
        </div>
      );
    }
    return <div></div>;
  }
}

export default withRouter(PasswordResetTA);
