import React, { Component } from 'react';
import * as uuid from "uuid";
import { Tooltip } from "reactstrap";
import PropTypes from 'prop-types';

class IconWithTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
            id: "ID_" + uuid.v4()
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    onIconClickHandler() {
        return this.props.onClick();
    }
    render() {
        return <span className={this.props.className}>
            {this.props.isIcon &&
                <i onClick={() => {
                    this.props.isClickAble &&
                        this.onIconClickHandler()
                    // this.props.onClick && this.props.onClick()
                }}
                    className={this.props.classList}
                    id={this.state.id}
                />
            }
            {this.props.isToolTip &&
                <Tooltip placement={this.props.placement} isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
                    {this.props.toolTipTxt}
                </Tooltip>
            }
        </span>
    }
}

IconWithTooltip.defaultProps = {
    placement: "right"
};

IconWithTooltip.propTypes = {
    placement: PropTypes.string,
    helpMessage: PropTypes.string
};

export default IconWithTooltip;