import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { checkLogin, postLoginUser, postLoginUserV2 } from "../../NetworkHelpers";
import { withRouter } from "react-router";
import { showError } from "../../MainApp";
import * as PropTypes from "prop-types";
import Config from "../../Config";
import ResetModal from "../../common/ResetModal"


class LoginCard extends Component {
  render() {
    return (
      <Card className={this.props.className + "round-10 bb-cyan"}>
        <CardBody className={"text-center position-relative"}>
          <a href={"https://www.gete4score.com/"}>
            <img
              alt="Logo"
              className="login-logo w-100"
              src={
                Config.preLoginBrand
                  ? Config.preLoginBrand
                  : "/assets/img/brand/e4score-Supply-Chain-Digitization-Solutions-Shipment-Visibility-Software.png"
              }
            />
          </a>
          <img
            alt="Logo"
            className={"mb-3 w-25"}
            src={
              "/assets/img/brand/icon_user.png"
            }
          />
          <Form>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="icon-color">
                  <i className="fa fa-user" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Email address"
                value={this.props.value}
                autoComplete="username"
                onChange={this.props.onUsernameChange}
                onKeyPress={this.props.onKeyPress}
              />
            </InputGroup>
            <InputGroup className="">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="icon-color">
                  <i className="fa fa-lock" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="password"
                placeholder="Password"
                value={this.props.password}
                autoComplete="current-password"
                onChange={this.props.onPasswordChange}
                onKeyPress={this.props.onKeyPress}
              />

            </InputGroup>
            <Row>
              <Col xs="12 text-right">
                <Button
                  color="link"
                  className="px-0 muted-link font-9"
                  onClick={this.props.onPasswordResetClick}
                >
                  Forgot password?
                </Button>
              </Col>
            </Row>


            <Row>
              <Col xs="12 mt-3">
                <Button
                  className="px-5 loginBtn text-uppercase"
                  onClick={this.props.onLoginClick}
                >
                  Login
                </Button>{" "}


              </Col>
            </Row>
            <div className="v-space" />
            <Row className={"position-absolute terms"}>
              <Col xs="12">
                {/* <a href="#"><span className="font-11 icon-color font-weight-bold">Terms and Conditions</span></a> &nbsp; <span className="font-11 color-c6cacd">|</span> &nbsp; */}
                <a href="https://www.e4score.com/contact-us/"><span className="font-11 icon-color font-weight-bold">Contact us</span> &nbsp;
                  |&nbsp;<span className="font-11 icon-color font-weight-bold">Version : {Config.version}</span></a>
              </Col>
            </Row>
          </Form>

        </CardBody>

      </Card>
    );
  }
}

LoginCard.propTypes = {
  value: PropTypes.any,
  onUsernameChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  password: PropTypes.any,
  onPasswordChange: PropTypes.func,
  onLoginClick: PropTypes.func,
  onPasswordResetClick: PropTypes.func,
  className: PropTypes.any,
};
// new
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: true,
      userIsLoggedIn: false,
      resetModalOpen: false,
      count: 1
    };
  }

  componentWillUnmount() {
    document.body.style.background = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.background = null;
    document.body.style.backgroundColor = "#000";
    this.checkLogin();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  registerClicked(e) {
    e.preventDefault();
    this.nextPath("/register");
  }

  checkLogin() {
    checkLogin(
      (user) => {
        this.props.login(user.username, user);
        this.setState({ loading: false, userIsLoggedIn: true });
      },
      () => {
        this.setState({ loading: false, userIsLoggedIn: false });
      }
    );
  }

  login() {
    postLoginUserV2(
      { username: this.state.username, password: this.state.password },
      (user) => {
        localStorage.setItem('taskPopUpCheck', false);
        this.props.login(this.state.username, user.content);
      },
      () => {
        showError("You could not be logged in. Please check your credentials.");
      }
    );
  }

  onUsernameChange(username) {
    this.setState({ username: username });
  }

  onPasswordChange(password) {
    this.setState({ password: password });
  }

  onEnterLogin(e) {
    if (e.charCode === 13) this.login();
  }

  showRequestPasswordReset() {
    this.props.history.push("password-reset-request");
  }

  getCardWidth() {
    return Config.hideInfoOnLogin ? 6 : 12;
  }
  openResetModal() {
    let s = this.state.count;
    s = s + 1;
    this.setState({
      count: s
    });
    if (this.state.count > 4) {
      this.setState({ resetModalOpen: true });
    }
  }
  render() {
    if (!this.state.loading && !this.state.userIsLoggedIn) {
      return (
        <div className="app flex-row align-items-center animated fadeIn login-background">
          <ResetModal
            open={this.state.resetModalOpen}

            onSave={(reasonCode) => {
              // this.props.history.push("/");
              localStorage.clear();
              let url = window.location.origin;

              window.location.replace(url);

            }}
            onCancel={() => this.setState({ resetModalOpen: false, count: 1 })}
          />

          <span className={"hideBtn"} onClick={() => {
            this.openResetModal();
          }}>&nbsp;</span>
          <Container>
            <Row className="justify-content-center">
              <Col md={"4"}>
                <CardGroup>
                  <LoginCard
                    className={
                      "p-4 text-left login-card "

                    }
                    value={this.state.username}
                    onUsernameChange={(e) =>
                      this.onUsernameChange(e.target.value)
                    }
                    onKeyPress={(e) => this.onEnterLogin(e)}
                    password={this.state.password}
                    onPasswordChange={(e) =>
                      this.onPasswordChange(e.target.value)
                    }
                    onLoginClick={() => this.login()}
                    onPasswordResetClick={(e) => {
                      e.preventDefault();
                      this.showRequestPasswordReset();
                    }}
                  />

                </CardGroup>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return <div></div>;
  }
}

export default withRouter(Login);
