import MomentDisplay from "../../assets/components/MomentDisplay";
import React, {Component} from "react";
import {formatLatLong} from "../../common/utilities";

class EZCheckInEventMapPinPopup extends Component {
    getProviders(event) {
        let col = "";
        if (event && event.providers) {
            for (const provider of event.providers) {
                if (col === "") {
                    col += provider;
                } else {
                    col += provider + ", ";
                }
            }
        }
        if (event && event.broker && event.broker !== "N/A") {
            if (col === "") {
                col += event.broker;
            } else {
                col += event.broker + ", ";
            }
        }
        if (event && event.carrier && event.carrier !== "N/A") {
            if (col === "") {
                col += event.carrier;
            } else {
                col += event.carrier + ", ";
            }
        }
        return col;
    }
    render() {
        return (
            <>
                {this.props.event.date &&
                <p><b>Report Time:</b>&nbsp;
                    <MomentDisplay date={this.props.event.date}
                                   timezone={this.props.event.timezone}/></p>
                }
                {this.props.event.eventDescription &&
                <p><b>Event:</b> {this.props.event.eventDescription}</p>
                }
                {this.props.event.status &&
                <p><b>Shipment Status:</b> {this.props.event.status}</p>
                }
                {this.props.event.geocodeLocation && this.props.event.geocodeLocation.locality
                    && this.props.event.geocodeLocation.state && this.props.event.geocodeLocation.country &&
                <p><b>GPS:</b> {this.props.event.geocodeLocation.address && this.props.event.geocodeLocation.address+<br/>}
                    {this.props.event.geocodeLocation.locality} , {
                    this.props.event.geocodeLocation.state} {this.props.event.geocodeLocation.country !== "US" &&
                    ", " + this.props.event.geocodeLocation.country}
                </p>
                }
                {this.props.event.latitude && this.props.event.longitude &&
                <p><b>Coordinates: </b>{formatLatLong(this.props.event.latitude) + ", " +
                                        formatLatLong(this.props.event.longitude)}</p>
                }
                {this.getProviders(this.props.event) !== "" &&
                <p><b>Carrier / Broker: </b>{this.getProviders(this.props.event)}</p>
                }
                {this.props.event.shipmentNumber &&
                <p><b>Shipment #:</b> {this.props.event.shipmentNumber}</p>
                }
            </>
        )
    }
}
export default EZCheckInEventMapPinPopup;