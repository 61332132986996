import React, {Component} from 'react';
import {Col, FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import Select from "react-select";

class FormSelectField extends Component {
    icon() {
        if ( this.props.icon ) {
            return <i className={"fa " + this.props.icon}></i>
        } else if ( this.props.iconText ) {
            return <strong>{this.props.iconText}</strong>;
        }
        return " ";
    }

    render() {
            return (
                <FormGroup>
                    <Row>
                        <Col lg="2" md="3" sm="4">
                            <Label>{this.props.label} {this.props.label && this.props.required ? '*' : ''}</Label>
                        </Col>
                        <Col lg="10" md="9" sm="8">
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>{this.icon()}</InputGroupText>
                                </InputGroupAddon>
                                <Select name={this.props.fieldname}
                                        maxMenuHeight={this.props.maxMenuHeight}
                                        fieldname={this.props.fieldname}
                                        isClearable={this.props.isClearable}
                                        placeholder={this.props.placeholder}
                                        isSearchable={this.props.isSearchable}
                                        errors={this.props.errors}
                                        touched={this.props.touched}
                                        isMulti={this.props.isMulti}
                                        onChange={(v) => {
                                            if (!this.props.disableFormik) {
                                                if (this.props.formikOverride) {
                                                    this.props.setFieldValue(this.props.formikOverride, this.props.isMulti ? v : (v ? v.value : ""));
                                                    this.props.setFieldTouched(this.props.formikOverride, true);
                                                } else {
                                                    this.props.setFieldValue(this.props.fieldname, this.props.isMulti ? v : (v ? v.value : ""));
                                                    this.props.setFieldTouched(this.props.fieldname, true);
                                                }

                                            }
                                            this.props.onChange && this.props.onChange(v);
                                        }}
                                        className={"basic-single select-in-form" + ((this.props.touched && this.props.touched[this.props.fieldname] && !!this.props.errors[this.props.fieldname]) ? " is-invalid" : "")}
                                        classNamePrefix="select"
                                        options={this.props.options.map((v)=>{return {value:v, label:v}})}
                                        onBlur={() => {
                                            if(this.props.formikOverride){
                                                this.props.setFieldTouched(this.props.formikOverride, true);
                                            }else{
                                                this.props.setFieldTouched(this.props.fieldname, true)
                                            }
                                        }}
                                        values={this.props.values}
                                        value={this.getValue()}
                                        isDisabled={this.props.isDisabled}
                                />
                                <FormFeedback>{this.props.errors && this.props.errors[this.props.fieldname]}</FormFeedback>
                            </InputGroup>
                        </Col>
                    </Row>
                </FormGroup>
            )
        }


    getValue() {
        let values;
        if (this.props.isMulti) {
            values = this.props.values[this.props.fieldname];
        } else {
            values = this.props.values && this.props.values[this.props.fieldname] ? {
                label: this.props.values[this.props.fieldname],
                name: this.props.values[this.props.fieldname]
            } : null;
        }
        return values;
    }
}


export default FormSelectField;