import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { downloadAuthorizedLocations, promiseAuthorizedLocationYardEnableSearch, promiseAuthorizedLocationSearch, promiseAuthorizedLocationSearchForGate, downloadAuthorizedLocationsForGate } from "../../../NetworkHelpers";
import AsyncSelect from "react-select/lib/Async";
import * as PropTypes from "prop-types";
import HelpIcon from "../../../common/HelpIcon";
export class ChangeLocationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLocation: null
    };
  }

  render() {
    return <>
      <Modal isOpen={true}>
        <ModalBody>
          <Row style={{ marginBottom: "15px" }}>
            <Col md={12}>
              <label className={"w-100"}>
                Your Current Location
                <AsyncSelect
                  placeholder="Choose a location"
                  defaultOptions
                  defaultValue={
                    this.props.currentLocation ?
                      { label: this.props.currentLocation.nickname ? this.props.currentLocation.nickname : this.props.currentLocation.name, value: this.props.currentLocation.uuid }
                      : null
                  }
                  onChange={(v) => {
                    this.setState({ selectedLocation: v })
                  }}
                  loadOptions={this.props.pageType === "gate" ?
                    promiseAuthorizedLocationSearchForGate : this.props.pageType === "onsite" ?
                      promiseAuthorizedLocationYardEnableSearch : promiseAuthorizedLocationSearch}
                />
              </label>
            </Col>
          </Row>
          <Row>
            <Col md={6} className={"text-left"}>

              {/* <Button  color={'danger'} onClick={() => this.props.onCancel()}>
                                Cancel
                            </Button> */}
            </Col>
            <Col md={6} className={"text-right"}>
              <Button color={"primary"}
                onClick={() => this.props.onChange(this.state.selectedLocation)}
                disabled={!!!this.props.currentLocation}>
                Continue
              </Button>

            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>;
  }
}

export class CurrentLocation extends Component {

  render() {
    return <span>
      <span
        className="">{this.getLocationName()}</span>
      <Button id="checkLocation" disabled={this.props.yardCheckActive === true} onClick={this.props.onClick} color="link">Change</Button>
      {this.props.yardCheckActive === true &&
        <HelpIcon className={"mr-3"} helpMessage={"Location change is not allowed during yardcheck."} />

      }</span>;
  }

  getLocationName() {
    if (this.props.currentLocation) {
      if (this.props.currentLocation.nickname) {
        return <> {this.props.currentLocation.nickname}</>;
      } else {
        return <> {this.props.currentLocation.name}</>;
      }
    } else {
      return <> {"None Selected"}</>;
    }
  }
}

export class ChangeLocationFormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationList: null,
      open: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.startChangeLocation();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.open === true && prevState.open == false) {
      this.startChangeLocation();
    }
  }

  onLocationsDownloaded(data) {
    this.setState({ locationList: data })
  }

  startChangeLocation() {
    if (this.props.pageType === 'gate') {
      downloadAuthorizedLocationsForGate((data) => {
        this.onLocationsDownloaded(data.content)
      }, (e) => {
        this.setState({ loading: false });
      });
    } else {
      downloadAuthorizedLocations((data) => {
        this.onLocationsDownloaded(data.content)
      }, (e) => {
        this.setState({ loading: false });
      });
    }
  }

  render() {
    if (this.state.open) {
      return <>
        <ChangeLocationModal
          currentLocation={this.props.currentLocation}
          yardCheckActive={this.props.yardCheckActive}
          setCurrentLocation={this.props.setCurrentLocation}
          pageType={this.props.pageType ? this.props.pageType : ""}
          onChange={(location) => {
            if (location) {
              for (const locationEntity of this.state.locationList) {
                if (location.value === locationEntity.uuid) {
                  this.setState({ open: false }, () => {
                    this.props.onChange(locationEntity);
                    return;
                  })
                }
              }
            } else {
              this.setState({ open: false })
            }

          }}
          onCancel={() => {
            this.setState({ open: false })
          }}
        />
      </>;
    } else {
      return (
        <CurrentLocation
          yardCheckActive={this.props.yardCheckActive}
          currentLocation={this.props.currentLocation}
          onClick={() => {
            this.setState({ open: true })
          }}
        />)
    }
  }
}

ChangeLocationFormField.propTypes = {
  onChange: PropTypes.func,
  currentLocation: PropTypes.object,
  setCurrentLocation: PropTypes.func
}