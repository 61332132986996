import * as React from "react";
import StaticDurationDisplay from "../../assets/components/StaticDurationDisplay";
import {getDwellForAssetType} from "../../common/utilities";

export class AlertModelStaticDurationDisplay extends React.Component {
    render() {
        return <div>
            <StaticDurationDisplay
                durationMillis={this.props.visitLength}
                dwellAlert={
                    getDwellForAssetType(
                        this.props.assetType,
                        this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.dwellAlert,
                        this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.dwellAlert
                    )
                }
                criticalDwellInMinutes={
                    getDwellForAssetType(
                        this.props.assetType,
                        this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.criticalDwellAlertInSeconds / 60,
                        this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.criticalDwellAlertInSeconds / 60
                    )
                }
                cricitalDwellAlertUnit={
                    getDwellForAssetType(
                        this.props.assetType,
                        this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.criticalDwellAlertUnit,
                        this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.criticalDwellAlertUnit
                    )
                }
                warningDwellInMinutes={
                    getDwellForAssetType(
                        this.props.assetType,
                        this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.warningDwellAlertInSeconds / 60,
                        this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.warningDwellAlertInSeconds / 60
                    )
                }
                warningDwellAlertUnit={
                    getDwellForAssetType(
                        this.props.assetType,
                        this.props.alertModel.tractorDwell && this.props.alertModel.tractorDwell.warningDwellAlertUnit,
                        this.props.alertModel.trailerDwell && this.props.alertModel.trailerDwell.warningDwellAlertUnit
                    )
                }
            />
        </div>;
    }
}