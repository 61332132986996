import React from 'react';
import PropTypes from 'prop-types';
import {FormFeedback, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";

export default class RegisterInputGroup extends React.Component {
    render() {
        return (
            <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        {this.props.icontype && <i className={this.props.icontype}></i>}
                        {this.props.prependText && this.props.prependText}
                    </InputGroupText>
                </InputGroupAddon>
                {this.props.children}
                <FormFeedback>{this.props.error}</FormFeedback>
            </InputGroup>
        );
    }
}
RegisterInputGroup.propTypes = {
    fieldname: PropTypes.any,
    icontype: PropTypes.any,
    errors: PropTypes.any
};
    