import React, {Component} from 'react';
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {storeItemsPerPage} from "../../assets/actions/Assets";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";
import AsyncSelect from 'react-select/lib/Async';
import {promiseCompanyByPrefix, setCompanyAdministrationUuid} from "../../NetworkHelpers";
import EventNotifications from "./EventNotifications";
import {setUsersCompany, storeUserCompanyReferenceNumberTypes} from "../../Login/actions/Login";
import {ReferenceNumberTypes} from "./ReferenceNumberTypes";
import DispatchSettings from "./DispatchSettings";
import {
    ROLE_ACCOUNTS_PAYABLE,
    ROLE_ADMIN, ROLE_ATTENDANT, ROLE_CARRIER_ACCOUNTS_PAYABLE,
    ROLE_CARRIER_ADMIN,
    ROLE_E4SCORE_ADMIN, ROLE_OPERATOR,
    ROLE_POWERYARD_ADMIN,
    ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER,
    userHasRole
} from "../../Roles";
import WorkflowSettings from "./WorkflowSettings";
import Workflows from "./Workflows";

class EZCheckInSettings extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '8',
            hideFilters: true
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    componentDidMount() {
        window.scrollTo(0,0);
        if(this.props.defaultCompany && this.props.defaultCompany.name === "e4score" ){
            this.setState({
                activeTab: '4'
            });
        }
    }

    render() {
        return (
            <div className="admin text-left p-3 form-bk">
                <h1>Administration</h1>
                {this.props.superAdmin &&
                <Row style={{marginBottom: "10px"}}>
                    <Col md={12}>
                        <h3>Change Company</h3>
                    </Col>
                    <Col md={12}>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={promiseCompanyByPrefix}
                            placeholder={this.props.defaultCompany.name}
                            onChange={(option) => {
                                this.setState({selectedCompany: option});
                                setCompanyAdministrationUuid(option.uuid, (company) => {
                                    this.props.setUsersCompany(company);
                                }, () => {

                                })
                            }}
                        />
                    </Col>
                </Row>
                }
                <div className="admin-main">
                    <Nav tabs>
                        {(this.props.loggedInUser.company.enableEzCheckInDispatch ||
                            this.props.loggedInUser.company.enableEzCheckInWelcome) && this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
                            userHasRole(this.props.loggedInUser, [ROLE_E4SCORE_ADMIN, ROLE_ADMIN, ROLE_CARRIER_ADMIN]) &&
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === '8' ? "active" : ""}
                                onClick={() => {
                                    this.toggle('8');
                                }}
                            >
                                Event Notifications
                            </NavLink>
                        </NavItem>
                        }
                        {/* {(this.props.loggedInUser.company.enableEzCheckInDispatch) && this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
                        userHasRole(this.props.loggedInUser, [ROLE_E4SCORE_ADMIN, ROLE_ADMIN, ROLE_CARRIER_ADMIN]) &&
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === '10' ? "active" : ""}
                                onClick={() => {
                                    this.toggle('10');
                                }}
                            >
                                Reference Number Types
                            </NavLink>
                        </NavItem>
                        } */}
                        {(this.props.loggedInUser.company.enableEzCheckInDispatch || this.props.loggedInUser.company.enableEzCheckInWelcome)
                        && this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score' &&
                        userHasRole(this.props.loggedInUser, [ROLE_E4SCORE_ADMIN, ROLE_ADMIN, ROLE_CARRIER_ADMIN]) &&
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === '12' ? "active" : ""}
                                onClick={() => {
                                    this.toggle('12');
                                }}
                            >
                                Dispatch Settings
                            </NavLink>
                        </NavItem>
                        }
                        {(this.props.loggedInUser.company.enableEzCheckInDispatch) && this.props.defaultCompany
                        && this.props.defaultCompany.name !== 'e4score'
                        && userHasRole(this.props.loggedInUser, [ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER,
                            ROLE_ADMIN, ROLE_ATTENDANT, ROLE_ACCOUNTS_PAYABLE, ROLE_OPERATOR, ROLE_CARRIER_ACCOUNTS_PAYABLE]) &&
                        <NavItem>
                            <NavLink
                                className={this.state.activeTab === '5' ? "active" : ""}
                                onClick={() => {
                                    this.toggle('5');
                                }}
                            >
                                Workflow Settings
                            </NavLink>
                        </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="8">
                            <EventNotifications company={this.props.loggedInUser.company} onChange={(settings) => {
                                this.props.loggedInUser.company.eventNotificationSettings = settings;
                                this.props.setUsersCompany(this.props.loggedInUser.company);
                            }}/>
                        </TabPane>
                        <TabPane tabId="10">
                            <ReferenceNumberTypes
                                company={this.props.loggedInUser.company}
                                loggedInUser={this.props.loggedInUser}
                                defaultCompany= {this.props.defaultCompany}
                                onChange={(referenceNumberTypes)=>this.props.setUsersReferenceNumberTypes(Object.values(referenceNumberTypes))}
                                onCompanyChange={(company) =>{
                                    this.props.setUsersCompany(company);
                                }}
                            />
                        </TabPane>
                        <TabPane tabId="12">
                            <DispatchSettings
                                company={this.props.loggedInUser.company}
                                onChange={(settings) => {
                                    this.props.loggedInUser.company.dispatchSettings = settings;
                                    this.props.setUsersCompany(this.props.loggedInUser.company);
                                }}
                            />
                        </TabPane>
                        {(this.props.loggedInUser.company.enableEzCheckInDispatch) && this.props.defaultCompany && this.props.defaultCompany.name !== 'e4score'
                        && userHasRole(this.props.loggedInUser, [ROLE_POWERYARD_ADMIN, ROLE_POWERYARD_JOCKEY, ROLE_POWERYARD_REQUESTER,
                            ROLE_ADMIN, ROLE_ATTENDANT, ROLE_ACCOUNTS_PAYABLE, ROLE_OPERATOR, ROLE_CARRIER_ACCOUNTS_PAYABLE]) &&
                        <TabPane tabId="5">
                            <div className="flex-column flex flex-grow-1">
                                <WorkflowSettings company={this.props.loggedInUser.company} onChange={(settings) => {
                                    this.props.loggedInUser.company.workflowSettings = settings;
                                    this.props.setUsersCompany(this.props.loggedInUser.company);
                                }}/>
                                <Workflows/>
                            </div>
                        </TabPane>
                        }
                    </TabContent>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        loggedInUser: state.login.user,
        itemsPerPage: state.assets.itemsPerPage
    }
}

function mapDispatchToProps(dispatch) {
    return ({
        setUsersCompany: function(company){
            dispatch(setUsersCompany(company));
        },
        setUsersReferenceNumberTypes: function(referenceNumberTypes){
            dispatch(storeUserCompanyReferenceNumberTypes(referenceNumberTypes));
        },
        setItemsPerPage: function(itemsPerPage) {
            dispatch(storeItemsPerPage(itemsPerPage));
        }
    });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EZCheckInSettings));